import { uploadFile, uploadFilePublic } from "api/upload/uploadFile";
import { uploadFileOnURL } from "api/upload/uploadImgOnR2";
import addNotification from "helpers/notify";
import { v4 as uuidv4 } from "uuid";
import JSZip from "jszip";

export const uploadFileToServer = async (file, isPublic = false) => {
  try {
    const zip = new JSZip();
    const fileExt = file?.name?.split(".").pop();
    const name = uuidv4() + "." + fileExt + "." + "gz";

    let res;
    if (isPublic) {
      res = await uploadFilePublic({ fileNames: [name] });
    } else {
      res = await uploadFile({ fileNames: [name] });
    }

    if (res) {
      zip.file(file?.name, file);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const formObj = {
        url: res?.uploadUrls[0],
        body: zipBlob,
      };
      await uploadFileOnURL(formObj);
    }

    return name;
  } catch (error) {
    console.error("Error uploading file to server:", error);
    return null; // Return null in case of error
  }
};
