import React, { useEffect, useState } from "react";
import Loading from "../../ui-components/loading";
import { postLicence } from "api/licences/postLicence";
import addNotification from "helpers/notify";
import { useHttp } from "hooks/useHttp";
import { checkoutSession } from "api/stripe/checkoutSession";
import capitalize from "helpers/capitalize";
import { eventTrack } from "helpers/ga4Helper";
import { useParams } from 'react-router-dom';

const PricingForm = (props) => {
  const { selectedPlan, handleBack } = props;
  const { username } = useParams();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");

  const { loading, request: submitLicenceAPI } = useHttp({
    requestCallback: (data) => checkoutSession(data),
    onLoad: (res) => {
      setEmail("");
      if (res.err) {
        addNotification(res.err || "Error", "danger");
      } else {
        window.location = res.url;
        // addNotification(
        //   "An email has been sent with a link to register your company",
        //   "success"
        // );
      }
    },
    onError: (err) => {
      setEmail("");
      setMobile("");
      setFname("");
      setLname("");
      addNotification(err.status, "danger");
    },
  });

  const submitLicence = (e) => {
    e.preventDefault();
    submitLicenceAPI({
      email: email,
      priceId: selectedPlan.priceId,
      product: selectedPlan.title,
      affiliationId: username
    });
    eventTrack(
      "Buy-Plan-Action",
      selectedPlan.title,
      selectedPlan.priceId,
      selectedPlan.priceId,
      false,
      selectedPlan.priceId
    );
  };

  return (
    <>
      <div>
        <div className="container" style={{ marginTop: "70px" }}>
          {/* <div className="row justify-content-center"> */}
          <div className="justify-content-center">
            <div>
              {/* <form
                className="bg-white box-register px-5"
                onSubmit={submitLicence}
              > */}
              <form
                id="scrollonClick"
                className="px-3"
                onSubmit={submitLicence}
                // action="/stripe/checkout-session" method="POST"
              >
                <div className="px-3">
                  <h3 className="head-bidlight">
                    {" "}
                    {selectedPlan.title}{" "}
                    <span className="text-muted">
                      (${selectedPlan.amount} /Month)
                    </span>
                  </h3>{" "}
                  <p
                    className="mx-0 mb-4 w-100 sub-text-price"
                    dangerouslySetInnerHTML={{
                      __html: selectedPlan?.description,
                    }}
                  ></p>
                  <p className="mx-0 mb-5 w-100 sub-text-price">
                    Fill in your information and receive a license in your
                    inbox!
                  </p>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-6 mb-2 mt-2">
                    <div className="form-group form-price">
                      <span className="fa fa-user form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        id="fname"
                        value={fname}
                        required
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </div>

                    {/* <input
                      type="text"
                      id="fname"
                      className="form-control form-register"
                      value={fname}
                      placeholder="First Name"
                      required
                      onChange={(e) => setFname(e.target.value)}
                    /> */}
                  </div>
                  <div className="col-lg-6 mb-2 mt-2">
                    {/* <label className="text-black" htmlFor="lname">
                      Last Name
                    </label> */}

                    <div className="form-group form-price">
                      <span className="fa fa-user form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        id="lname"
                        value={lname}
                        placeholder="Last Name"
                        required
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </div>

                    {/* <input
                      type="text"
                      id="lname"
                      className="form-control form-register"
                      value={lname}
                      placeholder="Last Name"
                      required
                      onChange={(e) => setLname(e.target.value)}
                    /> */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 mb-2 mt-2">
                    <div className="form-group form-price">
                      <span className="fa fa-mobile form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        value={mobile}
                        placeholder="Mobile"
                        required
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                    {/* <input
                      type="mobile"
                      id="mobile"
                      className="form-control form-register"
                      value={mobile}
                      placeholder="Mobile"
                      required
                      onChange={(e) => setMobile(e.target.value)}
                    /> */}
                  </div>

                  <div className="col-lg-12 mb-2 mt-2">
                    <div className="form-group form-price">
                      <span className="fa fa-envelope form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        id="email"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    {/* <input
                      type="email"
                      id="email"
                      placeholder="Email"
                      className="form-control form-register"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    /> */}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-6">
                    <button type="submit" className="btn btn-block btn-price">
                      Get A License
                      {loading ? <div className="spinner-border "></div> : null}
                    </button>
                  </div>
                  <div className="col-lg-6">
                    <button
                      type="reset"
                      onClick={handleBack}
                      className="btn btn-block btn-price btn-price-cencel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                {/* <div className="mx-2 mt-5 pt-5 pb-2">
                  <div className="d-flex text-center">
                    <button
                      type="submit"
                      className="btn-login mr-2 mb-2 licence-btn"
                    >
                      Get A License
                    </button>
                    <button
                      type="reset"
                      onClick={handleBack}
                      className="btn-login mr-2 mb-2 licence-btn-cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div> */}
                {/* {loading ? (
                        <Loading color="black" text="Loading..." />
                      ) : null} */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingForm;
