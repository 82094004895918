import { connect } from 'react-redux';

import withDeleteModal from '../hoc/withDeleteModal';

import { removeTimeline } from 'api/projects/deleteTimeline';
import { adminDeleteTimeLine } from 'actions/admin';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    reduxRemover: timeline => dispatch(adminDeleteTimeLine(timeline)),
  };
};

const DeleteTimelineModal = withDeleteModal({
  itemLabel: 'timeline',
  request: removeTimeline,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteTimelineModal);
