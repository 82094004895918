import { useState } from 'react';

export const useHttp = config => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { requestCallback, onLoad, onError } = config;

  const request = async data => {
    setLoading(true);
    return await requestCallback(data)
      .then(async res => {
        setLoading(false);
       await onLoad(res);
        return await res;
      })
      .catch(async res => {
        setLoading(false);
        setError(true);
       await  onError(res);
        return await res;
      });
  };

  return { loading, error, request };
};
