import React from 'react';
import PropTypes from 'prop-types';
import { MDBBox } from 'mdbreact';

const NoItems = props => {
  return <MDBBox className="text-center font-size-3 mt-3">{props.text}</MDBBox>;
};

NoItems.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NoItems;
