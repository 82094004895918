import React, { useRef, useContext, useMemo, useState } from "react";
import { MDBBox, MDBCol, MDBBtn } from "mdbreact";
import { url as urlApi } from "api";
import DownloadIcon from "assets/images/download";
import CollapsibleTable from "components/CollapsibleTable";
import CollapsibleEstimate from "components/CollapsibleEstimate";

import iconEquip from "assets/images/icn-53-equipment.svg";
import iconLabor from "assets/images/icn-53-labor.svg";
import iconMat from "assets/images/icn-53-material.svg";
import iconMoney from "assets/images/icn-53-money.svg";
import iconTime from "assets/images/icn-53-time.svg";
import { useSelector } from "react-redux";


import { QuantityContext } from "hooks/quantityContext";

const Navigate = ({ timeline, rule }) => {
  const defaultProps = {
    currencysymb: "",
    expanded: true,
    priceView: true,
  }
  // const [laborProps, setLaborProps] = useState(defaultProps);
  // const [equipProps, setEquipProps] = useState(defaultProps);
  // const [matProps, setMatProps] = useState(defaultProps);
  // const [totalSelectionTime, setTotalSelectionTime] = useState(0);
  // const [totalSelectionPrice, setTotalSelectionPrice] = useState(0);

  const { Currency, meshQuantityInfoRef } = useContext(QuantityContext);
  const isMountedRef = useRef(null);

  const usersRedux = useSelector((s) => s.user.users);

  let totalSelectionTime = 0;
  let totalSelectionPrice = 0;

  let laborProps = { ...defaultProps, currencysymb: Currency }
  let equipProps = { ...defaultProps, currencysymb: Currency }
  let matProps = { ...defaultProps, currencysymb: Currency }

  function downloadURI(uri, name) {
    fetch(uri).then((response) => response.blob())
      .then((blobresp) => {
        var blob = new Blob([blobresp], { type: "octet/stream" });
        var url = window.URL.createObjectURL(blob);

        var link = document.createElement("a");
        link.download = name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // delete link;
      })
  }

  useMemo(() => {
    if (rule && (rule.prices || rule.estimation_excel || rule.estimation_excel_awp) || !rule) {
      isMountedRef.current = true;
      if (isMountedRef.current && (meshQuantityInfoRef) && defaultProps) {

        matProps.data = [];
        equipProps.data = [];
        laborProps.data = [];

        var meshTime = 0;

        meshQuantityInfoRef.forEach(mesh => {



          if (mesh.LaborInfo && mesh.LaborInfo.length > 0) {

            meshTime = mesh.LaborInfo ? mesh.LaborInfo?.map(l => {

              laborProps.data.push({
                TotalCost: l.TotalPrice, //placeholder
                TotalTime: l.TotalTime, //placeholder
                Code: mesh.Name + "-" + mesh.Type,
                Description: l.Description,
              })

              if (l.length > 0) {
                return l.reduce((x, y) => Number(x.TotalTime) + Number(y.TotalTime))
              } else {
                return Number(l.TotalTime)
              }
            }) : 0;

          }


          if (mesh.EquipmentInfo && mesh.EquipmentInfo.length > 0) {

            mesh.EquipmentInfo.forEach(info => {
              equipProps.data.push({
                TotalCost: info.TotalPrice, //placeholder
                TotalTime: info.TotalTime, //placeholder
                Code: mesh.Name,
                Description: info.Description,
              })
            })
          }


          matProps.data.push({
            TotalCost: mesh.TotalPrice ? Number(mesh.TotalPrice) : 0, //placeholder
            TotalTime: meshTime ? Number(meshTime) : 0, //placeholder
            Code: mesh.Name,
            Description: mesh.MaterialNames?.join(" | "),
          })






          totalSelectionTime += meshTime ? Number(meshTime) : 0
          totalSelectionPrice += mesh.TotalPrice ? Number(mesh.TotalPrice) : 0



        });


      }
      return () => (isMountedRef.current = false);
    }
  }, [laborProps, equipProps, matProps, meshQuantityInfoRef, defaultProps]);

  // console.log({matProps})

  const dataRendered = () => {
    return (
      <MDBBox className="estimation-detail-itemView">
        <MDBCol className="col-cols-lg-3 col-cols-sm-2 col-cols-1 mt-3">
          <CollapsibleEstimate
            type="labor"
            {...laborProps}
            icon={iconLabor}
            moneyIcon={iconMoney}
            timeIcon={iconTime}
            myClassName="modal-sideShare-table"
          ></CollapsibleEstimate>

          <CollapsibleEstimate
            type="material"
            {...matProps}
            icon={iconMat}
            moneyIcon={iconMoney}
            timeIcon={iconTime}
            totalSelectionTime={totalSelectionTime}
            totalSelectionPrice={totalSelectionPrice}
            myClassName="modal-sideShare-table"
          ></CollapsibleEstimate>

          <CollapsibleEstimate
            type="equipment"
            {...equipProps}
            icon={iconEquip}
            moneyIcon={iconMoney}
            timeIcon={iconTime}
            myClassName="modal-sideShare-table"
          ></CollapsibleEstimate>
        </MDBCol>
      </MDBBox>
    );
  }


  // for (row of LaborinfoRef) for (e of row) LaborArray.push(e);


  // tempProps.data[0].Description = Laborinfo == null ? "null" : "Add info";

  return (
    <>
      <div className="share-files d-flex justify-content-between align-items-center">
        {timeline?.doneBy && (
          <div className="by">
            Exported by: <br /> <span>{timeline?.doneBy?.name + ' ' + timeline?.doneBy?.lastName}</span>
          </div>
        )}

        <div>
          {/* {timeline?.pdf && (!rule || rule.takeoff) ? (
            <a
              href={`${urlApi}/${timeline.pdf}`}
              target="_blank"
              rel="noopener noreferrer"
              download
              className=""
            >
              <DownloadIcon color="#2C3E50" />
              <span className="ml-1">Full takeoff.pdf</span>
              <span className="ml-5 font-small">
                {(timeline.pdfInfo.size / 1000).toFixed(2)}kb
              </span>
            </a>
          ) : null} */}
          {timeline?.excelQuant && (!rule || rule.quantity_excel) ? (
            <MDBBtn

              size="sm"
              color=""

              title="BOQ.xlsx"

              position="right"

              className="bg-white modal3d-tool-btn"

              onClick={() => downloadURI(`${urlApi}/${timeline.excelQuant}`, "BOQ.xlsx")}

            >
              <div>
                <DownloadIcon color="#2C3E50" />
                <b> <span className="ml-1">BOQ.xlsx</span>
                </b>
              </div>

              <span className="ml-5">
                {(timeline.excelQuantInfo.size / 1000).toFixed(2)}kb
              </span>

            </MDBBtn>
          ) : null}
          {timeline?.excel && (!rule || rule.estimation_excel) ? (


            <MDBBtn
              size="sm"
              color=""

              title="Divisions.xlsx"

              position="right"

              className="bg-white modal3d-tool-btn"

              onClick={() => downloadURI(`${urlApi}/${timeline.excel}`, "Divisions.xlsx")}

            >
              <div>
                <DownloadIcon color="#2C3E50" />
                <b>   <span className="ml-1 ">Divisions.xlsx</span>   </b>
              </div>

              <span className="ml-5 ">
                {(timeline.excelInfo.size / 1000).toFixed(2)}kb
              </span>
            </MDBBtn>
          ) : null}
          {timeline?.excelAwp && (!rule || rule.estimation_excel_awp) ? (

            <MDBBtn
              size="sm"
              color=""

              title="AWP.xlsx"

              position="right"

              className="bg-white modal3d-tool-btn"
              onClick={() => downloadURI(`${urlApi}/${timeline.excelAwp}`, "AWP.xlsx")}

            >
              <div>
                <DownloadIcon color="#2C3E50" />
                <b>   <span className="ml-1">AWP.xlsx</span>   </b>
              </div>

              <span className="ml-5 ">
                {(timeline.excelAwpInfo.size / 1000).toFixed(2)}kb
              </span>
            </MDBBtn>
          ) : null}
        </div>
      </div>
      {((rule && (rule.prices || rule.estimation_excel || rule.estimation_excel_awp)) || !rule) && (dataRendered())}
    </>
  );
};
export default Navigate;
