import React from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
} from 'mdbreact';

const NoMeshModal = props => {
  return (
    <MDBModal isOpen={props.isOpen} toggle={props.toggle} centered>
      <MDBModalHeader toggle={props.toggle}>No mesh</MDBModalHeader>
      <MDBModalBody className="font-size-1">
        There is no model associated with this component.
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="blue darken-2" size="sm" onClick={props.toggle}>
          Ok
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default NoMeshModal;
