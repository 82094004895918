import React, { useEffect, useState } from "react";
import HealthIcon from "assets/images/icons/Health (1).png";
import EyeImg from "assets/images/icons/eye-fill.png";
import DeleteImg from "assets/images/icons/delete-bin-6-fill.png";
import User from "assets/images/icons/user.png";
import { useHistory } from "react-router-dom";
//
import { useSelector, useDispatch } from "react-redux";
import { url } from "api";
import { Modal } from "antd";
import { RequestCard } from "pages/configuration/requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { setAllHealthReports, setFeatureRequest } from "actions/user";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";
import { deleteReport } from "api/healthCheck/deleteReport";
import addNotification from "helpers/notify";
import Loading from "components/ui-components/loading";
import moment from "moment";
import DefaultImage from "assets/images/user.png";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import { FROM_NEWER_TO_OLDER, FROM_OLDER_TO_NEWER, sortByDate } from "helpers/sortByDate";
import { eventTrack } from "helpers/ga4Helper";

export default function HealthCheckListView({ data }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const featureRequestRedux = useSelector((s) => s.user.featureRequest);
  const healthReportsRedux = useSelector((s) => s.user.healthReports);
  const [requestType, setRequestType] = useState("");
  const [requestModal, setRequestModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [reportsToDelete, setReportsToDelete] = useState(null);


  const [healthReportList, setHealthReportList] = useState([]);
  const [sortedToNewer, setSortedToNewer] = useState(false);


  useEffect(() => {
    setHealthReportList(sortByDate(data, FROM_NEWER_TO_OLDER));
  }, [data]);

  const STATUS = {
    PASS: "Pass",
    FAILED: "Fail",
    GREAT: "Great",
  };

  const sort = () => {
    if (sortedToNewer) {
      setHealthReportList(sortByDate(data, FROM_OLDER_TO_NEWER));
    } else {
      setHealthReportList(sortByDate(data, FROM_NEWER_TO_OLDER));
    }
  };

  const deletePopupOpen = (item) => {
    setReportsToDelete(item);
    setOpenDeleteModal(true);
  };

  const handleDeleteReport = async () => {
    setLoading(true);
    const res = await deleteReport({ _id: reportsToDelete?._id });
    if (!res.error) {
      const filterResponse = healthReportsRedux?.filter(
        (item) => item?._id !== res._id
      );
      dispatch(setAllHealthReports(filterResponse));
      setLoading(false);
      setOpenDeleteModal(false);
      addNotification("Report Deleted Successfully! ", "success");
    } else {
      addNotification(res?.message, "danger");
    }
  };

  const requestAccess = async () => {
    setLoading(true);
    const res = await createFeatureRequest(userInfoRedux.id, {
      companyId: userInfoRedux.company,
      permission: requestType,
      userId: userInfoRedux.id,
    });
    if (res.status === 200) {
      setLoading(false);
      dispatch(
        setFeatureRequest(res?.map((feature) => feature?.requestedFeature))
      );
    }
  };

  const fetchImages = async (img) => {
    try {
      const url = await extractImageFromZip(img);
      return url;
    } catch (error) {
      // console.error("Error fetching image:", error);
      return null; // Or handle the error as needed
    }
  };

  return (
    <div className="mt-3">
      {/* page title */}
      <div>
        <div className="assestmgmt-table-div"> 
          <table className="bid-table bid-table-small sticky-table-header border-top-none">
            <thead>
              <tr>
                <th>Model Name</th>
                <th>Projects</th>
                <th>Exported By</th>
                <th>Report Date</th>
                <th>Model Health Status</th>
                <th>Model Health Result</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {healthReportList?.length > 0 ? (
                healthReportList?.map((item, key) => (
                  <tr key={key}>
                    <td>{item?.modelName}</td>
                    <td>
                      <div className="users-profile-list">
                        {item?.projects?.map((i, index) => (
                          <img
                            src={fetchImages(i?.img)}
                            alt="grp"
                            key={index}
                            className="u-profile"
                          />
                        ))}
                        {item?.projects?.length > 4 && (
                          <div className="u-profile">
                            +$
                            {item?.projects?.length - 1}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <img
                          src={extractImageFromZip(item?.doneBy?.profilePhoto)}
                          className="list-user-img"
                          onError={(e) => (e.target.src = DefaultImage)}
                        />
                        <p className="mb-0 f-14 ml-2 poppins-regular text-black">
                          {item?.doneBy?.name}
                        </p>
                      </div>
                    </td>
                    <td>{moment(item?.date).format("DD MMMM YYYY")}</td>
                    <td>
                      {" "}
                      <div className="d-flex">
                        <p className="number-count bg-light-success text-success mr-2">
                          {item?.reportDetails?.passedRules?.length}
                        </p>
                        <p className="number-count bg-light-warning text-warning mr-2">
                          {item?.summary?.healthStatus?.normalFailedRules}
                        </p>
                        <p className="number-count bg-light-danger text-danger">
                          {item?.summary?.healthStatus?.criticalFailedRules}
                        </p>
                      </div>
                    </td>
                    <td>
                      <p
                        className={`${"number-count px-3 health-result-status"}
                    ${
                      item?.summary?.reportStatus === STATUS.PASS
                        ? "bg-light-warning text-warning"
                        : item?.summary?.reportStatus === STATUS.FAILED
                        ? "bg-light-danger text-danger"
                        : item?.summary?.reportStatus === STATUS.GREAT
                        ? "bg-light-success text-success"
                        : ""
                    }
                    `}
                      >
                        {item?.summary?.reportStatus}
                      </p>
                    </td>
                    <td
                      onClick={() => {
                        history.push(`./model-health/${item?._id}/`);
                        eventTrack(
                          "View-Detailed-Health-Report-Action",
                          "View-Detailed-Health-Report",
                          userInfoRedux?.name,
                          "+1",
                          false,
                          "+1"
                        );
                      }}
                      className="cursor-pointer"
                    >
                      <img src={EyeImg} alt="eye" className="action-icon" />
                    </td>
                    <td>
                      <img
                        src={DeleteImg}
                        alt="delete"
                        className="action-icon cursor-pointer"
                        onClick={() => {
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.MODAL_HEALTH_REPORT_DELETE
                          )
                            ? deletePopupOpen(item)
                            : setRequestModal(true);
                          setRequestType(
                            ALL_PERMISSIONS.MODAL_HEALTH_REPORT_DELETE
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No record found</td>
                </tr>
              )}
            </tbody>
          </table> 
        </div>
      </div>

      {/* delete modal check */}
      <MDBModal
        isOpen={openDeleteModal}
        toggle={() => setOpenDeleteModal(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpenDeleteModal(false)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Report ?</p>
          <p className="delete-modal-label">
            Are you sure you want to delete this Report? This action cannot be
            undone.
          </p>
        </MDBModalBody>
        {loading ? <Loading color="black" text="Deleting..." /> : null}
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpenDeleteModal(false);
                setReportsToDelete("");
              }}
              color=""
              disabled={loading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={() => {handleDeleteReport()
                eventTrack(
                  "Delete-Health-Report-Action",
                  "Delete-Health-Report",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
              disabled={loading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      {/* request Access Modal */}
      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequestRedux?.includes(requestType)}
          loading={loading}
          icon={
            featureRequestRedux?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequestRedux?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequestRedux?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </div>
  );
}
