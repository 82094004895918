import React from "react";
import { MDBBox, MDBBtn } from "mdbreact";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import back from "assets/images/icons/back.svg";
import logo from "assets/images/logo/logo1.png";
import { useLogout } from "hooks/useLogout";
import { slide as MobileMenu } from "react-burger-menu";
import { useHistory, useLocation } from "react-router";
import { Nav } from "reactstrap";

import logout from "assets/images/icons/login-box-fill.png";

import NewDrawer from "pages/configuration/newDrawer";
import "./Header.scss";

import { Avatar } from "antd";
import Hamburger from "assets/images/icons/hamburger.png";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarStatus } from "actions/user";
import DefaultImage from "assets/images/user.png";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import { eventTrack } from "helpers/ga4Helper";

var styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "20px",
    height: "16px",
    top: "25%",
    right: "20px",
  },
  bmBurgerBars: {
    background: "#000000",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "50px",
    width: "50px",
    top: "20px",
    right: "10px",
  },
  bmCross: {
    background: "#000000",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%", //vh
    top: "0px",
  },
  bmMenu: {
    background: "#fff",
    padding: "23px",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "black !important",
    padding: "0em",
  },
  bmItem: {
    display: "inline-block",
    listStyle: "none",
    paddingTop: "10px !important",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.6)",
    opacity: 1,
    width: "100vw",
  },
};

const Header = (props) => {
  const [show, setShow] = useState(false);
  const logOut = useLogout();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const sidebarStatusRedux = useSelector((s) => s.user.sidebar);

  const [initialUrl, setInitialUrl] = useState("");
  const [profilePic, setProfilePic] = useState(null);

  const location = useLocation();

  const learning = location.pathname.startsWith("/learning");

  useEffect(() => {
    setInitialUrl(pathname);
  }, []);

  useEffect(() => {
    (async () => {
      const extractedImg = await extractImageFromZip(
        userInfoRedux?.profilePhoto
      );
      setProfilePic(extractedImg);
    })();
  }, [userInfoRedux]);

  const { setModal, anonymous } = props;
  const handleLogout = () => {
    logOut();
    history.push("/");
  };

  const navigateToLogin = () => {
    history.push("/authorization");
  };

  const HeaderComp = () => {
    return (
      <>
        <div
          className="d-flex align-items-center cursor-pointer header-profile-icon desktop-show"
          onClick={() => {
            history.push("/profile")
            {
              eventTrack(
                "Profile-Navigation-Action",
                "Profile-Navigation",
                userInfoRedux?.name,
                "+1",
                false,
                "+1"
              );
            }
            
          }}
        >
          <img
            src={profilePic}
            onError={(e) => (e.target.src = DefaultImage)}
          />
          <p className="mb-0 font-weight-600 f-16 text-black ml-2 roboto">
            {userInfoRedux?.name ?? "Guest"}
          </p>
        </div>

        <div className="vr mr-3 ml-5"></div>
        {learning && (
          <div className="learning-container-flex">
            <MDBBtn
              className="header-btn bg-white border-bid text-bid shadow-none pl-4"
              color=""
              onClick={() => {
                history.push("/learning");
              }}
            >
              <i className="fa fa-question f-15" />
              <span className="ml-3 pr-2">FAQ</span>
            </MDBBtn>

            <MDBBtn
              className="header-btn bg-white border-bid text-bid shadow-none pl-4"
              color=""
              onClick={() => {
                history.push("/learning/articles")
                {
                  eventTrack(
                    "Articles-Navigation-Action",
                    "Articles-Navigation",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }
              }}
            >
              <i className="fa fa-book f-15" />
              <span className="ml-3 pr-2">Articles</span>
            </MDBBtn>
          </div>
        )}

        {pathname !== "/dashboard" && (
          <MDBBtn
            color=""
            id="backButton"
            className="header-btn bg-bid border-bid text-white shadow-none pl-4 desktop-show"
            onClick={() => {
              history.goBack();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
            >
              <path
                d="M5.66087 0.827637L0.488281 6.00025L5.66087 11.1728L6.83937 9.99433L2.84528 6.00025L6.83937 2.00614L5.66087 0.827637Z"
                fill="white"
              />
            </svg>
            <span className="ml-3 pr-2">Back</span>
          </MDBBtn>
        )}

        {anonymous ? (
          <MDBBtn
            onClick={() => navigateToLogin()}
            color=""
            className="header-btn border-bid text-bid shadow-none pl-3"
          >
            <img src={logout} />
            <span>Login</span>
          </MDBBtn>
        ) : (
          <MDBBtn
            onClick={handleLogout}
            color=""
            className="header-btn border-bid text-bid shadow-none pl-3"
          >
            <img src={logout} />
            <span>Logout</span>
          </MDBBtn>
        )}
      </>
    );
  };

  const MobileHeaderComp = () => {
    return (
      <>
        <div
          className="d-flex align-items-center cursor-pointer header-profile-icon desktop-show"
          onClick={() => {
            history.push("/profile");
          }}
        >
          <img
            src={profilePic}
            onError={(e) => (e.target.src = DefaultImage)}
          />
          <p className="mb-0 font-weight-600 f-16 text-black ml-2 roboto">
            {userInfoRedux?.name ?? "Guest"}
          </p>
        </div>

        <div className="mobile-sidebar-btngroup">
          <div>
          {learning && (
            <div className="learning-container-flex">
              <MDBBtn
                color=""
                onClick={() => {
                  history.push("/learning")
                  {
                    eventTrack(
                      "FAQ-Navigation-Action",
                      "FAQ-Navigation",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
              >
                <i className="fa fa-question f-15" />
                <span className="ml-3 pr-2">FAQ</span>
              </MDBBtn>

              <MDBBtn
                color=""
                onClick={() => {
                  history.push("/learning/articles");
                  {
                    eventTrack(
                      "Articles-Navigation-Action",
                      "Articles-Navigation",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
              >
                <i className="fa fa-book f-15" />
                <span className="ml-3 pr-2">Articles</span>
              </MDBBtn>
            </div>
          )}

          {pathname !== "/dashboard" && (
            <MDBBtn
              color=""
              onClick={() => {
                history.goBack();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M5.66087 0.827637L0.488281 6.00025L5.66087 11.1728L6.83937 9.99433L2.84528 6.00025L6.83937 2.00614L5.66087 0.827637Z"
                  fill="white"
                />
              </svg>
              <span className="ml-3 pr-2">Back</span>
            </MDBBtn>
          )}

          {anonymous ? (
            <MDBBtn onClick={() => navigateToLogin()} color="">
              <img src={logout} />
              <span>Login</span>
            </MDBBtn>
          ) : (
            <MDBBtn onClick={handleLogout} color="">
              <img src={logout} />
              <span className="ml-3 pr-2">Logout</span>
            </MDBBtn>
          )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <NewDrawer
        open={sidebarStatusRedux}
        // setOpen={setOpen}
        anonymous={anonymous}
        setModal={setModal}
      />
      <MDBBox className="position-sticky header centerMargin">
        <MDBBox className="custom-container align-items-center ">
          <span className="d-flex align-items-center ">
            <div className="hamburger-div">
              <img
                src={Hamburger}
                className="w-20px cursor-pointer"
                onClick={() => dispatch(setSidebarStatus(true))}
              />
            </div>
            <img
              src={logo}
              className="header-logo "
              onClick={() => history.push("/dashboard")}
            />

            <div className="btn-tool-group mt-1 desktop-show">
              <button
                onClick={() => {
                  setModal("DownloadSoftware");
                }}
                className="bg-bid px-3 mr-0"
              >
                <i className="fa fa-download mr-2"></i>
                BidLight 3.0
              </button>
            </div>
          </span>
          <MDBBox className="justify-content-between align-items-center header-content desktop-show">
            <div className="d-flex align-items-center">
              <HeaderComp />
            </div>
          </MDBBox>
        </MDBBox>

        <Nav className="ml-auto pr-3 mobile-show navDropToggle" navbar>
          <MobileMenu width="70%" right styles={styles}>
            <MobileHeaderComp />
          </MobileMenu>
        </Nav>
      </MDBBox>
    </>
  );
};

Header.propTypes = {
  setModal: PropTypes.func,
};

export default Header;
