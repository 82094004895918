import React, { createContext, useContext } from "react";
import axios from "axios";
import { baseURL } from "../util/fetcher";
import { tokenRef } from "api";

const AxiosContext = createContext(null);

const AuthAxiosLearning = ({ children }) => {
  const authAxios = axios.create({
    baseURL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(tokenRef)}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const code = error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        console.log("error code", code);
      }
      return Promise.reject(error);
    }
  );

  return (
    <AxiosContext.Provider value={authAxios}>
      {children}
    </AxiosContext.Provider>
  );
};

const useAuthAxios = () => {
  return useContext(AxiosContext);
};

export { AuthAxiosLearning, useAuthAxios };
