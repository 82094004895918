import { useDispatch } from 'react-redux';
import {
  setProjects,
  setUser,
  setCompanyUsers,
  addPermissionIsUser,
  setCompanyPermission,
  setAllPermission
} from "../actions/user";

import { mainAdminSetCompanies } from '../actions/main-admin';

import { adminSetUsers } from '../actions/admin';
import { setCatalogsTree } from '../actions/user';
import { all_permissionsRef, company_permissionsRef, tokenRef, url, user_permissionsRef } from 'api';

export const useLogout = () => {
  const dispatch = useDispatch();
  
  return () => {
    localStorage.removeItem(tokenRef);
    localStorage.removeItem(user_permissionsRef);
    localStorage.removeItem(company_permissionsRef);
    localStorage.removeItem(all_permissionsRef); 
    localStorage.removeItem('exporters');

    dispatch(setUser(null));
    dispatch(setProjects(null));
    dispatch(setCompanyUsers(null));
    dispatch(adminSetUsers(null));
    dispatch(addPermissionIsUser([]));
    dispatch(setAllPermission([]))
    dispatch(setCompanyPermission([]))

    dispatch(setCatalogsTree(null))
    dispatch({
      type: 'ADMIN_SET_PROJECTS',
      payload: null,
    });
    dispatch({
      type: 'ADMIN_SET_COMPANY',
      payload: null,
    });
    dispatch({
      type: 'MAIN_ADMIN_SET_ALL_COMPANIES_PROJECTS',
      payload: null,
    });
    dispatch({
      type: 'MAIN_ADMIN_SET_ALL_USERS',
      payload: null,
    });
    dispatch(mainAdminSetCompanies(null));
  };
};
