import React from "react";
import LoaderImg from "assets/images/logo/Bidlight logo.png";

export default function Loader() {
  return (
    <div className="loader-container">
      <div className="inner-loader-container">
      <img src={LoaderImg} alt="loader" />
      <div className="spinner-border text-light d-block mx-auto" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      </div>
    </div>
  );
}
