import { getUserPermission } from "api/permission/getPermissions";
import { addPermissionIsUser } from "../actions/user";
import { setCompanyPermission } from "../actions/user";
import { setAllPermission } from "../actions/user";
import { getUserCompanyPermission } from "api/permission/getPermissions";
import { all_permissionsRef, company_permissionsRef, url, user_permissionsRef } from "api";

export const initPermission = async ({ Store, decoded }) => {
  if (
    localStorage.getItem(user_permissionsRef) &&
    localStorage.getItem(company_permissionsRef) &&
    localStorage.getItem(all_permissionsRef)
  ) {
    const user = localStorage.getItem(user_permissionsRef).split(",");
    const company = localStorage.getItem(company_permissionsRef).split(",");
    const all = localStorage.getItem(all_permissionsRef).split(",");

    Store.dispatch(addPermissionIsUser(user));
    Store.dispatch(setCompanyPermission(company));
    Store.dispatch(setAllPermission(all));
  } else {
    const res = await getUserCompanyPermission(decoded?.id, decoded?.company);
    if (res) {
      localStorage.setItem(user_permissionsRef, res?.user_permissions);
      localStorage.setItem(company_permissionsRef, res?.company_permissions);
      localStorage.setItem(all_permissionsRef, res?.all_permissions);
      //
      Store.dispatch(addPermissionIsUser(res?.user_permissions));
      Store.dispatch(setCompanyPermission(res?.company_permissions));
      Store.dispatch(setAllPermission(res?.all_permissions));
    }
  }
};
