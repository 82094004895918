import React, { useEffect, useState } from "react";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "bootstrap/dist/js/bootstrap.bundle.min";

const html = `
 <!DOCTYPE html>
 <!-- saved from url=(0041)https://bidlight.com/privacy-->
 <html lang="en-US" class="js no-svg"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
 
 <title>Privacy Policy – BidLight</title>
 <script type="text/javascript">function heateorSssLoadEvent(e) {var t=window.onload;if (typeof window.onload!="function") {window.onload=e}else{window.onload=function() {t();e()}}};	var heateorSssSharingAjaxUrl = 'https://Bidlight.com/wp-admin/admin-ajax.php', heateorSssCloseIconPath = 'https://Bidlight.com/wp-content/plugins/sassy-social-share/public/../images/close.png', heateorSssPluginIconPath = 'https://Bidlight.com/wp-content/plugins/sassy-social-share/public/../images/logo.png', heateorSssHorizontalSharingCountEnable = 0, heateorSssVerticalSharingCountEnable = 1, heateorSssSharingOffset = -10; var heateorSssMobileStickySharingEnabled = 1;var heateorSssCopyLinkMessage = "Link copied.";var heateorSssReduceVerticalSvgHeight = true;var heateorSssUrlCountFetched = [], heateorSssSharesText = 'Shares', heateorSssShareText = 'Share';function heateorSssPopup(e) {window.open(e,"popUpWindow","height=400,width=600,left=400,top=100,resizable,scrollbars,toolbar=0,personalbar=0,menubar=no,location=no,directories=no,status")}</script>			<style type="text/css">
                     .heateor_sss_horizontal_sharing .heateorSssSharing{
                             color: #fff;
                         border-width: 200px;
             border-style: solid;
             border-color: transparent;
         }
                 .heateor_sss_horizontal_sharing .heateorSssTCBackground{
             color:#666;
         }
                 .heateor_sss_horizontal_sharing .heateorSssSharing:hover{
                         border-color: transparent;
         }
         .heateor_sss_vertical_sharing .heateorSssSharing{
                             color: #fff;
                         border-width: 0px;
             border-style: solid;
             border-color: transparent;
         }
                 .heateor_sss_vertical_sharing .heateorSssTCBackground{
             color:#666;
         }
                 .heateor_sss_vertical_sharing .heateorSssSharing:hover{
                         border-color: transparent;
         }
                         div.heateor_sss_vertical_sharing .heateorSssSharingSvg{height:70%;margin-top:6px}div.heateor_sss_vertical_sharing .heateor_sss_square_count{line-height:15.2px;}
                 @media screen and (max-width:783px) {.heateor_sss_vertical_sharing{display:none!important}}div.heateor_sss_mobile_footer{display:none;}@media screen and (max-width:783px) {div.heateor_sss_bottom_sharing{width:100%!important;left:0!important;}div.heateor_sss_bottom_sharing li{width:14.285714285714% !important;}div.heateor_sss_bottom_sharing .heateorSssSharing{width: 100% !important;}div.heateor_sss_bottom_sharing div.heateorSssTotalShareCount{font-size:1em!important;line-height:28px!important}div.heateor_sss_bottom_sharing div.heateorSssTotalShareText{font-size:.7em!important;line-height:0px!important}div.heateor_sss_mobile_footer{display:block;height:40px;}.heateor_sss_bottom_sharing{padding:0!important;display:block!important;width: auto!important;bottom:-10px!important;top: auto!important;}.heateor_sss_bottom_sharing .heateor_sss_square_count{line-height: inherit;}.heateor_sss_bottom_sharing .heateorSssSharingArrow{display:none;}.heateor_sss_bottom_sharing .heateorSssTCBackground{margin-right: 1.1em !important}}			</style>
             <meta name="robots" content="max-image-preview:large">
 <link rel="dns-prefetch" href="https://www.google.com/">
 <link rel="dns-prefetch" href="https://fonts.googleapis.com/">
 <link rel="dns-prefetch" href="https://s.w.org/">
 <link href="https://fonts.gstatic.com/" crossorigin="" rel="preconnect">
 <link rel="alternate" type="application/rss+xml" title="BidLight » Feed" href="https://Bidlight.com/feed/">
 <link rel="alternate" type="application/rss+xml" title="BidLight » Comments Feed" href="https://Bidlight.com/comments/feed/">
         <script type="text/javascript">
             window._wpemojiSettings = {"baseUrl":"https:\/\/s.w.org\/images\/core\/emoji\/13.0.1\/72x72\/","ext":".png","svgUrl":"https:\/\/s.w.org\/images\/core\/emoji\/13.0.1\/svg\/","svgExt":".svg","source":{"concatemoji":"https:\/\/Bidlight.com\/wp-includes\/js\/wp-emoji-release.min.js?ver=5.7.2"}};
             !function(e,a,t){var n,r,o,i=a.createElement("canvas"),p=i.getContext&&i.getContext("2d");function s(e,t){var a=String.fromCharCode;p.clearRect(0,0,i.width,i.height),p.fillText(a.apply(this,e),0,0);e=i.toDataURL();return p.clearRect(0,0,i.width,i.height),p.fillText(a.apply(this,t),0,0),e===i.toDataURL()}function c(e){var t=a.createElement("script");t.src=e,t.defer=t.type="text/javascript",a.getElementsByTagName("head")[0].appendChild(t)}for(o=Array("flag","emoji"),t.supports={everything:!0,everythingExceptFlag:!0},r=0;r<o.length;r++)t.supports[o[r]]=function(e){if(!p||!p.fillText)return!1;switch(p.textBaseline="top",p.font="600 32px Arial",e){case"flag":return s([127987,65039,8205,9895,65039],[127987,65039,8203,9895,65039])?!1:!s([55356,56826,55356,56819],[55356,56826,8203,55356,56819])&&!s([55356,57332,56128,56423,56128,56418,56128,56421,56128,56430,56128,56423,56128,56447],[55356,57332,8203,56128,56423,8203,56128,56418,8203,56128,56421,8203,56128,56430,8203,56128,56423,8203,56128,56447]);case"emoji":return!s([55357,56424,8205,55356,57212],[55357,56424,8203,55356,57212])}return!1}(o[r]),t.supports.everything=t.supports.everything&&t.supports[o[r]],"flag"!==o[r]&&(t.supports.everythingExceptFlag=t.supports.everythingExceptFlag&&t.supports[o[r]]);t.supports.everythingExceptFlag=t.supports.everythingExceptFlag&&!t.supports.flag,t.DOMReady=!1,t.readyCallback=function(){t.DOMReady=!0},t.supports.everything||(n=function(){t.readyCallback()},a.addEventListener?(a.addEventListener("DOMContentLoaded",n,!1),e.addEventListener("load",n,!1)):(e.attachEvent("onload",n),a.attachEvent("onreadystatechange",function(){"complete"===a.readyState&&t.readyCallback()})),(n=t.source||{}).concatemoji?c(n.concatemoji):n.wpemoji&&n.twemoji&&(c(n.twemoji),c(n.wpemoji)))}(window,document,window._wpemojiSettings);
         </script>
         <style type="text/css">
 img.wp-smiley,
 img.emoji {
     display: inline !important;
     border: none !important;
     box-shadow: none !important;
     height: 1em !important;
     width: 1em !important;
     margin: 0 .07em !important;
     vertical-align: -0.1em !important;
     background: none !important;
     padding: 0 !important;
 }
 </style>
     <link rel="stylesheet" id="validate-engine-css-css" href="./PrivacyPolicy_files/validationEngine.jquery.css" type="text/css" media="all">
 <link rel="stylesheet" id="wp-block-library-css" href="./PrivacyPolicy_files/style.min.css" type="text/css" media="all">
 <link rel="stylesheet" id="contact-form-7-css" href="./PrivacyPolicy_files/styles.css" type="text/css" media="all">
 <link rel="stylesheet" id="twentyseventeen-fonts-css" href="./PrivacyPolicy_files/css" type="text/css" media="all">
 <link rel="stylesheet" id="twentyseventeen-style-css" href="./PrivacyPolicy_files/style.css" type="text/css" media="all">
 <!--[if lt IE 9]>
 <link rel='stylesheet' id='twentyseventeen-ie8-css'  href='https://Bidlight.com/wp-content/themes/twentyseventeen/assets/css/ie8.css?ver=1.0' type='text/css' media='all' />
 <![endif]-->
 <link rel="stylesheet" id="heateor_sss_frontend_css-css" href="./PrivacyPolicy_files/sassy-social-share-public.css" type="text/css" media="all">
 <link rel="stylesheet" id="heateor_sss_sharing_default_svg-css" href="./PrivacyPolicy_files/sassy-social-share-svg.css" type="text/css" media="all">
 <script type="text/javascript" src="./PrivacyPolicy_files/jquery.min.js.download" id="jquery-core-js"></script>
 <script type="text/javascript" src="./PrivacyPolicy_files/jquery-migrate.min.js.download" id="jquery-migrate-js"></script>
 <script type="text/javascript" src="./PrivacyPolicy_files/adblock-detector.min.js.download" id="abd-adblock-detector-js"></script>
 <script type="text/javascript" src="./PrivacyPolicy_files/advertisement.min.js.download" id="abd-fake-ad-js"></script>
 <script type="text/javascript" src="./PrivacyPolicy_files/public-view.js.download" id="abd-public-view-js"></script>
 <!--[if lt IE 9]>
 <script type='text/javascript' src='https://Bidlight.com/wp-content/themes/twentyseventeen/assets/js/html5.js?ver=3.7.3' id='html5-js'></script>
 <![endif]-->
 <link rel="https://api.w.org/" href="https://Bidlight.com/wp-json/"><link rel="alternate" type="application/json" href="https://Bidlight.com/wp-json/wp/v2/pages/594"><link rel="EditURI" type="application/rsd+xml" title="RSD" href="https://Bidlight.com/xmlrpc.php?rsd">
 <link rel="wlwmanifest" type="application/wlwmanifest+xml" href="https://Bidlight.com/wp-includes/wlwmanifest.xml"> 
 <meta name="generator" content="WordPress 5.7.2">
 <link rel="canonical" href="https://Bidlight.com/privacy-policy/">
 <link rel="shortlink" href="https://Bidlight.com/?p=594">
 <link rel="alternate" type="application/json+oembed" href="https://Bidlight.com/wp-json/oembed/1.0/embed?url=https%3A%2F%2FBidlight.com%2Fprivacy-policy%2F">
 <link rel="alternate" type="text/xml+oembed" href="https://Bidlight.com/wp-json/oembed/1.0/embed?url=https%3A%2F%2FBidlight.com%2Fprivacy-policy%2F&amp;format=xml">
 <link rel="icon" href="https://Bidlight.com/wp-content/uploads/2018/07/cropped-ico-32x32.png" sizes="32x32">
 <link rel="icon" href="https://Bidlight.com/wp-content/uploads/2018/07/cropped-ico-192x192.png" sizes="192x192">
 <link rel="apple-touch-icon" href="https://Bidlight.com/wp-content/uploads/2018/07/cropped-ico-180x180.png">
 <meta name="msapplication-TileImage" content="https://Bidlight.com/wp-content/uploads/2018/07/cropped-ico-270x270.png">
 
 <!-- BEGIN ExactMetrics v5.3.5 Universal Analytics - https://exactmetrics.com/ -->
 <script>
 (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
     (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
 })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
   ga('create', 'UA-119671347-1', 'auto');
   ga('send', 'pageview');
 </script>
 <!-- END ExactMetrics Universal Analytics -->
 
 
 
 <div id="page" class="site" style="color:blue;text-align:left; padding-left:50px">

 
     <header id="masthead" class="site-header" role="banner">
 
         <div class="custom-header">
 
         <div class="custom-header-media">
            
 
     <div class="site-branding">
     <div class="wrap">
 
       
         <div class="site-branding-text">
                             <p class="site-title"><a href="https://Bidlight.com/" rel="home">BidLight</a></p>
             
                             <p class="site-description">Automatic Estimation</p>
                     </div><!-- .site-branding-text -->
 
         
     </div><!-- .wrap -->
 </div><!-- .site-branding -->
 
 </div><!-- .custom-header -->
 
         
     </header><!-- #masthead -->
     
    
     <div class="site-content-contain" >
         <div id="content" class="site-content">
 
 <div class="wrap">
     <div id="primary" class="content-area">
         <main id="main" class="site-main" role="main">
 
             
 <article id="post-594" class="post-594 page type-page status-publish hentry">
     <header class="entry-header">
         <h1 class="entry-title">Privacy Policy</h1>			</header><!-- .entry-header -->
         <body >
     <div class="entry-content" >
         <p>INTRODUCTION</p>
 <p>This privacy notice provides you with details of how we collect and process your personal data through your use of our site BidLight.com, including any information you may provide through our site when you purchase a product or service, sign up to our newsletter or take part in a prize draw or competition.</p>
 <p>By providing us with your data, you warrant to us that you are over 13 years of age.</p>
 <p>Trading as BidLight  is the data controller and we are responsible for your personal data (referred to as “we”, “us” or “our” in this privacy notice).</p>
 <p>Contact Details</p>
 <p>Our full details are:</p>
 <p>Full name of legal entity: BidLight, INC, trading as BidLight .</p>
 <p>Email address: available via our contact page.</p>
 <p>Postal address: 701&nbsp; E Apache Blvd, Tempe, AZ 85281</p>
 <p>If you are not happy with any respect of how we collect and use your data, please contact us so we can try to resolve the issue for you.</p>
 <p>It is very important that the information we hold about you is accurate and up to date. Please let us know if at any time your personal information changes by emailing us via our contact page.</p>
 <p>WHAT DATA DO WE COLLECT ABOUT YOU<br>
 Personal data means any information capable of identifying an individual. It does not include anonymous data.</p>
 <p>We may process certain types of personal data about you as follows:</p>
 <p>Identity Data may include your first name, maiden name, last name, username, marital status, title, date of birth and gender.<br>
 Contact Data may include your billing address, delivery address, email address and telephone numbers.<br>
 Financial Data may include your bank account and payment card details.<br>
 Transaction Data may include details about payments between us and other details of purchases made by you.<br>
 Technical Data may include your login data, internet protocol addresses, browser type and version, browser plug-in types and versions, time zone setting and location, operating system and platform and other technology on the devices you use to access this site.<br>
 Profile Data may include your username and password, purchases or orders, your interests, preferences, feedback and survey responses.<br>
 Usage Data may include information about how you use our website, products, and services.<br>
 Marketing and Communications Data may include your preferences in receiving marketing communications from us and our third parties and your communication preferences.<br>
 We may also process Aggregated Data from your personal data but this data does not reveal your identity and as such in itself is not personal data. An example of this is where we review your Usage Data to work out the percentage of website users using a specific feature of our site. If we link the Aggregated Data with your personal data so that you can be identified from it, then it is treated as personal data.</p>
 <p>Sensitive Data</p>
 <p>We do not collect any Sensitive Data about you. Sensitive data refers to data that includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data. We do not collect any information about criminal convictions and offenses.</p>
 <p>Where we are required to collect personal data by law, or under the terms of the contract between us and you do not provide us with that data when requested, we may not be able to perform the contract (for example, to deliver goods or services to you). If you don’t provide us with the requested data, we may have to cancel a product or service you have ordered but if we do, we will notify you at the time.</p>
 <p>HOW WE COLLECT YOUR PERSONAL DATA<br>
 We collect data about you through a variety of different methods including:</p>
 <p>Direct interactions: You may provide data by filling in forms on our site (or otherwise) or by communicating with us by post, phone, email or otherwise, including when you:<br>
 order our products or services;<br>
 create an account on our site;<br>
 subscribe to our service or publications;<br>
 request resources or marketing be sent to you;<br>
 enter a competition, prize draw, promotion or survey; or<br>
 give us feedback.<br>
 Automated technologies or interactions: As you use our site, we may automatically collect Technical Data about your equipment, browsing actions and usage patterns. We collect this data by using cookies, server logs, and similar technologies. We may also receive Technical Data about you if you visit other websites that use our cookies. Please see our cookie policy via this link for further details.<br>
 Third parties or publicly available sources: We may receive personal data about you from various third parties and public sources as set out below:<br>
 analytics providers such as Google, Clicky or Momently based outside the EU;<br>
 advertising networks such as Facebook or Buysellads based outside the EU; and<br>
 Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as Leadpages, Drip, Gumroad or Thrive Cart, based outside the EU.<br>
 HOW WE USE YOUR PERSONAL DATA<br>
 We will only use your personal data when legally permitted. The most common uses of your personal data are:</p>
 <p>Where we need to perform the contract between us.<br>
 Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.<br>
 Where we need to comply with a legal or regulatory obligation.<br>
 Generally, we do not rely on consent as a legal ground for processing your personal data, other than in relation to sending marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by emailing us via our contact page.</p>
 <p>Purposes for processing your personal data</p>
 <p>Set out below is a description of the ways we intend to use your personal data and the legal grounds on which we will process such data. We have also explained what our legitimate interests are where relevant.</p>
 <p>We may process your personal data for more than one lawful ground, depending on the specific purpose for which we are using your data. Please email us via our contact page if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
 <p>Purpose/Activity</p>
 <p>Type of data</p>
 <p>Lawful basis for processing</p>
 <p>To register you as a new customer</p>
 <p>(a) Identity<br>
 (b) Contact</p>
 <p>Performance of a contract with you</p>
 <p>To process and deliver your order including:<br>
 (a) Manage payments, fees and charges</p>
 <p>(b) Collect and recover money owed to us</p>
 <p>(a) Identity<br>
 (b) Contact</p>
 <p>(c) Financial</p>
 <p>(d) Transaction</p>
 <p>(e) Marketing and Communications</p>
 <p>(a) Performance of a contract with you<br>
 (b) Necessary for our legitimate interests to recover debts owed to us</p>
 <p>To manage our relationship with you which will include:<br>
 (a) Notifying you about changes to our terms or privacy policy</p>
 <p>(b) Asking you to leave a review or take a survey</p>
 <p>(a) Identity<br>
 (b) Contact</p>
 <p>(c) Profile</p>
 <p>(d) Marketing and Communications</p>
 <p>(a) Performance of a contract with you<br>
 (b) Necessary to comply with a legal obligation</p>
 <p>(c) Necessary for our legitimate interests to keep our records updated and to study how customers use our products/services</p>
 <p>To enable you to partake in a prize draw, competition or complete a survey</p>
 <p>(a) Identity<br>
 (b) Contact</p>
 <p>(c) Profile</p>
 <p>(d) Usage</p>
 <p>(e) Marketing and Communications</p>
 <p>(a) Performance of a contract with you<br>
 (b) Necessary for our legitimate interests to study how customers use our products/services, to develop them and grow our business</p>
 <p>To administer and protect our business and our site (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</p>
 <p>(a) Identity<br>
 (b) Contact</p>
 <p>(c) Technical</p>
 <p>(a) Necessary for our legitimate interests for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise<br>
 (b) Necessary to comply with a legal obligation</p>
 <p>To deliver relevant content and advertisements to you and measure and understand the effectiveness of our advertising</p>
 <p>(a) Identity<br>
 (b) Contact</p>
 <p>(c) Profile</p>
 <p>(d) Usage</p>
 <p>(e) Marketing and Communications</p>
 <p>(f) Technical</p>
 <p>Necessary for our legitimate interests to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy</p>
 <p>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</p>
 <p>(a) Technical<br>
 (b) Usage</p>
 <p>Necessary for our legitimate interests to define types of customers for our products and services, to keep our site updated and relevant, to develop our business and to inform our marketing strategy</p>
 <p>To make suggestions and recommendations to you about goods or services that may be of interest to you</p>
 <p>(a) Identity<br>
 (b) Contact</p>
 <p>(c) Technical</p>
 <p>(d) Usage</p>
 <p>(e) Profile</p>
 <p>Necessary for our legitimate interests to develop our products/services and grow our business</p>
 <p>Marketing communications</p>
 <p>You will receive marketing communications from us if you have:</p>
 <p>requested information from us or purchased goods or services from us; or<br>
 if you provided us with your details when you entered a competition or registered for a promotion or free resources; and<br>
 in each case, you have not opted out of receiving that marketing.<br>
 You may opt-out of receiving communications from us by using the one-click unsubscribe button at the bottom of one of our emails. Or by contacting us directly via our contact page.</p>
 <p>Where you opt out of receiving our marketing communications, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</p>
 <p>Change of purpose</p>
 <p>We will only use your personal data for the purposes for which we collected it unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to find out more about how the processing for the new purpose is compatible with the original purpose, please email us via our contact page.</p>
 <p>If we need to use your personal data for a purpose unrelated to the purpose for which we collected the data, we will notify you and we will explain the legal ground of processing.</p>
 <p>We may process your personal data without your knowledge or consent where this is required or permitted by law.</p>
 <p>DISCLOSURES OF YOUR PERSONAL DATA<br>
 We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above:</p>
 <p>Service providers who provide IT and system administration services.<br>
 Professional advisers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance, and accounting services.<br>
 HM Revenue &amp; Customs, regulators and other authorities based in the United Kingdom and other relevant jurisdictions who require reporting of processing activities in certain circumstances.<br>
 Third parties to whom we sell, transfer, or merge parts of our business or our assets.<br>
 We require all third parties to whom we transfer your data to respect the security of your personal data and to treat it in accordance with the law. We only allow such third parties to process your personal data for specified purposes and in accordance with our instructions.</p>
 <p>INTERNATIONAL TRANSFERS<br>
 Countries outside of the European Economic Area (EEA) do not always offer the same levels of protection to your personal data, so European law has prohibited transfers of personal data outside of the EEA unless the transfer meets certain criteria.</p>
 <p>Many of our third parties service providers are based outside the European Economic Area (EEA) so their processing of your personal data will involve a transfer of data outside the EEA.</p>
 <p>Whenever we transfer your personal data out of the EEA, we do our best to ensure a similar degree of security of data by ensuring at least one of the following safeguards is implemented:</p>
 <p>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission; or<br>
 Where we use certain service providers, we may use specific contracts or codes of conduct or certification mechanisms approved by the European Commission which give personal data the same protection it has in Europe; or<br>
 Where we use providers based in the United States, we may transfer data to them if they are part of the EU-US Privacy Shield which requires them to provide similar protection to personal data shared between the Europe and the US.<br>
 If none of the above safeguards is available, we may request your explicit consent to the specific transfer. You will have the right to withdraw this consent at any time.</p>
 <p>Please email us via our contact page if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.</p>
 <p>DATA SECURITY<br>
 We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know such data. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
 <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
 <p>DATA RETENTION<br>
 We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
 <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
 <p>By law, we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers for tax purposes.</p>
 <p>In some circumstances you can ask us to delete your data: see below for further information.</p>
 <p>In some circumstances, we may anonymize your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
 <p>YOUR LEGAL RIGHTS<br>
 Under certain circumstances, you have rights under data protection laws in relation to your personal data. These include the right to:</p>
 <p>Request access to your personal data.<br>
 Request correction of your personal data.<br>
 Request erasure of your personal data.<br>
 Object to the processing of your personal data.<br>
 Request restriction of processing your personal data.<br>
 Request transfer of your personal data.<br>
 Right to withdraw consent.<br>
 You can see more about these rights at:</p>
 <p>https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</p>
 <p>If you wish to exercise any of the rights set out above, please email us via our contact page.</p>
 <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
 <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
 <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
 <p>THIRD-PARTY LINKS<br>
 This website may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.</p>
 <p>COOKIES<br>
 You can set your browser to refuse all or some browser cookies or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our cookie policy. Accepting cookies help us to deliver more useful content that’s free for you to consume.</p>
 <p>EMAIL NEWSLETTER<br>
 We may offer an email newsletter subscription on our website. As a newsletter subscriber, you may receive email updates from us. This includes updates about our latest website content, subscriber-only content, new products, partner deals, and more. If at any point you wish to unsubscribe, you can do so by using the ‘unsubscribe’ link at the bottom of any of our emails. This will delete your email address from our database.</p>
     </div><!-- .entry-content -->
 </article><!-- #post-## -->
 
         </main><!-- #main -->
     </div><!-- #primary -->
 </div><!-- .wrap -->
 
 
         </div><!-- #content -->
 
         </div></div><footer id="colophon" class="site-footer" role="contentinfo" ">
         <div class="wrap">
             

 <aside class="widget-area" role="complementary" aria-label="Footer">
                 <div class="widget-column footer-widget-1">
             <section id="pages-2" class="widget widget_pages"><h2 class="widget-title">LEARN ABOUT BidLight </h2>
         <ul>
             <li class="page_item page-item-575"><a href="https://Bidlight.com/#contact-section">Contact Us</a></li>
<li class="page_item page-item-598"><a href="https://Bidlight.com/disclosure/">Disclosure</a></li>
<li class="page_item page-item-594 current_page_item"><a href="https://Bidlight.com/privacy-policy/" aria-current="page">Privacy Policy</a></li>
<li class="page_item page-item-596"><a href="https://Bidlight.com/privacy-policy/">Terms of Service</a></li>
         </ul>

         </section>			</div>
                 <div class="widget-column footer-widget-2">
             </div></aside></div></footer></body></html>
             

`;

const Privacy = (props) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default Privacy;
