import React, { Component } from "react";
import { Link } from "react-router-dom";

import styles from "../../components/articleAside/aside.module.css";

class AsideFeed extends Component {
  render() {
    const userInfoRedux = this.props.userInfoRedux;
    const top_articles = this.props._articles.map((_article, i) => (
      <li className="top-stories-list-item" key={i}>
        <div className="count-button-wrapper">
          <span className="count-button">{i + 1}</span>
        </div>
        <div
          className="top-stories-links"
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            paddingBottom: "5px",
          }}
        >
          <Link
            className="post-title"
            to={`/learning/articles/${_article.slug}`}
          >
            {_article.title}
          </Link>
          <br />
          <small>
            <div className="PopoverLink">
              <span className="popover-link" data-reactroot="">
                <a href={`/learning/articles/profile/${_article.author._id}`}>
                  {_article.author.name} {_article.author.lastName}
                </a>
              </span>
            </div>
          </small>
        </div>
      </li>
    ));
    return (
      <div className={styles.topListRoot}>
        <div className={styles.topList}>
          <div className="d-flex align-items-center justify-content-between"> 
          <h4 className="small-heading">
            Top{" "}
            {this.props.selectedCategory == "All"
              ? "Articles"
              : this.props.selectedCategory}
          </h4>
          {(userInfoRedux?.admin || userInfoRedux?.role === 'marketing') && (
            <Link to={`/learning/articles/create/editor`} className="btn-header add-article-btn bg-bid text-white">
             <i className="fa fa-plus mr-2"></i>  Add Article
            </Link> 
          )}
          </div>
          <aside className={`${styles.rootAside} main-sidebar`}>
            <div className="sidebar-top-stories">
              <ul>{top_articles}</ul>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
export default AsideFeed;
