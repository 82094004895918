import { combineReducers } from 'redux';
import userReducer from './user';
import adminReducer from './admin';
import viewerReducer from './viewer';
import mainAdminReducer from './main-admin';
import pricingReducer from './pricing';
import rolePermissionSettingsReducer from './role-permission-settings';
import extrasReducer from './extras';
//import socketviewerReducer from './socket-viewer';
//import sessionReducer from './websocket-sessions';

const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  viewer: viewerReducer,
  mainAdmin: mainAdminReducer,
  pricing: pricingReducer,
  rolesAndPermissions: rolePermissionSettingsReducer,
  extras : extrasReducer
  //socketViewer:socketviewerReducer,
//  session:sessionReducer,
});

export default rootReducer;
