import React, { useState } from "react";
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../ui-components/loading";
import { useHttpWithCache } from "hooks/useHtthWithCache";

import { choseToggle } from "helpers/toggleIfLoading";
import addNotification from "helpers/notify";
import { assignUserToProject } from "api/users/addUserToProject";
import { removeUserFromProject } from "api/users/removeUserFromProject";
import { Maybe } from "helpers/maybeFunctor";
import { useSearch } from "hooks/useSearch";
import { useHttp } from "hooks/useHttp";
import {
  addUserToProject as addUserToProjectRedux,
  removeUserFromProject as removeUserFromProjectRedux,
  setCompanyUsers,
  setProjects,
} from "actions/user";
import { eventTrack } from "helpers/ga4Helper";
import { bulkAddUserToProject } from "api/users/bulkAddUserToProject";
import { bulkDeleteUserToProject } from "api/users/bulkDeleteUserToProject";
import { getProjects } from "api/projects/getProjects";
import { mainAdminGetCompanies } from "api/users/mainAdminGetCompanies";

const BulkAssign = (props) => {
  const { projectsToAssign } = props;

  const usersRedux = useSelector((s) => s.user.users);
  const projectsRedux = useSelector((s) => s.user.projects);
  const emailRedux = useSelector((s) => s.user.userInfo.email);
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const dispatch = useDispatch();
  const search = useSearch();

  const [searchValue, setSearchValue] = useState("");
  const [isAddPage, setIsAddPage] = useState(true);
  const [chosenUserForAdd, setChosenUserForAdd] = useState([]);
  const [chosenUserForDelete, setChosenUserForDelete] = useState([]);

  const [btn, setBtn] = useState("AddUser");

  const toggle = () => {
    props.toggle();
    setChosenUserForAdd([]);
    setChosenUserForDelete([]);
  };

  const { loading: projectLoading, request: getProjectsAPI } = useHttp({
    requestCallback: getProjects,
    onLoad: (res) => {
      dispatch(setProjects(res?.projects));
      dispatch(setCompanyUsers(res?.users));
    },
    onError: (res) => {},
  });

  const {
    adminProjectsLoading,
    adminProjectsError,
    request: getAdminProjectsAPI,
  } = useHttpWithCache({
    requestCallback: () => mainAdminGetCompanies(),
    reduxSetter: (data) => {
      dispatch(
        setProjects(
          data?.company_projects?.find(
            (item) => item?._id === userInfoRedux?.company
          )?.projects
        )
      );
      dispatch(
        setCompanyUsers(
          data?.all_users?.filter(
            (item) => item?.company === userInfoRedux?.company
          )
        )
      );
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
    },
    reduxCash: {
      projects: projectsRedux,
      users: usersRedux,
    },
    allowToCash: true,
  });

  //   Assign user to project api call
  const {
    loading: assignUserToProjectAPILoading,
    request: BulkAssignUserToProjectAPI,
  } = useHttp({
    requestCallback: bulkAddUserToProject,
    onLoad: (res) => {
      if(userInfoRedux?.admin){
        getAdminProjectsAPI()
      }
      else{
        getProjectsAPI()
      }
      dispatch(addUserToProjectRedux(chosenUserForAdd, projectsToAssign));
      setChosenUserForAdd([]);
      addNotification(
        "User was successfully added to this project!",
        "success"
      );
      toggle();
    },
    onError: (res) => {
      if(userInfoRedux?.admin){
        getAdminProjectsAPI()
      }
      else{
        getProjectsAPI()
      }
      setChosenUserForAdd([]);
      addNotification(
        "User was successfully added to this project!",
        "success"
      );
      toggle();
    },
  });

  //   Delete user from project api call
  const {
    loading: deleteUserFromProjectLoading,
    request: bulkDeleteUserFromProjectAPI,
  } = useHttp({
    requestCallback: bulkDeleteUserToProject,
    onLoad: (res) => {
      if(userInfoRedux?.admin){
        getAdminProjectsAPI()
      }
      else{
        getProjectsAPI()
      }
      dispatch(
        removeUserFromProjectRedux(chosenUserForDelete, projectsToAssign)
      );
      setChosenUserForDelete([]);
      addNotification(
        "User was successfully removed from this project!",
        "success"
      );
      toggle();
    },
    onError: (res) => {
      if(userInfoRedux?.admin){
        getAdminProjectsAPI()
      }
      else{
        getProjectsAPI()
      }
      addNotification(
        "User was successfully added to this project!",
        "success"
      );
      toggle();
    },
  });

  //   function to call user addition api
  const addUserToProject = () => {
    if (!chosenUserForAdd) {
      addNotification(
        "Choose the user you want to add to this project",
        "default"
      );
    } else {
      const resultObjects = {
        project_ids: projectsToAssign,
        user_ids: chosenUserForAdd,
      };
      return BulkAssignUserToProjectAPI(resultObjects);
    }
  };

  //   function to call user deletion api
  const deleteUserToProject = () => {
    if (!chosenUserForDelete) {
      addNotification(
        "Choose the user you want to delete from this project",
        "default"
      );
    } else {
      const resultObjects = {
        project_ids: projectsToAssign,
        user_ids: chosenUserForDelete,
      };
      return bulkDeleteUserFromProjectAPI(resultObjects);
    }
  };

  const projectListToEditUsers = Maybe.of(projectsRedux).map((projectsRedux) =>
    projectsRedux
      .filter((obj) => projectsToAssign?.includes(obj._id))
      .flatMap((project) => project.users)
  );

  const handleCheckBox = (e, id) => {
    const isChecked = e.target.checked;

    const index = chosenUserForAdd.indexOf(id);

    if (isChecked && index === -1) {
      setChosenUserForAdd((prev) => [...prev, id]);
    }

    if (!isChecked && index !== -1) {
      setChosenUserForAdd((prev) => prev.filter((userId) => userId !== id));
    }
  };

  const handleCheckBoxDeleteuser = (e, id) => {
    const isChecked = e.target.checked;

    const index = chosenUserForDelete.indexOf(id);

    if (isChecked && index === -1) {
      setChosenUserForDelete((prev) => [...prev, id]);
    }

    if (!isChecked && index !== -1) {
      setChosenUserForDelete((prev) => prev.filter((userId) => userId !== id));
    }
  };

  const usersAbleToAdd = Maybe.of(usersRedux)
    .map((users) => {
      if (projectListToEditUsers) {
        return users
          .filter((user) => !projectListToEditUsers.value?.includes(user._id))
          .filter((user) => user.email !== emailRedux);
      } else {
        return null;
      }
    })
    .map((filteredUsers) => {
      return search(filteredUsers, "email", searchValue);
    })
    .map((searchedUsers) => {
      return searchedUsers.map((user) => {
        const { name, lastName, email, _id } = user;
        return (
          <MDBListGroupItem
            key={_id}
            className="cursor-pointer p-2 member-list"
            active={chosenUserForAdd?.includes(_id)}
          >
            <input
              checked={chosenUserForAdd?.includes(_id)}
              onChange={(e) => handleCheckBox(e, _id)}
              type="checkbox"
              className="squared-checkbox mr-3"
            />
            <div>
              <div className="font-size-2">
                Name : {name} {lastName}
              </div>
              <span className="font-size-1">Email : {email}</span>
            </div>
          </MDBListGroupItem>
        );
      });
    }).value;

  const usersAbleToDelete = Maybe.of(usersRedux)
    .map((users) => {
      if (projectListToEditUsers) {
        return users
          .filter((user) => projectListToEditUsers.value?.includes(user._id))
          .filter((user) => user.email !== emailRedux);
      } else {
        return null;
      }
    })
    .map((filteredUsers) => {
      return search(filteredUsers, "email", searchValue);
    })
    .map((searchedUsers) => {
      return searchedUsers.map((user) => {
        const { name, lastName, email, _id } = user;
        return (
          <MDBListGroupItem
            key={_id}
            className="cursor-pointer p-2 member-list"
            active={chosenUserForDelete?.includes(_id)}
          >
            <input
              checked={chosenUserForDelete?.includes(_id)}
              onChange={(e) => handleCheckBoxDeleteuser(e, _id)}
              type="checkbox"
              className="squared-checkbox mr-3"
            />
            <div>
              <div className="font-size-2">
                Name : {name} {lastName}
              </div>
              <span className="font-size-1">Email : {email}</span>
            </div>
          </MDBListGroupItem>
        );
      });
    }).value;

  const loading = assignUserToProjectAPILoading || deleteUserFromProjectLoading;

  return (
    <MDBModal
      toggle={choseToggle(loading, toggle)}
      isOpen={props.open}
      centered
      className="create-modal"
    >
      <MDBModalHeader
        toggle={choseToggle(loading, toggle)}
        className="d-flex mb-0 pb-2"
      >
        Assign Users
      </MDBModalHeader>
      <MDBModalBody className="pt-3">
        <div
          className="btn-group btn-group-toggle toggle-group ml-0 toggle-btn-group"
          data-toggle="buttons"
        >
          <label
            class="btn btn-secondary  active"
            onClick={() => {
              setIsAddPage(true);
              setBtn("AddUser");
              {
                eventTrack(
                  "Bulk-Assign-Action",
                  "Add-User",
                  { searchValue },
                  { searchValue },
                  false,
                  searchValue
                );
              }
            }}
          >
            <input
              type="radio"
              name="options"
              id="option1"
              checked={btn === "AddUser"}
            />{" "}
            Add Users
          </label>
          <label
            class="btn btn-secondary"
            onClick={() => {
              {
                setIsAddPage(false);
                setBtn("Delete");
              }
              {
                eventTrack(
                  "Delete-User-Action",
                  "Delete-User",
                  { searchValue },
                  { searchValue },
                  false,
                  searchValue
                );
              }
            }}
          >
            <input
              type="radio"
              name="options"
              id="option2"
              checked={btn === "Delete"}
            />{" "}
            Delete Users
          </label>
        </div>

        {isAddPage ? (
          <>
            <div className="form-group mb-2">
              <input
                type="text"
                className="shared-input-search mt-2"
                placeholder="Search by email.."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <MDBListGroup className="fixes-height-members overflow-auto">
              {usersAbleToAdd}
            </MDBListGroup>
            {loading ? (
              <div className="d-flex flex-center py-2 spinner-box">
                <Loading color="black" text="Loading..." />
              </div>
            ) : null}

            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                className="header-btn text-white bg-bid border-bid mr-0"
                onClick={() => {
                  addUserToProject();
                  {
                    eventTrack(
                      "Add-User-Action",
                      "Add-User",
                      { searchValue },
                      { searchValue },
                      false,
                      searchValue
                    );
                  }
                }}
                disabled={loading}
              >
                Assign Users
                <span className="ml-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                  >
                    <path
                      d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="form-group mb-2">
              <input
                type="text"
                className="shared-input-search mt-2"
                placeholder="Search by email.."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <MDBListGroup className="fixes-height-members overflow-auto">
              {usersAbleToDelete}
            </MDBListGroup>
            {loading ? (
              <div className="d-flex flex-center py-2 spinner-box">
                <Loading color="black" text="Loading..." />
              </div>
            ) : null}

            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                className="header-btn text-white bg-bid border-bid mr-0"
                onClick={() => {
                  deleteUserToProject();
                  {
                    eventTrack(
                      "Add-User-Action",
                      "Add-User",
                      { searchValue },
                      { searchValue },
                      false,
                      searchValue
                    );
                  }
                }}
                disabled={loading}
              >
                Delete Users
                <span className="ml-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                  >
                    <path
                      d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </>
        )}
      </MDBModalBody>
    </MDBModal>
  );
};

export default BulkAssign;
