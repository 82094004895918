export const ALL_PERMISSIONS = {
  PARAMETER_MGMT_VIEW: "parameterManagementView",
  PARAMETER_MGMT_ADD: "parameterManagementAdd",
  PARAMETER_MGMT_EDIT: "parameterManagementEdit",
  PARAMETER_MGMT_DELETE: "parameterManagementDelete",
  KANBAN_EDIT: "kanbanEdit",
  KANBAN_VIEW: "kanbanView",
  PROJECT_ADD: "projectAdd",
  PROJECT_EDIT: "projectEdit",
  PROJECT_DELETE: "projectDelete",
  PROJECT_VIEW: "projectView",
  USER_ADD: "userAdd",
  USER_EDIT: "userEdit",
  USER_DELETE: "userDelete",
  USER_VIEW: "userView",
  USER_IMPORT: "userImport",
  MODAL_HEALTH_REPORT_EXPORT: "modelHealthReportExport",
  MODAL_HEALTH_REPORT_VIEW: "modelHealthReportView",
  MODAL_HEALTH_REPORT_DELETE: "modelHealthReportDelete",
  MODAL_HEALTH_SETTINGS_VIEW: "modelHealthSettingsView",
  MODAL_HEALTH_SETTINGS_EDIT: "modelHealthSettingsEdit",
  MODAL_FILE_VIEW: "modelFileView",
  MODAL_FILE_EXPORT: "modelFileExport",
  PROJECT_TRACKING: "projectTracking",
  PROJECT_MANAGEMENT: "projectManagement",
  PRICING_DATABASE_VIEW: "pricingDatabaseView",
  PRICING_DATABASE_EDIT: "pricingDatabaseEdit",
  PRICING_DATABASE_DELETE: "pricingDatabaseDelete",
  MILESTONE_VIEW: "milestoneView",
  MILESTONE_ADD: "milestoneAdd",
  MILESTONE_EDIT: "milestoneEdit",
  MILESTONE_DELETE: "milestoneDelete",
  MILESTONE_IMPORT: "milestoneImport",
  PROJECT_EXPORT_SETTINGS_VIEW: "projectExportSettingsView",
  PROJECT_EXPORT_SETTINGS_EDIT: "projectExportSettingsEdit",
  PROJECT_EXPORT_SETTINGS_ADD: "projectExportSettingsAdd",
  ESTIMATION_EXPORT: "estimationExport",
  ESTIMATION_SHARE: "estimationShare",
  ESTIMATION_VIEW: "estimationView",
  ROLE_ADD: "roleAdd",
  ROLE_EDIT: "roleEdit",
  ROLE_DELETE: "roleDelete",
  ADMIN_SECTION_ACCESS: "adminSectionAccess",
  REAL_TIME_ESTIMATE: "realTimeEstimate",
  SHARE_PROJECT_ADD: "shareProjectAdd",
  ASSIGN_PROJECT_ADD: "assignProjectAdd",
  ASSIGN_PROJECT_DELETE: "assignProjectDelete",
  THREE_D_MODAL_VIEW: "3dModelView",
  FILTER_VIEW: "filterView",
  FILTER_ADD: "filterAdd",
  FILTER_EDIT: "filterEdit",
  FILTER_DELETE: "filterDelete",
  ASSET_MANAGEMENT_VIEW: "assetManagementView",
  ASSET_MANAGEMENT_ADD: "assetManagementAdd",
  ASSET_MANAGEMENT_EDIT: "assetManagementEdit",
  ASSET_MANAGEMENT_DELETE: "assetManagementDelete",
  DOC_CONTROL_VIEW: "docControlView",
  DOC_CONTROL_ADD: "docControlAdd",
  DOC_CONTROL_DELETE: "docControlDelete",
  DOC_CONTROL_EDIT: "docControlEdit",
  DISCIPLINE_VIEW: "disciplineView",
  DISCIPLINE_ADD: "disciplineAdd",
  DISCIPLINE_EDIT: "disciplineEdit",
  DISCIPLINE_DELETE: "disciplineDelete",
  REVISION_VIEW: "revisionView",
  REVISION_ADD: "revisionAdd",
  REVISION_EDIT: "revisionEdit",
  REVISION_DELETE: "revisionDelete",
  ENGINEERING_TRACKING_VIEW: "engineeringTrackingView",
  ENGINEERING_TRACKING_EDIT: "engineeringTrackingEdit",
  ENGINEERING_TRACKING_ADD: "engineeringTrackingAdd",
  ENGINEERING_TRACKING_DELETE: "engineeringTrackingDelete",
  TAKEOFF_VIEW: 'takeoffView',
  TAKEOFF_EDIT: 'takeoffEdit',
  TAKEOFF_ADD: 'takeoffAdd',
  TAKEOFF_DELETE: 'takeoffDelete',
  TAKEOFF_EXPORT: 'takeoffExport',
  AFFILIATIONS_VIEW: 'affiliationView',
  AFFILIATIONS_ADD: 'affiliationAdd',
  AFFILIATIONS_EDIT: 'affiliationEdit',
};
