import React from "react";
import { Link } from "react-router-dom";
//import slug from 'slug'
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";

import styles from "./post-item.module.css";

function getslug(title) {
  return title
    .toLowerCase()
    .replace(/\s(-)\s/g, "-")
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

const PostItem = ({ vote, title, created }) => {
  //, id
  return (
    <div className={styles.container}>
      <div className={styles.vote}>{vote}</div>
      <Link to={`/learning/questions/${getslug(title)}`}>
        <a className={styles.title}>{title}</a>
      </Link>
      <div className={styles.created}>
        {formatDistanceToNowStrict(new Date(created), {
          addSuffix: true,
        })}
      </div>
    </div>
  );
};

export default PostItem;
