import React, { useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { MDBBox, MDBContainer, MDBRow } from 'mdbreact';

import ProjectItemAdmin from 'components/ProjectItemAdmin';
import MembersModalAdmin from 'components/members-modal';
import NoItems from 'components/ui-components/no-items';
import userEmail from 'assets/images/Group 4367.svg' ;
import userIcon from 'assets/images/Group 4369.svg' ;




const UserProjects = props => {
  const { idUser } = props;
  const [modal, setModal] = useState(false);
  const [projectListToEdit, setProjectListToEdit] = useState(null);

  const { pathname } = useLocation();
  const { companyId } = useParams();
  const history = useHistory();

  const usersRedux = useSelector(s => s.admin.users);
  const projectsRedux = useSelector(s => s.admin.projects);
 
  if (!usersRedux || !projectsRedux) {
    return <Redirect to={`/main-admin/${companyId}`} />;
  }

  let user = usersRedux.find(user => user._id === idUser);
  const projects = projectsRedux.filter(itemProject =>
    itemProject.users?.includes(user._id),
  );

  const projectsToJSX = (
    <MDBRow className="row-cols-lg-4 row-cols-sm-3 row-cols-1 mt-3">
      {projects.map((userProject, index) => {
        return (
          <ProjectItemAdmin
            projectItem={userProject}
            key={userProject._id}
            className={index > 3 ? 'mt-4' : ''}
            openTimelines={() => history.push(`${pathname}/${userProject._id}`)}
            openMemberShipList={() => {
              setProjectListToEdit(userProject);
              setModal(true);
            }}
          />
        );
      })}
    </MDBRow>
  );

  return ( 
    <MDBBox className="user-projects">
      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between py-3">
          <p className="my-heading">Projects</p>
          <div className="d-flex align-items-center">
            <div className='d-flex align-items-center project-admin-header'>
              <img src={userIcon} />
              {user.name} {user.lastName}
            </div>
            <div className='d-flex align-items-center project-admin-header'>
              <img src={userEmail} />
              {user.email}
            </div>
          </div>
        </div>
      </div>

      <MDBContainer size="lg" className="pt-12">
        <MDBBox className="pt-3">
          {projects.length === 0 ? (
            <NoItems text="This user has no projects" />
          ) : null}
          {projectsToJSX}
        </MDBBox>
      </MDBContainer>
      <MembersModalAdmin
        open={modal}
        toggle={() => setModal(s => !s)}
        projectListToEdit={projectListToEdit ? projectListToEdit._id : null}
      />
    </MDBBox>
  );
};

export default UserProjects;
