import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useReducer,
} from "react";
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
  MDBCardBody,
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";

import WebGlApp from "webgl";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import TreeViewMenu from "react-simple-tree-menu";
import ResizablePanels from "resizable-panels-react";
import jwt_decode from "jwt-decode";
import Share from "components/Share";
import { setCompanyUsers, setProjects, setProjectfilesTree } from "actions/user";
import { setUser } from "actions/user";
import { setJWT, tokenRef } from "api";
import Loading from "components/ui-components/loading";
import NoMeshModal from "../../components/NoMeshModal";
import ResizableViewer from "components/hoc/resizable-viewer/resizable-viewer";
import addNotification from "helpers/notify";
import { useHttp } from "../../hooks/useHttp";
import { getJsonFile, getCompressedJsonFile } from "api/files/getJsonFile";
import { url, IFC_Url } from "api";
import { Maybe } from "helpers/maybeFunctor";
import { getProjects } from "api/projects/getProjects";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import TreeViewerItem from "components/TreeViewerItem";
import { generateId } from "helpers/id";
import {
  findRecursiveInTreeJsonId,
  findRecursiveInTreeListJsonId,
  generateMeshes,
  generateSubprojectMeshes,
  generateTree,
  generateSubprojectTree,
  generateRawMeshes,
  generateRawTree,
} from "helpers/three";

import "./TimelineViewer.scss";
import { useLogout } from "hooks/useLogout";

import { ReactComponent as CloseIcon } from "../../assets/images/down-arrow.svg";
import CollapsibleTable from "components/CollapsibleTable";
import ClockIcon from "assets/images/clock.png";
// import existingImg from "assets/images/exist-comp.png";
import demoImg from "assets/images/demo-comp.png";

import estimationImg from "assets/images/estimationImg.png";
import pricedImg from "assets/images/pricedImg.png";
import unpricedImg from "assets/images/unpricedImg.png";
import existingImg from "assets/images/existingImg.png";
import demolition from "assets/images/demolition.png";
import projectIcon from "assets/images/projectIcon.png";
import { QuantityContext } from "../../hooks/quantityContext";
import { mainAdminGetCompanies } from "api/users/mainAdminGetCompanies";

let numeral = require("numeral");
let summ = null;

const TimelineViewer = (props) => {
  const { projectId, timelineId, token, line, personal } = props;
  const dispatch = useDispatch();
  const logOut = useLogout();
  const viewerRef = useRef();
  const WebGL = useRef();
  const history = useHistory();
  const resizeRef = useRef();
  const [pending, setPending] = useState([]);
  const [withShare, setWith] = useState([]);
  const [menu, setMenu] = useState("");
  const [point, setPoint] = useState(false);
  const [summary, setSummary] = useState(null);
  const [currencysymb, setCurrencysymb] = useState("");
  const projectsRedux = useSelector((s) => s.user.projects);
  const usersRedux = useSelector((s) => s.user.users);
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const selectedMesh = useRef(null);

  useLayoutEffect(() => {
    function updateSize() {
      // setSize([window.innerWidth, window.innerHeight]);
      const currWindowHeight = window.innerHeight;
      const currWindowWidth = window.innerWidth;
      setWindowWidth(currWindowWidth);

      setSecondScreen(true);
      if (currWindowWidth < 1300) setFirstScreen(false);
      if (currWindowWidth < 900) setThirdScreen(false);
      if (currWindowWidth < 600) setSecondScreen(false);

      if (currWindowWidth >= 1300) setFirstScreen(true);
      if (currWindowWidth >= 900) setThirdScreen(true);
      if (currWindowWidth >= 600) setSecondScreen(true);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    try {
      if (token) {
        const userInfo = jwt_decode(token);
        localStorage.setItem(tokenRef, token);
        setJWT(token);
        dispatch(setUser(userInfo));
      }
    } catch (err) {
      console.log(err);
      addNotification(err.message, "danger");
      history.push("/");
    }
  }, [token]);
  useEffect(() => {
    if (menu) {
      resizeRef.current.state.panelsSize = [100];
    } else {
      resizeRef.current.state.panelsSize = [0];
    }
    setPoint((prev) => !prev);
  }, [menu]);

  const project =
    line?.line ||
    Maybe.of(projectsRedux).map((projects) => {
      // console.log(">>>>>>>>>>>>>>>>> projectsRedux", projectsRedux)
      return projects.filter((project) => project._id == projectId)[0];
    }).value;

  const neededTimeline =
    line?.line ||
    Maybe.of(projectsRedux)
      .map((projects) => {
        return projects.find((project) => {
          return project.timelines.find(
            (timeline) => timeline._id === timelineId
          );
        });
      })
      .map((project) => project.timelines)
      .map((timelines) =>
        timelines.find((timeline) => {
          return timeline._id === timelineId;
        })
      ).value;

  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current && neededTimeline != null) {
      summ = neededTimeline.summary;
      setSummary(summ);
      if (neededTimeline?.currencysymb)
        setCurrencysymb(neededTimeline?.currencysymb || "");
    }
  }, [summary]);

  useEffect(() => {
    console.log("Project UseEffect*********")
    isMountedRef.current = true;
    if (neededTimeline?.pending_share)
      setPending(neededTimeline?.pending_share || []);
    if (neededTimeline?.summary) setSummary(neededTimeline?.summary || null);
    if (neededTimeline?.currencysymb)
      setCurrencysymb(neededTimeline?.currencysymb || "");
    setWith(neededTimeline?.shared_with || []);
    if (isMountedRef.current && neededTimeline && project && !isGLOpend) {
      setGLOpend(true);
      initializeAPI();
      {
        setMenu("navigate");
      }
    }

    return () => (isMountedRef.current = false);
  }, [neededTimeline, project]);

  let meshes = null;
  let treeMeshes = null;

  let unmatchedMeshes = null;
  let treeUnmatchedMeshes = null;

  let existingMeshes = null;
  let treeExistingMeshes = null;

  let demolitionMeshes = null;
  let treeDemolitionMeshes = null;

  const isMountedRef = useRef(null);

  const [meshesArray, setMeshes] = useState(null);
  const [hoverMesh, setHoverMesh] = useState({});
  const [clickedChild, setClickedChild] = useState([]);
  const [clickedUnmatchedChild, setClickedUnmatchedChild] = useState([]);
  //
  const [clickedExistingChild, setClickedExistingChild] = useState([]);
  const [clickedDemolitionChild, setClickedDemolitionChild] = useState([]);
  //
  const [treeData, setTreeData] = useState([]);
  const [treeUnmatchedData, setTreeUnmatchedData] = useState([]);
  //
  const [treeExistingData, setTreeExistingData] = useState([]);
  const [treeDemolitionData, setTreeDemolitionData] = useState([]);
  // 
  const [percentsLoaded, setPercentsLoaded] = useState(0);
  const [allowToResize, setAllowToResize] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [openNodes, setOpenNodes] = useState([]);
  const [unmatchedOpenNodes, setUnmatchedOpenNodes] = useState([]);
  //
  const [existingOpenNodes, setExistingOpenNodes] = useState([]);
  const [demolitionOpenNodes, setDemolitionOpenNodes] = useState([]);
  //
  const [isPopover, setIsPopOver] = useState(false);
  const [popoverCoords, setCoordsForPopover] = useState({
    top: "0px",
    left: "0px",
  });
  const [scrollId, setScrollId] = useState("");
  const [unmatchedMeshesArray, setUnMatchedMeshes] = useState(null);
  //
  const [existingMeshesArray, setExistingMeshes] = useState(null);
  const [demolitionMeshesArray, setDemolitionMeshes] = useState(null);
  //
  const [resize, setResize] = useState(null);
  const [isUnmatchedMeshes, setIsUnmatchedMeshes] = useState(false);
  //
  const [isExistingMeshes, setIsExistingMeshes] = useState(false);
  const [isDemolitionMeshes, setIsDemolitionMeshes] = useState(false);
  //
  const [totalEstimation, setTotalEstimation] = useState("");
  const [isSecondScreen, setSecondScreen] = useState(true);
  const [windowWidth, setWindowWidth] = useState(null);
  const [isFirstScreen, setFirstScreen] = useState(true);
  // const [isThirdScreen, setThirdScreen] = useState(true);

  const [selectGroups, setSelectGroups] = useState([]);
  const [isPriceView, setPriceView] = useState(true);
  const [isEstimationView, setEstimatioView] = useState(true);
  const [isGLOpend, setGLOpend] = useState(false);

  // project files variables
  let filesMeshes = [];
  let treeFilesMeshes = [];

  
  let additionalMeshes = [];
  let electricalMeshes = [];
  let mechanicalMeshes = [];

  let [modelNumber, setModelNumber] = useState(0);
  let [modelsNames, setModelsNames] = useState([]);
  let [jsons, setJsons] = useState([]);
  let [models, setModels] = useState([]);

  let [additionalParamsPath] = useState([]);
  let [electricalCircuitsPath] = useState([]);
  let [mechanicalSystemsPath] = useState([]);

  const [isThirdScreen, setThirdScreen] = useState(true);
  const [treeFilesData, setTreeFilesData] = useState([]);
  const [filesOpenNodes, setFilesOpenNodes] = useState([]);
  const [filesMeshesArray, setFilesMeshes] = useState(null);

  const [additionalMeshesArray, setAdditionalMeshes] = useState(null);
  const [electricalMeshesArray, setElectricalMeshes] = useState(null);
  const [mechanicalMeshesArray, setMechanicalMeshes] = useState(null);

  const [isFilesMeshes, setIsFilesMeshes] = useState(false);
  const [clickedFilesChild, setClickedFilesChild] = useState([]);
  const [FiltersArr, setFiltersArr] = useState([]);

  const LaborinfoRef = useRef([]);
  const EquipinfoRef = useRef([]);
  const MatinfoRef = useRef([]);
  // const [meshQuantityInfoState, setMeshQuantityInfoState] = useState([]); // not working
  const meshQuantityInfoRef = useRef([]);

  useEffect(() => {
    setFiltersArr([
      { ProjectFiles: treeFilesData.filter((item) => item) },
      { UnpricedComponents: treeUnmatchedData },
      { ExistingComponents: treeExistingData },
      { DemolitionComponents: treeDemolitionData },
      { PricedComponents: treeData },
      { AdditionalParams: additionalMeshesArray}, 
      { ElectricalCircuits: electricalMeshesArray}, 
      { MechanicalSystems: mechanicalMeshesArray}, 
    ]);
  }, [
    treeFilesData,
    treeUnmatchedData,
    treeExistingData,
    treeDemolitionData,
    treeData,
    additionalMeshesArray,
    electricalMeshesArray,
    mechanicalMeshesArray
  ]);

  // project files variables
  console.log(neededTimeline)
  
  const { request: initializeAPI } = useHttp({
    requestCallback: async (d) =>
      await getJsonFile(neededTimeline.json).then(async (res) => {
      const t1=  neededTimeline?.zipInfo?.pathsmapping.forEach((fname) => {
          if (fname.endsWith(".json") || fname.endsWith(".json.gz")) {
            setModelNumber(modelNumber);
            modelNumber = modelNumber + 1;
            var fileName = fname.split(".")[0];
            modelsNames.push(fileName);
            setModelsNames(modelsNames);
          }
        });

        var baseURL = url;
        if (project?.isIfcProject) {
          baseURL = project.isIfcProject ? IFC_Url : url;
        } else if (neededTimeline?.isIfcProject) {
          baseURL = neededTimeline.isIfcProject ? IFC_Url : url;
        }

       const t2 = neededTimeline?.zipInfo?.paths.forEach((path, i) => {
          const filename = neededTimeline.zipInfo.pathsmapping[i];
          if (filename?.includes("_AdditionalParam")) {
            additionalParamsPath.push(`${path}`);
          }
          if (filename?.includes("_ElectricalCircuits")) {
            electricalCircuitsPath.push(`${path}`);
          }
          if (filename?.includes("_MechanicalSystems")) {
            mechanicalSystemsPath.push(`${path}`);
          }
          if (path.endsWith(".glb") || path.endsWith(".glb.gz")) {
            models.push(`${baseURL}/${path}`);
          } else if (path.endsWith(".json") || path.endsWith(".json.gz")) {
            jsons.push(`${path}`);
          }
        });

        setModels(models);
        setJsons(jsons);
        for (let i = 0; i < modelNumber; i++) {
          var mNumber = i;
          var mName = modelsNames[i];
          var mpath = jsons[i];
          var mAdditionalPath = additionalParamsPath[i];
          var mElectricalCircuitsPath = electricalCircuitsPath[i];
          var mMechanicalSystemsPath = mechanicalSystemsPath[i];

          await getCompressedJsonFile(
            mpath,
            project?.isIfcProject
              ? project?.isIfcProject
              : neededTimeline?.isIfcProject
              ? neededTimeline?.isIfcProject
              : false
          )
            .then((res) => {
              console.log("res", res);
              console.log("mNumber", mNumber);
              filesMeshes[mNumber] = generateRawMeshes(res); //generateMeshes(res);

              treeFilesMeshes[mNumber] = generateRawTree(res);
            })
            .catch((err) => console.log(err.message));

                    // download additional json
        await getCompressedJsonFile(
          mAdditionalPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            additionalMeshes[mNumber] = res;
          })
          .catch((err) => {});

        // download electrical json
        await getCompressedJsonFile(
          mElectricalCircuitsPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            electricalMeshes[mNumber] = res;
          })
          .catch((err) => {});

        // download mechanical json
        await getCompressedJsonFile(
          mMechanicalSystemsPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            mechanicalMeshes[mNumber] = res;
          })
          .catch((err) => {});
        }

        WebGL.current = new WebGlApp(
          viewerRef.current,
          {
            glbs: models,
          },
          {
            setClickedChild,
            setLoading,
            setError,
            setPercentsLoaded,
            setAllowToResize,
            setModal,
            setCoordsForPopover,
            findInJsonUsingViewer,
            setIsPopOver,
            onHoverMesh,
            selectedMesh,
            project,
          },
          line,
          false,
          project?.isIfcProject
            ? project?.isIfcProject
            : neededTimeline?.isIfcProject
            ? neededTimeline?.isIfcProject
            : false
        );
        return res;
      }),
    onLoad: (res) => {
      console.log("res**", res)
      setFilesMeshes([...filesMeshes]);
      setTreeFilesData([...treeFilesMeshes]);
      setTotalEstimation(res.FinalEstimation);

      setAdditionalMeshes(...additionalMeshes);
      setElectricalMeshes(...electricalMeshes);
      setMechanicalMeshes(...mechanicalMeshes);
      dispatch(setProjectfilesTree(treeFilesMeshes.filter((item) => item)));

      unmatchedMeshes = generateSubprojectMeshes(res.UnmatchedSubProjects);

      treeUnmatchedMeshes = generateSubprojectTree(
        res.$id,
        res.Title,
        res.UnmatchedSubProjects
      );

      //
      existingMeshes = generateSubprojectMeshes(res.ExistingSubProjects);
      demolitionMeshes = generateSubprojectMeshes(res.DemolitionSubprojects);
      //
      //
      treeExistingMeshes = generateSubprojectTree(
        res.$id,
        res.Title,
        res.ExistingSubProjects
      );
      //
      treeDemolitionMeshes = generateSubprojectTree(
        res.$id,
        res.Title,
        res.DemolitionSubprojects
      );

      meshes = generateSubprojectMeshes(res.SubProjects);
      treeMeshes = generateSubprojectTree(res.$id, res.Title, res.SubProjects);
      setMeshes(meshes);
      setTreeData(treeMeshes);
      console.log('actual Tree Data ******** ', treeMeshes);

      setTreeUnmatchedData(treeUnmatchedMeshes);
      setUnMatchedMeshes(unmatchedMeshes);

      //
      setTreeExistingData(treeExistingMeshes);
      setTreeDemolitionData(treeDemolitionMeshes);

      //
      //
      setExistingMeshes(existingMeshes);
      setDemolitionMeshes(demolitionMeshes);
      //
      console.log(...treeFilesMeshes, 'treeFilesMeshes')

      setFilesOpenNodes([
        ...filesOpenNodes,
        ...treeFilesMeshes.map((tv) => tv[0]?.key),
      ]);
      setOpenNodes([...openNodes, treeMeshes[0].key]);

      setUnmatchedOpenNodes([
        ...unmatchedOpenNodes,
        treeUnmatchedMeshes[0].key,
      ]);
      setExistingOpenNodes([...existingOpenNodes, treeExistingMeshes[0].key]);
      setDemolitionOpenNodes([
        ...demolitionOpenNodes,
        treeDemolitionMeshes[0].key,
      ]);
    },
    onError: (e) => {
      console.error(e);
      setError(true);
    },
  });

  const {
    error: cacheError,
    loading: cacheLoading,
    errorStatus: cacheErrorStatus,
    request: cacheRequest,
    refresh,
  } = useHttpWithCache({
    requestCallback: () => getProjects(),
    reduxSetter: (data) => {
      dispatch(setProjects(data.projects));
      dispatch(setCompanyUsers(data.users));
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
    },
    reduxCash: {
      projects: projectsRedux,
      users: usersRedux,
    },
    allowToCash: true,
  });

  const {
    adminProjectsLoading,
    adminProjectsError,
    request: getAdminProjectsAPI,
  } = useHttpWithCache({
    requestCallback: () => mainAdminGetCompanies(),
    reduxSetter: (data) => {
      dispatch(
        setProjects(
          data?.company_projects?.find(
            (item) => item?._id === userInfoRedux?.company
          )?.projects
        )
      );
      dispatch(
        setCompanyUsers(
          data?.all_users?.filter(
            (item) => item?.company === userInfoRedux?.company
          )
        )
      );
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
    },
    reduxCash: {
      projects: projectsRedux,
      users: usersRedux,
    },
    allowToCash: true,
  });



  const onHoverMesh = (id) => {
    const neededMesh = meshes.find((mesh) => mesh.UniqueId === id);
    setHoverMesh(neededMesh);
  };

  const jsonInfo = Maybe.of(
    (() => {
      if (
        isUnmatchedMeshes &&
        !isExistingMeshes &&
        !isFilesMeshes &&
        !isDemolitionMeshes
      ) {
        return {
          neededMeshesArray: unmatchedMeshesArray,
          neededClickedChild: clickedUnmatchedChild,
        };
      } else if (
        !isUnmatchedMeshes &&
        !isExistingMeshes &&
        !isFilesMeshes &&
        !isDemolitionMeshes
      ) {
        return {
          neededMeshesArray: meshesArray,
          neededClickedChild: clickedChild,
        };
      } else if (
        !isUnmatchedMeshes &&
        isExistingMeshes &&
        !isFilesMeshes &&
        !isDemolitionMeshes
      ) {
        return {
          neededMeshesArray: existingMeshesArray,
          neededClickedChild: clickedExistingChild,
        };
      } else if (
        !isUnmatchedMeshes &&
        !isExistingMeshes &&
        isFilesMeshes &&
        !isDemolitionMeshes
      ) {
        return {
          neededMeshesArray: filesMeshesArray,
          neededClickedChild: clickedFilesChild,
        };
      } else if (
        !isUnmatchedMeshes &&
        !isExistingMeshes &&
        !isFilesMeshes &&
        isDemolitionMeshes
      ) {
        return {
          neededMeshesArray: demolitionMeshesArray,
          neededClickedChild: clickedDemolitionChild,
        };
      }
    })()
  ).map(({ neededMeshesArray, neededClickedChild }) => {
    meshQuantityInfoRef.current = neededClickedChild;
    // console.log("from Timeline", {clickedChild})
    return neededClickedChild.length === 1
    ? neededClickedChild?.flatMap((i) => {
      return  neededMeshesArray?.flat()?.find((k) => k?.UniqueId === i?.UniqueId)
    })[0]
      : neededClickedChild.reduce(
          (totalObject, mesh) => {
            // console.log({ neededClickedChild })
            // setMeshQuantityInfoState(neededClickedChild);
            const sumProps = Object.assign(
              {},
              {
                LaborInfoTime:
                  totalObject.LaborInfoTime + mesh.LaborInfo
                    ? mesh.LaborInfo?.map((l) => l.TotalTime)
                    : 0,
                EquipmentInfoTime:
                  totalObject.EquipmentInfoTime + mesh.EquipmentInfo
                    ? mesh.EquipmentInfo?.map((l) => l.TotalTime)
                    : 0,

                LaborInfoPrice:
                  totalObject.LaborInfoPrice + mesh.LaborInfo
                    ? mesh.LaborInfo?.map((l) => l.TotalPrice)
                    : 0,
                EquipmentInfoPrice:
                  totalObject.EquipmentInfoPrice + mesh.EquipmentInfo
                    ? mesh.EquipmentInfo?.map((l) => l.TotalPrice)
                    : 0,

                Area: totalObject.Area + mesh.Area,
                Count: totalObject.Count + mesh.Count,
                Length: totalObject.Length + mesh.Length,
                Quantity: totalObject.Quantity + mesh.Quantity,
                Volume: totalObject.Volume + mesh.Volume,
                TotalPrice: totalObject.TotalPrice + mesh.TotalPrice,
              },
              typeof totalObject.Width === "number"
                ? {
                    Width: totalObject.Width + mesh.Width,
                  }
                : {},
              typeof totalObject.Depth === "number"
                ? {
                    Depth: totalObject.Depth + mesh.Depth,
                  }
                : {},
              typeof totalObject.TotalAmount === "number"
                ? {
                    TotalAmount: totalObject.TotalAmount + mesh.TotalAmount,
                  }
                : {},
              typeof totalObject.Dimentions === "number"
                ? {
                    Dimentions: totalObject.Dimentions + mesh.Dimentions,
                  }
                : {}
            );

            const stringProps = Object.assign(
              {},
              totalObject.Uniformat === mesh.Uniformat && totalObject.Uniformat
                ? { Uniformat: "-" }
                : { Uniformat: mesh.Uniformat },
              totalObject.Name !== mesh.Name && totalObject.Name
                ? { Name: "-" }
                : { Name: mesh.Name },
              totalObject.Type !== mesh.Type && totalObject.Type
                ? { Type: "-" }
                : { Type: mesh.Type },
              totalObject.MainCategory !== mesh.MainCategory &&
                totalObject.MainCategory
                ? { MainCategory: "-" }
                : { MainCategory: mesh.MainCategory },
              totalObject.CategoryName !== mesh.CategoryName &&
                totalObject.CategoryName
                ? { CategoryName: "-" }
                : { CategoryName: mesh.CategoryName },
              totalObject.LaborInfo !== mesh.LaborInfo && totalObject.LaborInfo
                ? { LaborInfoType: "-" }
                : {
                    LaborInfoType: mesh.LaborInfo
                      ? mesh.LaborInfo?.map((l) => l.Description).join(" | ")
                      : "-",
                  },
              totalObject.EquipmentInfo !== mesh.EquipmentInfo &&
                totalObject.EquipmentInfo
                ? { EquipmentInfoType: "-" }
                : {
                    EquipmentInfoType: mesh.EquipmentInfo
                      ? mesh.EquipmentInfo?.map((l) => l.Description).join(
                          " | "
                        )
                      : "-",
                  }
            );

            if (!totalObject.LaborInfo || totalObject.LaborInfo == null) {
              totalObject.LaborInfo = [];
            }
            totalObject.LaborInfo.push(
              ...totalObject.LaborInfo,
              mesh.LaborInfo ? mesh.LaborInfo : null
            );

            LaborinfoRef.current = [...totalObject.LaborInfo];

            // console.log("Total Labor Info - totalObject.LaborInfo: ", totalObject.LaborInfo, LaborinfoRef.current);

            if (
              !totalObject.EquipmentInfo ||
              totalObject.EquipmentInfo == null
            ) {
              totalObject.EquipmentInfo = [];
            }
            totalObject.EquipmentInfo.push(
              ...totalObject.EquipmentInfo,
              mesh.EquipmentInfo ? mesh.EquipmentInfo : null
            );
            EquipinfoRef.current = [...totalObject.EquipmentInfo];

            if (!totalObject.MaterialInfo || totalObject.MaterialInfo == null) {
              totalObject.MaterialInfo = [];
            }

            totalObject.MaterialInfo.push(...totalObject.MaterialInfo, {
              Types: mesh.MaterialNames,
              Name: mesh.Type,
              TotalAmount: mesh.TotalAmount,
              TotalPrice: mesh.TotalPrice,
            });
            MatinfoRef.current = [...totalObject.MaterialInfo];

            // setMaterialInfo(MaterialInfo);
            // setEquipmentInfo(EquipmentInfo);

            return { ...sumProps, ...stringProps };
          },
          neededClickedChild[0]
            ? {
                ...neededClickedChild[0],
                ...{
                  LaborInfoTime: 0,
                  EquipmentInfoTime: 0,
                  EquipmentInfoPrice: 0,
                  LaborInfoPrice: 0,
                  LaborInfo: [],
                  EquipmentInfo: [],
                  MaterialInfo: [],
                  Area: 0,
                  Count: 0,
                  Length: 0,
                  Quantity: 0,
                  Volume: 0,
                  TotalPrice: 0,
                },
              }
            : null
        );
  }).value;

  const jsonMeshesToJSX = useMemo(() => {
    return Maybe.of(treeData).map((tree) => (
      <TreeViewMenu key={generateId()} openNodes={openNodes} data={tree}>
        {({ items }) => {
          return items.map((item, index) => {
            return (
              <TreeViewerItem
                item={item}
                index={index}
                clickedChild={clickedChild}
                setClickedChild={setClickedChild}
                selectGroups={selectGroups}
                setSelectGroups={setSelectGroups}
                key={generateId()}
                selectedMesh={selectedMesh}
                WebGL={WebGL}
                tree={tree}
                setTreeData={setTreeData}
                setOpenNodes={setOpenNodes}
                isUnmatchedMeshes={() => setIsUnmatchedMeshes(false)}
                isExistingMeshes={() => setIsExistingMeshes(false)}
                isDemolitionMeshes={() => setIsDemolitionMeshes(false)}
                isFilesMeshes={() => setIsFilesMeshes(false)}
                selectors={{
                  prevMeshes: "prevMeshes",
                  prevMaterials: "prevMaterials",
                  color: "blue",
                }}
              />
            );
          });
        }}
      </TreeViewMenu>
    )).value;
  }, [ clickedChild, openNodes]);

  const jsonUnmatchedMeshesToJSX = useMemo(
    () =>
      Maybe.of(treeUnmatchedData).map((tree) => (
        <TreeViewMenu
          key={generateId()}
          data={tree}
          openNodes={unmatchedOpenNodes}
        >
          {({ items }) => {
            return items.map((item, index) => {
              return (
                <TreeViewerItem
                  item={item}
                  index={index}
                  selectGroups={selectGroups}
                  setSelectGroups={setSelectGroups}
                  key={generateId()}
                  clickedChild={clickedUnmatchedChild}
                  setClickedChild={setClickedUnmatchedChild}
                  selectedMesh={selectedMesh}
                  WebGL={WebGL}
                  tree={tree}
                  setTreeData={setTreeUnmatchedData}
                  setOpenNodes={setUnmatchedOpenNodes}
                  isUnmatchedMeshes={() => setIsUnmatchedMeshes(true)}
                  isExistingMeshes={() => setIsExistingMeshes(false)}
                  isDemolitionMeshes={() => setIsDemolitionMeshes(false)}
                  isFilesMeshes={() => setIsFilesMeshes(false)}
                  selectors={{
                    prevMeshes: "prevUnmatchedMeshes",
                    prevMaterials: "prevUnmatchedMaterials",
                    color: "red",
                  }}
                />
              );
            });
          }}
        </TreeViewMenu>
      )).value,
    [unmatchedOpenNodes, treeUnmatchedData, clickedUnmatchedChild]
  );

  const jsonExistingMeshesToJSX = useMemo(
    () =>
      Maybe.of(treeExistingData).map((tree) => (
        <TreeViewMenu
          key={generateId()}
          data={tree}
          openNodes={existingOpenNodes}
        >
          {({ items }) => {
            return items.map((item, index) => {
              return (
                <TreeViewerItem
                  item={item}
                  index={index}
                  selectGroups={selectGroups}
                  setSelectGroups={setSelectGroups}
                  key={generateId()}
                  clickedChild={clickedExistingChild}
                  setClickedChild={setClickedExistingChild}
                  selectedMesh={selectedMesh}
                  WebGL={WebGL}
                  tree={tree}
                  setTreeData={setTreeExistingData}
                  setOpenNodes={setExistingOpenNodes}
                  isUnmatchedMeshes={() => setIsUnmatchedMeshes(false)}
                  isExistingMeshes={() => setIsExistingMeshes(true)}
                  isDemolitionMeshes={() => setIsDemolitionMeshes(false)}
                  isFilesMeshes={() => setIsFilesMeshes(false)}
                  selectors={{
                    prevMeshes: "prevExistingMeshes",
                    prevMaterials: "prevExistingMaterials",
                    color: "purple",
                  }}
                />
              );
            });
          }}
        </TreeViewMenu>
      )).value,
    [existingOpenNodes, treeExistingData, clickedExistingChild]
  );

  const jsonDemolitionMeshesToJSX = useMemo(
    () =>
      Maybe.of(treeDemolitionData).map((tree) => (
        <TreeViewMenu
          key={generateId()}
          data={tree}
          openNodes={demolitionOpenNodes}
        >
          {({ items }) => {
            return items.map((item, index) => {
              return (
                <TreeViewerItem
                  item={item}
                  index={index}
                  selectGroups={selectGroups}
                  setSelectGroups={setSelectGroups}
                  key={generateId()}
                  clickedChild={clickedDemolitionChild}
                  setClickedChild={setClickedDemolitionChild}
                  selectedMesh={selectedMesh}
                  WebGL={WebGL}
                  tree={tree}
                  setTreeData={setTreeDemolitionData}
                  setOpenNodes={setDemolitionOpenNodes}
                  isUnmatchedMeshes={() => setIsUnmatchedMeshes(false)}
                  isExistingMeshes={() => setIsExistingMeshes(false)}
                  isDemolitionMeshes={() => setIsDemolitionMeshes(true)}
                  isFilesMeshes={() => setIsFilesMeshes(false)}
                  selectors={{
                    prevMeshes: "prevDemolitionMeshes",
                    prevMaterials: "prevDemolitionMaterials",
                    color: "brown",
                  }}
                />
              );
            });
          }}
        </TreeViewMenu>
      )).value,
    [demolitionOpenNodes, treeDemolitionData, clickedDemolitionChild]
  );

  const jsonFilesMeshesToJSX = useMemo(() => {
    return treeFilesData.map((section, mainIndex) => {
      return Maybe.of(section).map((tree) => (
        <TreeViewMenu key={generateId()} openNodes={filesOpenNodes} data={tree}>
          {({ items }) => {
            return items.map((item, index) => {
              return (
                <TreeViewerItem
                  item={item}
                  index={index}
                  clickedChild={clickedFilesChild}
                  setClickedChild={setClickedFilesChild}
                  selectGroups={selectGroups}
                  setSelectGroups={setSelectGroups}
                  key={generateId()}
                  selectedMesh={selectedMesh}
                  WebGL={WebGL}
                  tree={tree}
                  setTreeData={(updatedSection) => {
                    [...treeFilesData][mainIndex] = updatedSection;
                    setTreeFilesData([...treeFilesData]);
                  }}
                  setOpenNodes={setFilesOpenNodes}
                  isUnmatchedMeshes={() => setIsUnmatchedMeshes(false)}
                  isExistingMeshes={() => setIsExistingMeshes(false)}
                  isDemolitionMeshes={() => setIsDemolitionMeshes(false)}
                  isFilesMeshes={() => setIsFilesMeshes(true)}
                  selectors={{
                    prevMeshes: "prevFilesMeshes",
                    prevMaterials: "prevFilesMaterials",
                    color: "orange",
                  }}
                />
              );
            });
          }}
        </TreeViewMenu>
      )).value;
    });
  }, [[...treeFilesData], clickedFilesChild, filesOpenNodes]);

  const pickMeshInNeededTree = (
    clicked,
    treeMeshes,
    setOpenNodes,
    setClickedChild,
    keyIsCtrl,
    selectors
  ) => {
    setSelectGroups([]);
    if (!clicked) {
      setModal(true);
    } else {
      let newClicked;
      console.log("pickMeshInNeededTree => clicked ", clicked);

      if (clicked.length > 1) {
        clicked.forEach((m) => {
          if (m) {
            newClicked = m;
            console.log("item within children", m);
          }
        });
      }

      let path;

      if (clicked.length > 1) {
        path = findRecursiveInTreeListJsonId(treeMeshes, newClicked.$id).path;
        console.log(
          "treeList > json path from pick needed mesh ",
          treeMeshes,
          "clicked.id = ",
          newClicked.$id
        );
      } else {
        path = findRecursiveInTreeJsonId(treeMeshes, clicked.$id).path;
        console.log(
          "tree > json path from pick needed mesh ",
          treeMeshes,
          "clicked.id = ",
          clicked.$id
        );
      }

      if (clicked.length > 1) {
        clicked = newClicked;
      }

      console.log("path from pick needed mesh ", path);

      const nodesKey = [
        path[0].id,
        `${path[0].id}/${path[1].id}`,
        `${path[0].id}/${path[1].id}/${path[2].id}`,
        `${path[0].id}/${path[1].id}/${path[2].id}/${path[3].id}`,
      ];
      nodesKey.forEach((nodeKey) => {
        setOpenNodes((s) => {
          if (s?.includes(nodeKey)) {
            return s;
          }
          return [...s, nodeKey];
        });
      });
      setClickedChild((s) => {
        if (keyIsCtrl) {
          if (s.find((mesh) => mesh.UniqueId === clicked.UniqueId)) {
            const neededIndex = s.findIndex(
              (mesh) => mesh.UniqueId === clicked.UniqueId
            );
            return [...s.slice(0, neededIndex), ...s.slice(neededIndex + 1)];
          }
          return [...s, clicked];
        } else {
          return [clicked];
        }
      });
      setScrollId(path[3].id);
      WebGL.current.viewer.onClickChildInList(
        clicked ? clicked.UniqueId : "",
        keyIsCtrl,
        selectors,
        false
      );
      selectedMesh.current = clicked.UniqueId;
    }
  };

  const findInJsonUsingViewer = (id, keyIsCtrl) => {
    console.log("findInJsonUsingViewer => id", id);
    const clickedUsualMesh = meshes.find((mesh) => mesh.UniqueId === id);
    const clickedUnpricedMesh = unmatchedMeshes.find(
      (mesh) => mesh.UniqueId === id
    );
    //
    const clickedExistingMesh = existingMeshes.find(
      (mesh) => mesh.UniqueId === id
    );
    //
    const clickedDemolitionMesh = demolitionMeshes.find(
      (mesh) => mesh.UniqueId === id
    );
    //
    // Project files
    const clickedFilesMesh = filesMeshes.map((file) => {
      console.log("file size", file.length);
      return file.find((mesh) => {
        return mesh.UniqueId === id;
      });
    });
    console.log(
      "clickedUnpricedMesh clickedFiles findInJsonUsingViewer",
      clickedUnpricedMesh
    );
    console.log(
      "clickedExistingMesh clickedFiles findInJsonUsingViewer",
      clickedExistingMesh
    );
    console.log(
      "clickedUsualMesh clickedFiles findInJsonUsingViewer",
      clickedUsualMesh
    );
    console.log(
      "clickedDemolitionMesh clickedFiles findInJsonUsingViewer",
      clickedDemolitionMesh
    );
    console.log(
      "clickedFilesMesh clickedFiles findInJsonUsingViewer",
      clickedFilesMesh
    );
    //
    if (clickedUnpricedMesh) {
      setIsUnmatchedMeshes(true);
      setIsExistingMeshes(false);
      setIsDemolitionMeshes(false);
      setIsFilesMeshes(false);
      pickMeshInNeededTree(
        clickedUnpricedMesh,
        treeUnmatchedMeshes,
        setUnmatchedOpenNodes,
        setClickedUnmatchedChild,
        keyIsCtrl,
        {
          prevMeshes: "prevUnmatchedMeshes",
          prevMaterials: "prevUnmatchedMaterials",
          color: "red",
        }
      );
    } else if (clickedExistingMesh) {
      setIsExistingMeshes(true);
      setIsUnmatchedMeshes(false);
      setIsDemolitionMeshes(false);
      setIsFilesMeshes(false);
      pickMeshInNeededTree(
        clickedExistingMesh,
        treeExistingMeshes,
        setExistingOpenNodes,
        setClickedExistingChild,
        keyIsCtrl,
        {
          prevMeshes: "prevExistingMeshes",
          prevMaterials: "prevExistingMaterials",
          color: "purple",
        }
      );
    } else if (clickedUsualMesh) {
      setIsUnmatchedMeshes(false);
      setIsExistingMeshes(false);
      setIsDemolitionMeshes(false);
      setIsFilesMeshes(false);
      pickMeshInNeededTree(
        clickedUsualMesh,
        treeMeshes,
        setOpenNodes,
        setClickedChild,
        keyIsCtrl,
        {
          prevMeshes: "prevMeshes",
          prevMaterials: "prevMaterials",
          color: "blue",
        }
      );
    } else if (clickedDemolitionMesh) {
      setIsUnmatchedMeshes(false);
      setIsExistingMeshes(false);
      setIsDemolitionMeshes(true);
      setIsFilesMeshes(false);
      pickMeshInNeededTree(
        clickedDemolitionMesh,
        treeDemolitionMeshes,
        setDemolitionOpenNodes,
        setClickedDemolitionChild,
        keyIsCtrl,
        {
          prevMeshes: "prevDemolitionMeshes",
          prevMaterials: "prevDemolitionMaterials",
          color: "brown",
        }
      );
    } else if (clickedFilesMesh) {
      setIsFilesMeshes(true);
      setIsDemolitionMeshes(false);
      setIsExistingMeshes(false);
      setIsUnmatchedMeshes(false);
      pickMeshInNeededTree(
        clickedFilesMesh,
        treeFilesMeshes,
        setFilesOpenNodes,
        setClickedFilesChild,
        keyIsCtrl,
        {
          prevMeshes: "prevFilesMeshes",
          prevMaterials: "prevFilesMaterials",
          color: "orange",
        }
      );
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    if (!projectsRedux && !line) {
      if (isMountedRef.current) {
        if(userInfoRedux?.admin){
          getAdminProjectsAPI()
        }
        else{
          cacheRequest();
        }
      }
    } else {
      //   initializeAPI();
    }
    return () => (isMountedRef.current = false);
  }, [projectsRedux]);

  useEffect(() => {
    isMountedRef.current = true;
    if (scrollId && document.getElementById(scrollId)) {
      if (isMountedRef.current) {
        document.getElementById(scrollId).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
    return () => (isMountedRef.current = false);
  }, [scrollId]);

  useEffect(() => {
    if (cacheErrorStatus) {
      const stringError = String(cacheErrorStatus);
      addNotification(stringError, "danger");
      if (stringError.toLowerCase()?.includes("unexpected token")) {
        logOut();
        history.push("/");
      }
    }
  }, [cacheErrorStatus]);

  useEffect(() => {
    isMountedRef.current = true;
    if (!isFirstScreen || !isSecondScreen || !isThirdScreen) {
      if (isMountedRef.current) {
        setAllowToResize(true);
      }
    } else {
      setAllowToResize(true);
    }
    return () => (isMountedRef.current = false);
  }, [isFirstScreen, isSecondScreen, isThirdScreen]);

  const handleChangeViewPrice = (isVisiblePrice, isVisibleEstimation) => {
    if (!personal) {
      setPriceView(isVisiblePrice);
      setEstimatioView(isVisibleEstimation);
    }
  };

  const infoPanel = (
    <div
      style={{
        minWidth: `${isFirstScreen ? resize?.panels[1] || 300 : ""}px`,
        maxWidth: `${isFirstScreen ? resize?.panels[1] || 300 : ""}px`,
      }}
      className="viewer-outer-div"
    >
      <div className="px-2 d-flex justify-content-between align-items-center overflow-hidden viewer-header-item viewer-gapping">
        <div className="d-flex align-items-center viewer-header-item ">
          <img src={estimationImg} className="estimation-icon" />
          Estimation Details:
        </div>

        <MDBBox
          onClick={() => setFirstScreen((s) => !s)}
          className={`viewer-icon ${
            !isFirstScreen ? "viewer-icon-rotate" : ""
          }`}
        >
          <CloseIcon />
        </MDBBox>
      </div>
    </div>
  );

  const pricedComponent = (
    <div
      style={{
        minWidth: `${isSecondScreen ? resize?.panels[2] || 300 : ""}px`,
        maxWidth: `${isSecondScreen ? resize?.panels[2] || 300 : ""}px`,
      }}
      className="viewer-outer-div"
    >
      <div className="px-2 d-flex align-items-center viewer-header-item viewer-gapping overflow-hidden">
        <MDBBox className="viewer-header-item d-flex align-items-center">
          <img src={pricedImg} className="priced-img" />
          Priced component:
        </MDBBox>
        <MDBBox className="flex-grow-1 text-truncate viewer-header-item ml-2">
          {clickedChild[clickedChild.length - 1]
            ? `${clickedChild[clickedChild.length - 1].Name}:${
                clickedChild[clickedChild.length - 1].Type
              }`
            : ""}
        </MDBBox>
        <MDBBox
          onClick={() => setSecondScreen((s) => !s)}
          className={`viewer-icon ${
            !isSecondScreen ? "viewer-icon-rotate" : ""
          }`}
        >
          <CloseIcon />
        </MDBBox>
      </div>
    </div>
  );

  const ProjectPanel = (
    <div
      style={{
        minWidth: `${isThirdScreen ? resize?.panels[3] || 300 : ""}px`,
        maxWidth: `${isThirdScreen ? resize?.panels[3] || 300 : ""}px`,
      }}
      className="viewer-outer-div"
    >
      {/* <i className="fa fa-file"></i> */}
      <div className="px-2 d-flex justify-content-between align-items-center overflow-hidden viewer-header-item viewer-gapping">
        <MDBBox className="viewer-header-item d-flex align-items-center">
          <img src={projectIcon} className="project-icon" />
          Project Files
        </MDBBox>
        <MDBBox
          onClick={() => setThirdScreen((s) => !s)}
          className={`viewer-icon ${
            !isThirdScreen ? "viewer-icon-rotate" : ""
          }`}
        >
          <CloseIcon />
        </MDBBox>
      </div>
    </div>
  );

  let timelineArray = [infoPanel, pricedComponent, ProjectPanel];

  const hoverMeshInfo = () => {
    if (hoverMesh?.TotalPrice && neededTimeline?.currencysymb) {
      return `Price :  ${numeral(hoverMesh?.TotalPrice).format(
        `${neededTimeline?.currencysymb} 0,0.00`
      )}`;
    }
    if (
      hoverMesh?.UnitPrice &&
      hoverMesh?.Unit &&
      neededTimeline?.currencysymb
    ) {
      return `Price : ${numeral(hoverMesh?.UnitPrice).format(
        `${neededTimeline?.currencysymb} 0,0.00`
      )}/${hoverMesh?.Unit}`;
    }

    return "unpriced item";
  };

  const price = clickedChild?.reduce((totalPrice, prevMesh) => {
    const laborTotal = prevMesh?.LaborInfo?.reduce((sum, labor) => sum + (labor?.TotalPrice || 0), 0) || 0;
    const equipmentTotal = prevMesh?.EquipmentInfo?.reduce((sum, equipment) => sum + (equipment?.TotalPrice || 0), 0) || 0;
    const mainTotalPrice = prevMesh?.TotalPrice || 0;
    return totalPrice + mainTotalPrice + laborTotal + equipmentTotal;
  }, 0).toFixed(2);
  
  // console.log('line =>', line);
  // console.log('neededTimeline =>', neededTimeline);

  return (
    <div className="timeline-viewer-container">
      <MDBBox className="viewer d-flex overflow-hidden flex-wrap">
        <MDBBox className="viewer-header viewer-header1 d-flex w-100">
          {/* { timelineArray.map((el) => {
          return (<el />)
        }) }  */}

          {/* {infoPanel}
					{pricedComponent}
					{ProjectPanel} */}

          {isFirstScreen && isSecondScreen && isThirdScreen && (
            <>
              {infoPanel}
              {pricedComponent}
              {ProjectPanel}
            </>
          )}

          {!isFirstScreen && isSecondScreen && isThirdScreen && (
            <>
              {pricedComponent}
              {ProjectPanel}
              {infoPanel}
            </>
          )}
          {isFirstScreen && !isSecondScreen && isThirdScreen && (
            <>
              {infoPanel}
              {ProjectPanel}
              {pricedComponent}
            </>
          )}
          {isFirstScreen && isSecondScreen && !isThirdScreen && (
            <>
              {infoPanel}
              {pricedComponent}
              {ProjectPanel}
            </>
          )}
          {!isFirstScreen && !isSecondScreen && isThirdScreen && (
            <>
              {ProjectPanel}
              {infoPanel}
              {pricedComponent}
            </>
          )}
          {!isFirstScreen && isSecondScreen && !isThirdScreen && (
            <>
              {pricedComponent}
              {infoPanel}
              {ProjectPanel}
            </>
          )}
          {isFirstScreen && !isSecondScreen && !isThirdScreen && (
            <>
              {infoPanel}
              {pricedComponent}
              {ProjectPanel}
            </>
          )}
          {!isFirstScreen && !isSecondScreen && !isThirdScreen && (
            <>
              {infoPanel}
              {pricedComponent}
              {ProjectPanel}
            </>
          )}

          {/* /* last container header */}

          {(!line || line.rule.prices) && (
            <div className="d-flex align-items-center justify-content-between flex-grow-1 px-2 viewer-header-item viewer-gapping h-100">
              <MDBBox tag="span" className="viewer-header-item">
                Total Cost :
                {numeral(totalEstimation).format(
                  `${neededTimeline?.currencysymb} 0,0.00`
                )}
              </MDBBox>

              {
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="checkboxTooltip"
                  />
                  <label
                    htmlFor="checkboxTooltip"
                    className="custom-control-label custom-control-label1 viewer-header-item"
                  >
                    Enable Tooltip prices
                  </label>
                </div>
              }
              <MDBBox tag="span" className="viewer-header-item">
                Selection Cost :{" "}
                {numeral(price).format(
                  `${neededTimeline?.currencysymb} 0,0.00`
                )}
              </MDBBox>
              {project ? (
                <MDBBox tag="span" className="viewer-header-item">
                  <div className="d-flex align-items-center project-admin-header ">
                    <i className="fa fa-home mr-2"></i> Project:
                    <strong className="ml-2">{project.title}</strong>
                  </div>
                </MDBBox>
              ) : null}
            </div>
          )}
        </MDBBox>

        {/* resizable main container starts */}
        <ResizableViewer
          width="100%"
          height="100%"
          panelsSize={[30, 70]}
          WebGlApp={WebGL.current}
          setResize={setResize}
          isFirstScreen={isFirstScreen}
          isSecondScreen={isSecondScreen}
          isThirdScreen={isThirdScreen}
          allowToResize={allowToResize}
        >
          {/* estimation-info container starts */}
          <MDBBox className="overflow-auto h-100 inner_resizable">
            <MDBListGroup>
              {jsonInfo ? (
                Object.entries(jsonInfo).map((keyAndValue) => {
                  const [key, value] = keyAndValue;
                  if (key === "$id") {
                    return null;
                  }

                  if (
                    line &&
                    !line.rule.prices &&
                    (key === "TotalPrice" || key === "UnitPrice")
                  ) {
                    return null;
                  }

                  return (
                    <MDBListGroupItem
                      key={key}
                      className="border border-light py-1 text-truncate"
                    >
                      {key === "TotalPrice"
                        ? `${key}: ${numeral(value).format(
                            `${neededTimeline?.currencysymb} 0,0.00`
                          )}`
                        : `${key}: ${value}`}
                    </MDBListGroupItem>
                  );
                })
              ) : isPriceView || isEstimationView ? (
                <MDBBox className="p-0">
                  <CollapsibleTable
                    data={summary}
                    currencysymb={neededTimeline?.currencysymb}
                    expanded={true}
                    priceView={isPriceView}
                    myClassName="modal-side-table"
                  />
                </MDBBox>
              ) : null}
            </MDBListGroup>
          </MDBBox>

          {/* Mashes container starts */}
          <div className="h-100 overflow-hidden inner_resizable">
            <ResizablePanels
              displayDirection="column"
              width="100%"
              height="100%"
              panelsSize={[25, 25, 25, 25]}
              resizerSize="3px"
              resizerColor="#f2f2f2"
              sizeUnitMeasure="%"
            >
              {/* priced container starts */}
              <MDBBox className="h-100 overflow-auto ">
                {jsonMeshesToJSX}
                {loading ? (
                  <MDBBox className="w-100">
                    <Loading color="black" text="Loading component..." />
                  </MDBBox>
                ) : null}
              </MDBBox>

              {/* unpriced container starts */}
              <MDBBox className="h-100 ">
                <div className="px-2 position-sticky viewer-lowerheader text-bid">
                  <MDBBox className="d-flex align-items-center h-100">
                    <img src={unpricedImg} className="unpriced-img" />

                    <MDBBox
                      tag="span"
                      className="viewer-header-item d-flex align-items-center"
                    >
                      Unpriced components
                    </MDBBox>
                  </MDBBox>
                </div>
                <MDBBox className="viewer-lowertree overflow-auto">
                  {jsonUnmatchedMeshesToJSX}
                  {loading ? (
                    <MDBBox className="w-100">
                      <Loading color="black" text="Loading component..." />
                    </MDBBox>
                  ) : null}
                </MDBBox>
              </MDBBox>

              {/* Existing container starts */}
              <MDBBox className="h-100">
                <div className="px-2 position-sticky viewer-lowerheader text-bid">
                  <MDBBox className="h-100">
                    <MDBBox
                      tag="span"
                      className="viewer-header-item d-flex align-items-center h-100"
                    >
                      {/* <div className="component-sign-label bg-existing">
                      <i className="fa fa-usd text-white"></i>
                      <i className="fa fa-minus bg-existing"></i>
                    </div> */}
                      <img src={existingImg} className="existing-img" />
                      Existing components
                    </MDBBox>
                  </MDBBox>
                </div>
                <MDBBox className="viewer-lowertree overflow-auto">
                  {jsonExistingMeshesToJSX}
                  {loading ? (
                    <MDBBox className="w-100">
                      <Loading color="black" text="Loading component..." />
                    </MDBBox>
                  ) : null}
                </MDBBox>
              </MDBBox>

              {/* Demolition container starts */}
              <MDBBox className="h-100">
                <div className="px-2 position-sticky viewer-lowerheader text-bid">
                  <MDBBox className="h-100">
                    <MDBBox
                      tag="span"
                      className="viewer-header-item d-flex align-items-center h-100"
                    >
                      {/* <div className="component-sign-label bg-demolition">
                      <i className="fa fa-usd text-white"></i>
                      <i className="fa fa-info bg-demolition"></i>
                    </div> */}
                      <img src={demolition} className="demo-img" />
                      Demolition components
                    </MDBBox>
                  </MDBBox>
                </div>
                <MDBBox className="viewer-lowertree overflow-auto">
                  {jsonDemolitionMeshesToJSX}
                  {loading ? (
                    <MDBBox className="w-100">
                      <Loading color="black" text="Loading component..." />
                    </MDBBox>
                  ) : null}
                </MDBBox>
              </MDBBox>
            </ResizablePanels>
          </div>
          {/* Mashes container ends */}

          {/* project files container starts */}
          <div style={{ height: "100%" }}>
            <ResizablePanels
              displayDirection="column"
              width="100%"
              maxheight="100%"
              panelsSize={0}
              resizerSize="3px"
              // resizerColor="#f2f2f2"
              sizeUnitMeasure="%"
            >
              <MDBBox className="overflow-auto h-86vh inner_resizable">
                {jsonFilesMeshesToJSX}
                {loading ? (
                  <MDBBox className="h-100">
                    <Loading color="black" text="Loading..." />
                  </MDBBox>
                ) : null}
              </MDBBox>
              <MDBBox></MDBBox>
            </ResizablePanels>
          </div>

          {/* 3d modal and share container starts */}
          <div className="h-100 position-relative">
            <ResizablePanels
              displayDirection="column"
              width="100%"
              height="100%"
              panelsSize={[0, 100]}
              resizerSize={menu ? "10px" : "0px"}
              resizerColor="#E6EAF4"
              sizeUnitMeasure="%"
              ref={resizeRef}
            >
              <QuantityContext.Provider
                value={{
                  Currency: currencysymb,
                  meshQuantityInfoRef: meshQuantityInfoRef.current,
                }}
              >
                <Share
                  timeline={neededTimeline}
                  rule={line?.rule}
                  pending={pending}
                  setPending={setPending}
                  withShare={withShare}
                  setWith={setWith}
                  viewerRef={viewerRef}
                  menu={menu}
                  setMenu={setMenu}
                  setPriceView={handleChangeViewPrice}
                  FiltersData={FiltersArr}
                  setTreeData={setTreeData}
                  treeData={treeData}
                  WebGL={WebGL}
                  treeFilesData={treeFilesData}
                />
              </QuantityContext.Provider>

              <div
                id="WebglViewer"
                style={{ flexGrow: 1 }}
                className="h-100 position-relative"
                ref={viewerRef}
              >
                {isPopover ? (
                  <MDBBox
                    className="position-absolute viewer-popover border border-dark"
                    style={popoverCoords}
                  >
                    {hoverMeshInfo()}
                  </MDBBox>
                ) : null}
                {loading ? (
                  <MDBBox className="h-100 w-100 flex-center flex-column">
                    <Loading color="black" text="Loading model..." />
                    {percentsLoaded ? (
                      <span>{percentsLoaded}% has loaded...</span>
                    ) : null}
                  </MDBBox>
                ) : null}
                <MDBBtnGroup
                  size="sm"
                  className="position-absolute viewer-zoom"
                >
                  <p id="clippingTooltip" />
                  <MDBBtn
                    size="sm"
                    // position="left"
                    color=""
                    className="bg-white modal3d-tool-btn "
                    onClick={() => {
                      window.location.reload(false);
                      //    {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                    }}
                  >
                    <MDBIcon icon="refresh" className="font-size-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => WebGL.current.viewer.onZoom(0.1)}
                  >
                    <MDBIcon icon="plus" className="font-size-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => WebGL.current.viewer.onZoom(-0.1)}
                  >
                    <MDBIcon icon="minus" className="font-size-1" />
                  </MDBBtn>
                  <MDBBtn
                    title="Take a screenshot of the current view"
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => WebGL.current.viewer.onScreenShot()}
                  >
                    <a id="take-snapshot">
                      <MDBIcon icon="camera" className="font-size-1" />
                      &nbsp; <b>[S] </b>
                    </a>
                  </MDBBtn>

                  <MDBBtn
                    title="Go to Home view"
                    size="sm"
                    position="left"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => {
                      WebGL.current.viewer.onInitialPosition();
                      //  {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                    }}
                  >
                    <MDBIcon icon="home" className="font-size-1" />
                    &nbsp; <b>[H] </b>
                  </MDBBtn>

                  <MDBBtn
                    title="Zoom to selected item in viewer"
                    size="sm"
                    //  position="left"

                    color=""
                    className="bg-white modal3d-tool-btn "
                    onClick={() => {
                      WebGL.current.viewer.targetItems();
                      //WebGL.current.viewer.targetItem(selectedMesh.current);
                      // {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                    }}
                  >
                    <MDBIcon icon="map-marker" className="font-size-1" />
                    &nbsp; <b>[F] </b>
                  </MDBBtn>
                  <MDBBtn
                    id="gridToggleBtn"
                    title="Toggle Grid in viewer"
                    size="sm"
                    // position="left"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
                      WebGL.current.viewer.onShowGrid(e.currentTarget);
                      //   {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                    }}
                  >
                    <MDBIcon icon="braille" className="font-size-1" />
                    &nbsp; <b>[G] </b>
                  </MDBBtn>
                  <MDBBtn
                    id="measurementToggleBtn"
                    title="Toggle Measurement Tool in viewer"
                    size="sm"
                    // position="left"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
                      WebGL.current.viewer.toggleMeasurement(e.currentTarget);
                      //   {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                    }}
                  >
                    <MDBIcon icon="tape" className="font-size-1" />
                    &nbsp; <b>[M] </b>
                  </MDBBtn>
                  <MDBBtn
                    id="clippingToggleBtn"
                    title="Toggle Clipping Tool in viewer"
                    size="sm"
                    // position="left"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
                      WebGL.current.viewer.toggleClipping(e.currentTarget);
                      //   {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                    }}
                  >
                    <MDBIcon icon="cut" className="font-size-1" />
                    &nbsp; <b>[C] </b>
                  </MDBBtn>

                  <MDBBtn
                    id="isolateToggleBtn"
                    title="Toggle Isolation Tool in viewer"
                    size="sm"
                    // position="left"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
                      WebGL.current.viewer.toggleIsolate(e.currentTarget);
                      //   {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                    }}
                  >
                    <MDBIcon icon="box" className="font-size-1" />
                    &nbsp; <b>[I] </b>
                  </MDBBtn>
                </MDBBtnGroup>
              </div>
            </ResizablePanels>
          </div>
        </ResizableViewer>

        <NoMeshModal isOpen={modal} toggle={() => setModal((s) => !s)} />
      </MDBBox>
    </div>
  );
};

export default TimelineViewer;
