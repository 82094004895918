import { addBankAccount } from "api/affiliations/addBankAccount";
import Loading from "components/ui-components/loading";
import addNotification from "helpers/notify";
import { useHttp } from "hooks/useHttp";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopNavbar from "../navbar";
import Sidebar from "../sidebar";
import {
  getCompanySettings,
  getCompanySettingsAdmin,
  putCompanySettings,
} from "api/companySettings/getCompanySettings";
import Loader from "components/Loader";
import DefaultImage from "assets/images/user.png";
import UploadIcon from "assets/images/icons/Featured icon.png";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import { uploadFileToServer } from "components/uploadFile/uploadFile";
import moment from "moment";
import { eventTrack } from "helpers/ga4Helper";

export default function CompanySettings() {
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    companyAddress: "",
    companyPhoneNumber: "",
    companyOwner: "",
    companySize: "",
    companyIndustry: "",
    dateOfExpiration: "",
  });

  const [image, setImage] = useState("");
  const [URL, SET_URL] = useState("");

  const [logoUrl, setLogoUrl] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    getCompanySettingsAPI();
  }, []);

  const [openEdit, setOpenEdit] = useState(false);

  const { loading: loadingPostSettings, request: updateSettingsAPI } = useHttp({
    requestCallback: (data) => putCompanySettings(data),
    onLoad: (res) => {
      addNotification("Success", "success");
      setOpenEdit(false);
    },
    onError: (res) => {
      addNotification(res?.err, "danger");
    },
  });

  const { loading: loadingGetSettings, request: getCompanySettingsAPI } =
    useHttp({
      requestCallback: (data) =>
        getCompanySettingsAdmin(userInfoRedux?.company),
      onLoad: (res) => {
        console.log(res);
        setCompanyDetails((prevData) => ({
          ...prevData,
          companyName: res?.companyName,
          companyAddress: res?.companyAddress,
          companyPhoneNumber: res?.companyPhoneNumber,
          companyOwner: res?.companyOwner,
          companySize: res?.companySize,
          companyIndustry: res?.companyIndustry,
          dateOfExpiration: res?.dateOfExpiration,
        }));

        setLogoUrl(res?.companyLogo);
        // SET_URL(extractImageFromZip(res?.companyLogo));
        (async () => {
          const extractedImg = await extractImageFromZip(res?.companyLogo);
          SET_URL(extractedImg);
        })();
      },
      onError: (res) => {
        // addNotification(res?.err, "danger");
      },
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (image) {
      var fileUrl;
      fileUrl = await uploadFileToServer(image);
    }

    const payload = {
      ...companyDetails,
      companyLogo: image ? fileUrl : logoUrl,
      _id: userInfoRedux?.company,
    };

    // console.log(payload);
    updateSettingsAPI(payload);
    eventTrack(
      "Update-Company-Settings-Action",
      "Update-Company-Settings",
      userInfoRedux?.name,
      "+1",
      false,
      "+1"
    );
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setOpenEdit(false);
    getCompanySettingsAPI();
  };

  const handleSearch = () => {};

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleProfileImg = async (e) => {
    const selectedImg = e.target.files[0];
    setImage(selectedImg);
  };

  return (
    <div className="personal-area">
      {loadingGetSettings && <Loader />}
      <TopNavbar handleSearch={handleSearch} />

      <div className="content-bg p-2">
        <div className="container d-flex mt-1">
          <Sidebar />

          <div className="profile-content px-0 pt-0">
            <div className="w-100 py-4 px-4 profile-div position-relative">
              <p className="font-weight-bold text-black mb-1 h5">
                Company settings
              </p>
              <p>Update your Company details here.</p>
              <button
                className="editBankAccount"
                onClick={() => setOpenEdit(true)}
              >
                <i className="fa fa-pen"></i>
              </button>
              <hr />
              <form onSubmit={handleSubmit} className="w-75">
                <div className="">
                  <img
                    src={image ? window.URL.createObjectURL(image) : URL}
                    // src={URL}
                    className="d-block mt-4"
                    style={{ width: 100, height: 100, borderRadius: 100 }}
                    // onError={(e) => (e.target.src = DefaultImage)}
                  />

                  <div
                    className={`profile-upload-box ${
                      openEdit ? "cursor-pointer" : "cursor-disabled"
                    }`}
                    onClick={openEdit && handleAvatarClick}
                  >
                    <img src={UploadIcon} />
                    <p className="font-weight-bold text-bid">
                      Click to upload company logo{" "}
                    </p>
                    <p className="font-weight-bold">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </p>

                    <input
                      ref={fileInputRef}
                      onChange={handleProfileImg}
                      type="file"
                      id="profilePicture"
                      name="profilePicture"
                      accept="image/*"
                      style={{ display: "none" }}
                      readOnly={!openEdit}
                    />
                  </div>
                </div>
                <div className="my-2">
                  <label className="labels font-weight-bold text-bid">
                    Company Name
                  </label>
                  <input
                    name="companyName"
                    onChange={handleOnchange}
                    value={companyDetails?.companyName || ""}
                    type="text"
                    className="form-control"
                    required
                    readOnly={!openEdit}
                  />
                </div>

                <div className="my-2">
                  <label className="labels font-weight-bold text-bid">
                    Company Address
                  </label>
                  <input
                    name="companyAddress"
                    onChange={handleOnchange}
                    value={companyDetails?.companyAddress || ""}
                    type="text"
                    className="form-control"
                    required
                    readOnly={!openEdit}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-between w-100 gap-3 my-2">
                  <div className="w-50">
                    <label className="labels font-weight-bold text-bid">
                      Company Phone Number
                    </label>
                    <input
                      name="companyPhoneNumber"
                      onChange={handleOnchange}
                      value={companyDetails?.companyPhoneNumber || ""}
                      type="text"
                      className="form-control"
                      required
                      readOnly={!openEdit}
                    />
                  </div>

                  <div className="w-50">
                    <label className="labels font-weight-bold text-bid">
                      Company Owner
                    </label>
                    <input
                      name="companyOwner"
                      onChange={handleOnchange}
                      value={companyDetails?.companyOwner || ""}
                      type="text"
                      className="form-control"
                      required
                      readOnly
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between w-100 gap-3 my-2">
                  <div className="w-50">
                    <label className="labels font-weight-bold text-bid">
                      Company Size
                    </label>
                    <input
                      name="companySize"
                      onChange={handleOnchange}
                      value={companyDetails?.companySize || ""}
                      type="text"
                      className="form-control"
                      readOnly={!openEdit}
                    />
                  </div>

                  <div className="w-50">
                    <label className="labels font-weight-bold text-bid">
                      Company Industry
                    </label>
                    <input
                      name="companyIndustry"
                      onChange={handleOnchange}
                      value={companyDetails?.companyIndustry || ""}
                      type="text"
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>

                <div className="w-100">
                  <label className="labels font-weight-bold text-bid">
                    Company Expiration Date
                  </label>
                  <input
                    name="dateOfExpiration"
                    onChange={handleOnchange}
                    value={
                      moment(companyDetails?.dateOfExpiration).format(
                        "DD/MM/YYYY"
                      ) || ""
                    }
                    type="text"
                    className="form-control"
                    readOnly
                  />
                </div>

                {loadingPostSettings && (
                  <Loading color="black" text="Updating..." />
                )}
                {openEdit && (
                  <div className="d-flex ml-auto w-100 mt-3">
                    <button
                      className="header-btn text-bid bg-white border-bid px-3"
                      type="button"
                      onClick={() => handleCancel()} // Uncomment or modify this if you have a cancel function
                    >
                      Cancel
                    </button>
                    <button
                      className="header-btn text-white bg-bid border-bid px-4"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
