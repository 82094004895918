import React, { useEffect, useState } from "react";
import DeleteIcon from "assets/images/icons/delete-bin-line.png";
import BulkImportIcon from "assets/images/icons/bulk.png";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import Pagination from "components/Pagination/Pagination";
import { getParameters } from "api/parameters/getParameters";
import { useHttp } from "hooks/useHttp";
import Loader from "components/Loader";
import ParameterNavbar from "./ParameterNavbar";

export default function Global({
  setParameterToDelete,
  setOpenDeleteModal,
  FinalPermissions,
  setRequestModal,
  setRequestType,
  setOpenBulkDeleteModal,
  setBulkParameterToDelete,
  bulkParameterToDelete,
  setAddParameterModal,
  userInfoRedux,
  setglobalTotalRecords,
  initialAPICall,
}) {
  const [paramsList, setParamsList] = useState([]);
  // pagination
  const [totalPage, setTotalPage] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  //
  const [filteredList, setFilteredList] = useState([]);
  const [filtered, setFiltered] = useState("");

  var pageData = {
    index: page,
    limit: limit,
    companyId: userInfoRedux?.company,
    type: "global",
  };

  const { paramsLoad, request: getParametersAPI } = useHttp({
    requestCallback: (data) => getParameters(data),
    onLoad: (res) => {
      setParamsList(res?.parameters);
      setTotalRecords(res?.totalCount);
      setglobalTotalRecords(res?.totalCount);
      setTotalPage(Math.ceil(res?.totalCount / limit));
    },
    onError: (res) => {},
  });

  useEffect(() => {
    getParametersAPI(pageData);
  }, [page, limit, initialAPICall]);

  const deletePopupOpen = (item) => {
    setParameterToDelete(item);
    setOpenDeleteModal(true);
  };

  const handleCheckBox = (e, id) => {
    const isChecked = e.target.checked;
    setBulkParameterToDelete((prev) => {
      if (isChecked) {
        return [...prev, id];
      } else {
        return prev.filter((userId) => userId !== id);
      }
    });
  };

  const handleSelectAll = (e, allParameters) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allIds = allParameters?.map((item) => item?._id);
      setBulkParameterToDelete(allIds);
    } else {
      setBulkParameterToDelete([]);
    }
  };

  const handleSearch = (keyword) => {
    const filteredResult = paramsList.filter((list) =>
      list?.name.toLowerCase().includes(keyword.toLowerCase())
    );

    setFilteredList(filteredResult);

    if (keyword.length > 0) {
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  };

  return (
    <>
      {paramsLoad ? <Loader /> : null}

      <ParameterNavbar handleSearch={handleSearch} />

      <div class="w-100 px-0 position-relative h-100">
        <div className="d-flex justify-content-between px-3 py-3 border-bottom">
          <div className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
            >
              <path
                d="M0.273438 17.7598V0.240234H9.25072L9.71511 2.40519H15.7265V12.1955H8.92443L8.46004 10.048H1.9843V17.7598H0.273438Z"
                fill="black"
              />
            </svg>
            <span className="font-weight-bold ml-3 text-black">
              Global Parameters ({totalRecords}){" "}
            </span>

            {bulkParameterToDelete?.length > 0 && (
              <div
                className="header-btn bg-white text-bid border-bid rounded-lg h6 ml-4 mb-0 cursor-pointer"
                onClick={() => {
                  FinalPermissions?.includes(
                    ALL_PERMISSIONS.PARAMETER_MGMT_DELETE
                  )
                    ? setOpenBulkDeleteModal(true)
                    : setRequestModal(true);
                  setRequestType(ALL_PERMISSIONS.PARAMETER_MGMT_DELETE);
                }}
              >
                <img src={DeleteIcon} className="w-15" />
                <span className="ml-1 f-14">
                  Delete {bulkParameterToDelete?.length} Parameters
                </span>
              </div>
            )}
          </div>

          <div className="d-flex">
            <div
              onClick={() => setAddParameterModal(true)}
              className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
              >
                <path
                  d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                  fill="#455E6C"
                />
              </svg>{" "}
              <span className="ml-2 f-14">Add Parameter</span>
            </div>

            {/* <div
              //   onClick={handleBulkClick}
              className="header-btn bg-bid text-white border-bid rounded-lg h6 ml-2 mb-0 cursor-pointer"
            >
              <img src={BulkImportIcon} className="w-15" />
              <span className="ml-1 f-14">Import Parameters</span>
            </div> */}
          </div>
        </div>
        <div className="parameter-table-header">
          <div>Name</div>
          <div>Discipline</div>
          <div>DataType</div>
          <div>Group</div>
          <div>Instance</div>
          <div></div>
        </div>
        <div className="parameter-content">
          <div className="p-2 border-bottom parameter-table">
            <div className="d-flex align-items-center div-1">
              <input
                onChange={(e) => handleSelectAll(e, paramsList)}
                type="checkbox"
                style={{ marginRight: "10px" }}
                className="squared-checkbox"
              />
              Select All
            </div>
          </div>
          {filtered
            ? filteredList?.map((item, i) => (
                <div
                  key={item?._id}
                  className="p-2 border-bottom parameter-table"
                >
                  <div className="d-flex align-items-center div-1">
                    <input
                      checked={bulkParameterToDelete?.includes(item._id)}
                      onChange={(e) => handleCheckBox(e, item._id)}
                      type="checkbox"
                      style={{ marginRight: "10px" }}
                      className="squared-checkbox"
                    />
                    {item?.name}
                  </div>
                  <div className="div-2">{item?.discipline ?? "N/A"}</div>
                  <div className="div-3">{item?.dataType ?? "N/A"}</div>
                  <div className="div-4">
                    {item?.groupParameterUnder ?? "N/A"}
                  </div>
                  <div className="div-5">{item?.isInstance ?? "N/A"}</div>

                  <div className="div-6">
                    <span
                      onClick={() => {
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.PARAMETER_MGMT_DELETE
                        )
                          ? deletePopupOpen(item)
                          : setRequestModal(true);
                        setRequestType(ALL_PERMISSIONS.PARAMETER_MGMT_DELETE);
                      }}
                      className="mr-5 ml-3 cursor-pointer table-icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="22"
                        viewBox="0 0 26 22"
                        fill="none"
                      >
                        <path
                          d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                          fill="#3A5765"
                        />
                        <path
                          d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                          fill="black"
                          fillOpacity="0.2"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              ))
            : paramsList?.map((item, i) => (
                <div
                  key={item?._id}
                  className="p-2 border-bottom parameter-table"
                >
                  <div className="d-flex align-items-center div-1">
                    <input
                      checked={bulkParameterToDelete?.includes(item._id)}
                      onChange={(e) => handleCheckBox(e, item._id)}
                      type="checkbox"
                      style={{ marginRight: "10px" }}
                      className="squared-checkbox"
                    />
                    {item?.name}
                  </div>
                  <div className="div-2">{item?.discipline ?? "N/A"}</div>
                  <div className="div-3">{item?.dataType ?? "N/A"}</div>
                  <div className="div-4">
                    {item?.groupParameterUnder ?? "N/A"}
                  </div>
                  <div className="div-5">{item?.isInstance ?? "N/A"}</div>

                  <div className="div-6">
                    <span
                      onClick={() => {
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.PARAMETER_MGMT_DELETE
                        )
                          ? deletePopupOpen(item)
                          : setRequestModal(true);
                        setRequestType(ALL_PERMISSIONS.PARAMETER_MGMT_DELETE);
                      }}
                      className="mr-5 ml-3 cursor-pointer table-icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="22"
                        viewBox="0 0 26 22"
                        fill="none"
                      >
                        <path
                          d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                          fill="#3A5765"
                        />
                        <path
                          d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                          fill="black"
                          fillOpacity="0.2"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              ))}
        </div>
        <Pagination
          totalRecords={totalRecords}
          totalPage={totalPage}
          recordsPerPage={limit}
          page={page + 1}
          onPageChange={(pageNo) => setPage(pageNo - 1)}
          limit={limit}
          onLimitChange={(limitNo) => {
            setLimit(limitNo);
          }}
        />
      </div>
    </>
  );
}
