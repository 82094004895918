import { post, get, remove } from './index';

export const addShare = (data, id) => {
  return post(`/share/${id}`, data);
};

export const rmShare = id => {
  return remove(`/share/${id}`);
};

export const getTimeline = id => {
  return get(`/share/${id}`);
};
