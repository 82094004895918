import React from "react";
import { Button, Result } from "antd";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

export const RequestCard = ({
  title,
  buttonText,
  description,
  buttonHandler,
  loading,
  status,
}) => {
  console.log("Here is my status: ", status);
  return (
    <Result
      title={title}
      icon={<InfoCircleOutlined style={{ color: "black" }} />}
      subTitle={description}
      extra={
        status ? (
          <Button
            style={{ opacity: "0.7", cursor: "default", background: "#ffffff" }}
            icon={<ClockCircleOutlined style={{ color: "black" }} />}
          >
            {" "}
            Waiting
          </Button>
        ) : (
          <Button
            type="primary"
            key="console"
            style={{ background: "#FE6919" }}
            loading={loading}
            onClick={buttonHandler}
            className="cursor-pointer"
          >
            {buttonText}
          </Button>
        )
      }
    />
  );
};
