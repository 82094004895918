import { addBankAccount } from "api/affiliations/addBankAccount";
import Loading from "components/ui-components/loading";
import { eventTrack } from "helpers/ga4Helper";
import addNotification from "helpers/notify";
import { useHttp } from "hooks/useHttp";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function AccountDetails({ data, setRefresh, refresh }) {
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const [banAccountDetails, setBanAccountDetails] = useState({
    bankName: "",
    bankNumber: "",
    routingNumber: "",
    accountNumber: "",
    ibanNumber: "",
    swiftNumber: "",
    // accountType: "",
    // accountHolderName: "",
    // accountHolderAddress: "",
    // accountHolderCity: "",
    // accountHolderState: "",
    // accountHolderZipCode: "",
    // accountHolderPhoneNumber: "",
    // accountHolderEmail: "",
  });

  useEffect(() => {
    initialData();
  }, [data]);

  const initialData = () => {
    setBanAccountDetails((prevData) => ({
      ...prevData,
      bankName: data?.user?.bankInformation?.bankName,
      bankNumber: data?.user?.bankInformation?.bankNumber,
      routingNumber: data?.user?.bankInformation?.routingNumber,
      accountNumber: data?.user?.bankInformation?.accountNumber,
      ibanNumber: data?.user?.bankInformation?.ibanNumber,
      swiftNumber: data?.user?.bankInformation?.swiftNumber,
    }));
  };

  const [openEdit, setOpenEdit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      userId: userInfoRedux?.id,
      bankInfo: banAccountDetails,
    };
    addBankAccountAPI(payload);
    eventTrack(
      "Add-Bank-Account-Action",
      "Add-Bank-Account",
      userInfoRedux?.name,
      "+1",
      false,
      "+1"
    );
  };

  const { loading: loadingGetAffiliations, request: addBankAccountAPI } =
    useHttp({
      requestCallback: (data) => addBankAccount(data),
      onLoad: (res) => {
        addNotification("Success", "success");
        setRefresh(!refresh);
        setOpenEdit(false);
      },
      onError: (res) => {
        addNotification(res?.err, "danger");
      },
    });

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setBanAccountDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setOpenEdit(false);
    initialData();
  };

  return (
    <div className="w-100 py-4 px-4 profile-div position-relative">
      <p className="font-weight-bold text-black mb-1 h5">Account details</p>
      <p>Update your Bank information and personal details here.</p>
      <button className="editBankAccount" onClick={() => setOpenEdit(true)}>
        <i className="fa fa-pen"></i>
      </button>
      <hr />
      <form onSubmit={handleSubmit} className="w-75">
        <div className="my-2">
          <label className="labels font-weight-bold text-bid">Bank Name</label>
          <input
            name="bankName"
            onChange={handleOnchange}
            value={banAccountDetails?.bankName || ""}
            type="text"
            className="form-control"
            required
            readOnly={!openEdit}
          />
        </div>

        <div className="my-2">
          <label className="labels font-weight-bold text-bid">
            Bank Number
          </label>
          <input
            name="bankNumber"
            onChange={handleOnchange}
            value={banAccountDetails?.bankNumber || ""}
            type="number"
            className="form-control"
            required
            readOnly={!openEdit}
          />
        </div>

        <div className="d-flex align-items-center justify-content-between w-100 gap-3 my-2">
          <div className="w-50">
            <label className="labels font-weight-bold text-bid">
              Routing Number
            </label>
            <input
              name="routingNumber"
              onChange={handleOnchange}
              value={banAccountDetails?.routingNumber || ""}
              type="text"
              className="form-control"
              required
              readOnly={!openEdit}
            />
          </div>

          <div className="w-50">
            <label className="labels font-weight-bold text-bid">
              Account Number
            </label>
            <input
              name="accountNumber"
              onChange={handleOnchange}
              value={banAccountDetails?.accountNumber || ""}
              type="text"
              className="form-control"
              required
              readOnly={!openEdit}
            />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between w-100 gap-3 my-2">
          <div className="w-50">
            <label className="labels font-weight-bold text-bid">
              IBAN (International Bank Account Number)
            </label>
            <input
              name="ibanNumber"
              onChange={handleOnchange}
              value={banAccountDetails?.ibanNumber || ""}
              type="text"
              className="form-control"
              readOnly={!openEdit}
            />
          </div>

          <div className="w-50">
            <label className="labels font-weight-bold text-bid">
              SWIFT / BIC
            </label>
            <input
              name="swiftNumber"
              onChange={handleOnchange}
              value={banAccountDetails?.swiftNumber || ""}
              type="text"
              className="form-control"
              readOnly={!openEdit}
            />
          </div>
        </div>

        {loadingGetAffiliations && <Loading color="black" text="adding..." />}
        {openEdit && (
          <div className="d-flex ml-auto w-100 mt-3">
            <button
              className="header-btn text-bid bg-white border-bid px-3"
              type="button"
              onClick={() => handleCancel()} // Uncomment or modify this if you have a cancel function
            >
              Cancel
            </button>
            <button
              className="header-btn text-white bg-bid border-bid px-4"
              type="submit"
            >
              Save
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
