import { connect } from 'react-redux';

import withDeleteModal from '../hoc/withDeleteModal';

import { removeUser } from 'api/users/removeUser';
import { adminDeleteUser } from 'actions/admin';

const DeleteUserFromCompanyModal = withDeleteModal({
  request: removeUser,
  itemLabel: 'user',
});

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    reduxRemover: user => dispatch(adminDeleteUser(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteUserFromCompanyModal);
