import { url } from "api";
import JSZip from "jszip";

export const extractImageFromZip = async (uri) => {
  try {
    if (uri?.split(".")?.pop() !== "gz") {
      return uri;
    } else {
      const response = await fetch(`${url}/uploads/${uri}`);
      const data = await response.arrayBuffer();
      const zip = await JSZip.loadAsync(data);

      // Filter the files to only include image files
      const imageFiles = Object.keys(zip.files).filter((filename) =>
        /\.(jpe?g|png|gif)$/i.test(filename)
      );

      const imageUrls = await Promise.all(
        imageFiles.map(async (filename) => {
          const blob = await zip.file(filename).async("blob");
          return window.URL.createObjectURL(blob);
        })
      );
      return imageUrls;
    }
  } catch (error) {
    // console.error("Error extracting images from zip:", error);
    return uri; // Return zip link if there's an error
  }
};
