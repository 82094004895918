import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

export default function ProjectChart({ chartData }) {
  const [data, setData] = useState([]);
  const getOverallEstimationCost = () => {
    const res = chartData?.map((item) => {
      return {
        name: item?.projectId?.title,
        hours: item?.numberOfHours,
      };
    });

    setData(res);
  };

  useEffect(() => {
    getOverallEstimationCost();
  }, [chartData]);

  const tickFormatter = (value, index) => {
    const limit = 10; // put your maximum character
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}...`;
 };


  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        data={data}
        barSize={25}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="0 3" />
        <XAxis
          dataKey="name"
          padding={{ left: 0, right: 0 }}
          angle={-40}
          textAnchor="end"
          tick={{ fontSize: "12px" }}
          tickFormatter={tickFormatter}
        />
        <YAxis yAxisId="left" orientation="left" stroke="#283f4b" />3
        <Tooltip />
        <Bar
          yAxisId="left"
          dataKey="hours"
          fill="#153448"
          radius={[5, 5, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
