export const HEALTH_SETTINGS_FORM_FEILDS = {
  maxSizeOfProject: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  maxUnusedFamilies: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  usedFamilies: {
    maxCount: {
      value: 50,
      importance: "normal",
      include: true,
    }
  },
  totalFamilies: {
    maxCount: {
      value: 50,
      importance: "normal",
      include: true,
    },
  },
  worksets: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    }
  },
  views: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  phases: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  viewSheets: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
    maxViewsPerSheet: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  sharedParameters: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: 10,
      type: "number",
      importance: "normal",
      include: true
    },
  },
  projectParameters: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: 10,
      type: "number",
      importance: "normal",
      include: true
    }
  },
  globalParameters: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: 10,
      type: "number",
      importance: "normal",
      include: true
    }
  },
  maxParametersCount: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  linkedRevit: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  linkedImage: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  linkedCAD: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  linkedPDF: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  linkedModel: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  importedCADFiles: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  listOfLevels: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  listOfLevelTypes: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  listOfGrids: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  listOfGridTypes: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  rooms: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  spaces: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  panelScheduleViews: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  viewSchedule: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  modelName: {
    prefix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    suffix: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
  },
  modelArea: {
    inputValue: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  modelHeight: {
    inputValue: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  buildingType: {
    inputValue: {
      value: "office",
      type: "string",
      importance: "normal",
      include: false,
    },
  },
  warnings: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  unusedElements: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  nonBuiltInObjectStyle: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  modelGroups: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  detailGroups: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  inPlaceFamiles: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  designOptions: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  loadedFamilies: {
    maxCount: {
      value: 50,
      type: "number",
      importance: "normal",
      include: true,
    }
  },
  projectCoordinates: {
    ew: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    ns: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    longitude: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    latitude: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    elevation: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    angle: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
  },
  surveyCoordinates: {
    ew: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    ns: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    longitude: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    latitude: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    elevation: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
    angle: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
  },
  linkedRevitFilesNotInPlace: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  linkedCADFilesVisibleInAllViews: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  linkedCADFilesNotPinnedInPlace: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  wrongElementsOnSharedLevelsAndGrid: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  sharedLevelsAndGridOnWrongWorkset: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  unPlacedRooms: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  redundantAndUnEnclosedRooms: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  unSpaces: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  redundantAndUnEnclosedSpaces: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  placeHolderSheets: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  viewTemplates: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  viewWithHiddenElements: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  viewWithNoViewTemplate: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  viewsNotOnSheet: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  viewsOnSheetWithNoViewTemplate: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  sheetsWithOutView: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  legendViews: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  scopeBoxes: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  duplicateModeledElements: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  mirroredElements: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  assemblies: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  genericModels: {
    maxCount: {
      value: "10",
      type: "number",
      importance: "normal",
      include: true,
    },
  },
  isUniqueRoomNumber: {
    inputValue: {
      value: false,
      type: "boolean",
      importance: "normal",
      include: false,
    },
  },
  isCloudpath: {
    inputValue: {
      value: false,
      type: "boolean",
      importance: "normal",
      include: false,
    },
  },
  centralServerPath: {
    inputValue: {
      value: "",
      type: "text",
      importance: "normal",
      include: false,
    },
  },
};
