

import React, {useEffect, useState, useRef} from 'react'
import Sidebar from './sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { useHttp } from "hooks/useHttp";


import { getPermissionOfUser } from 'api/permission/getPermissions';


const Permissions = () => {
    const dispatch= useDispatch()
    const [users, setUsers]= useState([])
    const userInfoRedux = useSelector((s) => s.user.userInfo);

    const [usersToDelete, setUsersToDelete]= useState([])

    const { request: getPermissionAPI } = useHttp({
        requestCallback: getPermissionOfUser,
        onLoad: (res) => {
            setUsers(res?.data)
        },
        onError: (res) => {},
      });

   
    useEffect(()=>{
        getPermissionAPI(userInfoRedux.company)
    },[])
 
    const handleCheckBox = (e, id) => {
        const isChecked = e.target.checked;
      
        // Check if the user is already in the array
        const index = usersToDelete.indexOf(id);
      
        // If checked and not in array, add to the array
        if (isChecked && index === -1) {
          setUsersToDelete((prev) => [...prev, id]);
        }
      
        // If unchecked and in array, remove from the array
        if (!isChecked && index !== -1) {
          setUsersToDelete((prev) => prev.filter((userId) => userId !== id));
        }
      
    };

    return (
        <div style={{paddingBottom:"70px"}} class="d-flex justify-content-center">
            <Sidebar />
            <div className='mx-2 shadow-sm rounded' style={{ padding: "10px", width: "60%", paddingTop:"20px", paddingBottom:"20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bag-fill" viewBox="0 0 16 16">
                            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4z"/>
                        </svg>
                        <span style={{marginTop:"5px"}} className="font-weight-bold ml-2">Permissions</span>
                        
                        
                    </div>

                    <div style={{ marginBottom: "5px", textAlign: "center", border: "1px solid #3A5765", cursor: "pointer" }} className="rounded p-1">
                        Save Changes
                    </div>
                    
                  
                </div>
                <div style={{borderBottom:"2px solid #E2E2E2", borderTop:"2px solid #E2E2E2",  paddingLeft:"30px"}} className='pt-3 pb-2'>User Name</div>
                    {
                        users?.map((user, i)=>{
                            if(userInfoRedux.id == user._id || user?.companyAdmin) return
                        
                            return (
                             <>   
                            <div key={i} style={{borderBottom:"2px solid #E2E2E2"}} className='d-flex d-flex justify-content-between  pt-3 pb-2'>
                                <div style={{display:"flex", alignItems:"center", paddingLeft:"10px"}}>
                                    <input checked={usersToDelete?.includes(user._id)} onChange={(e)=>handleCheckBox(e,user._id)} type='checkbox' style={{marginRight:"10px"}}/>   
                                    <img
                                        src={user?.profilePhoto}
                                        //alt={`Avatar for ${row.name}`}
                                        style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                                    />
                                    <div className='ml-2'>  {user?.username} </div>
                            
                                </div>
                                <div className='mr-4 cursor-pointer' data-toggle="collapse" data-target={`#${user._id}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"/>
                                    </svg>
                                </div>
                                
                            </div>
                            {/* id={`#${user._id}`} */}
                            <div className="collapse" id={`${user._id}`}>
                                <div style={{marginTop:"10px"}}>
                                    <input type='checkbox' style={{marginLeft:"50px"}} value={"user"}/> User
                                </div>
                                <div style={{marginTop:"4px"}}>
                                    <input type='checkbox' style={{marginLeft:"50px"}} value={"user"}/> User
                                </div>
                               
                               
                            </div>
                            </>
                        )
                        })
                    }
                </div>

           
        </div>
    )
}

export default Permissions