import React from 'react';
import { MDBBox } from 'mdbreact';

const Error = () => {
  return (
    <MDBBox className="d-flex flex-column align-items-center text-danger font-size-2 mt-3">
      <MDBBox>Something went wrong while loading the data.</MDBBox>
      <MDBBox className="mt-1">Please try again or try later.</MDBBox>
    </MDBBox>
  );
};

export default Error;
