import { REFRESH_PARAMETERS } from "../constants/action-types/extras";

const initialState = {
  refreshParameters: null,
};

export default function extrasReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_PARAMETERS:
      return {
        ...state,
        refreshParameters: action.payload,
      };

    default:
      return state;
  }
}
