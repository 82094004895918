import React, { useState } from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
} from "mdbreact";
import { Modal, Button } from "antd";
import {
  RullerIcon,
  UserLocationIcon,
  ProjectIcon,
  ProfileIcon,
  CheckInIcon,
  DownArrowIcon,
  DatabaseIcon
} from "assets/svg-icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setNewLaborType,
  setNewTemplate,
  setNewProjectType,
  setTemplates,
} from "actions/pricing";
import {
  addNewLaborType,
  addNewProjectType,
  addNewTemplate,
} from "api/database";
import addNotification from "helpers/notify";
import { eventTrack } from "helpers/ga4Helper";

const formatsDataArray = [
  { label: "RevitUniFormat", value: "catalogs" },
  { label: "MasterFormat", value: "catalogs_masters" },
  { label: "CSIUniFormat", value: "catalogs_uniformats" },
  { label: "OmniClass", value: "catalogs_omnis" },
  { label: "TeslaMasterFormat", value: "catalogs_tesla_masters" },
];

const unitsData = [
  { label: "Imperial", value: ["SF", "LF", "CY", "SY", "TON", "LB", "EA"] },
  { label: "Metric", value: ["SM", "LM", "CM", "TONNE", "KG", "EA"] },
];

const dataBaseSources = ["BidLight", "OneBuild", "Craftsman"];

export const AddNewVersionModal = ({
  closeModal,
  OrganizationId,
  setIsLoading,
  handleSelectTemplate,
  selectedCompany,
}) => {
  const projectTypes = useSelector((s) => s.pricing?.projectTypes);
  const laborTypes = useSelector((s) => s.pricing?.laborTypes);
  const countriesRedux = useSelector((s) => s.pricing?.countries);
  const userInfoRedux = useSelector((s) => s.user?.userInfo);
  const [countries, setCountries] = useState(countriesRedux);
  const [countryState, setCountryState] = useState(null);
  const [countryStatesData, setCountryStatesData] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);

  const [allCurrencies, setAllCurrencies] = useState([]);
  const [allFormats, setAllFormats] = useState(formatsDataArray);
  const [cities, setCities] = useState();
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [laborType, setLaborType] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedDatabaseSource, setSelectedDatabaseSource] = useState(null);
  const [selectedForrmat, setSelectedFormat] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const [templateCode, setTemplateCode] = useState("");
  const [searchedLaborTypeText, setSearchedLaborTypeText] = useState(null);
  const [searchedProjectTypeText, setSearchedProjectTypeText] = useState(null);
  const dispatch = useDispatch();

  const [allLaborTypes, setAllLaborTypes] = useState(laborTypes);
  const [allProjectTypes, setAllProjetTypes] = useState(projectTypes);
  const isTeslaUser =
    userInfoRedux?.email &&
    userInfoRedux?.email?.toLowerCase().endsWith("@tesla.com");

  const company = selectedCompany?.value ?? OrganizationId;

  const handleSelectCountry = (selectedCountry) => {
    if (!selectedCountry) return;
    const statesData = selectedCountry?.states?.map((item) => item.name);
    const currencies = countriesRedux?.map((country) => country.currency);
    setAllCurrencies(currencies);
    setCountry(selectedCountry);
    setDefaultCurrency(selectedCountry.currency);
    setCountryState(null);
    setCity(null);
    statesData.length && setCountryStatesData(statesData);
    setAllStates(statesData);
  };

  /**
   * handleSelectState function to select state from dropdown
   * getting cities data accrording to selected state
   */
  const handleSelectState = (state) => {
    if (state) {
      const selectedState = country?.states?.find(
        (item) => item.name === state
      );
      const citiesData = selectedState?.cities?.map((item) => item.name);
      setCity(null);
      setCountryState(selectedState);
      setAllCities(citiesData);
      citiesData.length && setCities(citiesData);
    }
  };

  const handleCreateProjectType = async () => {
    if (!searchedProjectTypeText) return;
    const payload = {
      Code: "",
      Category: searchedProjectTypeText,
      APIUrlIdentifier: "testing",
      isPublic: true,
    };

    const { data } = await addNewProjectType(company, payload);

    if (data) {
      addNotification("New Project type created successfully!", "success");
      dispatch(setNewProjectType(data[0]));
    } else {
      addNotification("Facing error while adding project type", "danger");
    }
    setSearchedProjectTypeText(null);
  };

  const handleCreateLaborType = async () => {
    if (!searchedLaborTypeText) return;
    const payload = {
      Category: searchedLaborTypeText,
      APIUrlIdentifier: "testing",
    };

    const { data } = await addNewLaborType(company, payload);

    if (data) {
      addNotification("New Labor type created successfully!", "success");
      dispatch(setNewLaborType(data[0]));
    } else {
      addNotification("Facing error while adding labor type", "danger");
    }
    setSearchedLaborTypeText(null);
  };

  const handleCreatetemplate = async () => {
    try {
      if (
        !templateCode ||
        !country ||
        !projectType ||
        !laborType ||
        !selectedUnit ||
        !selectedForrmat
      ) {
        const missingData = !templateCode
          ? "template code"
          : !country
          ? "country"
          : !projectType
          ? "Project Type"
          : !laborType
          ? "Labor Type"
          : !selectedForrmat
          ? "Protocol"
          : !selectedUnit
          ? "Unit"
          : null;
        addNotification(`Please Add ${missingData && missingData}`, "danger");
        return;
      }
      setIsLoading("table-data");
      const payload = [
        {
          Protocol: selectedForrmat,
          Code: templateCode,
          Country: country?.iso3,
          State: countryState?.state_code,
          City: city,
          ProjectType: projectType,
          LaborType: laborType,
          Currency: country?.currency,
          Measurements: selectedUnit,
          DatabaseSource: selectedDatabaseSource,
          isPublic:
            selectedCompany?.value && userInfoRedux?.admin ? true : false,
        },
      ];
      const { data } = await addNewTemplate(company, payload);
      const response = data[0];
      if (response.err) {
        addNotification(
          response.err ??
            "There is issue while creating template, Please connect support",
          "danger"
        );
      } else {
        setTimeout(() => {
          const structureResponse = {
            default: response,
            company: response,
            average: response,
          };
          dispatch(setTemplates([structureResponse]));
          handleSelectTemplate(structureResponse);
          setIsLoading("");
          addNotification("New template created successfully!", "success");
        }, 2000);
      }
      setIsLoading("");
      closeModal();
    } catch (error) {
      console.log(error, "danger");
      setIsLoading("");
      throw error;
    }
  };

  return (
    <Modal
      open
      title="Add New Template"
      onCancel={closeModal}
      footer={(_, { OkBtn, CancelBtn }) => (
        <span>
          <div
            onClick={() => {
              handleCreatetemplate();
              {
                eventTrack(
                  "Create-Catelogues-Template-Action",
                  "Create-Catelogues-Template",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }
            }}
            style={{
              width: "100%",
              backgroundColor: "#3A5765",
              color: "white",
              padding: 10,
              display: "flex",
              justifyContent: "center",
              borderRadius: 2,
              cursor: "pointer",
              marginBottom: 5,
            }}
          >
            <span>Confirm</span>
          </div>
          <Button
            onClick={() => {
              closeModal();
            }}
            style={{ width: "100%" }}
          >
            Cancel
          </Button>
        </span>
      )}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {/*  template name field */}
        <div className="placholder-main">
          <div className="input-placeholder">
            <input
              value={templateCode}
              data-end="*"
              onChange={(e) => setTemplateCode(e.target.value)}
              name="key"
              type="text"
              className="form-control"
            />
            {templateCode.length < 1 ? (
              <div className="placeholder">
                Template Code<span>*</span>
              </div>
            ) : null}
          </div>
        </div>
        {/* country dropdown */}
        <MDBDropdown dropend group className="w-100 dropdownListItems">
          <MDBDropdownToggle
            className={`pricingDropdown d-flex justify-content-between`}
          >
            <div className="d-flex align-items-center gap-2">
              <CheckInIcon />
              <span className="dropdownItems">
                {country?.name || "Country"}
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            <div className="dropDownInput">
              <input
                onChange={(e) => {
                  const filteredCountries = countriesRedux.filter((country) =>
                    country.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                  setCountries(filteredCountries);
                }}
                name="key"
                type="text"
                className="form-control"
                placeholder="Search Country"
              />
            </div>
            <div className="list">
              {(countries || []).map((option, index) => (
                <MDBDropdownItem
                  key={`${index}_${option?.name}`}
                  onClick={() => handleSelectCountry(option)}
                >
                  {option.name}
                </MDBDropdownItem>
              ))}
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>
        {/* state dropdown */}
        <MDBDropdown dropend group className="w-100 dropdownListItems">
          <MDBDropdownToggle
            className={`pricingDropdown d-flex justify-content-between ${
              !country && "disabled"
            }`}
          >
            <div className="d-flex align-items-center gap-2">
              <CheckInIcon />
              <span className="dropdownItems ">
                {countryState?.name || "State"}
              </span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            <div className="dropDownInput">
              <input
                onChange={(e) => {
                  const filteredStates = allStates.filter((state) =>
                    state.toLowerCase().includes(e.target.value.toLowerCase())
                  );
                  setCountryStatesData(filteredStates);
                }}
                name="key"
                type="text"
                className="form-control"
                placeholder="Search State"
              />
            </div>
            <div className="list">
              {(countryStatesData || []).map((option, index) => (
                <MDBDropdownItem
                  key={`${index}_index`}
                  onClick={() => handleSelectState(option)}
                >
                  {option}
                </MDBDropdownItem>
              ))}
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/* city dropdown */}
        <MDBDropdown dropend group className="w-100 dropdownListItems">
          <MDBDropdownToggle
            className={`pricingDropdown d-flex justify-content-between ${
              !countryState && "disabled"
            }`}
          >
            <div className="d-flex align-items-center gap-2">
              <CheckInIcon />
              <span className="dropdownItems">{city || "City"}</span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            <div className="dropDownInput">
              <input
                onChange={(e) => {
                  const filteredCities = allCities.filter((city) =>
                    city.toLowerCase().includes(e.target.value.toLowerCase())
                  );
                  setCities(filteredCities);
                }}
                name="key"
                type="text"
                className="form-control"
                placeholder="Search City"
              />
            </div>
            <div className="list">
              {(cities || [])?.map((option, index) => (
                <MDBDropdownItem
                  key={`${index}_index`}
                  onClick={() => {
                    setCity(option);
                  }}
                >
                  {option}
                </MDBDropdownItem>
              ))}
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/* Labor type dropdown */}
        <MDBDropdown dropend group className="w-100 dropdownListItems">
          <MDBDropdownToggle
            className={`pricingDropdown d-flex justify-content-between`}
          >
            <div className="d-flex align-items-center gap-2">
              <ProfileIcon />
              <span className="dropdownItems">
                {laborType || "Labor Type"}
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            <div className="dropDownInput">
              <input
                onChange={(e) => {
                  setSearchedLaborTypeText(e.target.value);
                  const filteredLaborTypes = laborTypes?.filter((type) =>
                    type?.company?.Category?.toLowerCase().includes(
                      e.target.value.toLowerCase()
                    )
                  );
                  setAllLaborTypes(filteredLaborTypes);
                }}
                name="key"
                type="text"
                className="form-control"
                placeholder="Search Labor Type"
              />
              {searchedLaborTypeText && !allLaborTypes.length ? (
                <button className="btnDropdown" onClick={handleCreateLaborType}>
                  <MDBIcon fas icon="plus" /> Create {searchedLaborTypeText}
                </button>
              ) : null}
            </div>
            <div className="list">
              {(allLaborTypes || []).map((option, index) => (
                <MDBDropdownItem
                  key={`${index}_index`}
                  onClick={() => {
                    setLaborType(option?.company?.Category);
                  }}
                >
                  {option?.company?.Category}
                </MDBDropdownItem>
              ))}
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/* Project type dropdown */}
        <MDBDropdown dropend group className="w-100 dropdownListItems">
          <MDBDropdownToggle
            className={`pricingDropdown d-flex justify-content-between`}
          >
            <div className="d-flex align-items-center gap-2">
              <ProjectIcon />
              <span className="dropdownItems">
                {projectType || "Project Type"}
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            <div className="dropDownInput">
              <input
                onChange={(e) => {
                  setSearchedProjectTypeText(e.target.value);
                  const filteredProjectTypes = projectTypes?.filter((type) =>
                    type?.company?.Category?.toLowerCase().includes(
                      e.target.value.toLowerCase()
                    )
                  );
                  setAllProjetTypes(filteredProjectTypes);
                }}
                name="key"
                type="text"
                className="form-control"
                placeholder="Search Project Type"
              />
              {searchedProjectTypeText && allProjectTypes ? (
                <button
                  className="btnDropdown"
                  onClick={() => {
                    handleCreateProjectType();
                    {
                      eventTrack(
                        "Create-Project-Type-Catelogues-Action",
                        "Create-Project-Type-Catelogues",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }
                  }}
                >
                  <MDBIcon fas icon="plus" /> Create {searchedProjectTypeText}
                </button>
              ) : null}
            </div>
            <div className="list">
              {(allProjectTypes || []).map((option, index) => (
                <MDBDropdownItem
                  key={`${index}_index`}
                  onClick={() => setProjectType(option?.company?.Category)}
                >
                  {option?.company?.Category}
                </MDBDropdownItem>
              ))}
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/*  Currencies dropdown */}
        <MDBDropdown dropend group className="w-100 dropdownListItems">
          <MDBDropdownToggle
            className={`pricingDropdown d-flex justify-content-between ${
              !country && "disabled"
            }`}
          >
            <div className="d-flex align-items-center gap-2">
              <CheckInIcon />
              <span className="dropdownItems">
                {" "}
                {defaultCurrency || "Currency"}
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            <div className="dropDownInput">
              <input
                onChange={(e) => {
                  const currencies = countriesRedux.map(
                    (country) => country.currency
                  );
                  const filteredCurrencies = currencies.filter((currency) =>
                    currency
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                  const nonRepeatCurrencies = [...new Set(filteredCurrencies)];
                  setAllCurrencies(nonRepeatCurrencies);
                }}
                name="key"
                type="text"
                className="form-control"
                placeholder="Search Currency"
              />
            </div>
            <div className="list">
              {allCurrencies.map((currency, index) => (
                <MDBDropdownItem
                  key={`${index}_index`}
                  onClick={() => setDefaultCurrency(currency)}
                >
                  {currency}
                </MDBDropdownItem>
              ))}
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/* units dropdown */}
        <MDBDropdown dropend group>
          <MDBDropdownToggle className="pricingDropdown d-flex justify-content-between">
            <div className="dropdowntoggle d-flex align-items-center gap-2">
              <RullerIcon />
              <span className="dropdownItems">
                {selectedUnit || "Units"}
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            {(unitsData || []).map((option, index) => (
              <MDBDropdownItem
                key={`${index}_${option?.label}`}
                onClick={() => {
                  setSelectedUnit(option.label);
                }}
              >
                {option.label}
              </MDBDropdownItem>
            ))}
          </MDBDropdownMenu>
        </MDBDropdown>

        {/* formats dropdown */}
        <MDBDropdown dropend group>
          <MDBDropdownToggle className="pricingDropdown d-flex justify-content-between">
            <div className="dropdowntoggle d-flex align-items-center gap-2">
              <UserLocationIcon />
              <span className="dropdownItems">
                {selectedForrmat || "Formats"}
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            <div className="dropDownInput">
              <input
                onChange={(e) => {
                  const filteredFormats = formatsDataArray.filter((format) =>
                    format.label
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                  setAllFormats(filteredFormats);
                }}
                name="key"
                type="text"
                className="form-control"
                placeholder="Search Formats"
              />
            </div>
            <div className="list">
              {(
                allFormats.filter((format) =>
                  !isTeslaUser ? format?.label !== "TeslaMasterFormat" : format
                ) || []
              ).map((option, index) => (
                <MDBDropdownItem
                  key={`${index}_index`}
                  onClick={() => {
                    setSelectedFormat(option.label);
                  }}
                >
                  {option.label}
                </MDBDropdownItem>
              ))}
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/* Database source dropdown */}
        <MDBDropdown dropend group>
          <MDBDropdownToggle className="pricingDropdown d-flex justify-content-between">
            <div className="dropdowntoggle d-flex align-items-center gap-2">
              <DatabaseIcon color="#9C9C9C" />
              <span className="dropdownItems">
                {selectedDatabaseSource || "Database Source"}
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>

            <div>
              <DownArrowIcon />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="w-100 border-none" responsive="end">
            {(dataBaseSources || []).map((option, index) => (
              <MDBDropdownItem
                key={`${index}_${option?.label}`}
                onClick={() => {
                  setSelectedDatabaseSource(option);
                }}
              >
                {option}
              </MDBDropdownItem>
            ))}
          </MDBDropdownMenu>
        </MDBDropdown>
      </div>
    </Modal>
  );
};
