import React from "react";
import { Link } from "react-router-dom";
import styles from "./tag.module.css";

const Tag = ({ children, className, count, ...props }) => {
  return count ? (
    <div>
      {/* <Link to={{ pathname: "/learning", query: { tag: children } }} className={styles.tag}>{children}</Link> */}
      <Link to={`${"/learning/tags"}/${children}`} className={styles.tag}>
        {children}
      </Link>
      <span className={styles.multiplier}>×</span>
      &nbsp;
      <span className={styles.count}>{count}</span>
    </div>
  ) : (
    <Link to={`${"/learning/tags"}/${children}`} className={styles.tag}>
      {children}
    </Link>
  );
};

export default Tag;
