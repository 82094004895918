import React, { useState, useEffect } from "react";
import KanbanCard from "../KanbanCard/KanbanCard";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { FROM_NEWER_TO_OLDER, FROM_OLDER_TO_NEWER, sortByDate } from "helpers/sortByDate";

const Milestone = ({
  mile,
  index,
  projects,
  color,
  // placeholderProps,
  isAdmin,
  setModal,
  setProjectToDelete,
  setProjectToUpdate,
  setProjectListToEdit,
}) => {
  const [fontSize, setFontSize] = useState();

  const [FinalProjectList, setFinalProjectList] = useState([]);

  useEffect(() => {
    setInitialData();
  }, [projects]);

  const setInitialData = () => {
    const filteredProjects = projects.filter(
      (project) => project.milestone == mile._id
    );
    // console.log(filteredProjects)
    setFinalProjectList(sortByDate(filteredProjects, FROM_NEWER_TO_OLDER));
  }

  useEffect(() => {
    const updateWidth = () => {
      const newWidth = window.innerWidth;
      if (newWidth < 900) {
        setFontSize(7);
      } else if (newWidth < 1157) {
        setFontSize(7);
      } else {
        setFontSize(7);
      }
    };

    window.addEventListener("resize", updateWidth);

    updateWidth();

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);


  return (
    <Draggable draggableId={mile?._id} index={index}>
      {(provided)=> (
    <div  {...provided.draggableProps} ref={provided.innerRef} className="board-box">
      <div
      {...provided.dragHandleProps}
        className="board-box-header pb-2 mb-2"
        style={{ borderColor: color }}
      >
        <span className="color-dot" style={{ backgroundColor: color }}></span>
        <span
          className="f-16 text-black"
        >
          {mile?.key}
        </span>
        <span className="count">{FinalProjectList?.length}</span>
      </div>

      <Droppable droppableId={mile._id} type="PROJECT">
        {(provided, snapshot) => (
          <div
            className="card-container d-flex flex-column"
            key={mile._id}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {FinalProjectList.map((project, index) => (
              <KanbanCard
                key={index}
                project={project}
                index={index}
                isAdmin={isAdmin}
                setModal={setModal}
                setProjectToDelete={setProjectToDelete}
                setProjectToUpdate={setProjectToUpdate}
                setProjectListToEdit={setProjectListToEdit}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
    )}
    </Draggable>
  );
};

export default Milestone;
