const handleGenrateDivisionCode = (protocol, parentDV, isParent) => {
  const parentLevel = parseInt(parentDV.Level);
  if (protocol !== "TeslaMasterFormat" && parentLevel === 5) {
    return null;
  }
  if (protocol === "MasterFormat") {
    return makeMasterFormatDivisionCode(parentDV, isParent);
  } else if (protocol === "OmniClass") {
    return makeOmniClassDivisionCode(parentDV, isParent);
  } else if (protocol === "CSIUniFormat") {
    return makeCSIUniFormatDivisionCode(parentDV, isParent);
  } else if (protocol === "TeslaMasterFormat") {
    return makeTeslaMasterFormatDivisionCode(parentDV, isParent);
  } else if (protocol === "RevitUniFormat") {
    return makeRevitUnitFormatCode(parentDV, isParent);
  } else {
    return makeUniFormatDivisionCode(parentDV, isParent);
  }
};

function makeMasterFormatDivisionCode(parentDV, isParent) {
  let code = null;

  const parentLevel = parseInt(parentDV.Level);
  if (isParent) {
    for (let i = 1; i < 100; i++) {
      const childDivision = `${i.toString().padStart(2, "0")} 00 00`;
      if (
        !parentDV.some(
          (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  } else {
    const splittedCode = parentDV.Division?.split(" ");
    if (!splittedCode || splittedCode.length !== 3) return null;

    switch (parentLevel) {
      case 1:
        for (let i = 10; i < 100; i += 10) {
          const childDivision = `${splittedCode[0].trim()} ${i
            .toString()
            .padStart(2, "0")} 00`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 2:
        for (let i = 1; i <= 9; i++) {
          const childDivision = `${splittedCode[0].trim()} ${
            splittedCode[1].trim()[0]
          }${i.toString()} 00`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 3:
        for (let i = 1; i <= 99; i++) {
          const childDivision = `${splittedCode[0].trim()} ${splittedCode[1].trim()} ${i
            .toString()
            .padStart(2, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 4:
        for (let i = 1; i <= 99; i++) {
          const childDivision = `${parentDV.Division}.${i
            .toString()
            .padStart(2, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 5:
        for (let i = 0; i < 1000; i++) {
          const childDivision = `${parentDV.Division} ${i
            .toString()
            .padStart(3, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x?.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
    }
  }

  return code;
}

function makeOmniClassDivisionCode(parentDV, isParent) {
  let code = null;

  const parentLevel = parseInt(parentDV.Level);
  if (isParent) {
    for (let i = 51; i < 100; i++) {
      const childDivision = i.toString().padStart(2, "0");
      if (
        !parentDV.some(
          (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  } else {
    switch (parentLevel) {
      case 1:
        for (let i = 1; i < 100; i++) {
          const childDivision = `${parentDV.Division}-${i
            .toString()
            .padStart(2, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 2:
      case 3:
      case 4:
        for (let i = 10; i < 100; i += 10) {
          const childDivision = `${parentDV.Division} ${i
            .toString()
            .padStart(2, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 5:
        for (let i = 0; i < 1000; i += 1) {
          const childDivision = `${parentDV?.Division}.${i
            .toString()
            .padStart(3, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x?.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
    }
  }

  return code;
}

function makeTeslaMasterFormatDivisionCode(parentDV, isParent) {
  let code = null;

  const parentLevel = parseInt(parentDV.Level);
  if (parentLevel === 7) {
    return null;
  }
  if (isParent) {
    for (let i = 1; i < 100; i++) {
      const childDivision = `${i.toString().padStart(2, "0")} 00 00`;
      if (
        !parentDV.some(
          (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  } else {
    const splittedCode1 = parentDV.Division?.split(" ");
    if (!splittedCode1 || splittedCode1.length < 3 || splittedCode1.length > 6)
      return null;

    const lvl1 = splittedCode1[0].trim();
    const lvl2 = splittedCode1[1].trim();
    const lvl3SplittedCode = splittedCode1[2].split(".");
    const lvl3 = lvl3SplittedCode[0].trim();
    let lvl4 = null;
    let lvl5 = null;
    let lvl6 = null;
    let lvl7 = null;
    if (lvl3SplittedCode.length === 2) lvl4 = lvl3SplittedCode[1];
    if (splittedCode1.length > 3) lvl5 = splittedCode1[3];
    if (splittedCode1.length > 4) lvl6 = splittedCode1[4];
    if (splittedCode1.length > 5) lvl7 = splittedCode1[5];

    switch (parentLevel) {
      case 1:
        for (let i = 10; i <= 99; i += 10) {
          const childDivision = `${lvl1} ${i.toString().padStart(2, "0")} 00`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 2:
        for (let i = 1; i <= 99; i++) {
          const childDivision = `${lvl1} ${lvl2} ${i.toString()}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 3:
        for (let i = 1; i <= 99; i++) {
          const childDivision = `${lvl1} ${lvl2} ${lvl3}.${i
            .toString()
            .padStart(2, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 4:
        for (let i = 1; i <= 999; i++) {
          const childDivision = `${lvl1} ${lvl2} ${lvl3}.${lvl4} ${i
            .toString()
            .padStart(3, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 5:
        for (let i = 1; i <= 9999; i++) {
          const childDivision = `${lvl1} ${lvl2} ${lvl3}.${lvl4} ${lvl5} ${i
            .toString()
            .padStart(4, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 6:
        for (let i = 1; i <= 9999; i++) {
          const childDivision = `${lvl1} ${lvl2} ${lvl3}.${lvl4} ${lvl5} ${lvl6} ${i
            .toString()
            .padStart(4, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
      case 7:
        for (let i = 0; i < 1000; i++) {
          const childDivision = `${lvl1} ${lvl2} ${lvl3}.${lvl4} ${lvl5} ${lvl6} ${lvl7} ${i
            .toString()
            .padStart(3, "0")}`;
          if (
            !parentDV.Children.some(
              (x) => x?.Division.toLowerCase() === childDivision.toLowerCase()
            )
          ) {
            code = childDivision;
            break;
          }
        }
        break;
    }
  }

  return code;
}

function makeCSIUniFormatDivisionCode(parentDV) {
  let code = null;
  const parentLevel = parseInt(parentDV?.Level);

  if (parentLevel <= 0) {
    const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < alpha.length; i++) {
      const divisionToCheck = alpha[i];
      if (
        !parentDV.Children.some(
          (x) => x.Division.toLowerCase() === divisionToCheck.toLowerCase()
        )
      ) {
        code = divisionToCheck;
        break;
      }
    }
  } else if (parentLevel < 3 && parentLevel > 0) {
    const multiplier = 10;
    const parentDivision = parentDV.Division;
    for (let i = 1; i < 10; i++) {
      const childDivision = parentDivision + (i * multiplier).toString();
      if (
        !parentDV.Children.some(
          (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  } else if (parentLevel === 3) {
    code = makeLevel4CSIUniFormatDivisionCode(parentDV, 10, 10);

    if (!code) code = makeLevel4CSIUniFormatDivisionCode(parentDV, 5, 5);

    if (!code) code = makeLevel4CSIUniFormatDivisionCode(parentDV, 1, 1);
  } else if (parentLevel === 4) {
    const parentDivision = parentDV?.Division;
    for (let i = 0; i < 1000; i++) {
      const childDivision = `${parentDivision} ${(i).toString().padStart(3, "0")}}`;
      if (
        !parentDV.Children.some(
          (x) => x?.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  }

  return code;
}

function makeUniFormatDivisionCode(parentDV, isParent) {
  let code = null;
  const parentLevel = parseInt(parentDV.Level);
  if (isParent) {
    const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < alpha.length; i++) {
      const divisionToCheck = alpha[i];
      if (
        !parentDV.some(
          (x) => x.Division.toLowerCase() === divisionToCheck.toLowerCase()
        )
      ) {
        code = divisionToCheck;
        break;
      }
    }
  } else if (parentLevel < 4 && parentLevel > 0) {
    let multiplier = 10;
    if (parentLevel === 3) multiplier = 100;

    const parentDivision = parentDV.Division;
    for (let i = 1; i < 10; i++) {
      const childDivision = parentDivision + (i * multiplier).toString();
      if (
        !parentDV.Children.some(
          (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  } else if (parentLevel === 4) {
    const parentDivision = parentDV.Division.substring(0, 6);
    for (let i = 1; i < 100; i++) {
      const childDivision = parentDivision + i.toString().padStart(2, "0");
      if (
        !parentDV.Children.some(
          (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  } else if (parentLevel === 5) {
    const parentDivision = parentDV?.Division.substring(0, 8);
    for (let i = 0; i < 1000; i++) {
      const childDivision = `${parentDivision} ${i
        .toString()
        .padStart(3, "0")}`;
      if (
        !parentDV.Children.some(
          (x) => x?.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  }

  return code;
}

function makeRevitUnitFormatCode(parentDV, isParent) {
  let code = null;
  const parentLevel = parseInt(parentDV.Level);

  if (isParent) {
    const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < alpha.length; i++) {
      const divisionToCheck = alpha[i];
      if (
        !parentDV.some(
          (x) => x.Division.toLowerCase() === divisionToCheck.toLowerCase()
        )
      ) {
        code = divisionToCheck;
        break;
      }
    }
  } else if(parentLevel < 5 && parentLevel > 0) {
    const numberValue = parentDV.Division.substring(1);
    const letter = parentDV.Division.charAt(0);
    const valueToAdd = parentLevel === 3 ? 100 : 10;
    for (let i = 1; i <= 9; i++) {
      let newItem;
      const count = i * valueToAdd;
      if (parentLevel === 4) {
        newItem = `${letter}${parseInt(numberValue) + count}`;
      } else {
        newItem = `${parentDV.Division}${count}`;
      }
      if (!parentDV.Children.some((x) => x.Division === newItem)) {
        code = newItem;
        break;
      }
    }
  } else if (parentLevel === 5) {
    const parentDivision = parentDV?.Division.substring(0, 8);
    for (let i = 0; i < 1000; i++) {
      const childDivision = `${parentDivision}.${i
        .toString()
        .padStart(3, "0")}`;
      if (
        !parentDV.Children.some(
          (x) => x?.Division.toLowerCase() === childDivision.toLowerCase()
        )
      ) {
        code = childDivision;
        break;
      }
    }
  }
  return code;
}

function makeLevel4CSIUniFormatDivisionCode(parentDV, start, incrementer) {
  let code = null;
  const parentDivision = parentDV.Division;

  for (let i = start; i < 100; i += incrementer) {
    const childDivision = `${parentDivision}.${i.toString().padStart(2, "0")}`;
    if (
      !parentDV.Children.some(
        (x) => x.Division.toLowerCase() === childDivision.toLowerCase()
      )
    ) {
      code = childDivision;
      break;
    }
  }

  return code;
}

export default handleGenrateDivisionCode;
