import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import addNotification from "../../helpers/notify";
import UploadIcon from "assets/images/icons/Featured icon.png";
import { editProfile } from "api/users/editProfile";
import { verifyUsername } from "api/users/verifyUsername";
import { useHttp } from "hooks/useHttp";
import { setUser } from "actions/user";
import { uploadFileToServer } from "components/uploadFile/uploadFile";
import { getUsersByUserId } from "api/users/getUsers";
import DefaultImage from "assets/images/user.png";
import Loader from "components/Loader";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import { setJWT, tokenRef, url } from "api";
import jwt_decode from 'jwt-decode';
import { eventTrack } from "helpers/ga4Helper";
import { debounce } from "lodash";
import { useForm } from "react-hook-form";

const PersonalInfo = () => {
  const {
    register,
    formState: { errors },
    trigger,
    reset,
    setError,
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [phone_number, setPhone] = useState("");
  const [URL, SET_URL] = useState("");
  const [image, setImage] = useState("");
  const [affiliationPercentage, setAffiliationPercentage] = useState(""); 

  const fileInputRef = useRef(null);

  const { loading, request: editProfileCall } = useHttp({
    requestCallback: editProfile,
    onLoad: (res) => {
      addNotification("Updated Successfully! ", "success");

      localStorage.setItem(tokenRef, res?.token);
      setJWT(res.token);
      const userInformation = jwt_decode(res.token);
      dispatch(setUser(userInformation));
    },
    onError: (res) => {
      addNotification("Error not update", "danger");
    },
  });

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  useEffect(() => {
    setName(userInfoRedux?.name);
    setPhone(userInfoRedux?.phone_number);
    setUsername(userInfoRedux?.username);
    setAffiliationPercentage(userInfoRedux?.percentageValue);
    (async () => {
      const extractedImg = await extractImageFromZip(
        userInfoRedux?.profilePhoto
      );
      SET_URL(extractedImg);
    })();
    console.log(userInfoRedux)
  }, []);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const debouncedCheckUsernameUnique = useCallback(
    debounce(async (value) => {
      try {
        const res = await verifyUsername({ username: value });
        if (!res.err) {
          setError("username", { type: "custom", message: "" });
        } else {
          setError("username", { type: "custom", message: "Username already in use" });
          setUsername("");
        }
      } catch (error) {
        setError("username", { type: "custom", message: error.err || "An error occurred" });
        setUsername("");
      }
    }, 1000),
    []
  );

  const debouncedApiCall = (value) =>{
    setUsername(value);
    debouncedCheckUsernameUnique(value);
  };

  const handleProfileImg = async (e) => {
    const selectedImg = e.target.files[0];
    setImage(selectedImg);
  };

  const handleSave = async () => {
    if (image) {
      var fileUrl;
      fileUrl = await uploadFileToServer(image);
    }

    let body = {
      id: userInfoRedux?.id,
      payload: {
        name: name,
        phone_number: phone_number,
        username: username,
        profilePhoto: image ? fileUrl : userInfoRedux?.profilePhoto,
        percentageValue : affiliationPercentage
      },
    };
    editProfileCall(body);
    eventTrack(
      "Update-Profile-Action",
      "Update-Profile",
      userInfoRedux?.name,
      "+1",
      false,
      "+1"
    )
  };

  const onClickCancel = () => {
    setName(userInfoRedux?.name);
    setPhone(userInfoRedux?.phone_number);
    (async () => {
      const extractedImg = await extractImageFromZip(
        userInfoRedux?.profilePhoto
      );
      SET_URL(extractedImg);
    })();
    setImage("");
  };

  return (
    <div className="w-100 py-4 px-2 profile-div">
      <p className="font-weight-bold text-black mb-1 h5">Personal Info</p>
      <p>Update your photo and personal details here.</p>
      <hr />

      <p className="font-weight-bold text-black mb-1">Your Photo</p>
      <p>This will be displayed on your profile.</p>

      <img
        src={image ? window.URL.createObjectURL(image) : URL}
        className="d-block mt-4"
        style={{ width: 100, height: 100, borderRadius: 100 }}
        onError={(e) => (e.target.src = DefaultImage)}
      />

      <div
        className="profile-upload-box cursor-pointer"
        onClick={handleAvatarClick}
      >
        <img src={UploadIcon} />
        <p className="font-weight-bold text-bid">
          Click to upload{" "}
          <span className="font-weight-light">or drag and drop</span>
        </p>
        <p className="font-weight-bold">
          SVG, PNG, JPG or GIF (max. 800x400px)
        </p>
      </div>

      <hr />

      <div className="my-2">
        <label className="labels font-weight-bold text-bid">Display Name</label>
        <input
          onChange={handleName}
          value={name}
          type="text"
          className="form-control"
          placeholder="Name"
        />
      </div>

      <hr />

      <div className="my-2">
        <label className="labels font-weight-bold text-bid">
          Mobile Number
        </label>
        <input
          value={phone_number}
          onChange={handlePhone}
          type="number"
          className="form-control"
          placeholder="enter phone number"
        />
      </div>

      <div className="my-2">
        <label className="labels font-weight-bold text-bid">
          Affiliation percentage (%)
        </label>
        <input
          value={affiliationPercentage}
          onChange={(e) => setAffiliationPercentage(e.target.value)}
          type="number"
          className="form-control"
        />
      </div>

      <hr />

      <div className="my-2">
          <label className="labels font-weight-bold text-bid">
            Username
          </label>
          <input
            value={username}
            type="text"
            className="form-control"
            placeholder="Username"
            {...register("username", {
              required: "Username is required",
              maxLength: 10,
            })}
            onChange={e => debouncedApiCall(e.target.value)}
          />
          {errors.username && (
            <p role="alert" className="validation-error-msg">
              {errors.username.message}
            </p>
          )}
        </div>

        <hr />

      <div className="d-flex align-items-center justify-content-between w-100 gap-3 my-2">
        <div className="w-50">
          <label className="labels font-weight-bold text-bid">
            Company Name
          </label>
          <input
            value={userInfoRedux?.companyName}
            type="text"
            className="form-control"
            disabled
            placeholder="company"
          />
        </div>

        <div className="w-50">
          <label className="labels font-weight-bold text-bid">
            Email Address
          </label>
          <input
            value={userInfoRedux?.email}
            type="text"
            className="form-control"
            disabled
            placeholder="Username"
          />
        </div>
      </div>

      <hr />

      {loading && <Loader />}

      <div className="d-flex ml-auto w-100">
        <button
          className="header-btn text-bid bg-white border-bid px-3"
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <button
          className="header-btn text-white bg-bid border-bid px-4"
          disabled={username.length === 0}
          onClick={handleSave}
        >
          Save
        </button>
      </div>

      <input
        ref={fileInputRef}
        onChange={handleProfileImg}
        type="file"
        id="profilePicture"
        name="profilePicture"
        accept="image/*"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default PersonalInfo;
