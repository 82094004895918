import React from 'react';

export const DocumentIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41648 5.50065V2.75065C6.41648 2.24439 6.82689 1.83398 7.33315 1.83398H18.3331C18.8394 1.83398 19.2498 2.24439 19.2498 2.75065V15.584C19.2498 16.0903 18.8394 16.5007 18.3331 16.5007H15.5831V19.2498C15.5831 19.7566 15.1707 20.1673 14.6603 20.1673H3.67277C3.16304 20.1673 2.75 19.7598 2.75 19.2498L2.75238 6.41812C2.75247 5.91142 3.16492 5.50065 3.6753 5.50065H6.41648ZM4.58555 7.33398L4.58351 18.334H13.7498V7.33398H4.58555ZM8.24982 5.50065H15.5831V14.6673H17.4165V3.66732H8.24982V5.50065ZM6.41667 10.084H11.9167V11.9173H6.41667V10.084ZM6.41667 13.7507H11.9167V15.584H6.41667V13.7507Z"
        fill="#9C9C9C"
      />
    </svg>
  );
};
