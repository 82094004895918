import React, { useEffect, useRef, useState } from "react";
import { getAffiliationsAdmin } from "api/affiliations/getAffiliationsAdmin";
import EyeImg from "assets/images/icons/eye-fill.png";
import Loader from "components/Loader";
import { useHttp } from "hooks/useHttp";
import { MDBModal } from "mdbreact";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import TopNavbar from "../navbar";
import Sidebar from "../sidebar";
import UploadIcon from "assets/images/icons/Featured icon.png";
import { uploadFileToServer } from "components/uploadFile/uploadFile";
import addNotification from "helpers/notify";
import { markAsPaid } from "api/affiliations/markAsPaid";
import Loading from "components/ui-components/loading";

const Affiliations = () => {
  const invoiceRef = useRef();
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const [affiliations, setAffiliations] = useState([]);
  const [allData, setAllData] = useState([]);
  const [openActiveInactiveModal, setOpenActiveInactiveModal] = useState(false);
  const [activeInactiveItem, setActiveInactiveItem] = useState(null);
  const TABS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  };
  const [activeTab, setActiveTab] = useState(TABS.ACTIVE);

  const { loading: loadingGetAffiliations, request: getAffiliatedUserAPI } =
    useHttp({
      requestCallback: (data) => getAffiliationsAdmin(),
      onLoad: (res) => {
        setAffiliations(res?.data);
        setAllData(res?.data);
      },
      onError: (res) => {},
    });

  useEffect(() => {
    getAffiliatedUserAPI();
  }, []);

  const handleSearch = (keyword) => {
    const filteredResult = affiliations?.filter((item) =>
      item?.user?.name?.toLowerCase().includes(keyword.toLowerCase())
    );

    if (keyword.length > 0) {
      setAffiliations(filteredResult);
    } else {
      setAffiliations(allData);
    }
  };

  const handleActionBtnClick = (item) => {
    setOpenActiveInactiveModal(true);
    setActiveInactiveItem(item);
  };

  const [openEditModel, setOpenEditModel] = useState(false);
  const [invoiceUploading, setInvoiceUploading] = useState(false);

  const [invoiceImg, setInvoiceImg] = useState(null);
  const [body, setBody] = useState({
    date: "",
    invoiceNo: "",
    userId: "",
    invoicePath: "",
  });

  const handleEdit = (item) => {
    console.log(item);
    setOpenEditModel(true);
    body.userId = item?.user?._id;
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setBody((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setInvoiceUploading(true);
    var fileUrl;
    fileUrl = await uploadFileToServer(invoiceImg);
    console.log(fileUrl);
    if (fileUrl === null) {
      addNotification(
        "Something went wrong while uploading invoice image",
        "danger"
      );
      setInvoiceUploading(false);
      return;
    }
    setInvoiceUploading(false);
    body.invoicePath = fileUrl;
    markAsPaidAPI(body);
  };

  const { loading: markAsPaidLoading, request: markAsPaidAPI } = useHttp({
    requestCallback: markAsPaid,
    onLoad: (res) => {
      if (res.err) {
        addNotification(res.err, "danger");
      } else {
        addNotification(res?.message, "success");
        setOpenEditModel(false)
      }
    },
    onError: (err) => {
      addNotification(err.err, "danger");
    },
  });

  return (
    <>
      {loadingGetAffiliations && <Loader />}
      <div className="personal-area">
        <TopNavbar handleSearch={handleSearch} />

        <div className="content-bg p-2">
          <div className="container d-flex mt-1">
            <Sidebar />

            <div className="profile-content px-0 pt-0">
              <div className="w-100 px-0">
                <div className="d-flex justify-content-between px-3 py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <div className="heading-svg-wrapper">
                      <i className="fa fa-money"></i>
                    </div>
                    <span className="font-weight-bold ml-3 text-black">
                      Affiliations
                    </span>
                  </div>
                </div>

                <table className="bid-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Earnings</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {affiliations?.length === 0 ? (
                      <tr>
                        <td>No records found</td>
                      </tr>
                    ) : (
                      affiliations?.map((item, i) => (
                        <tr key={item?.user?._id}>
                          <td>{i + 1}</td>
                          <td>
                            {item?.user?.name} {item?.user?.lastName}
                          </td>
                          <td>{item?.user?.email}</td>
                          <td>{item?.user?.role ?? "N/A"}</td>
                          <td>{item?.user?.newEarnings ?? "N/A"}</td>
                          <td>
                            {" "}
                            <img
                              src={EyeImg}
                              alt="eye"
                              className="action-icon cursor-pointer mr-3"
                              onClick={() => handleActionBtnClick(item)}
                            />
                            <span
                              onClick={() => handleEdit(item)}
                              className="mr-0 cursor-pointer table-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                              >
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Edit User */}
        <MDBModal
          isOpen={openEditModel}
          toggle={() => setOpenEditModel(false)}
          centered
          className="modal-lg"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <p className="delete-modal-label-head">Mark As Paid</p>
            </h5>
            <button
              onClick={() => setOpenEditModel(false)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={(e) => handleSave(e)}>
            <div className="modal-body pt-0">
              <div className="form-group mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="f-14 inter text-black font-weight-500"
                >
                  Date
                </label>
                <input
                  value={body.date}
                  name="date"
                  type="date"
                  className="form-control"
                  required
                  placeholder=""
                  onChange={(e) => handleOnchange(e)}
                />
              </div>
              <div className="form-group mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="f-14 inter text-black font-weight-500"
                >
                  Invoice Number
                </label>
                <input
                  value={body.invoiceNo}
                  name="invoiceNo"
                  type="text"
                  className="form-control"
                  placeholder="Enter Value"
                  onChange={(e) => handleOnchange(e)}
                  required
                />
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="profile-upload-box cursor-pointer w-fill d-flex align-items-center my-0 justify-content-between"
                  onClick={() => invoiceRef.current.click()}
                >
                  <div className="text-left">
                    <p className="font-weight-bold text-black f-14">
                      Click to upload invoice{" "}
                    </p>
                    <p className="f-12">SVG, PNG, JPG (max. 800x400px)</p>
                  </div>
                  <img src={UploadIcon} className="upload-icon" />
                </div>
                <img
                  src={
                    invoiceImg !== null
                      ? window.URL.createObjectURL(invoiceImg)
                      : UploadIcon
                  }
                  className="uploaded-icon"
                />
              </div>

              <input
                ref={invoiceRef}
                onChange={(e) => setInvoiceImg(e.target.files[0])}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                required
              />
              {invoiceUploading ? (
                <Loading color="black" text="Uploading Invoice..." />
              ) : null}

              {markAsPaidLoading ? (
                <Loading color="black" text="Submitting..." />
              ) : null}
            </div>

            <div className="modal-footer border-none">
              <div className="w-100">
                <button
                  type="submit"
                  className="border-bid bg-bid rounded-lg py-2 w-100 text-white text-center inter f-16 font-weight-500 cursor-pointer"
                >
                  Submit
                </button>
                <div
                  onClick={() => setOpenEditModel(false)}
                  className="bg-white border-bid rounded-lg py-2 w-100 text-bid text-center inter f-16 font-weight-500 mt-3 cursor-pointer"
                  data-dismiss="modal"
                >
                  Cancel
                </div>
              </div>
            </div>
          </form>
        </MDBModal>
        <MDBModal
          isOpen={openActiveInactiveModal}
          toggle={() => setOpenActiveInactiveModal(false)}
          centered
          className="modal-xl"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <p className="delete-modal-label-head">
                {activeInactiveItem?.user?.email}
              </p>
            </h5>
            <button
              onClick={() => setOpenActiveInactiveModal(false)}
              type="button"
              className="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <div className="d-flex mb-2">
              <div
                className={`header-btn border-bid rounded-lg h6 ml-0 mr-1 mb-0 cursor-pointer ${
                  activeTab === TABS.ACTIVE
                    ? "bg-bid text-white"
                    : "bg-white text-bid"
                }`}
                onClick={() => setActiveTab(TABS.ACTIVE)}
              >
                <span className="ml-1 f-14">Active users</span>
              </div>
              <div
                className={`header-btn border-bid rounded-lg h6 ml-1 mr-1 mb-0 cursor-pointer ${
                  activeTab === TABS.INACTIVE
                    ? "bg-bid text-white"
                    : "bg-white text-bid"
                }`}
                onClick={() => setActiveTab(TABS.INACTIVE)}
              >
                <span className="ml-1 f-14">Inactive users</span>
              </div>
            </div>
            <table className="bid-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Created At</th>
                  <th>Paid Status</th>
                  <th>Hourly Rate</th>
                  <th>Package</th>
                  <th>Product</th>
                  <th>Rate Type</th>
                  <th>Package</th>
                </tr>
              </thead>

              {activeTab === TABS.ACTIVE ? (
                <tbody>
                  {activeInactiveItem?.activeUsers?.length === 0 ? (
                    <tr>
                      <td>No records found</td>
                    </tr>
                  ) : (
                    activeInactiveItem?.activeUsers?.map((item, i) => (
                      <tr key={item?._id}>
                        <td>{i + 1}</td>
                        <td>{item?.mail}</td>
                        <td>{item?.role ?? "N/A"}</td>
                        <td>
                          {moment(item?.createdAt).format("DD/MM/YYYY") ??
                            "N/A"}
                        </td>
                        <td>
                          {item?.affiliationPaid === false
                            ? "Not Paid"
                            : "Paid" ?? "N/A"}
                        </td>
                        <td>
                          {item?.hourlyRate ? "$" + item?.hourlyRate : "N/A"}
                        </td>
                        <td>{item?.package ?? "N/A"}</td>
                        <td>{item?.product ?? "N/A"}</td>
                        <td>{item?.rateType ?? "N/A"}</td>
                        <td>{item?.role ?? "N/A"}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              ) : (
                <tbody>
                  {activeInactiveItem?.inActiveUsers?.length === 0 ? (
                    <tr>
                      <td>No records found</td>
                    </tr>
                  ) : (
                    activeInactiveItem?.inActiveUsers?.map((item, i) => (
                      <tr key={item?._id}>
                        <td>{i + 1}</td>
                        <td>{item?.mail}</td>
                        <td>{item?.role ?? "N/A"}</td>
                        <td>
                          {moment(item?.createdAt).format("DD/MM/YYYY") ??
                            "N/A"}
                        </td>
                        <td>
                          {item?.affiliationPaid === false
                            ? "Not Paid"
                            : "Paid" ?? "N/A"}
                        </td>
                        <td>
                          {item?.hourlyRate ? "$" + item?.hourlyRate : "N/A"}
                        </td>
                        <td>{item?.package ?? "N/A"}</td>
                        <td>{item?.product ?? "N/A"}</td>
                        <td>{item?.rateType ?? "N/A"}</td>
                        <td>{item?.role ?? "N/A"}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              )}
            </table>
          </div>

          <div className="modal-footer border-none">
            <div className="w-100">
              {/* <div
              onClick={() => {
                handleAddAffiliations();
              }}
              className="border-bid bg-bid rounded-lg py-2 w-100 text-white text-center inter f-16 font-weight-500 cursor-pointer"
            >
              Add Affiliation
            </div> */}
              <div
                onClick={() => setOpenActiveInactiveModal(false)}
                className="bg-white border-bid rounded-lg py-2 w-100 text-bid text-center inter f-16 font-weight-500 mt-3 cursor-pointer"
              >
                Close
              </div>
            </div>
          </div>
        </MDBModal>
      </div>
    </>
  );
};

export default Affiliations;
