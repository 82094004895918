import { connect } from 'react-redux';

import withDeleteModal from '../hoc/withDeleteModal';
import { removeObject } from 'api/projects/removeProject';
import { removeProject } from 'actions/user';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    reduxRemover: project => dispatch(removeProject(project)),
  };
};

const DeleteProjectModal = withDeleteModal({
  itemLabel: 'project',
  request: removeObject,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProjectModal);
