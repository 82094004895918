import React from "react";

export const FilterIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.97635 16.0002C7.8442 16.0036 7.71277 15.9798 7.59015 15.9305C7.46752 15.8811 7.3563 15.8072 7.26335 15.7132C7.16932 15.6203 7.09534 15.509 7.04597 15.3864C6.99661 15.2638 6.97291 15.1323 6.97635 15.0002V9.00018L1.17635 1.60015C1.05613 1.45573 0.981356 1.27901 0.961426 1.09216C0.941495 0.905308 0.977304 0.716699 1.06435 0.550165C1.14163 0.376041 1.27058 0.229907 1.43372 0.131525C1.59685 0.0331435 1.78628 -0.0127164 1.97635 0.000177374H15.9763C16.1664 -0.0127164 16.3559 0.0331435 16.519 0.131525C16.6821 0.229907 16.8111 0.376041 16.8884 0.550165C16.9754 0.716699 17.0112 0.905308 16.9913 1.09216C16.9713 1.27901 16.8966 1.45573 16.7764 1.60015L10.9763 9.00018V15.0002C10.9797 15.1324 10.9558 15.2639 10.9063 15.3865C10.8567 15.5092 10.7826 15.6204 10.6884 15.7132C10.5955 15.8071 10.4845 15.8809 10.362 15.9303C10.2396 15.9796 10.1083 16.0034 9.97635 16.0002H7.97635ZM8.97635 8.30017L13.9263 2.00018H4.02635L8.97635 8.30017Z"
        fill="#455E6C"
      />
    </svg>
  );
};
