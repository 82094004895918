import React from "react";
import Profile from "../../../components/articles/Profile";

const ProfilePage = ({ anonymous, userInfoRedux, slug }) => {


  return (
    <>
      {slug && <Profile id={slug} />}
    </>
  );
};

export default ProfilePage;
