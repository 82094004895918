import React, { useEffect, useState } from "react";
import { publicFetch } from "../../util/fetcher";

import Layout from "../../components/layout";
import UserCard from "../../components/user-card";
import AvatarCard from "../../components/user-card/avatar-card";
import PostList from "../../components/user-card/post-list";
import PostItem from "../../components/user-card/post-list/post-item";
import { Spinner } from "../../components/icons";
import Seo from "../../components/seo/Seo";

import { eventTrack } from "../../components/utils";
import GA4 from "../../components/GA4";
import { HeadProvider } from "react-head";
import { url } from "api";

const UserDetail = ({ anonymous, userInfoRedux, username }) => {
  const [posts, setPosts] = useState(null);
  const [postType, setPostType] = useState("Questions");

  useEffect(() => {
    const fetchQuestions = async () => {
      const { data } = await publicFetch.get(`/question/user/${username}`);
      setPosts(data);
    };
    fetchQuestions();
  }, [postType, username]);

  return (
    <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>
      <Seo
        title={`User ${username} | BidLight`}
        siteUrl={`${url}/learning/users/${username}`}
        description={username}
        keywords={username}
        authorUsername={username}
      />
      {eventTrack("username", username, username, 5, false)}
      <GA4
        userId={username}
        category={"username"}
        action={"username"}
        label={"username"}
        value={5}
        nonInteraction={true}
        transport={"xhr"}
      ></GA4>

      <HeadProvider>
        <title>
          Questions by {username} - BidLight BIM Revit Plugin Questions &
          Support - BidLight Learning
        </title>
      </HeadProvider>

      <UserCard>
        <AvatarCard username={username} />
        <PostList postType={postType} setPostType={setPostType}>
          {!posts && (
            <div className="loading">
              <Spinner />
            </div>
          )}

          {posts?.map(({ id, title, score, createdAt }) => (
            <PostItem
              key={id}
              title={title}
              vote={score}
              created={createdAt}
              id={id}
            />
          ))}

          {posts?.length == 0 && (
            <p className="not-found-questions">
              Don&apos;t have any questions yet.
            </p>
          )}
        </PostList>
      </UserCard>
    </Layout>
  );
};

export async function getServerSideProps(context) {
  const username = context.params.username;
  return {
    props: {
      username,
    },
  };
}

export default UserDetail;
