import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FollowButton from "./FollowButton";
import CONSTANTS from "../../constants";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { timeDifferenceForDate } from "../utils";
import { eventTrack } from "../utils";
import GA4 from "../GA4";

import ShareSocial from "../share-social/share";
import { tokenRef, url } from "api";
import addNotification from "helpers/notify";

const extractContent = (HTMLPart) =>
  HTMLPart.replace(/\n/gi, "")
    .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/gi, "")
    .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/gi, "")
    .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/gi, "")
    .replace(/<\/\s*(?:p|div)>/gi, "\n")
    .replace(/<br[^>]*\/?>/gi, "\n")
    .replace(/<[^>]*>/gi, "")
    .replace("&nbsp;", " ")
    .replace(/[^\S\r\n][^\S\r\n]+/gi, " ");

const ArticleView = (props) => {
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const [loggedInUser, setLoggedInUser] = useState(userInfoRedux);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoggedInUser(userInfoRedux);
  }, [userInfoRedux]);

  console.log(loggedInUser);

  const deleteArticle = async () => {
    const userConfirmed = window.confirm("Do you want to proceed?");
    if (userConfirmed) {
      console.log(props.article_id);
      setLoading(true);
      const token = localStorage.getItem(tokenRef);
      const _url = `${CONSTANTS.SERVER_URL}/api/`;
      try {
        await axios.delete(`${_url}article?id=${props.article_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLoading(false);
        addNotification("article successfully deleted", "success");
        history.push("/learning/articles");
      } catch (error) {
        setLoading(false);
        addNotification("Something went wrong", "danger");
      }
    }
  };

  function clap(article_id) {
    console.log("clapping...");
    axios
      .post(`${url}/api/article/clap`, { article_id })
      .then((res) => {
        // loadArticles();
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      {eventTrack("ArticleView", props.article_id, props.article_id, 6, false)}
      <GA4
        userId={props.article_id}
        category={"ArticleView"}
        action={"ArticleView"}
        label={"ArticleView"}
        value={6}
        nonInteraction={true}
        transport={"xhr"}
      />
      <div>
        <div
          className="row animated fadeInUp"
          data-animation="fadeInUp-fadeOutDown"
        >
          <div id="main-post" className="col-xs-12 main-content">
            <div className="UserFollowButton d-flex align-items-center justify-content-between">
              <FollowButton to_follow={`${props.author.id}`} />
              {(loggedInUser?.id === props.author._id ||
                loggedInUser?.admin) && (
                <ul className="article-action-box">
                  <li className="mr-3">
                    <Link
                      style={{ cursor: "pointer" }}
                      to={`${"/learning/articles/editor/"}${props.slug}`}
                    >
                      <i class="fa fa-pen" />
                    </Link>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        deleteArticle();
                        {
                          eventTrack(
                            "Delete-Article-Action",
                            "Delete-Article",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          );
                        }
                      }}
                    >
                      <i class="fa fa-trash" />
                    </a>
                  </li>
                </ul>
              )}
            </div>

            <div className="post-metadata mb-3 mb-3">
              <img
                alt={props.author.name}
                className="avatar-image"
                src={props.author.profilePhoto}
                height="40"
                width="40"
              />
              <div className="post-info">
                <div className="PopoverLink">
                  <span className="popover-link" data-reactroot="">
                    {props.author.name}
                  </span>
                </div>
                <small> Article {props.smartNote}</small>
                <small
                  style={{
                    position: "absolute",
                    paddingRight: "10px",
                    right: "5px",
                    textAlign: "right",
                    align: "right",
                  }}
                >
                  Published • {timeDifferenceForDate(props.createdAt)}
                </small>
              </div>
            </div>

            {props.f_img && (
              <div className="post-picture-wrapper">
                <img
                  src={`${CONSTANTS.SERVER_URL}/marketing/branding/hotlink-ok/${props.f_img}`}
                  alt={extractContent(props.description)}
                />
              </div>
            )}

            <h1 className="title mt-3">{props.title}</h1>
            <div
              className="body"
              dangerouslySetInnerHTML={{ __html: props.text.replace(/https:\/\/(www\.)?bidlight\.com/g, `${CONSTANTS.SERVER_URL}`) }}
            ></div>

            <div className="post-stats clearfix">
              <div className="pull-left">
                <div className="like-button-wrapper">
                  <div className="like-button-wrapper">
                    <button
                      onClick={() => clap(props.article_id)}
                      className="like-button"
                      data-behavior="trigger-overlay"
                      type="submit"
                    >
                      <span className="text d-flex align-items-center">
                        <i className="fa fa-thumbs-up mr-2"></i> Like
                        <span className="like-count ml-3 mr-0">
                          {props.claps}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="author-info">
              <div class="author-metadata">
                <img
                  alt={props.author.name}
                  className="avatar-image"
                  src={props.author.profilePhoto}
                  height="40"
                  width="40"
                />

                <div className="username-description">
                  <h4>{props.author.name}</h4>
                </div>
              </div>
              <div className="UserFollowButton">
                {props.username && (
                  <FollowButton to_follow={`${props.author.id}`} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="post-show-footer row animated fadeInUp"
          data-animation="fadeInUp-fadeOutDown"
        >
          <div className="col-xs-10 col-md-6 col-xs-offset-1 col-md-offset-3 main-content related-stories">
            <h4 className="small-heading">Related Lessons</h4>
            <div className="post-list-item">
              <div className="flex-container">
                <div className="avatar-wrapper">
                  <img
                    alt={props.author.name}
                    className="avatar-image"
                    src={props.author.profilePhoto}
                    height="40"
                    width="40"
                  />
                </div>
                <div className="post-info">
                  <strong className="pli-title">
                    <a href="#"></a>
                  </strong>
                  <br />
                  <small className="pli-username">
                    <a href="#"></a>
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div
            id="responses"
            className="col-xs-10 col-md-6 col-xs-offset-1 col-md-offset-3 main-content"
          >
            <h4 className="small-heading">Responses</h4>
            <div data-behavior="responses-list"></div>
          </div>
        </div>

        <div data-page="post-metadata-bar">
          <div
            className="flex-container is-inView"
            data-behavior="animated-metadata"
          >
            <ShareSocial
              shareUrl={`${url}/learning/articles/${props.slug}`}
              title={props.title}
              image_url={`${url}/marketing/branding/hotlink-ok/${props.f_img}`}
              description={props.description}
            />
            <div className="d-flex align-items-center">
              <div className="like-button-wrapper">
                <div className="like-button-wrapper">
                  <button
                    onClick={() => props.clap(props.article_id)}
                    className="like-button"
                    data-behavior="trigger-overlay"
                    type="submit"
                  >
                    <span className="text d-flex align-items-center">
                      <i className="fa fa-thumbs-up mr-2"></i> Like
                      <span className="like-count ml-3 mr-0">
                        {props.claps}
                      </span>
                    </span>
                  </button>
                </div>
              </div>

              <div className="bookmark-button">
                <div className="bookmark-button-wrapper">
                  <form className="button_to" method="get" action="">
                    <button
                      className="bookmark-button"
                      data-behavior="trigger-overlay"
                      type="submit"
                    >
                      <span className="icon-bookmark-o"></span>
                      <span className="hide-text">Bookmark</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="flex-container flex-space-btw">
              <div className="author-metadata ">
                <img
                  alt={props.author.name}
                  className="avatar-image"
                  src={props.author.profilePhoto}
                  height="40"
                  width="40"
                />

                <div className="PopoverLink">
                  <span className="popover-link" data-reactroot="">
                    {props.author.name}
                  </span>
                </div>
              </div>
              <div className="UserFollowButton">
                {props.author && (
                  <FollowButton to_follow={`${props.author.id}`} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleView;
