import { eventTrack } from "helpers/ga4Helper";
import React, { Component } from "react";
import { Link } from "react-scroll";

export default class FooterSpy extends Component {
  render() {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
      <div className="d-flex">
        <div className="footer-links-div">
          <Link to="whatisbidlight" spy={true} smooth={true} duration={1500}>
            What is BidLight?
          </Link>
          <Link to="Affiliations" spy={true} smooth={true} duration={1500}>
            Affiliations
          </Link>
          <Link to="SaasServices" spy={true} smooth={true} duration={1500}>
            {" "}
            BidLight Services
          </Link>
          <Link to="SassFeatures" spy={true} smooth={true} duration={1500}>
            {" "}
            BidLight Features
          </Link>
          <Link to="BIMServices" spy={true} smooth={true} duration={1500}>
            {" "}
            BIM Services
          </Link>

          {/* <Link
          to="about"
          spy={true}
          smooth={true}
          duration={1500}
          style={{ cursor: "pointer" }}
        >
          About Us
        </Link> */}
        </div>
        <div className="footer-links-div ml-5">
        <Link to="pricing-section" spy={true} smooth={true} duration={1500}>
            Pricing
          </Link>
          <Link to="contact-section" spy={true} smooth={true} duration={1500}>
            Request a Demo
          </Link>
          <Link
            activeclass="nav-menu-item-active"
            //  className="pl-md-5"
            to=""
            //   spy={true}
            //   smooth={true}
            //   duration={1500}
            style={{ cursor: "pointer" }}
            offset={0}
            onClick={() => {
              openInNewTab(
                "https://apps.autodesk.com/RVT/en/Detail/Index?id=6237938337445006374&appLang=en&os=Win64"
              )
              {
                eventTrack(
                  "Open-Revit-Action",
                  "Open-Revit",
                  'none-logged-in-user',
                  "+1",
                  false,
                  "+1"
                );
              }
            }
            }
          >
            {/* Get a License<a href="https://learning.bidlight.com"></a>*/}
            Revit Plugin
          </Link>
          <Link
            activeclass="nav-menu-item-active"
            //  className="pl-md-5"
            to=""
            //   spy={true}
            //   smooth={true}
            //   duration={1500}
            style={{ cursor: "pointer" }}
            offset={0}
            onClick={() =>{
              openInNewTab("https://bidlight.com/learning/articles")
              {
                eventTrack(
                  "Open-Articles-Action",
                  "Open-Articles",
                  'none-logged-in-user',
                  "+1",
                  false,
                  "+1"
                );
              }
            }
            }
          >
            {/* Get a License<a href="https://learning.bidlight.com"></a>*/}
            Learn more ...
          </Link>
        </div>
      </div>
    );
  }
}
