import { putGeneral, post } from "../index";

export const copyUserPermissionFromRole = (id, body) => {
  return putGeneral(`/permissions/copy-role-permission-user/${id}`, body);
};

export const editUserPermission = (id, body) => {
  return putGeneral(`/permissions/${id}`, body);
};

export const editBulkPermission = (body) => {
  return post(`/roles/role-with-bulk-permission`, body);
};
