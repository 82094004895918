import React, { useEffect, useState } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MDBRow, MDBBox, MDBBtn, MDBBtnGroup, MDBContainer } from 'mdbreact'

import HowToCreateModal from 'components/HowToCreateTimelineModal'
import TimelineItem from 'components/TimelineItemPersonalArea'
import NoItems from 'components/ui-components/no-items'
import Loading from 'components/ui-components/loading'
import Error from 'components/ui-components/Error'

//import { sortByDate, FROM_NEWER_TO_OLDER } from 'helpers/sortByDate'
import { setCompanyUsers, setProjects } from 'actions/user'
import { getProjects } from 'api/projects/getProjects'
import { useHttpWithCache } from 'hooks/useHtthWithCache'
import { Maybe } from 'helpers/maybeFunctor'

import CreateModal from "components/CreateProjectModal";
import DeleteProjectModal from "components/DeleteProjectModal/DeleteProjectModal";
import UpdateProjectModal from "components/UpdateProjectModal/UpdateProjectModal";
import Members from "components/MembersModalPersonalArea";
import ShareModal from "components/ShareModal";
import { useSearch } from "hooks/useSearch";
import ProjectItemPersonalArea from "components/ProjectItemPersonalArea";


import {
  sortByDate,
  FROM_NEWER_TO_OLDER,
  FROM_OLDER_TO_NEWER,
  
} from "helpers/sortByDate";

import { setSidebarStatus } from 'actions/user'
import { mainAdminGetCompanies } from 'api/users/mainAdminGetCompanies'



const TimelinesPagePersonalArea = props => {
  const { projectId } = props

  const dispatch = useDispatch()
  const search = useSearch();


  const [modal, setModal] = useState(false)
  const projectsRedux = useSelector(s => s.user.projects)
  const usersRedux = useSelector(s => s.user.users)
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const [sortedToNewer, setSortedToNewer] = useState(false);


  const [projectToDelete, setProjectToDelete] = useState(null);
  const [projectToUpdate, setProjectToUpdate] = useState(null);
  const [projectListToEdit, setProjectListToEdit] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const { loading: projectLoading, error,  request, refresh } = useHttpWithCache({
    requestCallback: () => getProjects(),
    reduxSetter: data => {
      dispatch(setProjects(data.projects))
      dispatch(setCompanyUsers(data.users))
    },
    reduxReset: () => {
      dispatch(setProjects(null))
      dispatch(setCompanyUsers(null))
    },
    reduxCash: {
      projects: projectsRedux,
      users: usersRedux
    },
    allowToCash: true
  })

  const {
    loading: adminProjectsLoading,
    adminProjectsError,
    request: getAdminProjectsAPI,
  } = useHttpWithCache({
    requestCallback: () => mainAdminGetCompanies(),
    reduxSetter: (data) => {
      dispatch(
        setProjects(
          data?.company_projects?.find(
            (item) => item?._id === userInfoRedux?.company
          )?.projects
        )
      );
      dispatch(
        setCompanyUsers(
          data?.all_users?.filter(
            (item) => item?.company === userInfoRedux?.company
          )
        )
      );
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
    },
    reduxCash: {
      projects: projectsRedux,
      users: usersRedux,
    },
    allowToCash: false,
  });

  useEffect(() => {
    if (userInfoRedux?.admin) {
      getAdminProjectsAPI();
    } else {
      request();
    }
  }, []);

  const { companyAdmin, admin, company, name, lastName, email } = userInfoRedux;

  const maybeProject = Maybe.of(projectsRedux).map(projects => {
    return projects.find(project => project._id === projectId)
  }).value

  const childProjects =  Maybe.of(projectsRedux)
  .map(projects  => {
    return   projects.filter(project =>  project.parentProjectID == projectId && project.isParent == false)
    }
    ); 

    
  const maybeProjectId = Maybe.of(maybeProject).map(project => project._id)
    .value

  const arrayOfColors = [
    'vinous',
    'purple',
    'grey-and-blue',
    'blue',
    'lightGreen'
  ]

  const maybeProjectTimelines = Maybe.of(maybeProject)
    .map(project => project.timelines)
    .map(timelines => sortByDate(timelines, FROM_NEWER_TO_OLDER))
    .map(sortedTimelines => {
      let colorVar = 0

      return sortedTimelines.map((timeline, index) => {
        if (colorVar > arrayOfColors.length - 2) {
          colorVar = 0
        } else {
          colorVar++
        }

        return (
          
          <TimelineItem
            urls={timeline.urls}
            timelineItem={timeline}
            textPosition={`${index % 2 === 0 ? 'left' : 'right'}`}
            colorClassName={arrayOfColors[colorVar]}
            className={index > 0 ? 'mt-3' : ''}
            key={timeline._id}
          />
        )
      })
    }).value

    const maybeProjects = childProjects.map((projects) => {
      return !sortedToNewer
        ? sortByDate(projects, FROM_OLDER_TO_NEWER)
        : sortByDate(projects, FROM_NEWER_TO_OLDER);
    })
    .map((projects) => search(projects, "title", searchValue))
    .map((projects) => {
      return (
        <MDBRow className="row-cols-lg-3 row-cols-sm-2 row-cols-1 mt-3">
          {projects.map((projectItem, index) => {
            return (
            
              <ProjectItemPersonalArea
                
                isAdmin = {companyAdmin || admin}
                className={index > 2 ? "mt-4" : ""}
                projectItem={projectItem}
                key={projectItem._id}
                idUser={userInfoRedux._id || userInfoRedux.id}
                isParent = {projectItem.isParent }
                parentProjectID = {projectItem.parentProjectID}
                openUpdateModal={() => {
                  setProjectToUpdate(projectItem);
                  setModal("UpdateModal");
                }}

                openDeleteModal={() => {
                  setProjectToDelete(projectItem);
                  setModal("DeleteModal");
                }}

                openMembersModal={() => {
                  setProjectListToEdit(projectItem);
                  setModal("ProjectListToEdit");
                }}

                openShareModal={() => {
                  setProjectListToEdit(projectItem);
                  setModal("Share");
                }}

              />
            );
          })}
        </MDBRow>
      );
    });

    const { value: projectsToJSX } = maybeProjects;


  return (
    <div className='timelines-page'>
      <div className='inner-header-position'>
      <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
     
      <div className="projActionButtons d-flex flex-wrap align-items-center" style={{ maxWidth: "100vw" }}>
      <div onClick={() => dispatch(setSidebarStatus(true))} className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
              </div>
              <Link to={'/personal-area'} className="my-heading f-14 ml-3 mr-3 headerLink" >My Projects</Link>
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              >
              <path
              d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
              fill="#9E9E9E"
              />
              </svg>

        {maybeProject?.isParent ? (<p className="my-heading f-14 ml-3 mr-3">Sub Projects</p>) : <p className="my-heading f-14 ml-3 mr-3">Estimation History</p>}  
        {maybeProject?.isParent ?   <input
                type="text"
                className="search-bar"
                
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
                disabled={userInfoRedux?.admin ? adminProjectsLoading : projectLoading}
              /> : null}
        </div>
          {(maybeProject) ?(  
              <div className="d-flex align-items-center project-admin-header ">
           <i className="fa fa-home mr-2"></i> Project: 
            <strong className="ml-2">{maybeProject.title}</strong>
          </div>
              ) :null}
          <div className='d-flex align-items-center'>
            <div className='btn-tool-group'>
              <button
                onClick={userInfoRedux?.admin ? getAdminProjectsAPI : refresh}
                disabled={userInfoRedux?.admin ? adminProjectsLoading : projectLoading}
                className='refresh-btn bg-bid text-white mr-0'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
                  />
                </svg>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>

      {!userInfoRedux?.admin &&  projectLoading && <Loading color='black' text='Loading...' />}
      {userInfoRedux?.admin && adminProjectsLoading && <Loading color='black' text='Loading...' />}


      {maybeProject?.isParent ? 
      (  
        
        <div className="personal-area">
			  <MDBContainer size="lg" className="pt-20">
				<MDBBox className="pt-3 min-h-100 mt-6">
					{error ? <Error /> : null}
					{maybeProjects && maybeProjects.length === 0 ? <NoItems text="There is no projects" /> : null}
					{projectsToJSX}
					<CreateModal isOpen={modal === "CreateModal"} toggle={() => setModal("")} />
					<DeleteProjectModal
						isOpen={modal === "DeleteModal"}
						toggle={() => setModal("")}
						itemToDelete={projectToDelete ? { _id: projectToDelete._id } : null}
					/>
					<UpdateProjectModal
						isOpen={modal === "UpdateModal"}
						toggle={() => setModal("")}
						itemToUpdate={projectToUpdate ? { _id: projectToUpdate._id } : null}
					/>
					<Members
						open={modal === "ProjectListToEdit"}
						toggle={() => setModal("")}
						projectListToEdit={projectListToEdit ? projectListToEdit._id : null}
					/>
					<ShareModal open={modal === "Share"} toggle={() => setModal("")} projectItem={projectListToEdit} setProjectItem={setProjectListToEdit} />
				</MDBBox>
			</MDBContainer>
      </div>):
      (
      <div className='historyCards pt-2'>
        <MDBBox className='pt-2'>
          {maybeProjectTimelines}
          {error ? <Error /> : null}
          {maybeProject &&
          maybeProject.timelines &&
          maybeProject.timelines.length === 0 ? (
            <NoItems text='There is no estimation history in this project' />
          ) : null}
        </MDBBox>
        <HowToCreateModal
          id={maybeProjectId}
          isOpen={modal}
          toggle={() => setModal(s => !s)}
        />
      </div>
      )}

    </div>
  )
}

export default TimelinesPagePersonalArea
