import React, { useState, useRef, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { pricingData } from "./PricingComponentData";
import PricingForm from "./PricingForm";
import profile from "assets/images/profile-img.jpg";
import rating from "assets/images/five-star-rating.png";
import logo from "assets/images/logo/logo.png";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ElementsProvider from "./ElementsProvider";
import { useHttp } from "hooks/useHttp";
import { getProducts } from "api/stripe/checkoutSession";
import addNotification from "helpers/notify";
import { reviewsData } from "./PricingComponentData";
import Step1 from "assets/images/pricemain.png";
import star from "assets/images/star.png";
import arrowforward from "assets/images/arrow-forward-circle-outline.png";
import { Link } from "react-scroll";
import { useHistory } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
};

const OurPricingComponent = () => {
  let carouselRef = useRef();
  const history = useHistory();
  const path = history.location.pathname;
  const [isContainer, setIsContainer] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [products, setProducts] = useState(pricingData);
  const { loading, request: getLicenceApi } = useHttp({
    requestCallback: (data) => getProducts(data),
    onLoad: (res) => {
      // setEmail("");
      // window.location = res.url
      if (res.err) {
        addNotification(res.status || "Error", "danger");
      } else {
        console.log(res);
        setProducts(
          products.map((value) => {
            value["productId"] = res.data.filter(
              (val) => val.product == value.title
            ).length
              ? res.data.filter((val) => val.product == value.title)[0]
                  .productId
              : "";
            value["priceId"] = res.data.filter(
              (val) => val.product == value.title
            ).length
              ? res.data.filter((val) => val.product == value.title)[0].priceId
              : "";
            return value;
          })
        );
      }
    },
    onError: (err) => {
      addNotification(err.status, "danger");
    },
  });

  useEffect(() => {
    getLicenceApi();
  }, []);
  console.log(history.location.pathname);
  const handleClick = (title) => {
    let scrollDiv = null;

    if (title == "Enterprise") {
      scrollDiv = document.getElementById("contact-section");
    } else {
      setIsContainer(false);
      carouselRef.next();
      scrollDiv = document.getElementById("scrollonClick");
    }
    const scrollLength =
      scrollDiv.getBoundingClientRect().top + window.scrollY - 100;
    window.scroll({ top: scrollLength, behavior: "smooth" });
    setSelectedPlan(pricingData.filter((val) => val.title === title)[0]);
  };

  const handleBack = () => {
    setIsContainer(true);
    carouselRef.goToSlide(0);
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
      <div className="carousel-button-group">
        <div
          className={
            currentSlide === totalItems - slidesToShow
              ? "disable"
              : "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
          }
        >
          <img
            src={arrowforward}
            width={20}
            height={20}
            onClick={() => next()}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="site-section" id="pricing-section">
        <div className="container">
          <div className="row mb-4 justify-content-center text-center">
            <div className="col-7 text-center  mb-2">
              <h2 className="section-title">Pricing</h2>
              <p className="lead">Only pay for what you need!</p>
            </div>
          </div>
        </div>

        <Carousel
          responsive={responsive}
          swipeable={false}
          draggable={false}
          keyBoardControl={true}
          arrows={false}
          shouldResetAutoplay={false}
          ref={(el) => (carouselRef = el)}
        >
          <div className={"container"}>
            <div className="row justify-content-center">
              {products.map((data, index) => {
                return path.includes("/estimation") && data.title !== "Enterprise" ? (
                  <div
                    key={index}
                    className="col-lg-3"
                    onClick={() => handleClick(data?.title)}
                  >
                    <div
                      className={
                        data.label
                          ? "pricing-box bg-gradient"
                          : "pricing-box active"
                      }
                      onClick={() => handleClick(data.title)}
                    >
                      <h3>
                        {data?.title}
                        {data?.label && (
                          <label className="bestOffer"> {data?.label}</label>
                        )}
                      </h3>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.description,
                        }}
                      />

                      {data?.title === "Enterprise" ? (
                        <div className="pricing-montly">
                          <h2>Contact US</h2>
                        </div>
                      ) : (
                        <div className="pricing-montly">
                          <h2>
                            $ {data?.amount}
                            <sup>
                              {" "}
                              <span>
                                {" "}
                                <s>
                                  ${" "}
                                  {data?.amount == 0 ? 99 : data?.amount + Math.round(data?.amount / 3)}
                                </s>{" "}
                              </span>
                            </sup>
                            <span>/ Month</span>
                          </h2>
                        </div>
                      )}

                      <ul className="list-pricing-features">
                        {data?.points.map((point, pointIndex) => (
                          <li key={pointIndex}>{point}</li>
                        ))}
                      </ul>
                      {data?.title === "Enterprise" ? (
                        <ul className="trialtext">
                          <strong>Learn more ...</strong>
                        </ul>
                      ) : (
                        <ul className="trialtext">
                          <strong>7-days free trial</strong>
                        </ul>
                      )}

                      {data?.title !== "Enterprise" ? (
                        <button
                          id={data?.title}
                          onClick={() => handleClick(data?.title)}
                          className="btn-choose"
                        >
                          Start Trial
                        </button>
                      ) : (
                        <Link
                          className="btn-choose"
                          activeClass="btn-choose"
                          to="contact-section"
                          spy={true}
                          smooth={true}
                          duration={1500}
                          style={{ cursor: "pointer" }}
                        >
                          Contact Us
                        </Link>
                      )}
                    </div>
                  </div>
                ) : null;
              })}
            </div>

            <div className="row justify-content-center">
              {products.map((data, index) => {
                return path === "/" && data.title === "Enterprise" ? (
                  <div
                    key={index}
                    className="col-lg-8"
                    onClick={() => handleClick(data?.title)}
                  >
                    <div
                      className={
                        data.label
                          ? "pricing-box bg-gradient p-4"
                          : "pricing-box active p-4"
                      }
                      onClick={() => handleClick(data.title)}
                    >
                      <h3 className="enterprize-head">
                        {data?.title}
                        {data?.label && (
                          <label className="bestOffer"> {data?.label}</label>
                        )}
                      </h3>

                      <p
                        className="enterprize-p"
                        dangerouslySetInnerHTML={{
                          __html: data?.description,
                        }}
                      />

                      {data?.title === "Enterprise" ? (
                        <div className="pricing-montly ">
                          <h2 className="enterprize-subhead">Contact US</h2>
                        </div>
                      ) : (
                        <div className="pricing-montly">
                          <h2>
                            $ {data?.amount}
                            <sup>
                              {" "}
                              <span>
                                {" "}
                                <s>
                                  ${" "}
                                  {data?.amount + Math.round(data?.amount / 3)}
                                </s>{" "}
                              </span>
                            </sup>
                            <span>/ Month</span>
                          </h2>
                        </div>
                      )}

                      <div className="list-pricing-features mt-3">
                        {data?.points.map((point, pointIndex) => (
                          <div key={pointIndex}>{point}</div>
                        ))}
                      </div>
                      {data?.title === "Enterprise" ? (
                        <ul className="trialtext">
                          <strong>Learn more ...</strong>
                        </ul>
                      ) : (
                        <ul className="trialtext">
                          <strong>7-days free trial</strong>
                        </ul>
                      )}

                      {data?.title !== "Enterprise" ? (
                        <button
                          id={data?.title}
                          onClick={() => handleClick(data?.title)}
                          className="btn-choose"
                        >
                          Start Trial
                        </button>
                      ) : (
                        <Link
                          className="btn-choose mt-3"
                          activeClass="btn-choose"
                          to="contact-section"
                          spy={true}
                          smooth={true}
                          duration={1500}
                          style={{ cursor: "pointer" }}
                        >
                          Contact Us
                        </Link>
                      )}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className={""}>
            <div className={`row  ${isContainer ? "cutom-price-row" : ""}`}>
              <div className="col-lg-1 bg-dark-gary"></div>
              <div className="col-lg-5 p-0" style={{ marginLeft: "20px" }}>
                <div className="custome-height">
                  <img
                    src={Step1}
                    alt=""
                    width="100%"
                    height="100%"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div className="row price-main-content w-100">
                  <div className="col-md-5">
                    <div className="logo-price">
                      <div className="pt-2 pl-4 pr-0">
                        <div className="d-flex align-items-center mb-4 mt-4">
                          <img
                            src={logo}
                            alt="Bidlight Logo"
                            height={50}
                            width={50}
                          />
                          <span className="logo-title ml-2 text-white logo-sub-text">
                            <span>id</span>Light
                          </span>
                        </div>
                        <div className="logo-price-text pt-5 h3">In Our</div>
                        <div className="logo-price-text h3">Community</div>
                        <div className="logo-price-sub-text">We Trust</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 custom-carousel">
                    <div className="custom-rating">
                      <div>
                        <Carousel
                          responsive={responsive}
                          infinite
                          autoPlay
                          arrows={false}
                          showDots
                          dotListClass="custom-dot-list-style"
                          className="carousel-track-custom"
                          // customDot={<CustomDot />}
                          customButtonGroup={<ButtonGroup />}
                        >
                          {reviewsData.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="bg-transparent rounded-lg mx-1 p-4 text-left"
                              >
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex justify-content-start">
                                    <div>
                                      <img
                                        src={item.image}
                                        alt=""
                                        width={50}
                                        height={50}
                                        className="rounded-circle mr-3"
                                      />
                                    </div>
                                    <div>
                                      <h4 className="mb-1">{item.company}</h4>
                                      <span className="mb-0 mt-auto">
                                        <strong>{item.user}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <span className="mr-2">5.0</span>
                                    <img
                                      src={star}
                                      alt=""
                                      width={25}
                                      height={25}
                                    />
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <p className="mb-0">{item.review}</p>
                                </div>
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 bg-light-gary">
                <PricingForm
                  selectedPlan={selectedPlan}
                  handleBack={handleBack}
                />
              </div>
              <div className="col-lg-1 bg-light-gary"></div>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default OurPricingComponent;
