import React from "react";

export const DatabaseIcon = ({ color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M18 6.5V9.5C18 11.9853 13.9706 14 9 14C4.02944 14 0 11.9853 0 9.5V6.5C0 8.9853 4.02944 11 9 11C13.9706 11 18 8.9853 18 6.5ZM0 11.5C0 13.9853 4.02944 16 9 16C13.9706 16 18 13.9853 18 11.5V14.5C18 16.9853 13.9706 19 9 19C4.02944 19 0 16.9853 0 14.5V11.5ZM9 9C4.02944 9 0 6.98528 0 4.5C0 2.01472 4.02944 0 9 0C13.9706 0 18 2.01472 18 4.5C18 6.98528 13.9706 9 9 9Z"
        fill={color}
      />
    </svg>
  );
};
