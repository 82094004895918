const handleBuildTree = (result, isAdmin, version) => {
  const possibleParentItems = [];
  const processedItems = result
    .map((row) => {
      const showFallbackData = false;
      const possibleItem = getApplicableDataForDatabase(
        row,
        false,
        showFallbackData
      );
      if (!possibleItem && row.company != null) {
        const database = {
          default: row.default || row.average || row.company,
        };

        if (database != null) {
          const companyDiv = convertFrom(
            divisionConvertFrom(row.company),
            null,
            null
          );
          const databaseDiv = convertFrom(
            divisionConvertFrom(database),
            null,
            null
          );
          let item = null;

          if (
            row.oneBuild !== null &&
            row.craftsman !== null &&
            row.company !== null &&
            row.default !== null &&
            row.average !== null
          ) {
            const oneBuildDiv = convertFrom(
              divisionConvertFrom(row?.oneBuild),
              null,
              null
            );
          
            const craftsmanDiv = convertFrom(
              divisionConvertFrom(row?.craftsman),
              null,
              null
            );
            item = CompanyConvertFrom(companyDiv, databaseDiv, oneBuildDiv, craftsmanDiv);
          } else {
            item = CompanyConvertFrom(companyDiv, databaseDiv, null, null);
          }

          item.ExistingDataInDB = true;
          possibleParentItems.push(item);
        }
      }

      return possibleItem;
    })
    .filter((item) => item != null);

  const tree = buildTheTree(
    processedItems,
    possibleParentItems,
    "Project X",
    version
  );
  return tree;
};

const buildTheTree = (
  listofDivisionViewers,
  possibleParentOfMissingItems,
  projectName,
  protocol
) => {
  // JavaScript object equivalents to ConcurrentDictionary
  const division1 = {};
  const division2 = {};
  const division3 = {};
  const division4 = {};
  const division5 = {};
  const division6 = {};
  const division7 = {};

  // JavaScript equivalent of Parallel.ForEach using forEach
  listofDivisionViewers.forEach((divisionViewer) => {
    switch (divisionViewer?.Level) {
      case "1":
        division1[divisionViewer.Division] = divisionViewer;
        break;
      case "2":
        division2[divisionViewer.Division] = divisionViewer;
        break;
      case "3":
        division3[divisionViewer.Division] = divisionViewer;
        break;
      case "4":
        division4[divisionViewer.Division] = divisionViewer;
        break;
      case "5":
        division5[divisionViewer.Division] = divisionViewer;
        break;
      case "6":
        division6[divisionViewer.Division] = divisionViewer;
        break;
      case "7":
        division7[divisionViewer.Division] = divisionViewer;
        break;
      default:
        break;
    }
  });

  const missingDivisionViewer1 = addParentsToNodes(division1, null, protocol);
  const missingDivisionViewer2 = addParentsToNodes(
    division2,
    division1,
    protocol
  );
  const missingDivisionViewer3 = addParentsToNodes(
    division3,
    division2,
    protocol
  );
  const missingDivisionViewer4 = addParentsToNodes(
    division4,
    division3,
    protocol
  );
  const missingDivisionViewer5 = addParentsToNodes(
    division5,
    division4,
    protocol
  );
  const missingDivisionViewer6 = addParentsToNodes(
    division6,
    division5,
    protocol
  );
  const missingDivisionViewer7 = addParentsToNodes(
    division7,
    division6,
    protocol
  );

  if (possibleParentOfMissingItems && possibleParentOfMissingItems.length > 0) {
    const missingParentDivisionViewer1 = {};
    const missingParentDivisionViewer2 = {};
    const missingParentDivisionViewer3 = {};
    const missingParentDivisionViewer4 = {};
    const missingParentDivisionViewer5 = {};
    const missingParentDivisionViewer6 = {};
    const missingParentDivisionViewer7 = {};

    possibleParentOfMissingItems.forEach((divisionViewer) => {
      const level = parseInt(divisionViewer?.Level);
      switch (level) {
        case 1:
          missingParentDivisionViewer1[divisionViewer.Division] =
            divisionViewer;
          break;
        case 2:
          missingParentDivisionViewer2[divisionViewer.Division] =
            divisionViewer;
          break;
        case 3:
          missingParentDivisionViewer3[divisionViewer.Division] =
            divisionViewer;
          break;
        case 4:
          missingParentDivisionViewer4[divisionViewer.Division] =
            divisionViewer;
          break;
        case 5:
          missingParentDivisionViewer5[divisionViewer.Division] =
            divisionViewer;
          break;
        case 6:
          missingParentDivisionViewer6[divisionViewer.Division] =
            divisionViewer;
          break;
        case 7:
          missingParentDivisionViewer7[divisionViewer.Division] =
            divisionViewer;
          break;
        default:
          break;
      }
    });

    const addMissingParentsToNodesTasks = [
      addParentsToNodes(missingDivisionViewer1, null, protocol),
      addParentsToNodes(
        missingDivisionViewer2,
        missingParentDivisionViewer1,
        protocol
      ),
      addParentsToNodes(
        missingDivisionViewer3,
        missingParentDivisionViewer2,
        protocol
      ),
      addParentsToNodes(
        missingDivisionViewer4,
        missingParentDivisionViewer3,
        protocol
      ),
      addParentsToNodes(
        missingDivisionViewer5,
        missingParentDivisionViewer4,
        protocol
      ),
      addParentsToNodes(
        missingDivisionViewer6,
        missingParentDivisionViewer5,
        protocol
      ),
      addParentsToNodes(
        missingDivisionViewer7,
        missingParentDivisionViewer6,
        protocol
      ),
    ];

    Promise.all(addMissingParentsToNodesTasks).then((res) => {});

    assignParentRecursively(
      missingDivisionViewer7,
      missingParentDivisionViewer6,
      missingParentDivisionViewer5,
      missingParentDivisionViewer4,
      missingParentDivisionViewer3,
      missingParentDivisionViewer2,
      missingParentDivisionViewer1,
      protocol
    );
    assignParentRecursively(
      null,
      missingDivisionViewer6,
      missingParentDivisionViewer5,
      missingParentDivisionViewer4,
      missingParentDivisionViewer3,
      missingParentDivisionViewer2,
      missingParentDivisionViewer1,
      protocol
    );
    assignParentRecursively(
      null,
      null,
      missingDivisionViewer5,
      missingParentDivisionViewer4,
      missingParentDivisionViewer3,
      missingParentDivisionViewer2,
      missingParentDivisionViewer1,
      protocol
    );
    assignParentRecursively(
      null,
      null,
      null,
      missingDivisionViewer4,
      missingParentDivisionViewer3,
      missingParentDivisionViewer2,
      missingParentDivisionViewer1,
      protocol
    );
    assignParentRecursively(
      null,
      null,
      null,
      null,
      missingDivisionViewer3,
      missingParentDivisionViewer2,
      missingParentDivisionViewer1,
      protocol
    );
    assignParentRecursively(
      null,
      null,
      null,
      null,
      null,
      missingDivisionViewer2,
      missingParentDivisionViewer1,
      protocol
    );

    for (const key in missingParentDivisionViewer1) {
      if (
        !division1.hasOwnProperty(key) &&
        missingParentDivisionViewer1.hasOwnProperty(key)
      ) {
        const missingItem = missingParentDivisionViewer1[key];
        if (
          missingItem &&
          missingItem.Children &&
          missingItem.Children.length > 0
        ) {
          division1[key] = missingItem;
        }
      }
    }
  }

  const resultDivision = Object.values(division1).sort((a, b) =>
    a.Division.localeCompare(b.Division)
  );

  const childResult = addChildrenToMainNode(
    resultDivision,
    projectName,
    protocol
  );
  return childResult;
};

const addParentsToNodes = (
  mainNodes,
  possibleParents = null,
  protocol = null
) => {
  const result = {};
  Object.entries(mainNodes).forEach(([key, node]) => {
    if (node !== null) {
      const parent = findParent(node, possibleParents, protocol);
      node.Parent = parent;
      if (parent) {
        parent.Children.push(node);
      } else {
        result[key] = node;
      }
    }
  });

  return result;
};

const lvl5LastDigitsAreChildren = (mainCode, childCode) => {
  const mainNumber = parseInt(mainCode.slice(-3));
  const childNumber = parseInt(childCode.slice(-3));

  return childNumber < mainNumber + 100 && childNumber > mainNumber;
};

const addChildrenToMainNode = (possibleChildren = [], projectName = null) => {
  const mainDiv = {
    Division: "X",
    Description: projectName,
    Level: "0",
    Children: [],
  };
  for (const child of possibleChildren) {
    const level = parseInt(child.Level);
    if (child) {
      mainDiv.Children.push(child);
      if (level > 1) {
        child.Parent = mainDiv;
      }
    }
  }

  return mainDiv;
};

const assignParentRecursively = (
  missingDVs,
  missingParentDivisionViewer6,
  missingParentDivisionViewer5,
  missingParentDivisionViewer4,
  missingParentDivisionViewer3,
  missingParentDivisionViewer2,
  missingParentDivisionViewer1,
  protocol
) => {
  for (const [key, missingDV] of Object.entries(missingDVs)) {
    let parent = assignParent(
      missingDV,
      missingParentDivisionViewer6,
      protocol
    );
    if (parent) {
      parent = assignParent(parent, missingParentDivisionViewer5, protocol);
      parent = assignParent(parent, missingParentDivisionViewer4, protocol);
      parent = assignParent(parent, missingParentDivisionViewer3, protocol);
      parent = assignParent(parent, missingParentDivisionViewer2, protocol);
      parent = assignParent(parent, missingParentDivisionViewer1, protocol);
    } else {
      parent = assignParent(missingDV, missingParentDivisionViewer5, protocol);
      if (parent) {
        parent = assignParent(parent, missingParentDivisionViewer4, protocol);
        parent = assignParent(parent, missingParentDivisionViewer3, protocol);
        parent = assignParent(parent, missingParentDivisionViewer2, protocol);
        parent = assignParent(parent, missingParentDivisionViewer1, protocol);
      } else {
        parent = assignParent(
          missingDV,
          missingParentDivisionViewer4,
          protocol
        );
        if (parent) {
          parent = assignParent(parent, missingParentDivisionViewer3, protocol);
          parent = assignParent(parent, missingParentDivisionViewer2, protocol);
          parent = assignParent(parent, missingParentDivisionViewer1, protocol);
        } else {
          parent = assignParent(
            missingDV,
            missingParentDivisionViewer3,
            protocol
          );
          if (parent) {
            parent = assignParent(
              parent,
              missingParentDivisionViewer2,
              protocol
            );
            parent = assignParent(
              parent,
              missingParentDivisionViewer1,
              protocol
            );
          } else {
            parent = assignParent(
              missingDV,
              missingParentDivisionViewer2,
              protocol
            );
            if (parent) {
              parent = assignParent(
                parent,
                missingParentDivisionViewer1,
                protocol
              );
            } else {
              parent = findParent(
                missingDV,
                missingParentDivisionViewer1,
                protocol
              );
            }
          }
        }
      }
    }
  }
};

const assignParent = (node, possibleParents, protocol) => {
  if (!node || !possibleParents) return null;

  const parent = findParent(node, possibleParents, protocol);
  if (!parent) return null;

  if (!parent.Children.includes(node)) {
    parent.Children.push(node);
    node.Parent = parent;
  }
  return parent;
};

const findParent = (division, possibleParents = null, protocol = null) => {
  if (!possibleParents) {
    return null;
  } else {
    let result = null;
    if (protocol === "RevitUniFormat") {
      for (const [key, value] of Object.entries(possibleParents)) {
        const diffat = findTheBreakIndex(division.Division, value.Division);
        const parLength = value.Division.length;
        const childLength = division.Division.length;
        if (division.Division.startsWith(value.Division)) {
          if (
            diffat !== -1 &&
            parLength < childLength &&
            diffat === parLength
          ) {
            result = value;
            break;
          }
        } else if (
          division.Level == "5" &&
          diffat !== -1 &&
          parLength === childLength &&
          (diffat === 6 || diffat === 7) &&
          lvl5LastDigitsAreChildren(value.Division, division.Division)
        ) {
          result = value;
          break;
        }
      }
    } else if (protocol === "MasterFormat") {
      const splittedCode = division.Division?.split(" ");
      if (!splittedCode || !(splittedCode.length == 3 || splittedCode.length == 4)) return null;

      let expectedParentId = null;
      switch (division.Level) {
        case "1":
          expectedParentId = null;
          break;
        case "2":
          expectedParentId = `${splittedCode[0]} 00 00`;
          break;
        case "3":
          const secondPart = parseInt(splittedCode[1]);
          if (isNaN(secondPart)) return null;
          const parentSecondPartItem = secondPart - (secondPart % 10);
          if (parentSecondPartItem >= 100) return null;
          const parentSecondPartStr = parentSecondPartItem
            .toString()
            .padStart(2, "0");
          if (!parentSecondPartStr) return null;
          expectedParentId = `${splittedCode[0]} ${parentSecondPartStr} 00`;
          break;
        case "4":
          expectedParentId = `${splittedCode[0]} ${splittedCode[1]} 00`;
          break;
        case "5":
          expectedParentId = `${splittedCode[0]} ${splittedCode[1]} ${
            splittedCode[2].split(".")[0]
          }`;
          break;
        case "6":
          expectedParentId = `${splittedCode[0]} ${splittedCode[1]} ${splittedCode[2]}`;
          break;
        default:
          return null;
      }

      if (!expectedParentId) return null;

      return possibleParents[expectedParentId];
    } else if (protocol === "OmniClass") {
      const splittedCode = division.Division?.split(" ");

      let expectedParentId = null;
      switch (division.Level) {
        case "1":
          expectedParentId = null;
          break;
        case "2":
          if (!splittedCode || splittedCode.length !== 1) return null;

          const subSplit = division.Division?.split("-");
          if (!subSplit || subSplit.length !== 2) return null;

          expectedParentId = subSplit[0].trim();
          break;
        case "3":
          if (!splittedCode || splittedCode.length !== 2) return null;
          expectedParentId = splittedCode[0].trim();
          break;
        case "4":
          if (!splittedCode || splittedCode.length !== 3) return null;
          expectedParentId = `${splittedCode[0].trim()} ${splittedCode[1].trim()}`;
          break;
        case "5":
          if (!splittedCode || splittedCode.length !== 4) return null;
          expectedParentId = `${splittedCode[0].trim()} ${splittedCode[1].trim()} ${splittedCode[2].trim()}`;
          break;
          case "6":
          if (!splittedCode || splittedCode.length !== 5) return null;
          expectedParentId = `${splittedCode[0].trim()} ${splittedCode[1].trim()} ${splittedCode[2].trim() + splittedCode[3].Split(".")[0]}`;
          break;
        default:
          return null;
      }

      if (!expectedParentId) return null;

      return possibleParents[expectedParentId];
    } else if (protocol === "TeslaMasterFormat") {
      const splittedCode = division.Division?.split(" ");
      if (!splittedCode || splittedCode.length < 3 || splittedCode.length > 6)
        return null;

      const lvl1 = splittedCode[0].trim();
      const lvl2 = splittedCode[1].trim();
      const lvl3SplittedCode = splittedCode[2].split(".");
      const lvl3 = lvl3SplittedCode[0].trim();
      let lvl4 = null;
      let lvl5 = null;
      let lvl6 = null;
      let lvl7 = null;
      if (lvl3SplittedCode.length === 2) lvl4 = lvl3SplittedCode[1];
      if (splittedCode.length > 3) lvl5 = splittedCode[3];
      if (splittedCode.length > 4) lvl6 = splittedCode[4];
      if (splittedCode.length > 5) lvl7 = splittedCode[5];

      let expectedParentId = null;
      switch (division.Level) {
        case "1":
          expectedParentId = null;
          break;
        case "2":
          expectedParentId = `${lvl1} 00 00`;
          break;
        case "3":
          expectedParentId = `${lvl1} ${lvl2} 00`;
          break;
        case "4":
          expectedParentId = `${lvl1} ${lvl2} ${lvl3}`;
          break;
        case "5":
          expectedParentId = `${lvl1} ${lvl2} ${lvl3}.${lvl4}`;
          break;
        case "6":
          expectedParentId = `${lvl1} ${lvl2} ${lvl3}.${lvl4} ${lvl5}`;
          break;
        case "7":
          expectedParentId = `${lvl1} ${lvl2} ${lvl3}.${lvl4} ${lvl5} ${lvl6}`;
          break;
        default:
          return null;
      }

      if (!expectedParentId) return null;

      return possibleParents[expectedParentId];
    } else if (protocol === "CSIUniFormat") {
      let parrentKeys = Object.keys(possibleParents);
      for (let key of parrentKeys) {
        if (division.Division.startsWith(possibleParents[key]?.Division)) {
          result = possibleParents[key];
          break;
        }
      }
      // result = Object.keys(possibleParents).find(x => division.Code.startsWith(x.Code));
    }
    return result;
  }
};

const findTheBreakIndex = (child, parent) => {
  if (!child.length || !parent.length) return 0;

  for (let i = 0; i < child.length; i++) {
    if (child[i] !== parent[i]) {
      return i;
    }
  }
  if (child.length !== parent.length) {
    return child.length;
  }
  return -1;
};

// May be used if current scenario is not working properly
// function getApplicableDataForDatabase(row, isAdmin, showFallbackData) {
//   if (!row) return null;
//   let itemToProcess = null;

//   if (isAdmin) {
//     if (showFallbackData) {
//       itemToProcess =
//         row.default != null && row.default.ref === "default"
//           ? row.default
//           : row.average;
//     } else {
//       itemToProcess =
//         row.default != null && !(row.default.ref === "default")
//           ? row.default
//           : null;
//     }

//     if (
//       showFallbackData &&
//       row.default != null &&
//       row.default.ref !== "default"
//     ) {
//       itemToProcess._id = null;
//     }
//   } else {
//     if (showFallbackData) {
//       itemToProcess =
//         row.company != null && row.company.ref === "company"
//           ? row.company
//           : row.average;
//     } else {
//       itemToProcess =
//         row.company != null && !(row.company.ref === "company")
//           ? row.company
//           : null;
//     }

//     if (
//       showFallbackData &&
//       row.company != null &&
//       row.company.ref !== "company"
//     ) {
//       itemToProcess._id = null;
//     }
//   }

//   if (!itemToProcess) return null;

//   if (showFallbackData) {
//     itemToProcess._id = null;
//   }

//   if (isAdmin) {
//     if (!row.default && itemToProcess === row.average) {
//       itemToProcess.IsFallback = true;
//     }
//   } else {
//     if (!row.company && itemToProcess === row.average) {
//       itemToProcess.IsFallback = true;
//     }
//   }
//   const database = row.average ?? row.default ?? row.company;

//   const companyDiv = convertFrom(itemToProcess, null, null);
//   const divisionFormData = divisionConvertFrom(database);
//   const databaseDiv = convertFrom(divisionFormData, null, null);

//   const item = CompanyConvertFrom(companyDiv, databaseDiv);
//   return item;
// }

function getApplicableDataForDatabase(row, isAdmin, showFallbackData) {
  if (!row) return null;
  let itemToProcess = null;

  if (showFallbackData) {
    itemToProcess =
      row.company != null && (row.company.isFallback ?? false)
        ? row.company
        : row.average;
  } else {
    itemToProcess =
      row.company != null && !(row.company.isFallback ?? false)
        ? row.company
        : null;
  }

  if (showFallbackData && row.company != null && row.company.isFallback) {
    itemToProcess._id = null;
  }

  if (!itemToProcess) return null;

  if (showFallbackData) {
    itemToProcess._id = null;
  }

  if (isAdmin) {
    if (!row.default && itemToProcess === row.average) {
      itemToProcess.isFallback = true;
    }
  } else {
    if (!row.company && itemToProcess === row.average) {
      itemToProcess.isFallback = true;
    }
  }
  const database = row.average ?? row.default ?? row.company;
  let oneBuildRow = null;
  let craftsmanRow = null;
  const companyDiv = convertFrom(itemToProcess, null, null);
  const divisionFormData = divisionConvertFrom(database);
  const databaseDiv = convertFrom(divisionFormData, null, null);
    const oneBuildDiv = row.oneBuild ?? null;
    const craftsmanDiv = row.craftsman ?? null;
    const oneBuildFormData = divisionConvertFrom(oneBuildDiv);
    oneBuildRow = convertFrom(oneBuildFormData, null, null);
    const craftsmanFormData = divisionConvertFrom(craftsmanDiv);
    craftsmanRow = convertFrom(craftsmanFormData, null, null);

  const item = CompanyConvertFrom(companyDiv, databaseDiv, oneBuildRow, craftsmanRow);
  return item;
}

function convertFrom(
  division,
  getCrewAction = null,
  getEquipmentAction = null
) {
  const divViewer = {
    _id: division._id,
    Division: division.Code,
    CategoryId: division.CategoryId,
    CustomCategoryUpdated: division.CustomCategoryUpdated,
    Description: division.Description,
    Level: division.Level,
    Unit: division.Unit,
    TotalComposedUnitCost: division.TotalComposedUnitCost || 0,
    MaterialUnitCost: division.MaterialUnitCost,
    CrewUnitCost: division.CrewUnitCost,
    EquipmentUnitCost: division.EquipmentUnitCost,
    TotalSimpleUnitCost: division.TotalSimpleUnitCost || 0,
    UnitCost: division.UnitCost,
    Awp: division.Awp,
    Source: division.Source,
    isFallback: division.isFallback,
    isPublic: division?.isPublic ?? false,
    ref: division.ref,
    Crews: [],
    Tools: [],
  };

  // Add Crews
  // if (getCrewAction !== null && division.crews !== null) {
  //   for (const crewCode of division.crews) {
  //     const crew = getCrewAction(crewCode);
  //     if (crew !== null) {
  //       divViewer.crews.push(crew);
  //     }
  //   }
  // }

  // Add Equipment
  // if (getEquipmentAction !== null && division.EquipmentCodes !== null) {
  //   for (const equipmentCode of division.EquipmentCodes) {
  //     const equipment = getEquipmentAction(equipmentCode);
  //     if (equipment !== null) {
  //       divViewer.Tools.push(equipment);
  //     }
  //   }
  // }

  return divViewer;
}

function divisionConvertFrom(input) {
  let categoryID = [];
  if (input?.CategoryId !== null) {
    if (
      typeof input?.CategoryId === "string" &&
      input?.CategoryId.trim() !== ""
    ) {
      categoryID.push(input.CategoryId?.trim());
    } else if (Array.isArray(input?.CategoryId)) {
      input.CategoryId.forEach((item) => {
        if (typeof item === "string" && item.trim() !== "") {
          categoryID.push(item.trim());
        }
      });
    } else if (Array.isArray(input?.CategoryId)) {
      input.CategoryId.forEach((item) => {
        const itemToAdd = item.toString().trim();
        if (itemToAdd !== "") {
          categoryID.push(itemToAdd);
        }
      });
    }
  }

  const div = {
    _id: input?._id,
    Description: input?.Description,
    Code: input?.Code,
    Level: input?.Level,
    Unit: input?.Unit,
    categoryId: categoryID,
    TotalSimpleUnitCost: input?.TotalSimpleUnitCost ?? 0,
    Guid: input?._id,
    OrganizationId: input?.OrganizationId,
    IsPublic: input?.isPublic ?? false,
    // EquipmentCodes:
    //   input?.EquipmentCodes !== null ? [...input?.EquipmentCodes] : [],
    // CrewCodes: input.CrewCodes !== null ? [...input.CrewCodes] : [],
    CrewUnitCost: input?.CrewUnitCost ?? 0,
    EquipmentUnitCost: input?.EquipmentUnitCost ?? 0,
    MaterialUnitCost: input?.MaterialUnitCost ?? 0,
    TotalComposedUnitCost: input?.TotalComposedUnitCost ?? 0,
    Awp: input?.Awp,
    ref: input?.ref,
    isFallback: input?.isFallback ?? false,
    Source: input?.Source,
    UnitCost: input?.UnitCost ?? 0,
  };

  return div;
}

function CompanyConvertFrom(division, database, oneBuildDiv, craftsmanDiv) {
  const item = {};
  item._id = division._id;
  item.CategoryId = division.CategoryId;
  item.Description = division.Description;
  item.Division = division.Division; // code
  item.Level = division.Level;
  item.Unit = division.Unit;
  item.Awp = division.Awp;

  item.totalComposedUnitCost = division.TotalComposedUnitCost;
  item.materialUnitCost = division.MaterialUnitCost;
  item.crewUnitCost = division.CrewUnitCost;
  item.equipmentUnitCost = division.EquipmentUnitCost;

  item.totalSimpleUnitCost = division.TotalSimpleUnitCost;

  item.unitCost = division.UnitCost;

  item.isPublic = division.isPublic ?? false;

  item.isFallback = division.isFallback;
  item.ref = division.ref;
  item.Children = [];
  item.Database = database;
  item.oneBuild = oneBuildDiv;
  item.craftsman = craftsmanDiv;

  if (division.Crews !== null) {
    division.Crews.forEach((crew) => item.AddCrew(crew));
  }
  if (division.Tools !== null) {
    division.Tools.forEach((tool) => item.AddTool(tool));
  }

  return item;
}

export default handleBuildTree;
