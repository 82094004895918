import React, { useState } from "react";
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../components/ui-components/loading";

import { choseToggle } from "helpers/toggleIfLoading";
import addNotification from "helpers/notify";
import { assignUserToProject } from "api/users/addUserToProject";
import { removeUserFromProject } from "api/users/removeUserFromProject";
import { Maybe } from "helpers/maybeFunctor";
import { useSearch } from "hooks/useSearch";
import { useHttp } from "hooks/useHttp";
import {
  addUserToProject as addUserToProjectRedux,
  removeUserFromProject as removeUserFromProjectRedux,
} from "actions/user";
import { eventTrack } from "helpers/ga4Helper";
const MemberModalAdmin = (props) => {
  const { projectListToEdit } = props;

  //   const usersRedux = useSelector((s) => s.user.users);
  //   const projectsRedux = useSelector((s) => s.user.projects);
  //   const emailRedux = useSelector((s) => s.user.userInfo.email);

  const dispatch = useDispatch();
  const search = useSearch();

  const [searchValue, setSearchValue] = useState("");
  const [isAddPage, setIsAddPage] = useState(true);
  const [chosenUserForDelete, setChosenUserForDelete] = useState(null);
  const [chosenUserForAdd, setChosenUserForAdd] = useState(null);

  const [btn, setBtn] = useState("AddUser");

  const toggle = () => {
    props.toggle();
    setChosenUserForDelete(null);
    setChosenUserForAdd(null);
  };

  const {
    loading: assignUserToProjectAPILoading,
    request: assignUserToProjectAPI,
  } = useHttp({
    requestCallback: assignUserToProject,
    onLoad: (res) => {
    //   dispatch(addUserToProjectRedux(chosenUserForAdd, projectListToEdit));
      setChosenUserForAdd(null);
      addNotification(
        "User was successfully added to this project!",
        "success"
      );
    },
    onError: (res) => {
      setChosenUserForAdd(null);
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const addUserToProject = () => {
    if (!chosenUserForAdd) {
      addNotification(
        "Choose the user you want to add to this project",
        "default"
      );
    } else {
      return assignUserToProjectAPI({
        project_id: projectListToEdit,
        user_id: chosenUserForAdd,
      });
    }
  };

  const {
    loading: deleteUserFromProjectLoading,
    request: removeUserFromProjectAPI,
  } = useHttp({
    requestCallback: removeUserFromProject,
    onLoad: (res) => {
    //   dispatch(
    //     removeUserFromProjectRedux(chosenUserForDelete, projectListToEdit)
    //   );
      setChosenUserForDelete(null);
      addNotification(
        "User was successfully removed from this project!",
        "success"
      );
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const deleteUserToProject = () => {
    if (!chosenUserForDelete) {
      addNotification(
        "Choose the user you want to delete from this project",
        "default"
      );
    } else {
      return removeUserFromProjectAPI({
        project_id: projectListToEdit,
        user_id: chosenUserForDelete,
      });
    }
  };

  const projectListToEditUsers = Maybe.of(props?.companyProjects?.projects)
    .map((projectsRedux) =>
      Array.isArray(projectsRedux)
        ? projectsRedux.find(
            (projectItem) => projectItem?._id === projectListToEdit
          )
        : null
    )
    ?.map((project) => project?.users)?.value;


    console.log(projectListToEditUsers)
    console.log(props?.companyProjects)
    console.log(projectListToEdit)



  const usersAbleToAdd = Maybe.of(props?.companyUser)
    .map((users) => {
      if (projectListToEditUsers) {
        return users.filter(
          (user) => !projectListToEditUsers?.includes(user._id)
        );
        //   .filter((user) => user.email !== emailRedux);
      } else {
        return null;
      }
    })
    .map((filteredUsers) => {
      return search(filteredUsers, "email", searchValue);
    })
    .map((searchedUsers) => {
      return searchedUsers.map((user) => {
        const { name, lastName, email, _id } = user;
        return (
          <MDBListGroupItem
            key={_id}
            className="cursor-pointer p-2 member-list"
            onClick={() => setChosenUserForAdd(_id)}
            active={chosenUserForAdd === _id}
          >
            <div className="font-size-2 ">
              Name : {name} {lastName}
            </div>
            <div className="font-size-1">Email : {email}</div>
          </MDBListGroupItem>
        );
      });
    }).value;

  const usersAbleToDelete = Maybe.of(props?.companyUser)
    .map((users) => {
      if (projectListToEdit && projectListToEditUsers) {
        return users.filter((user) =>
          projectListToEditUsers?.includes(user._id)
        );
        //   .filter((user) => user.email !== emailRedux);
      } else {
        return null;
      }
    })
    .map((filteredUsers) => {
      return search(filteredUsers, "email", searchValue);
    })
    .map((searchedUsers) => {
      return searchedUsers.map((user) => {
        const { name, lastName, email, _id } = user;
        return (
          <MDBListGroupItem
            key={_id}
            className="cursor-pointer p-2 member-list"
            onClick={() => setChosenUserForDelete(_id)}
            active={chosenUserForDelete === _id}
          >
            <div className="font-size-2">
              Name : {name} {lastName}
            </div>
            <div className="font-size-1">Email : {email}</div>
          </MDBListGroupItem>
        );
      });
    }).value;

  const loading = deleteUserFromProjectLoading || assignUserToProjectAPILoading;

  return (
    <MDBModal
      toggle={choseToggle(loading, toggle)}
      isOpen={props.open}
      centered
      className="create-modal"
    >
      <MDBModalHeader
        toggle={choseToggle(loading, toggle)}
        className="d-flex mb-0 pb-2"
      >
        Project members
      </MDBModalHeader>
      <MDBModalBody className="pt-1">
        <div
          className="btn-group btn-group-toggle toggle-group mt-3 w-100 mx-0 report-btn-group"
          data-toggle="buttons"
        >
          <label
            className={`${"btn btn-secondary py-3"} ${
              btn === "AddUser" ? "active" : ""
            }`}
            onClick={() => {
              setIsAddPage(true);
              setBtn("AddUser");
              {
                eventTrack(
                  "Add-User-Action",
                  "Add-User",
                  { searchValue },
                  { searchValue },
                  false,
                  searchValue
                );
              }
            }}
          >
            <input type="radio" name="options" id="option1" defaultChecked />
            Add User
          </label>
          <label
            className={`${"btn btn-secondary py-3"} ${
              btn === "Delete" ? "active" : ""
            }`}
            onClick={() => {
              {
                setIsAddPage(false);
                setBtn("Delete");
              }
              {
                eventTrack(
                  "Delete-User-Action",
                  "Delete-User",
                  { searchValue },
                  { searchValue },
                  false,
                  searchValue
                );
              }
            }}
          >
            <input type="radio" name="options" id="option2" />
            Delete User
          </label>
        </div>

        {isAddPage ? (
          <>
            {/* <h6 className="mt-2">Add user menu</h6> */}
            <div className="form-group mb-2">
              <input
                type="text"
                className="shared-input-search mt-2"
                placeholder="Search by email.."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <MDBListGroup className="fixes-height-members overflow-auto">
              {usersAbleToAdd}
            </MDBListGroup>
            {loading ? (
              <div className="d-flex flex-center py-2 spinner-box">
                <Loading color="black" text="Loading..." />
              </div>
            ) : null}
            <MDBBox className="d-flex justify-content-center">
              <MDBBtn
                size="sm"
                color=""
                className="btn-header active-toggle mt-4 capitalize f-15"
                onClick={() => {
                  addUserToProject();
                  {
                    eventTrack(
                      "Add-User-Action",
                      "Add-User",
                      { searchValue },
                      { searchValue },
                      false,
                      searchValue
                    );
                  }
                }}
                disabled={loading}
              >
                <i className="fa fa-plus mr-2"></i>
                Add this user
              </MDBBtn>
            </MDBBox>
          </>
        ) : (
          <>
            {/* <h6 className="mt-2">Delete user menu</h6> */}
            <div className="form-group mb-2">
              <input
                type="text"
                className="shared-input-search mt-2"
                placeholder="Search by email.."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <MDBListGroup className="fixes-height-members overflow-auto">
              {usersAbleToDelete}
            </MDBListGroup>
            {loading ? (
              <div className="d-flex flex-center py-2 spinner-box">
                <Loading color="black" text="Loading..." />
              </div>
            ) : null}
            <MDBBox className="d-flex justify-content-center">
              <MDBBtn
                size="sm"
                color=""
                className="btn-header active-toggle mt-4 capitalize f-15 bg-danger"
                onClick={() => {
                  deleteUserToProject();
                  {
                    eventTrack(
                      "Delete-User-From-Project-Action",
                      "Delete-User-From-Project",
                      { searchValue },
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
                disabled={loading}
              >
                <i className="fa fa-trash mr-2"></i>
                Remove this user
              </MDBBtn>
            </MDBBox>
          </>
        )}
      </MDBModalBody>
    </MDBModal>
  );
};

export default MemberModalAdmin;
