import { post,get, postpro, globalPut } from '../index';

export const createProjectExportSettings = data => {
    return post('/project-export-settings', data, false);
  };

  export const updateProjectExportSettings = data => {
    return globalPut('/project-export-settings', data, false);
  };

  export const getProjectExportSettings = (exportSettingsID) => {
    return get(`${'/project-export-settings/project'}/${exportSettingsID}`);
  };

  export const getRecentExportSettings = (companyId) => {
    return get(`${'/project-export-settings/company'}/${companyId}`);
  };

  export const updateBulkProjectExportSettings = data => {
    return globalPut('/project-export-settings/bulk-update', data, false);
  };
