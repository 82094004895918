import { get, globalPut, post } from '../index';

export const getModalHealthSettings = (companyID) => {
  return get(`/model-health-settings/recent/${companyID}`);
};

export const createModelHealthSettings = (data) => {
  return post(`/model-health-settings`, data, false, false);
};

export const updateModelHealthSettings = (data) => {
  return globalPut(`/model-health-settings`, data, false)
};

export const updateBulkModelHealthSettings = (data) => {
  return globalPut(`/model-health-settings/bulk-update`, data, false)
};
