import { get,getCompressed,getWithoutURL } from '../index';

export const getJsonFile = jsonPath => {
  return get(`/${jsonPath}`);
};

export const getJsonFileWithoutURL  = jsonPath => {
  console.log("getJsonFileWithoutURL",jsonPath);
  return getWithoutURL(`${jsonPath}`);
};

export const getCompressedJsonFile =  (jsonPath,isIfcProject) => {
  return getCompressed(`/${jsonPath}`,isIfcProject);
};
