import React from "react";

export const EllipsesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0007 2.5C9.08398 2.5 8.33398 3.25 8.33398 4.16667C8.33398 5.08333 9.08398 5.83333 10.0007 5.83333C10.9173 5.83333 11.6673 5.08333 11.6673 4.16667C11.6673 3.25 10.9173 2.5 10.0007 2.5ZM10.0007 14.1667C9.08398 14.1667 8.33398 14.9167 8.33398 15.8333C8.33398 16.75 9.08398 17.5 10.0007 17.5C10.9173 17.5 11.6673 16.75 11.6673 15.8333C11.6673 14.9167 10.9173 14.1667 10.0007 14.1667ZM10.0007 8.33333C9.08398 8.33333 8.33398 9.08333 8.33398 10C8.33398 10.9167 9.08398 11.6667 10.0007 11.6667C10.9173 11.6667 11.6673 10.9167 11.6673 10C11.6673 9.08333 10.9173 8.33333 10.0007 8.33333Z"
        fill="white"
      />
    </svg>
  );
};
