import React from "react";

export const SaveIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 2.5H14.1667L17.2559 5.58926C17.4122 5.74553 17.5 5.9575 17.5 6.17851V16.6667C17.5 17.1269 17.1269 17.5 16.6667 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5ZM10 15C11.3807 15 12.5 13.8807 12.5 12.5C12.5 11.1192 11.3807 10 10 10C8.61925 10 7.5 11.1192 7.5 12.5C7.5 13.8807 8.61925 15 10 15ZM4.16667 4.16667V7.5H12.5V4.16667H4.16667Z"
        fill="white"
      />
    </svg>
  );
};
