import React, { useEffect, useState } from "react";
import Arrow from "assets/images/icons/arrow.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import MainData from "./mainData";

export default function PassedRule({ reportData }) {
  const [passedRule, setPassesRule] = useState(reportData);
  const [nestedElement, setNestedElement] = useState([]);

  useEffect(() => {
    setPassesRule(reportData)
  },[reportData])

  const Extradetails = ({ rule }) => {
    const additionalDetails = rule.details ?? [];


    const viewNestedElement = (entries) => {
      setNestedElement(entries);
    };

    return (
      <>
        {additionalDetails?.length > 0 ? (
          <div className="bg-light-bid m-3 rounded-3">
            <table className="bid-table bid-table-small border-top-none">
              <thead>
                <tr>
                  {Object.entries(additionalDetails[0]).map(([key, value], index) => (
                    value && <th className="txt-cap" key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {additionalDetails?.map((item, key) => {
                  return (
                    <tr key={key}>
                      {Object.values(item).map((entries, index) => (
                        entries && <td className="txt-cap" key={index}>
                          {Array.isArray(entries) ? (
                            <button onClick={() => viewNestedElement(entries)}>
                              View
                            </button>
                          ) : (
                            entries
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="bg-light-bid m-3 rounded-3 p-3 f-10">No Data</div>
        )}
      </>
    );
  };

  return (
    <div className="mt-0">
      <table className="bid-table border-top-none">
        <thead className="border-bottom-none">
          <tr>
            <th width="30%">Setting Name</th>
            <th width="30%">Rule Name</th>
            <th width="30%">Importance</th>
            <th width="10%"></th>
          </tr>
        </thead>
      </table>

      <Accordion allowZeroExpanded>
        {passedRule?.reportDetails?.passedRules.map((item, key) => {
          return (
            <AccordionItem key={key}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <table className="bid-table">
                        <tbody>
                          <tr>
                            <td width="30%">{item?.settingName}</td>
                            <td width="30%">{item?.ruleName}</td>
                            <td width="30%">
                              <div
                                className={`${"number-count px-3 health-result-status capitalize"} ${
                                  item?.importance === "normal" &&
                                  "bg-light-warning text-warning"
                                }
                                  ${
                                    item?.importance === "critical" &&
                                    "bg-light-danger text-danger"
                                  }
                                  ${
                                    item?.importance === "great" &&
                                    "bg-light-success text-success"
                                  }
                                  
                                  
                                  `}
                              >
                                {item?.importance}
                              </div>
                            </td>
                            <td width="10%">
                              <img
                                src={Arrow}
                                className={
                                  expanded
                                    ? "arrow-icon"
                                    : "arrow-icon  transform-180"
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="pl-16px">Note: {item?.note}</p>
                <Extradetails rule={item} />
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
}
