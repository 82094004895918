import React from "react";
import ReactDOM from "react-dom";
import Stepper from "react-js-stepper";
import {
  faArrowLeft,
  faArrowRight,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import Step1 from "../../../assets/images/landing/step1.png";
//import Step2 from "../../../assets/images/landing/step2.png";
import Step3 from "../../../assets/images/landing/step3.png";
import Step4 from "../../../assets/images/landing/step4.png";
import Step5 from "../../../assets/images/landing/step5.png";
//import Step6 from "../../../assets/images/landing/step6.png";

import bgCircle from "assets/images/landing/Component371.svg";

const steps = [
  // { title: "Step 1" },
  // { title: "Step 2" },
  { title: "Step  1" },
  { title: "Step 2" },
  { title: "Step 3" },
  //  { title: "Step 6" },
];

export default class HowToUseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
    };
  }

  handleOnClickStepper = (step) => {
    this.setState({ activeStep: step });
  };

  handleOnClickNext = () => {
    if (this.state.activeStep === steps.length) return;
    let nextStep = this.state.activeStep + 1;
    this.setState({ activeStep: nextStep });
  };

  handleOnClickBack = () => {
    if (this.state.activeStep === 1) return;
    let prevStep = this.state.activeStep - 1;
    this.setState({ activeStep: prevStep });
  };

  render() {
    return (
      <div id="howto-section">
        <div className="bg-step position-relative">
          <img src={bgCircle} className="stepper-bg-circle" />

          <React.Fragment>
            <Stepper
              steps={steps}
              activeStep={this.state.activeStep}
              onSelect={this.handleOnClickStepper}
              showNumber={false}
            />
            <div style={{ marginTop: "100px" }} className="step-button">
              <div>
                <FontAwesomeIcon icon={faArrowLeft} />
                <input
                  type="button"
                  value={this.state.activeStep === 1 ? "Start" : "Back"}
                  onClick={this.handleOnClickBack}
                />
              </div>
              <div>
                <input
                  type="button"
                  value={
                    this.state.activeStep === steps.length ? "Finish" : "Next"
                  }
                  onClick={
                    this.state.activeStep === steps.length
                      ? null
                      : this.handleOnClickNext
                  }
                />
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
            <div>
              {
                // this.state.activeStep === 1 ? (
                //   <div>
                //     <h2 className="section-title text-center text-white">
                //       Step 1
                //     </h2>
                //     <p className="lead text-center ">
                //       Request a license through our contact email or apply for a 1
                //       month free license and check your inbox for the registration
                //       link.
                //     </p>
                //     <img
                //       src={Step1}
                //       className="m-auto d-block img-custom-step custom-image"
                //     />
                //   </div>
                // ) : this.state.activeStep === 2 ? (
                //   <div>
                //     {" "}
                //     <h2 className="section-title text-center text-white">
                //       Step 2
                //     </h2>
                //     <p className="lead text-center ">
                //       Download and install Bidlight software and plugin
                //     </p>{" "}
                //     <img
                //       src={Step2}
                //       className="m-auto d-block img-custom-step custom-image"
                //     />
                //   </div>
                // ) :

                this.state.activeStep === 1 ? (
                  <div>
                    <h2 className="section-title text-center text-white">
                      Step 1
                    </h2>
                    <p className="lead text-center ">
                      Open your 3D authoring tool (Revit) and export your
                      project to the bidlight cloud
                    </p>{" "}
                    <img
                      src={Step3}
                      className="m-auto d-block img-custom-step custom-image"
                    />
                  </div>
                ) : this.state.activeStep === 2 ? (
                  <div>
                    {" "}
                    <h2 className="section-title text-center text-white">
                      Step 2
                    </h2>
                    <p className="lead text-center ">
                      Launch Bidlight the desktop app and adjust your estimation
                      in real time, once satisfied export the bid package to the
                      cloud
                    </p>{" "}
                    <img
                      src={Step4}
                      className="m-auto d-block img-custom-step custom-image"
                    />
                  </div>
                ) : this.state.activeStep === 3 ? (
                  <div>
                    {" "}
                    <h2 className="section-title text-center text-white">
                      Step 3
                    </h2>
                    <p className="lead text-center ">
                      Head over to bidlight.com and investigate your estimation
                      and 3D model, download and share your estimate through
                      email.
                    </p>{" "}
                    <img
                      src={Step5}
                      className="m-auto d-block img-custom-step custom-image"
                    />
                  </div>
                ) : (
                  // : this.state.activeStep === 6 ? (
                  //   <div>
                  //     <h2 className="section-title text-center text-white">
                  //       Step 6
                  //     </h2>
                  //     <p className="lead text-center ">
                  //       Share your estimation package with co-workers and clients
                  //     </p>{" "}
                  //     <img
                  //       src={Step6}
                  //       className="m-auto d-block img-custom-step custom-image"
                  //     />
                  //   </div>
                  // )
                  <></>
                )
              }
            </div>
          </React.Fragment>
        </div>

        <div className="box-vacate"></div>
      </div>
    );
  }
}
