import jwt_decode from 'jwt-decode';
import { all_permissionsRef, company_permissionsRef, setJWT, tokenRef, url, user_permissionsRef } from '../api';
import { setUser } from '../actions/user';
import { initPermission } from './initPermission';

export const initUser = Store => {
  
  if (localStorage.getItem(tokenRef)) {
    setJWT(localStorage.getItem(tokenRef));
    const decoded = jwt_decode(localStorage.getItem(tokenRef));
    if (new Date().getTime() <= decoded.token_death) {
      // console.log(decoded)
      Store.dispatch(setUser(decoded));
      initPermission({Store,decoded})
    } else {
      localStorage.removeItem(tokenRef);
      localStorage.removeItem(user_permissionsRef);
      localStorage.removeItem(company_permissionsRef);
      localStorage.removeItem(all_permissionsRef);
      localStorage.removeItem('exporters');
    }
  }
};
