import {
  SET_PROJECT_TYPE,
  SET_LABOR_TYPE,
  SET_VERSIONS,
  CLEAR_VERSIONS,
  ADD_NEW_VERSION,
  ADD_NEW_PROJECT_TYPE,
  ADD_NEW_LABOR_TYPE,
  SET_CATEGORIES,
  SET_COUNTRIES,
  SET_AWPS,
  CLEAR_CATEGORIES,
  SET_ALL_CATEGORIES,
  SET_EXPANDED_CATALOGS_DIVISIONS,
  ADD_EXPANDED_CATALOGS_DIVISIONS,
} from "../constants/action-types/pricing";

const initialState = {
  projectTypes: [],
  versions: [],
  laborTypes: [],
  expandedCatalogsDivisions: [],
  categories: [],
  allCategories: [],
  countries: [],
};

export default function pricingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_TYPE:
      return {
        ...state,
        projectTypes: action.payload,
      };
    case SET_LABOR_TYPE:
      return {
        ...state,
        laborTypes: action.payload,
      };
    case SET_VERSIONS:
      return {
        ...state,
        versions: [...state?.versions, ...action.payload],
      };
    case CLEAR_VERSIONS:
      return {
        ...state,
        versions: []
      }

    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case CLEAR_CATEGORIES: {
      return {
        ...state,
        categories: [],
      };
    }

    case SET_ALL_CATEGORIES: {
      return {
        ...state,
        allCategories: action.payload,
      };
    }

    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };

    case SET_AWPS:
      return {
        ...state,
        awps: action.payload,
      };

    case ADD_NEW_VERSION:
      return {
        ...state,
        versions: [action.payload, ...state.versions],
      };
    case ADD_NEW_PROJECT_TYPE:
      return {
        ...state,
        projectTypes: [action.payload, ...state.projectTypes],
      };
    case ADD_NEW_LABOR_TYPE:
      return {
        ...state,
        laborTypes: [action.payload, ...state.laborTypes],
      };

    case SET_EXPANDED_CATALOGS_DIVISIONS:
      return {
        ...state,
        expandedCatalogsDivisions: action.payload,
      };

    case ADD_EXPANDED_CATALOGS_DIVISIONS:
      return {
        ...state,
        expandedCatalogsDivisions: [
        action.payload,
          ...state.expandedCatalogsDivisions,
        ],
      };
    default:
      return state;
  }
}
