import React, { useState } from "react";
import addNotification from "../../helpers/notify";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import Loading from "../ui-components/loading";
import capitalize from "../../helpers/capitalize";
import { choseToggle } from "../../helpers/toggleIfLoading";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";

const withDeleteModal = (config) => {
  return (props) => {
    const [loading, setLoading] = useState(false);

    const deleteItem = () => {
      setLoading(true);
      return config
        .request(props.itemToDelete)
        .then((res) => {
          setLoading(false);
          props.toggle();
          props.reduxRemover(res._id);
          addNotification(
            `${capitalize(config.itemLabel)}, was deleted successfully`,
            "success"
          );
        })
        .catch(() => {
          setLoading(false);
          props.toggle();
          addNotification(
            `Something went wrong with deleting ${config.itemLabel}, please contact support`,
            "danger"
          );
        });
    };

    return (
      <MDBModal
        isOpen={props.isOpen}
        toggle={choseToggle(loading, props.toggle)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={choseToggle(loading, props.toggle)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">
            Delete this {config.itemLabel}?
          </p>
          <p className="delete-modal-label">
            Are you sure you want to delete? This action cannot be undone.
          </p>
        </MDBModalBody>
        {loading ? <Loading color="black" text="Deleting..." /> : null}
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={props.toggle}
              color=""
              disabled={loading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={deleteItem}
              disabled={loading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>
    );
  };
};

export default withDeleteModal;
