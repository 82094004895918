import React, { useState } from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBInput,
  MDBRow,
  MDBBox,
  MDBCol,
} from 'mdbreact';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Loading from '../ui-components/loading';

import { updateIfcProject } from 'api/projects/updateIfcProject';
import addNotification from 'helpers/notify';
import { choseToggle } from 'helpers/toggleIfLoading';
import { useHttp } from 'hooks/useHttp';
import { editIfcProject } from '../../actions/user';

import './UpdateProjectModal.scss';

const UpdateModal = props => {
  // const [title, setTitle] = useState('');
  // const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [masterFile, setMasterFile] = useState('');

  const dispatch = useDispatch();

  const toggle = () => {
    props.toggle();
    // setTitle('');
    // setDescription('');
    setFile(null);
    setMasterFile(null);
	};

  const { loading, request: updateIfcProjectAPI } = useHttp({
    requestCallback: data => updateIfcProject(data),
    onLoad: res => {
      console.log({res});
      // dispatch(editIfcProject(res));
      toggle();
      let notification = "Project was successfully updated!"
      let notificationType = "success";
      // console.log(res.isIfcProject);
      // if (res.isIfcProject) {
      //   notification += "  \nYou will be receive an email once the IFC is ready to use."
      //   notificationType = "info";
      // }
      addNotification(notification, notificationType);
    },
    onError: res => {
      toggle();
      addNotification(
        'Something went wrong while creating your project, please contact support.',
        'danger',
      );
    },
  });

  const createObject = e => {
    e.preventDefault();
    const createObjectForm = new FormData();
    // createObjectForm.append('title', title);
    // createObjectForm.append('description', description);
    createObjectForm.append("bidlightProjectId", props.itemToUpdate._id);
    createObjectForm.append("ifc", file);
    createObjectForm.append("masterFile", masterFile);
    updateIfcProjectAPI(createObjectForm);
  };

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={choseToggle(loading, toggle)}
      centered
    >
      <MDBModalHeader toggle={choseToggle(loading, toggle)}>
        Update project
      </MDBModalHeader>
      <MDBModalBody className="pt-0 updateModalBody">
        <form onSubmit={createObject}>
          <div className="grey-text">
            {/* <MDBInput
              label="Title"
              type="text"
              required
              size="sm"
              value={title}
              className="mdb-input"
              onChange={e => setTitle(e.target.value)}
            />
            <MDBInput
              label="Description"
              type="textarea"
              required
              size="sm"
              value={description}
              className="mdb-input desc-height"
              onChange={e => setDescription(e.target.value)}
            /> */}
            {file && file.name.endsWith('.zip') &&
              <MDBInput
                title="Full Master FileName (eg: MasterFile.ifc)"
                label="Full Master FileName (eg: MasterFile.ifc)"
                type="text"
                required
                size="sm"
                value={masterFile}
                className="mdb-input"
                onChange={e => setMasterFile(e.target.value)}
              />
            }
          </div>
          <div className='modalButtons'>
            <MDBBox className='w-100'>
            <MDBInput
              title={file ? file.name : "Choose IFC file or zipped IFC files"}
              label={file ? "Choose ifc file/s(zipped)" : ""}
              type="file"
              style={{"border-radius": "5px"}}
              onChange={e => setFile(e.target.files[0])}
              className={`border-bid radius-btn w-95 text-center py-2 px-2 file-input-btn ${
                !!file ? 'bg-bid text-white' : 'bg-white'
              }`}
              id="input_file"
              required
              accept={'.ifc,.zip'}
              // disabled={!!file}
            />
            {/* <label
              htmlFor="input_file"
			        title='Choose ifc file/s(zipped)'
              className={`border-bid radius-btn w-95 text-center py-2 file-input-btn ${
                !!file ? 'bg-bid text-white' : 'bg-white'
              }`}
            >
              {file ? file.name : "Choose ifc file/s(zipped)"}
            </label> */}
          </MDBBox>
          <div className="form-group mb-0 w-100">
            <input
              type="submit"
              className={`border-bid text-white bg-bid radius-btn w-95 py-2 float-right `}
              value="Update"
              disabled={loading}
            />
          </div>
          </div>
          
        </form>
        {loading ? <Loading color="dark" text="Creating..." /> : null}
      </MDBModalBody>
    </MDBModal>
  );
};

UpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default UpdateModal;
