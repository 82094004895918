import {
  SET_ROLES_AND_THEIR_PERMISSIONS,
  UPDATE_ROLES_AND_THEIR_PERMISSIONS,
} from "constants/action-types/role-permission-settings";

const initialState = {
  rolesPermissionSettings: [],
};

export default function rolePermissionSettingsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_ROLES_AND_THEIR_PERMISSIONS:
      return {
        ...state,
        rolesPermissionSettings: [
          action.payload,
          ...state.rolesPermissionSettings,
        ],
      };

    case UPDATE_ROLES_AND_THEIR_PERMISSIONS: {
      let updatedPermissionIndex = state.rolesPermissionSettings.findIndex(
        (val) => val.userId === action.payload.userId
      );
      let updatedRolesAndPermissions = [...state.rolesPermissionSettings];
      updatedRolesAndPermissions[updatedPermissionIndex] = action.payload;
      return {
        ...state,
        rolesPermissionSettings: updatedRolesAndPermissions,
      };
    }

    default:
      return state;
  }
}
