import React from 'react'

import styles from './navigation.module.css'

const ArticleNav = ({ children }) => {  
  return (
    <div className={styles.stickyResponsive}>
      <nav className={styles.nav}>
        {children}
      </nav>
    </div>
  )
}

export default ArticleNav
