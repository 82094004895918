import React from 'react';
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
} from 'mdbreact';

import { url } from 'api';
import formatTime from '../../helpers/formatTime';

import './ProjectItem.scss';
import {eventTrack} from 'helpers/ga4Helper'
import { useHistory } from 'react-router-dom';



let numeral = require('numeral');
const ProjectItemAdmin = props => {
  const { img, title, description, date, timelines } = props.projectItem;
  const history = useHistory();
  return (
   
    <MDBCol className={props.className}>
      <MDBCard className="h-100">
        <div className="project-item-img">
          <img src={`${url}/${img}`} alt={`Project ${title}`} />
          <span className="project-item-date">{formatTime(date)}</span>
        </div>
        <MDBCardBody>
          <MDBCardTitle className="font-size-2">{title}</MDBCardTitle>
          {timelines && timelines[0] && timelines[0].estimation ? (
            <MDBBox className="card-price font-size-2 mb-1">
              {numeral(timelines[timelines.length - 1].estimation).format(
                '0,0.00',
              )}
            </MDBBox>
          ) : null}
          <MDBCardText>{description}</MDBCardText>
        </MDBCardBody>
        <MDBCardFooter className='px-0'>
        <div className="d-flex align-items-center justify-content-between">
              <MDBBtn
                color=''
                className="bg-bid card-button text-white border-bid shadow-none text-style-inherit "
                onClick={props.openMemberShipList}
              >
                Members
              </MDBBtn>
            <MDBBtn
            color='' 
            className="bg-white card-button text-bid border-bid shadow-none text-style-inherit "
              onClick={props.openTimelines}
            >
              {eventTrack("ViewHistory-Action","Bid-History",history.location.pathname,title,false,"xhr")}
              Estimation history
            </MDBBtn>
          </div>

          {/* <MDBBtnGroup size="sm">
            <MDBBtn
              size="sm"
              className="blue darken-2 px-3"
              onClick={props.openMemberShipList}
            >
              Members 
            </MDBBtn>
            <MDBBtn
              size="sm"
              className="blue darken-2 px-3"
              onClick={props.openTimelines}
            >
              Bid history
            </MDBBtn>
          </MDBBtnGroup> */}
        </MDBCardFooter>
      </MDBCard>
    </MDBCol>
  );
};

export default ProjectItemAdmin;
