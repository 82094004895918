import React, { useEffect, useRef, useState } from "react";
import { setSidebarStatus } from "actions/user";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { useHttp } from "hooks/useHttp";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Arrow from "assets/images/leftArr.png";
import SortImg from "assets/images/sort.png";
import Loader from "components/Loader";
import {
  sortByDate,
  FROM_NEWER_TO_OLDER,
  FROM_OLDER_TO_NEWER,
} from "helpers/sortByDate";
import { CSVLink } from "react-csv";
import { getDocControl } from "api/docControl/getDocControl";
import Pagination from "components/Pagination/Pagination";
import Select from "react-select";
import moment from "moment";
import addNotification from "helpers/notify";
import { url } from "api";
import Preview from "./Preview";
import { getDiscipline } from "api/discipline/getDiscipline";
import JSZip from "jszip";
import pako from "pako"; // For Gzip decompression
import { eventTrack } from "helpers/ga4Helper";

export default function DocControl() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const [coversheet, setCoversheet] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(0);
  const [columnNameOptions, setColumnNameOptions] = useState();
  const [columnNameSelectValue, setColumnNameSelectValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [csvFileData, setCSVFileData] = useState([]);

  const [sortedToNewer, setSortedToNewer] = useState(false);

  const [openPreview, setOpenPreview] = useState(false);
  const [enableDownloadPackage, setEnableDownloadPackage] = useState(false);
  const [disciplines, setDisciplines] = useState([]);

  const data = useRef({ index: 0, limit: 10 });

  const coversheetHeaders = [
    { header: "Discipline", value: "discipline" },
    { header: "Project Name", value: "projectId" },
    { header: "Revision Date", value: "revisionDate" },
    { header: "Revision Number", value: "revisionNumber" },
    { header: "Revit File Name", value: "revitFileName" },
    { header: "Sequence Number", value: "sequenceNumber" },
    { header: "Sheet Name", value: "sheetName" },
    { header: "Sheet Number", value: "sheetNumber" },
    { header: "Sheet Status", value: "sheetStatus" },
    { header: "Upload Date", value: "updatedAt" },
  ];

  const { loading: apiLoading, request: getDocAPI } = useHttp({
    requestCallback: (data) => getDocControl(data?.current),
    onLoad: (res) => {
      console.log(res);
      setCoversheet(res?.documents);
      setTotalRecords(res?.totalCount);
      setTotalPage(Math.ceil(res?.totalCount / data?.current?.limit));
    },
    onError: (res) => {
      console.log("err", res);
      addNotification("Something went wrong", "danger");
    },
  });

  const { loading: downloadLoading, request: DownloadCoversheet } = useHttp({
    requestCallback: (data) => getDocControl(data),
    onLoad: (res) => {
      setCSVFileData(res?.documents);
    },
    onError: (res) => {
      console.log("err", res);
    },
  });

  const { loading: disciplineLoading, request: getDisciplineAPI } = useHttp({
    requestCallback: (data) => getDiscipline(userInfoRedux.company),
    onLoad: (res) => {
      setDisciplines(res);
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  useEffect(() => {
    data.current = {
      ...data.current,
      index: page,
      column: columnNameSelectValue?.value,
      search: searchValue,
      sort: "updatedAt",
      sort_dir: -1,
    };

    console.log(data);
    getDocAPI(data);
  }, [page]);

  useEffect(() => {
    setColumnNameOptions(
      coversheetHeaders
        .filter(
          (value) =>
            value?.value !== "revisionDate" && value?.value !== "updatedAt"
        )
        .map((value) => ({ value: value.value, label: value.header }))
    );

    DownloadCoversheet({
      sort: "updatedAt",
      sort_dir: -1,
    });
    getDisciplineAPI();
  }, []);

  const onDropdownChange = (e) => {
    if (e.value == columnNameSelectValue.value)
      setColumnNameSelectValue({ label: "", value: "" });
    else setColumnNameSelectValue(e);
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onClearFunc = () => {
    setSearchValue("");
    data.current = { ...data.current, index: 0, search: null, column: null };
    getDocAPI(data);
    setEnableDownloadPackage(false);
  };

  const SearchFunction = () => {
    setCoversheet([]);
    data.current = {
      ...data.current,
      index: 0,
      column: columnNameSelectValue.value,
      search: searchValue,
    };
    getDocAPI(data);
    setTotalPage(0);
    setPage(0);
    setEnableDownloadPackage(true);
  };

  const sort = () => {
    if (sortedToNewer) {
      setCoversheet(sortByDate(coversheet, FROM_OLDER_TO_NEWER));
    } else {
      setCoversheet(sortByDate(coversheet, FROM_NEWER_TO_OLDER));
    }
  };

  const extractPdfFromZip = async (uri) => {
    try {
      // Check if the file is a GZ file by its extension
      if (uri?.split(".")?.pop() !== "gz") {
        throw new Error("File is not a GZ archive.");
      }
      const response = await fetch(`${url}/${uri}`);
      // Verify the Content-Type header
      const contentType = response.headers.get("Content-Type");
      if (
        contentType !== "application/gzip" &&
        contentType !== "application/x-gzip"
      ) {
        throw new Error("The file is not a GZ file.");
      }
      const data = await response.arrayBuffer();
      // Decompress the GZ file using pako
      const decompressedData = pako.inflate(new Uint8Array(data));
      // Create a Blob from the decompressed data
      const blob = new Blob([decompressedData], { type: "application/pdf" });
      // Create a URL for the Blob and trigger the download
      const urls = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urls;
      link.download = uri.replace(".gz", ".pdf"); // Rename the download to match the expected file type
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return urls; // Return the URL of the extracted PDF
    } catch (error) {
      console.error("Error extracting PDF from GZ file:", error);
      throw error;
    }
  };

  return (
    <>
      {apiLoading && <Loader />}
      <div className="personal-area">
        <div className="inner-header-position">
          <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center">
              <div
                onClick={() => dispatch(setSidebarStatus(true))}
                className="cursor-pointer"
              >
                <img src={Arrow} className="left-arrow-img" />
              </div>
              <p className="my-heading f-14 ml-3 mr-3">Doc Control</p>
            </div>
            <div
              className="projActionButtons d-flex flex-wrap"
              style={{ maxWidth: "100vw" }}
            >
              <Select
                onChange={(e) => onDropdownChange(e)}
                options={columnNameOptions}
                placeholder="Select Category"
                value={columnNameSelectValue}
                selected={columnNameSelectValue}
                className="header-select"
                classNamePrefix="header-select"
              />
              <div className="input-with-cancelBtn">
                <input
                  type="text"
                  className="search-bar"
                  value={searchValue}
                  onChange={(e) => onSearchChange(e)}
                  placeholder="Search"
                  disabled={!columnNameSelectValue}
                />
                {searchValue?.length > 0 && (
                  <button onClick={() => onClearFunc()}>
                    <i className="fa fa-times" />
                  </button>
                )}
              </div>

              <button
                disabled={!searchValue?.length > 0}
                onClick={() => SearchFunction()}
                className="global-btn bg-bid text-white mr-0"
              >
                <i className="fa fa-search mr-2" />
                Search
              </button>
              {enableDownloadPackage && (
                <button
                  className="global-btn bg-bid text-white ml-2 mr-0"
                  onClick={() => setOpenPreview(true)}
                >
                  <i className="fa fa-download mr-2" />
                  Download package
                </button>
              )}
              <div className="vr mx-3"></div>
              <div className="btn-tool-group">
                {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                  <>
                    <button
                      onClick={() => {
                        if (
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.PROJECT_VIEW
                          )
                        ) {
                          history.push(`/admin/projects-settings`);
                        } else if (
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_VIEW
                          )
                        ) {
                          history.push(`/admin/milestones`);
                        } else {
                          history.push(`/admin/user-permissions`);
                        }
                        eventTrack(
                          "Navigate-Admin-Section-Action",
                          "Navigate-Admin-Section",
                          userInfoRedux?.name,
                          "+1",
                          false,
                          "+1"
                        );
                      }}
                      className="bg-bid px-3 mr-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="19"
                        viewBox="0 0 14 19"
                        fill="none"
                      >
                        <path
                          d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                          fill="white"
                        />
                      </svg>
                      Admin
                    </button>
                    <div className="vr mx-3"></div>
                  </>
                )}

                <button
                  onClick={() => {
                    setSortedToNewer(!sortedToNewer);
                    sort();
                    eventTrack(
                      "Sort-Doc-Control-Action",
                      "Sort-Doc-Control",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }}
                  className="px-3 bg-white text-bid shadow-hover d-flex align-items-center leading-1"
                >
                  <img
                    src={SortImg}
                    className={`${"w-15 mr-2"} ${
                      sortedToNewer ? "upsideDown-svg" : ""
                    } `}
                  />
                  Sort
                </button>

                <button
                  disabled={apiLoading}
                  onClick={() => {
                    onClearFunc();
                  }}
                  className="px-3 bg-white text-bid shadow-hover"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                  >
                    <path
                      d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                      fill="#3A5765"
                    />
                    <path
                      d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                      fill="black"
                      fillOpacity="0.2"
                    />
                  </svg>
                  Refresh
                </button>
              </div>
              <div className="vr mx-2"></div>

              <div className="head-filter-icon" title="Download CSV">
                <CSVLink data={csvFileData} filename={"Doc"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#3a5765"
                    className="bi bi-filetype-csv"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z"
                    />
                  </svg>
                </CSVLink>
              </div>
            </div>
          </div>
        </div>

        <div className="assestmgmt-table-div mt-3">
          <table className="bid-table bid-table-small border-top-none sticky-table-header">
            <thead>
              <tr>
                <th>S.No</th>
                {coversheetHeaders.map((value, key) => {
                  return <th key={key}>{value.header}</th>;
                })}
                <th></th>
              </tr>
            </thead>
            {!apiLoading && coversheet?.length !== 0 ? (
              <tbody className="no-wrap">
                {coversheet
                  ?.slice(0, data.current?.limit)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.discipline?.key}</td>
                        <td>{item?.projectId?.title}</td>
                        <td>
                          {moment(item?.revisionDate).format("DD/MM/YYYY")}
                        </td>
                        <td>{item?.revisionNumber}</td>
                        <td>{item?.revitFileName}</td>
                        <td>{item?.sequenceNumber}</td>
                        <td>{item?.sheetName}</td>
                        <td>{item?.sheetNumber}</td>
                        <td>{item?.sheetStatus}</td>
                        <td>{moment(item?.updatedAt).format("DD/MM/YYYY")}</td>
                        <td className="pdf-svg cursor-pointer">
                          <button
                            className="border-none"
                            onClick={() => {
                              extractPdfFromZip(item?.link);
                              eventTrack(
                                "Download-Doc-Control-PDF-Action",
                                "Download-Doc-Control-PDF",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-file-earmark-pdf-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.523 12.424q.21-.124.459-.238a8 8 0 0 1-.45.606c-.28.337-.498.516-.635.572l-.035.012a.3.3 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647q-.178.037-.356.078a21 21 0 0 0 .5-1.05 12 12 0 0 0 .51.858q-.326.048-.654.114m2.525.939a4 4 0 0 1-.435-.41q.344.007.612.054c.317.057.466.147.518.209a.1.1 0 0 1 .026.064.44.44 0 0 1-.06.2.3.3 0 0 1-.094.124.1.1 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a5 5 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.5.5 0 0 1 .145-.04c.013.03.028.092.032.198q.008.183-.038.465z" />
                              <path
                                fillRule="evenodd"
                                d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.7 11.7 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.86.86 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.84.84 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.8 5.8 0 0 0-1.335-.05 11 11 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.24 1.24 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a20 20 0 0 1-1.062 2.227 7.7 7.7 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>No record Found</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>

        <div className="asset-pagination-div mx-auto">
          <Pagination
            totalRecords={totalRecords}
            totalPage={totalPage}
            page={page + 1}
            onPageChange={(pageNo) => setPage(pageNo - 1)}
            onLimitChange={(count) => {
              data.current = { ...data.current, limit: count };
              if (page === 0) {
                getDocAPI(data);
              } else {
                setPage(0);
              }
            }}
            limit={data.current?.limit}
          />
        </div>
      </div>

      {coversheet && (
        <Preview
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
          data={coversheet}
          disciplines={disciplines}
        />
      )}
    </>
  );
}
