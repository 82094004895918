import {
  ADMIN_ADD_USER_TO_PROJECT,
  ADMIN_EDIT_TIMELINE,
  ADMIN_REMOVE_TIMELINE,
  ADMIN_REMOVE_USER_FROM_COMPANY,
  ADMIN_REMOVE_USER_FROM_PROJECT,
  ADMIN_SET_USERS,
  EDIT_PROJECT,

} from '../constants/action-types/admin';

export const adminSetUsers = users => {
  return {
    type: ADMIN_SET_USERS,
    payload: users,
  };
};

export const adminEditTimeline = (newTimeline, oldTimeline) => {
  return {
    type: ADMIN_EDIT_TIMELINE,
    payload: { newTimeline, oldTimeline },
  };
};

export const adminDeleteTimeLine = timeline => {
  return {
    type: ADMIN_REMOVE_TIMELINE,
    payload: timeline,
  };
};

export const adminDeleteUser = user => {
  return {
    type: ADMIN_REMOVE_USER_FROM_COMPANY,
    payload: user,
  };
};

export const adminRemoveUserFromProject = (user, project) => {
  return {
    type: ADMIN_REMOVE_USER_FROM_PROJECT,
    payload: { user, project },
  };
};

export const adminAddUserToProject = (user, project) => {
  return {
    type: ADMIN_ADD_USER_TO_PROJECT,
    payload: { user, project },
  };
};

export const adminEditProject = newProject => {
  return {
    type: EDIT_PROJECT,
    payload:  newProject ,
  };
};