const getProp = (obj, prop) => {
  const propArray = prop.split(".");
  if (propArray.length === 1) {
    return obj[prop];
  } else {
    return propArray.reduce((total, value) => {
      return total[value];
    }, obj);
  }
};

export const useSearch = () => {
  return (data, propFilter, value) => {
    if (!value || !data) {
      return data;
    }
    if (typeof propFilter === "object") {
      const UpdatedData= data?.map(val=>{
        let isStandard='';
        let isActive='';
        let noActivePlan='';        
        if(val.trial){
          if(val?.plan[0]?.product==='Standard'){
            isStandard='Standard'
          }
          else {
            isActive='active plan'
          }
        }
        else {
          noActivePlan='no active plan'
        }
      return{
        ...val,
        isStandard,
        isActive,
        noActivePlan
      }
      })
     
      return UpdatedData.filter((dataItem) => {
        return (
        dataItem.companyName
          .toLowerCase()
          ?.includes(value.toLowerCase()) ||
        dataItem.companyAddress
          .toLowerCase()
          ?.includes(value.toLowerCase()) ||
        dataItem.isStandard.toLowerCase()?.includes(value.toLowerCase()) || 
        dataItem.isActive.toLowerCase()?.includes(value.toLowerCase()) || 
        dataItem.noActivePlan.toLowerCase()?.includes(value.toLowerCase()) 
      );
      });
    } else {
      return data?.filter((dataItem) => {
        const property = getProp(dataItem, propFilter);
        return property.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
    }
  };
};
