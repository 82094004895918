import React, { useState } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBInput, MDBRow, MDBBox, MDBCol } from "mdbreact";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Loading from "../ui-components/loading";

import { createProject, createIFCProject } from "api/projects/createProject";
import addNotification from "helpers/notify";
import { choseToggle } from "helpers/toggleIfLoading";
import { useHttp } from "hooks/useHttp";
import { addNewProject } from "../../actions/user";

import "./CreateModal.scss";

const CreateModal = (props) => {
	const [title, setTitle] = useState("");
	const [isIfc, setIsIFC] = useState(false);
	const [description, setDescription] = useState("");
	const [file, setFile] = useState(null);
	const [masterFile, setMasterFile] = useState("");

	const dispatch = useDispatch();

	const toggle = () => {
		props.toggle();
		setTitle("");
		setIsIFC(false);
		setDescription("");
		setFile(null);
		setMasterFile(null);
	};

	const { loading, request: createProjectAPI } = useHttp({
		requestCallback: (data) => (isIfc ? createIFCProject(data) : createProject(data)),
		onLoad: (res) => {
			console.log("create project----",res)
			dispatch(addNewProject(res));
			toggle();
			addNotification("Project was successfully created!", "success");
		},
		onError: (res) => {
			toggle();
			addNotification("Something went wrong while creating your project, please contact support.", "danger");
		},
	});

	const createObject =async (e) => {
		e.preventDefault();

		// if(!file) alert("Please upload a IFC/Zip file")

		const createObjectForm = new FormData();
		createObjectForm.append("title", title);
		createObjectForm.append("description", description);
		if (isIfc) {
			createObjectForm.append("ifc", file);
			createObjectForm.append("masterFile", masterFile);
		}
		const res= await createProjectAPI(createObjectForm);
		
	};

	return (
		<MDBModal isOpen={props.isOpen} toggle={choseToggle(loading, toggle)} centered>
			<MDBModalHeader toggle={choseToggle(loading, toggle)}>Create new project</MDBModalHeader>
			<MDBModalBody className="pt-0">
				<form onSubmit={createObject}>
					<div className="grey-text">
						<MDBBox className="w-100 d-flex align-items-center justify-content-between">
							<MDBInput
								label="Title"
								type="text"
								required
								size="sm"
								value={title}
								style={{ width: "300px" }}
								className="mdb-input"
								onChange={(e) => setTitle(e.target.value)}
							/>
							<MDBInput
								label="isIFC"
								className="d-flex"
								type="checkbox"
								value={isIfc}
								style={{ left: "-10px", top: "-15px" }}
								onChange={(e) => {
									console.log("event", e.target);
									setIsIFC(!isIfc);
								}}
							/>
						</MDBBox>
						<MDBInput
							label="Description"
							type="textarea"
							required
							size="sm"
							value={description}
							className="mdb-input desc-height"
							onChange={(e) => setDescription(e.target.value)}
						/>
						{file && file.name.endsWith(".zip") && (
							<MDBInput
								title="Full Master FileName (eg: MasterFile.ifc)"
								label="Full Master FileName (eg: MasterFile.ifc)"
								type="text"
								required
								size="sm"
								value={masterFile}
								className="mdb-input"
								onChange={(e) => setMasterFile(e.target.value)}
							/>
						)}
					</div>
					<div className="modalButtons">
						{isIfc && (
							<MDBBox className="w-100 m-0">
								<MDBInput
									title={file ? file.name : "Choose IFC file or zipped IFC files"}
									label={file ? "Choose ifc file/s(zipped)" : ""}
									type="file"
									style={{ borderRadius: "5px" }}
									onChange={(e) => setFile(e.target.files[0])}
									className={`border-bid radius-btn w-95 text-center py-2 px-2 file-input-btn ${!!file ? "bg-bid text-white" : "bg-white"}`}
									id="input_file"
									required
									accept={".ifc,.zip"}
									// disabled={!!file}
								/>
								{/* <label
              htmlFor="input_file"
			        title='Choose ifc file/s(zipped)'
              className={`border-bid radius-btn w-95 text-center py-2 file-input-btn ${
                !!file ? 'bg-bid text-white' : 'bg-white'
              }`}
              >
              {file ? file.name : "Choose ifc file/s(zipped)"}
            </label> */}
							</MDBBox>
						)}
						<div className="form-group mb-0 w-100">
							<input type="submit" className={`border-bid text-white bg-bid radius-btn w-95 py-2 `} value="Create" disabled={loading} />
						</div>
					</div>
				</form>
				{loading ? <Loading color="dark" text="Creating..." /> : null}
			</MDBModalBody>
		</MDBModal>
	);
};

CreateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default CreateModal;
