import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Logo from "assets/images/logo/side-logo.png";
import menuClose from "assets/images/logo/Vector.png";
import FavImg from "assets/images/icons/star.png";
import SupportImg from "assets/images/icons/support-svgrepo-com 1.png";
import LearningImg from "assets/images/icons/book-open-line.png";
import FAQImg from "assets/images/icons/question-line.png";
import DownloadImg from "assets/images/icons/move_to_inbox.png";
import ApiImg from "assets/images/icons/apiDoc.png";
import { Link } from "react-router-dom";
import { eventTrack } from "helpers/ga4Helper";
import Arrow from "assets/images/icons/arrow.png";
import { Modal } from "antd";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import addNotification from "helpers/notify";

import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu } from "mdbreact";
import { RequestCard } from "./requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { getUserCompanyPermission } from "api/permission/getPermissions";
import { Maybe } from "helpers/maybeFunctor";
import {
  setSidebarStatus,
  setCompanyPermission,
  setAllPermission,
  setFeatureRequest,
  addPermissionIsUser,
  setCompanySettings,
  setPersonalAreaActiveTab,
  setMilestones,
  setProjects,
  setCompanyUsers,
} from "actions/user";

import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { getCompanySettings } from "api/companySettings/getCompanySettings";
import { useHttp } from "hooks/useHttp";
import { getHealthCheck } from "api/healthCheck/getHealthCheck";
import { getProjects } from "api/projects/getProjects";
import DefaultImage from "assets/images/user.png";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import { getMileStones } from "api/milestones/getMilestones";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import { mainAdminGetCompanies } from "api/users/mainAdminGetCompanies";
import {
  all_permissionsRef,
  company_permissionsRef,
  tokenRef,
  url,
  user_permissionsRef,
} from "api";
import { mainAdminSetCompanies } from "actions/main-admin";

const NewDrawer = ({ anonymous, setModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user?.userInfo);
  const sidebarStatusRedux = useSelector((s) => s.user?.sidebar);

  const usersPermissionRedux = useSelector((s) => s.user?.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user?.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user?.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const featureRequestRedux = useSelector((s) => s.user?.featureRequest);
  const refreshProjectRedux = useSelector((s) => s.user?.refreshProjects);

  const projectsRedux = useSelector((s) => s.user?.projects);
  const milestonesRedux = useSelector((s) => s.user?.milestones);

  const userToken = localStorage.getItem(tokenRef);
  const [requestModal, setRequestModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [loading, setLoading] = useState(false);
  const [recentProjects, setRecentProjects] = useState([]);
  const [recentModels, setRecentModels] = useState([]);
  const [profilePic, setProfilePic] = useState(null);

  const onClose = () => {
    dispatch(setSidebarStatus(false));
  };

  useEffect(() => {
    (async () => {
      const extractedImg = await extractImageFromZip(
        userInfoRedux?.profilePhoto
      );
      setProfilePic(extractedImg);
    })();
  }, [userInfoRedux]);

  const Header = () => {
    return (
      <div>
        <div className="sidebar-logo-div">
          <img
            src={menuClose}
            onClick={() => {
              onClose();
              {
                eventTrack(
                  "Close-Sidebar-Action",
                  "Close-Sidebar",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }
            }}
            className="cursor-pointer"
          />
          <img src={Logo} />
        </div>

        <div
          className="d-flex align-items-center mt-3 cursor-pointer text-underline-hover"
          onClick={() => {
            history.push("/profile");
            {
              eventTrack(
                "Profile-Navigation-Action",
                "Profile-Navigation",
                userInfoRedux?.name,
                "+1",
                false,
                "+1"
              );
            }
          }}
        >
          <img
            src={profilePic ?? DefaultImage}
            onError={(e) => (e.target.src = DefaultImage)}
            className="sidebar-profile-img"
          />
          <div className="ml-3">
            <p className="mb-0 f-16 roboto text-black font-weight-normal">
              {userInfoRedux?.name ?? "Guest"}
            </p>
            <p className="mb-0 f-14 roboto font-weight-normal">
              {userInfoRedux?.email}
            </p>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      getAdminandCompanyPermission();
      getCompanySettingsAPI();
      getHealthCheckAPI();
      if (userInfoRedux?.admin) {
        getAdminProjectsAPI();
      } else {
        getProjectsAPI();
      }
      requestMileStone();
    })();
  }, []);


  useEffect(() => {
    refreshRecentProject(projectsRedux);
  }, [projectsRedux]);

  useEffect(() => {
    if (userInfoRedux?.admin) {
      getAdminProjectsAPI();
    } else {
      getProjectsAPI();
    }
  }, [refreshProjectRedux]);

  const { request: getProjectsAPI } = useHttp({
    requestCallback: () => getProjects(),
    onLoad: (res) => {
      refreshRecentProject(res?.projects);
      dispatch(setProjects(res?.projects));
    },
    onError: (res) => {},
  });

  const {
    adminProjectsLoading,
    adminProjectsError,
    request: getAdminProjectsAPI,
  } = useHttpWithCache({
    requestCallback: () => mainAdminGetCompanies(),
    reduxSetter: (data) => {
      dispatch(
        setProjects(
          data?.company_projects?.find(
            (item) => item?._id === userInfoRedux?.company
          )?.projects
        )
      );
      dispatch(
        setCompanyUsers(
          data?.all_users?.filter(
            (item) => item?.company === userInfoRedux?.company
          )
        )
      );
      dispatch(mainAdminSetCompanies(data.company));
      dispatch({
        type: "MAIN_ADMIN_SET_ALL_COMPANIES_PROJECTS",
        payload: data.company_projects,
      });
      dispatch({
        type: "MAIN_ADMIN_SET_ALL_USERS",
        payload: data.all_users,
      });
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
      dispatch(mainAdminSetCompanies(null));
      dispatch({
        type: "MAIN_ADMIN_SET_ALL_COMPANIES_PROJECTS",
        payload: null,
      });
      dispatch({
        type: "MAIN_ADMIN_SET_ALL_USERS",
        payload: null,
      });
    },
    reduxCash: {},
    allowToCash: false,
  });

  const { request: getCompanySettingsAPI } = useHttp({
    requestCallback: () => getCompanySettings(userInfoRedux?.company),
    onLoad: (res) => {
      dispatch(setCompanySettings(res));
    },
    onError: (res) => {},
  });

  const getAdminandCompanyPermission = async () => {
    try {
      const res = await getUserCompanyPermission(
        userInfoRedux?.id,
        userInfoRedux?.company
      );
      if (res) {
        dispatch(setCompanyPermission(res.company_permissions));
        dispatch(setAllPermission(res.all_permissions));
        dispatch(addPermissionIsUser(res?.user_permissions));
        if (
          !localStorage.getItem(user_permissionsRef) &&
          !localStorage.getItem(company_permissionsRef) &&
          !localStorage.getItem(all_permissionsRef)
        ) {
          localStorage.setItem(user_permissionsRef, res?.user_permissions);
          localStorage.setItem(
            company_permissionsRef,
            res?.company_permissions
          );
          localStorage.setItem(all_permissionsRef, res?.all_permissions);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { request: getHealthCheckAPI } = useHttp({
    requestCallback: () => getHealthCheck(userInfoRedux?.company),
    onLoad: (res) => {
      const ModelsSortByDate = Maybe.of(res)?.value?.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      setRecentModels(ModelsSortByDate);
    },
    onError: (res) => {},
  });

  const { request: requestMileStone } = useHttpWithCache({
    requestCallback: () => getMileStones(userInfoRedux?.company),
    reduxSetter: (data) => {
      dispatch(setMilestones(data));
    },
    reduxCash: {
      milestones: milestonesRedux,
    },
    reduxReset: () => {
      dispatch(setMilestones(null));
    },
    allowToCash: false,
  });

  const requestAccess = async () => {
    try {
      setLoading(true);
      const res = await createFeatureRequest(userInfoRedux?.id, {
        companyId: userInfoRedux?.company,
        permission: requestType,
        userId: userInfoRedux?.id,
      });
      if (res) {
        setLoading(false);
        addNotification("Request Successfully! ", "success");
        dispatch(
          setFeatureRequest(res?.map((feature) => feature?.requestedFeature))
        );
      }
    } catch (e) {
      addNotification(e?.message, "danger");
      setLoading(false);
    }
  };

  const refreshRecentProject = (projects) => {
    const parentFavProjects = Maybe.of(projects).map((item) => {
      return item.filter(
        (project) =>
          (project.isParent == true || project.parentProjectID == "") &&
          project.favourite
      );
    });
    const ProjectSortByDate = parentFavProjects?.value
      ?.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      })
      ?.slice(0, 4);
    setRecentProjects(ProjectSortByDate);
  };

  return (
    <>
      <Drawer
        title={<Header />}
        placement="left"
        closable={false}
        onClose={() => onClose()}
        open={sidebarStatusRedux}
        key="left"
        width={320}
      >
        <div>
          <div>
            <Accordion allowZeroExpanded>
              {/*Dashboard  */}
              <div>
                <h5 className="mb-0">
                  <button className="sidebar-collapse-btn bg-white">
                    <div className="sidebar-list">
                      <i className="fa fa-home" aria-hidden="true"></i>
                      <Link to={"/dashboard"} className="text-decoration-none">
                        <p className="ml-1">Dashboard</p>
                      </Link>
                    </div>
                  </button>
                </h5>
              </div>

              {/* projects */}
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <button
                          className={
                            expanded
                              ? "sidebar-collapse-btn collapse-active"
                              : "sidebar-collapse-btn bg-white"
                          }
                        >
                          <div className="sidebar-list">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M1.6 16C1.16 16 0.783333 15.8351 0.47 15.5053C0.156667 15.1754 0 14.7789 0 14.3158V5.05263C0 4.58947 0.156667 4.19298 0.47 3.86316C0.783333 3.53333 1.16 3.36842 1.6 3.36842H4.8V1.68421C4.8 1.22105 4.95667 0.824561 5.27 0.494737C5.58333 0.164912 5.96 0 6.4 0H9.6C10.04 0 10.4167 0.164912 10.73 0.494737C11.0433 0.824561 11.2 1.22105 11.2 1.68421V3.36842H14.4C14.84 3.36842 15.2167 3.53333 15.53 3.86316C15.8433 4.19298 16 4.58947 16 5.05263V14.3158C16 14.7789 15.8433 15.1754 15.53 15.5053C15.2167 15.8351 14.84 16 14.4 16H1.6ZM6.4 3.36842H9.6V1.68421H6.4V3.36842Z"
                                fill=""
                              />
                            </svg>
                            <p className="ml-1"> Projects </p>
                            {!FinalPermissions?.includes(
                              ALL_PERMISSIONS.PROJECT_VIEW
                            ) ? (
                              <p
                                className="access-btn"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(
                                      ALL_PERMISSIONS.PROJECT_VIEW
                                    );
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                  {
                                    eventTrack(
                                      "Projects-Navigation-Action",
                                      "Projects-Navigation",
                                      userInfoRedux?.name,
                                      "+1",
                                      false,
                                      "+1"
                                    );
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.PROJECT_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            ) : (
                              <img
                                src={Arrow}
                                className={
                                  expanded
                                    ? "arrow-icon"
                                    : "arrow-icon transform-180"
                                }
                              />
                            )}
                          </div>
                        </button>
                      )}
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                {FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW) && (
                  <AccordionItemPanel className="show">
                    <div className="card-body admin-card-body">
                      <ul className="siderbar-inner-ul admin-inner-ul mb-0">
                        <Link
                          to={"/personal-area"}
                          className="mb-0"
                          onClick={() => {
                            dispatch(setPersonalAreaActiveTab("CARD_VIEW"));
                            {
                              eventTrack(
                                "Projects-Navigation-Action",
                                "Projects-Navigation",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              );
                            }
                          }}
                        >
                          All Projects{" "}
                          {/* <MDBDropdown dropright className="ml-auto">
                            <MDBDropdownToggle className="dots-btn">
                              <img src={SidebarDots} className="ml-auto dots" />
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className="w-100 border-none">
                              <div className="card-body-help">
                                <ul className="siderbar-inner-ul">
                                  <li>Access</li>
                                  <li>Members</li>
                                  <li>History</li>
                                  <li>Share Estimate</li>
                                </ul>
                              </div>
                            </MDBDropdownMenu>
                          </MDBDropdown> */}
                        </Link>
                      </ul>
                    </div>
                    <div className="card-body admin-card-body">
                      <p className="f-14">Favourite Projects</p>
                      <ul className="siderbar-inner-ul admin-inner-ul">
                        {recentProjects?.length > 0 ? (
                          recentProjects?.map((item, key) => (
                            <li className="text-capitalize" key={key}>
                              <Link
                                to={`/personal-area/projects/${item?._id}/timelines`}
                                className="mb-0"
                              >
                                <img src={FavImg} /> {item?.title}
                              </Link>
                            </li>
                          ))
                        ) : (
                          <li className="text-capitalize">
                            <Link to="#" className="mb-0">
                              No Favourite projects
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="card-body admin-card-body">
                      <ul className="siderbar-inner-ul mb-0 admin-inner-ul">
                        {FinalPermissions?.includes(
                          ALL_PERMISSIONS.KANBAN_VIEW
                        ) ? (
                          <Link
                            to={"/personal-area"}
                            className="mb-0"
                            onClick={() => {
                              dispatch(setPersonalAreaActiveTab("KANBAN_VIEW"));
                              eventTrack(
                                "Navigate-Kanban-Action",
                                "Navigate-Kanban",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              );
                            }}
                          >
                            Kanban
                          </Link>
                        ) : (
                          <div className="d-flex">
                            <p className="mb-0">Kanban</p>
                            <p
                              className="access-btn mb-0"
                              onClick={() => {
                                setRequestType(ALL_PERMISSIONS.KANBAN_VIEW);
                                setRequestModal(true);
                                dispatch(setSidebarStatus(false));
                                eventTrack(
                                  "Request-Licence-Action",
                                  "Request-Licence",
                                  userInfoRedux?.name,
                                  "+1",
                                  false,
                                  "+1"
                                );
                              }}
                            >
                              Request License
                            </p>
                          </div>
                        )}
                      </ul>
                    </div>
                  </AccordionItemPanel>
                )}
              </AccordionItem>

              {/* modal health */}

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <button
                          className={
                            expanded
                              ? "sidebar-collapse-btn collapse-active"
                              : "sidebar-collapse-btn bg-white"
                          }
                        >
                          <div className="sidebar-list">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 2C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H6C5.44772 22 5 21.5523 5 21V19H3V17H5V15H3V13H5V11H3V9H5V7H3V5H5V3C5 2.44772 5.44772 2 6 2H20ZM14 8H12V11H9V13H11.999L12 16H14L13.999 13H17V11H14V8Z"
                                fill=""
                              />
                            </svg>
                            <p className="ml-1"> Model Health </p>
                            {!FinalPermissions?.includes(
                              ALL_PERMISSIONS.MODAL_HEALTH_REPORT_VIEW
                            ) ? (
                              <p
                                className="access-btn"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(
                                      ALL_PERMISSIONS.MODAL_HEALTH_REPORT_VIEW
                                    );
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                    eventTrack(
                                      "Request-Licence-Action",
                                      "Request-Licence",
                                      userInfoRedux?.name,
                                      "+1",
                                      false,
                                      "+1"
                                    );
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.MODAL_HEALTH_REPORT_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            ) : (
                              <img
                                src={Arrow}
                                className={
                                  expanded
                                    ? "arrow-icon"
                                    : "arrow-icon transform-180"
                                }
                              />
                            )}
                          </div>
                        </button>
                      )}
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>

                {FinalPermissions?.includes(
                  ALL_PERMISSIONS.MODAL_HEALTH_REPORT_VIEW
                ) && (
                  <AccordionItemPanel className="show">
                    <div className="card-body admin-card-body">
                      <ul className="siderbar-inner-ul mb-0 admin-inner-ul">
                        <Link
                          to={"/model-health"}
                          className="mb-0"
                          onClick={eventTrack(
                            "Navigate-ModelHealth-Action",
                            "Navigate-ModelHealth",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          )}
                        >
                          All Reports{" "}
                        </Link>
                      </ul>
                    </div>

                    <div className="card-body admin-card-body">
                      <ul className="siderbar-inner-ul mb-0 admin-inner-ul">
                        <Link
                          to={"/modal-file-list"}
                          className="mb-0"
                          onClick={eventTrack(
                            "Navigate-ModelFileList-Action",
                            "Navigate-ModelFileList",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          )}
                        >
                          Model File List{" "}
                        </Link>
                      </ul>
                    </div>

                    <div className="card-body admin-card-body">
                      <p className="f-14">Recent Health Reports</p>
                      {recentModels?.length > 0 && (
                        <ul className="siderbar-inner-ul admin-inner-ul">
                          {}
                          <li className="text-capitalize">
                            <Link
                              to={`/model-health/${recentModels[0]?._id}`}
                              className="mb-0"
                            >
                              {recentModels[0]?.modelName}
                            </Link>
                          </li>
                          <li className="text-capitalize">
                            <Link
                              to={`/model-health/${recentModels[1]?._id}`}
                              className="mb-0"
                            >
                              {recentModels[1]?.modelName}
                            </Link>
                          </li>
                          <li className="text-capitalize">
                            <Link
                              to={`/model-health/${recentModels[2]?._id}`}
                              className="mb-0"
                            >
                              {recentModels[2]?.modelName}
                            </Link>
                          </li>
                          <li className="text-capitalize">
                            <Link
                              to={`/model-health/${recentModels[3]?._id}`}
                              className="mb-0"
                            >
                              {recentModels[3]?.modelName}
                            </Link>
                          </li>
                        </ul>
                      )}
                    </div>
                  </AccordionItemPanel>
                )}
              </AccordionItem>

              {/*Database  */}
              <div>
                <h5 className="mb-0">
                  <button className="sidebar-collapse-btn bg-white">
                    <div className="sidebar-list">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 9.5V12.5C21 14.9853 16.9706 17 12 17C7.02944 17 3 14.9853 3 12.5V9.5C3 11.9853 7.02944 14 12 14C16.9706 14 21 11.9853 21 9.5ZM3 14.5C3 16.9853 7.02944 19 12 19C16.9706 19 21 16.9853 21 14.5V17.5C21 19.9853 16.9706 22 12 22C7.02944 22 3 19.9853 3 17.5V14.5ZM12 12C7.02944 12 3 9.98528 3 7.5C3 5.01472 7.02944 3 12 3C16.9706 3 21 5.01472 21 7.5C21 9.98528 16.9706 12 12 12Z"
                          fill="#000"
                        />
                      </svg>
                      {FinalPermissions?.includes(
                        ALL_PERMISSIONS.PRICING_DATABASE_VIEW
                      ) ? (
                        <Link
                          to={"/pricing-catalogues"}
                          onClick={eventTrack(
                            "Navigate-Pricing-Catelogues-Action",
                            "Navigate-Pricing-Catelogues",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          )}
                          className="text-decoration-none"
                        >
                          <p className="ml-1">Pricing Catalogs</p>
                        </Link>
                      ) : (
                        <>
                          <p className="ml-1"> Pricing Catalogs </p>
                          <p
                            className="access-btn"
                            onClick={() => {
                              if (userToken) {
                                setRequestType(
                                  ALL_PERMISSIONS.PRICING_DATABASE_VIEW
                                );
                                dispatch(setSidebarStatus(false));
                                setRequestModal(true);
                              } else {
                                history.push("/#contact-section");
                              }
                            }}
                          >
                            {userInfoRedux?.companyAdmin
                              ? "Acquire License"
                              : featureRequestRedux?.includes(
                                  ALL_PERMISSIONS.PRICING_DATABASE_VIEW
                                )
                              ? "Pending"
                              : "Request Access"}
                          </p>
                        </>
                      )}
                    </div>
                  </button>
                </h5>
              </div>

              {/*Parameters  */}
              <div>
                <div>
                  <h5 className="mb-0">
                    <button className="sidebar-collapse-btn bg-white">
                      <div className="sidebar-list">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.0"
                          width="512.000000pt"
                          height="512.000000pt"
                          viewBox="0 0 512.000000 512.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path d="M2982 4776 c-139 -34 -298 -150 -370 -269 -61 -101 -85 -188 -86 -307 -1 -171 54 -305 174 -426 110 -111 234 -165 395 -172 172 -7 307 42 429 158 133 127 191 261 190 440 -2 173 -60 306 -187 426 -76 72 -175 127 -269 149 -76 18 -204 18 -276 1z m226 -362 c46 -19 98 -68 123 -116 79 -154 -35 -338 -211 -338 -177 0 -291 183 -210 340 24 45 76 95 120 114 46 20 131 20 178 0z" />
                            <path d="M159 4370 c-118 -62 -143 -210 -52 -311 66 -73 21 -70 1095 -67 l955 3 48 30 c83 52 119 160 81 248 -17 39 -71 92 -109 106 -19 8 -340 11 -1005 11 -943 -1 -978 -2 -1013 -20z" />
                            <path d="M4055 4376 c-38 -17 -90 -72 -104 -109 -33 -88 3 -192 84 -242 l48 -30 394 -3 c453 -4 475 -1 536 67 89 99 63 252 -52 311 -39 19 -58 20 -458 20 -328 -1 -424 -4 -448 -14z" />
                            <path d="M1685 3154 c-239 -37 -432 -206 -501 -439 -24 -81 -24 -229 0 -310 61 -206 215 -360 421 -421 95 -29 239 -24 340 10 249 85 409 306 409 566 0 258 -161 482 -405 564 -74 25 -202 39 -264 30z m184 -384 c81 -41 125 -115 125 -211 0 -53 -5 -74 -27 -112 -48 -81 -115 -121 -207 -121 -99 0 -168 44 -217 138 -23 43 -22 150 1 196 63 123 206 171 325 110z" />
                            <path d="M185 2747 c-55 -22 -84 -48 -110 -100 -14 -28 -25 -67 -25 -86 0 -58 42 -136 91 -168 l42 -28 291 -3 c191 -2 304 0 328 8 142 42 186 235 76 337 -53 50 -77 53 -380 52 -191 0 -293 -4 -313 -12z" />
                            <path d="M2700 2744 c-168 -73 -156 -324 18 -374 26 -8 371 -10 1128 -8 l1090 3 41 27 c48 32 93 113 93 169 0 21 -12 60 -28 90 -22 43 -38 59 -81 81 l-53 28 -1087 0 c-947 -1 -1090 -3 -1121 -16z" />
                            <path d="M3005 1513 c-173 -42 -310 -140 -398 -285 -59 -98 -82 -184 -81 -308 1 -119 25 -206 86 -307 74 -123 244 -243 386 -272 195 -40 396 21 538 163 81 81 115 136 151 239 35 99 37 250 6 352 -63 200 -228 357 -429 409 -58 15 -212 20 -259 9z m223 -379 c85 -42 142 -148 128 -237 -30 -187 -251 -272 -387 -150 -72 65 -100 147 -79 232 36 150 199 224 338 155z" />
                            <path d="M206 1119 c-179 -43 -209 -286 -45 -370 38 -19 61 -19 1027 -17 l988 3 36 24 c56 39 82 85 86 155 5 81 -25 139 -93 181 l-48 30 -956 2 c-566 1 -972 -3 -995 -8z" />
                            <path d="M4098 1121 c-172 -34 -216 -262 -70 -362 l36 -24 428 -3 c410 -3 429 -2 467 17 166 85 134 328 -49 370 -51 12 -753 13 -812 2z" />
                          </g>
                        </svg>
                        {FinalPermissions?.includes(
                          ALL_PERMISSIONS.PARAMETER_MGMT_VIEW
                        ) ? (
                          <Link
                            to={"/all-parameters"}
                            onClick={eventTrack(
                              "Navigate-Parameters-Action",
                              "Navigate-Parameters",
                              userInfoRedux?.name,
                              "+1",
                              false,
                              "+1"
                            )}
                            className="text-decoration-none"
                          >
                            <p className="ml-1">Parameters</p>
                          </Link>
                        ) : (
                          <>
                            <p className="ml-1"> Parameters </p>
                            <p
                              className="access-btn"
                              onClick={() => {
                                if (userToken) {
                                  setRequestType(
                                    ALL_PERMISSIONS.PARAMETER_MGMT_VIEW
                                  );
                                  dispatch(setSidebarStatus(false));
                                  setRequestModal(true);
                                } else {
                                  history.push("/#contact-section");
                                }
                              }}
                            >
                              {userInfoRedux?.companyAdmin
                                ? "Acquire License"
                                : featureRequestRedux?.includes(
                                    ALL_PERMISSIONS.PARAMETER_MGMT_VIEW
                                  )
                                ? "Pending"
                                : "Request Access"}
                            </p>
                          </>
                        )}
                      </div>
                    </button>
                  </h5>
                </div>
              </div>

              {/*Assets Mgmt  */}
              <div>
                <div>
                  <h5 className="mb-0">
                    <button className="sidebar-collapse-btn bg-white">
                      <div className="sidebar-list">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.0"
                          width="90.000000pt"
                          height="90.000000pt"
                          viewBox="0 0 90.000000 90.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path d="M265 698 c-123 -102 -170 -146 -170 -162 0 -16 7 -22 28 -24 l27 -3 0 -175 c0 -224 -7 -214 160 -214 l120 0 -6 58 c-12 111 40 213 136 266 46 25 66 31 122 31 57 0 68 3 68 17 0 11 10 18 28 20 40 4 42 40 3 67 -28 20 -30 27 -33 94 l-3 72 -40 0 c-37 0 -40 -2 -45 -32 l-5 -32 -97 79 c-53 44 -103 80 -110 79 -7 0 -89 -63 -183 -141z" />
                            <path d="M605 401 c-168 -78 -164 -314 7 -385 170 -71 346 112 269 279 -49 106 -173 154 -276 106z m115 -60 c0 -10 11 -26 25 -35 32 -21 33 -46 1 -46 -14 0 -28 5 -31 10 -8 14 -42 13 -51 -2 -9 -14 23 -38 53 -38 29 0 63 -33 63 -61 0 -12 -13 -34 -30 -49 -16 -15 -30 -35 -30 -44 0 -11 -9 -16 -30 -16 -22 0 -30 5 -30 18 0 10 -13 28 -30 40 -16 13 -30 27 -30 32 0 14 46 12 69 -2 10 -6 26 -8 35 -5 26 10 19 36 -11 42 -58 12 -78 29 -81 67 -3 30 1 40 22 54 14 9 26 25 26 35 0 14 7 19 30 19 23 0 30 -5 30 -19z" />
                          </g>
                        </svg>
                        {FinalPermissions?.includes(
                          ALL_PERMISSIONS.ASSET_MANAGEMENT_VIEW
                        ) ? (
                          <Link
                            to={"/assets-management"}
                            onClick={eventTrack(
                              "Navigate-Datawarehouse-Action",
                              "Navigate-Datawarehouse",
                              userInfoRedux?.name,
                              "+1",
                              false,
                              "+1"
                            )}
                            className="text-decoration-none"
                          >
                            <p className="ml-1">Data Warehouse</p>
                          </Link>
                        ) : (
                          <>
                            <p className="ml-1"> Data Warehouse </p>
                            <p
                              className="access-btn"
                              onClick={() => {
                                if (userToken) {
                                  setRequestType(
                                    ALL_PERMISSIONS.ASSET_MANAGEMENT_VIEW
                                  );
                                  dispatch(setSidebarStatus(false));
                                  setRequestModal(true);
                                } else {
                                  history.push("/#contact-section");
                                }
                              }}
                            >
                              {userInfoRedux?.companyAdmin
                                ? "Acquire License"
                                : featureRequestRedux?.includes(
                                    ALL_PERMISSIONS.ASSET_MANAGEMENT_VIEW
                                  )
                                ? "Pending"
                                : "Request Access"}
                            </p>
                          </>
                        )}
                      </div>
                    </button>
                  </h5>
                </div>
              </div>

              {/*Filter view  */}
              <div>
                <h5 className="mb-0">
                  <button className="sidebar-collapse-btn bg-white">
                    <div className="sidebar-list">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.97635 16.0002C7.8442 16.0036 7.71277 15.9798 7.59015 15.9305C7.46752 15.8811 7.3563 15.8072 7.26335 15.7132C7.16932 15.6203 7.09534 15.509 7.04597 15.3864C6.99661 15.2638 6.97291 15.1323 6.97635 15.0002V9.00018L1.17635 1.60015C1.05613 1.45573 0.981356 1.27901 0.961426 1.09216C0.941495 0.905308 0.977304 0.716699 1.06435 0.550165C1.14163 0.376041 1.27058 0.229907 1.43372 0.131525C1.59685 0.0331435 1.78628 -0.0127164 1.97635 0.000177374H15.9763C16.1664 -0.0127164 16.3559 0.0331435 16.519 0.131525C16.6821 0.229907 16.8111 0.376041 16.8884 0.550165C16.9754 0.716699 17.0112 0.905308 16.9913 1.09216C16.9713 1.27901 16.8966 1.45573 16.7764 1.60015L10.9763 9.00018V15.0002C10.9797 15.1324 10.9558 15.2639 10.9063 15.3865C10.8567 15.5092 10.7826 15.6204 10.6884 15.7132C10.5955 15.8071 10.4845 15.8809 10.362 15.9303C10.2396 15.9796 10.1083 16.0034 9.97635 16.0002H7.97635ZM8.97635 8.30017L13.9263 2.00018H4.02635L8.97635 8.30017Z"
                          fill="#000000"
                        ></path>
                      </svg>
                      {FinalPermissions?.includes(
                        ALL_PERMISSIONS.FILTER_VIEW
                      ) ? (
                        <Link
                          to={"/filter-view"}
                          onClick={eventTrack(
                            "Navigate-Filter-View-Action",
                            "Navigate-Filter-View",
                            userInfoRedux?.name,
                            "+1"
                          )}
                          className="text-decoration-none"
                        >
                          <p className="ml-1">Filter View</p>
                        </Link>
                      ) : (
                        <>
                          <p className="ml-1">Filter View </p>
                          <p
                            className="access-btn"
                            onClick={() => {
                              if (userToken) {
                                setRequestType(ALL_PERMISSIONS.FILTER_VIEW);
                                dispatch(setSidebarStatus(false));
                                setRequestModal(true);
                              } else {
                                history.push("/#contact-section");
                              }
                            }}
                          >
                            {userInfoRedux?.companyAdmin
                              ? "Acquire License"
                              : featureRequestRedux?.includes(
                                  ALL_PERMISSIONS.FILTER_VIEW
                                )
                              ? "Pending"
                              : "Request Access"}
                          </p>
                        </>
                      )}
                    </div>
                  </button>
                </h5>
              </div>

              {/*Doc control  */}
              <div>
                <h5 className="mb-0">
                  <button className="sidebar-collapse-btn bg-white">
                    <div className="sidebar-list">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="800px"
                        height="800px"
                        viewBox="-2 0 32 32"
                        version="1.1"
                      >
                        <g
                          id="Page-1"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Icon-Set-Filled"
                            transform="translate(-208.000000, -101.000000)"
                            fill="#000000"
                          >
                            <path
                              d="M231,109 C229.935,109 229,108.039 229,107 L229,103.031 L229,103.029 L234,109 L231,109 L231,109 Z M229,117.016 L220,117.016 C219.448,117.016 219,116.568 219,116.017 C219,115.465 219.448,115.018 220,115.018 L229,115.018 C229.552,115.018 230,115.465 230,116.017 C230,116.568 229.552,117.016 229,117.016 L229,117.016 Z M229,122.011 L220,122.011 C219.448,122.011 219,121.563 219,121.012 C219,120.46 219.448,120.013 220,120.013 L229,120.013 C229.552,120.013 230,120.46 230,121.012 C230,121.563 229.552,122.011 229,122.011 L229,122.011 Z M227,131 L212,131 C210.95,131 210,130.019 210,129 L210,109 C210,107.513 211.224,107 213,107 L213,125 C213,127.059 214.884,129 217,129 C217,129 227.842,128.989 229.009,128.989 C229.009,130.202 228.213,131 227,131 L227,131 Z M229,101.031 C228.876,101.031 217,101 217,101 C214.884,101 213,102.941 213,105 L211.845,105.027 C209.729,105.027 208,106.941 208,109 L208,129 C208,131.059 209.884,133 212,133 L227,133 C229.116,133 231,131.059 231,129 L232,129 C234.116,129 236,127.059 236,125 L236,109.023 L229,101.031 L229,101.031 Z"
                              id="multiple-documents-files"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      {FinalPermissions?.includes(
                        ALL_PERMISSIONS.DOC_CONTROL_VIEW
                      ) ? (
                        <Link
                          to={"/document-control"}
                          onClick={eventTrack(
                            "Navigate-Doc-Control-Action",
                            "Navigate-Doc-Control",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          )}
                          className="text-decoration-none"
                        >
                          <p className="ml-1">Doc Control</p>
                        </Link>
                      ) : (
                        <>
                          <p className="ml-1">Doc Control </p>
                          <p
                            className="access-btn"
                            onClick={() => {
                              if (userToken) {
                                setRequestType(
                                  ALL_PERMISSIONS.DOC_CONTROL_VIEW
                                );
                                dispatch(setSidebarStatus(false));
                                setRequestModal(true);
                              } else {
                                history.push("/#contact-section");
                              }
                            }}
                          >
                            {userInfoRedux?.companyAdmin
                              ? "Acquire License"
                              : featureRequestRedux?.includes(
                                  ALL_PERMISSIONS.DOC_CONTROL_VIEW
                                )
                              ? "Pending"
                              : "Request Access"}
                          </p>
                        </>
                      )}
                    </div>
                  </button>
                </h5>
              </div>

              {/*Engineering Tracking  */}
              <div>
                <h5 className="mb-0">
                  <button className="sidebar-collapse-btn bg-white">
                    <div className="sidebar-list">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      {FinalPermissions?.includes(
                        ALL_PERMISSIONS.ENGINEERING_TRACKING_VIEW
                      ) ? (
                        <Link
                          to={"/resource-tracking"}
                          onClick={eventTrack(
                            "Navigate-Resource-Tracking-Action",
                            "Navigate-Resource-Tracking",
                            userInfoRedux?.name,
                            "+1"
                          )}
                          className="text-decoration-none"
                        >
                          <p className="ml-1">Resource Tracking</p>
                        </Link>
                      ) : (
                        <>
                          <p className="ml-1">Resource Tracking </p>
                          <p
                            className="access-btn"
                            onClick={() => {
                              if (userToken) {
                                setRequestType(
                                  ALL_PERMISSIONS.ENGINEERING_TRACKING_VIEW
                                );
                                dispatch(setSidebarStatus(false));
                                setRequestModal(true);
                              } else {
                                history.push("/#contact-section");
                              }
                            }}
                          >
                            {userInfoRedux?.companyAdmin
                              ? "Acquire License"
                              : featureRequestRedux?.includes(
                                  ALL_PERMISSIONS.ENGINEERING_TRACKING_VIEW
                                )
                              ? "Pending"
                              : "Request Access"}
                          </p>
                        </>
                      )}
                    </div>
                  </button>
                </h5>
              </div>

              {/*Earnings  */}
              <div>
                <h5 className="mb-0">
                  <button className="sidebar-collapse-btn bg-white">
                    <div className="sidebar-list">
                      <i className="fa fa-money" aria-hidden="true"></i>
                      {FinalPermissions?.includes(
                        ALL_PERMISSIONS.AFFILIATIONS_VIEW
                      ) ? (
                        <Link
                          to={"/earnings"}
                          onClick={eventTrack(
                            "Navigate-Earnings-Action",
                            "Navigate-Earnings",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          )}
                          className="text-decoration-none"
                        >
                          <p className="ml-1">Earnings</p>
                        </Link>
                      ) : (
                        <>
                          <p className="ml-1">Earnings</p>
                          <p
                            className="access-btn"
                            onClick={() => {
                              if (userToken) {
                                setRequestType(
                                  ALL_PERMISSIONS.AFFILIATIONS_VIEW
                                );
                                dispatch(setSidebarStatus(false));
                                setRequestModal(true);
                              } else {
                                history.push("/#contact-section");
                              }
                            }}
                          >
                            {userInfoRedux?.companyAdmin
                              ? "Acquire License"
                              : featureRequestRedux?.includes(
                                  ALL_PERMISSIONS.AFFILIATIONS_VIEW
                                )
                              ? "Pending"
                              : "Request Access"}
                          </p>
                        </>
                      )}
                    </div>
                  </button>
                </h5>
              </div>

              {/* admin */}
              <div className="admin-accordian">
                {/* admin  */}
                {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                  <AccordionItem>
                    <AccordionItemPanel className="show">
                      <div className="card-body rounded-lg admin-card-body admin-card-body1">
                        <ul className="siderbar-inner-ul admin-side-toggle admin-inner-ul">
                          {userInfoRedux?.admin == true && (
                            <Link to={"/main-admin"}>Company Settings</Link>
                          )}
                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.PROJECT_VIEW
                          ) ? (
                            <Link
                              to={"/admin/projects-settings"}
                              onClick={eventTrack(
                                "Navigate-Admin-Project-Settings-Action",
                                "Navigate-Admin-Project-Settings",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              )}
                            >
                              Projects Settings
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center position-relative">
                              <p className="admin-nav-list">
                                {" "}
                                Projects Settings{" "}
                              </p>
                              <p
                                className="access-btn cursor-pointer mb-2"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(
                                      ALL_PERMISSIONS.PROJECT_VIEW
                                    );
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.PROJECT_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            </div>
                          )}

                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.MODAL_HEALTH_SETTINGS_VIEW
                          ) ? (
                            <Link
                              to={"/admin/model-health-settings"}
                              onClick={eventTrack(
                                "Navigate-Admin-Model-Health-Settings-Action",
                                "Navigate-Admin-Model-Health-Settings",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              )}
                            >
                              Model Health Settings
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center position-relative">
                              <p className="admin-nav-list">
                                {" "}
                                Model Health Settings{" "}
                              </p>
                              <p
                                className="access-btn cursor-pointer mb-2"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(
                                      ALL_PERMISSIONS.MODAL_HEALTH_SETTINGS_VIEW
                                    );
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.MODAL_HEALTH_SETTINGS_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            </div>
                          )}

                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_VIEW
                          ) ? (
                            <Link
                              to={"/admin/milestones"}
                              onClick={eventTrack(
                                "Navigate-Admin-Milestone-Settings-Action",
                                "Navigate-Admin-Milestone-Settings",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              )}
                            >
                              Milestones
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center position-relative">
                              <p className="admin-nav-list"> Milestones </p>
                              <p
                                className="access-btn cursor-pointer mb-2"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(
                                      ALL_PERMISSIONS.MILESTONE_VIEW
                                    );
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.MILESTONE_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            </div>
                          )}

                          {/* discipline */}
                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.DISCIPLINE_VIEW
                          ) ? (
                            <Link
                              to={"/admin/discipline-settings"}
                              onClick={eventTrack(
                                "Navigate-Admin-Discipline-Settings-Action",
                                "Navigate-Admin-Discipline-Settings",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              )}
                            >
                              Discipline Settings
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center position-relative">
                              <p className="admin-nav-list">
                                {" "}
                                Discipline Settings{" "}
                              </p>
                              <p
                                className="access-btn cursor-pointer mb-2"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(
                                      ALL_PERMISSIONS.DISCIPLINE_VIEW
                                    );
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.DISCIPLINE_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            </div>
                          )}

                          {/* Revisions */}
                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.REVISION_VIEW
                          ) ? (
                            <Link
                              to={"/admin/revision-settings"}
                              onClick={eventTrack(
                                "Navigate-Admin-Revision-Settings-Action",
                                "Navigate-Admin-Revision-Settings",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              )}
                            >
                              Revision Settings
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center position-relative">
                              <p className="admin-nav-list">
                                {" "}
                                Revision Settings{" "}
                              </p>
                              <p
                                className="access-btn cursor-pointer mb-2"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(
                                      ALL_PERMISSIONS.REVISION_VIEW
                                    );
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.REVISION_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            </div>
                          )}

                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.USER_VIEW
                          ) ? (
                            <Link
                              to={"/admin/user-permissions"}
                              onClick={eventTrack(
                                "Navigate-Admin-User-Permissions-Settings-Action",
                                "Navigate-Admin-User-Permissions-Settings",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              )}
                            >
                              User, Roles & Permissions
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center position-relative">
                              <p className="admin-nav-list">
                                {" "}
                                User, Roles & Permissions{" "}
                              </p>
                              <p
                                className="access-btn cursor-pointer mb-2"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(ALL_PERMISSIONS.USER_VIEW);
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.USER_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            </div>
                          )}

                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.PROJECT_EXPORT_SETTINGS_VIEW
                          ) ? (
                            <Link
                              to={"/admin/export-settings"}
                              onClick={eventTrack(
                                "Navigate-Admin-Export-Settings-Action",
                                "Navigate-Admin-Export-Settings",
                                userInfoRedux?.name,
                                "+1",
                                false,
                                "+1"
                              )}
                            >
                              Export Settings
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center position-relative">
                              <p className="admin-nav-list">
                                {" "}
                                Export Settings{" "}
                              </p>
                              <p
                                className="access-btn cursor-pointer mb-2"
                                onClick={() => {
                                  if (userToken) {
                                    setRequestType(
                                      ALL_PERMISSIONS.PROJECT_EXPORT_SETTINGS_VIEW
                                    );
                                    dispatch(setSidebarStatus(false));
                                    setRequestModal(true);
                                  } else {
                                    history.push("/#contact-section");
                                  }
                                }}
                              >
                                {userInfoRedux?.companyAdmin
                                  ? "Acquire License"
                                  : featureRequestRedux?.includes(
                                      ALL_PERMISSIONS.PROJECT_EXPORT_SETTINGS_VIEW
                                    )
                                  ? "Pending"
                                  : "Request Access"}
                              </p>
                            </div>
                          )}
                        </ul>
                      </div>
                    </AccordionItemPanel>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <button className="sidebar-collapse-btn collapse-active">
                                <div className="sidebar-list">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="19"
                                    viewBox="0 0 15 19"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.57087 10.5891C8.45123 10.5891 9.20257 10.278 9.82489 9.65566C10.4472 9.03334 10.7584 8.282 10.7584 7.40165C10.7584 6.52129 10.4472 5.76995 9.82489 5.14763C9.20257 4.52531 8.45123 4.21415 7.57087 4.21415C6.69051 4.21415 5.93917 4.52531 5.31685 5.14763C4.69453 5.76995 4.38337 6.52129 4.38337 7.40165C4.38337 8.282 4.69453 9.03334 5.31685 9.65566C5.93917 10.278 6.69051 10.5891 7.57087 10.5891ZM7.57087 18.7856C5.3548 18.224 3.5865 17.0097 2.26596 15.1427C0.945424 13.2758 0.285156 11.1811 0.285156 8.85879V3.30343L7.57087 0.571289L14.8566 3.30343V8.85879C14.8566 11.1811 14.1963 13.2758 12.8758 15.1427C11.5552 17.0097 9.78694 18.224 7.57087 18.7856ZM7.57087 16.8731C8.46641 16.5847 9.25949 16.1331 9.95011 15.5184C10.6407 14.9037 11.2441 14.2092 11.7602 13.4351C11.1075 13.1012 10.4282 12.847 9.72243 12.6724C9.01663 12.4979 8.29944 12.4106 7.57087 12.4106C6.8423 12.4106 6.12511 12.4979 5.41931 12.6724C4.7135 12.847 4.03426 13.1012 3.38159 13.4351C3.89766 14.2092 4.501 14.9037 5.19163 15.5184C5.88225 16.1331 6.67534 16.5847 7.57087 16.8731Z"
                                      fill=""
                                    />
                                  </svg>
                                  <p className="ml-1"> Admin </p>
                                  <img src={Arrow} className="arrow-icon" />
                                </div>
                              </button>
                            ) : (
                              <button className="sidebar-collapse-btn bg-white">
                                <div className="sidebar-list">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="19"
                                    viewBox="0 0 15 19"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.57087 10.5891C8.45123 10.5891 9.20257 10.278 9.82489 9.65566C10.4472 9.03334 10.7584 8.282 10.7584 7.40165C10.7584 6.52129 10.4472 5.76995 9.82489 5.14763C9.20257 4.52531 8.45123 4.21415 7.57087 4.21415C6.69051 4.21415 5.93917 4.52531 5.31685 5.14763C4.69453 5.76995 4.38337 6.52129 4.38337 7.40165C4.38337 8.282 4.69453 9.03334 5.31685 9.65566C5.93917 10.278 6.69051 10.5891 7.57087 10.5891ZM7.57087 18.7856C5.3548 18.224 3.5865 17.0097 2.26596 15.1427C0.945424 13.2758 0.285156 11.1811 0.285156 8.85879V3.30343L7.57087 0.571289L14.8566 3.30343V8.85879C14.8566 11.1811 14.1963 13.2758 12.8758 15.1427C11.5552 17.0097 9.78694 18.224 7.57087 18.7856ZM7.57087 16.8731C8.46641 16.5847 9.25949 16.1331 9.95011 15.5184C10.6407 14.9037 11.2441 14.2092 11.7602 13.4351C11.1075 13.1012 10.4282 12.847 9.72243 12.6724C9.01663 12.4979 8.29944 12.4106 7.57087 12.4106C6.8423 12.4106 6.12511 12.4979 5.41931 12.6724C4.7135 12.847 4.03426 13.1012 3.38159 13.4351C3.89766 14.2092 4.501 14.9037 5.19163 15.5184C5.88225 16.1331 6.67534 16.5847 7.57087 16.8731Z"
                                      fill=""
                                    />
                                  </svg>
                                  <p className="ml-1"> Admin </p>
                                  <img
                                    src={Arrow}
                                    className="arrow-icon transform-180"
                                  />
                                </div>
                              </button>
                            )
                          }
                        </AccordionItemState>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </AccordionItem>
                )}

                {/* Help */}
                <MDBDropdown dropend group className="w-100">
                  <MDBDropdownToggle className="help-btn">
                    Help
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className="w-100 border-none">
                    <div className="card-body-help">
                      <ul className="siderbar-inner-ul">
                        <a
                          href="https://predeployment.curlight.com/data/api-docs"
                          target="_blank"
                          onClick={eventTrack(
                            "Navigate-Api-Docs-Action",
                            "Navigate-Api-Docs",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          )}
                        >
                          <img className="help-icons" src={ApiImg} />
                          API Documentation
                        </a>
                        <a
                          href="mailto:support@bidlight.com"
                          target="_blank"
                          onClick={eventTrack(
                            "Navigate-Contact-Support-Action",
                            "Navigate-Contact-Support",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          )}
                        >
                          <img className="help-icons" src={SupportImg} />{" "}
                          Contact Support
                        </a>
                        <a
                          href="/learning/articles"
                          target="_blank"
                          onClick={eventTrack(
                            "Navigate-Learning-Action",
                            "Navigate-Learning",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          )}
                        >
                          <img className="help-icons" src={LearningImg} />{" "}
                          Learning
                        </a>
                        <a href="/learning" target="_blank">
                          <img className="help-icons" src={FAQImg} /> FAQ
                        </a>
                        <li
                          onClick={() => {
                            setModal("DownloadSoftware");
                            {
                              eventTrack(
                                "Download-Software-Action",
                                "Download-Software",
                                history.location.pathname,
                                "+1",
                                false,
                                "+1"
                              );
                            }
                          }}
                        >
                          <img className="help-icons" src={DownloadImg} />{" "}
                          Download Software
                        </li>
                      </ul>
                    </div>
                  </MDBDropdownMenu>
                </MDBDropdown>
                <Modal
                  title={
                    userInfoRedux?.companyAdmin
                      ? "Acquire License"
                      : "Request Access"
                  }
                  open={requestModal}
                  footer={null}
                  onCancel={() => setRequestModal(false)}
                >
                  <RequestCard
                    status={featureRequestRedux?.includes(requestType)}
                    loading={loading}
                    icon={
                      featureRequestRedux?.includes(requestType) ? (
                        <ClockCircleOutlined style={{ color: "black" }} />
                      ) : (
                        <InfoCircleOutlined style={{ color: "black" }} />
                      )
                    }
                    title={
                      featureRequestRedux?.includes(requestType)
                        ? `Request Already Sent`
                        : `Oops, looks like you need to ${
                            userInfoRedux?.companyAdmin
                              ? `Acquire License`
                              : `Request Access`
                          }`
                    }
                    description={
                      featureRequestRedux?.includes(requestType)
                        ? `Please reach out to your project Admin`
                        : `Elevate your experience with exclusive futures, early releases, and premium features.`
                    }
                    buttonText={
                      userInfoRedux?.companyAdmin
                        ? "Acquire License"
                        : "Request Access"
                    }
                    buttonHandler={() => requestAccess()}
                  />
                </Modal>
                {/*  */}
              </div>
            </Accordion>
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default NewDrawer;
