import React from "react";
import Main from "./main";
import Header from "./header";
import FooterComponent from "components/LandingPageTemplateComponents/FooterComponent";

const Layout = ({ anonymous, userInfoRedux, children }) => {
  return (
    <div style={{background: '#f6f7fb'}}>
      {/* <Header anonymous={anonymous} /> */}
      <Main>{children}</Main>
      <div className="mt-3">
      <FooterComponent />
      </div>
    </div>
  );
};

export default Layout;
