import React from "react";

export const ProfileIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6667 20.166H16.8333V18.3327C16.8333 16.8139 15.6022 15.5827 14.0833 15.5827H8.58333C7.06455 15.5827 5.83333 16.8139 5.83333 18.3327V20.166H4V18.3327C4 15.8014 6.05203 13.7493 8.58333 13.7493H14.0833C16.6146 13.7493 18.6667 15.8014 18.6667 18.3327V20.166ZM11.3333 11.916C8.29577 11.916 5.83333 9.45357 5.83333 6.41602C5.83333 3.37845 8.29577 0.916016 11.3333 0.916016C14.3709 0.916016 16.8333 3.37845 16.8333 6.41602C16.8333 9.45357 14.3709 11.916 11.3333 11.916ZM11.3333 10.0827C13.3583 10.0827 15 8.44106 15 6.41602C15 4.39097 13.3583 2.74935 11.3333 2.74935C9.30829 2.74935 7.66667 4.39097 7.66667 6.41602C7.66667 8.44106 9.30829 10.0827 11.3333 10.0827Z"
        fill="#9C9C9C"
      />
    </svg>
  );
};

export const FilledProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
    >
      <path
        d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
        fill="white"
      />
    </svg>
  );
};
