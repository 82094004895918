import moment from "moment";
import React, { useState, useEffect } from "react";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { useHistory } from "react-router";
import { eventTrack } from "helpers/ga4Helper";

export default function ActivityLog({ data, loading }) {
  const [logdata, setLogData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setLogData(data);
  }, [data]);

  const PACKAGE_NAME_CONSTANT = [
    {
      name: "General",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M1.6 16C1.16 16 0.783333 15.8351 0.47 15.5053C0.156667 15.1754 0 14.7789 0 14.3158V5.05263C0 4.58947 0.156667 4.19298 0.47 3.86316C0.783333 3.53333 1.16 3.36842 1.6 3.36842H4.8V1.68421C4.8 1.22105 4.95667 0.824561 5.27 0.494737C5.58333 0.164912 5.96 0 6.4 0H9.6C10.04 0 10.4167 0.164912 10.73 0.494737C11.0433 0.824561 11.2 1.22105 11.2 1.68421V3.36842H14.4C14.84 3.36842 15.2167 3.53333 15.53 3.86316C15.8433 4.19298 16 4.58947 16 5.05263V14.3158C16 14.7789 15.8433 15.1754 15.53 15.5053C15.2167 15.8351 14.84 16 14.4 16H1.6ZM6.4 3.36842H9.6V1.68421H6.4V3.36842Z"
            fill=""
          />
        </svg>
      ),
    },
    {
      name: "ProjectManagement",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M1.6 16C1.16 16 0.783333 15.8351 0.47 15.5053C0.156667 15.1754 0 14.7789 0 14.3158V5.05263C0 4.58947 0.156667 4.19298 0.47 3.86316C0.783333 3.53333 1.16 3.36842 1.6 3.36842H4.8V1.68421C4.8 1.22105 4.95667 0.824561 5.27 0.494737C5.58333 0.164912 5.96 0 6.4 0H9.6C10.04 0 10.4167 0.164912 10.73 0.494737C11.0433 0.824561 11.2 1.22105 11.2 1.68421V3.36842H14.4C14.84 3.36842 15.2167 3.53333 15.53 3.86316C15.8433 4.19298 16 4.58947 16 5.05263V14.3158C16 14.7789 15.8433 15.1754 15.53 15.5053C15.2167 15.8351 14.84 16 14.4 16H1.6ZM6.4 3.36842H9.6V1.68421H6.4V3.36842Z"
            fill=""
          />
        </svg>
      ),
    },

    {
      name: "ModelHealthChecker",
      img: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 2C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H6C5.44772 22 5 21.5523 5 21V19H3V17H5V15H3V13H5V11H3V9H5V7H3V5H5V3C5 2.44772 5.44772 2 6 2H20ZM14 8H12V11H9V13H11.999L12 16H14L13.999 13H17V11H14V8Z"
            fill=""
          />
        </svg>
      ),
    },

    {
      name: "EstimationManagement",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M1.6 16C1.16 16 0.783333 15.8351 0.47 15.5053C0.156667 15.1754 0 14.7789 0 14.3158V5.05263C0 4.58947 0.156667 4.19298 0.47 3.86316C0.783333 3.53333 1.16 3.36842 1.6 3.36842H4.8V1.68421C4.8 1.22105 4.95667 0.824561 5.27 0.494737C5.58333 0.164912 5.96 0 6.4 0H9.6C10.04 0 10.4167 0.164912 10.73 0.494737C11.0433 0.824561 11.2 1.22105 11.2 1.68421V3.36842H14.4C14.84 3.36842 15.2167 3.53333 15.53 3.86316C15.8433 4.19298 16 4.58947 16 5.05263V14.3158C16 14.7789 15.8433 15.1754 15.53 15.5053C15.2167 15.8351 14.84 16 14.4 16H1.6ZM6.4 3.36842H9.6V1.68421H6.4V3.36842Z"
            fill=""
          />
        </svg>
      ),
    },
    {
      name: "DocControl",
      img: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="800px"
          height="800px"
          viewBox="-2 0 32 32"
          version="1.1"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Icon-Set-Filled"
              transform="translate(-208.000000, -101.000000)"
              fill="#000000"
            >
              <path
                d="M231,109 C229.935,109 229,108.039 229,107 L229,103.031 L229,103.029 L234,109 L231,109 L231,109 Z M229,117.016 L220,117.016 C219.448,117.016 219,116.568 219,116.017 C219,115.465 219.448,115.018 220,115.018 L229,115.018 C229.552,115.018 230,115.465 230,116.017 C230,116.568 229.552,117.016 229,117.016 L229,117.016 Z M229,122.011 L220,122.011 C219.448,122.011 219,121.563 219,121.012 C219,120.46 219.448,120.013 220,120.013 L229,120.013 C229.552,120.013 230,120.46 230,121.012 C230,121.563 229.552,122.011 229,122.011 L229,122.011 Z M227,131 L212,131 C210.95,131 210,130.019 210,129 L210,109 C210,107.513 211.224,107 213,107 L213,125 C213,127.059 214.884,129 217,129 C217,129 227.842,128.989 229.009,128.989 C229.009,130.202 228.213,131 227,131 L227,131 Z M229,101.031 C228.876,101.031 217,101 217,101 C214.884,101 213,102.941 213,105 L211.845,105.027 C209.729,105.027 208,106.941 208,109 L208,129 C208,131.059 209.884,133 212,133 L227,133 C229.116,133 231,131.059 231,129 L232,129 C234.116,129 236,127.059 236,125 L236,109.023 L229,101.031 L229,101.031 Z"
                id="multiple-documents-files"
              ></path>
            </g>
          </g>
        </svg>
      ),
    },
  ];

  const findPackageImage = (packageName) => {
    const res = PACKAGE_NAME_CONSTANT?.find(
      (item) => item?.name === packageName
    )?.img;

    return res;
  };

  return (
    <div className="dashboard-cards card border-0 mb-7">
      <div className="d-flex justify-content-between align-items-center">
        <p className="chart-header">Activity Log </p>
        <span
          className="small-redirect-text ml-auto mb-0 mr-2"
          onClick={() => {history.push("./activity-log")
            eventTrack(
              "View-All-ActivityLog-Action",
              "View-All-ActivityLog",
              'View-All-ActivityLog',
              "+1",
              false,
              "+1"
            );
          }}
        >
          View all
          <i className="fa fa-arrow-right ml-1"></i>
        </span>
      </div>
      <div className="table-responsive">
        {loading ? (
          <div
            style={{
              height: "290px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i className="fa fa-spinner small-spinner" />
          </div>
        ) : (
          <table className="table table-hover table-nowrap bid-table border-top-none">
            <tbody>
              {logdata?.slice(-9).map((item, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="activity-img">
                          {findPackageImage(item?.packageName)}
                        </div>
                        <a className="text-heading font-semibold" href="#">
                          {item?.task}
                        </a>
                      </div>
                    </td>
                    <td className="ml-auto">
                      <div className="text-right">
                        <p className="mb-0">
                          {formatDistanceToNowStrict(new Date(item?.date), {
                            addSuffix: true,
                          })}
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
