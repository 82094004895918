import React, { useEffect, useState, useMemo } from "react";
import { MDBIcon } from "mdbreact";
import "./style.scss";
import { eventTrack } from "helpers/ga4Helper";

let numeral = require("numeral");

const RenderItem = ({ i, item, priceView, currencysymb, timeIcon }) => {
  const itemRows = [
    <tr className="item-child"  key={"row-data-" + i}>
      {/* <td size="sm" style={{width:'30px'}}>   </td>  */}
      <td  colSpan="25%" width="200px">{item.Code?.substring(0,30)}</td>
      <td  colSpan="25%" width="250px">{item.Description?.substring(0,30)}</td>
      {/*}  <td size="sm">{numeral(item.InflationRate).format('0,0.00')}</td>*/}
      {/*}  <td size="sm">{numeral(item.InflationValue).format('0,0.00')}</td>		*/}
      {/*}  <td size="sm">{numeral(item.MaxTotalCost).format('0,0.00')}</td>	*/}
      {/*}  <td size="sm">{numeral(item.MinTotalCos).format('0,0.00')}</td> */}
      {timeIcon &&
      <td  colSpan="25%" width="250px">
        {numeral(item.TotalTime).format(`0,0.00`)} hours
      </td>
      }
           {priceView && (
        <td  colSpan="25%" width="250px" >
         {numeral(item.TotalCost).format(`${currencysymb}0,0.00`)}
        </td>
      )}
      {/*} <td size="sm">{numeral(item.TotalCostWithoutInflation).format('0,0.00')}</td>	*/}
      {/*} <td size="sm">{item.Amount}</td> */}
    </tr>,
  ];

  return itemRows;
};


const CollapsibleEstimate = (props) => {
  const { currencysymb } = props;
  const [allItemRows, setAllItemRowss] = useState([]);
  const [isExpanded, setExpand] = useState(props.expanded);
  const [totalCost, setTotalCoast] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const handleRowClick = () => {
    setExpand(!isExpanded);
  };
  useMemo(() => {
    let allItem = [];
    let estimationDetails = props.data;
    let _totalCost = 0;
    let _totalTime = 0;
    if (props.data != undefined) {

      if (estimationDetails.length > 0) {
        var i = 0;
        estimationDetails.forEach((item) => {
          _totalCost += item.TotalCost;
          _totalTime += item.TotalTime

          const perItemRows = (
            <RenderItem
              key={i}
              item={item}
              priceView={props.priceView}
              currencysymb={props.currencysymb}
              timeIcon = {props.timeIcon}
            />
          );
          if (!allItem?.includes(perItemRows)) {
            i++;
            allItem.push(perItemRows);
          }
        });
      }
    }
    setAllItemRowss(allItem);
    setTotalCoast(props.type == "material" ? props.totalSelectionPrice :_totalCost);
    setTotalTime(props.type == "material" ? props.totalSelectionTime : _totalTime);
  }, [props]);

  return (
    <table className={props.myClassName + " w-100"}>
      <tbody >
        <tr
          className={`${isExpanded ? "expanded" : null} expandable`}
          size="sm"
          onClick={() => { handleRowClick()
            {
              eventTrack(
                "Expand-Action",
                "Expand-Row",
                'expanded',
                "+1",
                false,
                "+1"
              );
            }
          }
          }
          key={"row-data-header"}
        >
          <td colSpan="100%" className="total-summary" >
            <td size="sm" width="50px" >
              <MDBIcon
                className="mr-2 collapse-icon-wrap"
                icon={`${isExpanded ? "minus" : "plus"} cursor-pointer`}
                onClick={handleRowClick}
              />
            </td>
            <td size="sm" width="450px"  >
              <img src={props.icon} alt="" height={30} srcSet="" />
              {props.type}
            </td>
            <td    size="sm" width="300px"   >
              {props.timeIcon ?
                <>
                <img src={props.timeIcon} alt="" height={30} srcSet="" />
                  {numeral(totalTime).format(`0,0.00`)} hours
                </>
                  : null
              }
            </td>
            <td   size="sm" width="300px"  >
              {props.priceView
                ? <>
                  <img src={props.moneyIcon} alt="" height={30} srcSet="" />
                  {numeral(totalCost).format(`${props.currencysymb}0,0.00`)}
                </>
                : null
              }
            
            </td>
          </td>
        </tr>
        {isExpanded && <>{allItemRows}</>}
      </tbody>
    </table>
  );
};

export default CollapsibleEstimate;
