import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useSearch } from "hooks/useSearch";
import { eventTrack } from "../../helpers/ga4Helper";
import "react-input-range/lib/css/index.css";
import { setSidebarStatus } from "actions/user";
import { setRefreshParameters } from "actions/extras";

export default function EarningNavbar({ handleSearch, setRefresh, refresh}) {
  const [searchValue, setSearchValue] = useState("");
  const [updateState, setUpdateState] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const { companyAdmin, admin, company, name, lastName, email, role } =
    userInfoRedux;

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  return (
    <div className="inner-header-position parameter-header">
      <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center">
          <div
            onClick={() => dispatch(setSidebarStatus(true))}
            className="cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>
          </div>
          <p className="my-heading f-14 ml-3 mr-3">Earnings</p>
        </div>
        <div
          className="projActionButtons d-flex flex-wrap"
          style={{ maxWidth: "100vw" }}
        >
          <input
            type="text"
            className="search-bar"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
            // disabled={loading}
          />
          <div className="vr mx-3"></div>
          <div className="btn-tool-group">
            {admin || companyAdmin ? (
              <button
                onClick={() => {
                  history.push(`/admin/projects-settings`);
                  {
                    eventTrack(
                      "View-Admin-Action",
                      "View-Admin",
                      name,
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
                className="bg-bid px-3 mr-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="19"
                  viewBox="0 0 14 19"
                  fill="none"
                >
                  <path
                    d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                    fill="white"
                  />
                </svg>
                Admin
              </button>
            ) : null}

            <div className="vr mx-3"></div>

            <button
                // disabled={loading}
              onClick={() => setRefresh(!refresh)}
              className="px-3 bg-white text-bid shadow-hover"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
              >
                <path
                  d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                  fill="#3A5765"
                />
                <path
                  d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                  fill="black"
                  fillOpacity="0.2"
                />
              </svg>
              Refresh
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
