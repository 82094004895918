function findDuplicatePermissions(permissions) {
  const allPermissions = Object.values(permissions).flat();
  const uniquePermissions = new Set(allPermissions);
  const duplicates = Array.from(
    allPermissions.reduce((acc, permission) => {
      if (uniquePermissions.has(permission)) {
        uniquePermissions.delete(permission);
      } else {
        acc.add(permission);
      }
      return acc;
    }, new Set())
  );

  return duplicates;
}

export { findDuplicatePermissions };
