import {
    Matrix3,
    Vector3,
    Object3D,
    MeshBasicMaterial,
    PlaneGeometry,
    Mesh,
    DoubleSide,
} from 'three';
import { ClipPlane } from './clipPlane';


export class Clipper {
    constructor(scene, allModels, container, camera, cameraControls, orbitControls) {
        this.container = container;
        this.allModels = allModels;
        this.cameraControls = cameraControls;
        this.OrbitControls = orbitControls;
        this.camera = camera;
        this.scene = scene;
        this.orthogonalY = true;
        this.planes = [];
        this.clippingPlanes = [];
        this.toleranceOrthogonalY = 0.7;
        this.normal = undefined;
        this.tempClipperPlane = this.tempClipper();
        this.tooltip = document.getElementById("clippingTooltip");

    }

    moveTooltip() {
        const clipBtn = document.getElementById("clippingToggleBtn");

        // this.tooltip.style.left = clipBtn.getBoundingClientRect().left - 100 + "px"; // With Offset Of 20px for correct postition
        // this.tooltip.style.top = clipBtn.getBoundingClientRect().top - 100 + "px"; 
    }
    createPlane = (intersects) => {
        console.log("Creating Clipping plane");
        // if (!this.enabled)
        //     return;
        if (!intersects)
            return;
        const clippingPlane = this.createPlaneFromIntersection(intersects[0]);
        this.intersection = undefined;

        // const helper = new PlaneHelper(clippingPlane, 2, 0xFF0000);
        // this.scene.add(helper);

        // Create a TransformControls object for the X clipping plane
        console.log(this.container);
        // const clipTransformControls = new TransformControls(this.camera, this.container);
        // clipTransformControls.attach(clippingPlane);
        // this.scene.add(clipTransformControls);

        return clippingPlane;
    };
    createPlaneFromIntersection = (intersection) => {
        var _a;
        const constant = intersection.point.distanceTo(new Vector3(0, 0, 0));
        const normal = (_a = intersection.face) === null || _a === void 0 ? void 0 : _a.normal;
        if (!constant || !normal)
            return;
        const normalMatrix = new Matrix3().getNormalMatrix(intersection.object.matrixWorld);
        const worldNormal = normal.clone().applyMatrix3(normalMatrix).normalize();
        this.normal = worldNormal.negate();
        // this.normalizePlaneDirectionY(worldNormal);
        console.log("clipper", worldNormal, this.normal)
        const plane = new ClipPlane(this.scene, worldNormal, intersection, this.normal, this.container, this.camera, this.cameraControls, this.OrbitControls, this.allModels);
        this.planes.push(plane);
        this.clippingPlanes.push(plane.plane);
        this.updateMaterials();

        return plane
    };

    removeClippingPlane(plane) {
        const rootPlane = this.planes.find((p) => {
            if (p.planeMesh === plane || p.arrowBoundingBox === plane) {
                return p;
            }
            return null;
        });

        const rootIndex = this.planes.indexOf(rootPlane);
        this.planes.splice(rootIndex, 1);
        rootPlane.removefromScene();

        const index = this.clippingPlanes.indexOf(plane);
        this.clippingPlanes.splice(index, 1);

        // this.scene.remove(plane);
        this.updateMaterials();
    }

    updateMaterials = () => {
        // Apply clipping to all models
        const planes = this.clippingPlanes;
        this.allModels.forEach((model) => {
            model.children.forEach((child) => {
                if (child.type == "Group") {
                    child.children.forEach((subchild) => (subchild.material.clippingPlanes = planes));
                }
                else {
                    child.material.clippingPlanes = planes;
                }
            })
        });
        // // Applying clipping to all subsets. then we can also filter and apply only to specified subsest as parameter
        // Object.values(this.ifc.loader.ifcManager.subsets.getAllSubsets()).forEach((subset) => {
        //     const mesh = subset.mesh;
        //     if (mesh.material)
        //         this.updateMaterial(mesh, planes);
        //     if (mesh.userData.wireframe)
        //         this.updateMaterial(mesh.userData.wireframe, planes);
        // });
    };
    normalizePlaneDirectionY(normal) {
        if (this.orthogonalY) {
            if (normal.y > this.toleranceOrthogonalY) {
                normal.x = 0;
                normal.y = 1;
                normal.z = 0;
            }
            if (normal.y < -this.toleranceOrthogonalY) {
                normal.x = 0;
                normal.y = -1;
                normal.z = 0;
            }
        }
    }

    tempClipper() {

        const planeMat = new MeshBasicMaterial({
            color: 0xff0000,
            side: DoubleSide,
            transparent: true,
            opacity: 0.5
        });

        const planeGeom = new PlaneGeometry(5, 5, 1);
        const tempMesh = new Mesh(planeGeom, planeMat);


        const helper = new Object3D();
        helper.name = "temporaryClipper";


        // const scene = this.scene;
        // scene.add(helper);
        helper.add(tempMesh);

        console.log({ helper })
        return helper;
    }

    updateTempClipper(intersects) {

        const intersection = intersects[0];
        if (intersects.length == 0 || intersection.object.name === "clipingHelper" || this.scene.removeTempClipper) {
            this.scene.remove(this.tempClipperPlane);
            return;
        } else {
            this.scene.add(this.tempClipperPlane);
        }

        var _a;
        const constant = intersection.point.distanceTo(new Vector3(0, 0, 0));
        const normal = (_a = intersection.face) === null || _a === void 0 ? void 0 : _a.normal;
        if (!constant || !normal)
            return;
        const normalMatrix = new Matrix3().getNormalMatrix(intersection.object.matrixWorld);
        const worldNormal = normal.clone().applyMatrix3(normalMatrix).normalize();
        const tempNormal = worldNormal.negate();
        // this.normalizePlaneDirectionY(worldNormal);

        // this.tempClipperPlane.setFromNormalAndCoplanarPoint(tempNormal, intersection.point);

        // reference: https://discourse.threejs.org/t/aligning-objects-faces-using-intersects/10445/6
        const n = intersection.face.normal.clone();
        n.transformDirection(intersection.object.matrixWorld);
        // n.multiplyScalar(10);
        n.add(intersection.point);
        // this.normalizePlaneDirectionY(n);

        // intersection.normal.copy(intersection.face.normal);
        // n.y = 1
        this.tempClipperPlane.lookAt(n);

        // this.tempClipperPlane.lookAt(intersection.face.normal);
        this.tempClipperPlane.position.copy(intersection.point);

    }

    updateTooltip(intersects = []) {
        this.moveTooltip();

        if (intersects.length != 0 && intersects[0]?.object.name === "clipingHelper") {
            this.tooltip.innerText = "Click to Delete this plane"
        } else {
            if (this.clippingPlanes.length > 0) {
                this.tooltip.innerText = "Click an existing Clipping plane to delete"
            } else {
                this.tooltip.innerText = "Click on any face to create a Clipping Plane"
            }
        }
    }
}