import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { extractImageFromZip } from "../../../utils/imageHandler";

import { useAuthAxios } from "../../../store/fetch";

import Tag from "../../tag";

import styles from "./post-summary.module.css";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

const PostSummary = ({
  tags,
  author,
  created,
  questionId,
  answerId,
  setQuestion,
  children,
}) => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const router = useHistory();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const authAxios = useAuthAxios();


  const handleDeleteComment = async () => {
    const res = window.confirm("Are you sure delete your post?");
    if (res) {
      const { data } = await authAxios.delete(
        answerId
          ? `/answer/${questionId}/${answerId}`
          : `/question/${questionId}`
      );

      if (answerId) {
        setQuestion(data);
      } else {
        router.push("/learning");
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const extractedProfilePhoto = await extractImageFromZip(
        author.profilePhoto
      );
      setProfilePhoto(extractedProfilePhoto);
    };
    fetchUser();
  }, [author]);

  return (
    <div className={styles.postCell}>
      <div className={styles.text}>{children}</div>
      <div className={styles.footer}>
        <div className={styles.row}>
          <div className={styles.tagContainer}>
            {tags?.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </div>
          <div className={styles.userDetails}>
            <Link to="/users/[user]" as={`/users/${author.username}`}>
              <a>
                <img src={profilePhoto} alt={author.username} />
              </a>
            </Link>
            <div className={styles.info}>
              <span>
                {tags ? "asked" : "answered"}{" "}
                {formatDistanceToNowStrict(new Date(created), {
                  addSuffix: true,
                })}
              </span>
              <Link to="/users/[user]" as={`/users/${author.username}`}>
                <a>
                  {author.name} {author.lastName}
                </a>
              </Link>
            </div>
          </div>
        </div>
        {(userInfoRedux?.id === author._id ||
          userInfoRedux?.companyAdmin ||
          userInfoRedux?.admin) && (
          <div className={styles.row}>
            <a className={styles.delete} onClick={() => handleDeleteComment()}>
              <i className="fa fa-trash mr-2" /> delete 
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostSummary;
