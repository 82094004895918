import { get } from "../index";

export const getParameters = (data) => {
  let apiConfig = `?companyId=${data?.companyId}`;

  if (data?.index !== 'undefined' ) {
    apiConfig = apiConfig + `&index=${data?.index}`;
  }

  if (data?.limit) {
    apiConfig = apiConfig.includes("?")
      ? apiConfig + `&limit=${data?.limit}`
      : `?limit=${data?.limit}`;
  }
  if (data?.type) {
    apiConfig = apiConfig + `&parameterType=${data?.type}`;
  }
  if (data?.search) {
    apiConfig = apiConfig.includes("?")
      ? apiConfig + `&search=${data?.search}`
      : `?search=${data?.search}`;
  }

  return get(`/parameters` + apiConfig);
};
