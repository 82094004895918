import React, { useState, useEffect, useCallback } from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { DownArrowIcon } from "assets/svg-icons/Arrows";
import {
  GreenTrendIcon,
  RedTrendIcon,
  BlueTrendIcon,
  YelloTrendIcon,
} from "assets/svg-icons/Trend";
import { useDispatch, useSelector } from "react-redux";
import { LeftArrowIcon } from "assets/svg-icons/Arrows";
import handleBuildTree from "helpers/buildTree";
import useDebounce from "hooks/useDebounce";
import {
  clearCategories,
  setExpandedCatalogsDivisons,
  addExpandedCatalogsDivision,
} from "actions/pricing";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import bidlightLogo from "assets/images/logo/logo-blue.png";
import onebuildLogo from "assets/images/logo/1build-logo.png";
import craftsmanLogo from "assets/images/logo/craftsman-logo.png";
import defaultCompanyLogo from "assets/images/logo/CompanyLeadingIcon.png";

export const CollapseAbleTable = ({
  units,
  conversionRate,
  catalogs,
  getCategoriesWithSearch,
  searchCategoryQuery,
  setSearchCategoryQuery,
  searchCategoryColumn,
  setSearchCategoryColumn,
  page,
  setPage,
  setSelectedCatalog,
  selectedCatalog,
  appliedTemplate,
  setSearchedCatalogs,
  setSelectedCatalogsIds,
  selectedCatalogsIds,
  isOneBuildAllowed,
  dataTypes,
}) => {
  const dispatch = useDispatch();
  const awpsData = useSelector((s) => s.pricing.awps);
  const categoryData = useSelector((s) => s.pricing.categories);
  const allCategoryData = useSelector((s) => s.pricing.allCategories);
  const expandedItems = useSelector((s) => s.pricing.expandedCatalogsDivisions);
  const catalogsData = useSelector((s) => s.user.catalogs);
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const { admin, logo } = userInfoRedux;
  const [logoURL, SetLogoURL] = useState("");

  const [allUnitsOptions, setAllUnitOptions] = useState(units);
  const [allAwpOptions, setAllAwpOptions] = useState(awpsData);
  const debouncedSearchTerm = useDebounce(searchCategoryQuery, 300);
  const [companyUnitCosts, setCompanyUnitCosts] = useState({});
  const [catalogsIsPublic, setCatalogsIsPublic] = useState({});

  useEffect(() => {
    handleCategorySearch();
  }, [debouncedSearchTerm]);

  useEffect(()=> {
    (async () => {
      const extractedImg = await extractImageFromZip(
        logo
      );
      SetLogoURL(extractedImg);
    })();
  }, [])

  const handlePageChange = () => {
    const newPage = page + 1;
    setPage(newPage);
    const skip = newPage * 50;
    const columnName = searchCategoryQuery.length ? "DisplayName" : "";
    getCategoriesWithSearch({
      skip,
      limit: 50,
      search: searchCategoryQuery,
      column: columnName,
    });
  };

  const singleCatalogs = [];

  const getAllCatalogs = (allCatalogs) => {
    allCatalogs.forEach((cat) => {
      singleCatalogs.push(cat);
      if (cat.Children.length) {
        getAllCatalogs(cat.Children);
      }
    });
  };

  const handleSelectAllCatalogs = (event) => {
    if (event?.target?.checked) {
      getAllCatalogs(catalogs);
      const allCatalogsIds = singleCatalogs.map((cat) => cat?._id);
      setSelectedCatalogsIds(allCatalogsIds);
      setSelectedCatalog(singleCatalogs);
    } else {
      setSelectedCatalogsIds([]);
      setSelectedCatalog([]);
    }
  };

  const handleMakeCatalogsToPublic = (event, item, singleToUpdate = false) => {
    const isPublicValue = event?.target?.checked;
    let updatedItems = [...catalogsData];
    if (singleToUpdate) {
      const itemId = item.Division;
      setCatalogsIsPublic((prevItem) => ({
        ...prevItem,
        [itemId]: isPublicValue,
      }));
      updatedItems = updatedItems.map((catalog) => {
        if (catalog.company.Code === item.Division) {
          catalog.company.isPublic = isPublicValue;
        }
        return catalog;
      });
    } else {
      updatedItems = catalogsData.map((catalog) => {
        catalog.company.isPublic = isPublicValue;
        return catalog;
      });
    }
    getCatalogsTreeData(updatedItems);
  };

  const handleCategorySearch = useCallback(() => {
    const columnName = searchCategoryQuery.length ? "DisplayName" : "";
    setSearchCategoryColumn(columnName);
    getCategoriesWithSearch({
      skip: 0,
      limit: 50,
      search: searchCategoryQuery,
      column: columnName,
    });
  }, [searchCategoryQuery, searchCategoryColumn]);

  const toggleAccordion = (code) => {
    if (expandedItems?.includes(code)) {
      const expandedItemLeft = expandedItems.filter((item) => item !== code);
      dispatch(setExpandedCatalogsDivisons(expandedItemLeft));
    } else {
      dispatch(addExpandedCatalogsDivision(code));
    }
  };

  // const findAllParentsOfSelectedItems = (selectedItems) => {
  //   const allParents = [];
  //   for (const item of selectedItems) {
  //     let parent = item?.Parent;
  //     while (parent) {
  //       allParents.push(parent);
  //       parent = parent.Parent;
  //     }
  //   }
  //   return allParents;
  // };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      const checkedCatalogs = [...selectedCatalog, item];
      const checkedCatalogsIds = checkedCatalogs.map((catalog) => catalog?._id);
      setSelectedCatalogsIds(checkedCatalogsIds);
      setSelectedCatalog(checkedCatalogs);
    } else {
      const unCheckedItem = selectedCatalog.filter((c) => c?._id !== item?._id);
      const uncheckedCatalogsIds = unCheckedItem.map((catalog) => catalog?._id);
      setSelectedCatalog(unCheckedItem);
      setSelectedCatalogsIds(uncheckedCatalogsIds);
    }
  };

  // Multiple selection case implemented to use in future
  // const handleCheckboxChange = (event, accordionItem) => {
  //   if (event.target.checked) {
  //     const selectedItems = [
  //       accordionItem,
  //       ...findAllParentsOfSelectedItems([accordionItem]),
  //     ];
  //     const allParents = findAllParentsOfSelectedItems([selectedItems]);
  //     const updatedSelectedCatalog = [...selectedCatalog];
  //     [...selectedItems, ...allParents].forEach((item) => {
  //       if (
  //         !updatedSelectedCatalog.some(
  //           (selectedItem) => selectedItem._id === item._id
  //         )
  //       ) {
  //         updatedSelectedCatalog.push(item);
  //       }
  //     });
  //     const allSelectedItemsIds = updatedSelectedCatalog.map(
  //       (item) => item?._id
  //     );
  //     setSelectedCatalogsIds(allSelectedItemsIds);
  //     setSelectedCatalog(updatedSelectedCatalog);
  //     console.log("allSelectedItemsIds**", allSelectedItemsIds, updatedSelectedCatalog);
  //   } else {
  //     // Unchecking an accordion item
  //     const unCheckedItem = selectedCatalog.filter(
  //       (c) => c?._id !== accordionItem?._id
  //     );
  //     setSelectedCatalog(unCheckedItem);

  //     // Find all children of the unchecked item
  //     const allChildren = findAllParentsOfSelectedItems([accordionItem]);

  //     // Remove all children from the selectedCatalog state
  //     const updatedSelectedCatalog = selectedCatalog.filter(
  //       selectedItem => !allChildren.some(child => child._id === selectedItem._id)
  //     );
  //     setSelectedCatalog(updatedSelectedCatalog);
  //   }
  // };

  const handlePriceChange = (item, newPrice) => {
    const itemId = item?.Division;
    setCompanyUnitCosts((prevCosts) => ({
      ...prevCosts,
      [itemId]: newPrice,
    }));
    const updatedItems = [...catalogsData];
    const dataToUpdate = updatedItems.find((catalog) => {
      if (catalog.company.Code === item.Division) return catalog;
    });
    dataToUpdate.company.UnitCost = Number(newPrice) / conversionRate;
    getCatalogsTreeData(updatedItems);
  };

  const handleUnitChange = (item, unit) => {
    const updatedItems = [...catalogsData];
    const dataToUpdate = updatedItems.find(
      (catalog) => catalog.company._id === item._id
    );
    dataToUpdate.company.Unit = unit;
    getCatalogsTreeData(updatedItems);
  };

  const handleAwpChange = (item, awp) => {
    const updatedItems = [...catalogsData];
    const dataToUpdate = updatedItems.find(
      (catalog) => catalog.company._id === item._id
    );
    dataToUpdate.company.Awp = awp;
    getCatalogsTreeData(updatedItems);
  };

  const handleCategoryChange = (item, category) => {
    let updatedItems = [...catalogsData];
    const dataToUpdateIndex = updatedItems.findIndex(
      (catalog) => catalog.company._id === item._id
    );
    const dataToUpdate = updatedItems[dataToUpdateIndex];
    dataToUpdate.company.CategoryId = [
      (category.company ?? category.default).Key,
    ];
    updatedItems[dataToUpdateIndex] = dataToUpdate;
    getCatalogsTreeData(updatedItems);
  };

  const getCatalogsTreeData = (updatedItems) => {
    const catalogsDataTree = handleBuildTree(
      updatedItems,
      admin,
      appliedTemplate.company.Protocol
    );

    setSearchedCatalogs(catalogsDataTree.Children);
  };

  const RenderTableRow = (item, index) => {
    const isExpandedItemExist = expandedItems.includes(item.Division);
    const databseCatelogCategory = allCategoryData?.find(
      (category) =>
        (category.company ?? category.default).Key ===
        item.Database?.CategoryId?.at(0)
    );
    const companyCatelogCategory = allCategoryData?.find(
      (category) =>
        (category.company ?? category.default).Key === item.CategoryId?.at(0)
    );
    const oneBuildRow = item.oneBuild;
    const craftsmanRow = item.craftsman;
    const companyUnitCost = item.unitCost * conversionRate; //company
    const databaseUnitCost = item.Database.UnitCost * conversionRate; //bidlight
    const craftsmanUnitCost = craftsmanRow?.UnitCost // craftsman
      ? craftsmanRow?.UnitCost * conversionRate
      : 0;

    const oneBuildUnitCost = oneBuildRow?.UnitCost //oneBuild
      ? oneBuildRow?.UnitCost * conversionRate
      : 0;

    const trendResult = () => {
      const highestCost = { Icon: <RedTrendIcon />, class: "highestUnitCost" };
      const secondHighestCost = {
        Icon: <YelloTrendIcon />,
        class: "secondHighestUnitCost",
      };
      const middleCost = { Icon: <BlueTrendIcon />, class: "middleUnitCost" };
      const lowestCost = { Icon: <GreenTrendIcon />, class: "lowestUnitCost" };

      let trendsResults = {
        company: { Icon: "", class: "" },
        oneBuild: { Icon: "", class: "" },
        bidlight: { Icon: "", class: "" },
        craftsman: { Icon: "", class: "" },
      };

      if (
        oneBuildUnitCost === databaseUnitCost &&
        databaseUnitCost === companyUnitCost &&
        companyUnitCost === craftsmanUnitCost
      ) {
        return {
          bidlight: lowestCost,
          oneBuild: lowestCost,
          company: lowestCost,
          craftsman: lowestCost,
        };
      }
      let coreConditions = [
        { name: "company", cost: companyUnitCost },
        { name: "oneBuild", cost: oneBuildUnitCost },
        { name: "bidlight", cost: databaseUnitCost },
        { name: "craftsman", cost: craftsmanUnitCost },
      ].sort((a, b) => a.cost - b.cost);
      const lowestComposed = coreConditions[0];
      const highestComposed = coreConditions[3];
      if (dataTypes.length > 0) {
        const rest = coreConditions
          .filter(
            (x) =>
              x.name !== lowestComposed.name && x.name !== highestComposed.name
          )
          .sort((a, b) => a.cost - b.cost);
        const midLowComposed = rest[0];
        const midHighestComposed = rest[1];
        if (
          midHighestComposed.cost === midLowComposed.cost &&
          midLowComposed.cost === lowestComposed.cost
        ) {
          trendsResults[highestComposed.name] = highestCost;
          trendsResults[lowestComposed.name] =
            trendsResults[midHighestComposed.name] =
            trendsResults[midLowComposed.name] =
              lowestCost;
        } else if (
          midHighestComposed.cost === midLowComposed.cost &&
          midLowComposed.cost === highestComposed.cost
        ) {
          trendsResults[highestComposed.name] =
            trendsResults[midHighestComposed.name] =
            trendsResults[midLowComposed.name] =
              highestCost;
          trendsResults[lowestComposed.name] = lowestCost;
        } else if (
          highestComposed.cost === midHighestComposed.cost &&
          midLowComposed.cost === lowestComposed.cost
        ) {
          trendsResults[highestComposed.name] = trendsResults[
            midHighestComposed.name
          ] = highestCost;
          trendsResults[lowestComposed.name] = trendsResults[
            midLowComposed.name
          ] = lowestCost;
        } else if (
          highestComposed.cost === midHighestComposed.cost &&
          midLowComposed.cost > lowestComposed.cost
        ) {
          trendsResults[highestComposed.name] = trendsResults[
            midHighestComposed.name
          ] = highestCost;
          trendsResults[lowestComposed.name] = lowestCost;
          trendsResults[midLowComposed.name] = middleCost;
        } else if (
          highestComposed.cost > midHighestComposed.cost &&
          midLowComposed.cost === lowestComposed.cost
        ) {
          trendsResults[highestComposed.name] = highestCost;
          trendsResults[midHighestComposed.name] = middleCost;
          trendsResults[lowestComposed.name] = trendsResults[
            midLowComposed.name
          ] = lowestCost;
        } else if (midHighestComposed.cost === midLowComposed.cost) {
          trendsResults[highestComposed.name] = highestCost;
          trendsResults[midHighestComposed.name] = trendsResults[
            midLowComposed.name
          ] = middleCost;
          trendsResults[lowestComposed.name] = lowestCost;
        } else {
          trendsResults[highestComposed.name] = highestCost;
          trendsResults[midHighestComposed.name] = secondHighestCost;
          trendsResults[midLowComposed.name] = middleCost;
          trendsResults[lowestComposed.name] = lowestCost;
        }
      } else {
        trendsResults.company = lowestCost;
      }
      return trendsResults;
    };

    const trendsResponse = trendResult();

    const oneBuildCategory = oneBuildRow?.CategoryId?.at(0);

    return (
      <React.Fragment key={"catlog_item_" + item._id + "-" + index}>
        <tr>
          {/* level */}
          <td
          // rowSpan={`${
          //   dataTypes.length === 1 ? 2 : dataTypes.length === 2 ? 3 : 1
          // }`}
          >
            {item.Level}
          </td>

          {/* div */}
          <td
          // rowSpan={`${
          //   dataTypes.length === 1 ? 2 : dataTypes.length === 2 ? 3 : 1
          // }`}
          >
            <div
              className={`d-flex gap-3 align-items-center Div ml-${item.Level}`}
            >
              {item.Children?.length > 0 ? (
                <div
                  className="cursor-pointer"
                  onClick={() => toggleAccordion(item.Division)}
                  aria-expanded={expandedItems.includes(item.Division)}
                  aria-controls={`collapse-${item.Division}`}
                >
                  {isExpandedItemExist ? <DownArrowIcon /> : <LeftArrowIcon />}
                </div>
              ) : (
                <div className="ml-4"></div>
              )}
              <input
                type="checkbox"
                role="switch"
                id={`flexSwitchCheckChecked${index}`}
                checked={selectedCatalogsIds.includes(item?._id)}
                onChange={(event) => {
                  handleCheckboxChange(event, item);
                }}
              />
              {item.Division}
            </div>
          </td>

          {/* description */}
          <td
          // rowSpan={`${
          //   dataTypes.length === 1 ? 2 : dataTypes.length === 2 ? 3 : 1
          // }`}
          >
            {item.Description}
          </td>

          {admin && (
            <td>
              <div className="d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  role="switch"
                  id={`flexSwitchCheckChecked${index}`}
                  checked={catalogsIsPublic[item.Division] || item?.isPublic}
                  onChange={(event) => {
                    handleMakeCatalogsToPublic(event, item, true);
                  }}
                />
              </div>
            </td>
          )}

          {/* Trend */}
          <td className="p-0">
            <div>
              {dataTypes.includes("bidlight") && (
                <div className="trend-div p-2 flex-overallCenter">
                  {trendsResponse?.bidlight.Icon}
                </div>
              )}

              {dataTypes.includes("oneBuild") && (
                <div className="trend-div p-2 flex-overallCenter">
                  {trendsResponse?.oneBuild.Icon}
                </div>
              )}
              {dataTypes.includes("craftsman") && (
                <div className="trend-div p-2 flex-overallCenter">
                  {trendsResponse?.craftsman.Icon}
                </div>
              )}
              <div className="p-2 flex-overallCenter">
                {trendsResponse?.company.Icon}
              </div>
            </div>
          </td>

          {/* Company */}
          <td className="p-0">
            {dataTypes.includes("bidlight") && (
              <div className="defaultCompany flex-overallCenter border-bottom unit-cost-height">
                <img src={bidlightLogo} className="bidlight-logo" />
              </div>
            )}
            {dataTypes.includes("oneBuild") && (
              <div className="defaultCompany flex-overallCenter border-bottom unit-cost-height">
                <div className="defaultCompany flex-overallCenter border-bottom unit-cost-height">
                  <img src={onebuildLogo} className="oneBuild-logo" />
                </div>
              </div>
            )}
            {dataTypes.includes("craftsman") && (
              <div className="defaultCompany flex-overallCenter border-bottom unit-cost-height">
                <div className="defaultCompany flex-overallCenter border-bottom unit-cost-height">
                  <div
                    style={{
                      backgroundColor: "#3A5765",
                      width: "25px",
                      height: "25px",
                      position: "relative",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{
                        display: "block",
                        mixBlendMode: "luminosity",
                        width: "25px",
                      }}
                      src={craftsmanLogo}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="company flex-overallCenter unit-cost-height">
              <div className="defaultCompany flex-overallCenter border-bottom unit-cost-height">
                <img
                  src={
                    logoURL && logoURL?.length > 0
                      ? logoURL
                      : defaultCompanyLogo
                  }
                  style={{width: "30px"}}
                />
              </div>
            </div>
          </td>

          {/* Unit cost */}
          <td className="p-0">
            {dataTypes.includes("bidlight") && (
              <div
                className={`unit-cost-height flex-overallCenter border-bottom defaultCompany m-auto ${trendsResponse?.bidlight.class}`}
              >
                {`${
                  isOneBuildAllowed
                    ? oneBuildUnitCost.toFixed(2)
                    : databaseUnitCost?.toFixed(2)
                }`}
              </div>
            )}

            {dataTypes.includes("oneBuild") && (
              <div
                className={`unit-cost-height flex-overallCenter border-bottom defaultCompany m-auto ${trendsResponse?.oneBuild.class}`}
              >
                {`${oneBuildUnitCost.toFixed(2)}`}
              </div>
            )}

            {dataTypes.includes("craftsman") && (
              <div
                className={`unit-cost-height flex-overallCenter border-bottom defaultCompany m-auto ${trendsResponse?.craftsman.class}`}
              >
                {`${craftsmanUnitCost.toFixed(2)}`}
              </div>
            )}

            <div
              className={` numberInput unit-cost-height flex-overallCenter defaultCompany m-auto ${trendsResponse?.company.class}`}
            >
              <div className="unitCountInput" data-mdb-input-init>
                <input
                  type="number"
                  id="form12"
                  placeholder="Cost"
                  value={
                    companyUnitCosts[item.Division] ||
                    companyUnitCost.toFixed(2)
                  }
                  className="form-control"
                  style={{ textAlign: "center" }}
                  onChange={(e) => handlePriceChange(item, e.target.value)}
                />
              </div>
            </div>
          </td>

          {/* Unit */}
          <td className="p-0">
            {dataTypes.includes("bidlight") && (
              <div>
                {" "}
                <MDBDropdown
                  dropend
                  group
                  className="dropdownList w-100 flex-overallCenter border-bottom"
                >
                  <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                    <div className="defaultCompany">
                      {`${item?.Database?.Unit || "(None)"}`}
                    </div>
                  </MDBDropdownToggle>
                </MDBDropdown>
              </div>
            )}

            {dataTypes.includes("oneBuild") && (
              <div>
                <MDBDropdown
                  dropend
                  group
                  className="dropdownList w-100 flex-overallCenter border-bottom"
                >
                  <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                    <div className="defaultCompany">
                      {`${oneBuildRow?.Unit || "(None)"}`}
                    </div>
                  </MDBDropdownToggle>
                </MDBDropdown>
              </div>
            )}

            {dataTypes.includes("craftsman") && (
              <div>
                {" "}
                <MDBDropdown
                  dropend
                  group
                  className="dropdownList w-100 flex-overallCenter border-bottom"
                >
                  <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                    <div className="defaultCompany">
                      {`${craftsmanRow?.Unit || "(None)"}`}
                    </div>
                  </MDBDropdownToggle>
                </MDBDropdown>
              </div>
            )}

            <div>
              {" "}
              <MDBDropdown
                dropend
                group
                className="dropdownList w-100 flex-overallCenter "
              >
                <MDBDropdownToggle className="tabledropdown d-flex justify-content-between">
                  <div>{item.Unit || "(None)"}</div>
                  <div>
                    <DownArrowIcon />
                  </div>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="w-100 border-none" responsive="end">
                  <div className="px-2">
                    <input
                      onChange={(e) => {
                        const filteredUnits = units.filter((unit) =>
                          unit
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        );
                        setAllUnitOptions(filteredUnits);
                      }}
                      name="key"
                      type="text"
                      className="form-control p-2"
                      placeholder="Search units"
                    />
                  </div>
                  <div className="list">
                    {allUnitsOptions.map((unit, index) => (
                      <MDBDropdownItem
                        onClick={() => handleUnitChange(item, unit)}
                        key={unit + "_" + index}
                        title={unit}
                      >
                        {unit}
                      </MDBDropdownItem>
                    ))}
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </td>

          {/* category */}
          <td className="p-0">
            {dataTypes.includes("bidlight") && (
              <div>
                {" "}
                <MDBDropdown
                  dropend
                  group
                  className="dropdownList w-100 flex-overallCenter border-bottom"
                >
                  <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                    <div className="defaultCompany">
                      {`${databseCatelogCategory?.DisplayName || "(None)"}`}
                    </div>
                  </MDBDropdownToggle>
                </MDBDropdown>
              </div>
            )}

            {dataTypes.includes("oneBuild") && (
              <div>
                {" "}
                <MDBDropdown
                  dropend
                  group
                  className="dropdownList w-100 flex-overallCenter border-bottom"
                >
                  <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                    <div className="defaultCompany">
                      {`${oneBuildCategory || "(None)"}`}
                    </div>
                  </MDBDropdownToggle>
                </MDBDropdown>
              </div>
            )}

            {dataTypes.includes("craftsman") && (
              <div>
                {" "}
                <MDBDropdown
                  dropend
                  group
                  className="dropdownList w-100 flex-overallCenter border-bottom"
                >
                  <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                    <div className="defaultCompany">
                      {`${oneBuildCategory || "(None)"}`}
                    </div>
                  </MDBDropdownToggle>
                </MDBDropdown>
              </div>
            )}

            <div>
              <MDBDropdown
                dropend
                group
                className="dropdownList w-100 flex-overallCenter"
              >
                <MDBDropdownToggle className="tabledropdown d-flex justify-content-between">
                  <div className="truncated">
                    {companyCatelogCategory?.company?.DisplayName || "(None)"}
                  </div>

                  <div>
                    <DownArrowIcon />
                  </div>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="w-100 border-none" responsive="end">
                  <div className="px-2">
                    <input
                      onChange={(e) => {
                        dispatch(clearCategories());
                        setSearchCategoryQuery(e.target.value);
                      }}
                      name="key"
                      type="text"
                      className="form-control"
                      placeholder="Search Category"
                    />
                  </div>
                  <div
                    className="list"
                    id="categoriesDropdown"
                    style={{ overflowY: "auto", maxHeight: "200px" }}
                  >
                    {categoryData?.map((category) => (
                      <MDBDropdownItem
                        onClick={() => handleCategoryChange(item, category)}
                        className="truncatedText"
                        key={"cat_update_" + category?.company?._id}
                        title={
                          (category?.company ?? category?.default)?.DisplayName
                        }
                      >
                        {(category?.company ?? category?.default)?.DisplayName}
                      </MDBDropdownItem>
                    ))}
                    <div className="px-2" onClick={handlePageChange}>
                      <span
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                      >
                        Load More
                      </span>
                    </div>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </td>

          {/* awp */}
          <td className="p-0">
            {dataTypes.includes("bidlight") && (
              <div>
                {" "}
                <MDBDropdown
                  dropend
                  group
                  className="dropdownList w-100 flex-overallCenter border-bottom"
                >
                  <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                    <div className="defaultCompany">
                      {`${item?.Database?.Awp || "(None)"}`}
                    </div>
                  </MDBDropdownToggle>
                </MDBDropdown>
              </div>
            )}

            {dataTypes.includes("oneBuild") && (
              <MDBDropdown
                dropend
                group
                className="dropdownList w-100 flex-overallCenter border-bottom"
              >
                <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                  <div className="defaultCompany">
                    {`${oneBuildRow?.Awp || "(None)"}`}
                  </div>
                </MDBDropdownToggle>
              </MDBDropdown>
            )}

            {dataTypes.includes("craftsman") && (
              <MDBDropdown
                dropend
                group
                className="dropdownList w-100 flex-overallCenter border-bottom"
              >
                <MDBDropdownToggle className="tabledropdown d-flex justify-content-between disabled">
                  <div className="defaultCompany">
                    {`${craftsmanRow?.Awp || "(None)"}`}
                  </div>
                </MDBDropdownToggle>
              </MDBDropdown>
            )}

            <div>
              {" "}
              <MDBDropdown
                dropend
                group
                className="dropdownList w-100 flex-overallCenter"
              >
                <MDBDropdownToggle className="tabledropdown d-flex justify-content-between">
                  <div className="truncated">{item.Awp || "(None)"}</div>

                  <div>
                    <DownArrowIcon />
                  </div>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="w-100 border-none" responsive="end">
                  <div className="px-2">
                    <input
                      onChange={(e) => {
                        const filteredAwps = allAwpOptions.filter((awp) =>
                          awp.Description.toLowerCase().includes(
                            e.target.value.toLowerCase()
                          )
                        );
                        setAllAwpOptions(filteredAwps);
                      }}
                      name="key"
                      type="text"
                      className="form-control"
                      placeholder="Search Awp"
                    />
                  </div>
                  <div className="list">
                    {awpsData?.map((awp) => (
                      <MDBDropdownItem
                        onClick={() =>
                          handleAwpChange(item, awp.company?.Description ?? awp?.average?.Description  ?? awp?.default?.Description)
                        }
                        className="truncatedText"
                        key={"awp_update_" + awp?.company?.Description ?? awp?.average?.Description  ?? awp?.default?.Description}
                        title={awp?.company?.Description ?? awp?.average?.Description ?? awp?.default?.Description}
                      >
                        {awp?.company?.Description ?? awp?.average?.Description ?? awp?.default?.Description}
                      </MDBDropdownItem>
                    ))}
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </td>
        </tr>
        {isExpandedItemExist && (
          <React.Fragment key={"catlog_item_" + item._id + "-" + index}>
            {item?.Children &&
              item.Children.sort((a, b) => {
                const numberA = parseInt(a.Division.substring(1));
                const numberB = parseInt(b.Division.substring(1));
                return numberA - numberB;
              }).map((child, index) => RenderTableRow(child, index))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="dataTable pricing-table-container px-4">
      <MDBTable
        align="middle"
        className="w-100"
        style={{ border: "2px solid #E2E2E2" }}
      >
        <MDBTableHead>
          <tr>
            <th scope="col" rowSpan="2">
              Level
            </th>
            <th scope="col">
              <div className={`d-flex gap-3 align-items-center`}>
                <div
                  className={
                    "d-flex justify-content-center gap-1 aligh-item-center"
                  }
                >
                  <input
                    type="checkbox"
                    role="switch"
                    id={`flexSwitchCheckChecked`}
                    onChange={handleSelectAllCatalogs}
                  />
                  Select All
                </div>
                DIV
              </div>
            </th>
            <th scope="col" rowSpan="2">
              Description
            </th>
            {admin && (
              <th scope="col" rowSpan="2">
                <div
                  className={
                    "d-flex justify-content-center gap-1 aligh-item-center"
                  }
                >
                  <input
                    type="checkbox"
                    role="switch"
                    id={`flexSwitchCheckChecked`}
                    onChange={handleMakeCatalogsToPublic}
                  />
                  Public
                </div>
              </th>
            )}
            <th scope="col" rowSpan="2">
              Trend
            </th>
            <th scope="col">Company</th>
            <th scope="col">Unit cost</th>
            <th scope="col">Unit</th>
            <th scope="col">Category</th>
            <th scope="col">Awp</th>
          </tr>
        </MDBTableHead>

        {catalogs?.length > 0 ? (
          <MDBTableBody>
            {catalogs?.map((item, index) => (
              <React.Fragment key={"catlog_item_" + item._id + "-" + index}>
                {RenderTableRow(item, index)}
              </React.Fragment>
            ))}
          </MDBTableBody>
        ) : (
          <div className="dataNotFounnd">
            <div>No data exist for this template</div>
          </div>
        )}
      </MDBTable>
    </div>
  );
};
