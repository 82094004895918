import { get } from "../index";

export const getQuestionByTag = (data, tag) => {
  let query = "";
  for (let d in { ...data })
    query +=
      data[d] || (data[d] == 0 && data[d] !== "")
        ? d + "=" + data[d] + "&"
        : "";
  return get(`/api/questions/${tag}?` + query);
};
