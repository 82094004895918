import { post, get, globalPut } from '../index';

export const getRoomByUserId = body => {
  return post('/room', body);
};

export const getAllRooms = () => {
  return get('/room');
};

export const getRoomForGuest = (body) => {
  return post('/guest-chat', body);
}

export const updateRoomStatus = (body) => {
  return globalPut('/room/status', body);
};