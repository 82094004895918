import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal, Button, Checkbox, Col, Row, Select, Input } from "antd";
import {
  ClockCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import { getUsers } from "api/users/getUsers";
import { createUser } from "api/auth/registration";
import { createInvite } from "api/invite/createInvite";
import { editUser } from "api/users/editUser";
import { deleteUserOnRole } from "api/users/deleteUser";
import {
  getCompanyRoles,
  getUserPermission,
  getRole,
} from "api/permission/getPermissions";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";

import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { createRole } from "api/permission/createPermissions";
import {
  copyUserPermissionFromRole,
  editUserPermission,
  editBulkPermission,
} from "api/permission/editPermissions";
import { removeUser } from "api/users/removeUser";
import { findDuplicatePermissions } from "helpers/helper";
import addNotification from "helpers/notify";
import Loading from "components/ui-components/loading";
import AddUserToCompanyModal from "./addUserModal";
import TopNavbar from "./navbar";
import Sidebar from "./sidebar";
import { RequestCard } from "./requestCard";
import BulkImportIcon from "assets/images/icons/bulk.png";
import DeleteIcon from "assets/images/icons/delete-bin-line.png";
import { setFeatureRequest } from "actions/user";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { setCompanyRole } from "actions/user";
import {
  setRolesAndTheirPermissions,
  updateRolesAndTheirPermissions,
} from "actions/role-permission-settings";
import DefaultImage from "assets/images/user.png";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import { ratesData } from "constants/utils";
import { eventTrack } from "helpers/ga4Helper";

const Users = () => {
  let userAllPermissions = null;
  const dispatch = useDispatch();
  const modalRef = useRef();

  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const featureRequestRedux = useSelector((s) => s.user.featureRequest);

  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [usertodelete, setDelete] = useState("");
  const [roles, setRoles] = useState([]);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState([]);
  const [isOpen2, setOpen2] = useState(false);
  const [individualUserDelete, setIndividualUserDelete] = useState("");

  const hiddenFileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [modelOpen, setModel] = useState(false);
  const [newRoleModal, setNewRoleModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectionType, setSelectionType] = useState("");
  const [selectionRoleId, setSelectionRoleId] = useState("");
  const [bulkRoleName, setBulkRoleName] = useState("");
  const [requestModal, setRequestModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filtered, setFiltered] = useState("");
  const [id, setId] = useState("");
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [body, setBody] = useState({
    name: "",
    lastName: "",
    country: "",
    city: "",
    role: "User",
    rate: "Hourly",
    rateValue: "",
  });
  const [bulk, setBulk] = useState([{ key: "kanban", label: "kanban" }]);
  const [bulkRole, setBulkRole] = useState();
  const [bulkPermissions, setBulkPermissions] = useState(allPermissionRedux);
  const [isloading, setIsloading] = useState(false);
  const [warning, setWarning] = useState(false);

  const { request: fetchCompanyRoles } = useHttpWithCache({
    requestCallback: () => getCompanyRoles(userInfoRedux.company),
    reduxSetter: (data) => {
      if (data) {
        const roles = data.map((role) => {
          return {
            value: role._id,
            label: role.name,
          };
        });
        dispatch(setCompanyRole(data));
        setRoles(roles);
      }
    },
    reduxCash: {},
    reduxReset: () => {},
    allowToCash: false,
  });

  useEffect(() => {
    fetchCompanyRoles(userInfoRedux?.company);
  }, []);

  const handleEdit = (user) => {
    console.log(user);
    setBody(user);
    setId(user._id);
  };

  useEffect(() => {
    (async () => {
      setPermissionLoading(true);
      const res = await getUsers(userInfoRedux?.company);
      setUsers(res);
      setPermissionLoading(false);
    })();
  }, []);

  const getUsersLists = async () => {
    const res = await getUsers(userInfoRedux?.company);
    setUsers(res);
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;

    console.log(name, value);
    setBody((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    id ? handleEditUser() : handleCreate();
  };

  const [userLoading, setUserLoading] = useState(false);
  const handleEditUser = async () => {
    setUserLoading(true);
    const isAdmin = body.role === "Admin";
    body.admin = isAdmin;

    try {
      const res = await editUser(id, body);
      if (!res.error) {
        updateUsersList(res);
        addNotification("User Updated Successfully!", "success");
        closeModal();
        setUserLoading(false);
      } else {
        addNotification(res?.message, "danger");
        setUserLoading(false);
      }
    } catch (error) {
      console.error("Error editing user:", error);
      addNotification("An error occurred while updating user.", "danger");
      setUserLoading(false);
    }
  };

  const handleCreate = async () => {
    body.company = userInfoRedux.company;

    try {
      const res = await createUser(body);

      if (!res.error) {
        // Handle success if needed
      } else {
        addNotification(res?.message, "danger");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      addNotification("An error occurred while creating user.", "danger");
    }
  };

  const updateUsersList = (updatedUser) => {
    setUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) =>
        user._id === id ? updatedUser : user
      );
      return updatedUsers;
    });
  };

  const closeModal = () => {
    modalRef.current.click();
  };

  const handleClear = () => {
    setBody({
      email: "",
      company: "",
      password: "",
      name: "",
      lastName: "",
      country: "",
      city: "",
      username: "",
      role: "User",
      rate: "Hourly",
      rateValue: "",
    });
    setId("");
  };

  const handleBulkDelete = async () => {
    setLoading(true);
    try {
      const res = await removeUser({ ids: usersToDelete });
      setUsers((prev) =>
        prev.filter((user) => !usersToDelete.includes(user._id))
      );
      setUsersToDelete([]);
      setLoading(false);
      setOpen2(false);
      addNotification("Deleted Successfully! ", "success");
      getUsersLists();
    } catch (err) {
      setLoading(false);
      setOpen2(false);
      addNotification("Error not delete", "danger");
    }
  };

  const handleBulkClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  const handleUploadCsv = async () => {
    setSpinner(true);
    try {
      let err = null;
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("owner", userInfoRedux?.company);
      const fileReader = new FileReader();

      fileReader.onload = async (event) => {
        // Get the content of the file
        const fileContent = event.target.result;
        Papa.parse(fileContent, {
          header: true, // Set to true if the CSV file has a header
          step: async (result, parser) => {
            if (
              Object.values(result.data)[0] === "" &&
              Object.values(result.data)[1] === ""
            ) {
              // Continue to the next line
              parser.resume();
              return;
            }
            try {
              const invite = await createInvite({
                mail: Object.values(result.data)[0],
                role: Object.values(result.data)[1],
                owner: userInfoRedux?.company,
              });
              if (invite) parser.resume();
            } catch (e) {
              console.log("Error uploading CSV:", e);
              err = e;
            }
          },
          complete: (result) => {
            setSelectedFile(null);
            setTimeout(() => {
              setSpinner(false);
              if (!err) {
                addNotification(
                  "An email has been sent to your inbox, please allow 5 minutes before checking! ",
                  "success"
                );
              }
            }, 3000);
          },
          error: (error) => {
            console.error("CSV Parsing Error:", error.message);
            setSpinner(false);
            addNotification(
              "Something went wrong, please contact support",
              "danger"
            );
          },
        });
      };
      fileReader.readAsText(selectedFile);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSearch = (keyword) => {
    const filteredResult = users.filter(
      (user) =>
        user?.email?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
        user?.name?.toLowerCase()?.includes(keyword?.toLowerCase())
    );
    setFilteredProjects(filteredResult);

    if (keyword.length > 0) {
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  };

  const deleteUser = async () => {
    try {
      const res = await deleteUserOnRole({
        id: individualUserDelete.id,
        email: individualUserDelete.email,
      });
      if (res) {
        getUsersLists();
        console.log("deleted successfully");
        setOpen(false);
        setIndividualUserDelete("");
        addNotification("User deleted successfully", "success");
      }
    } catch (err) {
      console.error("Error deleting user:", err);
      addNotification("Something went wrong", "danger");
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setBulk(allPermissionRedux);
    } else {
      setBulk([]);
    }
  };

  const handlePermissionChange = (checkedValues) => {
    console.log(checkedValues);
    setBulk(checkedValues);
  };

  const handleBulkCheckBox = async (e) => {
    const data = bulkPermissions.map((permission) => {
      if (permission.key === e.target.value) {
        return {
          ...permission,
          checked: e.target.checked,
        };
      } else {
        return permission;
      }
    });
    let permissionData = [];
    if (e.target.checked) {
      permissionData = [...bulkPermissions, e.target.value];
    } else {
      permissionData = bulkPermissions.filter((rp) => rp !== e.target.value);
    }
    setBulkPermissions(data);
  };

  const handleBulkPermSave = async () => {
    const a = {};
    setIsloading(true);
    usersToDelete.forEach((element) => {
      if (Object.keys(userAllPermissions).includes(element)) {
        a[element] = userAllPermissions[element];
      }
    });
    const d = findDuplicatePermissions(a);
    const permissions = bulkPermissions
      .filter((bp) => bp.checked)
      .map((p) => p.key);
    if (!warning && permissions.length < 1) {
      setWarning(true);
      return;
    }

    const body = {
      usersIds: usersToDelete,
      permissions: permissions,
      roleId: selectionRoleId,
      type: selectionType,
      roleName: bulkRoleName,
      deletePermission: d.filter((x) => !permissions.includes(x)),
    };
    try {
      await editBulkPermission(body);
      setWarning(false);
      setModel(false);
      setIsloading(false);
    } catch (err) {
      setWarning(false);
      setIsloading(false);
      setBulkPermissions(allPermissionRedux);
    }
  };

  const handleAddNewRole = async () => {
    if (!warning && bulk.length < 1) {
      setWarning(true);
      return;
    }
    try {
      setIsloading(true);
      const res = await createRole({
        name: roleName,
        permissions: bulk,
        companyId: userInfoRedux?.company,
      });

      if (res) {
        setIsloading(false);
        addNotification("Role Created Successfully", "success");
      }

      const updatedRoles = [{ value: roleName, label: roleName }, ...roles];
      setBulk([]);
      setRoles(updatedRoles);
      setNewRoleModal(false);
      fetchCompanyRoles(userInfoRedux?.company);
    } catch (error) {
      setIsloading(false);
      addNotification(
        error.err ?? "Something went wrong, please connect with support",
        "danger"
      );
    }
  };

  const handleRolePerm = async (value) => {
    try {
      roles.filter((role) => {
        let selectedRoleId = value;
        if (role.value === selectedRoleId) {
          setBulkRoleName(role.label);
        }
      });
      setSelectionType("role");
      setSelectionRoleId(value);
      const res = await getRole(value);
      if (res) {
        const data = allPermissionRedux?.map((permission) => {
          return {
            ...permission,
            checked: res.permissions?.includes(permission),
          };
        });
        setBulkPermissions(data);
      }
      setBulkRole(value);
    } catch (err) {
      console.log(err);
    }
  };

  const requestAccess = async () => {
    try {
      setLoadingRequest(true);
      const res = await createFeatureRequest(userInfoRedux.id, {
        companyId: userInfoRedux.company,
        permission: requestType,
        userId: userInfoRedux.id,
      });
      if (res) {
        setLoadingRequest(false);
        dispatch(
          setFeatureRequest(res?.map((feature) => feature.requestedFeature))
        );
      }
    } catch (e) {
      console.log(e);
      setLoadingRequest(false);
    }
  };

  return (
    <>
      <div className="personal-area">
        <TopNavbar handleSearch={handleSearch} />
        <div className="content-bg p-2">
          <div className="container d-flex mt-1">
            <Sidebar />

            <div className="profile-content px-0 pt-0">
              <div className="w-100 px-0">
                <div className="d-flex justify-content-between px-3 py-3 border-bottom align-items-center">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        d="M15.2323 10.9609C14.3651 10.9609 13.5174 11.2181 12.7963 11.6999C12.0753 12.1817 11.5133 12.8664 11.1814 13.6676C10.8496 14.4688 10.7627 15.3504 10.9319 16.2009C11.1011 17.0515 11.5187 17.8327 12.1319 18.4459C12.7451 19.0591 13.5263 19.4767 14.3769 19.6459C15.2274 19.8151 16.109 19.7283 16.9102 19.3964C17.7114 19.0645 18.3962 18.5026 18.8779 17.7815C19.3597 17.0605 19.6169 16.2127 19.6169 15.3456C19.6108 14.1845 19.1469 13.0728 18.326 12.2519C17.505 11.4309 16.3933 10.967 15.2323 10.9609ZM16.0015 15.7302C15.8822 15.7272 15.7646 15.7011 15.6554 15.6532L14.0015 17.3071C13.9088 17.3989 13.7856 17.4536 13.6554 17.4609C13.5893 17.4673 13.5227 17.4565 13.462 17.4296C13.4014 17.4026 13.3487 17.3604 13.3092 17.3071C13.2165 17.208 13.1648 17.0774 13.1648 16.9417C13.1648 16.806 13.2165 16.6754 13.3092 16.5763L14.963 14.9225C14.9192 14.8119 14.8933 14.6951 14.8861 14.5763C14.8686 14.3929 14.8892 14.2079 14.9467 14.0329C15.0041 13.8578 15.0972 13.6966 15.2201 13.5593C15.3429 13.422 15.4928 13.3117 15.6604 13.2352C15.828 13.1586 16.0096 13.1177 16.1938 13.1148C16.3131 13.1177 16.4307 13.1438 16.54 13.1917C16.6169 13.1917 16.6169 13.2686 16.5784 13.3071L15.8092 14.0379C15.7914 14.0468 15.7764 14.0606 15.766 14.0776C15.7555 14.0945 15.75 14.1141 15.75 14.134C15.75 14.1539 15.7555 14.1735 15.766 14.1905C15.7764 14.2074 15.7914 14.2212 15.8092 14.2302L16.3092 14.7302C16.3229 14.7477 16.3404 14.762 16.3604 14.7717C16.3804 14.7815 16.4023 14.7866 16.4246 14.7866C16.4468 14.7866 16.4688 14.7815 16.4888 14.7717C16.5088 14.762 16.5263 14.7477 16.54 14.7302L17.2707 13.9994C17.3092 13.9609 17.4246 13.9609 17.4246 14.0379C17.4645 14.1496 17.4904 14.2659 17.5015 14.384C17.4986 14.5732 17.4566 14.7598 17.3782 14.9321C17.2997 15.1043 17.1865 15.2585 17.0457 15.3849C16.9048 15.5113 16.7394 15.6072 16.5597 15.6666C16.38 15.7261 16.1899 15.7477 16.0015 15.7302Z"
                        fill="#1D1D1D"
                      />
                      <path
                        d="M8.84826 11.1926C11.5884 11.1926 13.8098 8.97125 13.8098 6.23107C13.8098 3.49089 11.5884 1.26953 8.84826 1.26953C6.10808 1.26953 3.88672 3.49089 3.88672 6.23107C3.88672 8.97125 6.10808 11.1926 8.84826 11.1926Z"
                        fill="#1D1D1D"
                      />
                      <path
                        d="M10.1931 19.652C11.0392 19.652 10.5777 19.0751 10.5777 19.0751C9.72848 18.0173 9.267 16.7007 9.26998 15.3443C9.2659 14.4941 9.44978 13.6536 9.80844 12.8827C9.82459 12.8387 9.85095 12.7992 9.88537 12.7674C10.1546 12.2289 9.61614 12.1904 9.61614 12.1904C9.37393 12.1585 9.12959 12.1456 8.88537 12.152C7.07455 12.159 5.3262 12.8147 3.9573 14.0002C2.58841 15.1856 1.6896 16.8223 1.42383 18.6135C1.42383 18.9981 1.53921 19.6904 2.73152 19.6904H10.0777C10.1546 19.652 10.1546 19.652 10.1931 19.652Z"
                        fill="#1D1D1D"
                      />
                    </svg>
                    <span className="font-weight-bold ml-2 text-black">
                      Users, Roles <br /> & Permissions
                    </span>

                    {usersToDelete.length > 0 &&
                      (FinalPermissions?.includes(
                        ALL_PERMISSIONS.USER_DELETE
                      ) ? (
                        <div
                          className="header-btn bg-white text-bid border-bid rounded-lg h6 ml-4 mb-0 cursor-pointer"
                          onClick={() => setOpen2(true)}
                        >
                          <img src={DeleteIcon} className="w-15" />
                          <span className="ml-1 f-14">
                            Delete {usersToDelete.length} Users
                          </span>
                        </div>
                      ) : (
                        <div
                          className="header-btn bg-white text-bid border-bid rounded-lg h6 ml-4 mb-0 cursor-pointer"
                          onClick={() => {
                            setRequestType("bulkUserDelete");
                            setRequestModal(true);
                          }}
                        >
                          <img src={DeleteIcon} className="w-15" />
                          <span className="ml-1 f-14">
                            Delete {usersToDelete.length} Users
                          </span>
                        </div>
                      ))}
                  </div>

                  <div className="d-flex">
                    {usersToDelete.length > 1 &&
                      (FinalPermissions?.includes(ALL_PERMISSIONS.USER_EDIT) ? (
                        <div
                          className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                          onClick={() => {
                            const a = {};
                            usersToDelete.forEach((element) => {
                              if (
                                Object.keys(userAllPermissions).includes(
                                  element
                                )
                              ) {
                                a[element] = userAllPermissions[element];
                              }
                            });
                            const d = findDuplicatePermissions(a);
                            setModel(usersToDelete.length > 1);
                            setBulkPermissions(
                              allPermissionRedux?.map((permission) => {
                                return {
                                  ...permission,
                                  checked: d.includes(permission),
                                };
                              })
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                          >
                            <path
                              d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                              fill="#455E6C"
                            />
                          </svg>
                          <span className="ml-2 f-14">
                            Bulk Edit Permissions
                          </span>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setRequestType(ALL_PERMISSIONS.USER_EDIT);
                            setRequestModal(true);
                          }}
                          className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                          >
                            <path
                              d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                              fill="#455E6C"
                            />
                          </svg>
                          <span className="ml-2 f-14">
                            Bulk Edit Permissions
                          </span>
                        </div>
                      ))}

                    {FinalPermissions?.includes(ALL_PERMISSIONS.USER_ADD) ? (
                      <div
                        onClick={handleBulkClick}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                      >
                        <img src={BulkImportIcon} className="w-15" />
                        <span className="ml-1 f-14">Bulk Import Users</span>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setRequestType(ALL_PERMISSIONS.USER_ADD);
                          setRequestModal(true);
                        }}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                      >
                        <img src={BulkImportIcon} className="w-15" />
                        <span className="ml-1 f-14">Bulk Import Users</span>
                      </div>
                    )}

                    {FinalPermissions?.includes("roleAdd") ? (
                      <div
                        onClick={() => setNewRoleModal(true)}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span className="ml-2 f-14">Add New Role</span>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setRequestType("roleAdd");
                          setRequestModal(true);
                        }}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span className="ml-2 f-14">Add Role</span>
                      </div>
                    )}

                    {selectedFile ? (
                      <div
                        onClick={handleUploadCsv}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        {spinner ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="text-bid">Upload</div>
                        )}
                      </div>
                    ) : usersPermissionRedux?.includes(
                        ALL_PERMISSIONS.USER_ADD
                      ) ? (
                      <div
                        onClick={() => setAddUserOpen(true)}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span className="ml-2 f-14">Add Users</span>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setRequestType(ALL_PERMISSIONS.USER_ADD);
                          setRequestModal(true);
                        }}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span className="ml-2 f-14">Add Users</span>
                      </div>
                    )}
                  </div>
                </div>

                {permissionLoading ? (
                  <Loading color="black" text="Loading..." />
                ) : (
                  <div className="permission-table-div">
                    <table className="bid-table sticky-table-header">
                      <thead>
                        <tr className="tableRow">
                          <th
                            scope="col"
                            className="checkBox bg-table-header py-0"
                          ></th>
                          <th
                            scope="col"
                            className="headcol bg-table-header py-0"
                          >
                            User Name
                          </th>
                          <th
                            scope="col"
                            style={{ textAlign: "center" }}
                            className="role bg-table-header py-0"
                          >
                            Role
                          </th>
                          {allPermissionRedux?.map((permission, key) => (
                            <th
                              style={{ textAlign: "center" }}
                              scope="col"
                              className="capitalize py-0"
                              key={`${key}-${permission}`}
                            >
                              {permission.split(/(?=[A-Z])/).join(" ")}
                            </th>
                          ))}
                          <th
                            scope="col"
                            className="actions bg-table-header py-0"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filtered
                          ? filteredProjects?.map((user, i) => {
                              if (userInfoRedux.id == user._id) return;

                              return (
                                <UserRow
                                  key={i}
                                  user={user}
                                  setOpen={setOpen}
                                  handleEdit={handleEdit}
                                  setRequestType={setRequestType}
                                  setIndividualUserDelete={
                                    setIndividualUserDelete
                                  }
                                  setRequestModal={setRequestModal}
                                  roles={roles}
                                  setRoles={setRoles}
                                  usersToDelete={usersToDelete}
                                  setUsersToDelete={setUsersToDelete}
                                  onHandleUserPermissions={(e) => {
                                    userAllPermissions = {
                                      ...userAllPermissions,
                                      ...e,
                                    };
                                  }}
                                  setPermissionLoading={setPermissionLoading}
                                />
                              );
                            })
                          : users?.map((user, i) => {
                              if (userInfoRedux.id == user._id) return;

                              return (
                                <UserRow
                                  key={i}
                                  user={user}
                                  setOpen={setOpen}
                                  handleEdit={handleEdit}
                                  setRequestModal={setRequestModal}
                                  setIndividualUserDelete={
                                    setIndividualUserDelete
                                  }
                                  setRequestType={setRequestType}
                                  roles={roles}
                                  setRoles={setRoles}
                                  usersToDelete={usersToDelete}
                                  setUsersToDelete={setUsersToDelete}
                                  onHandleUserPermissions={(e) => {
                                    userAllPermissions = {
                                      ...userAllPermissions,
                                      ...e,
                                    };
                                  }}
                                  setPermissionLoading={setPermissionLoading}
                                />
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                )}

                <input
                  style={{ display: "none" }}
                  type="file"
                  accept=".csv"
                  name="file_image"
                  className="file-input"
                  id="input_image"
                  required
                  // disabled={loading}
                  ref={hiddenFileInput}
                  onChange={handleFile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit user modal */}
      <div
        className="modal fade"
        id="EditUserModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header px-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit User
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClear}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-0 px-0">
              <form>
                <div className="form-group-div mt-0">
                  <label htmlFor="exampleInputEmail1">Name</label>
                  <input
                    value={body.name}
                    onChange={handleOnchange}
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="form-group-div mt-3">
                  <label htmlFor="exampleInputEmail1">Last Name</label>
                  <input
                    value={body.lastName}
                    onChange={handleOnchange}
                    name="lastName"
                    type="text"
                    className="form-control"
                    placeholder="Enter last name"
                  />
                </div>
                <div className="form-group-div mt-3">
                  <label htmlFor="exampleInputEmail1">Email</label>
                  <input
                    value={body.email}
                    name="email"
                    type="text"
                    className="form-control"
                    placeholder="Enter email"
                    disabled
                  />
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <div className="form-group-div mt-3 w-50">
                    <label htmlFor="exampleInputEmail1">Country</label>
                    <input
                      value={body.country}
                      onChange={handleOnchange}
                      name="country"
                      type="text"
                      className="form-control"
                      placeholder="Enter Country"
                    />
                  </div>
                  <div className="form-group-div mt-3 w-50">
                    <label htmlFor="exampleInputEmail1">City</label>
                    <input
                      value={body.city}
                      onChange={handleOnchange}
                      name="city"
                      type="text"
                      className="form-control"
                      placeholder="Enter City"
                    />
                  </div>
                </div>
                <div className="form-group-div mt-3">
                  <label htmlFor="exampleFormControlSelect1">Role</label>
                  <select
                    value={body.role}
                    name="role"
                    onChange={handleOnchange}
                    className="form-control"
                    id="exampleFormControlSelect1"
                  >
                    {roles?.map((item, key) => (
                      <option
                        key={key}
                        value={item?.label}
                        className="capitalize"
                      >
                        {item?.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-flex justify-content-between gap-3">
                  {" "}
                  <div className="form-group-div mt-3 w-50">
                    <label htmlFor="exampleFormControlSelect1">Rate</label>
                    <select
                      value={body.rate}
                      name="rate"
                      onChange={handleOnchange}
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      {ratesData?.map((item, key) => (
                        <option key={key} value={item?.label}>
                          {item?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group-div mt-3">
                    <label htmlFor="exampleFormControlSelect1">Amount</label>
                    <div className="position-relative">
                      <span className="currency-prefix">$</span>
                      <input
                        type="number"
                        name="rateValue"
                        value={body.rateValue}
                        onChange={handleOnchange}
                        className="pl-4"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="form-group">
                            <label for="exampleInputPassword1">Password</label>
                            <input value={body.password} onChange={handleOnchange} name='password' type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"/>
                        </div> */}
              </form>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  ref={modalRef}
                  onClick={handleClear}
                  className="bg-white text-bid border-bid text-inherit rounded py-2"
                  style={{
                    width: "48%",
                    textAlign: "center",
                    color: "black",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </div>
                <div
                  onClick={() => {
                    handleSave();
                    eventTrack(
                      "Add-User-Action",
                      "Add-User",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }}
                  className="border-bid rounded py-2"
                  style={{
                    width: "48%",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "#3A5765",
                    color: "white",
                  }}
                >
                  {id ? "Update" : "Add"}{" "}
                  {userLoading && (
                    <i className="fa fa-spinner small-spinner ml-3"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* single delete */}
      <MDBModal
        isOpen={isOpen}
        toggle={() => setOpen(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpen(false)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>

        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Users ?</p>
          <p className="delete-modal-label">
            Are you sure you want to delete {individualUserDelete.name} ? This
            action cannot be undone.
          </p>
        </MDBModalBody>
        {loading ? <Loading color="black" text="Deleting..." /> : null}

        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpen(false);
                setIndividualUserDelete("");
              }}
              color=""
              disabled={loading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={() => {
                deleteUser();
                eventTrack(
                  "Delete-User-Action",
                  "Delete-User",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
              disabled={loading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      {/* bulk delete */}
      <MDBModal
        isOpen={isOpen2}
        toggle={() => setOpen2(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpen2(false)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Users ?</p>
          <p className="delete-modal-label">
            Are you sure you want to delete this Users? This action cannot be
            undone.
          </p>
        </MDBModalBody>
        {loading ? <Loading color="black" text="Deleting..." /> : null}

        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpen2(false);
                setDelete("");
              }}
              color=""
              disabled={loading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={() => {
                handleBulkDelete();
                eventTrack(
                  "Bulk-Delete-User-Action",
                  "Bulk-Delete-User",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
              disabled={loading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      <AddUserToCompanyModal
        isOpen={addUserOpen}
        toggle={() => {
          setAddUserOpen(false);
        }}
      />

      {/* bulk  */}
      <Modal
        open={modelOpen}
        title="User Permissions"
        onCancel={() => {
          setModel(false);
          setBulk([]);
          setWarning(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <span>
            <div
              onClick={handleBulkPermSave}
              style={{
                width: "100%",
                backgroundColor: "#3A5765",
                color: "white",
                padding: 6,
                display: "flex",
                justifyContent: "center",
                borderRadius: 2,
                cursor: "pointer",
                marginBottom: 5,
              }}
            >
              {isloading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 16,
                        color: "white",
                        marginRight: 4,
                        marginTop: 2,
                      }}
                      spin
                    />
                  }
                />
              )}
              <span>Confirm</span>
            </div>
            {warning && (
              <p style={{ color: "red", width: "100%" }}>
                Are You sure? users all permissions will be reset
              </p>
            )}
            <Button
              onClick={() => {
                setModel(false);
                setWarning(false);
              }}
              style={{ width: "100%" }}
            >
              Cancel
            </Button>
          </span>
        )}
      >
        <div style={{ width: "100%" }}>
          <div style={{ marginBottom: 6, color: "black" }}>Select Role</div>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Role"
            optionFilterProp="children"
            options={roles}
            onChange={handleRolePerm}
            value={bulkRole}
          />
        </div>

        <Checkbox.Group
          value={bulk}
          style={{ width: "100%", marginBottom: "30px" }}
          onChange={handlePermissionChange}
        >
          <Row>
            {bulkPermissions.map((permission, key) => {
              return (
                <Col span={10} style={{ marginTop: 10 }} key={key}>
                  <input
                    style={{ transform: "scale(1.5)" }}
                    value={permission.key}
                    onChange={(e) => handleBulkCheckBox(e, permission)}
                    type="checkbox"
                    checked={permission.checked}
                  />
                  <span style={{ marginLeft: "20px" }}>{permission.label}</span>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Modal>

      {/* add new role modal */}
      {newRoleModal && (
        <Modal
          open
          title="Add Role"
          style={{ top: 20 }}
          width={1200}
          onCancel={() => {
            setNewRoleModal(false);
            setBulk([]);
            setWarning(false);
          }}
          footer={(_, { OkBtn, CancelBtn }) => (
            <span>
              <Button
                onClick={() => {
                  handleAddNewRole();
                  eventTrack(
                    "Add-New-Role-Action",
                    "Add-New-Role",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#3A5765",
                  color: "white",
                  padding: 6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                  cursor: "pointer",
                  marginBottom: 5,
                }}
                disabled={roleName.length === 0}
              >
                {isloading && (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 16,
                          color: "white",
                          marginRight: 4,
                          marginTop: 2,
                        }}
                        spin
                      />
                    }
                  />
                )}
                <span>Confirm</span>
              </Button>
              {warning && (
                <p style={{ color: "red", width: "100%" }}>
                  Are You sure? users all permissions will be reset
                </p>
              )}
              <Button
                onClick={() => {
                  setNewRoleModal(false);
                  setModel(false);
                  setWarning(false);
                }}
                style={{ width: "100%" }}
              >
                Cancel
              </Button>
            </span>
          )}
        >
          <div style={{ width: "100%" }}>
            <Input
              style={{ width: "100%" }}
              placeholder="Role Name"
              onChange={(e) => setRoleName(e.target.value)}
            />
          </div>
          {/* <Checkbox.Group
            value={bulk}
            onChange={handlePermissionChange}
            style={{ width: "100%", marginBottom: "30px" }}
          > */}
          <Row>
            <Col span={6} style={{ marginTop: 10 }}>
              <Checkbox
                checked={bulk.length === allPermissionRedux.length}
                indeterminate={
                  bulk.length > 0 && bulk.length < allPermissionRedux.length
                }
                onChange={handleSelectAll}
                className="capitalize"
              >
                Select All
              </Checkbox>
            </Col>
            {allPermissionRedux?.map((permission) => (
              <Col span={6} style={{ marginTop: 10 }} key={permission}>
                <Checkbox
                  checked={bulk.includes(permission)}
                  onChange={() => handlePermissionChange(permission)}
                  className="capitalize"
                >
                  {permission.split(/(?=[A-Z])/).join(" ")}
                </Checkbox>
              </Col>
            ))}
          </Row>
          {/* </Checkbox.Group> */}
        </Modal>
      )}

      {/* request licence */}
      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequestRedux?.includes(requestType)}
          loading={loadingRequest}
          icon={
            featureRequestRedux?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequestRedux?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequestRedux?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </>
  );
};

const UserRow = ({
  user,
  onHandleUserPermissions,
  setRequestType,
  handleEdit,
  setOpen,
  setIndividualUserDelete,
  setRequestModal,
  roles,
  setRoles,
  usersToDelete,
  setUsersToDelete,
  setPermissionLoading,
}) => {
  const [role, setRole] = useState(user?.role);
  const [permissions, setPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);

  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const companyRoles = useSelector((s) => s.user.companyRoles);
  const rolesAndPermissionRedux = useSelector(
    (s) => s?.rolesAndPermissions?.rolesPermissionSettings
  );
  const dispatch = useDispatch();
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const fetchRolesAndSetPermissions = useCallback(() => {
    (async () => {
      try {
        const res = await getUserPermission(user._id);
        const data = allPermissionRedux?.map((permission) => {
          return {
            permission,
            checked:
              companyPermissionRedux?.includes(permission) &&
              res?.permissions?.includes(permission),
          };
        });
        dispatch(
          setRolesAndTheirPermissions({ userId: user?._id, permissions: data })
        );

        onHandleUserPermissions({ [user._id]: res?.permissions });
        setRolePermissions(res?.permissions ? res?.permissions : []);
      } catch (e) {
        console.log("Error: ", e);
      }
    })();
  }, [user._id]);

  useEffect(() => {
    fetchRolesAndSetPermissions();
  }, []);

  const handleRadioChange = async (value) => {
    try {
      let roleName;
      roles.filter((filterRoleId) => {
        let filteredRoleName = value;
        if (filterRoleId.value === filteredRoleName) {
          roleName = filterRoleId.label;
        }
      });
      const response = await copyUserPermissionFromRole(user._id, {
        roleId: value,
        role: roleName,
      });
      if (response.err || response.error) {
        addNotification(
          response.err ??
            "Something went wrong while updating role and permissions, please contact support.",
          "danger"
        );
      } else {
        addNotification(
          "User role and permissions updated successfully!.",
          "success"
        );

        const selectedCompanyRole = companyRoles.find(
          (item) => item?.name === response?.role
        );
        if (selectedCompanyRole?.permissions) {
          const data = allPermissionRedux?.map((permission) => {
            return {
              permission,
              checked: selectedCompanyRole?.permissions?.includes(permission),
            };
          });
          dispatch(
            updateRolesAndTheirPermissions({
              userId: user._id,
              permissions: data,
            })
          );
        } else {
          const data = allPermissionRedux?.map((permission) => {
            return {
              permission,
              checked: false,
            };
          });
          dispatch(
            updateRolesAndTheirPermissions({
              userId: user._id,
              permissions: data,
            })
          );
        }
      }

      setRole(value);
    } catch (err) {
      console.log("err-------", err);
    }
  };

  const handleCheckBox = (e, id) => {
    const isChecked = e.target.checked;
    const index = usersToDelete.indexOf(id);
    if (isChecked && index === -1) {
      setUsersToDelete((prev) => [...prev, id]);
    }
    if (!isChecked && index !== -1) {
      setUsersToDelete((prev) => prev.filter((userId) => userId !== id));
    }
  };

  const handleCheckBox2 = async (e) => {
    const data = permissions?.map((permission) => {
      if (permission.key === e.target.value) {
        return {
          ...permission,
          checked: e.target.checked,
        };
      } else {
        return permission;
      }
    });
    let permissionData = [];
    if (e.target.checked) {
      permissionData = [...rolePermissions, e.target.value];
    } else {
      permissionData = rolePermissions?.filter((rp) => rp !== e.target.value);
    }
    setRolePermissions(permissionData);
    setPermissions(data);
    try {
      const res = await editUserPermission(user._id, {
        permissions: permissionData,
        entityId: user._id,
        entityType: user?.role,
      });

      const data = allPermissionRedux?.map((permission) => {
        return {
          permission,
          checked: res?.permissions?.includes(permission),
        };
      });
      dispatch(
        updateRolesAndTheirPermissions({ userId: user._id, permissions: data })
      );
      if (res && res.role) {
        // setTimeout(() => {
        //   setRole(res.role._id);
        // }, 2000);
      }
    } catch (err) {
      console.log("err-------", err);
    }
  };

  return (
    <>
      <tr className="tableRow">
        <td className="checkBox">
          <input
            checked={usersToDelete.includes(user._id)}
            onChange={(e) => handleCheckBox(e, user._id)}
            type="checkbox"
            className="squared-checkbox"
          />
        </td>
        <td className="headcol">
          <span style={{ display: "flex", alignItems: "center" }}>
            <img
              src={
                user?.profilePhoto?.split(".").pop() === "gz"
                  ? extractImageFromZip(user?.profilePhoto)
                  : user?.profilePhoto
              }
              onError={(e) => (e.target.src = DefaultImage)}
              style={{ width: "30px", height: "30px", borderRadius: "50%" }}
            />
            <div className="ml-2"> {user?.name} </div>
          </span>
        </td>
        <td className="text-center role">
          <Select
            showSearch={true}
            style={{ width: 100 }}
            placeholder="Select Role"
            // filterOption={filterOption}
            optionFilterProp="children"
            options={roles}
            onChange={(value) => {
              handleRadioChange(value);
            }}
            value={role}
          />
        </td>

        {rolesAndPermissionRedux
          .find((item) => item?.userId === user?._id)
          ?.permissions?.map((item, key) => {
            return (
              <td className="text-center" key={key}>
                <input
                  className="squared-checkbox"
                  value={item?.permission}
                  disabled={!companyPermissionRedux?.includes(item?.permission)}
                  onChange={(e) => handleCheckBox2(e, item?.permission)}
                  type="checkbox"
                  checked={item.checked}
                />
              </td>
            );
          })}

        <td className="actions">
          <div style={{ display: "flex" }}>
            {FinalPermissions?.includes(ALL_PERMISSIONS.USER_EDIT) ? (
              <span
                onClick={() => handleEdit(user)}
                data-toggle="modal"
                data-target="#EditUserModal"
                className="mr-4 cursor-pointer table-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                >
                  <path
                    d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
              </span>
            ) : (
              <span
                onClick={() => {
                  setRequestType(ALL_PERMISSIONS.USER_EDIT);
                  setRequestModal(true);
                }}
                data-toggle="modal"
                data-target="#exampleModal"
                className="mr-2 cursor-pointer table-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                >
                  <path
                    d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
              </span>
            )}

            {FinalPermissions?.includes(ALL_PERMISSIONS.USER_DELETE) ? (
              <span
                onClick={() => {
                  setIndividualUserDelete({
                    id: user._id,
                    email: user.email,
                    name: user.name,
                  });
                  setOpen(true);
                }}
                className="mr-2 cursor-pointer table-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="22"
                  viewBox="0 0 26 22"
                  fill="none"
                >
                  <path
                    d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
              </span>
            ) : (
              <span
                onClick={() => {
                  setRequestType(ALL_PERMISSIONS.USER_DELETE);
                  setRequestModal(true);
                }}
                className="mr-2 cursor-pointer table-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="22"
                  viewBox="0 0 26 22"
                  fill="none"
                >
                  <path
                    d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
              </span>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default Users;
