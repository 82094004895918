import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import TopNavbar from "./navbar";
import { useSelector } from "react-redux";
import { useHttp } from "hooks/useHttp";
import {
  getRecentExportSettings,
  createProjectExportSettings,
  updateProjectExportSettings,
  getProjectExportSettings,
} from "api/projects/exportSettings";
import { getMileStones } from "api/milestones/getMilestones";
import { getParameters } from "api/parameters/getParameters";
import Select from "react-select";
import Loader from "components/Loader";
import addNotification from "helpers/notify";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBInput,
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBModalFooter,
} from "mdbreact";
import EyeImg from "assets/images/icons/eye-fill.png";
import formatTime from "helpers/formatTime";
import numeral from "numeral";
import DefaultImage from "assets/images/user.png";
import ExportSettingsProjectView from "./exportSettingsProjectView";
import ExportSettingsBulkAssignModel from "./exportSettingsBulkAssignModel";
import { eventTrack } from "helpers/ga4Helper";

const PARAMETER_TYPE_OPTIONS = [
  {
    label: "Built In Parameters",
    value: "builtIn",
  },
  {
    label: "Shared Parameters",
    value: "shared",
  },
  {
    label: "Project Parameters",
    value: "project",
  },
  {
    label: "Family Parameters",
    value: "family",
  },
  {
    label: "Global Parameters",
    value: "global",
  },
];

const ExportSettings = () => {
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const projectsRedux = useSelector((s) => s.user.projects);
  const [recentExportSettings, setRecenetExportSettings] = useState(false);
  const [include2DElements, setInclude2DElements] = useState(false);
  const [includeMiscellaneous, setIncludeMiscellaneous] = useState(false);
  const [excludeEmptyGeometry, setExcludeEmptyGeometry] = useState(true);
  const [excludeElementsNotInView, setExcludeElementsNotInView] = useState(true);

  const [filteredParams, setFilteredParams] = useState([]);
  const [filteredParamsNameList, setFilteredParamsNameList] = useState();
  const [matchingParamsType, setMatchingParamsType] = useState();
  const [matchingParamsNameList, setMatchingParamsNameList] = useState();
  const [filteredParamsType, setFilteredParamsType] = useState();
  const [milestones, setMilestones] = useState([]);
  const [selectedMilestone, setSelectedMilstone] = useState(null);
  const [showExtraMatchingParameter, setShowExtraMatchingParameter] =
    useState(false);
  const [showFilterBidlightParameter, setShowFilterBidlightParameter] =
    useState(false);
  const [showFilterCustomParameter, setShowFilterCustomParameter] =
    useState(false);
  const [filterParameterSelected, setFilterParameterSelected] = useState([]);
  const [matchingParameterId, setMatchingParameterId] = useState([]);
  const [filterParamsProjectObj, setFilterParamsProjectObj] = useState([]);
  const [selectedFilterParameter, setSelectedFilterParameter] = useState();
  const [selectedMatchingParameter, setSelectedMatchingParameter] = useState();

  const TABS = {
    GLOBAL: "GLOBAL",
    PROJECT: "PROJECT",
  };

  const [activeTab, setActiveTab] = useState(TABS.GLOBAL);

  const parentProjectOptions = projectsRedux?.map((i) => ({
    label: i.title,
    value: i._id,
  }));

  const handleSearch = (keyword) => {};

  const { id, company } = userInfoRedux;

  useEffect(() => {
    getRecentExportSetting();
    getParametersAPI();
  }, []);

  useEffect(() => {
    if (filteredParams?.length && recentExportSettings?._id) {
      if (recentExportSettings?.filterParameter.type === "custom parameter") {
        const filteredParameters = filteredParams
          .filter((param) =>
            recentExportSettings.filterParameter.value.includes(param._id)
          )
          ?.map((i) => ({
            label: i.name,
            value: i._id,
          }));
        setFilterParameterSelected(filteredParameters);
      }
      if (recentExportSettings?.matchingParameter.type === "custom parameter") {
        const matchinParameters = filteredParams
          .filter((param) =>
            recentExportSettings.matchingParameter.parameters.includes(
              param._id
            )
          )
          ?.map((i) => ({
            label: i.name,
            value: i._id,
          }));
        setMatchingParameterId(matchinParameters);
      }
    }
  }, [filteredParams, recentExportSettings]);

  const { loading, request: getRecentExportSetting } = useHttp({
    requestCallback: () => getRecentExportSettings(company),
    onLoad: async (response) => {
      if (response?.filterParameter?.type === "custom parameter") {
        setShowFilterCustomParameter(true);
      }

      if (response?.filterParameter?.type === "bidlight parameter") {
        setShowFilterBidlightParameter(true);
        const bidlightFilters = parentProjectOptions?.filter((option) =>
          response?.filterParameter?.value.includes(option.value)
        );
        console.log(bidlightFilters);
        setFilterParamsProjectObj(bidlightFilters);
      }

      if (response?.matchingParameter?.type === "custom parameter") {
        setShowExtraMatchingParameter(true);
      }

      const filterParameter = PARAMETER_TYPE_OPTIONS.find(
        (item) => item?.value === response?.filterParameter?.paramType
      );
      const matchingParameter = PARAMETER_TYPE_OPTIONS.find(
        (item) => item?.value === response?.matchingParameter?.paramType
      );

      setSelectedMatchingParameter(matchingParameter);
      setSelectedFilterParameter(filterParameter);
      setRecenetExportSettings(response);
      handle2DElementCheckbox(response?.include2DElements);
      handleMiscellaneousElementCheckbox(response?.includeMiscellaneous);
      handleExcludeEmptyGeometry(response?.excludeEmptyGeometry)
      handleExcludeElementsNotInView(response?.excludeElementsNotInView);
      const allMileStones = await getMileStone();
      const milestoneOptions = allMileStones?.map((i) => ({
        label: i.key,
        value: i._id,
      }));

      const settingMileStone = milestoneOptions?.find(
        (mileStoneItem) => mileStoneItem?.value === response?.milestone
      );

      setSelectedMilstone(settingMileStone);
      setMilestones(milestoneOptions);
    },
    onError: (res) => {
      console.log("Error**", res);
    },
  });

  const handle2DElementCheckbox = (value = false) => {
    setInclude2DElements(value);
  };

  const handleExcludeEmptyGeometry = (value = false) => {
    setExcludeEmptyGeometry(value);
  };

  const handleExcludeElementsNotInView = (value = false) => {
    setExcludeElementsNotInView(value);
  };

  const handleMiscellaneousElementCheckbox = (value = false) => {
    setIncludeMiscellaneous(value);
  };

  const { loading: loadingNew, request: createNewExportSetting } = useHttp({
    requestCallback: (data) => createProjectExportSettings(data),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("Error**", res);
    },
  });

  const { loading: updateLoading, request: updateExportSetting } = useHttp({
    requestCallback: (data) => updateProjectExportSettings(data),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("Error**", res);
    },
  });

  const { request: getParametersAPI } = useHttp({
    requestCallback: () => getParameters({ companyId: company }),
    onLoad: (res) => {
      setFilteredParams(res?.parameters);
    },
    onError: (res) => {},
  });

  const { request: getMileStone } = useHttp({
    requestCallback: () => getMileStones(company),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("Error**", res);
    },
  });

  const handleChangeFilterParameterType = async (filter) => {
    const filteredObj = filteredParams?.filter(
      (i) => i?.parameterType === filter.value
    );
    const filteredObjArr = filteredObj?.map((i) => ({
      label: i.name,
      value: i._id,
    }));
    // const res = await getParameters ({type: filter.value , companyId: userInfoRedux?.company})
    setFilteredParamsNameList(filteredObjArr);
    setFilteredParamsType({ ...filter });
    setSelectedFilterParameter({ ...filter });
  };

  const handleChangeMatchingParameterType = async (filter) => {
    const filteredObj = filteredParams?.filter(
      (i) => i?.parameterType === filter.value
    );
    const filteredObjArr = filteredObj?.map((i) => ({
      label: i.name,
      value: i._id,
    }));
    // const res = await getParameters ({type: filter.value , companyId: userInfoRedux?.company})
    setMatchingParameterId([]);
    setMatchingParamsNameList(filteredObjArr);
    setMatchingParamsType({ ...filter });
    setSelectedMatchingParameter({ ...filter });
  };

  const hanldeProjectSettingApiRequest = () => {
    const filterParameter = {
      type: showFilterBidlightParameter
        ? "bidlight parameter"
        : showFilterCustomParameter
        ? "custom parameter"
        : "None",
      paramType: showFilterCustomParameter
        ? filteredParamsType?.value
        : showFilterBidlightParameter
        ? "bidlight parameter"
        : "None",
      value: showFilterBidlightParameter
        ? filterParamsProjectObj?.map((i) => i.value)
        : showFilterCustomParameter
        ? filterParameterSelected?.map((i) => i.value)
        : [],
    };

    const matchingParameter = {
      type: showExtraMatchingParameter ? "custom parameter" : "Default",
      paramType: matchingParamsType?.value,
      parameters: matchingParameterId?.map((i) => i.value),
    };

    const payload = {
      companyId: company,
      projectId: recentExportSettings?.projectId,
      milestone: selectedMilestone?.value,
      includeMiscellaneous: includeMiscellaneous,
      include2DElements: include2DElements,
      matchingParameter: matchingParameter,
      filterParameter: filterParameter,
    };

    if (recentExportSettings) {
      payload._id = recentExportSettings?._id;
      updateExportSetting(payload);
    } else {
      createNewExportSetting(payload);
    }
  };

  const usersRedux = useSelector((s) => s.user.users);
  const milestonesRedux = useSelector((s) => s.user.milestones);

  let usersObj =
    usersRedux &&
    usersRedux.reduce((acc, val) => ({ ...acc, [val._id]: val }), {});

  //-------------------------------------------------- project export settings
  const [isOpen, setOpen] = useState(false);
  const [isOpenProjectExportSettings, setOpenProjectExportSettings] =
    useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [projectToEdit, setProjectToEdit] = useState(null);

  const getExportSettingsOfProject = (item) => {
    setOpenProjectExportSettings(true);
    setHeaderTitle(item?.title);
    setProjectToEdit(item);
  };

  //----- project export settings ends

  const [bulkEditProject, setBulkEditProject] = useState([]);
  const [openBulkEditModel, setBulkEditModel] = useState(false);

  const handleCheckboxChange = (item) => {
    if (bulkEditProject.includes(item)) {
      // If yes, remove it
      setBulkEditProject(
        bulkEditProject.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      // If no, add it
      setBulkEditProject([...bulkEditProject, item]);
    }
  };

  return (
    <>
      <div className="personal-area">
        <TopNavbar handleSearch={handleSearch} />

        <div className="content-bg p-2">
          <div className="container d-flex mt-1">
            <Sidebar />

            <div className="profile-content px-0 pt-0 position-relative">
              <div className="w-100 px-0">
                <div className="d-flex justify-content-between px-3 py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    {isOpenProjectExportSettings ? (
                      <i
                        className="fa fa-arrow-left cursor-pointer"
                        onClick={() => setOpenProjectExportSettings(false)}
                      ></i>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M9.95401 2.21109C11.2876 1.93193 12.6807 1.92312 14.0449 2.20834C14.2219 3.36789 14.9048 4.43941 15.9997 5.07152C17.0945 5.70362 18.364 5.75933 19.4566 5.33279C20.3858 6.37167 21.0747 7.58252 21.4997 8.87701C20.5852 9.61007 19.9997 10.7365 19.9997 11.9997C19.9997 13.2637 20.5859 14.3907 21.5013 15.1237C21.29 15.7641 21.0104 16.3927 20.6599 16.9997C20.3094 17.6068 19.9049 18.1632 19.4559 18.6664C18.3634 18.2401 17.0943 18.296 15.9997 18.9279C14.9057 19.5595 14.223 20.6299 14.0453 21.7884C12.7118 22.0675 11.3187 22.0763 9.95443 21.7911C9.77748 20.6316 9.09451 19.56 7.99967 18.9279C6.90484 18.2958 5.63539 18.2401 4.54272 18.6667C3.61357 17.6278 2.92466 16.4169 2.49964 15.1224C3.41412 14.3894 3.99968 13.2629 3.99968 11.9997C3.99968 10.7358 3.41344 9.60876 2.49805 8.87573C2.70933 8.23531 2.98894 7.60678 3.33942 6.99972C3.68991 6.39266 4.09443 5.83625 4.54341 5.33306C5.63593 5.7593 6.90507 5.70349 7.99967 5.07152C9.09364 4.43991 9.7764 3.36959 9.95401 2.21109ZM11.9997 14.9997C13.6565 14.9997 14.9997 13.6566 14.9997 11.9997C14.9997 10.3429 13.6565 8.99972 11.9997 8.99972C10.3428 8.99972 8.99967 10.3429 8.99967 11.9997C8.99967 13.6566 10.3428 14.9997 11.9997 14.9997Z"
                          fill="black"
                        />
                      </svg>
                    )}
                    <span
                      className="font-weight-bold ml-3 cursor-pointer text-black"
                      onClick={() => setOpenProjectExportSettings(false)}
                    >
                      Export Settings
                    </span>
                    {isOpenProjectExportSettings && (
                      <div className="d-flex">
                        <span className="mx-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                          >
                            <path
                              d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                              fill="#9E9E9E"
                            ></path>
                          </svg>
                        </span>
                        <span className="font-weight-bold">{headerTitle}</span>
                      </div>
                    )}
                  </div>
                  {activeTab === TABS.GLOBAL && (
                    <div
                      className="header-btn bg-bid text-white border-bid rounded-lg h6 ml-auto mb-0 cursor-pointer mr-1"
                      onClick={() => {hanldeProjectSettingApiRequest()
                        eventTrack(
                          "Save-ExportSettings-Action",
                          "Save-ExportSettings",
                          userInfoRedux?.name,
                          "+1",
                          false,
                          "+1"
                        );
                      }}
                    >
                      <span className="f-14">
                        <i className="fa fa-save mr-2 "></i> Save
                      </span>
                    </div>
                  )}

                  {activeTab === TABS.PROJECT &&
                    bulkEditProject.length > 0 &&
                    !isOpenProjectExportSettings && (
                      <div
                        className="header-btn bg-bid text-white border-bid rounded-lg h6 ml-auto mb-0 cursor-pointer mr-1"
                        onClick={() => setBulkEditModel(true)}
                      >
                        <span className="f-14">
                          <i className="fa fa-save mr-2 "></i> Bulk Assign
                        </span>
                      </div>
                    )}
                </div>

                {!isOpenProjectExportSettings && (
                  <div
                    className="btn-group btn-group-toggle toggle-group mt-3 w-75 mx-3 report-btn-group"
                    data-toggle="buttons"
                  >
                    <label
                      className={`${"btn btn-secondary py-3"} ${
                        activeTab === TABS.GLOBAL ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(TABS.GLOBAL)}
                    >
                      <input type="radio" name="options" id="option1" defaultChecked />
                      Global Settings
                    </label>
                    <label
                      className={`${"btn btn-secondary py-3"} ${
                        activeTab === TABS.PROJECT ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(TABS.PROJECT)}
                    >
                      <input type="radio" name="options" id="option2" />
                      Project Settings
                    </label>
                  </div>
                )}

                {isOpenProjectExportSettings ? (
                  <ExportSettingsProjectView
                    projectToEdit={projectToEdit}
                    filteredParams={filteredParams}
                  />
                ) : activeTab === TABS.GLOBAL ? (
                  loading || loadingNew || updateLoading ? (
                    <Loader />
                  ) : (
                    <div className="p-3">
                      <div className="border rounded-3 bg-white p-3 pb-4">
                        <div className="form-group-div">
                          <div>
                            <label className="inter pl-2">Milestone</label>
                            <Select
                              options={milestones}
                              value={selectedMilestone}
                              onChange={(options) =>
                                setSelectedMilstone(options)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
                        <label className="inter pl-2 f-14">
                          Select the preferred matching parameter
                        </label>
                        <div>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input rounded-checkbox"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked={!showExtraMatchingParameter}
                              onChange={(e) =>
                                setShowExtraMatchingParameter(false)
                              }
                              value={showExtraMatchingParameter}
                            />
                            <label
                              className="form-check-label inter f-14"
                              htmlFor="flexRadioDefault1"
                            >
                              Use revit default category
                            </label>
                          </div>
                          <div className="form-check mt-3">
                            <input
                              className="form-check-input rounded-checkbox"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              onChange={(e) =>
                                setShowExtraMatchingParameter(true)
                              }
                              checked={showExtraMatchingParameter}
                              value={showExtraMatchingParameter}
                            />
                            <label
                              className="form-check-label inter f-14"
                              htmlFor="flexRadioDefault2"
                            >
                              Use custom parameter
                            </label>
                          </div>
                        </div>

                        {showExtraMatchingParameter && (
                          <>
                            <div className="form-group-div mt-3">
                              <div>
                                <label className="inter pl-2">
                                  Project parameter
                                </label>
                                <Select
                                  options={PARAMETER_TYPE_OPTIONS}
                                  onChange={(option) =>
                                    handleChangeMatchingParameterType(option)
                                  }
                                  value={selectedMatchingParameter}
                                />
                              </div>
                            </div>

                            <div className="form-group-div mt-3">
                              <div>
                                <label className="inter pl-2">
                                  Input parameter
                                </label>
                                <div className="form-group-div mt-3">
                                  <Select
                                    options={matchingParamsNameList}
                                    isMulti
                                    value={matchingParameterId}
                                    onChange={(option) => {
                                      setMatchingParameterId(option);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
                        <label className="inter pl-2 f-14">
                          Select the preferred export filtering parameter
                        </label>
                        <div>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input rounded-checkbox"
                              type="radio"
                              name="filterParameterRad"
                              id="filterParameterRad1"
                              checked={
                                !showFilterBidlightParameter &&
                                !showFilterCustomParameter
                              }
                              onChange={(e) => {
                                setShowFilterBidlightParameter(false);
                                setShowFilterCustomParameter(false);
                              }}
                            />
                            <label
                              className="form-check-label inter f-14"
                              htmlFor="filterParameterRad"
                            >
                              None
                            </label>
                          </div>
                          <div className="form-check mt-3">
                            <input
                              className="form-check-input rounded-checkbox"
                              type="radio"
                              name="filterParameterRad"
                              id="filterParameterRad2"
                              checked={
                                showFilterBidlightParameter &&
                                !showFilterCustomParameter
                              }
                              onChange={(e) => {
                                setShowFilterBidlightParameter(true);
                                setShowFilterCustomParameter(false);
                              }}
                            />
                            <label
                              className="form-check-label inter f-14"
                              htmlFor="filterParameterRad2"
                            >
                              Use BidLight project ID
                            </label>
                          </div>
                        </div>

                        {showFilterBidlightParameter && (
                          <div>
                            <div className="form-group-div mt-3">
                              <Select
                                options={parentProjectOptions}
                                isMulti
                                value={filterParamsProjectObj}
                                onChange={(e) => setFilterParamsProjectObj(e)}
                              />
                            </div>
                          </div>
                        )}

                        {/* <div className="d-flex justify-content-between gap-3">
                        <div className="form-group-div mt-3 w-100">
                          <div>
                            <input type="text" placeholder="Parameter name" />
                          </div>
                        </div>
                        <div className="form-group-div mt-3 w-100">
                          <div>
                            <input type="text" placeholder="Parameter value" />
                          </div>
                        </div>
                      </div> */}

                        <div className="form-check mt-3">
                          <input
                            className="form-check-input rounded-checkbox"
                            type="radio"
                            name="filterParameterRad"
                            id="filterParameterRad3"
                            checked={
                              !showFilterBidlightParameter &&
                              showFilterCustomParameter
                            }
                            onChange={(e) => {
                              setShowFilterBidlightParameter(false);
                              setShowFilterCustomParameter(true);
                            }}
                          />
                          <label
                            className="form-check-label inter f-14"
                            htmlFor="filterParameterRad3"
                          >
                            Use Custom parameter
                          </label>
                        </div>

                        {showFilterCustomParameter && (
                          <>
                            <div className="form-group-div mt-3">
                              <div>
                                {/* <label className="inter pl-2">Project parameter</label> */}
                                <Select
                                  options={PARAMETER_TYPE_OPTIONS}
                                  onChange={(option) =>
                                    handleChangeFilterParameterType(option)
                                  }
                                  value={selectedFilterParameter}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between gap-3">
                              {/* <div className="form-group-div mt-3 w-100">
                              <div>
                                <input type="text" placeholder="Parameter name" />
                              </div>
                            </div> */}
                              <div className="form-group-div mt-3 w-100">
                                <div>
                                  <Select
                                    options={filteredParamsNameList}
                                    isMulti
                                    onChange={(option) =>
                                      setFilterParameterSelected(option)
                                    }
                                    value={filterParameterSelected}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
                        <label className="inter pl-2 f-14">
                          Additional export configuration
                        </label>
                        <div>
                          <div className="form-check">
                            <input
                              className="form-check-input squared-checkbox"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={includeMiscellaneous}
                              onChange={(e) =>
                                handleMiscellaneousElementCheckbox(
                                  e?.target?.checked
                                )
                              }
                            />
                            <label
                              className="form-check-label f-14 inter"
                              htmlFor="flexCheckDefault"
                            >
                              Include rooms spaces and miscellaneous items
                            </label>
                          </div>
                          <div className="form-check mt-3">
                            <input
                              className="form-check-input squared-checkbox"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked={include2DElements}
                              onChange={(e) =>
                                handle2DElementCheckbox(e?.target?.checked)
                              }
                            />
                            <label
                              className="form-check-label inter f-14"
                              htmlFor="flexCheckChecked"
                            >
                              Include 2D Elements
                            </label>
                          </div>
                          <div className="form-check mt-3">
                            <input
                              className="form-check-input squared-checkbox"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked={excludeEmptyGeometry}
                              onChange={(e) =>
                                handleExcludeEmptyGeometry(e?.target?.checked)
                              }
                            />
                            <label
                              className="form-check-label inter f-14"
                              htmlFor="flexCheckChecked"
                            >
                              Exclude empty geometry
                            </label>
                          </div>
                          <div className="form-check mt-3">
                            <input
                              className="form-check-input squared-checkbox"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked={excludeElementsNotInView}
                              onChange={(e) =>
                                handleExcludeElementsNotInView(e?.target?.checked)
                              }
                            />
                            <label
                              className="form-check-label inter f-14"
                              htmlFor="flexCheckChecked"
                            >
                              Exclude elements not in view
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : loading || loadingNew || updateLoading ? (
                  <Loader />
                ) : (
                  <div className="pt-3">
                    <div className="project-table-div overflow-hidden rounded-0">
                      <table className="bid-table project-list-view-table border-top-none">
                        <thead>
                          <tr>
                            <th className="pl-0"></th>
                            <th>Name</th>
                            <th>Exported By</th>
                            <th>Milestone</th>
                            <th>Members</th>
                            <th>Estimation date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectsRedux?.map((item, key) => (
                            <tr key={key}>
                              <td className="pl-0">
                                <input
                                  type="checkbox"
                                  className="squared-checkbox"
                                  onChange={() => handleCheckboxChange(item)}
                                />
                              </td>
                              <td>{item?.title}</td>
                              <td>
                                {item?.zipInfo?.exportedBy?.name
                                  ? `${item?.zipInfo?.exportedBy.name} ${item?.zipInfo?.exportedBy.lastName}`
                                  : item?.zipInfo?.exportedBy ?? "N/A"}
                              </td>
                              <td>
                                {
                                  milestonesRedux?.find(
                                    (i) => i?._id === item?.milestone
                                  )?.key
                                }
                              </td>
                              <td>
                                {" "}
                                <div className="users-profile-list">
                                  {item?.users
                                    .slice(0, 4)
                                    ?.map((val, index) => (
                                      <img
                                        key={index}
                                        className="u-profile"
                                        src={
                                          usersObj &&
                                          usersObj[val]?.profilePhoto
                                        }
                                        onError={(e) =>
                                          (e.target.src = DefaultImage)
                                        }
                                        alt={`User ${index + 1}`}
                                        title={
                                          usersObj &&
                                          `${usersObj[val]?.name} ${usersObj[val]?.lastName}`
                                        }
                                      />
                                    ))}
                                  {item?.users.length > 4 && (
                                    <div className="u-profile">
                                      +${item?.users.length - 1}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>{formatTime(item?.date)}</td>
                              <td>
                                <div
                                  onClick={() => {
                                    getExportSettingsOfProject(item);
                                    eventTrack(
                                      "View-Export-Settings-Action",
                                      "View-Export-Settings",
                                      userInfoRedux?.name,
                                      "+1",
                                      false,
                                      "+1"
                                    );
                                  }}
                                  className="cursor-pointer"
                                >
                                  <img
                                    src={EyeImg}
                                    alt="eye"
                                    className="action-icon"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ExportSettingsBulkAssignModel
        isOpen={openBulkEditModel}
        toggle={() => setBulkEditModel(false)}
        filteredParams={filteredParams}
        bulkEditProject={bulkEditProject}
        recentExportSettings={recentExportSettings}
      />
    </>
  );
};

export default ExportSettings;
