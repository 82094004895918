import constants from "../constants";
import { v4 as uuidv4 } from "uuid";
import JSZip from "jszip";

export async function uploadFile(body) {
  const url = `${constants.SERVER_URL}/projects/preupload`;
  const token = localStorage.getItem('Auth');
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json",
      "User-Agent": "Bidlight-Web-Deployment",
  
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
    }
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
}

export function uploadFileOnURL({url, body}) {
  return fetch(`${url}`, {
    method: "PUT",
    timeout: 'infinte',
    body: body,
    headers: {
      'Content-Type': 'application/zip'
    }
  }).then(async (res) => {
    if (!res.ok) {
      throw await res
    }
    return await res
  });
}

export const uploadFileToServer = async (file) => {
  try {
    const zip = new JSZip();
    const fileExt = file.name.split(".").pop();
    const name = uuidv4() + "." + fileExt + "." + "gz";

    const res = await uploadFile({ fileNames: [name] });
    if (res) {
      zip.file(file.name, file);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const formObj = {
        url: res?.uploadUrls[0],
        body: zipBlob,
      };
      await uploadFileOnURL(formObj);
    }

    return name;
  } catch (error) {
    console.error("Error uploading file to server:", error);
    return null; // Return null in case of error
  }
};

export const extractImageFromZip = async (uri) => {
  try {
    if (uri?.split(".")?.pop() !== "gz") {
      return uri;
    } else {
      const response = await fetch(`${constants.SERVER_URL}/uploads/${uri}`);
      const data = await response.arrayBuffer();
      const zip = await JSZip.loadAsync(data);

      // Filter the files to only include image files
      const imageFiles = Object.keys(zip.files).filter((filename) =>
        /\.(jpe?g|png|gif)$/i.test(filename)
      );

      const imageUrls = await Promise.all(
        imageFiles.map(async (filename) => {
          const blob = await zip.file(filename).async("blob");
          return window.URL.createObjectURL(blob);
        })
      );
      return imageUrls;
    }
  } catch (error) {
    console.error("Error extracting images from zip:", error);
    return uri; // Return zip link if there's an error
  }
};
