import React from 'react';

export const WalletIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3379 6.41667V4.58333H3.67122V17.4167H18.3379V15.5833H11.0046C10.4983 15.5833 10.0879 15.1729 10.0879 14.6667V7.33333C10.0879 6.82708 10.4983 6.41667 11.0046 6.41667H18.3379ZM2.75456 2.75H19.2546C19.7609 2.75 20.1712 3.16041 20.1712 3.66667V18.3333C20.1712 18.8396 19.7609 19.25 19.2546 19.25H2.75456C2.2483 19.25 1.83789 18.8396 1.83789 18.3333V3.66667C1.83789 3.16041 2.2483 2.75 2.75456 2.75ZM11.9212 8.25V13.75H18.3379V8.25H11.9212ZM13.7546 10.0833H16.5046V11.9167H13.7546V10.0833Z"
        fill="#9C9C9C"
      />
    </svg>
  );
};
