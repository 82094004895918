import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout";
import QuestionWrapper from "../components/question/question-wrapper";
import QuestionStats from "../components/question/question-stats";
import QuestionSummary from "../components/question/question-summary";
import PageTitle from "../components/page-title";
import ButtonGroup from "../components/button-group";
import { Spinner } from "../components/icons";
import Seo from "../components/seo/Seo";
import Sidebar from "learning/components/layout/sidebar";
import Extra from "learning/components/layout/extra";
import { url } from "api";
import { getQuestions } from "api/questions/getQuestions";
import { useHttp } from "hooks/useHttp";
import addNotification from "helpers/notify";
import { getQuestionByTag } from "api/questions/getQuestionByTag";
import Pagination from "components/Pagination/Pagination";

const HomePage = ({ anonymous, userInfoRedux, query }) => {
  const [questions, setQuestions] = useState(null);
  const [sortType, setSortType] = useState("Votes");

  const [totalRecords, setTotalRecords] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const data = useRef({ index: 0, limit: 10 });

  const { loading: apiLoading, request: fetchQuestionAPI } = useHttp({
    requestCallback: (data) => getQuestions(data?.current),
    onLoad: async (res) => {
      setQuestions(res?.questionsAll);
      setTotalRecords(res?.totalCount);
      setTotalPage(Math.ceil(res?.totalCount / data?.current?.limit));
    },
    onError: (res) => {
      console.log("err", res);
      addNotification("Something went wrong", "danger");
    },
  });

  const { request: fetchQuestionByTagAPI } = useHttp({
    requestCallback: (data) => getQuestionByTag(data?.current, query),
    onLoad: async (res) => {
      setQuestions(res?.questionsAll);
      setTotalRecords(res?.totalCount);
      setTotalPage(Math.ceil(res?.totalCount / data?.current?.limit));
    },
    onError: (res) => {
      console.log("err", res);
      addNotification("Something went wrong", "danger");
    },
  });

  useEffect(() => {
    data.current = {
      ...data.current,
      index: page,
      column: "",
      search: searchValue,
      sort: "",
      sort_dir: -1,
    };

    console.log(data);
    if (query) {
      fetchQuestionByTagAPI(data);
    } else {
      fetchQuestionAPI(data);
    }
  }, [page]);

  const handleSorting = () => {
    switch (sortType) {
      case "Votes":
        return (a, b) => b.votes.length - a.votes.length;
      case "Views":
        return (a, b) => b.views - a.views;
      case "Newest":
        return (a, b) => new Date(b.created) - new Date(a.created);
      case "Oldest":
        return (a, b) => new Date(a.created) - new Date(b.created);
      default:
        break;
    }
  };

  const SearchFunction = () => {
    setQuestions([]);
    data.current = {
      ...data.current,
      index: 0,
      column: "",
      search: searchValue,
      sort: "",
      sort_dir: -1,
    };
    if (query) {
      fetchQuestionByTagAPI(data);
    } else {
      fetchQuestionAPI(data);
    }
    setTotalPage(0);
    setPage(0);
  };

  return (
    <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>
      <Seo
        title={
          query
            ? `Question ${query} | BidLight BIM Revit Plugin`
            : "BidLight BIM Revit Plugin Questions"
        }
        description={
          query
            ? `Question ${query} BidLight BIM Revit Plugin`
            : "BidLight BIM Revit Plugin Questions"
        }
        siteUrl={query ? `${url}/learning/?tag=${query}` : `${url}/learning`}
        keywords={
          query
            ? ` BidLight BIM Revit Plugin ${query}`
            : "BidLight BIM Revit Plugin Questions"
        }
        authorUsername={"BidLight"}
        ogImage={`${url}/marketing/branding/hotlink-ok/question.png`}
      />
      <div className="personal-area min-h-full">
        <div className="container-fluid pt-3 learning-container-flex">
          <Sidebar />
          <div className="learning-content">
            <div>
              <PageTitle
                title={query ? `Questions tagged [${query}]` : "All Questions"}
                button
                borderBottom={false}
              />
              <div className="d-flex justify-content-start">
                <div className="search-div w-100 mt-0 ml-4">
                  <div className="input-with-cancelBtn">
                    <input
                      type="text"
                      className="search-bar"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search"
                    />
                    {searchValue?.length > 0 && (
                      <button
                        onClick={() => {
                          setSearchValue("");
                          data.current = {
                            ...data.current,
                            index: 0,
                            search: null,
                            column: "",
                          };
                          if (query) {
                            fetchQuestionByTagAPI(data);
                          } else {
                            fetchQuestionAPI(data);
                          }
                        }}
                        className="search-btn1"
                      >
                        <i className="fa fa-times" />
                      </button>
                    )}
                  </div>

                  <button
                    disabled={!searchValue?.length > 0}
                    onClick={() => SearchFunction()}
                    className="search-btn2 bg-bid text-white mr-0"
                  >
                    <i className="fa fa-search me-2" />
                    Search
                  </button>
                </div>
                <ButtonGroup
                  borderBottom
                  buttons={["Votes", "Views", "Newest", "Oldest"]}
                  selected={sortType}
                  setSelected={setSortType}
                />
              </div>

              {!questions && (
                <div className="loading">
                  <Spinner />
                </div>
              )}

              {questions?.length === 0 ? (
                <p>No Record Found</p>
              ) : (
                questions
                  ?.sort(handleSorting())
                  .map(
                    ({
                      id,
                      votes,
                      answers,
                      views,
                      title,
                      text,
                      tags,
                      author,
                      created,
                      feature_img,
                    }) => (
                      <QuestionWrapper key={id}>
                        <QuestionStats
                          voteCount={votes.length}
                          answerCount={answers.length}
                          view={views}
                        />
                        <QuestionSummary
                          id={id}
                          title={title}
                          tags={tags}
                          author={author}
                          createdTime={created}
                          feature_img={feature_img}
                        >
                          {text}
                        </QuestionSummary>
                      </QuestionWrapper>
                    )
                  )
              )}

              <div className="asset-pagination-div mx-auto">
                <Pagination
                  totalRecords={totalRecords}
                  totalPage={totalPage}
                  page={page + 1}
                  onPageChange={(pageNo) => setPage(pageNo - 1)}
                  onLimitChange={(count) => {
                    data.current = { ...data.current, limit: count };
                    if (page === 0) {
                      if (query) {
                        fetchQuestionByTagAPI(data);
                      } else {
                        fetchQuestionAPI(data);
                      }
                    } else {
                      setPage(0);
                    }
                  }}
                  limit={data.current?.limit}
                />
              </div>
            </div>
          </div>
          <Extra />
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
