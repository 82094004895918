import Viewer from "../viewer";
import Loader from "../loader";

export default class WebGlApp {
  constructor(container, glbs, ReactAPI, line, socketviewer, isIfcProject) {
    this.viewer = new Viewer(
      container,
      ReactAPI,
      line,
      socketviewer,
      isIfcProject
    );
    this.loader = new Loader(this.viewer, glbs, ReactAPI, isIfcProject); //list of uniqId
  }
}
