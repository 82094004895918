import React, { useState, useEffect } from "react";
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import moment from "moment";
import { useDispatch } from "react-redux";
import { rmShare } from "api/share";
import "./style.scss";
import {eventTrack} from 'helpers/ga4Helper'


const ShareModal = ({ toggle, open, projectItem, setProjectItem }) => {
  const [search, setSearch] = useState("");
  const [btn, setBtn] = useState("Pending");
  const dispatch = useDispatch();

  const rm = (id) => {
    rmShare(id);

    dispatch({
      type: "RMSHARE",
      payload: {
        ...projectItem,
        timelines: projectItem.timelines?.map((item) => {
          return {
            ...item,
            pending_share: item.pending_share?.filter((el) => el._id !== id),
            shared_with: item.shared_with?.filter((el) => el._id !== id),
          };
        }),
      },
    });
    setProjectItem((prev) => {
      return {
        ...prev,
        timelines: prev.timelines?.map((item) => {
          return {
            ...item,
            pending_share: item.pending_share?.filter((el) => el._id !== id),
            shared_with: item.shared_with?.filter((el) => el._id !== id),
          };
        }),
      };
    });
  };
  return (
    <MDBModal toggle={toggle} isOpen={open} className="big" centered>
      <MDBModalHeader toggle={toggle} className="d-flex mb-0 pb-2">
        Shared Access
      </MDBModalHeader>
      <MDBModalBody className="pt-1">
          <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center justify-content-between w-50">
          <MDBBtn
            size="sm"
            color=""
            className={btn === 'Pending' ? 'sharePending-button active-toggle' : 'sharePending-button'}
            onClick={() => {
              setBtn("Pending");
            }}
          >
            Pending
          </MDBBtn>
          <MDBBtn
            size="sm"
            color=""
            className={btn === 'Shared' ? 'sharePending-button active-toggle' : 'sharePending-button'}
            
            
                onClick={() => {
              setBtn("Shared");
              {eventTrack("View-Share-Action","View-Share",projectItem.title,'+1',false,'+1')}
            }}
          > 
            Shared
          </MDBBtn>
        </div>
        <h5 className="text-status">Status : {btn}</h5>
        </div>
        <div className="form-group mb-2">
          <input
            type="text"
            className="shared-input-search"
            placeholder="Search by email.."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="tbody">
          <div className="tr theader">
            <div className="td">Email</div>
            <div className="td">Estimation</div>
            <div className="td">Access Options</div>
            <div className="td"></div>
          </div>
          {projectItem?.timelines?.map((item) => {
            return (
              btn === "Pending"
                ? item.pending_share || []
                : item.shared_with || []
            )
              .filter((item) => item.email?.includes(search))
              .map((el) => (
                <div key={el._id} className="tr">
                  <div className="td">{el.email}</div>
                  <div className="td">
                    {moment(item.date).format("DD MMMM YYYY")} by {item.doneBy?.name ?? ""} {item.doneBy?.lastName ?? ""}
                  </div>
                  <div className="td">
                    {el.navigation && "navigation, "}
                    {el.estimation_excel && "estimation, "}
                    {el.estimation_excel_awp && "trades, "}
                    {el.prices && "prices, "}
                    {el.quantity_excel && "quantity, "}
                    {el.schedules && "schedules, "}
                   {/* {el.takeoff && "takeoff, "} */}
                  </div>
                  <div className="tr-delete" onClick={() => rm(el._id)} />
                </div>
              ));
          })}
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

export default ShareModal;
