import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { UserConversation } from "./UserConversation";

export const UserChat = (receiverId) => {
  const [allowChat, setAllowChat] = useState(false);
  const userInfo = useSelector((state) => state.user.userInfo);

  const handleChatToggle = () => {
    setAllowChat(!allowChat);
  };

  return (
    <div
      className="w-full d-block position-fixed float-end d-flex flex-column"
      style={{
        bottom: "82px",
        right: "3.7%",
        zIndex: 9999,
        cursor: "pointer",
      }}
    >
      {allowChat && (
        <UserConversation setAllowChat={setAllowChat} />
      )}
      {!userInfo?.admin && <div className="position-absolute right-0 bottom-0" style={{ right: 0 }}>
        <span onClick={handleChatToggle} className="chat-icon">
          {allowChat ? (
              <i class="bi bi-chat-dots"></i>
          ) : (
            <i class="bi bi-chat-text-fill"></i>
          )}
        </span>
      </div>}
    </div>
  );
};
