import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./sidebar";
import { useDispatch, useSelector } from "react-redux";
import addNotification from "../../helpers/notify";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import Loading from "../../components/ui-components/loading";
import { getMileStones } from "api/milestones/getMilestones";
import {
  deleteMileStone,
  deleteBulkMileStone,
} from "api/milestones/deleteMilestone";
import { createMilestone } from "api/milestones/createMilestone";
import { editMilestone } from "api/milestones/editMilestone";
import { tokenRef, url } from "api";
import axios from "axios";
import TopNavbar from "./navbar";
import Drawer from "./drawer";
import { RequestCard } from "./requestCard";

const RequestAccess = () => {
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const [milstones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [milestonetodelete, setDelete] = useState("");
  const [milestonekeytodelete, setmilstonetoDelete] = useState("");
  const modalRef = useRef();

  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkloading, setBulkLoad] = useState(false);
  const hiddenFileInput = useRef(null);
  const [spinner, setSpinner] = useState(false);

  const [milestonestodelete, setMilestonesDelete] = useState([]);
  const [isOpen2, setOpen2] = useState(false);

  const [id, setId] = useState("");
  const [body, setBody] = useState({
    key: "",
    value: "",
  });

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filtered, setFiltered] = useState("");

  useEffect(() => {
    (async () => {
      const res = await getMileStones(userInfoRedux.company);
      setMilestones(res);
      console.log("res-milstones------", res);
    })();
  }, []);

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setBody((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = (milestone) => {
    setBody(milestone);
    setId(milestone._id);
  };

  const handleSave = async () => {
    if (id) {
      const res = await editMilestone(body);
      console.log("res-------", res);
      if (!res.error) {
        setMilestones((prevMile) => {
          const updatedMiles = prevMile.map((mile) =>
            mile._id == id ? res : mile
          );
          return updatedMiles;
        });
        addNotification("Milestone Updated Successfully! ", "success");
        modalRef.current.click();
      } else {
        addNotification(res?.message, "danger");
        modalRef.current.click();
        // modalRef.current.click();
      }
    } else {
      body.company = userInfoRedux.company;
      try {
        const res = await createMilestone(body);
        setMilestones((prev) => [...prev, res]);
        addNotification("Milestone Created Successfully! ", "success");
        modalRef.current.click();
        console.log("res---create-----", res);
      } catch (err) {
        addNotification(
          "Something went wrong, please contact support",
          "danger"
        );
        modalRef.current.click();
      }
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const res = await deleteMileStone({ _id: milestonetodelete });
    if (!res.error) {
      setMilestones((prev) =>
        prev.filter((pre) => pre._id != milestonetodelete)
      );
      setLoading(false);
      setOpen(false);
      addNotification("Milestone Deleted Successfully! ", "success");
    } else {
      addNotification(res?.message, "danger");
    }
  };

  const handleClear = () => {
    setBody({
      key: "",
      value: "",
    });
    setId("");
  };

  const handleSearch = (keyword) => {
    const filteredResult = milstones.filter((milestone) =>
      milestone?.key.toLowerCase()?.includes(keyword.toLowerCase())
    );

    setFilteredProjects(filteredResult);

    if (keyword.length > 0) {
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <TopNavbar handleSearch={handleSearch} toggleSidebar={toggleSidebar} />
      {/* <Drawer isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/> */}

      <div
        style={{ paddingBottom: "70px" }}
        class="d-flex justify-content-center"
      >
        <Sidebar />
        <div
          className="mx-2 shadow-sm rounded"
          style={{
            padding: "10px",
            width: "60%",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-bag-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4z" />
              </svg>
              <span
                style={{ marginTop: "5px" }}
                className="font-weight-bold ml-2"
              >
                User Access and Permissions
              </span>
            </div>
          </div>

          <RequestCard
            title="Oops, looks like you need to Request Access"
            description="Elevate your experience with exclusive futures, early releases, and premium features."
            buttonText={"Request Access"}
            buttonHandler={() => {}}
          />
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Add New Milestone
                </h5>
                <button
                  onClick={handleClear}
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Key</label>
                    <input
                      value={body.key}
                      onChange={handleOnchange}
                      name="key"
                      type="text"
                      class="form-control"
                      placeholder="Enter Key"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Value</label>
                    <input
                      value={body.value}
                      onChange={handleOnchange}
                      name="value"
                      type="text"
                      class="form-control"
                      placeholder="Enter Value"
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <div
                    onClick={handleClear}
                    ref={modalRef}
                    className="bg-white text-bid border-bid text-inherit rounded py-2"
                    style={{
                      width: "48%",
                      textAlign: "center",
                      color: "black",
                    }}
                    data-dismiss="modal"
                  >
                    Close
                  </div>
                  <div
                    onClick={handleSave}
                    className="border-bid rounded py-2"
                    style={{
                      width: "48%",
                      textAlign: "center",
                      cursor: "pointer",
                      backgroundColor: "#3A5765",
                      color: "white",
                    }}
                  >
                    {id ? "Update" : "Add"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestAccess;
