import React from "react";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import addNotification from "helpers/notify";
import { deleteCatalog } from "api/database";
import { useSelector } from "react-redux";
import { eventTrack } from "helpers/ga4Helper";

export const DeleteCatalogModal = ({
  isDelete,
  closeModal,
  catalogsToDelete,
  protocol,
  getUpdatedData
}) => {
  const allCatalogsRowData = useSelector((s) => s.user.catalogs);

  const handleDeleteCatalog = () => {
    const itemToDeleteIds = catalogsToDelete.map((catalog) => catalog?._id);
    const catalogsRowsToDelete = allCatalogsRowData
      .filter((catalog) => itemToDeleteIds.includes(catalog.company._id))
      .map((catalog) => catalog.company);

    const deletedItemPromise = catalogsRowsToDelete?.map((catalog) => {
      return deleteCatalog(protocol, catalog);
    });
    Promise.all(deletedItemPromise)
      .then((results) => {
        if (results) {
          const error = results.find((result) => result.hasOwnProperty("err"));
          if (error) {
            addNotification(
              error?.err ?? "Something went wrong, please connect with support",
              "danger"
            );
          } else {
            addNotification("Catalogs deleted successfully", "success");
            getUpdatedData();
          }
          closeModal();
        }
      })
      .catch((error) => {
        addNotification(
          error?.message ?? "Something went wrong, please connect with support",
          "danger"
        );
        console.error("Error deleting items:", error);
        closeModal();
      });
  };

  return (
    <div>
      <MDBModal
        isOpen={isDelete}
        toggle={closeModal}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={closeModal}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Catalogs ?</p>
          <p className="delete-modal-label">
            Are you sure you want to delete this catalog? This action cannot be
            undone.
          </p>
        </MDBModalBody>
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={closeModal}
              color=""
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={() => {handleDeleteCatalog()
                {
                  eventTrack(
                    "Delete-Catelogue-Action",
                    "Delete-Catelogue",
                    'Delete-Catelogue',
                    "+1",
                    false,
                    "+1"
                  );
                }
              }}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>
    </div>
  );
};
