import React, { useEffect, useState } from "react";
import logo from "assets/images/logo/logo.png";

import { Navbar, NavbarBrand, Nav } from "reactstrap";

import { Link } from "react-scroll";

import { slide as Menu } from "react-burger-menu";

var styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "20px",
    height: "16px",
    top: "40%",
    right: "20px",
  },
  bmBurgerBars: {
    background: "#000000",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "50px",
    width: "50px",
    top: "20px",
    right: "10px",
  },
  bmCross: {
    background: "#000000",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%", //vh
    top: "0px",
  },
  bmMenu: {
    background: "#fff",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "black !important",
    padding: "1em",
  },
  bmItem: {
    display: "inline-block",
    listStyle: "none",
    paddingTop: "10px !important",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.6)",
    opacity: 1,
    width: "100vw",
  },
};

const navbarItems = [
  {
    navText: "What is BidLight?",
    navLink: "whatisbidlight",
  },
  {
    navText: "Features",
    navLink: "features-section",
  },
  {
    navText: "How to Use BidLight",
    navLink: "howto-section",
  },
  {
    navText: "Pricing",
    navLink: "pricing-section", 
  },
  {
    navText: "About Us",
    navLink: "about",
  },
  // {
  //   navText: "Request a Demo",
  //   navLink: "contact-section",
  // },
  {
    navText: "Learning",
    navLink: "https://app.bidlight.com/learning/articles",
  },
  {
    navText: "FAQ",
    navLink: "https://app.bidlight.com/learning",
  },
];

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const NavLinks = navbarItems.map((item, index) => {
  let linkItem;

  if (item.navLink.startsWith("http")) {
    linkItem = (
      <li key={index}>
        <Link
          activeClass="nav-menu-item-active"
          className="pl-md-5"
          style={{ cursor: "pointer" }}
          to=""
          onClick={() => openInNewTab(item.navLink)}
        >
          {item.navText}
        </Link>
      </li>
    );
  } else {
    linkItem = (
      <li key={index}>
        <Link
          activeClass="nav-menu-item-active"
          className="pl-md-5"
          to={item.navLink}
          spy={true}
          smooth={true}
          duration={1500}
          offset={-100}
          style={{ cursor: "pointer" }}
        >
          {item.navText}
        </Link>
      </li>
    );
  }

  return linkItem;
});

const NavigationComponent = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const href = window.location.href;
    if (href?.includes("#")) {
      const id = `${href.substring(href.indexOf("#") + 1)}`;
      const anchor = document.getElementById(id);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <Navbar
        className={`active1 navBarOverride ${show && "hidden"}`}
        color="white"
        light
        expand="md"
        sticky="top"
        id="navigation"
      >
        <div
          className="d-flex justify-content-between"
          style={{ fontFamily: "poppins-regular", color: "gray" }}
        >
          <NavbarBrand className="bg-dark-blue-header" href="/">
            <div className="text-center d-flex align-items-center justify-content-center">
              <img
                src={logo}
                className="authorization-logo"
                alt="Bidlight Logo"
              />
              <span
                className="logo-title"
                style={{ color: "#000000 !important" }}
              >
                <b>idLight</b>
              </span>
            </div>
          </NavbarBrand>
          <Nav
            className="mobile-hide align-items-center navLinks justify-content-between"
            navbar
          >
            <>
              {NavLinks}

              <li className="loginBtns signup_btn">
                <span className="btnLogin">
                  <a className="signup_btn" href="/authorization">
                    Login
                  </a>
                </span>
                <span className="btnLogin">
                  <Link
                    className="signup_btn"
                    activeClass="nav-menu-item-active"
                    to="pricing-section"
                    spy={true}
                    smooth={true}
                    duration={1500}
                    style={{ cursor: "pointer" }}
                  >
                    Sign Up
                  </Link>
                </span>
              </li>
            </>
          </Nav>

          <Nav className="ml-auto pr-3 desktop-hide navDropToggle" navbar>
            <Menu width="300px" right styles={styles}>
              <ul>
                <li className="signup_btn">
                  <span className="btnLogin">
                    <a className="signup_btn" href="/authorization">
                      Login
                    </a>
                  </span>
                </li>
                <li className="signup_btn">
                  <span className="btnLogin">
                    <Link
                      className="signup_btn"
                      activeClass="nav-menu-item-active"
                      to="pricing-section"
                      spy={true}
                      smooth={true}
                      duration={1500}
                      style={{ cursor: "pointer" }}
                    >
                      Sign Up
                    </Link>
                  </span>
                </li>
                {NavLinks}
              </ul>
            </Menu>
          </Nav>
        </div>
      </Navbar>
    </>
  );
};

export default NavigationComponent;
