import { VIEWER_SET_URLS } from '../constants/action-types/viewer';

const initialState = {
  urls: null,
};

const viewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEWER_SET_URLS:
      return { ...state, urls: action.payload };
    default:
      return state;
  }
};

export default viewerReducer;
