import React from 'react';
export const CheckInIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.334 19.1585L15.8712 14.6212C18.3771 12.1154 18.3771 8.05258 15.8712 5.54671C13.3654 3.04085 9.30258 3.04085 6.79671 5.54671C4.29085 8.05258 4.29085 12.1154 6.79671 14.6212L11.334 19.1585ZM11.334 21.7512L5.50035 15.9177C2.27853 12.6958 2.27853 7.47218 5.50035 4.25035C8.72218 1.02853 13.9458 1.02853 17.1677 4.25035C20.3895 7.47218 20.3895 12.6958 17.1677 15.9177L11.334 21.7512ZM11.334 11.9173C12.3465 11.9173 13.1673 11.0965 13.1673 10.084C13.1673 9.07146 12.3465 8.25065 11.334 8.25065C10.3214 8.25065 9.50065 9.07146 9.50065 10.084C9.50065 11.0965 10.3214 11.9173 11.334 11.9173ZM11.334 13.7507C9.30894 13.7507 7.66732 12.109 7.66732 10.084C7.66732 8.05894 9.30894 6.41732 11.334 6.41732C13.359 6.41732 15.0007 8.05894 15.0007 10.084C15.0007 12.109 13.359 13.7507 11.334 13.7507Z"
        fill="#9C9C9C"
      />
    </svg>
  );
};
