import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { useDispatch, useSelector } from "react-redux";
import { eventTrack } from "../../../helpers/ga4Helper";
import { useHistory } from "react-router";
import Arrow from "assets/images/icons/arrow.png";
import { useHttp } from "hooks/useHttp";
import { getModelFileList } from "api/healthCheck/getModelFileList";
import { getCompanyModelFileList } from "api/healthCheck/getCompanyModelFileList";
import "./style.scss";
import { url } from "api";
import { getUsers } from "api/users/getUsers";
import Loader from "components/Loader";
import moment from "moment";
import Loading from "components/ui-components/loading";
import CurvedRangeSlider from "components/CurvedRangeSlider/CurvedRangeSlider";
import DefaultImage from "assets/images/user.png";
import { setSidebarStatus } from "actions/user";
import DefaultImageProject from "assets/images/Default_Project_Image.png";
import addNotification from "helpers/notify";
import { extractImageFromZip } from "helpers/extractImageFromZip";

export default function ModalFileList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const { companyAdmin, admin, company, name, lastName, email } = userInfoRedux;
  const [AllFileList, setAllFileList] = useState([]);
  const [users, setUsers] = useState([]);
  const [fileDetails, setFileDetails] = useState();
  const [showNoData, setShowNoData] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [filtered, setFiltered] = useState("");
  const [totalScore, setTotalScore] = useState();

  const { detailLoading, request: getModelFileDetailAPI } = useHttp({
    requestCallback: (data) => getModelFileList(data),
    onLoad: (res) => {
      console.log(res);
      if (Object.keys(res).length === 0) {
        setShowNoData(true);
        setShowLoader(false);
      } else {
        setShowLoader(false);
        setFileDetails(res);
        if (res?.report) {
          setShowNoData(false);
          setTotalScore(
            (
              ((Number(res?.report?.summary?.healthStatus.criticalPassedRules) +
                Number(res?.report?.summary?.healthStatus.normalPassedRules)) /
                (Number(res?.report?.summary?.criticalRules) +
                  Number(res?.report?.summary?.normalRules))) *
              100
            ).toFixed(2)
          );
        }
      }
    },
    onError: (res) => {
      setShowNoData(true);
    },
  });

  const { loading, request: getCompanyModelFileListAPI } = useHttp({
    requestCallback: () => getCompanyModelFileList(userInfoRedux.company),
    onLoad: (res) => {
      setAllFileList(res);
    },
    onError: (res) => {
      addNotification(res?.err, "danger");
    },
  });

  const { request: getUsersListAPI } = useHttp({
    requestCallback: () => getUsers(userInfoRedux.company),
    onLoad: (res) => {
      setUsers(res);
      getCompanyModelFileListAPI();
    },
    onError: (res) => {},
  });

  const handleSortModelFIleList = () => {
    const sortedArray = [...AllFileList].sort((a, b) => {
      const divisionA = a.modelName.toUpperCase();
      const divisionB = b.modelName.toUpperCase();
      if (sortOrder === "asc") {
        return divisionA.localeCompare(divisionB, undefined, { numeric: true });
      } else {
        return divisionB.localeCompare(divisionA, undefined, { numeric: true });
      }
    });
    setAllFileList(sortedArray);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    getUsersListAPI();
  }, []);

  const handleSearch = (keyword) => {
    const filteredResult = AllFileList?.filter((list) =>
      list?.modelName.toLowerCase().includes(keyword.toLowerCase())
    );

    setFilteredList(filteredResult);

    if (keyword.length > 0) {
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  return (
    <div className="personal-area">
      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
            <p className="my-heading f-14 ml-3 mr-3">Model File List</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <input
              type="text"
              className="search-bar"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
              // disabled={loading}
            />
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              {admin || companyAdmin ? (
                <button
                  onClick={() => {
                    history.push(`/admin/projects-settings`);
                    {
                      eventTrack(
                        "Navigate-Admin-Action",
                        "Navigate-Admin",
                        name,
                        "+1",
                        false,
                        "+1"
                      );
                    }
                  }}
                  className="bg-bid px-3 mr-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                    fill="none"
                  >
                    <path
                      d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                      fill="white"
                    />
                  </svg>
                  Admin
                </button>
              ) : null}

              <div className="vr mx-3"></div>

              <button
                onClick={handleSortModelFIleList}
                disabled={loading}
                className="px-3 bg-white text-bid shadow-hover"
              >
                {sortOrder === "asc" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                  >
                    <path
                      d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                      fill="#3A5765"
                    />
                    <path
                      d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                      fill="black"
                      fillOpacity="0.2"
                    />
                  </svg>
                ) : (
                  <div className="upsideDown-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                    >
                      <path
                        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                        fill="#3A5765"
                      />
                      <path
                        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                        fill="black"
                        fillOpacity="0.2"
                      />
                    </svg>
                  </div>
                )}
                Sort
              </button>

              <button
                onClick={() => getUsersListAPI()}
                className="px-3 bg-white text-bid shadow-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        {loading ? (
          <Loader />
        ) : (
          <div className="assestmgmt-table-div">
            <table className="bid-table border-top-none sticky-table-header position-sticky top-0 z-1">
              <thead>
                <tr>
                  <th className="col-01">Model Name</th>
                  <th className="col-02">Size</th>
                  <th className="col-03">Latest exporter</th>
                  <th className="col-04">Associated projects</th>
                  <th className="col-05">Created Date</th>
                  <th className="col-06">latest report date</th>
                  <th className="col-07"></th>
                </tr>
              </thead>
            </table>

            <Accordion allowZeroExpanded>
              {filtered
                ? filteredList?.map((item, key) => (
                    <AccordionItem key={key}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <AccordionItemState>
                            {({ expanded }) => (
                              <table
                                className="bid-table fileListTable"
                                onClick={() => {
                                  getModelFileDetailAPI(item?._id);
                                  setShowLoader(true);
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td className="col-01">
                                      {item?.modelName}
                                    </td>
                                    <td className="col-02">
                                      {item?.fileSize}Mb
                                    </td>
                                    <td className="col-03">
                                      {" "}
                                      <div className="d-flex">
                                        <img
                                          src={item?.doneBy?.profilePhoto}
                                          onError={(e) =>
                                            (e.target.src = DefaultImage)
                                          }
                                          className="user-img"
                                        />
                                        <p className="mb-0 f-14 ml-2 poppins-regular text-black">
                                          {item?.doneBy?.name}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="col-04">
                                      <div className="users-profile-list">
                                        {item?.associatedProjects?.map(
                                          (i, index) => {
                                            const imgUrl = extractImageFromZip(
                                              i?.img
                                            );
                                            return (
                                              <img
                                                src={imgUrl}
                                                alt="grp"
                                                key={index}
                                                className="u-profile"
                                              />
                                            );
                                          }
                                        )}
                                        {item?.associatedProjects?.length >
                                          4 && (
                                          <div className="u-profile">
                                            +$
                                            {item?.associatedProjects?.length -
                                              1}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                    <td className="col-05">
                                      {" "}
                                      {moment(item?.createdAt).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </td>
                                    <td className="col-06">
                                      {moment(item?.updatedAt).format(
                                        "DD MMMM YYYY"
                                      )}
                                      {/* <p className="number-count bg-light-success text-success px-2 health-result-status">
                                Great
                              </p> */}
                                    </td>
                                    <td className="col-07">
                                      <img
                                        src={Arrow}
                                        className={
                                          expanded
                                            ? "arrow-icon"
                                            : "arrow-icon  transform-180"
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="position-relative p-2">
                          {detailLoading || showLoader ? (
                            <Loading color="dark" text="loading..." />
                          ) : (
                            <>
                              {showNoData ? (
                                <p>No Data Found</p>
                              ) : (
                                <div className="bg-detail p-3">
                                  <div className="w-100 rounded-2 py-2 px-2 border mt-0">
                                    <div className="row row-low-padding">
                                      <div className="col-md-2 d-flex align-items-center justify-content-center">
                                        <CurvedRangeSlider
                                          totalScore={totalScore}
                                          status={
                                            fileDetails?.report?.summary
                                              ?.reportStatus
                                          }
                                        />
                                      </div>

                                      <div className="col-md-4">
                                        <div className="text-center">
                                          <div className="rules-states-outer-container">
                                            <p className="f-18 mb-3 mt-1 text-bid mb-0 poppins-medium d-block">
                                              Critical rules
                                            </p>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                              <div className="rules-stats-div total">
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.criticalRules
                                                  }
                                                </h2>
                                                <p>
                                                  Total{" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="18"
                                                      height="13"
                                                      viewBox="0 0 18 13"
                                                      fill="none"
                                                    >
                                                      <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                              <div
                                                className={` ${"rules-stats-div great"}`}
                                              >
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.healthStatus
                                                      ?.criticalPassedRules
                                                  }
                                                </h2>
                                                <p>
                                                  Pass
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="18"
                                                      height="13"
                                                      viewBox="0 0 18 13"
                                                      fill="none"
                                                    >
                                                      <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="rules-stats-div fail">
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.healthStatus
                                                      ?.criticalFailedRules
                                                  }
                                                </h2>
                                                <p>
                                                  Failed{" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="14"
                                                      height="13"
                                                      viewBox="0 0 14 13"
                                                      fill="none"
                                                    >
                                                      <path
                                                        d="M7.0007 5.0865L11.9504 0.136719L13.3646 1.55093L8.4149 6.5007L13.3646 11.4504L11.9504 12.8646L7.0007 7.9149L2.05093 12.8646L0.636719 11.4504L5.5865 6.5007L0.636719 1.55093L2.05093 0.136719L7.0007 5.0865Z"
                                                        fill="#B22222"
                                                      />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="text-center">
                                          <div className="rules-states-outer-container">
                                            <p className="f-18 mb-3 mt-1 text-bid mb-0 poppins-medium d-block">
                                              Normal rules
                                            </p>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                              <div className="rules-stats-div total">
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.normalRules
                                                  }
                                                </h2>
                                                <p>
                                                  Total{" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="18"
                                                      height="13"
                                                      viewBox="0 0 18 13"
                                                      fill="none"
                                                    >
                                                      <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                              <div
                                                className={` ${"rules-stats-div great"}`}
                                              >
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.healthStatus
                                                      ?.normalPassedRules
                                                  }
                                                </h2>
                                                <p>
                                                  Pass
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="18"
                                                      height="13"
                                                      viewBox="0 0 18 13"
                                                      fill="none"
                                                    >
                                                      <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="rules-stats-div fail">
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.healthStatus
                                                      ?.normalFailedRules
                                                  }
                                                </h2>

                                                <p>
                                                  Failed{" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="14"
                                                      height="13"
                                                      viewBox="0 0 14 13"
                                                      fill="none"
                                                    >
                                                      <path
                                                        d="M7.0007 5.0865L11.9504 0.136719L13.3646 1.55093L8.4149 6.5007L13.3646 11.4504L11.9504 12.8646L7.0007 7.9149L2.05093 12.8646L0.636719 11.4504L5.5865 6.5007L0.636719 1.55093L2.05093 0.136719L7.0007 5.0865Z"
                                                        fill="#B22222"
                                                      />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-2">
                                        <div
                                          className={`${"rules-states-outer-container h-100"} ${
                                            fileDetails?.report?.summary
                                              ?.reportStatus === "Pass" &&
                                            "bg-light-warning"
                                          } ${
                                            fileDetails?.report?.summary
                                              ?.reportStatus === "Great" &&
                                            "bg-light-success"
                                          } ${
                                            fileDetails?.report?.summary
                                              ?.reportStatus === "Failed" &&
                                            "bg-light-danger"
                                          }`}
                                        >
                                          <div
                                            className={`${"rules-stats-div w-100 h-100"} ${
                                              fileDetails?.report?.summary
                                                ?.reportStatus === "Pass" &&
                                              "pass"
                                            } ${
                                              fileDetails?.report?.summary
                                                ?.reportStatus === "Great" &&
                                              "great"
                                            } ${
                                              fileDetails?.report?.summary
                                                ?.reportStatus === "Failed" &&
                                              "fail"
                                            }`}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="64"
                                              height="64"
                                              viewBox="0 0 64 64"
                                              fill="none"
                                            >
                                              <rect
                                                width="64"
                                                height="64"
                                                rx="32"
                                                fill={
                                                  fileDetails?.report?.summary
                                                    ?.reportStatus === "Pass"
                                                    ? "rgba(255, 165, 0, 1)"
                                                    : fileDetails?.report
                                                        ?.summary
                                                        ?.reportStatus ===
                                                      "Great"
                                                    ? "rgba(59, 135, 62, 1)"
                                                    : fileDetails?.report
                                                        ?.summary
                                                        ?.reportStatus ===
                                                      "Failed"
                                                    ? "rgba(178, 34, 34, 1)"
                                                    : null
                                                }
                                              />
                                              <path
                                                d="M29.0926 36.611L42.4634 23.2402L44.5204 25.2973L29.0926 40.725L19.8359 31.4684L21.893 29.4114L29.0926 36.611Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <h2 className="mt-3">
                                              {
                                                fileDetails?.report?.summary
                                                  ?.reportStatus
                                              }
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="bg-light-bid m-3 rounded-3">
                                    <table className="bid-table bid-table-small border-top-none">
                                      <thead>
                                        <tr>
                                          <th>Model Name</th>
                                          <th>IFC Model</th>
                                          <th>version</th>
                                          <th>Version Name</th>
                                          <th>Version Build</th>
                                          <th>Exported By</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{fileDetails?.modelName}</td>
                                          <td>
                                            {fileDetails?.isIfcModel === false
                                              ? "No"
                                              : "Yes"}
                                          </td>
                                          <td>{fileDetails?.version}</td>
                                          <td>{fileDetails?.versionName}</td>
                                          <td>{fileDetails?.versionBuild}</td>
                                          <td>
                                            <div className="d-flex">
                                              <img
                                                src={
                                                  fileDetails?.report
                                                    ?.exportedBy?.profilePhoto
                                                }
                                                className="user-img"
                                              />
                                              <p className="mb-0 f-14 ml-2 poppins-regular text-black">
                                                {
                                                  fileDetails?.report
                                                    ?.exportedBy?.name
                                                }
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))
                : AllFileList?.map((item, key) => (
                    <AccordionItem key={key}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <AccordionItemState>
                            {({ expanded }) => (
                              <table
                                className="bid-table fileListTable"
                                onClick={() => {
                                  getModelFileDetailAPI(item?._id);
                                  setShowLoader(true);
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td className="col-01">
                                      {item?.modelName}
                                    </td>
                                    <td className="col-02">
                                      {item?.fileSize}Mb
                                    </td>
                                    <td className="col-03">
                                      {" "}
                                      <div className="d-flex">
                                        <img
                                          src={item?.doneBy?.profilePhoto}
                                          onError={(e) =>
                                            (e.target.src = DefaultImage)
                                          }
                                          className="user-img"
                                        />
                                        <p className="mb-0 f-14 ml-2 poppins-regular text-black">
                                          {item?.doneBy?.name}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="col-04">
                                      <div className="users-profile-list">
                                        {item?.associatedProjects?.map(
                                          (i, index) => (
                                            <img
                                              src={`${url}/${i.img}`}
                                              alt="grp"
                                              key={index}
                                              className="u-profile"
                                            />
                                          )
                                        )}
                                        {item?.associatedProjects?.length >
                                          4 && (
                                          <div className="u-profile">
                                            +$
                                            {item?.associatedProjects?.length -
                                              1}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                    <td className="col-05">
                                      {" "}
                                      {moment(item?.createdAt).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </td>
                                    <td className="col-06">
                                      {moment(item?.updatedAt).format(
                                        "DD MMMM YYYY"
                                      )}
                                      {/* <p className="number-count bg-light-success text-success px-2 health-result-status">
                                Great
                              </p> */}
                                    </td>
                                    <td className="col-07">
                                      <img
                                        src={Arrow}
                                        className={
                                          expanded
                                            ? "arrow-icon"
                                            : "arrow-icon  transform-180"
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="position-relative p-2">
                          {detailLoading || showLoader ? (
                            <Loading color="dark" text="loading..." />
                          ) : (
                            <>
                              {showNoData ? (
                                <p>No Data Found</p>
                              ) : (
                                <div className="bg-detail p-3">
                                  <div className="w-100 rounded-2 py-2 px-2 border mt-0">
                                    <div className="row row-low-padding">
                                      <div className="col-md-2 d-flex align-items-center justify-content-center">
                                        <CurvedRangeSlider
                                          totalScore={totalScore}
                                          status={
                                            fileDetails?.report?.summary
                                              ?.reportStatus
                                          }
                                        />
                                      </div>

                                      <div className="col-md-4">
                                        <div className="text-center">
                                          <div className="rules-states-outer-container">
                                            <p className="f-18 mb-3 mt-1 text-bid mb-0 poppins-medium d-block">
                                              Critical rules
                                            </p>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                              <div className="rules-stats-div total">
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.criticalRules
                                                  }
                                                </h2>
                                                <p>
                                                  Total{" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="18"
                                                      height="13"
                                                      viewBox="0 0 18 13"
                                                      fill="none"
                                                    >
                                                      <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                              <div
                                                className={` ${"rules-stats-div great"}`}
                                              >
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.healthStatus
                                                      ?.criticalPassedRules
                                                  }
                                                </h2>
                                                <p>
                                                  Pass
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="18"
                                                      height="13"
                                                      viewBox="0 0 18 13"
                                                      fill="none"
                                                    >
                                                      <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="rules-stats-div fail">
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.healthStatus
                                                      ?.criticalFailedRules
                                                  }
                                                </h2>
                                                <p>
                                                  Failed{" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="14"
                                                      height="13"
                                                      viewBox="0 0 14 13"
                                                      fill="none"
                                                    >
                                                      <path
                                                        d="M7.0007 5.0865L11.9504 0.136719L13.3646 1.55093L8.4149 6.5007L13.3646 11.4504L11.9504 12.8646L7.0007 7.9149L2.05093 12.8646L0.636719 11.4504L5.5865 6.5007L0.636719 1.55093L2.05093 0.136719L7.0007 5.0865Z"
                                                        fill="#B22222"
                                                      />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="text-center">
                                          <div className="rules-states-outer-container">
                                            <p className="f-18 mb-3 mt-1 text-bid mb-0 poppins-medium d-block">
                                              Normal rules
                                            </p>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                              <div className="rules-stats-div total">
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.normalRules
                                                  }
                                                </h2>
                                                <p>
                                                  Total{" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="18"
                                                      height="13"
                                                      viewBox="0 0 18 13"
                                                      fill="none"
                                                    >
                                                      <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                              <div
                                                className={` ${"rules-stats-div great"}`}
                                              >
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.healthStatus
                                                      ?.normalPassedRules
                                                  }
                                                </h2>
                                                <p>
                                                  Pass
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="18"
                                                      height="13"
                                                      viewBox="0 0 18 13"
                                                      fill="none"
                                                    >
                                                      <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="rules-stats-div fail">
                                                <h2>
                                                  {
                                                    fileDetails?.report?.summary
                                                      ?.healthStatus
                                                      ?.normalFailedRules
                                                  }
                                                </h2>

                                                <p>
                                                  Failed{" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="14"
                                                      height="13"
                                                      viewBox="0 0 14 13"
                                                      fill="none"
                                                    >
                                                      <path
                                                        d="M7.0007 5.0865L11.9504 0.136719L13.3646 1.55093L8.4149 6.5007L13.3646 11.4504L11.9504 12.8646L7.0007 7.9149L2.05093 12.8646L0.636719 11.4504L5.5865 6.5007L0.636719 1.55093L2.05093 0.136719L7.0007 5.0865Z"
                                                        fill="#B22222"
                                                      />
                                                    </svg>
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-2">
                                        <div
                                          className={`${"rules-states-outer-container h-100"} ${
                                            fileDetails?.report?.summary
                                              ?.reportStatus === "Pass" &&
                                            "bg-light-warning"
                                          } ${
                                            fileDetails?.report?.summary
                                              ?.reportStatus === "Great" &&
                                            "bg-light-success"
                                          } ${
                                            fileDetails?.report?.summary
                                              ?.reportStatus === "Failed" &&
                                            "bg-light-danger"
                                          }`}
                                        >
                                          <div
                                            className={`${"rules-stats-div w-100 h-100"} ${
                                              fileDetails?.report?.summary
                                                ?.reportStatus === "Pass" &&
                                              "pass"
                                            } ${
                                              fileDetails?.report?.summary
                                                ?.reportStatus === "Great" &&
                                              "great"
                                            } ${
                                              fileDetails?.report?.summary
                                                ?.reportStatus === "Failed" &&
                                              "fail"
                                            }`}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="64"
                                              height="64"
                                              viewBox="0 0 64 64"
                                              fill="none"
                                            >
                                              <rect
                                                width="64"
                                                height="64"
                                                rx="32"
                                                fill={
                                                  fileDetails?.report?.summary
                                                    ?.reportStatus === "Pass"
                                                    ? "rgba(255, 165, 0, 1)"
                                                    : fileDetails?.report
                                                        ?.summary
                                                        ?.reportStatus ===
                                                      "Great"
                                                    ? "rgba(59, 135, 62, 1)"
                                                    : fileDetails?.report
                                                        ?.summary
                                                        ?.reportStatus ===
                                                      "Failed"
                                                    ? "rgba(178, 34, 34, 1)"
                                                    : null
                                                }
                                              />
                                              <path
                                                d="M29.0926 36.611L42.4634 23.2402L44.5204 25.2973L29.0926 40.725L19.8359 31.4684L21.893 29.4114L29.0926 36.611Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <h2 className="mt-3">
                                              {
                                                fileDetails?.report?.summary
                                                  ?.reportStatus
                                              }
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="bg-light-bid m-3 rounded-3">
                                    <table className="bid-table bid-table-small border-top-none">
                                      <thead>
                                        <tr>
                                          <th>Model Name</th>
                                          <th>IFC Model</th>
                                          <th>version</th>
                                          <th>Version Name</th>
                                          <th>Version Build</th>
                                          <th>Exported By</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{fileDetails?.modelName}</td>
                                          <td>
                                            {fileDetails?.isIfcModel === false
                                              ? "No"
                                              : "Yes"}
                                          </td>
                                          <td>{fileDetails?.version}</td>
                                          <td>{fileDetails?.versionName}</td>
                                          <td>{fileDetails?.versionBuild}</td>
                                          <td>
                                            <div className="d-flex">
                                              <img
                                                src={
                                                  fileDetails?.report
                                                    ?.exportedBy?.profilePhoto
                                                }
                                                className="user-img"
                                              />
                                              <p className="mb-0 f-14 ml-2 poppins-regular text-black">
                                                {
                                                  fileDetails?.report
                                                    ?.exportedBy?.name
                                                }
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
}
