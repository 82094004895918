import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDistanceToNowStrict } from "date-fns";

import { publicFetch } from "../../../util/fetcher";

import { Spinner } from "../../icons";

import styles from "./avatar-card.module.css";
import { extractImageFromZip } from "../../../utils/imageHandler";

const UserAvatar = ({ username }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [created, setCreated] = useState(null);
  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await publicFetch.get(`/user/${username}`);
      data.profilePhoto = await extractImageFromZip(data.profilePhoto);
      setUserInfo(data);
      setName(data.name);
      setLastName(data.lastName);
      setCreated(data.createdAt);
    };

    fetchUser();
  }, [username]);

  return (
    <div>
      <div className={styles.avatarCard}>
        {!userInfo ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <div className={styles.avatar}>
            <Link to={`/learning/users/${username}`}>
              <img src={`${userInfo.profilePhoto}`} alt={username} />
            </Link>
          </div>
        )}
        <h2 className={styles.username}>
          {name} {lastName}
        </h2>
        {!userInfo ? (
          <div className="loading">
            <Spinner />
          </div>
        ) : (
          <div className={styles.created}>
            <p>
              Created:{" "}
              <span>
                {created ?
                  formatDistanceToNowStrict(new Date(created), {
                    addSuffix: true,
                  }) : 'N/A'}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAvatar;
