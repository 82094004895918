import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./sidebar";
import { useDispatch, useSelector } from "react-redux";
import addNotification from "../../helpers/notify";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import Loading from "../../components/ui-components/loading";
import { getMileStones } from "api/milestones/getMilestones";
import {
  deleteMileStone,
  deleteBulkMileStone,
} from "api/milestones/deleteMilestone";
import { createMilestone } from "api/milestones/createMilestone";
import { editMilestone } from "api/milestones/editMilestone";
import TopNavbar from "./navbar";
import BulkImportIcon from "assets/images/icons/bulk.png";
import DeleteIcon from "assets/images/icons/delete-bin-line.png";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";
import Loader from "components/Loader";
import { Modal } from "antd";
import { RequestCard } from "./requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { uploadCsv } from "api/milestones/uploadCsv";
import { getFeatureRequest } from "api/featureRequests/getFeatureRequest";
import { useHttp } from "hooks/useHttp";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { useForm } from "react-hook-form";
import { eventTrack } from "helpers/ga4Helper";

const Milestones = () => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    trigger,
    reset,
  } = useForm({ mode: "onChange" });
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    .filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const [milstones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [milestonetodelete, setDelete] = useState("");
  const [milestonekeytodelete, setmilstonetoDelete] = useState("");
  const modalRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkloading, setBulkLoad] = useState(false);
  const hiddenFileInput = useRef(null);
  const [spinner, setSpinner] = useState(false);
  const [milestonestodelete, setMilestonesDelete] = useState([]);
  const [isOpen2, setOpen2] = useState(false);
  const [id, setId] = useState("");
  const [body, setBody] = useState({
    key: "",
    value: "",
  });
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filtered, setFiltered] = useState("");
  const [requestModal, setRequestModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [featureRequests, setFeatureRequests] = useState([]);

  const { request: getFeatureRequestAPI } = useHttp({
    requestCallback: getFeatureRequest,
    onLoad: (res) => {
      setFeatureRequests(res?.map((feature) => feature?.requestedFeature));
    },
    onError: (res) => {},
  });

  useEffect(() => {
    (async () => {
      const res = await getMileStones(userInfoRedux.company);
      setMilestones(res);
      getFeatureRequestAPI(userInfoRedux?.id);
    })();
  }, []);

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setBody((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = (milestone) => {
    setBody(milestone);
    setId(milestone._id);
  };

  const handleSave = async () => {
    if (id) {
      const res = await editMilestone(body);
      if (!res.error) {
        setMilestones((prevMile) => {
          const updatedMiles = prevMile.map((mile) =>
            mile._id == id ? res : mile
          );
          return updatedMiles;
        });
        addNotification("Milestone Updated Successfully! ", "success");
        modalRef.current.click();
      } else {
        addNotification(res?.message, "danger");
        modalRef.current.click();
      }
    } else {
      body.company = userInfoRedux.company;
      try {
        const res = await createMilestone(body);
        setMilestones((prev) => [...prev, res]);
        addNotification("Milestone Created Successfully! ", "success");
        modalRef.current.click();
      } catch (err) {
        addNotification(
          "Something went wrong, please contact support",
          "danger"
        );
        modalRef.current.click();
      }
    }
  };

  const { loading : deleteLoading,   request: deleteMileStoneAPI } = useHttp({
    requestCallback: (data) => deleteMileStone(data),
    onLoad: (res) => {
      console.log(res);
      if(res?.err){
        addNotification(res?.err, "danger");
        setOpen(false);
      }
      else{
        setMilestones((prev) =>
          prev.filter((pre) => pre._id != milestonetodelete)
        );
        setOpen(false);
        addNotification("Milestone Deleted Successfully! ", "success");
      }
    },
    onError: (res) => {
      addNotification(res?.err, "danger");
    },
  });


  const handleDelete = async () => {
    deleteMileStoneAPI({ _id: milestonetodelete })
  };

  const handleClear = () => {
    setBody({
      key: "",
      value: "",
    });
    setId("");
    reset();
  };

  const handleBulkClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  const { request: uploadCsvAPI } = useHttp({
    requestCallback: (data) => uploadCsv(data),
    onLoad: (res) => {
      setSelectedFile(null);
      setBulkLoad(false);
      setMilestones((prev) => [...prev, ...res]);
      setSpinner(false);
      addNotification("Bulk Milestones Added Successfully! ", "success");
      getMileStones(userInfoRedux.company);
    },
    onError: (res) => {
      setSpinner(false);
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const handleUploadCsv = async () => {
    setBulkLoad(true);
    setSpinner(true);

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("company", userInfoRedux.company);

    return uploadCsvAPI(formData);
  };

  const handleCheckBox = (e, id) => {
    const isChecked = e.target.checked;

    const index = milestonestodelete.indexOf(id);

    if (isChecked && index === -1) {
      setMilestonesDelete((prev) => [...prev, id]);
    }

    if (!isChecked && index !== -1) {
      setMilestonesDelete((prev) => prev.filter((userId) => userId !== id));
    }
  };

  const { loading : bulkDeleteLoading,   request: bulkDeleteMileStoneAPI } = useHttp({
    requestCallback: (data) => deleteBulkMileStone(data),
    onLoad: (res) => {
      setMilestones((prev) =>
        prev.filter((user) => !milestonestodelete?.includes(user._id))
      );
      setMilestonesDelete([]);
      setOpen2(false);
      addNotification("Deleted Successfully! ", "success");
    },
    onError: (res) => {
      setOpen2(false);
      addNotification("Error not delete", "danger");
    },
  });

  const handleDeleteBulk = async () => {
    bulkDeleteMileStoneAPI({ ids: milestonestodelete })
  };

  const handleSearch = (keyword) => {
    const filteredResult = milstones.filter((milestone) =>
      milestone?.key.toLowerCase().includes(keyword.toLowerCase())
    );

    setFilteredProjects(filteredResult);

    if (keyword.length > 0) {
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  };

  const requestAccess = async () => {
    setLoading(true);
    const res = await createFeatureRequest(userInfoRedux.id, {
      companyId: userInfoRedux.company,
      permission: requestType,
      userId: userInfoRedux.id,
    });
    if (res.status === 200) {
      setLoadingRequest(false);
      setFeatureRequests(res.data.map((feature) => feature.requestedFeature));
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="personal-area">
        <TopNavbar handleSearch={handleSearch} />

        <div className="content-bg p-2">
          <div className="container d-flex mt-1">
            <Sidebar />

            <div className="profile-content px-0 pt-0">
              <div className="w-100 px-0">
                <div className="d-flex justify-content-between px-3 py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                    >
                      <path
                        d="M0.273438 17.7598V0.240234H9.25072L9.71511 2.40519H15.7265V12.1955H8.92443L8.46004 10.048H1.9843V17.7598H0.273438Z"
                        fill="black"
                      />
                    </svg>
                    <span className="font-weight-bold ml-3 text-black">Milestones</span>
                    {milestonestodelete.length > 0 && (
                      <div
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 ml-4 mb-0 cursor-pointer"
                        onClick={() => {
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_DELETE
                          )
                            ? setOpen2(true)
                            : setRequestModal(true);
                          setRequestType(ALL_PERMISSIONS.MILESTONE_DELETE);
                          eventTrack(
                            "Delete-Milestone-Action",
                            "Delete-Milestone",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          );
                        }}
                      >
                        <img src={DeleteIcon} className="w-15" />
                        <span className="ml-1 f-14">
                          Delete {milestonestodelete.length} Milestones
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex">
                    <div
                      onClick={() => {
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.MILESTONE_ADD
                        )
                          ? handleBulkClick()
                          : setRequestModal(true);
                        setRequestType(ALL_PERMISSIONS.MILESTONE_ADD);
                        eventTrack(
                          "Bulk-Import-Milestone-Action",
                          "Bulk-Import-Milestone",
                          userInfoRedux?.name,
                          "+1",
                          false,
                          "+1"
                        );
                      }}
                      className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                    >
                      <img src={BulkImportIcon} className="w-15" />
                      <span className="ml-1 f-14">Bulk Import Milestones</span>
                    </div>
                    {selectedFile ? (
                      <div
                        onClick={handleUploadCsv}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        {spinner ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="text-bid">Upload</div>
                        )}
                      </div>
                    ) : FinalPermissions?.includes(
                        ALL_PERMISSIONS.MILESTONE_ADD
                      ) ? (
                      <div
                        data-toggle="modal"
                        data-target="#exampleModal"
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span className="ml-2 f-14">Add Milestone</span>
                      </div>
                    ) : (
                      <div
                        data-toggle="modal"
                        data-target="#exampleModdal"
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span
                          className="ml-2 f-14"
                          onClick={() => {
                            setRequestType(ALL_PERMISSIONS.MILESTONE_ADD);
                            setRequestModal(true);
                            eventTrack(
                              "Add-Milestone-Action",
                              "Add-Milestone",
                              userInfoRedux?.name,
                              "+1",
                              false,
                              "+1"
                            );
                          }}
                        >
                          Add Milestone
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    paddingRight: "250px",
                  }}
                  className="border-bottom pl-2 pt-2 pb-2 d-flex justify-content-between bg-table-header"
                >
                  <div className="f-12 font-weight-normal">Name</div>
                  <div className="f-12 font-weight-normal">
                    Milestone Value
                  </div>
                </div>

                {filtered
                  ? filteredProjects?.map((milstone, i) => (
                      <div
                        key={i}
                        className="d-flex justify-content-between p-2 border-bottom"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            checked={milestonestodelete?.includes(milstone._id)}
                            onChange={(e) => handleCheckBox(e, milstone._id)}
                            type="checkbox"
                          />
                          <div className="ml-3"> {milstone?.key} </div>
                        </div>
                        <div>
                          <span
                            className="px-2 py-1 f-12 rounded-5"
                            style={{
                              marginRight: "90px",
                              textAlign: "center",
                              backgroundColor: "rgba(79, 201, 42, 0.1)",
                              color: "rgba(59, 135, 62, 1)",
                            }}
                          >
                            {milstone?.value}
                          </span>
                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_EDIT
                          ) ? (
                            <span
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={() => handleEdit(milstone)}
                              className="mr-5 cursor-pointer table-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                              >
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={() => {
                                setRequestType(ALL_PERMISSIONS.MILESTONE_EDIT);
                                setRequestModal(true);
                              }}
                              className="mr-5 cursor-pointer table-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                              >
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          )}

                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_DELETE
                          ) ? (
                            <span
                              onClick={() => {
                                setDelete(milstone._id);
                                setmilstonetoDelete(milstone?.key);
                                setOpen(true);
                              }}
                              className="mr-5 cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="22"
                                viewBox="0 0 26 22"
                                fill="none"
                              >
                                <path
                                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                setRequestType(
                                  ALL_PERMISSIONS.MILESTONE_DELETE
                                );
                                setRequestModal(true);
                              }}
                              className="mr-5 cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="22"
                                viewBox="0 0 26 22"
                                fill="none"
                              >
                                <path
                                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      </div>
                    ))
                  : milstones?.map((milstone, i) => (
                      <div
                        key={i}
                        className="d-flex justify-content-between p-2 border-bottom"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            checked={milestonestodelete?.includes(milstone._id)}
                            onChange={(e) => handleCheckBox(e, milstone._id)}
                            type="checkbox"
                            style={{ marginRight: "10px" }}
                            className="squared-checkbox"
                          />
                          <div className="f-16 text-black font-weight-normal mb-0">
                            {" "}
                            {milstone?.key}{" "}
                          </div>
                        </div>
                        <div>
                          <span
                            className="px-2 py-1 f-12 font-weight-bold rounded-5"
                            style={{
                              marginRight: "90px",
                              textAlign: "center",
                              backgroundColor: "rgba(79, 201, 42, 0.1)",
                              color: "rgba(59, 135, 62, 1)",
                            }}
                          >
                            {milstone?.value}
                          </span>

                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_EDIT
                          ) ? (
                            <span
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={() => handleEdit(milstone)}
                              className="mr-5 cursor-pointer table-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                              >
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              data-toggle="modal"
                              onClick={() => {
                                setRequestType(ALL_PERMISSIONS.MILESTONE_EDIT);
                                setRequestModal(true);
                              }}
                              className="mr-5 cursor-pointer table-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                              >
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          )}

                          {FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_DELETE
                          ) ? (
                            <span
                              onClick={() => {
                                setDelete(milstone._id);
                                setmilstonetoDelete(milstone?.key);
                                setOpen(true);
                              }}
                              className="mr-5 cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="22"
                                viewBox="0 0 26 22"
                                fill="none"
                              >
                                <path
                                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                setRequestType(
                                  ALL_PERMISSIONS.MILESTONE_DELETE
                                );
                                setRequestModal(true);
                              }}
                              className="mr-5 cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="22"
                                viewBox="0 0 26 22"
                                fill="none"
                              >
                                <path
                                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>

        {/* add milestone */}
        <div
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="delete-modal modal fade"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content w-100">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <p className="delete-modal-label-head">
                    {" "}
                    {body.value ? "Edit Milestone" : "Add Milestone"}
                  </p>
                </h5>
                <button
                  onClick={handleClear}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-0">
                <form>
                  <div className="form-group mt-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="f-14 inter text-black font-weight-500"
                    >
                      Milestone name
                    </label>
                    <input
                      value={body.key}
                      name="key"
                      type="text"
                      className="form-control"
                      placeholder="Milestone name"
                      {...register("key", {
                        required: "Milestone name is required",
                        onChange: (e) => handleOnchange(e),
                      })}
                    />
                    {errors.key && (
                      <p role="alert" className="validation-error-msg">
                        {errors.key.message}
                      </p>
                    )}

                    {body.key.toLowerCase() === "untracked projects" && (
                      <p role="alert" className="validation-error-msg">
                        Default milestone name cannot be used
                      </p>
                    )}
                  </div>
                  <div className="form-group mt-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="f-14 inter text-black font-weight-500"
                    >
                      Milestone value
                    </label>
                    <input
                      value={body.value}
                      // onChange={handleOnchange}
                      name="value"
                      type="number"
                      className="form-control"
                      placeholder="Enter Value"
                      {...register("value", {
                        required: "Milestone value is required",
                        onChange: (e) => handleOnchange(e),
                      })}
                    />
                    {errors.value && (
                      <p role="alert" className="validation-error-msg">
                        {errors.value.message}
                      </p>
                    )}
                  </div>
                </form>
              </div>

              <div className="modal-footer border-none">
                <div className="w-100">
                  <div
                    onClick={(e) => {
                      body.key?.length === 0
                        ? trigger(["key"])
                        : body.value?.length === 0
                        ? trigger(["value"])
                        : body.key.toLowerCase() === "untracked projects"
                        ? e.stopPropagation()
                        : handleSave();
                        eventTrack(
                          "Save-Milestone-Action",
                          "Save-Milestone",
                          userInfoRedux?.name,
                          "+1",
                          false,
                          "+1"
                        );
                    }}
                    className="border-bid bg-bid rounded-lg py-2 w-100 text-white text-center inter f-16 font-weight-500 cursor-pointer"
                  >
                    {id ? "Update" : "Add"}
                  </div>
                  <div
                    onClick={handleClear}
                    ref={modalRef}
                    className="bg-white border-bid rounded-lg py-2 w-100 text-bid text-center inter f-16 font-weight-500 mt-3 cursor-pointer"
                    data-dismiss="modal"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* individual delete */}
        <MDBModal
          isOpen={isOpen}
          toggle={() => setOpen(false)}
          centered
          className="delete-modal"
        >
          <MDBModalHeader toggle={() => setOpen2(false)}>
            <img src={DeleteModalIcon} />
          </MDBModalHeader>
          <MDBModalBody>
            <p className="delete-modal-label-head">
              Delete Milestone {milestonekeytodelete} ?
            </p>
            <p className="delete-modal-label">
              Are you sure you want to delete this Millstone? This action cannot
              be undone.
            </p>
          </MDBModalBody>
          {deleteLoading ? <Loading color="black" text="Deleting..." /> : null}
          <MDBModalFooter className="d-flex justify-content-center border-none">
            <MDBBtnGroup size="md" className="w-100">
              <MDBBtn
                size="sm"
                onClick={() => {
                  setOpen(false);
                  setDelete("");
                }}
                color=""
                disabled={deleteLoading}
                className="delete-modal-btn cancel-confirm"
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                size="sm"
                color=""
                onClick={() => {handleDelete()
                  eventTrack(
                    "Delete-Milestone-Action",
                    "Delete-Milestone",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }}
                disabled={deleteLoading}
                className="delete-modal-btn delete-confirm"
              >
                Delete
              </MDBBtn>
            </MDBBtnGroup>
          </MDBModalFooter>
        </MDBModal>

        {/* bulk delete */}
        <MDBModal
          isOpen={isOpen2}
          toggle={() => setOpen2(false)}
          centered
          className="delete-modal"
        >
          <MDBModalHeader toggle={() => setOpen2(false)}>
            <img src={DeleteModalIcon} />
          </MDBModalHeader>
          <MDBModalBody>
            <p className="delete-modal-label-head">Delete Milestone</p>
            <p className="delete-modal-label">
              Are you sure you want to delete this Millstone? This action cannot
              be undone.
            </p>
          </MDBModalBody>
          {bulkDeleteLoading ? <Loading color="black" text="Deleting..." /> : null}
          <MDBModalFooter className="d-flex justify-content-center border-none">
            <MDBBtnGroup size="md" className="w-100">
              <MDBBtn
                size="sm"
                onClick={() => {
                  setOpen2(false);
                  setDelete("");
                }}
                color=""
                disabled={bulkDeleteLoading}
                className="delete-modal-btn cancel-confirm"
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                size="sm"
                color=""
                onClick={() => {handleDeleteBulk()
                  eventTrack(
                    "Bulk-Delete-Milestone-Action",
                    "Bulk-Delete-Milestone",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }}
                disabled={bulkDeleteLoading}
                className="delete-modal-btn delete-confirm"
              >
                Delete
              </MDBBtn>
            </MDBBtnGroup>
          </MDBModalFooter>
        </MDBModal>

        <input
          style={{ display: "none" }}
          type="file"
          accept=".csv"
          name="file_image"
          className="file-input"
          id="input_image"
          required
          // disabled={loading}
          ref={hiddenFileInput}
          onChange={handleFile}
        />

        <Modal
          title={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          open={requestModal}
          footer={null}
          onCancel={() => setRequestModal(false)}
        >
          <RequestCard
            status={featureRequests?.includes(requestType)}
            loading={loadingRequest}
            icon={
              featureRequests?.includes(requestType) ? (
                <ClockCircleOutlined style={{ color: "black" }} />
              ) : (
                <InfoCircleOutlined style={{ color: "black" }} />
              )
            }
            title={
              featureRequests?.includes(requestType)
                ? `Request Already Sent`
                : `Oops, looks like you need to ${
                    userInfoRedux?.companyAdmin
                      ? `Acquire License`
                      : `Request Access`
                  }`
            }
            description={
              featureRequests?.includes(requestType)
                ? `Please reach out to your project Admin`
                : `Elevate your experience with exclusive futures, early releases, and premium features.`
            }
            buttonText={
              userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
            }
            buttonHandler={() => requestAccess()}
          />
        </Modal>
      </div>
    </>
  );
};

export default Milestones;
