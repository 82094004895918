import ReactGA from "react-ga4";

export const googleAnalytics4 = (userId) => {
  ReactGA.initialize(
    "G-W482SV8S8S",
    [
      {
        legacyDimensionMetric: true,
        nonce: "",
        testMode: true,
        gaOptions: { userId: userId, debug_mode: true },
        gtagOptions: { user_id: userId, debug_mode: true }, //'send_page_view': true, 'debug_mode': false,
      },
      {
        trackingId: "UA-223995228-1",
      },
    ],
    { debug: true }
  );
  if (ReactGA.isInitialized) {
    ReactGA.gtag("config", "G-W482SV8S8S", { debug_mode: true }); //,{ 'debug_mode':true }

    ReactGA.gtag("set", { user_id: userId });
    ReactGA.ga("set", "userId", userId);

    ReactGA.gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    });
  }
};

export const eventTrack = (
  category,
  action,
  label,
  value,
  nonInteraction,
  transport
) => {
  // console.log("GA event:", category, ":", action, ":", label, ":", value, ":", nonInteraction, ":", transport);

  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    value: 0, // optional, must be a number
    nonInteraction: nonInteraction, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
};
