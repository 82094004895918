import React, { useEffect, useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import format from 'date-fns/format'
import { publicFetch } from '../../../../util/fetcher'
import { useAuthAxios } from '../../../../store/fetch'

import styles from './comment-item.module.css'
import { useSelector } from 'react-redux'
import { eventTrack } from 'helpers/ga4Helper'

const CommentItem = ({
  author,
  created,
  isOwner,
  answerId,
  questionId,
  commentId,
  setQuestion,
  children
}) => {
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const authAxios = useAuthAxios();

  const [name, setName] = useState(null)
  const [lastName, setLastName] = useState(null)
 // const [authorInfo, setAuthorInfo] = useState(null)
  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await publicFetch.get(`/user/${author}`)
     // setAuthorInfo(data)
      setName(data.name)
      setLastName(data.lastName)
    }

    fetchUser()
  }, [author])

  const handleDeleteComment = async () => {
   
    const res = window.confirm('Are you sure delete your comment?')
    if (res) {
      const { data } = await authAxios.delete(
        answerId
          ? `/comment/${questionId}/${answerId}/${commentId}`
          : `/comment/${questionId}/${commentId}`
      )

      setQuestion(data)
    }
  }

  return (
    <div className={styles.commentContainer}>
      <p>{children} –</p> &nbsp;
      <Link to="/users/[user]" as={`/users/${author}`}>
        <a className={isOwner ? styles.owner : undefined}>{name} {lastName} </a>
      </Link>
      &nbsp;
      <p className={styles.dateText}>
        {format(new Date(created), "MMM dd'`'yy 'at' k':'mm")}{' '}
      </p>
      {(userInfoRedux?._id === author._id || userInfoRedux?.companyAdmin || userInfoRedux?.admin) && (
        <a className={styles.delete} onClick={() => {handleDeleteComment()
          eventTrack(
            "Delete-Post-Comment-Action",
            "Delete-Post-Comment",
            userInfoRedux?.name,
            "+1",
            false,
            "+1"
          );
        }}>
          delete
        </a>
      )}
    </div>
  )
}

export default CommentItem
