import React, { useState } from 'react';
import {
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from 'mdbreact';
import { useDispatch } from 'react-redux';

import Loading from '../ui-components/loading';
import { useHistory } from 'react-router-dom';
import { Maybe } from 'helpers/maybeFunctor';
import { useHttp } from 'hooks/useHttp';
import { changeExpirationDate } from 'api/users/changeExpirationDate';
import { choseToggle } from 'helpers/toggleIfLoading';
import addNotification from 'helpers/notify';
import { mainAdminSetNewDateForCompany } from 'actions/main-admin';
import {eventTrack} from 'helpers/ga4Helper'


const ChangeExpirationModal = props => {
  const { companyToEdit } = props;
  const [time, setTime] = useState(0);
  const history = useHistory();
  const maybeCompanyName = Maybe.of(companyToEdit).map(
    companyToEdit => companyToEdit.companyName,
  ).value;

  const dispatch = useDispatch();

  const toggle = () => {
    props.toggle();
    setTime(0);
  };

  const { request: changeExpirationDateAPI, loading } = useHttp({
    requestCallback: changeExpirationDate,
    onLoad: res => {
      dispatch(mainAdminSetNewDateForCompany([res]));
      toggle();
      addNotification(
        `${maybeCompanyName} expiration date was changed successfully`,
        'success',
      );
    },
    onError: res => {
      toggle();
      addNotification(
        `Something went wrong with the expiration date change, please contact support`,
        'danger',
      );
    },
  });

  const submitNewExpirationDate = () => {
    if (time === 0) {
      addNotification(
        'Choose period you want to prolong the expiration date',
        'default',
      );
    } else {
      changeExpirationDateAPI({
        company_id: companyToEdit._id,
        month: time,
      });
    }
  };

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={choseToggle(loading, toggle)}
      centered
      className='expiration-modal'
    >
      <MDBModalHeader toggle={choseToggle(loading, toggle)}>
        Change {maybeCompanyName} expiration date
      </MDBModalHeader>
      <MDBModalBody>
        <MDBListGroup className='expiration-box'>
          <MDBListGroupItem
            className="cursor-pointer"
            active={time === 1}
            onClick={() =>{ setTime(1)
              {eventTrack("Change-Expirartion-Action","Change-Expirartions",history.location.pathname,companyToEdit._id,false,companyToEdit.companyName)}}}
          >
            Prolong for 1 month
          </MDBListGroupItem>
          <MDBListGroupItem
            className="cursor-pointer"
            active={time === 3}
            onClick={() => setTime(3)}
          >
            Prolong for 3 months
          </MDBListGroupItem>
          <MDBListGroupItem
            className="cursor-pointer"
            active={time === 12}
            onClick={() => setTime(12)}
          >
            Prolong for 12 months
          </MDBListGroupItem>
        </MDBListGroup>
        {loading ? <Loading color="dark" text="Changing date..." /> : null}
      </MDBModalBody>
      <MDBModalFooter className="d-flex justify-content-center border-none ">
        <MDBBtn
          color=""
          size="sm"
          className='bg-bid text-white card-button text-inherit w-100 expiration-btn'
          disabled={loading}
          onClick={submitNewExpirationDate}
        >
          Change expiration date
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default ChangeExpirationModal;
