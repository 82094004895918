import {
  ADD_USER_TO_PROJECT,
  ADD_NEW_PROJECT,
  UPDATE_IFC_PROJECT,
  SET_COMPANY_USERS,
  SET_PROJECTS,
  SET_USER,
  REMOVE_USER_FROM_PROJECT,
  REMOVE_PROJECT,
  SET_MILESTONES,
  UPDATE_MILESTONE,
  SET_PERM,
  FEATURE_REQUEST,
  SET_SIDEBAR,
  SET_CATALOGS_TREE,
  SET_CATALOGS,
  SET_COMPANY_PERMISSION,
  SET_ALL_PERMISSION,
  SET_ALL_HEALTH_REPORT,
  SET_ALL_PARAMETERS,
  SET_COMPANY_SETTINGS,
  UPDATE_PROJECT,
  SET_PERSONAL_AREA_ACTIVE_TAB,
  SET_COMPANY_ROLE,
  SET_USER_ANALYTICS,
  REFRESH_PROJECTS,
  PROJECT_FILES_TREE,
  SET_ADMIN_MOBILE_SIDEBAR
} from "../constants/action-types/user";

const initialState = {
  userInfo: null,
  catalogs: null,
  catalogsTree: null,
  projects: null,
  users: null,
  userPermission: [],
  featureRequest: null,
  sidebar: false,
  adminSidebar: false,
  companyPermission: [],
  allPermission: [],
  healthReports: [],
  parameters: [],
  companySettings: null,
  personalAreaActiveTab: "CARD_VIEW",
  companyRoles: null,
  userAnalytics: null,
  refreshProjects: false,
  projectFilesTree: []
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case SET_MILESTONES:
      return {
        ...state,
        milestones: action.payload,
      };
    case UPDATE_MILESTONE:
      console.log(action);
      let projectIndex = state.projects.findIndex(
        (val) => val._id === action.payload.id
      );
      let updatedProjects = [...state.projects];
      updatedProjects[projectIndex].milestone = action.payload.milestone;
      return {
        ...state,
        projects: updatedProjects,
      };
    case UPDATE_PROJECT: {
      console.log(action);
      let updatedProjectIndex = state.projects.findIndex(
        (val) => val._id === action.payload._id
      );
      let updatedProjects = [...state.projects];
      updatedProjects[updatedProjectIndex] = action.payload;
      return {
        ...state,
        projects: updatedProjects,
      };
    }
    case SET_COMPANY_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case "RMSHARE": {
      return {
        ...state,
        projects: state.projects?.map((item) => {
          if (item._id === action.payload._id) {
            return action.payload;
          }
          return item;
        }),
      };
    }

    case SET_USER:
      return {
        ...state,
        userInfo: action.payload,
      };

    case ADD_NEW_PROJECT:
      return {
        ...state,
        projects: [action.payload, ...state.projects],
      };

    case UPDATE_IFC_PROJECT:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };

    case REMOVE_PROJECT:
      const projectNeededIndex = state.projects.findIndex(
        (project) => project._id === action.payload
      );

      const newProjects = [
        ...state.projects.slice(0, projectNeededIndex),
        ...state.projects.slice(projectNeededIndex + 1),
      ];

      return {
        ...state,
        projects: newProjects,
      };

    case ADD_USER_TO_PROJECT: {
      const { user, project } = action.payload;

      const neededProject = state.projects.find(
        (projectItem) => projectItem._id === project
      );

      const neededProjectIndex = state.projects.findIndex(
        (projectItem) => projectItem._id === project
      );

      const neededProjectWithUser = {
        ...neededProject,
        users: [...neededProject.users, user],
      };

      const newProjects = [
        ...state.projects.slice(0, neededProjectIndex),
        neededProjectWithUser,
        ...state.projects.slice(neededProjectIndex + 1),
      ];

      return {
        ...state,
        projects: newProjects,
      };
    }

    case REMOVE_USER_FROM_PROJECT: {
      const { user, project } = action.payload;

      const neededProject = state.projects.find(
        (projectItem) => projectItem._id === project
      );

      const neededProjectIndex = state.projects.findIndex(
        (projectItem) => projectItem._id === project
      );

      const neededUserIdIndex = neededProject.users.findIndex(
        (userId) => userId === user
      );

      const neededProjectWithoutUser = {
        ...neededProject,
        users: [
          ...neededProject.users.slice(0, neededUserIdIndex),
          ...neededProject.users.slice(neededUserIdIndex + 1),
        ],
      };

      const newProjects = [
        ...state.projects.slice(0, neededProjectIndex),
        neededProjectWithoutUser,
        ...state.projects.slice(neededProjectIndex + 1),
      ];

      return {
        ...state,
        projects: newProjects,
      };
    }

    case SET_PERM:
      return {
        ...state,
        userPermission: action.payload,
      };

    case SET_COMPANY_PERMISSION:
      return {
        ...state,
        companyPermission: action.payload,
      };

    case SET_ALL_PERMISSION:
      return {
        ...state,
        allPermission: action.payload,
      };

    case FEATURE_REQUEST:
      return {
        ...state,
        featureRequest: action.payload,
      };

    case SET_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload,
      };

      case SET_ADMIN_MOBILE_SIDEBAR:
        return {
          ...state,
          adminSidebar: action.payload,
        };

    case SET_PERSONAL_AREA_ACTIVE_TAB:
      return {
        ...state,
        personalAreaActiveTab: action.payload,
      };

    case SET_ALL_HEALTH_REPORT:
      return {
        ...state,
        healthReports: action.payload,
      };

    case SET_ALL_PARAMETERS:
      return {
        ...state,
        parameters: action.payload,
      };
    case SET_CATALOGS_TREE:
      return {
        ...state,
        catalogsTree: action.payload,
      };

    case SET_CATALOGS:
      return {
        ...state,
        catalogs: action.payload,
      };

    case SET_COMPANY_SETTINGS:
      return {
        ...state,
        companySettings: action.payload,
      };

    case SET_COMPANY_ROLE:
      return {
        ...state,
        companyRoles: action.payload,
      };

    case SET_USER_ANALYTICS:
      return {
        ...state,
        userAnalytics: action.payload,
      };

    case REFRESH_PROJECTS:
      return {
        ...state,
        refreshProjects: action.payload,
      };

    case PROJECT_FILES_TREE:
      return {
        ...state,
        projectFilesTree: action.payload,
      };

    default:
      return state;
  }
}
