import React, { useEffect, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import { useHistory, useParams } from "react-router";

import withKey from "components/hoc/withKey";
import Loading from "components/ui-components/loading";

import { useHttp } from "hooks/useHttp";
import addNotification from "helpers/notify";
import { resetPassword } from "api/auth/resetPassword";

import "../RegistrationUserAdmin/Registration.scss";

const ResetPassword = (props) => {
  const { email, key, user } = props.licenseData;
  const { token } = useParams();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const history = useHistory();

  useEffect(() => {
    return () => {
      setName("");
      setLastName("");
      setCountry("");
      setCity("");
      setPassword("");
      setRepeatPassword("");
    };
  }, []);

  const { loading, request: resetPasswordAPI } = useHttp({
    requestCallback: resetPassword,
    onLoad: (res) => {
      if (res.err) {
        addNotification(res.err, "danger");
      } else {
        addNotification("Your password was succeffully changed", "success");
        history.push("/");
      }
    },
    onError: (err) => {
      addNotification(err.err, "danger");
    },
  });

  const submitResetPassword = (e) => {
    e.preventDefault();
    if (password.length < 6) {
      addNotification("Password must have 6 or more characters!", "danger");
    }
    if (password !== repeatPassword) {
      addNotification("Password mismatch", "danger");
    } else {
      const newUser = { email, token, password };
      return resetPasswordAPI(newUser);
    }
  };

  return (
    <>
      <div className="position-fixed registration-image"></div>
      <MDBModal
        isOpen={true}
        centered
        toggle={() => {}}
        className="create-modal"
      >
        <MDBModalHeader>Reset password</MDBModalHeader>
        <MDBModalBody className="w-100 overflow-hidden">
          <form onSubmit={submitResetPassword} className="w-100">
            <div className="form-group mt-3">
              <label
                htmlFor=""
                className="f-14 inter text-black font-weight-500"
              >
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Your email"
                autoComplete="off"
                value={email}
                onChange={() => {}}
                required
              />
            </div>
            <MDBRow>
              <MDBCol size="12">
                <div className="form-group mt-3">
                  <label
                    htmlFor=""
                    className="f-14 inter text-black font-weight-500"
                  >
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
              <MDBCol size="12">
                <div className="form-group mt-3">
                  <label
                    htmlFor=""
                    className="f-14 inter text-black font-weight-500"
                  >
                    Repeat Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Repeat password"
                    autoComplete="new-password"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>
            
            <div className="form-group mb-0">
              <input
                type="submit"
                className="header-btn bg-bid text-white border-bid mt-4 "
                value="Reset password"
              />
            </div>
            {loading ? <Loading color="white" text="Registering..." /> : null}
          </form>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default withKey(ResetPassword);
