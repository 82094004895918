import React, { useState } from "react";
import { MDBBox } from "mdbreact";

import Login from "components/Login";
import RequestResetPassword from "components/RequestResetPassword";
import logo from "assets/images/logo/logo.png";
import "./Authorization.scss";

const Authorization = (props) => {
  const [fromPath, setFromPath] = useState(() => {
    if (props.location.state && props.location.state.from) {
      return props.location.state.from;
    } else {
      return "/";
    }
  });
  return (
    //vh-100
    <MDBBox className=" d-flex" style={{ height: "100vh" }}>
      <MDBBox className="d-flex flex-column justify-content-between authorization">
        <MDBBox>
          <Login fromPath={fromPath} />
          <RequestResetPassword />
        </MDBBox>
        <MDBBox className="text-center d-flex flex-column">
          <div className="support-footer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="mail"
              width="18"
              height="14.324"
              viewBox="0 0 18 14.324"
            >
              <path
                id="mail-2"
                data-name="mail"
                d="M3.01,5.838a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1V17.162a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2v-11a1.008,1.008,0,0,1,.01-.14ZM5,8.062v9.1H19v-9.1l-4.878,4.879a3,3,0,0,1-4.243,0ZM6.572,6.806H17.428l-4.721,4.721a1,1,0,0,1-1.414,0Z"
                transform="translate(-3 -4.838)"
                fill="#aeaeae"
              />
            </svg>
            <a href="mailto:support@bidlight.com" className=" mt-2">
              support@bidlight.com
            </a>
          </div>
        </MDBBox>
      </MDBBox>
      <div className="authorization-image flex-grow-1">
        <div id="authImgText" className="bottom-flex">
          <h3>The Complete estimation solution for the AEC industry!</h3>
          <p>
            See your projects resources (Labor, Equipment, Material ...) in real
            time!
          </p>
        </div>
      </div>
    </MDBBox>
  );
};

export default Authorization;
