import {
    BoxGeometry,
    BufferGeometry,
    Color,
    Group,
    Line,
    LineBasicMaterial,
    Mesh,
    MeshBasicMaterial,
    Vector3
} from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

export class DimensionLine {

    labelClassName = "";
    static scaleFactor = 0.1;

    static scale = 1;
    units = 'units';

    // Elements
    root = new Group();
    line;
    textLabel;
    endpointMeshes = [];

    // Geometries
    axis;
    endpoint;

    // Dimensions
    start;
    end;
    center;
    length;
    scale = new Vector3(1, 1, 1);

    // Materials
    lineMaterial //: LineBasicMaterial;
    endpointMaterial //: MeshBasicMaterial;

    // Bounding box
    boundingMesh = new Mesh();
    boundingSize = 0.05;

    constructor(
        start, //: Vector3,
        end, //: Vector3,
        lineMaterial, //: LineBasicMaterial,
        endpointMaterial, //: MeshBasicMaterial,
        endpointGeometry, //: BufferGeometry,
        className, //: string,
        endpointScale, //: Vector3
        scene,
        project,
    ) {
        this.labelClassName = className;

        this.start = start;
        this.end = end;
        this.scale = endpointScale;
        this.scene = scene;
        this.project = project;
        this.units = this.project.measurementSystem == "METRIC" ? "m" : "ft";

        this.lineMaterial = lineMaterial;
        this.endpointMaterial = endpointMaterial;

        this.length = this.getLength();
        this.center = this.getCenter();

        this.axis = new BufferGeometry().setFromPoints([start, end]);
        this.line = new Line(this.axis, this.lineMaterial);
        this.line.computeLineDistances()
        this.root.add(this.line);
        this.endpoint = endpointGeometry;
        this.addEndpointMeshes();
        this.textLabel = this.newText();
        console.log("dimension text label", this.textLabel)
        this.textLabel.position.set(this.center.x, this.center.y, this.center.z);

        this.root.renderOrder = 2;
        this.scene.add(this.root);

        DimensionLine.scale = this.scene.modelScale;
        // this.context.ifcCamera.onChange.on(() => this.rescaleObjectsToCameraPosition());
        // this.rescaleObjectsToCameraPosition();
    }

    dispose() {
        this.removeFromScene();
        this.disposeMeshRecursively(this.root);
        (this.root) = null;
        this.disposeMeshRecursively(this.line);
        (this.line) = null;
        this.endpointMeshes.forEach((mesh) => this.disposeMeshRecursively(mesh));
        this.endpointMeshes.length = 0;
        this.axis.dispose();
        (this.axis) = null;
        this.endpoint.dispose();
        (this.endpoint) = null;

        this.textLabel.removeFromParent();
        this.textLabel.element.remove();
        (this.textLabel) = null;

        this.lineMaterial.dispose();
        (this.lineMaterial) = null;
        this.endpointMaterial.dispose();
        (this.endpointMaterial) = null;

        if (this.boundingMesh) {
            this.disposeMeshRecursively(this.boundingMesh);
            (this.boundingMesh) = null;
        }
    }

    get boundingBox() {
        return this.boundingMesh;
    }

    get text() {
        return this.textLabel;
    }

    set dimensionColor(dimensionColor) {
        this.endpointMaterial.color = dimensionColor;
        this.lineMaterial.color = dimensionColor;
    }

    set visibility(visible) {
        this.root.visible = visible;
        this.textLabel.visible = visible;
    }

    set endpointGeometry(geometry) {
        this.endpointMeshes.forEach((mesh) => this.root.remove(mesh));
        this.endpointMeshes = [];
        this.endpoint = geometry;
        this.addEndpointMeshes();
    }

    set endpointScale(scale) {
        this.scale = scale;
        this.endpointMeshes.forEach((mesh) => mesh.scale.set(scale.x, scale.y, scale.z));
    }

    set endPoint(point) {
        this.end = point;
        if (!this.axis) return;
        const position = this.axis.attributes.position;
        if (!position) return;
        position.setXYZ(1, point.x, point.y, point.z);
        position.needsUpdate = true;
        this.endpointMeshes[1].position.set(point.x, point.y, point.z);
        this.endpointMeshes[1].lookAt(this.start);
        this.endpointMeshes[0].lookAt(this.end);
        this.length = this.getLength();
        this.textLabel.element.textContent = this.getTextContent();
        this.center = this.getCenter();
        this.textLabel.position.set(this.center.x, this.center.y, this.center.z);
        this.line.computeLineDistances();
        console.log("ABC")
    }

    removeFromScene() {
        this.scene.remove(this.root);
        // this.root.remove(this.textLabel);
    }

    createBoundingBox() {
        this.boundingMesh = this.newBoundingBox();
        this.setupBoundingBox(this.end);
    }

    rescaleObjectsToCameraPosition() {
        this.endpointMeshes.forEach((mesh) => this.rescaleMesh(mesh, DimensionLine.scaleFactor));
        if (this.boundingMesh) {
            this.rescaleMesh(this.boundingMesh, this.boundingSize, true, true, false);
        }
    }

    rescaleMesh(mesh, scalefactor = 1, x = true, y = true, z = true) {
        const camera = this.context.ifcCamera.activeCamera;
        let scale = new Vector3().subVectors(mesh.position, camera.position).length();
        if (this.context.ifcCamera.projection === 2) { // CameraProjections.Orthographic) {
            scale *= 0.1;
        }
        scale *= scalefactor;
        const scaleX = x ? scale : 1;
        const scaleY = y ? scale : 1;
        const scaleZ = z ? scale : 1;
        mesh.scale.set(scaleX, scaleY, scaleZ);
    }

    addEndpointMeshes() {
        this.newEndpointMesh(this.start, this.end);
        this.newEndpointMesh(this.end, this.start);
    }

    newEndpointMesh(position, direction) {
        const mesh = new Mesh(this.endpoint, this.endpointMaterial);
        mesh.position.set(position.x, position.y, position.z);
        mesh.scale.set(this.scale.x, this.scale.y, this.scale.z);
        mesh.lookAt(direction);
        this.endpointMeshes.push(mesh);
        this.root.add(mesh);
    }

    newText() {
        const htmlText = document.createElement('div');
        htmlText.className = this.labelClassName;
        htmlText.textContent = this.getTextContent();
        const label = new CSS2DObject(htmlText);
        label.position.set(this.center.x, this.center.y, this.center.z);
        this.root.add(label);
        console.log("dimension", this.labelClassName);
        console.log("dimension label ", label)
        return label;
    }

    getTextContent() {
        console.log("dimension", this.length / DimensionLine.scale + this.units)
        return `${(this.length / DimensionLine.scale).toFixed(2)} ${this.units}`;
    }

    newBoundingBox() {
        const box = new BoxGeometry(1, 1, this.length);
        return new Mesh(box);
    }

    setupBoundingBox(end) {
        if (!this.boundingMesh) return;
        this.boundingMesh.position.set(this.center.x, this.center.y, this.center.z);
        this.boundingMesh.lookAt(end);
        this.boundingMesh.visible = false;
        this.root.add(this.boundingMesh);
    }

    getLength() {
        const calcLength = parseFloat(this.start.distanceTo(this.end));
        const scaledLength = this.convertUnits(calcLength, this.units)
        return scaledLength;
    }

    convertUnits(length, unit) {
        if (unit === "m") {
            length = length / 1000;
        }

        if (unit === "ft") {
            length = length / 304.8;
        }

        return length;
    }

    getCenter() {
        let dir = this.end.clone().sub(this.start);
        const len = dir.length() * 0.5;
        dir = dir.normalize().multiplyScalar(len);
        return this.start.clone().add(dir);
    }

    disposeMeshRecursively(mesh) {
        mesh.removeFromParent();
        if (mesh.geometry) mesh.geometry.dispose();
        if (mesh.material) {
            if (Array.isArray(mesh.material)) mesh.material.forEach((mat) => mat.dispose());
            else mesh.material.dispose();
        }
        if (mesh.children && mesh.children.length) {
            mesh.children.forEach((child) => this.disposeMeshRecursively(child));
        }
        mesh.children.length = 0;
    }
}