import { generateNewToken } from "api/users/generateAccessToken";
import { getUserAccessToken } from "api/users/getAccessToken";
import { CopyPaste } from "assets/svg-icons/CopyPaste";
import { PasswordHide, PasswordShow } from "assets/svg-icons/Password";
import Loader from "components/Loader";
import { eventTrack } from "helpers/ga4Helper";
import addNotification from "helpers/notify";
import { useHttp } from "hooks/useHttp";
import React, { useEffect, useState } from "react";

export default function API() {
  const [tokenLife, setTokenLife] = useState(1);
  const [showToken, setShowToken] = useState(false);
  const [token, setToken] = useState("");

  const { loading: getTokenLoading, request: getAccessToken } = useHttp({
    requestCallback: () => getUserAccessToken(),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("get token error => ", res);
      // addNotification("Something went wrong, please connect with support", "danger");
    },
  });

  useEffect(() => {
    const getData = async () => {
      const response = await getAccessToken();
      if (response.success) {
        setToken(response.accessToken);
        setTokenLife(response.tokenLife);
      } else {
        console.log("get 1 token error => ", response);
        addNotification(
          "Something went wrong, please connect with support",
          "danger"
        );
      }
    };
    getData();
  }, []);

  const { loading: generateTokenLoading, request: generateTokenAPI } = useHttp({
    requestCallback: (data) => generateNewToken(data),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("generate token error => ", res);
      addNotification(
        "Something went wrong, please connect with support",
        "danger"
      );
    },
  });

  const handleGeneratePassword = async () => {
    const tokenResponse = await generateTokenAPI({ tokenLife });
    if (tokenResponse.success) {
      setToken(tokenResponse.accessToken);
      addNotification("New token generated successfully", "success");
    } else {
      addNotification(
        "Something went wrong, please connect with support",
        "success"
      );
    }

    eventTrack(
      "Generate-API-Token-Action",
      "Generate-API-Token",
      "Generate-API-Token",
      "+1",
      false,
      "+1"
    );
  };

  return (
    <div className="w-100">
      <div className="py-4 px-2">
        <p className="font-weight-bold text-black mb-1 h3">API</p>
        <p>Manage your access token</p>
        <hr />

        {getTokenLoading || generateTokenLoading ? (
          <Loader />
        ) : (
          <>
            <div className="plan-container mt-4">
              <div className="plan-header">
                <p>Add token life in days</p>
              </div>
              <div className="plan-content">
                <input
                  onChange={(e) => setTokenLife(Number(e.target.value))}
                  value={tokenLife}
                  type="number"
                  className="form-control"
                  placeholder="Token Life"
                />
                {/* </div> */}
              </div>
            </div>
            <div className="plan-container mt-4">
              <div className="plan-header">
                <p>Generated Token</p>
              </div>
              <div className="plan-content">
                <input
                  onChange={(e) => setToken(e.target.value)}
                  // readOnly={true}
                  value={token}
                  type={showToken ? "text" : "password"}
                  className="form-control"
                  placeholder="Generated Token"
                />
                {token.length > 0 && (
                  <div className="d-flex justify-content-end w-100">
                    <i
                      className="passwordIcon"
                      onClick={() => setShowToken(!showToken)}
                    >
                      {showToken ? <PasswordHide /> : <PasswordShow />}
                    </i>
                    <i
                      className="copyIcon"
                      onClick={() => {
                        navigator.clipboard.writeText(token);
                        addNotification(
                          "Token successfully copied to clipboard",
                          "success"
                        );
                      }}
                    >
                      <CopyPaste />
                    </i>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-end w-100 mt-4">
              <button
                className="header-btn text-white bg-bid border-bid px-4 mr-0"
                onClick={handleGeneratePassword}
              >
                Generate Token
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
