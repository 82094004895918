import React, { useState } from "react";
import { useLocation, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { AuthAxiosLearning } from "learning/store/fetch";

import "../styles/variables.css";
import "../styles/nprogress.css";
import "../styles/index.css";

import "../styles/app.scss";
import { eventTrack, googleAnalytics4 } from "../components/utils";
import GA4 from "../components/GA4";
import HomePage from ".";
import FeedPage from "./articles";
import { tokenRef, url } from "api";
import jwt_decode from "jwt-decode";
import QuestionDetail from "./questions/QuestionDetail";
import TagsPage from "./tags";
import Ask from "./questions/ask";
import UsersPage from "./users";
import UserDetail from "./users/UserDetail";
import SingleArticle from "./articles/SingleArticle";
import ProfilePage from "./articles/profile/ProfilePage";
import ArticleEditor from "./articles/ArticleEditor";

function MyApp({ anonymous, userInfoRedux }) {
  const authToken =
    typeof window !== "undefined" ? localStorage.getItem(tokenRef) : null;

  if (authToken) {
    const decode = jwt_decode(localStorage.getItem(tokenRef));
    var _id = decode?.id;
  }

  return (
    <>
      {googleAnalytics4(_id)}
      {eventTrack("main-page", _id, _id, 2, false)}
      <GA4
        userId={_id}
        category={url}
        action={"Load"}
        label={"main-page"}
        value={0}
        nonInteraction={true}
        transport={"xhr"}
      ></GA4>
      <AuthAxiosLearning>
        <Switch>
          <Route
            path="/learning"
            exact
            component={() => {
              return (
                <HomePage
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/tags/:tag"
            exact
            component={({ match }) => {
              return (
                <HomePage
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  query={match.params.tag}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/tags"
            exact
            component={({ match }) => {
              return (
                <TagsPage
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/questions/:id"
            exact
            component={({ match }) => {
              return (
                <QuestionDetail
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  slug={match.params.id}
                />
              );
            }}
          />

          <Route
            path="/learning/ask-question"
            exact
            component={({ match }) => {
              return (
                <Ask anonymous={anonymous} userInfoRedux={userInfoRedux} />
              );
            }}
          />

          <Route
            path="/learning/users"
            exact
            component={({ match }) => {
              return (
                <UsersPage
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/users/:username"
            exact
            component={({ match }) => {
              return (
                <UserDetail
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  username={match.params.username}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/articles"
            exact
            component={({ match }) => {
              return (
                <FeedPage
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/articles/:articleSlug"
            exact
            component={({ match }) => {
              return (
                <SingleArticle
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  slug={match.params.articleSlug}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/articles/profile/:slug"
            exact
            component={({ match }) => {
              return (
                <ProfilePage
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  slug={match.params.slug}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/articles/editor/:articleSlug"
            exact
            component={({ match }) => {
              return (
                <ArticleEditor
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  slug={match.params.articleSlug}
                  exact
                />
              );
            }}
          />

          <Route
            path="/learning/articles/create/editor"
            exact
            component={() => {
              return (
                <ArticleEditor
                  anonymous={anonymous}
                  userInfoRedux={userInfoRedux}
                  exact
                />
              );
            }}
          />
        </Switch>
      </AuthAxiosLearning>
    </>
  );
}

export default MyApp;
