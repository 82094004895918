import { MDBContainer } from "mdbreact";
import React, { useEffect, useState } from "react";
import KanbanCategory from "./KanbanCategory/KanbanCategory";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { updateMilestone, setMilestones, setProjects } from "actions/user";
import { editProjectPut } from "api/projects/updateProject";
import { useHttp } from "hooks/useHttp";
import addNotification from "helpers/notify";
import { updateKanban } from "api/milestones/editMilestone";

const colors = [
  "#FFA500",
  "#E9367E",
  "#3E6EDF",
  "#8B5AEE",
  "#6FCAFF",
  "#FFA500",
  "#E9367E",
  "#3E6EDF",
  "#8B5AEE",
  "#6FCAFF",
];

const KanbanBoard = ({
  projects,
  milestones,
  users: usersObj,
  setModal,
  setProjectToDelete,
  setProjectToUpdate,
  setProjectListToEdit,
  isAdmin,
}) => {
  const dispatch = useDispatch();

  const projectsRedux = useSelector((s) => s.user.projects);

  function onDragEnd(res) {
    console.log(res);
    if (res?.type === "column") {
      const data = {
        milestoneId: res?.draggableId,
        sourceIndex: res?.source?.index,
        destinationIndex: res?.destination.index,
      };
      handleUpdateKanban(data);
    } else {
      if (res?.destination?.droppableId) {
        let milestone = milestones.find(
          (val) => val._id === res?.destination?.droppableId
        ) || { key: "" };
        dispatch(
          updateMilestone({
            id: res.draggableId,
            milestone: milestone?._id ?? "",
          })
        );

        const currentProject = projectsRedux?.find(
          (item) => item?._id === res.draggableId
        );

        let form = {
          milestone: milestone._id ?? "",
          _id: res.draggableId,
          projectExportSettings: currentProject?.projectExportSettings,
        };
        editProjectAPI(form);
      }
    }
  }

  const { loading, request: editProjectAPI } = useHttp({
    requestCallback: (data) => editProjectPut(data),
    onLoad: (res) => {
      console.log(res);

      const cloneProjectArr = [...projectsRedux];

      if (res?.isParent) {
        const i = cloneProjectArr.findIndex(
          (x) => x.parentProjectID === res._id
        );
        cloneProjectArr[i] = {
          ...cloneProjectArr[i],
          milestone: res?.milestone,
        };
        console.log(cloneProjectArr);
        dispatch(setProjects(cloneProjectArr));
      } else {
        const i = cloneProjectArr.findIndex((x) => x._id === res._id);
        cloneProjectArr[i] = res ;
        dispatch(setProjects(cloneProjectArr));
        console.log(cloneProjectArr);
      }

      addNotification("Project updated successfully", "success");
    },
    onError: (res) => {
      addNotification(
        res.err ?? "Something went wrong please connect with support",
        "danger"
      );
    },
  });

  const rearrangeMilestone = () => {
    if (milestones) {
      const index = milestones?.findIndex(
        (obj) => obj.key.toLowerCase() === "untracked projects"
      );

      if (index !== -1) {
        const removedObject = milestones?.splice(index, 1)[0];
        milestones.unshift(removedObject);
      }
    }
  };

  useEffect(() => {
    rearrangeMilestone();
  }, []);

  const { loading: milestoneLoading, request: handleUpdateKanban } = useHttp({
    requestCallback: updateKanban,
    onLoad: (res) => {
      console.log(res);
      if (res?.success) {
        dispatch(setMilestones(res.data));
      }
      addNotification("Milestone updated successfully", "success");
    },
    onError: (res) => {
      addNotification(
        res.err ?? "Something went wrong please connect with support",
        "danger"
      );
    },
  });

  const sortedMilestones =
    milestones?.sort(
      (a, b) => (a.milestoneIndex || 0) - (b.milestoneIndex || 0)
    ) ?? [];

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="d-flex karban-container"
          >
            {milestones &&
              sortedMilestones.map((mile, i) => (
                <KanbanCategory
                  // placeholderProps={placeholderProps}
                  color={colors[i]}
                  index={i}
                  key={mile._id}
                  mile={mile}
                  projects={projects}
                  usersObj={usersObj}
                  setModal={setModal}
                  setProjectToDelete={setProjectToDelete}
                  setProjectToUpdate={setProjectToUpdate}
                  setProjectListToEdit={setProjectListToEdit}
                />
              ))}
            {provided.placeholder}
            {/* </MDBContainer> */}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default KanbanBoard;
