import React, { useEffect, useState } from "react";
import Select from "react-select";
import { UpdateGroupVisibility, findRecursiveInTree } from "helpers/three";
import { fileterData, fileterTestData,filterDynamicData, logicGatesAndProjects } from "./test";
import { mergeDataWithLogicGate } from "./logicGate";
import { useSelector } from "react-redux";
import { useHttp } from 'hooks/useHttp';
import { postFilterView } from "api/filterView/postFilterView";
import { getFilterByProjectId } from "api/filterView/getFilterView";
import { useParams } from "react-router";
import addNotification from 'helpers/notify';

const Filters = ({ FiltersData, setTreeData, treeData, WebGL}) => {
  const [ProjectsRaw, setProjectsRaw] = useState();
  const [ProjectOption, setProjectOption] = useState();
  const [AdditionalParams, setAdditionalParams] = useState();
  const [EstimationStates, setEstimationStates] = useState();
  const [ElectricalCircuits, setElectricalCircuits] = useState();
  const [MechanicalSystems, setMechanicalSystems] = useState();

  const [logicalgate, setLogicalgate] = useState("AND");
  const [filterName, setFilterName] = useState("");
  const [selectedProject, setSelectedProject] = useState();
  const [selectedEstimationState, setSelectedEstimationState] = useState();
  const [selectedSubProject, setSelectedSubProject] = useState();
  const [selectedGroups, setSelectedgroups] = useState();
  const [selectedComponent, setSelectedComponent] = useState();

  const [selectedLogic, setSelectedLogic] = useState();
  const [subProjects, setSubProjects] = useState();
  const [groups, setGroups] = useState();
  const [component, setComponent] = useState();
  const [inputValue, setInputValue] = useState();
  const projectsFileRedux = useSelector((s) => s.user.projectFilesTree);

  const [filteredTable, setFilteredTable] = useState([]);
  const [tree, setTree] = useState([]);
  const {projectId, timelineId} = useParams();

  const [finalData, setFinalData] = useState([]);

  const EstimationStatesLabels = {
    PRICED: {
      key: "PricedComponents",
      label: "Priced Components",
    },
    UNPRICED: {
      key: "UnpricedComponents",
      label: "Unpriced Components",
    },
    EXISTING: {
      key: "ExistingComponents",
      label: "Existing Components",
    },
    DEMOLITION: {
      key: "DemolitionComponents",
      label: "Demolition Components",
    },
  };

  const { request: postFilterViewNodes } = useHttp({
    requestCallback: () => postFilterView({
      name: filterName,
      rules: tree,
      projectId: projectId,
      timelineId: timelineId
    }),
    onLoad: (data) => {
      if (data._id) {
        setFilterName(data.name);
        setTree(data.rules);
        const tableData = [];
        data.rules.forEach(rule => {
          const tableDataObject = {
            gate: rule.gate,
            project: [rule],
            estimationStates: rule.selectedEstimationState,
            subProject: rule.nodes,
            groups: rule.nodes.flatMap(x => x.nodes),
            component: [],
            logic: null,
            input: null,
          };
          tableData.push(tableDataObject);
        });
        setFilteredTable([...tableData]);
        addNotification('Filter(s) saved successfully!', 'success');
      }
    },
    onError: (error) => {
      console.log('Filter Error ====> ', error);
      addNotification('Something went wrong, please contact support', 'danger');
    },
  });


  const { request: getFilterViewNodesByProjectId } = useHttp({
    requestCallback: () => getFilterByProjectId(projectId, timelineId),
    onLoad: (data) => {
      if (data?._id) {
        setFilterName(data.name);
        setTree(data.rules);
        const tableData = [];
        data.rules.forEach(rule => {
          const tableDataObject = {
            gate: rule.gate,
            project: [rule],
            estimationStates: rule.estimationStates,
            subProject: rule.nodes,
            groups: rule.nodes.flatMap(x => x.nodes),
            component: [],
            logic: null,
            input: null,
          };
          tableData.push(tableDataObject);
        });
        console.log('from API ::', {tableData});
        setFilteredTable([...tableData]);
      }
    },
    onError: (error) => {
      console.log('Get filter error', error);
      addNotification('Something went wrong, please contact support', 'danger');
    },
  });

  useEffect(() => {
    const projOpt = [];

    const Allprojects = FiltersData?.map((item) => {
      if (item.hasOwnProperty("ProjectFiles")) {
        return item;
      }
    }).filter((item) => item);

    const AllAdditional = FiltersData?.map((item) => {
      if (item.hasOwnProperty("AdditionalParams")) {
        return item;
      }
    }).filter((item) => item);

    const estimationResults = FiltersData?.map((item) => {
      if (
        item.hasOwnProperty(EstimationStatesLabels.PRICED.key) ||
        item.hasOwnProperty(EstimationStatesLabels.UNPRICED.key) ||
        item.hasOwnProperty(EstimationStatesLabels.EXISTING.key) ||
        item.hasOwnProperty(EstimationStatesLabels.DEMOLITION.key)
      ) {
        return item;
      }
    }).filter((item) => item);

    const proj = Allprojects[0]?.ProjectFiles.forEach(function (item) {
      return item?.map((i) =>
        projOpt.push({
          value: i.id,
          label: i.label,
        })
      );
    });

    const AllElectrical = FiltersData?.map((item) => {
      if (item.hasOwnProperty("ElectricalCircuits")) {
        return item;
      }
    }).filter((item) => item);

    const AllMechanical = FiltersData?.map((item) => {
      if (item.hasOwnProperty("MechanicalSystems")) {
        return item;
      }
    }).filter((item) => item);

    getFilterViewNodesByProjectId();


    setProjectOption(projOpt);
    setEstimationStates([
      { None: [{ id: "none", label: "None" }] },
      ...estimationResults,
    ]);
    setProjectsRaw(...Allprojects);
    setAdditionalParams(...AllAdditional);
    setElectricalCircuits(...AllElectrical)
    setMechanicalSystems(...AllMechanical)
  }, []);

  const LogicalGates = [
    {
      label: "AND",
      value: "AND",
    },
    {
      label: "OR",
      value: "OR",
    },
    {
      label: "NOT",
      value: "NOT",
    },
    {
      label: "XOR",
      value: "XOR",
    },
    {
      label: "NAND",
      value: "NAND",
    },
    {
      label: "NOR",
      value: "NOR",
    },
    {
      label: "XNOR",
      value: "XNOR",
    },
  ];

  const Logics = [
    {
      label: "More Than",
      value: ">",
    },
    {
      label: "Contains",
      value: "==",
    },
    {
      label: "Less than",
      value: "<",
    },
    {
      label: "Different",
      value: "!=",
    },
    {
      label: "Equals",
      value: "==",
    },
    {
      label: "Has Value",
      value: "hasValue",
    },
    {
      label: "None",
      value: "none",
    },
  ];

  const onChangeFilter = (value) => {
    let Arr = [];
    const Object = ProjectsRaw?.ProjectFiles?.map((item) => {
      return value?.map((i) => {
        if (i.value === item[0].key) {
          return Arr.push(...item);
        }
      });
    });

    setSelectedProject(Arr);
    setSelectedEstimationState();
    setSelectedSubProject();
    setSelectedgroups();
    setSelectedComponent();
    setSelectedLogic();
  };

  const onChangeEstimationState = (obj) => {
    let IntersectData = [];

    if (obj?.filter((item) => item.value === "None").length !== 0) {
      IntersectData.push(...selectedProject?.flatMap((item) => item?.nodes));
    }

    const selectedEstimationResult = obj?.flatMap((item) => {
      return EstimationStates?.flatMap((i) => {
        if (i.hasOwnProperty(item.value)) {
          return Object.values(i);
        }
      }).filter((i) => i);
    });

    const filter = selectedEstimationResult?.flatMap((item) =>
      item?.flatMap((i) => {
        return i?.nodes?.flatMap((j) =>
          selectedProject?.flatMap((node) =>
            node?.nodes?.flatMap((k) => {
              if (k.label === j.label) {
                return IntersectData.push(k);
              }
            })
          )
        );
      })
    );

    const mergedDuplicateSubprojects = Array.from(
      new Set(IntersectData.map((s) => s))
    ).map((lab) => {
      return {
        label: lab.label,
        id: lab.id,
        nodes: IntersectData.filter((s) => s.label === lab.label)
          .map((item) => item.nodes)
          ?.flatMap((i) => i),
      };
    });

    // console.log(
    //   "--mergedDuplicateSubprojects--",
    //   JSON.stringify(mergedDuplicateSubprojects, null, 2)
    // );

    setSelectedEstimationState({ states: obj, nodes: IntersectData });
    setSubProjects(mergedDuplicateSubprojects);
    setSelectedSubProject();
    setSelectedgroups();
    setSelectedComponent();
    setSelectedLogic();
  };

  const onChangeSubprojects = (obj) => {
    const filteredNodes = obj
      ?.map((item) => JSON.parse(item?.obj))
      .flatMap((item) => {
        return item?.nodes?.flatMap((i) => {
          return i;
        });
      });

    const mergedDuplicategroups = Array.from(
      new Set(filteredNodes.map((s) => s))
    ).map((lab) => {
      // console.log(lab)
      return {
        label: lab.label,
        id: lab.id,
        nodes: filteredNodes
          .filter((s) => s.label === lab.label)
          .map((item) => item.nodes)
          ?.flatMap((i) => i),
      };
    });

    setSelectedSubProject(obj?.map((item) => JSON.parse(item?.obj)));
    setGroups(mergedDuplicategroups);

    setSelectedgroups();
    setSelectedComponent();
    setSelectedLogic();
  };

  const onChangeGroups = (obj) => {
    setSelectedgroups(obj?.map((item) => JSON.parse(item?.obj)));

    // getting meshinfo of first group node
    const meshInfo = obj
      ?.map((item) => JSON.parse(item?.obj))
      .flatMap((i) => {
        return i?.nodes[0]?.meshInfo;
      });

      const additionalParams = AdditionalParams?.AdditionalParams;
    // matching selected meshinfo UniqueID from Additional paramaters UniqueID
    const matchedAdditional = additionalParams?.flatMap(
      (item) => {
        return meshInfo
          ?.flatMap((i) => {
            if (i.UniqueId === item?.UniqueId) {
              return item;
            }
          })
          .filter((item) => item);
      }
    );

    const matchedElectrical = ElectricalCircuits?.ElectricalCircuits[0]?.flatMap(
      (item) => {
        return matchedAdditional
          ?.flatMap((i) => {
            if (i?.EquipmentParameters?.ElectricalSystem === item.uniqueId) {
              return item;
            }
          })
          .filter((item) => item);
      }
    );

    const matchedMechanical = ElectricalCircuits?.ElectricalCircuits[0]?.flatMap(
      (item) => {
        return matchedAdditional
          ?.flatMap((i) => {
            if (i?.EquipmentParameters?.MechanicalSystem === item.uniqueId) {
              return item;
            }
          })
          .filter((item) => item);
      }
    );


    let simplifiedMeshInfo = [];

    // Adding All objects of selected group meshInfo to Arr simplifiedMeshInfo
    const Mesh = meshInfo?.map((item) => {
      for (const keyName in item) {
        // console.log(keyName, item)
        simplifiedMeshInfo.push({
          value: item?.$id,
          label: keyName,
          meshInfo : item
        });
      }
    });

    // Adding All objects of Additional parameters to Array simplifiedMeshInfo
    const addMatch = matchedAdditional?.map((item) => {
      for (const key in item) {
        if (typeof item[key] === "object") {
          for (const innerKey in item[key]) {
            simplifiedMeshInfo.push({
              value: item?.$id,
              label: innerKey,
              meshInfo : item
            });
          }
        }
      }
    }); 

    setComponent(simplifiedMeshInfo);
    setSelectedComponent();
    setSelectedLogic();
  };

  const onChangeParameters = (obj) => {
    setSelectedComponent(obj);
    setSelectedLogic();
  };

  const onChangeLogic = (obj) => {
    setSelectedLogic(obj);
  };

  const applyFilter = () => {
    const Object = {
      gate: logicalgate,
      project: selectedProject,
      estimationStates: selectedEstimationState,
      subProject: selectedSubProject,
      groups: selectedGroups,
      component: selectedComponent,
      logic: selectedLogic,
      input: inputValue,
    };

    let fullSet = [
      ...selectedProject.map((project) => {
        return {
          id: project.id,
          gate: logicalgate,
          estimationStates: { states: selectedEstimationState.states },
          key: project.key,
          treeVisible: false,
          label: project.label,
          nodes: project.nodes // subproject
            .flatMap((subnode) => {
              return selectedSubProject?.flatMap((sub) => {
                if (sub?.id === subnode?.id) {
                  return {
                    id: sub.id,
                    key: sub.id,
                    label: sub.label,
                    nodes: sub?.nodes // group
                      ?.flatMap((groupnode) => {
                        return selectedGroups
                          ?.flatMap((gro) => {
                            if (gro.id === groupnode.id) {
                              return {
                                id: gro?.id,
                                key: gro?.id,
                                label: gro?.label,
                                nodes: gro?.nodes 
                                  ?.flatMap((compnode) => {
                                    return selectedComponent?.flatMap(
                                      (comp) => {
                                        if (comp?.value === compnode.id) {
                                          return {
                                            id: comp?.value,
                                            key: comp?.value,
                                            label: comp?.label,
                                            meshInfo: comp?.meshInfo,
                                            nodes: [],
                                            logic: selectedLogic,
                                            input: inputValue,
                                          };
                                        }
                                      }
                                    );
                                  })
                                  .filter((item) => item !== undefined),
                              };
                            }
                          })
                          .filter((item) => item !== undefined);
                      })
                      .filter((item) => item !== undefined),
                  };
                }
              });
            })
            .filter((item) => item !== undefined),
        };
      }),
    ];

    // console.log(fullSet);
    // console.log(Object);

    setFilteredTable((filteredTable) => [...filteredTable, Object]);
    setTree((tree) => [...tree, ...fullSet]);
    // const nodesTree = mergeDataWithLogicGate(fullSet, selectedProject);
    reset();
  };
  
  // useEffect(() => {
  //   UpdateGroupVisibility(tree, true);
  // }, [filteredTable]);

  const handleModelRender = (tree, id, treeVisible) => {
    const temp = [...tree];
    const newTreeItem = findRecursiveInTree(temp, id);

    if (treeVisible === false) {
      newTreeItem.result.treeVisible = false;
      newTreeItem.path.forEach((item) => {
        if (
          (item.nodes.filter((el) => el.treeVisible === false).length >=
            item.nodes.length - 1 &&
            (!newTreeItem.parent ||
              newTreeItem.parent.nodes.filter(
                (el) => el.treeVisible === false
              ).length >= newTreeItem.parent.nodes.length))
              ||
          item.treeVisible === false
        ) {
          (function disable(tree) {
            tree.treeVisible = false;
            if (tree.meshInfo) {
              let temp = WebGL.current.viewer.meshesObject.get(
                tree.meshInfo.UniqueId
              );
              if (temp) UpdateGroupVisibility(temp, false);
            }

            tree.nodes.forEach((item) => disable(item));
          })(item);
        }
      });
    } else {
      newTreeItem.result.treeVisible = true;
      newTreeItem.path.forEach((item) => {
        item.treeVisible = true;
      });
      if (
        newTreeItem.result.nodes.filter(
          (item) => item.treeVisible === false
        ).length >= newTreeItem.result.nodes.length
      ) {
        (function enable(tree) {
          tree.treeVisible = true;
          if (tree.meshInfo) {
            let temp = WebGL.current.viewer.meshesObject.get(
              tree.meshInfo.UniqueId
            );
            if (temp) UpdateGroupVisibility(temp, true);
          }
          tree.nodes.forEach((item) => enable(item));
        })(newTreeItem.result);
      }
    }

    setTreeData(temp);
}
  const RunFilter = () => {
    // console.log(fileterTestData, 'fileterTestData')
    // console.log(filterDynamicData, 'filterDynamicData')
    const filteredActualProjectTree = projectsFileRedux
    .flatMap((item) => item)
    .filter((i) => treeData.some((t) => t.label === i.label));
    
    const nodesTreeItems = mergeDataWithLogicGate(tree, filteredActualProjectTree);
    nodesTreeItems.forEach((nodeTree)=> {
      nodeTree.response.forEach((item)=> {
        handleModelRender(nodeTree?.projects, item.id, item.treeVisible)
      })
    });

  };

  const reset = () => {
    setSelectedProject();
    setSelectedEstimationState();
    setSelectedSubProject();
    setSelectedgroups();
    setSelectedComponent();
    setSelectedLogic();
  };

  const resetProjectFileTreeData = () => {
    const filteredActualProjectTree = projectsFileRedux
      .flatMap((item) => item)
      .filter((i) => treeData.some((t) => t.label === i.label));

    function enable(tree) {
      tree.treeVisible = true;
      if (tree.meshInfo) {
        let temp = WebGL.current.viewer.meshesObject.get(
          tree.meshInfo.UniqueId
        );
        if (temp) UpdateGroupVisibility(temp, true);
      }
      tree.nodes.forEach((item) => enable(item));
    }
    filteredActualProjectTree.forEach((item) => {
      enable(item);
    });
    setTreeData(filteredActualProjectTree);
  };

  const createFilterTree = async (data) => {
    try {
      const filteredData = data.map((mainObject) => {
        const uniqueNodes = {};
        const uniqSubProjNodes = {};
        const uniqSubProj = {};
        const projUniqId = {};
        const filteredGroup = [];
        const uniqueParentIDs = [];
        const filterSubProjNodes = [];
        const filteredSubProj = [];
        const filteredProj = [];
        const uniqLogic = {};

        mainObject.groups.forEach((group, index) => {
          group.nodes.forEach((node, index) => {
            mainObject.component.forEach((component, index) => {
              mainObject.logic.forEach((logic, index) => {
                const logicValue = logic.value;
                const evaluationResult = evaluateLogic(
                  node.meshInfo[component.label],
                  logicValue,
                  mainObject.input
                );

                if (evaluationResult) {
                  if (!uniqueNodes[node.id]) {
                    if (!uniqueParentIDs.includes(node.parentId)) {
                      uniqueParentIDs.push(node.parentId);
                    }
                    uniqueNodes[node.id] = true;
                    filteredGroup.push(node);
                  }
                }
              });
            });
          });
        });

        uniqueParentIDs.forEach((parentId) => {
          mainObject.subProject.forEach((subProj) => {
            subProj.nodes.forEach((node) => {
              if (node.id === parentId) {
                if (!uniqSubProjNodes[node.id]) {
                  const filteredNodeGroup = filteredGroup.filter(
                    (item) => item.parentId === parentId
                  );
                  uniqSubProjNodes[node.id] = true;
                  filterSubProjNodes.push({
                    id: node.id,
                    parentId: node.parentId,
                    treeVisible: node.treeVisible,
                    treeSelected: node.treeSelected,
                    key: node.key,
                    label: node.label,
                    nodes: filteredNodeGroup,
                  });
                }
              }
            });
          });
        });

        mainObject.estimationStates.nodes.forEach((node) => {
          filterSubProjNodes.forEach((node1) => {
            if (node.id === node1.parentId) {
              if (!uniqSubProj[node.id]) {
                uniqSubProj[node.id] = true;
                const filteredSubNodes = filterSubProjNodes.filter(
                  (item) => item.parentId === node.id
                );

                filteredSubProj.push({
                  id: node.id,
                  parentId: node.parentId,
                  treeVisible: node.treeVisible,
                  treeSelected: node.treeSelected,
                  key: node.key,
                  label: node.label,
                  nodes: filteredSubNodes,
                });
              }
            }
          });
        });

        mainObject.project.forEach((project) => {
          filteredSubProj.forEach((subProj) => {
            if (project.id === subProj.parentId) {
              if (!projUniqId[project.id]) {
                projUniqId[project.id] = true;
                const filteredSubNodes = filteredSubProj.filter(
                  (item) => item.parentId === project.id
                );
                filteredProj.push({
                  key: project.key,
                  label: project.label,
                  id: project.id,
                  parentId: project.parentId,
                  treeVisible: project.treeVisible,
                  nodes: filteredSubNodes,
                });
              }
            }
          });
        });

        return {
          // ...mainObject,
          gate: mainObject.gate,
          project: filteredProj,
        };
      });

      const resultLogic = mergeDataWithLogicGate(filteredData);

      // return filteredData;
    } catch (error) {
      console.log("--ErrorTreeTwo--", error);
    }
  };

  const evaluateLogic = (meshInfo, logicValue, input) => {
    switch (logicValue) {
      case "==":
        return meshInfo == input;
      case "!=":
        return meshInfo != input;
      case "<":
        return meshInfo < input;
      case ">":
        return meshInfo > input;
      case "hasValue":
        return meshInfo != undefined && meshInfo != null;
      case "none":
        return meshInfo == undefined || meshInfo == null;
      default:
        return false;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className=" btn-primary px-3 bg-bid"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          <i className="fa fa-plus  mr-2"></i>
          Add Rule
        </button>

        <div className="d-flex">
          <button
            className=" btn-primary px-3 bg-bid mr-2"
            onClick={() => {
              setFilteredTable([])
              setTree([]);
              resetProjectFileTreeData();
            }}
          >
            <i className="fa fa-refresh  mr-2"></i>
            Reset
          </button>
          <button
            type="button"
            className=" btn-primary px-3 bg-bid mr-2"
            data-toggle="modal"
            data-target="#saveModal"
            disabled={filteredTable.length === 0}
          >
            <i className="fa fa-floppy-o  mr-2"></i>
            Save
          </button>
          <button
            type="button"
            className=" btn-primary px-3 bg-bid"
            // data-toggle="modal"
            // data-target="#loadModal"
            onClick={getFilterViewNodesByProjectId}
          >
            <i className="fa fa-download  mr-2"></i>
            Load
          </button>
        </div>
      </div>

      <div className="filterTableDiv">
        <table className="table">
          <thead>
            <tr>
              <th>Logic gate</th>
              <th>Projects</th>
              <th>States</th>
              <th>Subprojects</th>
              <th>Groups</th>
              {/* <th>Parameters</th>
              <th>Logic</th>
              <th>Input</th> */}
            </tr>
          </thead>
          <tbody>
            {filteredTable?.map((item, i) => {
              return (
                <tr key={item.label + i}>
                  <td>{item?.gate}</td>
                  <td>{item?.project?.map((item) => item.label)}</td>
                  <td>
                    {item?.estimationStates?.states.map((item) => item.label)}
                  </td>
                  <td>{item?.subProject?.map((item) => item.label)}</td>
                  <td>{item?.groups?.map((item) => item.label)}</td>
                  <td>{item?.component?.map((item) => item.label)}</td>
                  <td>{item?.logic?.map((item) => item.label)}</td>
                  <td>{item?.input}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <button
        className=" btn-primary px-3 bg-bid ml-auto float-right"
        onClick={RunFilter}
        // disabled={filteredTable.length === 0}
      >
        <i className="fa fa- filter  mr-2"></i>
        Run Filter
      </button>

      {/* Add Rule Modal */}
      <div
        className="modal fade bd-example-modal-xl"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Rule
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="filters-container">
                <div className="form-group">
                  <label>Logic</label>
                  <select
                    className="form-control"
                    onChange={(e) => setLogicalgate(e.target.value)}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {LogicalGates.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Project Files</label>
                  <Select
                    name="filters"
                    isMulti
                    onChange={(e) => onChangeFilter(e)}
                    options={ProjectOption}
                  />
                </div>
                {selectedProject?.length > 0 && (
                  <div className="form-group">
                    <label>Estimation States</label>
                    <Select
                      isMulti
                      onChange={(e) => onChangeEstimationState(e)}
                      options={EstimationStates?.map((item) => {
                        for (var key in item) {
                          return {
                            value: key,
                            label: key,
                          };
                        }
                      })}
                    />
                  </div>
                )}

                {selectedEstimationState?.states?.length > 0 && (
                  <div className="form-group">
                    <label>Subprojects</label>
                    <Select
                      isMulti
                      onChange={(e) => onChangeSubprojects(e)}
                      options={subProjects?.map((item) => {
                        return {
                          label: item?.label,
                          value: item?.id,
                          obj: JSON.stringify(item),
                        };
                      })}
                    />
                  </div>
                )}

                {selectedSubProject?.length > 0 && (
                  <div className="form-group">
                    <label>Groups</label>
                    <Select
                      isMulti
                      onChange={(e) => onChangeGroups(e)}
                      options={groups?.map((item) => {
                        return {
                          label: item?.label,
                          value: item?.id,
                          obj: JSON.stringify(item),
                        };
                      })}
                    />
                  </div>
                )}

                {/* {selectedGroups?.length > 0 && (
                  <div className="form-group">
                    <label>Parameters</label>
                    <Select
                      isMulti
                      onChange={(e) => onChangeParameters(e)}
                      options={component}
                    />
                  </div>
                )}

                {selectedComponent?.length > 0 && (
                  <div className="form-group">
                    <label>Logic</label>
                    <Select
                      isMulti
                      onChange={(e) => onChangeLogic(e)}
                      options={Logics?.map((item) => {
                        return {
                          label: item?.label,
                          value: item?.value,
                        };
                      })}
                    />
                  </div>
                )}

                {selectedLogic?.length > 0 && (
                  <div className="form-group">
                    <label>Value</label>
                    <input
                      type="text"
                      className="filter_input"
                      onChange={(e) => setInputValue(e?.target?.value)}
                    />
                  </div>
                )} */}
              </div>
              <button
                type="button"
                className=" btn-primary mt-4 px-4 py-1 bg-bid"
                onClick={applyFilter}
                data-dismiss="modal"
                aria-label="Close"
                // disabled={selectedProject.length }
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Load Modal */}
      <div
        className="modal fade bd-example-modal-lg"
        id="loadModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="loadModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="loadModalLabel">
                Load Filter
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Logical</th>
                    <th>Project</th>
                    <th>Estimation States</th>
                    <th>Subproject</th>
                    <th>Groups</th>
                    <th>Parameter</th>
                    <th>Logic</th>
                    <th>Input</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                  </tr>
                  <tr>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                  </tr>
                  <tr>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                    <td>Demo</td>
                  </tr>
                </tbody>
              </table>
              <button
                type="button"
                className=" btn-primary mt-4 px-4 py-1 bg-bid"
                data-dismiss="modal"
                aria-label="Close"
              >
                Load
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Save Modal */}
      <div
        className="modal fade bd-example-modal-md"
        id="saveModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="saveModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="saveModalLabel">
                Save Filter
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body min-height-auto">
              <input
                type="text"
                placeholder="Filter Name"
                className="filterInputText"
                onChange={(event) => {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation();
                  setFilterName(event.target.value);
                }}
                value={filterName}
                required
              />
              <button
                type="button"
                disabled={filterName.length === 0}
                className=" btn-primary mt-4 px-4 py-1 bg-bid"
                data-dismiss="modal"
                aria-label="Close"
                onClick={postFilterViewNodes}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Filters;
