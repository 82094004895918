import React, { useState } from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBInput,
  MDBBox,
  MDBBtn,
} from 'mdbreact';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Loading from '../ui-components/loading';

import addNotification from 'helpers/notify';
import { choseToggle } from 'helpers/toggleIfLoading';
import { useHttp } from 'hooks/useHttp';
import { editTimeLine } from 'api/projects/editTimeline';
import { adminEditTimeline } from 'actions/admin';

const EditTimelineModal = props => {
  const [timeLineItemState, setTimeLineItemState] = useState(
    props.timelineItem,
  );
  const [json, setJson] = useState(null);
  const [xlsx, setXlsx] = useState(null);
  const [obj, setObj] = useState(null);
  const [mtl, setMtl] = useState(null);
  const [pdf, setPdf] = useState(null);


  const { toggle, clear } = props;
  const dispatch = useDispatch();

  const changeTimeline = (prop, payload) => {
    setTimeLineItemState(s => ({ ...s, [prop]: payload }));
  };
  const editTimelineForm = new FormData();

  const editTimeLineField = field => {
    if (props.timelineItem[field] !== timeLineItemState[field]) {
      editTimelineForm.append(field, timeLineItemState[field]);
    }
  };

  const { loading, request: editTimeLineAPI } = useHttp({
    requestCallback: editTimeLine,
    onLoad: res => {
      dispatch(adminEditTimeline(timeLineItemState, res));
      toggle();
      addNotification('Timeline was edited successfully', 'success');
    },
    onError: res => {
      toggle();
      clear();
      addNotification(res.message, 'danger');
    },
  });

  const editTimeline = () => {
    editTimeLineField('title');
    editTimeLineField('description');
    editTimeLineField('json');
    editTimeLineField('obj');
    editTimeLineField('mtl');
    editTimeLineField('excel');
    editTimeLineField('pdf');

    return editTimeLineAPI({
      id: timeLineItemState._id,
      body: editTimelineForm,
    });
  };

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={choseToggle(loading, toggle)}
      centered
    >
      <MDBModalHeader toggle={choseToggle(loading, toggle)}>
        Edit timeline
      </MDBModalHeader>
      <MDBModalBody>
        <form>
          <MDBInput
            size="sm"
            label="Title"
            className='mdb-input'
            value={timeLineItemState.title}
            onChange={e => changeTimeline('title', e.target.value)}
            required
            disabled={loading}
          />
          <MDBInput
            size="sm"
            label="Description"
            className='mdb-input'
            value={timeLineItemState.description}
            onChange={e => changeTimeline('description', e.target.value)}
            required
            disabled={loading}
          /> 
          <MDBBox className="mb-2">
            <input
              type="file"
              className="file-input"
              id="json_input"
              onChange={e => {
                changeTimeline('json', e.target.files[0]);
              setJson('jsonselected')}
              }
              accept={'.json'}
              required
              disabled={loading}
            />
            <label
              htmlFor="json_input"
              className={
                json === "jsonselected"
                  ? "sharePending-button active-toggle border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
                  : "sharePending-button border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
              }            >
              Choose JSON file
            </label>
          </MDBBox>
          <MDBBox className="mb-2">
            <input
              type="file"
              className="file-input"
              id="xlsx_input"
              onChange={e =>{
                changeTimeline('excel', e.target.files[0]);
                setXlsx('xlsxselected')
              } 
            }
              accept={'.xlsx'}
              required
              disabled={loading}
            />
            <label
              htmlFor="xlsx_input"
              className={
                xlsx === "xlsxselected"
                  ? "sharePending-button active-toggle border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
                  : "sharePending-button border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
              }            >
              Choose XLSX file
            </label>
          </MDBBox>
          <MDBBox className="mb-2">
            <input
              type="file"
              className="file-input"
              id="obj_input"
              onChange={e => {
                changeTimeline('obj', e.target.files[0]);
               setObj('objselected')
              }

              }
              accept={'.obj'}
              required
              disabled={loading}
            />
            <label
              htmlFor="obj_input"
              className={
                obj === "objselected"
                  ? "sharePending-button active-toggle border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
                  : "sharePending-button border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
              } 
            >
              Choose OBJ file
            </label>
          </MDBBox>
          <MDBBox className="mb-2">
            <input
              type="file"
              className="file-input"
              id="mtl_input"
              onChange={e => {
                changeTimeline('mtl', e.target.files[0])
                 setMtl('mtlselected')
              }
              }
              accept={'.mtl'}
              required
              disabled={loading}
            />
            <label
              htmlFor="mtl_input"
              className={
                mtl === "mtlselected"
                  ? "sharePending-button active-toggle border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
                  : "sharePending-button border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
              }            >
              Choose MTL file
            </label>
          </MDBBox>

          <MDBBox className="mb-2">
            <input
              type="file"
              className="file-input"
              id="pdf_input"
              onChange={e => {
                changeTimeline('pdf', e.target.files[0]);
                setPdf('pdfSelected')
              }}
              accept={'.pdf'}
              required
              disabled={loading}
            />
            <label
              htmlFor="pdf_input"
              className={
                pdf === "pdfSelected"
                  ? "sharePending-button active-toggle border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
                  : "sharePending-button border-bid radius-btn text-center py-2 w-100 file-input-btn m-0"
              }
              // className="border-bid text-white bg-bid radius-btn text-center py-2 file-input-btn w-100"
            >
              Choose PDF file
            </label>
          </MDBBox>

          <MDBBtn
            size="sm"
            color=''
            className="border-bid text-bid bg-white radius-btn text-center py-2 file-input-btn w-100 text-inherit shadow-none m-0"
            onClick={editTimeline}
            disabled={loading}
          >
            Edit
          </MDBBtn>
          {loading ? <Loading color="dark" text="Editing..." /> : null}
        </form>
      </MDBModalBody>
    </MDBModal>
  );
};


EditTimelineModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default EditTimelineModal;
