import { generateId } from './id';
import { Maybe } from './maybeFunctor';
import HashMap from 'hashmap'
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { Mesh, MeshBasicMaterial, MeshPhongMaterial, BufferGeometry, Box3, Matrix4, Vector3,
   DoubleSide, InstancedMesh, Box3Helper, DynamicDrawUsage, Float32BufferAttribute
   } from 'three'

export const generateRawMeshes = json => {
  const meshes = [];
  //console.log("From js utility generateMeshes ",json)
  json.SubProjects.forEach(SubProject => {
    SubProject.Groups.forEach(group => {
      group.Components.forEach(component => {
        meshes.push(component);
      });
    });
  });

  json.ExistingSubProjects.forEach(SubProject => {
    SubProject.Groups.forEach(group => {
      group.Components.forEach(component => {
        meshes.push(component);
      });
    });
  });
  json.DemolitionSubprojects.forEach(SubProject => {
    SubProject.Groups.forEach(group => {
      group.Components.forEach(component => {
        meshes.push(component);
      });
    });
  });
  return meshes;
};

export const generateMeshInfo = (json) => {
  const meshes = [];
  const arr = json?.map((item) => (
    item.forEach((i) => {
      i.nodes.forEach((j) => {
        j.nodes.forEach((k) => {
          // meshes.push(k.nodes[0].meshInfo); old code to add first instance of meshInfo
          k.nodes.forEach((l) => {
            meshes.push(l.meshInfo);
          })

        });
      });
    })
  ))

  return meshes;
};

export const generateRawTree = json => {


  let fullSet = [...json.SubProjects.map(subproject => {

    return {
      id: subproject.$id,
      parentId: json.$id,
      treeVisible: true,
      key: subproject.$id,
      label: subproject.Category,
      nodes: subproject.Groups.map(group => {


        return {
          id: group.$id,
          parentId: subproject.$id,
          treeVisible: true,
          key: group.$id,
          label: group.Name,
          nodes: group.Components.map(component => {
            return {
              id: component.$id,
              parentId: group.$id,
              key: component.$id,
              treeVisible: true,
              label: component.Name,
              meshInfo: component,
              nodes: [],
            };
          }),
        };
      }),
    };
  }), ...json.ExistingSubProjects.map(subproject => {

    return {
      id: subproject.$id,
      parentId: json.$id,
      treeVisible: true,
      key: subproject.$id,
      label: ' (Existing) ' + subproject.Category,
      nodes: subproject.Groups.map(group => {


        return {
          id: group.$id,
          parentId: subproject.$id,
          treeVisible: true,
          key: group.$id,
          label: ' (Existing) ' + group.Name,
          nodes: group.Components.map(component => {
            return {
              id: component.$id,
              parentId: group.$id,
              key: component.$id,
              treeVisible: true,
              label: ' (Existing) ' + component.Name,
              meshInfo: component,
              nodes: [],
            };
          }),
        };
      }),
    };
  }), ...json.DemolitionSubprojects.map(subproject => {

    return {
      id: subproject.$id,
      parentId: json.$id,
      treeVisible: true,
      key: subproject.$id,
      label: ' (Demolition) ' + subproject.Category,
      nodes: subproject.Groups.map(group => {


        return {
          id: group.$id,
          parentId: subproject.$id,
          treeVisible: true,
          key: group.$id,
          label: ' (Demolition) ' + group.Name,
          nodes: group.Components.map(component => {
            return {
              id: component.$id,
              parentId: group.$id,
              key: component.$id,
              treeVisible: true,
              label: ' (Demolition) ' + component.Name,
              meshInfo: component,
              nodes: [],
            };
          }),
        };
      }),
    };
  })]
  console.log("fullSet structure ", fullSet)
  return [
    {
      key: json.$id,
      label: json.Title,
      id: json.$id,
      parentId: json.$id,
      treeVisible: true,

      nodes: fullSet



    }

  ];
};

export const generateSubprojectMeshesHashed = subproejcts => {
  const meshes = new HashMap();
  //console.log("From js utility generateMeshes ",json)
  subproejcts.forEach(SubProject => {
    SubProject.Groups.forEach(group => {
      group.Components.forEach(component => {
        meshes.set(component.UniqueId, component);
      });
    });
  });

  return meshes;
};

export const generateMeshes = json => {
  const meshes = [];
  //console.log("From js utility generateMeshes ",json)
  json.SubProjects.forEach(SubProject => {
    SubProject.Groups.forEach(group => {
      group.Components.forEach(component => {
        meshes.push(component);
      });
    });
  });

  return meshes;
};

export const generateSubprojectMeshes = subproejcts => {
  const meshes = [];
  //console.log("From js utility generateMeshes ",json)
  subproejcts.forEach(SubProject => {
    SubProject.Groups.forEach(group => {
      group.Components.forEach(component => {
        meshes.push(component);
      });
    });
  });

  return meshes;
};

export const generateSubprojectHashmap = ($id, SubProjects) => {

  const hashmap = new HashMap();

  SubProjects.forEach(subproject => {
    hashmap.set(subproject.$id, {
      id: subproject.$id,
      parentId: $id,
      treeId: $id,
      treeVisible: true,
      treeSelected: false,
      key: subproject.$id,
      label: subproject.Category,
      nodes: subproject.Groups
    })

    subproject.Groups.forEach(group => {
      hashmap.set(group.$id,
        {
          id: group.$id,
          parentId: subproject.$id,
          treeId: $id,
          treeVisible: true,
          treeSelected: false,
          key: group.$id,
          label: group.Name,
          nodes: group.Components
        })

      group.Components.forEach(component => {
        hashmap.set(component.$id, {
          id: component.$id,
          parentId: group.$id,
          treeId: $id,
          key: component.$id,
          treeVisible: true,
          treeSelected: false,
          label: component.Name,
          meshInfo: component,
          nodes: [],
        })
      });
    });

  });

  return hashmap;
  // console.log("From js utility generateTree ",json)
  //  return [
  //    {
  //      key: $id,
  //      label: Title,
  //      id: $id,
  //      parentId: $id,
  //      treeVisible: true,
  //      treeSelected:false,
  //      nodes: SubProjects.map(subproject => {

  //        return {
  //          id: subproject.$id,
  //          parentId: $id,
  //          treeVisible: true,
  //          treeSelected:false,
  //          key: subproject.$id,
  //          label: subproject.Category,
  //          nodes: subproject.Groups.map(group => {


  //            return {
  //              id: group.$id,
  //              parentId: subproject.$id,
  //              treeVisible: true,
  //              treeSelected:false,
  //              key: group.$id,
  //              label: group.Name,
  //              nodes: group.Components.map(component => {
  //                return {
  //                  id: component.$id,
  //                  parentId: group.$id,
  //                  key: component.$id,
  //                  treeVisible: true,
  //                  treeSelected:false,
  //                  label: component.Name,
  //                  meshInfo: component,
  //                  nodes: [],
  //                };
  //              }),
  //            };
  //          }),
  //        };
  //      }),
  //    },
  //  ];
};

export const generateSubprojectTree = ($id, Title, SubProjects) => {

  // console.log("From js utility generateTree ",json)
  return [
    {
      key: $id,
      label: Title,
      id: $id,
      parentId: $id,
      treeVisible: true,
      treeSelected: false,
      nodes: SubProjects.map(subproject => {

        return {
          id: subproject.$id,
          parentId: $id,
          treeVisible: true,
          treeSelected: false,
          key: subproject.$id,
          label: subproject.Category,
          nodes: subproject.Groups.map(group => {


            return {
              id: group.$id,
              parentId: subproject.$id,
              treeVisible: true,
              treeSelected: false,
              key: group.$id,
              label: group.Name,
              nodes: group.Components.map(component => {
                return {
                  id: component.$id,
                  parentId: group.$id,
                  key: component.$id,
                  treeVisible: true,
                  treeSelected: false,
                  label: component.Name,
                  meshInfo: component,
                  //    LaborInfo:component.LaborInfo?.map(li => { return {TotalPrice:li.TotalPrice, TotalTime:li.TotalTime,Description:li.Description}}),
                  //     EquipmentInfo:component.EquipmentInfo?.map(li => { return {TotalPrice:li.TotalPrice, TotalTime:li.TotalTime,Description:li.Description}}),

                  nodes: [],
                };
              }),
            };
          }),
        };
      }),
    },
  ];
};

export const generateTree = json => {
  const mainParentId = generateId();
  // console.log("From js utility generateTree ",json)
  return [
    {
      key: json.$id,
      label: json.Title,
      id: mainParentId,
      parentId: mainParentId,
      treeVisible: true,
      jsonId: json.$id,
      nodes: json.SubProjects.map(subproject => {
        const mediumParentId = generateId();
        return {
          id: mediumParentId,
          parentId: mainParentId,
          treeVisible: true,
          jsonId: subproject.$id,
          key: json.$id,
          label: subproject.Category,
          nodes: subproject.Groups.map(group => {
            const deepParentId = generateId();

            return {
              id: deepParentId,
              parentId: mediumParentId,
              jsonId: group.$id,
              treeVisible: true,
              key: json.$id,
              label: group.Name,
              nodes: group.Components.map(component => {
                return {
                  id: generateId(),
                  parentId: deepParentId,
                  key: json.$id,
                  treeVisible: true,
                  label: component.Name,
                  meshInfo: component,
                  jsonId: component.$id,
                  nodes: [],
                };
              }),
            };
          }),
        };
      }),
    },
  ];
};


export const findRecursiveInTreeList = (isComposed, treeList, id) => {
  let result = null;
  let parent = null;
  let resultPath = [];
  console.log("isComposed Recursive", isComposed)
  console.log("temp = ", treeList)
  console.log("id = ", id)


  console.log("treeList size = ", treeList.length)
  treeList.map((treefile) => {
    return Maybe.of(treefile).map(tree => (

      (function search(tree, id, par, path = [tree]) {
        if (tree.id === id) {
          result = tree;
          parent = par;
          resultPath = path;
        } else if (!result) {
          tree.nodes.forEach(item => {
            const newPath = [...path, item];
            search(item, id, tree, newPath);
          });
        }
      })(tree[0], id)
    ))

    console.log(treefile, "findRecursiveInTreeList", id)
  })


  return {
    result: result,
    parent: parent,
    path: resultPath,
  };
};

export const findRecursiveInTree = (tree, id) => {
  let result = null;
  let parent = null;
  let resultPath = [];

  (function search(tree, id, par, path = [tree]) {
    if (tree.key === id) {
      result = tree;
      parent = par;
      resultPath = path;
    } else if (!result) {
      tree.nodes.forEach(item => {
        const newPath = [...path, item];
        search(item, id, tree, newPath);
      });
    }
  })(tree[0], id);

  return {
    result: result,
    parent: parent,
    path: resultPath,
  };
};

export const flattenTree = (tree, flattenedTree = []) => {
  if (tree.nodes.length === 0) {
    flattenedTree.push(tree);
  } else if (tree.nodes.length > 0) {
    tree.nodes.forEach(n => {
      if (n) {
        flattenTree(n, flattenedTree);
      }
    });
  }
  return flattenedTree;
};

let mtx = new Matrix4();
let mtx2 = new Matrix4();
// im - InstancedMesh
// instanceIdx - index of an instance
// visible - boolean show or hide
export const instanceShow = (im, instanceIdx, visible) => {

  var transformation = im.userData[instanceIdx].transformation;

  const matrix = new Matrix4()

  if (visible) {
    matrix.set(
      transformation.m0, transformation.m1, transformation.m2, transformation.m3,
      transformation.m4, transformation.m5, transformation.m6, transformation.m7,
      transformation.m8, transformation.m9, transformation.m10, transformation.m11,
      transformation.m12, transformation.m13, transformation.m14, transformation.m15)
  }
  else {
    matrix.set(
      transformation.m0, transformation.m1, transformation.m2, transformation.m3,
      transformation.m4, transformation.m5, transformation.m6, transformation.m7,
      transformation.m8, transformation.m9, transformation.m10, transformation.m11,
      transformation.m12, transformation.m13, transformation.m14, 0)
  }

  im.setMatrixAt(instanceIdx, matrix)
  im.instanceMatrix.needsUpdate = true;
}

export const UpdateGroupVisibility = (meshObj, visibility) => {

  // console.log("come here", meshObj);

  if (meshObj.mesh?.isMesh) {
    instanceShow(meshObj.mesh, meshObj.index, visibility);

    // // mesh.visible = visibility;
    // if (visibility === false)
    //   meshObj.mesh.layers.set(1);

    // if (visibility === true)
    //   meshObj.mesh.layers.set(0);

  } else if (meshObj.mesh?.children) {
    meshObj.mesh.children.forEach(m => {
      if (m) {
        UpdateGroupVisibility(m, visibility);
      }
    });
  }
};

export const GetChildrenMeshes = (meshObj, oldMeshes = []) => {
  if (meshObj.mesh.isMesh) {
    oldMeshes.push(meshObj);

  } else if (meshObj.mesh.children) {
    meshObj.mesh.children.forEach(m => {
      if (m) {
        GetChildrenMeshes(m, oldMeshes);
      }
    });
  }
  return oldMeshes;
};

export const MergeChildren = (meshObj, box3 = new Box3()) => {
  if (meshObj.mesh.isMesh) {

    const mesh = this.meshesObject.get(meshObj.uniqueId);

    // conform to the object size like it's a boundingBox
    box3.expandByObject(mesh);

  } else if (meshObj.mesh.children) {
    meshObj.mesh.children.forEach(m => {
      if (m) {
        MergeChildren(m, box3);
      }
    });
  }

  // make a BoxBufferGeometry of the same size as Box3
  const dimensions = new Vector3().subVectors(box3.max, box3.min);
  const boxGeo = new BufferGeometry(dimensions.x, dimensions.y, dimensions.z);

  // move new mesh center so it's aligned with the original object
  const matrix = new Matrix4().setPosition(dimensions.addVectors(box3.min, box3.max).multiplyScalar(0.5));


  // return a mesh

  return new Mesh(boxGeo.applyMatrix4(matrix), new MeshBasicMaterial({ color: 0xffcc55 }));
};

export const SetChildrenDepthWrite = (mesh) => {
  if (mesh.isMesh) {


    mesh.material.colorWrite = true;


    if (mesh.material.opacity < 1) {
      mesh.material.transparent = true;
    } else {
      mesh.material.transparent = false;


    }

    mesh.material.depthTest = true;
    mesh.material.depthWrite = true;

  } else if (mesh.children) {
    mesh.children.forEach(m => {
      if (m) {
        SetChildrenDepthWrite(m);
      }
    });
  }

};

export const GetChildrenMaterials = (meshObj, oldMaterials = []) => {
  if (meshObj.mesh.isMesh) {
    oldMaterials.push(meshObj.mesh.material);

  } else if (meshObj.mesh.children) {
    meshObj.mesh.children.forEach(m => {
      if (m) {
        GetChildrenMaterials(m, oldMaterials);
      }
    });
  }
  return oldMaterials;
};

export const GetChildrenids = (meshObj, ids = []) => {
  if (meshObj.mesh.isMesh) {
    ids.push(meshObj.UniqueId);

  } else if (meshObj.mesh.children) {
    meshObj.mesh.children.forEach(m => {
      if (m) {
        GetChildrenids(m, ids);
      }
    });
  }
  return ids;
};

export const SetChildrenMaterial = (meshObj, material, oldMaterials = []) => {

  //console.log('material here');

  if (meshObj.mesh.isMesh) {
    oldMaterials.push(meshObj.mesh.material);

    meshObj.mesh.setColorAt(meshObj.index, material.color);
    meshObj.mesh.instanceColor.needsUpdate = true;
    // meshObj.mesh.material.needsUpdate = true;
    // meshObj.mesh.needsUpdate = true;
    //  meshObj.mesh.geometry.computeVertexNormals();;

    // console.log("SetChildrenMaterial Index = " + meshObj.index);
    //console.log("SetChildrenMaterial Color = " + material.color);



  } else if (meshObj.mesh.children) {
    meshObj.mesh.children.forEach(m => {
      if (m) {
        SetChildrenMaterial(m, material, oldMaterials);
      }
    });
  }
  return oldMaterials;
};

export const SetChildrenMaterialsBack = (meshObjList, materialList, i = 0) => {

  //console.log(i);

  if (meshObjList && meshObjList[i] !== undefined) {
    if (meshObjList[i].mesh.isMesh) {

      //   meshObjList[i].mesh.material = material[i];
      meshObjList[i].mesh.setColorAt(meshObjList[i].index, materialList[i].color);
      meshObjList[i].mesh.needsUpdate = true;
      meshObjList[i].mesh.instanceColor.needsUpdate = true;

      // console.log("SetChildrenMaterialsBack Index = " + meshObjList[i].index);
      // console.log("SetChildrenMaterialsBack Color = " + materialList[i].color);


      i++;
      SetChildrenMaterialsBack(meshObjList, materialList[i], i);
    }
  } else if (meshObjList && meshObjList.children) {
    meshObjList.mesh.children.forEach(m => {
      if (m) {
        SetChildrenMaterialsBack(m, materialList, i);
      }
    });
  }
};

export const findRecursiveInTreeJsonId = (tree, jsonId) => {
  let result = null;
  let parent = null;
  let resultPath = [];

  (function search(tree, jsonId, par, path = [tree]) {
    if (tree.id === jsonId) {
      result = tree;
      parent = par;
      resultPath = path;
    } else if (!result) {

      tree.nodes.forEach(item => {
        const newPath = [...path, item];
        search(item, jsonId, tree, newPath);
      });


    }
  })(tree[0], jsonId);

  return {
    result: result,
    parent: parent,
    path: resultPath,
  };
};

export const findRecursiveInTreeJsonIdHashmap = (tree, jsonId) => {
  let result = tree.current.get(jsonId);
  console.log({ result })
  let parent = null;
  if (result) {
    parent = tree.current.get(result.parentId);
  }

  // let resultPath = [];

  // (function search(tree, jsonId, par, path = [tree]) {
  //   if (tree.id === jsonId) {
  //     result = tree;
  //     parent = par;
  //     resultPath = path;
  //   } else if (!result) {

  //       tree.nodes.forEach(item => {
  //         const newPath = [...path, item];
  //         search(item, jsonId, tree, newPath);
  //       });


  //   }
  // })(tree[0], jsonId);

  return {
    result: result,
    parent: parent,
    //  path: resultPath,
  };
};

export const findRecursiveInTreeListJsonId = (treeList, jsonId) => {

  console.log("findRecursiveInTreeListJsonId", treeList, "jsonid", jsonId)
  let result = null;
  let parent = null;
  let resultPath = [];
  if (isIterable(treeList)) {


    treeList.map((treefile, index) => {
      return Maybe.of(treefile).map(tree => (

        (function search(tree, jsonId, par, path = [tree]) {
          if (tree.id === jsonId) {
            result = tree;
            parent = par;
            resultPath = path;
          } else if (!result) {

            tree.nodes.forEach(item => {
              const newPath = [...path, item];
              search(item, jsonId, tree, newPath);
            });
          }
        })

          (tree[0], jsonId)))

      console.log("findRecursiveInTreeListJsonId", jsonId)
    })
  } else {
    (function search(treeList, jsonId, par, path = [treeList]) {
      if (treeList.id === jsonId) {
        result = treeList;
        parent = par;
        resultPath = path;
      } else if (!result) {
        treeList.nodes.forEach(item => {
          const newPath = [...path, item];
          search(item, jsonId, treeList, newPath);
        });
      }
    })

      (treeList[0], jsonId)
    console.log("findRecursiveInTreeListJsonId", jsonId)
  }





  return {
    result: result,
    parent: parent,
    path: resultPath,
  };
};

export const isIterable = (x) => { return !!x?.[Symbol.iterator] };


 let _cb = new Vector3();
 let  _ab = new Vector3();


export const modify = (geometry, count) => {

  _cb = new Vector3();
  _ab = new Vector3();

  //geometry = geometry.clone();
  const attributes = geometry.attributes; // this modifier can only process indexed and non-indexed geomtries with a position attribute

  for (const name in attributes) {
    if (name !== 'position') geometry.deleteAttribute(name);
  }

  geometry = BufferGeometryUtils.mergeVertices(geometry); //
  // put data of original geometry in different data structures
  //

  const vertices = [];
  const faces = []; // add vertices

  const positionAttribute = geometry.getAttribute('position');

  for (let i = 0; i < positionAttribute.count; i++) {

    const v = new Vector3().fromBufferAttribute(positionAttribute, i);
    const vertex = new Vertex(v);
    vertices.push(vertex);

  } // add faces


  let index = geometry.getIndex();

  if (index !== null) {

    for (let i = 0; i < index.count; i += 3) {

      const a = index.getX(i);
      const b = index.getX(i + 1);
      const c = index.getX(i + 2);
      const triangle = new Triangle(vertices[a], vertices[b], vertices[c], a, b, c);
      faces.push(triangle);

    }

  } else {

    for (let i = 0; i < positionAttribute.count; i += 3) {

      const a = i;
      const b = i + 1;
      const c = i + 2;
      const triangle = new Triangle(vertices[a], vertices[b], vertices[c], a, b, c);
      faces.push(triangle);

    }

  } // compute all edge collapse costs


  for (let i = 0, il = vertices.length; i < il; i++) {

    computeEdgeCostAtVertex(vertices[i]);

  }

  // Remove 'count' number of edges with the lowest costs
  const arrIncreasingCostVertices = Array(vertices.length).fill().map((_, index) => { return index })

  arrIncreasingCostVertices.sort(function (index1, index2) {
    return vertices[index1].collapseCost - vertices[index2].collapseCost;
  });

  const arrVerticesToRemove = arrIncreasingCostVertices.slice(0, count);

  arrVerticesToRemove.sort((index1, index2) => { return index2 - index1; });

  if (arrVerticesToRemove.length < count) {

    console.log("Only found " + arrVerticesToRemove.length + " vertices to remove.");

  }

  for (const nextVertexIndex of arrVerticesToRemove) {

    const nextVertex = vertices[nextVertexIndex];

    collapse(vertices, faces, nextVertex, nextVertex.collapseNeighbor);

  }

  const simplifiedGeometry = new BufferGeometry();
  const position = [];
  index = []; //

  for (let i = 0; i < vertices.length; i++) {

    const vertex = vertices[i].position;
    position.push(vertex.x, vertex.y, vertex.z); // cache final index to GREATLY speed up faces reconstruction

    vertices[i].id = i;

  } //


  for (let i = 0; i < faces.length; i++) {

    const face = faces[i];
    index.push(face.v1.id, face.v2.id, face.v3.id);

  } //


  simplifiedGeometry.setAttribute('position', new Float32BufferAttribute(position, 3));
  simplifiedGeometry.setIndex(index);
  return simplifiedGeometry;

}

function pushIfUnique(array, object) {

  if (array.indexOf(object) === - 1) array.push(object);

}

function removeFromArray(array, object) {

  const k = array.indexOf(object);
  if (k > - 1) array.splice(k, 1);

}

function computeEdgeCollapseCost(u, v) {

  // if we collapse edge uv by moving u to v then how
  // much different will the model change, i.e. the "error".
  const edgelength = v.position.distanceTo(u.position);
  let curvature = 0;
  const sideFaces = []; // find the "sides" triangles that are on the edge uv

  for (let i = 0, il = u.faces.length; i < il; i++) {

    const face = u.faces[i];

    if (face.hasVertex(v)) {

      sideFaces.push(face);

    }

  } // use the triangle facing most away from the sides
  // to determine our curvature term


  for (let i = 0, il = u.faces.length; i < il; i++) {

    let minCurvature = 1;
    const face = u.faces[i];

    for (let j = 0; j < sideFaces.length; j++) {

      const sideFace = sideFaces[j]; // use dot product of face normals.

      const dotProd = face.normal.dot(sideFace.normal);
      minCurvature = Math.min(minCurvature, (1.001 - dotProd) / 2);

    }

    curvature = Math.max(curvature, minCurvature);

  } // crude approach in attempt to preserve borders
  // though it seems not to be totally correct


  const borders = 0;

  if (sideFaces.length < 2) {

    // we add some arbitrary cost for borders,
    // borders += 10;
    curvature = 1;

  }

  const amt = edgelength * curvature + borders;
  return amt;

}

function computeEdgeCostAtVertex(v) {

  // compute the edge collapse cost for all edges that start
  // from vertex v.  Since we are only interested in reducing
  // the object by selecting the min cost edge at each step, we
  // only cache the cost of the least cost edge at this vertex
  // (in member variable collapse) as well as the value of the
  // cost (in member variable collapseCost).
  if (v.neighbors.length === 0) {

    // collapse if no neighbors.
    v.collapseNeighbor = null;
    v.collapseCost = - 0.01;
    return;

  }

  v.collapseCost = 100000;
  v.collapseNeighbor = null; // search all neighboring edges for "least cost" edge

  for (let i = 0; i < v.neighbors.length; i++) {

    const collapseCost = computeEdgeCollapseCost(v, v.neighbors[i]);

    if (!v.collapseNeighbor) {

      v.collapseNeighbor = v.neighbors[i];
      v.collapseCost = collapseCost;
      v.minCost = collapseCost;
      v.totalCost = 0;
      v.costCount = 0;

    }

    v.costCount++;
    v.totalCost += collapseCost;

    if (collapseCost < v.minCost) {

      v.collapseNeighbor = v.neighbors[i];
      v.minCost = collapseCost;

    }

  } // we average the cost of collapsing at this vertex


  v.collapseCost = v.totalCost / v.costCount; // v.collapseCost = v.minCost;

}

function removeVertex(v, vertices) {

  console.assert(v.faces.length === 0);

  while (v.neighbors.length) {

    const n = v.neighbors.pop();
    removeFromArray(n.neighbors, v);

  }

  removeFromArray(vertices, v);

}

function removeFace(f, faces) {

  removeFromArray(faces, f);
  if (f.v1) removeFromArray(f.v1.faces, f);
  if (f.v2) removeFromArray(f.v2.faces, f);
  if (f.v3) removeFromArray(f.v3.faces, f); // TODO optimize this!

  const vs = [f.v1, f.v2, f.v3];

  for (let i = 0; i < 3; i++) {

    const v1 = vs[i];
    const v2 = vs[(i + 1) % 3];
    if (!v1 || !v2) continue;
    v1.removeIfNonNeighbor(v2);
    v2.removeIfNonNeighbor(v1);

  }

}

function collapse(vertices, faces, u, v) {

  // u and v are pointers to vertices of an edge
  // Collapse the edge uv by moving vertex u onto v
  if (!v) {

    // u is a vertex all by itself so just delete it..
    removeVertex(u, vertices);
    return;

  }

  const tmpVertices = [];

  for (let i = 0; i < u.neighbors.length; i++) {

    tmpVertices.push(u.neighbors[i]);

  } // delete triangles on edge uv:


  for (let i = u.faces.length - 1; i >= 0; i--) {

    if (u.faces[i] && u.faces[i].hasVertex(v)) {

      removeFace(u.faces[i], faces);

    }

  } // update remaining triangles to have v instead of u


  for (let i = u.faces.length - 1; i >= 0; i--) {

    u.faces[i].replaceVertex(u, v);

  }

  removeVertex(u, vertices); // recompute the edge collapse costs in neighborhood

  for (let i = 0; i < tmpVertices.length; i++) {

    computeEdgeCostAtVertex(tmpVertices[i]);

  }

}

// we use a triangle class to represent structure of face slightly differently


class Triangle {

  constructor(v1, v2, v3, a, b, c) {

    this.a = a;
    this.b = b;
    this.c = c;
    this.v1 = v1;
    this.v2 = v2;
    this.v3 = v3;
    this.normal = new Vector3();
    this.computeNormal();
    v1.faces.push(this);
    v1.addUniqueNeighbor(v2);
    v1.addUniqueNeighbor(v3);
    v2.faces.push(this);
    v2.addUniqueNeighbor(v1);
    v2.addUniqueNeighbor(v3);
    v3.faces.push(this);
    v3.addUniqueNeighbor(v1);
    v3.addUniqueNeighbor(v2);

  }

  computeNormal() {

    const vA = this.v1.position;
    const vB = this.v2.position;
    const vC = this.v3.position;

    _cb.subVectors(vC, vB);

    _ab.subVectors(vA, vB);

    _cb.cross(_ab).normalize();

    this.normal.copy(_cb);

  }

  hasVertex(v) {

    return v === this.v1 || v === this.v2 || v === this.v3;

  }

  replaceVertex(oldv, newv) {

    if (oldv === this.v1) this.v1 = newv; else if (oldv === this.v2) this.v2 = newv; else if (oldv === this.v3) this.v3 = newv;
    removeFromArray(oldv.faces, this);
    newv.faces.push(this);
    oldv.removeIfNonNeighbor(this.v1);
    this.v1.removeIfNonNeighbor(oldv);
    oldv.removeIfNonNeighbor(this.v2);
    this.v2.removeIfNonNeighbor(oldv);
    oldv.removeIfNonNeighbor(this.v3);
    this.v3.removeIfNonNeighbor(oldv);
    this.v1.addUniqueNeighbor(this.v2);
    this.v1.addUniqueNeighbor(this.v3);
    this.v2.addUniqueNeighbor(this.v1);
    this.v2.addUniqueNeighbor(this.v3);
    this.v3.addUniqueNeighbor(this.v1);
    this.v3.addUniqueNeighbor(this.v2);
    this.computeNormal();

  }

}

class Vertex {

  constructor(v) {

    this.position = v;
    this.id = - 1; // external use position in vertices list (for e.g. face generation)

    this.faces = []; // faces vertex is connected

    this.neighbors = []; // neighbouring vertices aka "adjacentVertices"
    // these will be computed in computeEdgeCostAtVertex()

    this.collapseCost = 0; // cost of collapsing this vertex, the less the better. aka objdist

    this.collapseNeighbor = null; // best candinate for collapsing

  }

  addUniqueNeighbor(vertex) {

    pushIfUnique(this.neighbors, vertex);

  }

  removeIfNonNeighbor(n) {

    const neighbors = this.neighbors;
    const faces = this.faces;
    const offset = neighbors.indexOf(n);
    if (offset === - 1) return;

    for (let i = 0; i < faces.length; i++) {

      if (faces[i].hasVertex(n)) return;

    }

    neighbors.splice(offset, 1);

  }

}
