import React, { useEffect, useState } from "react";
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

import ChangeAdminModal from "components/ChangeAdminModal";
import AddUserToCompanyModal from "components/AddUserToCompanyModal";
import DeleteUserFromCompanyModal from "components/DeleteUserFromCompanyModal";
import Loading from "components/ui-components/loading";
import Error from "components/ui-components/Error";
import NoItems from "components/ui-components/no-items";

import { getCompanyUsersAndProjects } from "api/common/getCompanyUsersAndProjects";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import { adminSetUsers } from "actions/admin";
import { Maybe } from "helpers/maybeFunctor";
import { useSearch } from "hooks/useSearch";
import formatTime from "helpers/formatTime";

import "./Admin.scss";
import GA4 from "../../components/GA4";
import { eventTrack } from "../../helpers/ga4Helper";
import { setSidebarStatus } from "actions/user";

const Admin = () => {
  const [modal, setModal] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [companyDataValue, setCompanyDataValue] = useState("");

  const search = useSearch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { companyId } = useParams();
  const dispatch = useDispatch();
  console.info(pathname);
  const usersRedux = useSelector((s) => s.admin.users);
  const companyRedux = useSelector((s) => s.admin.company);
  const projectsRedux = useSelector((s) => s.admin.projects);
  const userRedux = useSelector((s) => s.user.userInfo);
  const mainAdminRedux = useSelector((s) => s.user.userInfo.admin);

  const { loading, error, request, refresh } = useHttpWithCache({
    requestCallback: () => getCompanyUsersAndProjects(companyId),
    reduxSetter: (data) => {
      dispatch(adminSetUsers(data.users));
      dispatch({
        type: "ADMIN_SET_PROJECTS",
        payload: data.projects,
      });
      dispatch({
        type: "ADMIN_SET_COMPANY",
        payload: data.company,
      });
    },
    reduxReset: () => {
      dispatch(adminSetUsers(null));
      dispatch({
        type: "ADMIN_SET_PROJECTS",
        payload: null,
      });
      dispatch({
        type: "ADMIN_SET_COMPANY",
        payload: null,
      });
    },
    reduxCash: {
      users: usersRedux,
      company: companyRedux,
      projects: projectsRedux,
    },
    allowToCash: false,
  });

  useEffect(() => request(), []);

  const { email: emailUser } = userRedux;

  const companydata = Maybe.of(companyRedux);

  useEffect(() => {
    if (companydata.value) {
      setCompanyDataValue(companydata.value);
    }
  }, [companydata]);

  // const handleReturnPlan =(company) =>{
  //   if(companyDataValue?._id == company){
  //         let dataSet;
  //         if(companyDataValue?.trial == true){
  //           dataSet ='active trial'
  //         }
  //         else{
  //           dataSet ='expired trial'
  //         }
  //         return(dataSet)
  //     }
  //  }
  const maybeCompanyName = Maybe.of(companyRedux).map((company) => (
    <>
      <div className="inner-header-position left-0">
        <div className="custom-container d-flex align-items-center justify-content-between">
          {/* <p className="my-heading">All {company.companyName} users</p> */}
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
            <Link
              to={"/admin/projects-settings"}
              className="my-heading f-14 ml-3 mr-3 headerLink"
            >
              Admin
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>
            <Link
              to={"/main-admin"}
              className="my-heading f-14 ml-3 mr-3 headerLink"
            >
              All Companies
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>

            <p className="my-heading f-14 ml-3 mr-3">
              All {company.companyName} users
            </p>
          </div>

          <div className="btn-tool-group d-flex align-items-center">
            <input
              type="text"
              className="search-bar"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
              disabled={loading}
            />

            <div className="btn-tool-group">
              {mainAdminRedux ? (
                <button
                  disabled={loading}
                  onClick={() => {
                    setModal("ChangeAdmin");
                    eventTrack(
                      "Open-Change-Admin-Model-Action",
                      "Open-Change-Admin-Model",
                      "Admin-Modal",
                      "+1",
                      false,
                      "+1"
                    );
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  Change admin
                </button>
              ) : null}
              <button
                disabled={loading}
                onClick={() => {
                  setModal("AddUserToCompany");
                  eventTrack(
                    "Opem-AddUser-Model-Action",
                    "Opem-AddUser-Model",
                    "Admin-Action",
                    "+1",
                    false,
                    "+1"
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                Add User
              </button>
              <button onClick={refresh} disabled={loading}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ));

  const maybeUsers = Maybe.of(usersRedux)
    .map((users) => search(users, "email", searchValue))
    .map((users) => {
      return (
        <div className="admin-table-shadow mt-3">
          <MDBTable small className="admin-table">
            <MDBTableHead color="" textWhite>
              <tr>
                <th>Last logged in</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Email</th>
                <th>Role</th>
                <th>Country</th>
                <th>City</th>
                <th>plan</th>
                <th>Projects</th>
                <th>Remove</th>
                <th>Admin</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {users.map((userItem) => {
                console.log(userItem, "llllll");
                const {
                  name,
                  lastName,
                  company,
                  email,
                  role,
                  city,
                  country,
                  _id,
                  plan,
                  lastlogin,
                  companyAdmin,
                } = userItem;
                return (
                  <tr key={_id}>
                    <td>{formatTime(lastlogin)}</td>
                    <td>{name}</td>
                    <td>{lastName}</td>
                    <td>{email}</td>
                    <td>{role}</td>
                    <td>{country}</td>
                    <td>{city}</td>
                    <td>{plan}</td>
                    <td>
                      <button
                        className="see-btn table-btn"
                        onClick={() => history.push(`${pathname}/${_id}`)}
                      >
                        <i className="fa fa-eye"></i>
                        view
                      </button>
                    </td>
                    <td>
                      {email !== emailUser ? (
                        <button
                          className="see-btn table-btn"
                          onClick={() => {
                            setUserToDelete(userItem);
                            setModal("DeleteUser");
                            eventTrack(
                              "Delete-User-Admin-Action",
                              "Delete-User",
                              "Admin-Action",
                              "+1",
                              false,
                              "+1"
                            );
                          }}
                        >
                          <i className="fa fa-trash"></i>
                          Remove
                        </button>
                      ) : null}
                    </td>
                    <td>
                      {companyAdmin ? (
                        <button className="admin-btn table-btn">
                          <i className="fa fa-check"></i>
                          Admin
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    });

  const { value: usersToJSX } = maybeUsers;
  const { value: companyName } = maybeCompanyName;

  console.log(userToDelete);

  return (
    <div className="" style={{ display: "block" }}>
      {companyName}
      <MDBBox className="">
        {usersToJSX}
        {loading ? <Loading color="black" text="Loading..." /> : null}
        <GA4
          userId={userRedux.id}
          category={"Navigation"}
          action={"Admin-Page"}
          label={history.location.pathname}
          value={companyName}
          nonInteraction={false}
          transport={"xhr"}
        ></GA4>
        {error ? <Error /> : null}
        {usersRedux && usersRedux.length === 0 ? (
          <NoItems text="There is no projects" />
        ) : null}
      </MDBBox>

      <DeleteUserFromCompanyModal
        isOpen={modal === "DeleteUser"}
        itemToDelete={
          userToDelete
            ? {
                id: userToDelete?._id,
                email: userToDelete?.email,
              }
            : null
        }
        toggle={() => {
          setModal("");

          eventTrack(
            "Admin-Action",
            "DeleteUserFromCompany",
            history.location.pathname,
            companyName,
            false,
            "xhr"
          );
        }}
      />

      <AddUserToCompanyModal
        open={modal === "AddUserToCompany"}
        toggle={() => {
          setModal("");
          eventTrack(
            "Admin-Action",
            "AddUserToCompany",
            history.location.pathname,
            companyName,
            false,
            "xhr"
          );
        }}
      />

      <ChangeAdminModal
        isOpen={modal === "ChangeAdmin"}
        toggle={() => {
          setModal("");
          eventTrack(
            "Admin-Action",
            "ChangeAdmin",
            history.location.pathname,
            companyName,
            false,
            "xhr"
          );
        }}
      />
    </div>
  );
};

export default Admin;
