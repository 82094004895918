import React, { useEffect, useState, useRef } from "react";
import { useHttp } from "hooks/useHttp";
import DefaultImage from "assets/images/user.png";
import { getAllRooms, updateRoomStatus } from "api/rooms/getRoomDetail";
import addNotification from "helpers/notify";
import { getConversationByRoomId } from "api/conversation/getConversations";
import { useSelector } from "react-redux";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import DeleteModalIcon from "assets/images/icons/switch.png";

import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import Loading from "components/ui-components/loading";
import { urlWS } from "api";
import Loader from "components/Loader";

export const Inbox = () => {
  const [chatRooms, setChatRooms] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [message, setMessage] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedRoomDetails, setSelectedRoomDetails] = useState(null);
  const [senderImg, setSenderImg] = useState("");
  const [receiverImg, setReceiverImg] = useState("");
  const [isOpen, setOpen] = useState(false);

  const ws = useRef(null);
  const { loading: loadingChatRoom, request: getAllChatRooms } = useHttp({
    requestCallback: getAllRooms,
    onLoad: (res) => {
      if (res) {
        if (res?.chatRooms?.length) {
          handleRoomsImg(res?.chatRooms);
        }
      }
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const handleRoomsImg = async (rooms) => {
    for (let room of rooms) {
      if (room.participantId.profilePhoto) {
        const imgUrl = await extractImageFromZip(
          room?.participantId?.profilePhoto
        );
        if (imgUrl) {
          room.participantId.profilePhoto = imgUrl;
        }
      } else {
        room.participantId.profilePhoto = DefaultImage;
      }
    }
    setChatRooms(rooms);
  };

  const { loading: updateStatusLoading, request: handleUpdateRoomStatus } =
    useHttp({
      requestCallback: updateRoomStatus,
      onLoad: (res) => {
        if (res.message) {
          addNotification(res.message, "success");
          setOpen(false);
          const activeRoom = chatRooms.find(
            (room) => room._id === selectedRoom
          );
          activeRoom.isActive = !selectedRoomDetails.isActive;
          setSelectedRoomDetails({ ...activeRoom });
        }
      },
      onError: (error) => {
        addNotification(
          error?.err ?? "Something went wrong, please contact support",
          "danger"
        );
      },
    });

  const { loading: conversationLoader, request: getConversation } = useHttp({
    requestCallback: getConversationByRoomId,
    onLoad: (res) => {
      if (res.conversations) {
        setChatMessages(res?.conversations);
      }
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const sendMessage = () => {
    if (ws.current && message.trim()) {
      const chatMessage = {
        header: "chat",
        senderId: userInfo?.id,
        message: message,
      };
      ws.current.send(JSON.stringify(chatMessage));
      setMessage(""); // Clear input after sending
    }
  };

  useEffect(() => {
    if (selectedRoom) {
      ws.current = new WebSocket(`${urlWS}/${selectedRoom}`);

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setChatMessages((prev) => [...prev, data]);
        console.log("WebSocket Messaging");
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket Error: ", error);
      };

      ws.current.onclose = () => {
        console.log("WebSocket connection closed");
      };

      return () => {
        // Cleanup when component unmounts or allowChat is disabled
        ws.current.close();
      };
    }
  }, [selectedRoom]);

  useEffect(() => {
    getAllChatRooms();
  }, []);

  const handleSelectRoom = async (room) => {
    setSelectedRoom(room?._id);
    getConversation(room?._id);
    setSelectedRoomDetails(room);
    let imgURL = await extractImageFromZip(room?.participantId?.profilePhoto);
    if (imgURL) {
      setSenderImg(imgURL);
    }
    imgURL = await extractImageFromZip(userInfo?.profilePhoto);
    if (imgURL) {
      setReceiverImg(imgURL);
    }
  };

  const getFormattedDate = (createdAt) => {
    const date = new Date(createdAt);
    const options = {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  return (
    <div className="m-2">
      <div className="d-flex flex-row justify-content-between">
        {loadingChatRoom && <Loader />}
        <div
          style={{
            width: "30%",
            padding: "5px",
            border: "1px solid #ddd",
            borderRadius: "12px",
            height: "70vh",
            overflow: "auto",
          }}
        >
          {chatRooms.map((room, ind) => (
            <div
              key={room._id + ind}
              className={`chat-room-container d-flex align-items-center cursor-pointer header-profile-icon desktop-show${
                selectedRoom === room._id ? " selected" : ""
              }`}
              onClick={() => {
                handleSelectRoom(room);
              }}
            >
              <img
                src={room?.participantId?.profilePhoto}
                onError={(e) => (e.target.src = DefaultImage)}
              />
              <p className="mb-0 font-weight-600 f-16 ml-2 roboto">
                {room?.participantId?.name ?? "Guest"}
              </p>
            </div>
          ))}
        </div>
        <div style={{ marginRight: "10px" }} />
        <>
          <div
            class="card-body"
            data-mdb-perfect-scrollbar-init
            style={{
              position: "relative",
              width: "70%",
            }}
          >
            {selectedRoomDetails && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      src={senderImg}
                      onError={(e) => (e.target.src = DefaultImage)}
                    />
                    <div>
                      <p className="mb-0 font-weight-600 f-16 text-black ml-2 roboto">
                        {`${selectedRoomDetails?.participantId?.name} ${selectedRoomDetails?.participantId?.lastName}`}
                      </p>
                      <p className="mb-0 font-weight-600 f-16 ml-2 roboto">
                        {selectedRoomDetails?.participantId?.email}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <button
                      className={`${"number-count px-3 health-result-status border-none p-3"}
                    ${
                      selectedRoomDetails.isActive
                        ? "bg-light-success text-success"
                        : "bg-light-danger text-danger"
                    }
                    `}
                      onClick={() => setOpen(true)}
                    >
                      Chat: {selectedRoomDetails.isActive ? "Active" : "Closed"}
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="messages-list">
              {chatMessages.map((m) =>
                m.senderId?._id === userInfo?.id ? (
                  <>
                    <div class="d-flex justify-content-end">
                      <p className="small mb-1">You</p>
                    </div>
                    <div class="d-flex flex-row justify-content-end align-items-center pt-1">
                      <div className="d-flex">
                        <div
                          style={{ marginTop: "5px", marginRight: "5px" }}
                          className="d-flex flex-column"
                        >
                          <div>
                            <p
                              style={{
                                wordBreak: "break-word",
                              }}
                              className="user-msgs"
                            >
                              {m?.message}
                            </p>
                          </div>
                          <div className="d-flex justify-content-end">
                            <p className="text-muted">
                              {m?.createdAt
                                ? getFormattedDate(m?.createdAt)
                                : "Jan 1, 12:00 am"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-end">
                          <img
                            src={receiverImg}
                            onError={(e) => (e.target.src = DefaultImage)}
                            alt="avatar 1"
                            style={{
                              width: "45px",
                              borderRadius: "50%",
                              marginBottom: "45px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div class="d-flex justify-content-start">
                      <p className="small mb-1">
                        {m?.senderId?.name ?? "Guest"}
                      </p>
                    </div>
                    <div class="d-flex flex-row justify-content-between align-items-center">
                      <div className="d-flex">
                        <div className="d-flex flex-column justify-content-end">
                          <img
                            src={senderImg}
                            onError={(e) => (e.target.src = DefaultImage)}
                            alt="avatar 1"
                            style={{
                              width: "45px",
                              borderRadius: "50%",
                              marginBottom: "45px",
                            }}
                          />
                        </div>
                        <div
                          style={{ marginTop: "5px", marginLeft: "5px" }}
                          className="d-flex flex-column"
                        >
                          <div>
                            <p
                              style={{
                                wordBreak: "break-word",
                                marginTop: "5px",
                                marginLeft: "5px",
                              }}
                              className="sender-msgs"
                            >
                              {m?.message}
                            </p>
                          </div>
                          <div className="d-flex justify-content-start">
                            <p className="text-muted">
                              {m?.createdAt
                                ? getFormattedDate(m?.createdAt)
                                : "Jan 1, 12:00 am"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
            {selectedRoom && (
              <div class="card-footer text-muted d-flex justify-content-start align-items-center pt-3">
                <div class="input-group mb-0">
                  <input
                    type="text"
                    class="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type message"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />
                  <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    class="btn text-white bg-bid m-0"
                    type="button"
                    id="button-addon2"
                    onClick={sendMessage}
                  >
                    Send
                  </button>
                </div>
              </div>
            )}
            {selectedRoomDetails && (
              <MDBModal
                isOpen={isOpen}
                toggle={() => setOpen(false)}
                centered
                className="delete-modal"
              >
                <MDBModalHeader toggle={() => setOpen(false)}>
                  <img src={DeleteModalIcon} />
                </MDBModalHeader>
                <MDBModalBody>
                  <p className="delete-modal-label-head">
                    Change Status of {selectedRoomDetails?.participantId?.name}{" "}
                    {selectedRoomDetails?.participantId?.lastName} chat?
                  </p>
                  <p className="delete-modal-label">
                    Are you sure you want to change the chat status to "
                    {selectedRoomDetails.isActive ? "Closed" : "Active"}"?
                  </p>
                </MDBModalBody>
                {updateStatusLoading ? (
                  <Loading color="black" text="Updating Status..." />
                ) : null}
                <MDBModalFooter className="d-flex justify-content-center border-none">
                  <MDBBtnGroup size="md" className="w-100">
                    <MDBBtn
                      size="sm"
                      onClick={() => {
                        setOpen(false);
                      }}
                      color=""
                      disabled={updateStatusLoading}
                      className="delete-modal-btn cancel-confirm"
                    >
                      Cancel
                    </MDBBtn>
                    <MDBBtn
                      size="sm"
                      color=""
                      onClick={() => {
                        handleUpdateRoomStatus({
                          roomId: selectedRoom,
                          isActive: !selectedRoomDetails?.isActive,
                        });
                      }}
                      disabled={updateStatusLoading}
                      className="delete-modal-btn delete-confirm"
                    >
                      Update
                    </MDBBtn>
                  </MDBBtnGroup>
                </MDBModalFooter>
              </MDBModal>
            )}
          </div>
        </>
      </div>
    </div>
  );
};
