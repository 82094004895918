import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../sidebar";
import { useDispatch, useSelector } from "react-redux";
import addNotification from "../../../helpers/notify";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import Loading from "../../../components/ui-components/loading";
import { getDiscipline } from "api/discipline/getDiscipline";
import TopNavbar from "../navbar";
import BulkImportIcon from "assets/images/icons/bulk.png";
import DeleteIcon from "assets/images/icons/delete-bin-line.png";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";
import Loader from "components/Loader";
import { Modal } from "antd";
import { RequestCard } from "../requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { getFeatureRequest } from "api/featureRequests/getFeatureRequest";
import { useHttp } from "hooks/useHttp";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { useForm } from "react-hook-form";
import { editDiscipline } from "api/discipline/editDiscipline";
import { createDiscipline } from "api/discipline/createDiscipline";
import {
  deleteBulkDiscipline,
  deleteDiscipline,
} from "api/discipline/deleteDiscipline";
import { uploadDisciplineCsv } from "api/discipline/uploadDisciplineCsv";
import { eventTrack } from "helpers/ga4Helper";

const DisciplineSettings = () => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    trigger,
    reset,
  } = useForm({ mode: "onChange" });
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    .filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const [discipline, setDiscipline] = useState([]);
  const [allData, setAllData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [disciplinetodelete, setDelete] = useState("");
  const [disciplinekeytodelete, setdisciplinetoDelete] = useState("");
  const modalRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const [disciplinestodelete, setDisciplinesDelete] = useState([]);
  const [isOpen2, setOpen2] = useState(false);
  const [id, setId] = useState("");
  const [body, setBody] = useState({
    key: "",
    value: "",
  });
  const [requestModal, setRequestModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [featureRequests, setFeatureRequests] = useState([]);

  const { request: getFeatureRequestAPI } = useHttp({
    requestCallback: getFeatureRequest,
    onLoad: (res) => {
      setFeatureRequests(res?.map((feature) => feature?.requestedFeature));
    },
    onError: (res) => {},
  });

  useEffect(() => {
    (async () => {
      getDisciplineAPI();
      getFeatureRequestAPI(userInfoRedux?.id);
    })();
  }, []);

  const { loading: disciplineLoading, request: getDisciplineAPI } = useHttp({
    requestCallback: (data) => getDiscipline(userInfoRedux.company),
    onLoad: (res) => {
      setDiscipline(res);
      setAllData(res);
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setBody((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = (discipline) => {
    setBody(discipline);
    setId(discipline._id);
  };

  const handleSave = async () => {
    if (id) {
      const res = await editDiscipline(body);
      if (!res.error) {
        setDiscipline((prevMile) => {
          const updatedMiles = prevMile.map((mile) =>
            mile._id == id ? res : mile
          );
          return updatedMiles;
        });
        setAllData((prevMile) => {
          const updatedMiles = prevMile.map((mile) =>
            mile._id == id ? res : mile
          );
          return updatedMiles;
        });
        addNotification("Discipline Updated Successfully! ", "success");
        modalRef.current.click();
      } else {
        addNotification(res?.message, "danger");
        modalRef.current.click();
      }
    } else {
      body.company = userInfoRedux.company;
      createDisciplineAPI(body);
    }
  };

  const { loading: createLoading, request: createDisciplineAPI } = useHttp({
    requestCallback: (data) => createDiscipline(data),
    onLoad: (res) => {
      setDiscipline((prev) => [...prev, res]);
      setAllData((prev) => [...prev, res]);
      addNotification("Discipline Created Successfully! ", "success");
      modalRef.current.click();
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
      modalRef.current.click();
    },
  });

  const { loading: deleteLoading, request: deleteDisciplineAPI } = useHttp({
    requestCallback: (data) => deleteDiscipline(data),
    onLoad: (res) => {
      setDiscipline((prev) =>
        prev.filter((pre) => pre._id != disciplinetodelete)
      );
      setAllData((prev) => prev.filter((pre) => pre._id != disciplinetodelete));
      setOpen(false);
      addNotification("Discipline Deleted Successfully! ", "success");
    },
    onError: (res) => {
      addNotification(res?.err, "danger");
    },
  });

  const handleDelete = async () => {
    deleteDisciplineAPI({ _id: disciplinetodelete });
  };

  const handleClear = () => {
    setBody({
      key: "",
      value: "",
    });
    setId("");
    reset();
  };

  const handleBulkClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  const { loading: uploadCsvLoading, request: uploadCsvAPI } = useHttp({
    requestCallback: (data) => uploadDisciplineCsv(data),
    onLoad: (res) => {
      setSelectedFile(null);
      setDiscipline((prev) => [...prev, ...res]);
      setAllData((prev) => [...prev, ...res]);
      addNotification("Bulk Disciplines Added Successfully! ", "success");
      getDisciplineAPI();
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const handleUploadCsv = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("company", userInfoRedux.company);

    return uploadCsvAPI(formData);
  };

  const handleCheckBox = (e, id) => {
    const isChecked = e.target.checked;

    const index = disciplinestodelete.indexOf(id);

    if (isChecked && index === -1) {
      setDisciplinesDelete((prev) => [...prev, id]);
    }

    if (!isChecked && index !== -1) {
      setDisciplinesDelete((prev) => prev.filter((userId) => userId !== id));
    }
  };

  const { loading: bulkDeleteLoading, request: bulkDeleteDisciplineAPI } =
    useHttp({
      requestCallback: (data) => deleteBulkDiscipline(data),
      onLoad: (res) => {
        setDiscipline((prev) =>
          prev.filter((user) => !disciplinestodelete?.includes(user._id))
        );
        setAllData((prev) =>
          prev.filter((user) => !disciplinestodelete?.includes(user._id))
        );
        setDisciplinesDelete([]);
        setOpen2(false);
        addNotification("Deleted Successfully! ", "success");
      },
      onError: (res) => {
        setOpen2(false);
        addNotification("Error not delete", "danger");
      },
    });

  const handleDeleteBulk = async () => {
    bulkDeleteDisciplineAPI({ ids: disciplinestodelete });
  };

  const handleSearch = (keyword) => {
    const filteredResult = discipline.filter((discipline) =>
      discipline?.key.toLowerCase().includes(keyword.toLowerCase())
    );

    if (keyword.length > 0) {
      setDiscipline(filteredResult);
    } else {
      setDiscipline(allData);
    }
  };

  const requestAccess = async () => {
    setLoading(true);
    const res = await createFeatureRequest(userInfoRedux.id, {
      companyId: userInfoRedux.company,
      permission: requestType,
      userId: userInfoRedux.id,
    });
    if (res.status === 200) {
      setLoadingRequest(false);
      setFeatureRequests(res.data.map((feature) => feature.requestedFeature));
    }
  };

  return (
    <>
      {disciplineLoading && <Loader />}
      <div className="personal-area">
        <TopNavbar handleSearch={handleSearch} />

        <div className="content-bg p-2">
          <div className="container d-flex mt-1">
            <Sidebar />

            <div className="profile-content px-0 pt-0">
              <div className="w-100 px-0">
                <div className="d-flex justify-content-between px-3 py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <div className="heading-svg-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="800px"
                        height="800px"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g id="Iconly/Curved/Category">
                          <g id="Category">
                            <path
                              id="Stroke 1"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M21.0003 6.6738C21.0003 8.7024 19.3551 10.3476 17.3265 10.3476C15.2979 10.3476 13.6536 8.7024 13.6536 6.6738C13.6536 4.6452 15.2979 3 17.3265 3C19.3551 3 21.0003 4.6452 21.0003 6.6738Z"
                              stroke="#130F26"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              id="Stroke 3"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.3467 6.6738C10.3467 8.7024 8.7024 10.3476 6.6729 10.3476C4.6452 10.3476 3 8.7024 3 6.6738C3 4.6452 4.6452 3 6.6729 3C8.7024 3 10.3467 4.6452 10.3467 6.6738Z"
                              stroke="#130F26"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              id="Stroke 5"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M21.0003 17.2619C21.0003 19.2905 19.3551 20.9348 17.3265 20.9348C15.2979 20.9348 13.6536 19.2905 13.6536 17.2619C13.6536 15.2333 15.2979 13.5881 17.3265 13.5881C19.3551 13.5881 21.0003 15.2333 21.0003 17.2619Z"
                              stroke="#130F26"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              id="Stroke 7"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.3467 17.2619C10.3467 19.2905 8.7024 20.9348 6.6729 20.9348C4.6452 20.9348 3 19.2905 3 17.2619C3 15.2333 4.6452 13.5881 6.6729 13.5881C8.7024 13.5881 10.3467 15.2333 10.3467 17.2619Z"
                              stroke="#130F26"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="font-weight-bold ml-3 text-black">
                      Discipline
                    </span>
                    {disciplinestodelete.length > 0 && (
                      <div
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 ml-4 mb-0 cursor-pointer"
                        onClick={() => {
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.DISCIPLINE_DELETE
                          )
                            ? setOpen2(true)
                            : setRequestModal(true);
                          setRequestType(ALL_PERMISSIONS.DISCIPLINE_DELETE);
                        }}
                      >
                        <img src={DeleteIcon} className="w-15" />
                        <span className="ml-1 f-14">
                          Delete {disciplinestodelete.length} Discipline
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex">
                    <div
                      onClick={() => {
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.DISCIPLINE_ADD
                        )
                          ? handleBulkClick()
                          : setRequestModal(true);
                        setRequestType(ALL_PERMISSIONS.DISCIPLINE_ADD);
                      }}
                      className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                    >
                      <img src={BulkImportIcon} className="w-15" />
                      <span className="ml-1 f-14">Bulk Import Discipline</span>
                    </div>
                    {selectedFile ? (
                      <div
                        onClick={handleUploadCsv}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        {uploadCsvLoading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="text-bid">Upload</div>
                        )}
                      </div>
                    ) : FinalPermissions?.includes(
                        ALL_PERMISSIONS.DISCIPLINE_ADD
                      ) ? (
                      <div
                        data-toggle="modal"
                        data-target="#exampleModal"
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span className="ml-2 f-14">Add Discipline</span>
                      </div>
                    ) : (
                      <div
                        data-toggle="modal"
                        data-target="#exampleModdal"
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span
                          className="ml-2 f-14"
                          onClick={() => {
                            setRequestType(ALL_PERMISSIONS.DISCIPLINE_ADD);
                            setRequestModal(true);
                          }}
                        >
                          Add Discipline
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    paddingRight: "250px",
                  }}
                  className="border-bottom pl-2 pt-2 pb-2 d-flex justify-content-between bg-table-header"
                >
                  <div className="f-12 font-weight-normal">Name</div>
                  <div className="f-12 font-weight-normal">Value</div>
                </div>

                {discipline?.map((discipline, i) => (
                  <div
                    key={i}
                    className="d-flex justify-content-between p-2 border-bottom"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        checked={disciplinestodelete?.includes(discipline._id)}
                        onChange={(e) => handleCheckBox(e, discipline._id)}
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        className="squared-checkbox"
                      />
                      <div className="f-16 text-black font-weight-normal mb-0">
                        {" "}
                        {discipline?.key}{" "}
                      </div>
                    </div>
                    <div>
                      <span
                        className="px-2 py-1 f-12 font-weight-bold rounded-5"
                        style={{
                          marginRight: "90px",
                          textAlign: "center",
                          backgroundColor: "rgba(79, 201, 42, 0.1)",
                          color: "rgba(59, 135, 62, 1)",
                        }}
                      >
                        {discipline?.value}
                      </span>

                      {FinalPermissions?.includes(
                        ALL_PERMISSIONS.DISCIPLINE_EDIT
                      ) ? (
                        <span
                          data-toggle="modal"
                          data-target="#exampleModal"
                          onClick={() => handleEdit(discipline)}
                          className="mr-5 cursor-pointer table-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="22"
                            viewBox="0 0 23 22"
                            fill="none"
                          >
                            <path
                              d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                              fill="#3A5765"
                            />
                            <path
                              d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                              fill="black"
                              fillOpacity="0.2"
                            />
                          </svg>
                        </span>
                      ) : (
                        <span
                          data-toggle="modal"
                          onClick={() => {
                            setRequestType(ALL_PERMISSIONS.DISCIPLINE_EDIT);
                            setRequestModal(true);
                          }}
                          className="mr-5 cursor-pointer table-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="22"
                            viewBox="0 0 23 22"
                            fill="none"
                          >
                            <path
                              d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                              fill="#3A5765"
                            />
                            <path
                              d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                              fill="black"
                              fillOpacity="0.2"
                            />
                          </svg>
                        </span>
                      )}

                      {FinalPermissions?.includes(
                        ALL_PERMISSIONS.DISCIPLINE_DELETE
                      ) ? (
                        <span
                          onClick={() => {
                            setDelete(discipline._id);
                            setdisciplinetoDelete(discipline?.key);
                            setOpen(true);
                          }}
                          className="mr-5 cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="22"
                            viewBox="0 0 26 22"
                            fill="none"
                          >
                            <path
                              d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                              fill="#3A5765"
                            />
                            <path
                              d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                              fill="black"
                              fillOpacity="0.2"
                            />
                          </svg>
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setRequestType(ALL_PERMISSIONS.DISCIPLINE_DELETE);
                            setRequestModal(true);
                          }}
                          className="mr-5 cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="22"
                            viewBox="0 0 26 22"
                            fill="none"
                          >
                            <path
                              d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                              fill="#3A5765"
                            />
                            <path
                              d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                              fill="black"
                              fillOpacity="0.2"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                ))}

                {discipline?.length === 0 && (
                  <p className="m-3">No record found</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* add Discipline */}
        <div
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="delete-modal modal fade"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content w-100">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <p className="delete-modal-label-head">
                    {" "}
                    {body.value ? "Edit Discipline" : "Add Discipline"}
                  </p>
                </h5>
                <button
                  onClick={handleClear}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-0">
                <form>
                  <div className="form-group mt-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="f-14 inter text-black font-weight-500"
                    >
                      Discipline name
                    </label>
                    <input
                      value={body.key}
                      name="key"
                      type="text"
                      className="form-control"
                      placeholder="Discipline name"
                      {...register("key", {
                        required: "Discipline name is required",
                        onChange: (e) => handleOnchange(e),
                      })}
                    />
                    {errors.key && (
                      <p role="alert" className="validation-error-msg">
                        {errors.key.message}
                      </p>
                    )}

                    {body.key.toLowerCase() === "untracked projects" && (
                      <p role="alert" className="validation-error-msg">
                        Default discipline name cannot be used
                      </p>
                    )}
                  </div>
                  <div className="form-group mt-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="f-14 inter text-black font-weight-500"
                    >
                      Discipline value
                    </label>
                    <input
                      value={body.value}
                      // onChange={handleOnchange}
                      name="value"
                      type="text"
                      className="form-control"
                      placeholder="Enter Value"
                      {...register("value", {
                        required: "Discipline value is required",
                        onChange: (e) => handleOnchange(e),
                      })}
                    />
                    {errors.value && (
                      <p role="alert" className="validation-error-msg">
                        {errors.value.message}
                      </p>
                    )}
                  </div>
                </form>
                {createLoading ? (
                  <Loading color="black" text="Creating..." />
                ) : null}
              </div>

              <div className="modal-footer border-none">
                <div className="w-100">
                  <div
                    onClick={(e) => {
                      body.key?.length === 0
                        ? trigger(["key"])
                        : body.value?.length === 0
                        ? trigger(["value"])
                        : body.key.toLowerCase() === "untracked projects"
                        ? e.stopPropagation()
                        : handleSave();
                      eventTrack(
                        "Create-Discipline-Action",
                        "Create-Discipline",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    // onClick={handleSave}
                    className="border-bid bg-bid rounded-lg py-2 w-100 text-white text-center inter f-16 font-weight-500 cursor-pointer"
                  >
                    {id ? "Update" : "Add"}
                  </div>
                  <div
                    onClick={handleClear}
                    ref={modalRef}
                    className="bg-white border-bid rounded-lg py-2 w-100 text-bid text-center inter f-16 font-weight-500 mt-3 cursor-pointer"
                    data-dismiss="modal"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* individual delete */}
        <MDBModal
          isOpen={isOpen}
          toggle={() => setOpen(false)}
          centered
          className="delete-modal"
        >
          <MDBModalHeader toggle={() => setOpen2(false)}>
            <img src={DeleteModalIcon} />
          </MDBModalHeader>
          <MDBModalBody>
            <p className="delete-modal-label-head">
              Delete Discipline {disciplinekeytodelete} ?
            </p>
            <p className="delete-modal-label">
              Are you sure you want to delete this Discipline? This action
              cannot be undone.
            </p>
          </MDBModalBody>
          {deleteLoading ? <Loading color="black" text="Deleting..." /> : null}
          <MDBModalFooter className="d-flex justify-content-center border-none">
            <MDBBtnGroup size="md" className="w-100">
              <MDBBtn
                size="sm"
                onClick={() => {
                  setOpen(false);
                  setDelete("");
                }}
                color=""
                disabled={deleteLoading}
                className="delete-modal-btn cancel-confirm"
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                size="sm"
                color=""
                onClick={() => {
                  handleDelete();
                  eventTrack(
                    "Delete-Discipline-Action",
                    "Delete-Discipline",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }}
                disabled={deleteLoading}
                className="delete-modal-btn delete-confirm"
              >
                Delete
              </MDBBtn>
            </MDBBtnGroup>
          </MDBModalFooter>
        </MDBModal>

        {/* bulk delete */}
        <MDBModal
          isOpen={isOpen2}
          toggle={() => setOpen2(false)}
          centered
          className="delete-modal"
        >
          <MDBModalHeader toggle={() => setOpen2(false)}>
            <img src={DeleteModalIcon} />
          </MDBModalHeader>
          <MDBModalBody>
            <p className="delete-modal-label-head">Delete Disciplines</p>
            <p className="delete-modal-label">
              Are you sure you want to delete this Disciplines? This action
              cannot be undone.
            </p>
          </MDBModalBody>
          {bulkDeleteLoading ? (
            <Loading color="black" text="Deleting..." />
          ) : null}
          <MDBModalFooter className="d-flex justify-content-center border-none">
            <MDBBtnGroup size="md" className="w-100">
              <MDBBtn
                size="sm"
                onClick={() => {
                  setOpen2(false);
                  setDelete("");
                }}
                color=""
                disabled={bulkDeleteLoading}
                className="delete-modal-btn cancel-confirm"
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                size="sm"
                color=""
                onClick={handleDeleteBulk}
                disabled={bulkDeleteLoading}
                className="delete-modal-btn delete-confirm"
              >
                Delete
              </MDBBtn>
            </MDBBtnGroup>
          </MDBModalFooter>
        </MDBModal>

        <input
          style={{ display: "none" }}
          type="file"
          accept=".csv"
          name="file_image"
          className="file-input"
          id="input_image"
          required
          // disabled={loading}
          ref={hiddenFileInput}
          onChange={handleFile}
        />

        <Modal
          title={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          open={requestModal}
          footer={null}
          onCancel={() => setRequestModal(false)}
        >
          <RequestCard
            status={featureRequests?.includes(requestType)}
            loading={loadingRequest}
            icon={
              featureRequests?.includes(requestType) ? (
                <ClockCircleOutlined style={{ color: "black" }} />
              ) : (
                <InfoCircleOutlined style={{ color: "black" }} />
              )
            }
            title={
              featureRequests?.includes(requestType)
                ? `Request Already Sent`
                : `Oops, looks like you need to ${
                    userInfoRedux?.companyAdmin
                      ? `Acquire License`
                      : `Request Access`
                  }`
            }
            description={
              featureRequests?.includes(requestType)
                ? `Please reach out to your project Admin`
                : `Elevate your experience with exclusive futures, early releases, and premium features.`
            }
            buttonText={
              userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
            }
            buttonHandler={() => requestAccess()}
          />
        </Modal>
      </div>
    </>
  );
};

export default DisciplineSettings;
