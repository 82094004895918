import React, { useState } from "react";
import { useHttp } from "hooks/useHttp";
import { resetUserPassword } from "api/users/updatePassword";
import Loader from "components/Loader";
import addNotification from "helpers/notify";
import { eventTrack } from "helpers/ga4Helper";

export default function Password() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const { loading: isLoading, request: updatePasswordAPI } = useHttp({
    requestCallback: (data) => resetUserPassword(data),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("API password error", res);
    },
  });

  const clearPasswords = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const handleUpdatePassword = async () => {
    const passwordUpdate = await updatePasswordAPI({
      password: currentPassword,
      newPassword: newPassword,
    });
    if (passwordUpdate.success) {
      addNotification(passwordUpdate.message, "success");
      clearPasswords();
    } else {
      console.log("Handle password error");
      addNotification(passwordUpdate.message, "danger");
    }

    eventTrack(
      "Update-Password-Action",
      "Update-Password",
      "Update-Password",
      "+1",
      false,
      "+1"
    );
  };

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  return (
    <div class="w-100">
      <div className="w-75 py-4 px-2">
        <p className="font-weight-bold text-black mb-1 h5">Password</p>
        <p>Please enter your current password to change your password.</p>
        <hr />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="my-4">
              <label class="labels font-weight-bold text-bid">
                Current password
              </label>
              <div className="position-relative">
                <input
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  value={currentPassword}
                  type={showCurrentPassword ? "text" : "password"}
                  class="form-control"
                  placeholder="Current Password"
                />
                <i
                  className={`password-eye fa ${
                    showCurrentPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={() =>
                    setShowCurrentPassword(
                      (prevShowPassword) => !prevShowPassword
                    )
                  }
                ></i>
              </div>
            </div>

            <div className="my-4">
              <label class="labels font-weight-bold text-bid">
                New password
              </label>
              <div className="position-relative">
                <input
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                  type={showNewPassword ? "text" : "password"}
                  class="form-control"
                  placeholder="New Password"
                />
                <i
                  className={`password-eye fa ${
                    showNewPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={() =>
                    setShowNewPassword((prevShowPassword) => !prevShowPassword)
                  }
                ></i>
              </div>
            </div>

            <div className="my-4">
              <label class="labels font-weight-bold text-bid">
                Confirm new password
              </label>
              <input
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                value={confirmNewPassword}
                type="password"
                class="form-control"
                placeholder="Confirm New Password"
              />
              <label className="validation-error-msg">
                {newPassword.length > 0 &&
                confirmNewPassword.length > 0 &&
                newPassword !== confirmNewPassword
                  ? "Password not matched"
                  : ""}
              </label>
            </div>

            <hr />

            <div className="d-flex justify-content-end w-100 mt-4">
              <button
                className="header-btn text-bid bg-white border-bid px-3"
                onClick={clearPasswords}
              >
                Cancel
              </button>
              <button
                disabled={
                  currentPassword.length === 0 ||
                  newPassword.length === 0 ||
                  confirmNewPassword.length === 0 ||
                  newPassword !== confirmNewPassword
                }
                className="header-btn text-white bg-bid border-bid px-4 mr-0"
                onClick={handleUpdatePassword}
              >
                Update password
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
