import React, { useEffect, useState, useRef } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import UploadIcon from "assets/images/icons/Featured icon.png";
import IFCIMG from "assets/images/newLanding/image 245.png";
import RevitImg from "assets/images/newLanding/revit 1(1).png";
import StepDoneImg from "assets/images/icons/_Step icon base.png";
import StepInProgressImg from "assets/images/icons/Content.png";
import StepIncompleteImg from "assets/images/icons/Content1.png";
import SuccessIMG from "assets/images/icons/success.png";
import { Maybe } from "helpers/maybeFunctor";
import { createProjectExportSettings } from "api/projects/exportSettings";

import Loading from "../../components/ui-components/loading";

import {
  createProjectNew as createProject,
  createIFCProjectNew as createIFCProject,
} from "api/projects/createProject";
import { getParameters } from "api/parameters/getParameters";
import { getMileStones } from "api/milestones/getMilestones";
import addNotification from "helpers/notify";
import { choseToggle } from "helpers/toggleIfLoading";
import { useHttp } from "hooks/useHttp";
import { addNewProject } from "../../actions/user";

import Select from "react-select";
import "../../components/CreateProjectModal/CreateModal.scss";
import { uploadFileToServer } from "components/uploadFile/uploadFile";
import {
  getProjectExportSettings,
} from "api/projects/exportSettings";

import { useForm } from "react-hook-form";
import { getCompanySettings } from "api/companySettings/getCompanySettings";
import { verifyProjectNumber } from "api/projects/verifyProjectNumber";
import { debounce } from "lodash";

const CreateModal = (props) => {
  var milestoneOptionsVar = [];
  var allParametersVar = [];
  const {
    register,
    formState: { errors },
    trigger,
    reset,
    setError,
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const projectImgInputRef = useRef(null);
  const ifcFileInputRef = useRef(null);

  const usersRedux = useSelector((s) => s.user.users);
  const emailRedux = useSelector((s) => s.user.userInfo.email);
  const projectsRedux = useSelector((s) => s.user.projects);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [projectImage, setProjectImage] = useState(null);
  const [projectNumber, setProjectNumber] = useState("");

  const [ifcFile, setIfcFile] = useState(null);
  const [ifcUrl, setIfcUrl] = useState("");

  const [teamMembers, setTeamMembers] = useState([]);
  const [isParent, setIsParent] = useState(false);
  const [selectAllAvaialable, setSelectAllAvaialable] = useState(false);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [projectNumberLoading, setProjectNumberLoading] = useState(false);

  const FilterteamMembers = Maybe.of(usersRedux).map((users) => {
    return users.filter((user) => user.email !== emailRedux);
  });

  const teamMembersOptions = FilterteamMembers?.value?.map((i) => ({
    label: i.name,
    value: i._id,
  }));

  const parentProjectOptions = projectsRedux?.map((i) => ({
    label: i.title,
    value: i._id,
  }));
  const [parentProject, setParentProject] = useState("");

  const PROJ_TYPES = {
    REVIT: "REVIT",
    IFC: "IFC",
  };

  const STEP = {
    PROJECT_SELECTION: "PROJECT_SELECTION",
    STEP_01: "STEP_01",
    STEP_02: "STEP_02",
    STEP_03: "STEP_03",
    STEP_04: "STEP_04",
  };

  const PARAMETER_TABS = {
    BUILT_IN: "builtIn",
    SHARED: "shared",
    PROJECT: "project",
    FAMILY: "family",
    GLOBAL: "global",
  };

  const PARAMETER_TYPE_OPTIONS = [
    {
      label: "Built In Parameters",
      value: "builtIn",
    },
    {
      label: "Shared Parameters",
      value: "shared",
    },
    {
      label: "Project Parameters",
      value: "project",
    },
    {
      label: "Family Parameters",
      value: "family",
    },
    {
      label: "Global Parameters",
      value: "global",
    },
  ];

  const [selectedProjectType, setSelectedProjectType] = useState(
    PROJ_TYPES.REVIT
  );
  const [selectedSteps, setSelectedSteps] = useState(STEP.PROJECT_SELECTION);
  const [activeParameterTab, setActiveParameterTab] = useState(
    PARAMETER_TABS.BUILT_IN
  );

  const [allParameters, setAllParameters] = useState([]);
  const [checkedAvailParameters, setCheckedAvailParameters] = useState([]);
  const [checkedSelectedParameters, setCheckedSelectedParameters] = useState(
    []
  );
  const [selectedparameters, setSelectedParameters] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [milestonesOptions, setMilestonesOptions] = useState([]);

  const [showExtraMatchingParameter, setShowExtraMatchingParameter] =
    useState(false);
  const [showFilterBidlightParameter, setShowFilterBidlightParameter] =
    useState(false);
  const [showFilterCustomParameter, setShowFilterCustomParameter] =
    useState(false);

  const [step_03_matchingParameterType, setStep_03_matchingParameterType] =
    useState("");
  const [
    step_03_matchingParameterNameList,
    setStep_03_matchingParameterNameList,
  ] = useState([]);
  const [step_03_matchingParameterId, setStep_03_matchingParameterId] =
    useState([]);

  const [step_03_filterParamsProjectObj, setStep_03_filterParamsProjectObj] =
    useState([]);
  const [step_03_filterParameterType, setStep_03_filterParameterType] =
    useState("");
  const [step_03_filterParameterNameList, setStep_03_filterParameterNameList] =
    useState([]);
  const [step_03_filterParameterSelected, setStep_03_filterParameterSelected] =
    useState([]);

  const [includeMiscellaneous, setIncludeMiscellaneous] = useState(false);
  const [include2DElements, setInclude2DElements] = useState(false);

  const createObjectForm = {};

  useEffect(() => {
    (async () => {
      const res = await getParametersAPI();
      (async () => {
        const res1 = await getMileStones(userInfoRedux.company);
        milestoneOptionsVar = res1?.map((i) => ({
          label: i.key,
          value: i._id,
        }));
        setMilestonesOptions(milestoneOptionsVar);
        getCompanySettingsAPI(userInfoRedux?.company);
      })();
    })();
  }, []);

  // on Click close reset form
  const toggle = () => {
    props.toggle();
    setTitle("");
    setDescription("");
    setProjectImage(null);
    setIfcFile(null);
    setTeamMembers([]);
    setIsParent(false);
    setParentProject("");
    setSelectedProjectType(PROJ_TYPES.REVIT);
    setSelectedSteps(STEP.PROJECT_SELECTION);
    reset();
  };

  const { loading, request: createProjectAPI } = useHttp({
    requestCallback: (data) => createProject(data),
    onLoad: (res) => {
      dispatch(addNewProject(res));
      addNotification("Project was successfully created!", "success");
      setSelectedSteps(STEP.STEP_04);
    },
    onError: (res) => {
      addNotification(
        "Something went wrong while creating your project, please contact support.",
        "danger"
      );
    },
  });

  const {
    loading: exportsettingsloading,
    request: createProjectExportSettingsAPI,
  } = useHttp({
    requestCallback: (data) => createProjectExportSettings(data),
    onLoad: (res) => {
      createObjectForm.projectExportSettings = res?._id;
      createProjectAPI(createObjectForm);
    },
    onError: (res) => {
      addNotification(
        "Something went wrong while creating your project, please contact support.",
        "danger"
      );
    },
  });

  const { loading: parametersLoading, request: getParametersAPI } = useHttp({
    requestCallback: () => getParameters({ companyId: userInfoRedux.company }),
    onLoad: (res) => {
      setAllParameters(res?.parameters);
      allParametersVar = res?.parameters;
    },
    onError: (res) => {},
  });

  const { request: getCompanySettingsAPI } = useHttp({
    requestCallback: (data) => getCompanySettings(data),
    onLoad: (res) => {
      getExportsSettingsAPI(res?.recentProjectExportSettings);
    },
    onError: (res) => {},
  });

  const { loading: exportSettingsLoading, request: getExportsSettingsAPI } =
    useHttp({
      requestCallback: (data) => getProjectExportSettings(data),
      onLoad: (res) => {
        console.log(res);
        setMilestones(
          milestoneOptionsVar?.find((i) => i.value === res?.milestone)
        );
        setIncludeMiscellaneous(res?.includeMiscellaneous);
        setInclude2DElements(res?.include2DElements);

        setShowExtraMatchingParameter(
          res?.matchingParameter?.type === "Default" || res?.matchingParameter?.type === null ? false : true
        );

        // res?.matchingParameter?.type === "custom parameter" &&

        const filteredObj = allParametersVar?.filter(
          (i) =>
            i?.parameterType ===
            PARAMETER_TYPE_OPTIONS?.find(
              (i) => i.value === res?.matchingParameter?.paramType
            ).value
        );

        const filteredObjArr = filteredObj?.map((i) => ({
          label: i.name,
          value: i._id,
        }));

        setStep_03_matchingParameterNameList(filteredObjArr);
        setStep_03_matchingParameterType(
          PARAMETER_TYPE_OPTIONS?.find(
            (i) => i.value === res?.matchingParameter?.paramType
          )
        );

        const selectedParams = res?.matchingParameter?.parameters?.flatMap(
          (item) => {
            return filteredObjArr?.filter((i) => {
              return i.value === item;
            });
          }
        );

        setStep_03_matchingParameterId(selectedParams);

        {
          res?.filterParameter?.type === "bidlight parameter" &&
            setShowFilterBidlightParameter(true);
          const selectedProj = res?.filterParameter?.value?.flatMap((item) => {
            return parentProjectOptions?.filter((i) => {
              return i.value === item;
            });
          });
          setStep_03_filterParamsProjectObj(selectedProj);
        }

        {
          res?.filterParameter?.type === "custom parameter" &&
            setShowFilterCustomParameter(true);
        }

        const filteredObj1 = allParametersVar?.filter(
          (i) =>
            i?.parameterType ===
            PARAMETER_TYPE_OPTIONS?.find(
              (i) => i.value === res?.filterParameter?.paramType
            ).value
        );

        const filteredObjArr1 = filteredObj1?.map((i) => ({
          label: i.name,
          value: i._id,
        }));

        setStep_03_filterParameterNameList(filteredObjArr1);
        setStep_03_filterParameterType(
          PARAMETER_TYPE_OPTIONS?.find(
            (i) => i.value === res?.filterParameter?.paramType
          )
        );

        const selectedParams1 = res?.filterParameter?.value?.flatMap((item) => {
          return filteredObjArr1?.filter((i) => {
            return i.value === item;
          });
        });
        setStep_03_filterParameterSelected(selectedParams1);
      },
      onError: (res) => {},
    });

  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const createObject = async (e) => {
    e.preventDefault();
    createObjectForm.companyId = userInfoRedux?.company;
    createObjectForm.title = title;
    createObjectForm.projectNumber = projectNumber;

    createObjectForm.description = description;

    if (projectImage) {
      setImageUploadLoading(true);
      var fileUrl;
      fileUrl = await uploadFileToServer(projectImage);
      setImageUploadLoading(false);
    }

    createObjectForm.img = fileUrl;

    createObjectForm.users = teamMembers?.map((i) => i.value);
    createObjectForm.isParent = isParent;
    if (!isParent) {
      createObjectForm.parentProjectID = parentProject?.value;
    }
    if (selectedProjectType === PROJ_TYPES.IFC) {
      createObjectForm.isIfcProject = true;
      createObjectForm.masterFile = `${"uploads/"}${ifcUrl}`;
    }

    if (selectedProjectType === PROJ_TYPES.REVIT) {
      createObjectForm.isIfcProject = false;
      createObjectForm.builtInParameters = selectedparameters
        .filter((item) => item?.parameterType === PARAMETER_TABS.BUILT_IN)
        ?.map((i) => i?._id);
      createObjectForm.sharedParameters = selectedparameters
        .filter((item) => item?.parameterType === PARAMETER_TABS.SHARED)
        ?.map((i) => i?._id);
      createObjectForm.projectParameters = selectedparameters
        .filter((item) => item?.parameterType === PARAMETER_TABS.PROJECT)
        ?.map((i) => i?._id);
      createObjectForm.familyParameters = selectedparameters
        .filter((item) => item?.parameterType === PARAMETER_TABS.FAMILY)
        ?.map((i) => i?._id);

      createObjectForm.globalParameters = selectedparameters
        .filter((item) => item?.parameterType === PARAMETER_TABS.GLOBAL)
        ?.map((i) => i?._id);

      createObjectForm.matchingParameter = {
        type: showExtraMatchingParameter ? "custom parameter" : "Default",
        paramType:  step_03_matchingParameterType ? step_03_matchingParameterType?.value:  " " ,
        parameters: step_03_matchingParameterId?.map((i) => i.value),
      };

      createObjectForm.filterParameter = {
        type: showFilterBidlightParameter
          ? "bidlight parameter"
          : showFilterCustomParameter
          ? "custom parameter"
          : "None",
        paramType: showFilterCustomParameter
          ? step_03_filterParameterType.value
          : showFilterBidlightParameter
          ? "bidlight parameter"
          : "None",
        parameters: showFilterBidlightParameter
          ? step_03_filterParamsProjectObj?.map((i) => i.value)
          : showFilterCustomParameter
          ? step_03_filterParameterSelected?.map((i) => i.value)
          : [],
      };
    }

    createObjectForm.milestone = milestones?.value;
    createObjectForm.includeMiscellaneous = includeMiscellaneous;
    createObjectForm.include2DElements = include2DElements;

    const res = await createProjectExportSettingsAPI(createObjectForm);
  };

  const handleProjectImg = async (e) => {
    const selectedImg = e.target.files[0];
    setProjectImage(selectedImg);
  };

  const handleIfcFile = async (e) => {
    const selectedIfc = e.target.files[0];
    setIfcFile(selectedIfc);
    const fileUrl = await uploadFileToServer(selectedIfc);
    setIfcUrl(fileUrl);
  };

  const AvaialableParameters = (filter) => {
    const [paramList, setParamList] = useState(
      allParameters?.filter((i) => i?.parameterType === filter.filter)
    );
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
      handleSearch(searchValue);
    }, [searchValue]);

    const handleSearch = (keyword) => {
      if (keyword?.length === 0) {
        setParamList(
          allParameters?.filter((i) => i?.parameterType === filter.filter)
        );
      } else {
        const filteredResult = allParameters
          ?.filter((i) => i?.parameterType === filter?.filter)
          ?.filter((item) => {
            return String(item?.name)
              ?.toLowerCase()
              ?.includes(keyword?.toLowerCase());
          });
        setParamList(filteredResult);
      }
    };

    const updateCheckList = (item) => {
      setSelectAllAvaialable(false);
      if (
        checkedAvailParameters.filter((value) => value._id == item._id).length >
        0
      ) {
        setCheckedAvailParameters(
          checkedAvailParameters.filter((i) => i?._id != item?._id)
        );
      } else {
        setCheckedAvailParameters([...checkedAvailParameters, item]); // or push
      }
    };

    const selectAllAvaialableParams = (checked, paramList) => {
      if (checked) {
        setCheckedAvailParameters(paramList);
        setSelectAllAvaialable(true);
      } else {
        setCheckedAvailParameters([]);
        setSelectAllAvaialable(false);
      }
    };

    return (
      <div className="param-box">
        <p className="f-18 inter font-weight-bold p-3 mb-0">
          Available Parameters{" "}
          <span className="f-12 inter font-weight-normal">
            ({paramList?.length})
          </span>
        </p>
        <input
          type="text"
          className="search-bar search-bar-parameters"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search"
          // disabled={loading}
        />
        <div className="overflow-ul-div">
          <ul>
            <li>
              <input
                type="checkbox"
                className="squared-checkbox"
                checked={selectAllAvaialable}
                onChange={(e) =>
                  selectAllAvaialableParams(e.target.checked, paramList)
                }
              />{" "}
              Select all
            </li>
            {paramList?.map((item, key) => (
              <li key={key}>
                <input
                  type="checkbox"
                  className="squared-checkbox"
                  checked={
                    checkedAvailParameters.filter(
                      (value) => value?._id == item?._id
                    ).length > 0
                  }
                  onChange={() => updateCheckList(item)}
                />{" "}
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const SelectedParameters = (filter) => {
    const [searchValue1, setSearchValue1] = useState("");
    const [selectedparametersFiltered, setSelectedparametersFiltered] =
      useState(selectedparameters);

    useEffect(() => {
      handleSearch(searchValue1);
    }, [searchValue1]);

    const handleSearch = (keyword) => {
      if (keyword?.length === 0) {
        setSelectedparametersFiltered(selectedparameters);
      } else {
        const filteredResult = selectedparameters?.filter((item) => {
          return String(item?.name)
            ?.toLowerCase()
            ?.includes(keyword?.toLowerCase());
        });
        setSelectedparametersFiltered(filteredResult);
      }
    };

    const updateCheckList = (item) => {
      setSelectAllSelected(false);
      if (
        checkedSelectedParameters.filter((value) => value._id == item._id)
          .length > 0
      ) {
        setCheckedSelectedParameters(
          checkedSelectedParameters.filter((i) => i?._id != item?._id)
        );
      } else {
        setCheckedSelectedParameters([...checkedSelectedParameters, item]); // or push
      }
    };

    const selectAllSelectedParams = (checked, paramList) => {
      if (checked) {
        setCheckedSelectedParameters(paramList);
        setSelectAllSelected(true);
      } else {
        setCheckedSelectedParameters([]);
        setSelectAllSelected(false);
      }
    };

    return (
      <div className="param-box">
        <p className="f-18 inter font-weight-bold p-3 mb-0">
          Selected Parameters{" "}
          <span className="f-12 inter font-weight-normal">
            ({selectedparametersFiltered.length})
          </span>
        </p>

        <input
          type="text"
          className="search-bar search-bar-parameters"
          value={searchValue1}
          onChange={(e) => setSearchValue1(e.target.value)}
          placeholder="Search"
          // disabled={loading}
        />

        <div className="overflow-ul-div">
          <ul>
            <li>
              <input
                type="checkbox"
                className="squared-checkbox"
                checked={selectAllSelected}
                onChange={(e) =>
                  selectAllSelectedParams(
                    e.target.checked,
                    selectedparametersFiltered
                  )
                }
              />{" "}
              Select all
            </li>
            {selectedparametersFiltered?.map((item, key) => (
              <li key={key}>
                <input
                  type="checkbox"
                  className="squared-checkbox"
                  checked={
                    checkedSelectedParameters.filter(
                      (value) => value?._id == item?._id
                    ).length > 0
                  }
                  onChange={() => updateCheckList(item)}
                />{" "}
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const moveToSelectedParameter = () => {
    setSelectAllAvaialable(false);
    setSelectAllSelected(false);
    const comparedBothArrays = allParameters.filter(
      (obj1) => !checkedAvailParameters.some((obj2) => obj1._id === obj2._id)
    );
    setAllParameters(comparedBothArrays);
    setSelectedParameters(checkedAvailParameters);
    setCheckedSelectedParameters([]);
  };

  const moveToAvailabalParameter = () => {
    setSelectAllAvaialable(false);
    setSelectAllSelected(false);
    const comparedBothArrays = selectedparameters.filter(
      (obj1) => !checkedSelectedParameters.some((obj2) => obj1._id === obj2._id)
    );
    setSelectedParameters(comparedBothArrays);
    setAllParameters((oldArray) => [...oldArray, ...checkedSelectedParameters]);
    setCheckedAvailParameters([]);
  };

  const handleChangeParameterType = (filter) => {
    const filteredObj = allParameters?.filter(
      (i) => i?.parameterType === filter.value
    );
    const filteredObjArr = filteredObj?.map((i) => ({
      label: i.name,
      value: i._id,
    }));
    setStep_03_matchingParameterNameList(filteredObjArr);
    setStep_03_matchingParameterType(filter);
  };

  const handleChangeFilterParameterType = (filter) => {
    const filteredObj = allParameters?.filter(
      (i) => i?.parameterType === filter.value
    );
    const filteredObjArr = filteredObj?.map((i) => ({
      label: i.name,
      value: i._id,
    }));
    setStep_03_filterParameterNameList(filteredObjArr);
    setStep_03_filterParameterType(filter);
  };

  const checkProjectNumber = async (value) => {
    setProjectNumberLoading(true);
    try {
      const res = await verifyProjectNumber({ projectNumber: value });
      setProjectNumberLoading(false);
      if (res.isProjectNumberUnique) {
        setProjectNumber(value);
      } else {
        setError("projectNo", { type: "custom", message: res.err });
        setProjectNumber("");
      }
    } catch (error) {
      setError("projectNo", { type: "custom", message: error.err });
      setProjectNumber("");
      setProjectNumberLoading(false);
    }
  };

  const debouncedApiCall = debounce(checkProjectNumber, 1000);

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={choseToggle(loading, toggle)}
      centered
      className="create-modal-full-size"
    >
      <MDBModalHeader toggle={choseToggle(loading, toggle)}>
        Create project
      </MDBModalHeader>
      <MDBModalBody className="pt-0">
        <form onSubmit={(e) => createObject(e)}>
          {selectedSteps === STEP.PROJECT_SELECTION ? (
            <div>
              <div className="text-center mt-5 text-black">
                <p className="inter f-14 font-weight-bold mb-1">Project type</p>
                <p className="inter f-14 font-weight-normal">
                  Please Select your project type
                </p>
              </div>

              <div className="d-flex gap-15 mt-5">
                <div
                  className={`${"cursor-pointer"} ${
                    selectedProjectType === PROJ_TYPES.REVIT
                      ? "select-proj-box-active"
                      : "select-proj-box"
                  }`}
                  onClick={() => setSelectedProjectType(PROJ_TYPES.REVIT)}
                >
                  <img src={RevitImg} alt="" />
                  <div>
                    <p className="f-14 inter font-weight-bold mb-1 text-black">
                      Revit
                    </p>
                    <p className="f-14 inter font-weight-normal mb-0 text-black">
                      Revit is a commercial building information modeling (BIM)
                      software by the company Autodesk.
                    </p>
                  </div>
                </div>
                <div
                  className={`${"cursor-pointer"} ${
                    selectedProjectType === PROJ_TYPES.IFC
                      ? "select-proj-box-active"
                      : "select-proj-box"
                  }`}
                  onClick={() => setSelectedProjectType(PROJ_TYPES.IFC)}
                >
                  <img src={IFCIMG} alt="" />
                  <div>
                    <p className="f-14 inter font-weight-bold mb-1 text-black">
                      IFC
                    </p>
                    <p className="f-14 inter font-weight-normal mb-0 text-black">
                      IFC is short for International Foundation Class and files
                      using the IFC file format are Building Information
                      Modelling (BIM) files.
                    </p>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-5">
                <button
                  className="header-btn text-bid border-bid"
                  type="button"
                  onClick={() => toggle()}
                >
                  Cancel
                </button>
                <button
                  className="header-btn text-white bg-bid border-bid mr-0"
                  onClick={() => setSelectedSteps(STEP.STEP_01)}
                  type="button"
                >
                  Next
                  <span className="ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                    >
                      <path
                        d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          ) : (
            <div>
              {selectedSteps !== STEP.STEP_04 && (
                <div className="stepper-div">
                  <div className="step-01">
                    {selectedSteps === STEP.STEP_01 && (
                      <img src={StepInProgressImg} alt="" />
                    )}
                    {selectedSteps === STEP.STEP_02 && (
                      <img src={StepDoneImg} alt="" />
                    )}
                    {selectedSteps === STEP.STEP_03 && (
                      <img src={StepDoneImg} alt="" />
                    )}
                    <p className="inter f-14 font-weight-bold mb-1">Step 01</p>
                    <p className="inter f-14 font-weight-normal">Basic info</p>
                  </div>
                  {selectedProjectType === PROJ_TYPES.REVIT && (
                    <div className="step-02">
                      {selectedSteps === STEP.STEP_01 && (
                        <img src={StepIncompleteImg} alt="" />
                      )}
                      {selectedSteps === STEP.STEP_02 && (
                        <img src={StepInProgressImg} alt="" />
                      )}
                      {selectedSteps === STEP.STEP_03 && (
                        <img src={StepDoneImg} alt="" />
                      )}
                      <p className="inter f-14 font-weight-bold mb-1">
                        Step 02
                      </p>
                      <p className="inter f-14 font-weight-normal">
                        Additional parameter
                      </p>
                    </div>
                  )}

                  <div className="step-03">
                    {selectedSteps === STEP.STEP_01 && (
                      <img src={StepIncompleteImg} alt="" />
                    )}
                    {selectedSteps === STEP.STEP_02 && (
                      <img src={StepIncompleteImg} alt="" />
                    )}
                    {selectedSteps === STEP.STEP_03 && (
                      <img src={StepInProgressImg} alt="" />
                    )}
                    <p className="inter f-14 font-weight-bold mb-1">Step 03</p>
                    <p className="inter f-14 font-weight-normal">
                      Export settings
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Step 01 */}
          {selectedSteps === STEP.STEP_01 ? (
            <>
              <div className="d-flex justify-content-between gap-2">
                <div className="form-group mt-3 w-50">
                  <label
                    htmlFor=""
                    className="f-14 inter text-black font-weight-500"
                  >
                    Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Project title"
                    {...register("title", {
                      required: "Project title is required",
                      maxLength: 20,
                      onChange: (e) => setTitle(e.target.value),
                    })}
                  />
                  {errors.title && (
                    <p role="alert" className="validation-error-msg">
                      {errors.title.message}
                    </p>
                  )}
                </div>
                <div className="form-group mt-3 w-50">
                  <label
                    htmlFor=""
                    className="f-14 inter text-black font-weight-500"
                  >
                    Project Number <span className="text-danger">*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Project Number"
                      {...register("projectNo", {
                        required: "Project Number is required",
                        maxLength: 20,
                        onChange: (e) => debouncedApiCall(e.target.value),
                      })}
                    />
                    {projectNumberLoading && (
                      <i className="fa fa-spinner small-spinner input-box-spinner" />
                    )}
                  </div>
                  {errors.projectNo && (
                    <p role="alert" className="validation-error-msg">
                      {errors.projectNo.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="form-group mt-3">
                <label htmlFor="" className="f-14 inter text-black font-weight-500">
                  Description
                </label>
                <textarea
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Description"
                ></textarea>
              </div>

              {selectedProjectType === PROJ_TYPES.IFC && (
                <div className="form-group mt-3">
                  <label
                    htmlFor=""
                    className="f-14 inter text-black font-weight-500"
                  >
                    IFC file
                  </label>
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className="profile-upload-box cursor-pointer w-fill d-flex align-items-center my-0 justify-content-between"
                      onClick={() => ifcFileInputRef.current.click()}
                    >
                      <div className="text-left">
                        {ifcFile ? (
                          ifcFile.name
                        ) : (
                          <p className="font-weight-bold text-black f-14">
                            Click to upload{" "}
                            <span className="font-weight-light">
                              or drag and drop
                            </span>
                          </p>
                        )}
                        <p className="f-12">.IFC</p>
                      </div>
                      <img src={UploadIcon} className="upload-icon" />
                    </div>
                  </div>
                  <input
                    ref={ifcFileInputRef}
                    onChange={handleIfcFile}
                    type="file"
                    id="ifcFile"
                    name="ifcFile"
                    accept={".ifc"}
                    style={{ display: "none" }}
                  />
                </div>
              )}

              <div className="form-group mt-3">
                <label htmlFor="" className="f-14 inter text-black font-weight-500">
                  Project image
                </label>
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="profile-upload-box cursor-pointer w-fill d-flex align-items-center my-0 justify-content-between"
                    onClick={() => projectImgInputRef.current.click()}
                  >
                    <div className="text-left">
                      <p className="font-weight-bold text-black f-14">
                        Click to upload{" "}
                        <span className="font-weight-light">
                          or drag and drop
                        </span>
                      </p>
                      <p className="f-12">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </p>
                    </div>
                    <img src={UploadIcon} className="upload-icon" />
                  </div>
                  <img
                    src={
                      projectImage
                        ? window.URL.createObjectURL(projectImage)
                        : UploadIcon
                    }
                    className="uploaded-icon"
                  />
                </div>

                <input
                  ref={projectImgInputRef}
                  onChange={handleProjectImg}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </div>

              {/* <div className="d-flex justify-content-between">
                <div className="form-group mt-3 w-49">
                  <label
                    htmlFor=""
                    className="f-14 inter text-black font-weight-500"
                  >
                    Start Date
                  </label>
                  <input
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    type="date"
                    className="form-control"
                  />
                </div>
                <div className="form-group mt-3 w-49">
                  <label
                    htmlFor=""
                    className="f-14 inter text-black font-weight-500"
                  >
                    End Date
                  </label>
                  <input
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    type="date"
                    className="form-control"
                  />
                </div>
              </div> */}

              <div className="form-group-div mt-3">
                <label className="f-14 inter text-black font-weight-500">
                  Team member
                </label>
                <Select
                  options={teamMembersOptions}
                  isMulti
                  onChange={(options) => setTeamMembers(options)}
                />
              </div>

              <div className="form-check form-switch ml-1 mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  value={isParent}
                  onChange={(e) => setIsParent(e.target.checked)}
                  checked={isParent}
                />
                <label
                  className="form-check-label ml-2 f-14 inter text-black font-weight-500"
                  htmlFor="flexSwitchCheckChecked"
                >
                  Parent Project
                </label>
              </div>

              {!isParent && (
                <div className="form-group-div mt-3">
                  <label className="f-14 inter text-black font-weight-500">
                    Select Parent
                  </label>
                  <Select
                    options={projectsRedux
                      ?.filter((x) => x.isParent === true)
                      .map((i) => ({
                        label: i.title,
                        value: i._id,
                      }))}
                    onChange={(e) => setParentProject(e)}
                  />
                </div>
              )}

              <div className="d-flex align-items-center justify-content-between mt-5">
                <button
                  type="button"
                  className="header-btn text-bid border-bid"
                  onClick={() => setSelectedSteps(STEP.PROJECT_SELECTION)}
                >
                  <span className="mr-3 transform-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                    >
                      <path
                        d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                        fill="#09121F"
                      />
                    </svg>
                  </span>
                  Back
                </button>
                <button
                  type="button"
                  className="header-btn text-white bg-bid border-bid mr-0"
                  // disabled={title.length === 0}
                  onClick={(e) => {
                    if (title.length === 0) {
                      trigger(["title"]);
                    } else if (projectNumber.length === 0) {
                      trigger(["projectNo"]);
                    } else {
                      selectedProjectType === PROJ_TYPES.IFC && !ifcFile
                        ? alert("Please upload a IFC/Zip file")
                        : setSelectedSteps(
                            selectedProjectType === PROJ_TYPES.REVIT
                              ? STEP.STEP_02
                              : STEP.STEP_03
                          );
                    }
                  }}
                >
                  Next
                  <span className="ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                    >
                      <path
                        d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </>
          ) : null}

          {/* Step 02 only htmlFor Revit*/}
          {selectedSteps === STEP.STEP_02 ? (
            <>
              {selectedProjectType === PROJ_TYPES.REVIT ? (
                <>
                  <div
                    className="btn-group btn-group-toggle toggle-group mt-3 w-100 report-btn-group"
                    data-toggle="buttons"
                  >
                    <label
                      className="btn btn-secondary  active "
                      onClick={() =>
                        setActiveParameterTab(PARAMETER_TABS.BUILT_IN)
                      }
                    >
                      <input type="radio" name="options" id="option1" checked />
                      Builtin Parameters
                    </label>
                    <label
                      className="btn btn-secondary"
                      onClick={() =>
                        setActiveParameterTab(PARAMETER_TABS.SHARED)
                      }
                    >
                      <input type="radio" name="options" id="option2" />
                      Shared Parameters
                    </label>
                    <label
                      className="btn btn-secondary"
                      onClick={() =>
                        setActiveParameterTab(PARAMETER_TABS.PROJECT)
                      }
                    >
                      <input type="radio" name="options" id="option3" />
                      Project Parameters
                    </label>
                    <label
                      className="btn btn-secondary"
                      onClick={() =>
                        setActiveParameterTab(PARAMETER_TABS.FAMILY)
                      }
                    >
                      <input type="radio" name="options" id="option4" />
                      Family Parameters
                    </label>
                    <label
                      className="btn btn-secondary"
                      onClick={() =>
                        setActiveParameterTab(PARAMETER_TABS.GLOBAL)
                      }
                    >
                      <input type="radio" name="options" id="option5" />
                      Global Parameters
                    </label>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-5">
                    {parametersLoading && (
                      <Loading color="black" text="Loading parameters..." />
                    )}

                    {activeParameterTab === PARAMETER_TABS.BUILT_IN && (
                      <AvaialableParameters filter={PARAMETER_TABS.BUILT_IN} />
                    )}
                    {activeParameterTab === PARAMETER_TABS.SHARED && (
                      <AvaialableParameters filter={PARAMETER_TABS.SHARED} />
                    )}
                    {activeParameterTab === PARAMETER_TABS.PROJECT && (
                      <AvaialableParameters filter={PARAMETER_TABS.PROJECT} />
                    )}
                    {activeParameterTab === PARAMETER_TABS.FAMILY && (
                      <AvaialableParameters filter={PARAMETER_TABS.FAMILY} />
                    )}
                    {activeParameterTab === PARAMETER_TABS.GLOBAL && (
                      <AvaialableParameters filter={PARAMETER_TABS.GLOBAL} />
                    )}

                    <div className="mx-3">
                      <button
                        className="header-btn text-white bg-bid border-bid mr-0"
                        type="button"
                        onClick={() => moveToSelectedParameter()}
                      >
                        Move
                        <span className="ml-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                          >
                            <path
                              d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <button
                        className="header-btn text-bid border-bid mt-3 mr-0"
                        type="button"
                        onClick={() => moveToAvailabalParameter()}
                      >
                        <span className="mr-3 transform-180">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                          >
                            <path
                              d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                              fill="#09121F"
                            />
                          </svg>
                        </span>
                        Move
                      </button>
                    </div>

                    <SelectedParameters />
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-5">
                    <button
                      type="button"
                      className="header-btn text-bid border-bid"
                      onClick={() => setSelectedSteps(STEP.STEP_01)}
                    >
                      <span className="mr-3 transform-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="12"
                          viewBox="0 0 8 12"
                          fill="none"
                        >
                          <path
                            d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                            fill="#09121F"
                          />
                        </svg>
                      </span>
                      Back
                    </button>
                    <button
                      type="button"
                      className="header-btn text-white bg-bid border-bid mr-0"
                      onClick={() => setSelectedSteps(STEP.STEP_03)}
                      // disabled={selectedparameters.length === 0}
                    >
                      Next
                      <span className="ml-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="12"
                          viewBox="0 0 8 12"
                          fill="none"
                        >
                          <path
                            d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </>
              ) : null}
            </>
          ) : null}

          {/* Step 03*/}
          {selectedSteps === STEP.STEP_03 ? (
            <>
              {exportSettingsLoading && (
                <Loading color="dark" text="Creating..." />
              )}
              <div className="form-group-div mt-3">
                <label className="f-14 inter text-black font-weight-500">
                  Milestone
                </label>
                <Select
                  options={milestonesOptions}
                  onChange={(options) => setMilestones(options)}
                  defaultValue={milestones}
                />
              </div>

              {selectedProjectType === PROJ_TYPES.REVIT && (
                <>
                  <div className="matching-param-box">
                    <p className="f-14 inter font-weight-500">
                      Matching Parameter
                    </p>
                    <div className="d-flex">
                      <div className="d-flex align-items-center mr-3">
                        <input
                          type="radio"
                          className="rounded-checkbox"
                          name="matching_parameter"
                          checked={!showExtraMatchingParameter}
                          onClick={(e) => setShowExtraMatchingParameter(false)}
                        />
                        <p className="mb-0 ml-2">Revit Default Category</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          className="rounded-checkbox"
                          name="matching_parameter"
                          onClick={(e) => setShowExtraMatchingParameter(true)}
                          checked={showExtraMatchingParameter}
                        />
                        <p className="mb-0 ml-2">Custom Parameter</p>
                      </div>
                    </div>

                    {showExtraMatchingParameter && (
                      <div>
                        <div className="form-group-div mt-3">
                          <Select
                            options={PARAMETER_TYPE_OPTIONS}
                            onChange={(option) =>
                              handleChangeParameterType(option)
                            }
                            defaultValue={step_03_matchingParameterType}
                          />
                        </div>

                        <div className="form-group-div mt-3">
                          <Select
                            options={step_03_matchingParameterNameList}
                            isMulti
                            onChange={(option) =>
                              setStep_03_matchingParameterId(option)
                            }
                            defaultValue={step_03_matchingParameterId}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="matching-param-box">
                    <p className="f-14 inter font-weight-500">
                      Filter Parameter
                    </p>
                    <div className="d-flex">
                      <div className="d-flex align-items-center mr-3">
                        <input
                          type="radio"
                          className="rounded-checkbox"
                          name="filter_parameter"
                          checked={
                            !showFilterBidlightParameter &&
                            !showFilterCustomParameter
                          }
                          onClick={(e) => {
                            setShowFilterBidlightParameter(false);
                            setShowFilterCustomParameter(false);
                          }}
                        />
                        <p className="mb-0 ml-2">None</p>
                      </div>
                      <div className="d-flex align-items-center mr-3">
                        <input
                          type="radio"
                          className="rounded-checkbox"
                          name="filter_parameter"
                          checked={
                            showFilterBidlightParameter &&
                            !showFilterCustomParameter
                          }
                          onClick={(e) => {
                            setShowFilterBidlightParameter(true);
                            setShowFilterCustomParameter(false);
                          }}
                        />
                        <p className="mb-0 ml-2">Use BidLight project ID</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          className="rounded-checkbox"
                          name="filter_parameter"
                          checked={
                            !showFilterBidlightParameter &&
                            showFilterCustomParameter
                          }
                          onClick={(e) => {
                            setShowFilterBidlightParameter(false);
                            setShowFilterCustomParameter(true);
                          }}
                        />
                        <p className="mb-0 ml-2">Use Custom parameter</p>
                      </div>
                    </div>

                    {showFilterBidlightParameter && (
                      <div>
                        <div className="form-group-div mt-3">
                          <Select
                            options={parentProjectOptions}
                            isMulti
                            onChange={(e) =>
                              setStep_03_filterParamsProjectObj(e)
                            }
                            defaultValue={step_03_filterParamsProjectObj}
                          />
                        </div>
                      </div>
                    )}

                    {showFilterCustomParameter && (
                      <div>
                        <div className="form-group-div mt-3">
                          <Select
                            options={PARAMETER_TYPE_OPTIONS}
                            onChange={(option) =>
                              handleChangeFilterParameterType(option)
                            }
                            defaultValue={step_03_filterParameterType}
                          />
                        </div>

                        <div className="form-group-div mt-3">
                          <Select
                            options={step_03_filterParameterNameList}
                            isMulti
                            onChange={(option) =>
                              setStep_03_filterParameterSelected(option)
                            }
                            defaultValue={step_03_filterParameterSelected}
                          />
                        </div>

                        {/* <div className="form-group-div mt-3">
                          <select>
                            <option>Parameter Value multiselect</option>
                          </select>
                        </div> */}
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="form-check form-switch ml-1 mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="includeMiscellaneous"
                  checked={includeMiscellaneous}
                  onChange={(e) => setIncludeMiscellaneous(e.target.checked)}
                />
                <label
                  className="form-check-label ml-2 f-14 inter text-black font-weight-500"
                  htmlFor="includeMiscellaneous"
                >
                  Include Miscellaneous
                </label>
              </div>

              <div className="form-check form-switch ml-1 mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="include2DElements"
                  checked={include2DElements}
                  onChange={(e) => setInclude2DElements(e.target.checked)}
                />
                <label
                  className="form-check-label ml-2 f-14 inter text-black font-weight-500"
                  htmlFor="include2DElements"
                >
                  Include 2D Elements
                </label>
              </div>

              {loading ? <Loading color="dark" text="Creating..." /> : null}

              <div className="d-flex align-items-center justify-content-between mt-5">
                <button
                  type="button"
                  className="header-btn text-bid border-bid"
                  onClick={() =>
                    setSelectedSteps(
                      selectedProjectType === PROJ_TYPES.REVIT
                        ? STEP.STEP_02
                        : STEP.STEP_01
                    )
                  }
                >
                  <span className="mr-3 transform-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                    >
                      <path
                        d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                        fill="#09121F"
                      />
                    </svg>
                  </span>
                  Back
                </button>
                <button
                  type="submit"
                  className="header-btn text-white bg-bid border-bid mr-0 position-relative"
                >
                  Next
                  {imageUploadLoading ? (
                    <i className="fa fa-spinner small-spinner ml-3" />
                  ) : exportsettingsloading ? (
                    <i className="fa fa-spinner small-spinner ml-3" />
                  ) : (
                    <span className="ml-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="12"
                        viewBox="0 0 8 12"
                        fill="none"
                      >
                        <path
                          d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  )}
                </button>
              </div>
            </>
          ) : null}
        </form>
        {selectedSteps === STEP.STEP_04 ? (
          <>
            <div className="successfully-project-created-div">
              <div>
                <img src={SuccessIMG} alt="" />
                <p className="f-14 inter font-weight-500 mb-0">Well Done!</p>
                <p className="f-14 inter font-weight-light">
                  Project created Successfully
                </p>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                className="header-btn text-white bg-bid border-bid mr-0"
                onClick={() => toggle()}
              >
                My projects
                <span className="ml-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                  >
                    <path
                      d="M4.97633 6.00058L0.851562 1.87577L2.03007 0.697266L7.33341 6.00058L2.03007 11.3038L0.851562 10.1253L4.97633 6.00058Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </>
        ) : null}
      </MDBModalBody>
    </MDBModal>
  );
};

export default CreateModal;
