import React, { useState } from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBIcon,
} from "mdbreact";
import {
  WalletIcon,
  RullerIcon,
  UserLocationIcon,
  ProjectIcon,
  ProfileIcon,
  DocumentIcon,
  CheckInIcon,
  DownArrowIcon,
  DatabaseIcon,
} from "assets/svg-icons";
import { AddNewVersionModal } from "./AddNewVersionModal";
import { useSelector } from "react-redux";
import { addNewCatalogs, updateCatalogs } from "api/database";
import addNotification from "helpers/notify";
import { NewCatalogModal } from "./NewCatalogModal";
import handleGenrateDivisionCode from "helpers/generateDivisionCode";
import { UploadOptionList } from "./UploadOptionList";
import { SaveOptionList } from "./SaveOptionList";
import { useHttp } from "hooks/useHttp";
import { DeleteCatalogModal } from "./DeleteCatalogModal";
import { AcquireLicenseModal } from "components/AcquireLicense/AcquireLicenseModal";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { formats } from "constants/utils";
import logo from "assets/images/logo/logo-blue.png";
import onebuildLogo from "assets/images/logo/1build-logo.png";
import craftsmanLogo from "assets/images/logo/craftsman-logo.png";
import { eventTrack } from "helpers/ga4Helper";

export const PricingCatalogsFiltrationBar = ({
  setSearchedCatalogs,
  onChangeTemplate,
  setIsLoading,
  selectedCatalog,
  conversionRate,
  allTemplates,
  countryState,
  country,
  city,
  selectedTemplate,
  appliedTemplate,
  breadcrumbData,
  setTypesOfData,
  dataTypes,
  selectedCompany,
}) => {
  const userInfo = useSelector((s) => s.user.userInfo);
  const allCatalogsData = useSelector((s) => s.user.catalogs);
  const catalogsTree = useSelector((s) => s.user.catalogsTree);

  const [addNewTemplate, setAddNewTemplate] = useState(null);
  const [openModalNewCatalog, setOpenModalNewCatalog] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isCatalogsUploaded, setIsCatalogsUploaded] = useState(false);
  const [newDivision, setNewDivison] = useState(null);
  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const [templateList, setAllTemplateList] = useState(allTemplates);
  const [acquireLicenseType, setAquireLicenseType] = useState("");
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux?.filter((element) =>
    companyPermissionRedux?.includes(element)
  );
  const { admin, company, companyAdmin } = userInfo;
  const SELECTED_FORMAT = formats[selectedTemplate?.company.Protocol];
  const APPLIED_FORMAT = formats[appliedTemplate?.company?.Protocol];
  const companyId = selectedCompany?.value ?? company;

  const template =
    selectedTemplate?.company ||
    selectedTemplate?.average ||
    selectedTemplate?.default;

  const { loading: catalogsLoading, request: handlePostNewCatalog } = useHttp({
    requestCallback: (data) => addNewCatalogs(APPLIED_FORMAT, companyId, data),
    onLoad: (res) => {
      if (res.err) {
        addNotification(res.err, "danger");
      } else {
        onChangeTemplate(appliedTemplate);
        addNotification("New catalogs added successfully", "success");
        return res;
      }
    },
    onError: (res) => {
      addNotification(
        res.err ?? "Something went wrong, please contact support",
        "danger"
      );
    },
  });

  const {
    loading: updateCatalogLoading,
    request: handleUpdateExistingCatalog,
  } = useHttp({
    requestCallback: (data) => updateCatalogs(APPLIED_FORMAT, data),
    onLoad: (res) => {
      if (res.err) {
        addNotification(res.err, "danger");
      } else {
        return res;
      }
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const findAllParentsOfSelectedItems = (selectedItems) => {
    const allParents = [];
    for (const item of selectedItems) {
      if (companyAdmin) {
        let parent = item?.Parent?.ref !== "company" && item?.Parent;

        while (parent) {
          allParents.push(parent);
          parent = parent.Parent;
        }
      } else {
        let parent = item?.Parent?.ref !== "default" && item?.Parent;

        while (parent) {
          allParents.push(parent);
          parent = parent.Parent;
        }
      }
    }
    return allParents;
  };

  const handleCreateNewCatalogByFile = () => {
    if (!selectedTemplate) {
      addNotification("Please select template", "danger");
    }
    if (!SELECTED_FORMAT) return;

    const catalogsRow = allCatalogsData.map((catalog) => catalog?.company);

    const newCatalogsPayload = catalogsRow.map((catalog) => ({
      Code: catalog.Code,
      Description: catalog.Description,
      City: template?.City,
      Country: template?.Country,
      State: template?.State,
      Level: catalog.Level,
      UnitCost: (catalog.UnitCost / conversionRate).toFixed(2),
      CategoryId: catalog.CategoryId,
      Unit: catalog.Unit,
      Awp: catalog.Awp,
      TotalSimpleUnitCost: catalog.UnitCost,
      EquipmentCodes: [],
      CrewCodes: [],
      LaborType: template?.LaborType,
      ProjectType: template?.ProjectType,
      isPublic: true,
      OrganizationId: company,
      Quarter: template?.Quarter,
      Year: template?.Year,
      Version: template?.Version,
    }));

    addNewCatalogs(SELECTED_FORMAT, companyId, newCatalogsPayload);
  };

  const handleUpdateCatalog = () => {
    const editedItem = selectedCatalog.map((catalog) => catalog?._id);
    const allParentIds = findAllParentsOfSelectedItems(selectedCatalog).map(
      (parentDiv) => parentDiv?._id
    );

    let catalogsToPost = editedItem.concat(allParentIds);
    catalogsToPost = [...new Set(catalogsToPost)];
    const itemsToUpdate = allCatalogsData
      .filter((catalog) => catalogsToPost.includes(catalog.company._id))
      .map((catalog) => {
        if (admin && selectedCompany?.value) {
          catalog.company.OrganizationId = selectedCompany?.value;
          catalog.company.City = template?.City;
          catalog.company.Country = template?.Country;
          catalog.company.State = template?.State;
          catalog.company.LaborType = template?.LaborType;
          catalog.company.ProjectType = template?.ProjectType;
        }
        return catalog.company;
      });

    const adoptedItems = [];
    const updatingItems = [];

    itemsToUpdate.forEach((element) => {
      if (admin) {
        (element?.ref !== "company" && selectedCompany?.value) ||
        (!selectedCompany && element?.ref !== "default")
          ? adoptedItems.push(element)
          : updatingItems.push(element);
      } else {
        element?.ref !== "company"
          ? adoptedItems.push(element)
          : updatingItems.push(element);
      }
    });

    const restAdoptedItem = adoptedItems.map((item) => {
      return {
        Code: item?.Code,
        Description: item?.Description,
        City: item?.City,
        Country: item?.Country,
        State: item?.State,
        Level: item?.Level,
        UnitCost: Number(item?.UnitCost),
        CategoryId: item?.CategoryId,
        Unit: item?.Unit,
        Awp: item?.Awp,
        TotalSimpleUnitCost: item?.TotalSimpleUnitCost,
        EquipmentCodes: item?.EquipmentCodes,
        CrewCodes: item?.CrewCodes,
        LaborType: item?.LaborType,
        ProjectType: item?.ProjectType,
        isPublic: false,
        Quarter: item?.Quarter,
        Year: item?.Year,
        Version: item?.Version,
      };
    });
    if (updatingItems.length > 0) {
      const updatedCatalogs = updatingItems?.map((catalog) => {
        return handleUpdateExistingCatalog(catalog);
      });
      Promise.all(updatedCatalogs)
        .then((results) => {
          if (results) {
            onChangeTemplate(appliedTemplate);
            addNotification("Catalogue(s) Updated Successfully", "success");
          }
        })
        .catch((error) => {
          console.error("Error while updating Catalogue(s):", error);
        });
    }
    if (adoptedItems.length > 0) {
      handlePostNewCatalog(restAdoptedItem);
    }
  };

  const handleNewCatalogModal = (isRootCatalog) => {
    if (FinalPermissions?.includes("pricingDatabaseEdit")) {
      setIsEdited(false);
      const ParentDivsions =
        selectedCatalog.length && isRootCatalog !== true
          ? selectedCatalog[selectedCatalog.length - 1]
          : catalogsTree;
      const isParent =
        !selectedCatalog.length || isRootCatalog === true ? true : false;
      const childDivision = handleGenrateDivisionCode(
        appliedTemplate?.company?.Protocol,
        ParentDivsions,
        isParent
      );
      if (childDivision === null) {
        addNotification(
          "This division is full, You can't add new catalog in this division",
          "danger"
        );
      } else {
        setNewDivison(childDivision);
        setOpenModalNewCatalog(true);
      }
    } else {
      setAquireLicenseType(ALL_PERMISSIONS.PRICING_DATABASE_EDIT);
      setOpenLicenseModal(true);
    }
  };

  const handleEditCatalog = () => {
    if (FinalPermissions?.includes("pricingDatabaseEdit")) {
      if (selectedCatalog.length) {
        setIsEdited(true);
        setOpenModalNewCatalog(true);
      } else {
        addNotification("Please select the catalog to edit", "danger");
      }
    } else {
      setAquireLicenseType(ALL_PERMISSIONS.PRICING_DATABASE_EDIT);
      setOpenLicenseModal(true);
    }
  };

  const handleDeleteCatalog = () => {
    if (FinalPermissions?.includes("pricingDatabaseDelete")) {
      if (!selectedCatalog.length) {
        addNotification("Please select catalogs to delete", "danger");
        return;
      }
      setOpenDeleteModal(true);
    } else {
      setAquireLicenseType(ALL_PERMISSIONS.PRICING_DATABASE_DELETE);
      setOpenLicenseModal(true);
    }
  };

  return (
    <div className="d-flex flex-row justify-center gap-2 px-4 bg-white">
      <div
        className="d-flex flex-column p-3"
        style={{
          border: "1px solid #E2E2E2",
          borderRadius: "9px",
          width: "70%",
        }}
      >
        <div className="d-flex flex-row gap-3">
          {/* Template dropdown */}
          <MDBDropdown
            dropend
            group
            className="w-100 dropdownList position-relative"
          >
            <span className="position-absolute bottom-2 lable">Template</span>

            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between`}
            >
              <div className="d-flex align-items-center gap-2">
                <DocumentIcon />

                <span className="dropdownItems">
                  {(
                    selectedTemplate?.company ??
                    selectedTemplate?.average ??
                    selectedTemplate?.default
                  )?.Code || "Templates"}
                </span>
              </div>

              <div>
                <DownArrowIcon />
              </div>
            </MDBDropdownToggle>
            <MDBDropdownMenu className="w-100 border-none" responsive="end">
              <div className="px-2">
                <input
                  onChange={(e) => {
                    const filteredTemplates = allTemplates.filter((template) =>
                      (
                        template?.company ??
                        template?.average ??
                        template?.default
                      ).Code.toLowerCase().includes(
                        e.target.value.toLowerCase()
                      )
                    );
                    setAllTemplateList(filteredTemplates);
                  }}
                  name="key"
                  type="text"
                  className="form-control p-2"
                  placeholder="Search Template"
                />
              </div>
              <div className="list">
                {(templateList || [])?.map((item, key) => (
                  <MDBDropdownItem
                    className="optionItems"
                    onClick={() => {
                      onChangeTemplate(item, false, true);
                    }}
                    key={`${
                      (item?.company ?? item?.average ?? item?.default)?._id
                    }_filter_temp_${key}`}
                    title={
                      (item?.company ?? item?.average ?? item?.default)?.Code
                    }
                  >
                    {(item?.company ?? item?.average ?? item?.default)?.Code}
                  </MDBDropdownItem>
                ))}
              </div>
            </MDBDropdownMenu>
          </MDBDropdown>

          {/* country dropdown */}
          <MDBDropdown dropend group className="w-100 dropdownList">
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between disabled`}
            >
              <div className="d-flex align-items-center gap-2">
                <CheckInIcon />
                <span className="dropdownItems">
                  {country?.name || "Country"}{" "}
                </span>
              </div>
            </MDBDropdownToggle>
          </MDBDropdown>

          {/* state dropdown */}
          <MDBDropdown dropend group className="w-100 dropdownList">
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between disabled`}
            >
              <div className="d-flex align-items-center gap-2">
                <CheckInIcon />
                <span className="dropdownItems ">
                  {countryState?.name || "State"}
                </span>
              </div>
            </MDBDropdownToggle>
          </MDBDropdown>

          {/* city dropdown */}
          <MDBDropdown dropend group className="w-100 dropdownList">
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between disabled`}
            >
              <div className="d-flex align-items-center gap-2">
                <CheckInIcon />
                <span className="dropdownItems">{city || "City"}</span>
              </div>
            </MDBDropdownToggle>
          </MDBDropdown>

          {/* Labor type dropdown */}
          <MDBDropdown dropend group className="w-100 dropdownList">
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between disabled`}
            >
              <div className="d-flex align-items-center gap-2">
                <ProfileIcon />
                <span className="dropdownItems">
                  {selectedTemplate?.company?.LaborType === "null" ||
                  !selectedTemplate?.company?.LaborType
                    ? "Labor type"
                    : selectedTemplate?.company?.LaborType}
                </span>
              </div>
            </MDBDropdownToggle>
          </MDBDropdown>

          {/* Project type dropdown */}
          <MDBDropdown dropend group className="w-100 dropdownList">
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between disabled`}
            >
              <div className="d-flex align-items-center gap-2">
                <ProjectIcon />
                <span className="dropdownItems">
                  {selectedTemplate?.company?.ProjectType === "null" ||
                  !selectedTemplate?.company?.ProjectType
                    ? "Project Type"
                    : selectedTemplate?.company?.ProjectType}
                </span>
              </div>
            </MDBDropdownToggle>
          </MDBDropdown>
        </div>

        {/* Breadcrumb */}
        <div className="mt-2">
          <MDBBreadcrumb className="breadcrumb d-flex justify-content-between">
            <div className="d-flex">
              <div className="mr-3">
                <DatabaseIcon color="black" />
              </div>
              {breadcrumbData.map((item, index) => (
                <MDBBreadcrumbItem
                  className="breadcrumb-item"
                  key={item + "_" + index}
                >
                  <span className="cursor-pointer">{item}</span>
                </MDBBreadcrumbItem>
              ))}
            </div>
            <div className="d-flex gap-3 justify-content-center align-items-center">
              <div className="form-switch mt-0">
                <input
                  type="checkbox"
                  className="squared-checkbox"
                  role="switch"
                  name={`Bidlight Toggle`}
                  checked={dataTypes?.includes("bidlight")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTypesOfData((prev) => [...prev, "bidlight"]);
                    } else {
                      setTypesOfData((prev) =>
                        prev.filter((pre) => pre !== "bidlight")
                      );
                    }
                  }}
                />
                <img
                  src={logo}
                  className="bidlight-logo"
                  style={{ marginLeft: "10px" }}
                />
              </div>
              <div
                style={{ border: "1px solid #D0D5DD", height: "20px" }}
              ></div>
              <div className="mt-0">
                <input
                  type="checkbox"
                  role="switch"
                  className="squared-checkbox"
                  name={`oneBuild Toggle`}
                  checked={dataTypes.includes("oneBuild")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTypesOfData((prev) => [...prev, "oneBuild"]);
                    } else {
                      setTypesOfData((prev) =>
                        prev.filter((pre) => pre !== "oneBuild")
                      );
                    }
                  }}
                />
                <img src={onebuildLogo} className="oneBuild-logo" />
              </div>
              <div
                style={{ border: "1px solid #D0D5DD", height: "20px" }}
              ></div>
              <div className="mt-0 d-flex align-items-center">
                <input
                  type="checkbox"
                  role="switch"
                  className="squared-checkbox"
                  name={`craftsman Toggle`}
                  checked={dataTypes.includes("craftsman")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTypesOfData((prev) => [...prev, "craftsman"]);
                    } else {
                      setTypesOfData((prev) =>
                        prev.filter((pre) => pre !== "craftsman")
                      );
                    }
                  }}
                />
                <div
                  style={{
                    backgroundColor: "#3A5765",
                    width: "25px",
                    height: "25px",
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      display: "block",
                      mixBlendMode: "luminosity",
                      width: "25px",
                    }}
                    src={craftsmanLogo}
                  />
                </div>
              </div>
              <div
                style={{ border: "1px solid #D0D5DD", height: "20px" }}
              ></div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleEditCatalog();
                  {
                    eventTrack(
                      "Edit-Catelogue-Action",
                      "Edit-Catelogue",
                      "Delete-Catelogue",
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
              >
                <MDBIcon fas icon="edit" />
              </span>
              <div
                style={{ border: "1px solid #D0D5DD", height: "20px" }}
              ></div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDeleteCatalog();
                  {
                    eventTrack(
                      "Delete-Catelogue-Action",
                      "Delete-Catelogue",
                      "Delete-Catelogue",
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
              >
                <MDBIcon fas icon="trash-alt" />
              </span>
              <div
                style={{ border: "1px solid #D0D5DD", height: "20px" }}
              ></div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleNewCatalogModal();
                  {
                    eventTrack(
                      "New-Catelogue-Action",
                      "New-Catelogue",
                      "New-Catelogue",
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
              >
                <MDBIcon fas icon="plus" />
              </span>
            </div>
          </MDBBreadcrumb>
        </div>
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "30%" }}
      >
        <div
          className="d-flex flex-column gap-3 p-3"
          style={{
            border: "1px solid #E2E2E2",
            borderRadius: "9px",
            width: "100%",
            height: "fit-content",
          }}
        >
          <div className="d-flex flex-row gap-2">
            {/* currency dropdown */}
            <MDBDropdown dropend group className="revitDropdown">
              <MDBDropdownToggle className="pricingDropdown d-flex justify-content-between disabled">
                <div className="d-flex align-items-center gap-2 ">
                  <WalletIcon />
                  <span className="dropdownItems">
                    {selectedTemplate?.company?.Currency || "Currency"}
                  </span>
                </div>
              </MDBDropdownToggle>
            </MDBDropdown>

            {/* units dropdown */}
            <MDBDropdown dropend group className="revitDropdown">
              <MDBDropdownToggle className="pricingDropdown d-flex justify-content-between disabled">
                <div className="dropdowntoggle d-flex align-items-center gap-2">
                  <RullerIcon />
                  <span className="dropdownItems">
                    {selectedTemplate?.company?.Measurements || "Units"}
                  </span>
                </div>
              </MDBDropdownToggle>
            </MDBDropdown>

            {/* formats dropdown */}
            <MDBDropdown dropend group className="revitDropdown">
              <MDBDropdownToggle className="pricingDropdown d-flex justify-content-between disabled">
                <div className="dropdowntoggle d-flex align-items-center gap-2">
                  <UserLocationIcon />
                  <span className="dropdownItems">
                    {selectedTemplate?.company?.Protocol || "Formats"}
                  </span>
                </div>
              </MDBDropdownToggle>
            </MDBDropdown>
          </div>
          <div className="d-flex flex-row gap-2">
            {/* Add New Template */}
            <MDBDropdown className="border-none catalogbtn ">
              <MDBDropdownToggle
                className={`bg-bid d-flex flex-1 flex-row justify-content-between ${
                  !selectedTemplate && "disabled"
                }`}
                onClick={() => setAddNewTemplate(true)}
              >
                <div className="d-flex gap-2 white-space-pre">
                  <DatabaseIcon />
                  {"New Template"}
                </div>
              </MDBDropdownToggle>
            </MDBDropdown>

            {/* Save button */}
            <SaveOptionList
              isCatalogsUploaded={isCatalogsUploaded}
              handleUpdateCatalog={handleUpdateCatalog}
              handleCreateNewCatalogByFile={handleCreateNewCatalogByFile}
              selectedTemplate={selectedTemplate}
              appliedTemplate={appliedTemplate}
              selectedCatalog={selectedCatalog}
              conversionRate={conversionRate}
              permissions={FinalPermissions}
              requestAcquireLicense={setOpenLicenseModal}
              giveAcquireLicenseType={setAquireLicenseType}
              setLoadingState={setIsLoading}
            />

            {/* Upload button */}
            <UploadOptionList
              setLoadingState={setIsLoading}
              setSearchedCatalogs={setSearchedCatalogs}
              template={selectedTemplate}
              conversionRate={conversionRate}
              admin={admin}
              getIsCatalogUploaded={setIsCatalogsUploaded}
              handleLoadFromDatabase={onChangeTemplate}
            />
          </div>
        </div>
      </div>
      {addNewTemplate ? (
        <AddNewVersionModal
          closeModal={() => {
            setIsEdited(false);
            setAddNewTemplate(false);
          }}
          OrganizationId={company}
          setIsLoading={setIsLoading}
          handleSelectTemplate={(newTemplate) =>
            onChangeTemplate(newTemplate, true)
          }
          selectedCompany={selectedCompany}
        />
      ) : null}
      {openModalNewCatalog ? (
        <NewCatalogModal
          closeModal={() => setOpenModalNewCatalog(false)}
          appliedTemplate={appliedTemplate}
          newDivision={newDivision}
          handleNewCatalogModal={handleNewCatalogModal}
          conversionRate={conversionRate}
          parentCatalog={selectedCatalog[selectedCatalog.length - 1]}
          isEdited={isEdited}
          setIsLoading={setIsLoading}
          onChangeTemplate={onChangeTemplate}
          selectedCompany={selectedCompany}
          company={company}
        />
      ) : null}
      {openDeleteModal ? (
        <DeleteCatalogModal
          isDelete={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          catalogsToDelete={selectedCatalog}
          protocol={APPLIED_FORMAT}
          getUpdatedData={() => onChangeTemplate(appliedTemplate)}
        />
      ) : null}
      {openLicenseModal ? (
        <AcquireLicenseModal
          requestModal={openLicenseModal}
          closeModal={() => setOpenLicenseModal(false)}
          requestType={acquireLicenseType}
        />
      ) : null}
    </div>
  );
};
