import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

const Thankyou = () => {
  const intervalRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (Object.keys(location).length && location.search) {
      const queryParams = location?.search?.split("?")[1]?.split("=");
      setStatus(queryParams[1] === "false" ? false : true);
      intervalRef.current = setInterval(() => {
        queryParams[1] === "false"
          ? history.push("/")
          : history.push("/authorization");
      }, 1000);
    } else {
      intervalRef.current = setInterval(() => {
        history.push("/authorization");
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [location]);

  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex text-center flex-column justify-content-center"
    >
      {status ? (
        <>
          <h1 className="display-1 text-success">Payment Successful</h1>
          <p className="text-muted">Kindly check your inbox for your licence</p>
        </>
      ) : (
        <>
          <h1 className="display-1 text-danger">Payment Failed</h1>
          <p className="text-muted">Kindly retry after some time.</p>
        </>
      )}
    </div>
  );
};

export default Thankyou;
