import React, { useState } from "react";
import { RequestCard } from "pages/configuration/requestCard";
import { Modal } from "antd";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setFeatureRequest } from "actions/user";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";

export const AcquireLicenseModal = ({
  requestModal,
  closeModal,
  requestType,
}) => {
  const [requestLoading, setRequestLoading] = useState(false);
  const [featureRequests, setFeatureRequests] = useState([]);
  const dispatch = useDispatch();

  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const requestAccess = async () => {
    try {
      setRequestLoading(true);
      const res = await createFeatureRequest(userInfoRedux.id, {
        companyId: userInfoRedux.company,
        permission: requestType,
        userId: userInfoRedux.id,
      });
      if (res) {
        setRequestLoading(false);
        dispatch(
          setFeatureRequest(res.map((feature) => feature.requestedFeature))
        );
        setFeatureRequests(res.map((feature) => feature.requestedFeature));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={closeModal}
      >
        <RequestCard
          status={featureRequests?.includes(requestType)}
          loading={requestLoading}
          icon={
            featureRequests?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={`Oops, looks like you need to ${
            userInfoRedux?.companyAdmin ? `Acquire License` : `Request Access`
          }`}
          description={
            featureRequests?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </div>
  );
};
