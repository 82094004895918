import React from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { EllipsesIcon, SaveIcon } from "assets/svg-icons";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { eventTrack } from "helpers/ga4Helper";

export const SaveOptionList = ({
  isCatalogsUploaded,
  handleCreateNewCatalogByFile,
  handleUpdateCatalog,
  selectedTemplate,
  appliedTemplate,
  selectedCatalog,
  permissions,
  requestAcquireLicense,
  giveAcquireLicenseType,
  setLoadingState,
  conversionRate
}) => {
  const catalogsTree = useSelector((s) => s.user.catalogsTree);
  const catalogs = useSelector((state) => state.user.catalogs);
  const allCategoryData = useSelector((s) => s.pricing.allCategories);

  const handleLoadDataToDatabase = () => {
    if (permissions?.includes("pricingDatabaseEdit")) {
      if (selectedCatalog?.length > 0) {
        handleUpdateCatalog();
      } else {
        handleCreateNewCatalogByFile();
      }
    } else {
      giveAcquireLicenseType(ALL_PERMISSIONS.PRICING_DATABASE_EDIT);
      requestAcquireLicense(true);
    }
  };

  const fileData = [];

  const setFileData = (catalogs) => {
    catalogs.forEach((cat) => {
      fileData.push(cat);
      if (cat.Children.length) {
        setFileData(cat.Children);
      }
    });
  };

  const findAllParentsOfSelectedItems = (selectedItems) => {
    const allParents = [];
    for (const item of selectedItems) {
      let parent = item?.Parent;

      while (parent) {
        allParents.push(parent);
        parent = parent.Parent;
      }
    }
    return allParents;
  };

  let allMatchingItems = [];

  const searchInRow = (item) => {
    const allSelectedItems = selectedCatalog.map((item) => item?._id);
    const catalogsItems = allSelectedItems;
    if (catalogsItems.includes(item?._id))
      allMatchingItems = [...allMatchingItems, item];
    if (item.Children.length) {
      item.Children.forEach((x) => searchInRow(x));
    }
    return allMatchingItems;
  };

  const getSelectedCatalogsRows = () => {
    if (catalogsTree?.length) {
      const searchedCatalogRows =
        catalogsTree?.map((row) => searchInRow(row)).flat(1) ?? [];
      const parentIds = findAllParentsOfSelectedItems(searchedCatalogRows).map(
        (x) => x._id
      );
      const matchedItems = [
        ...searchedCatalogRows.map((x) => x._id),
        ...parentIds,
      ];
      const uniqueItemIds = [...new Set(matchedItems)];
      const matchedCatalogs =
        catalogs?.filter((catalog) =>
          uniqueItemIds?.includes(catalog?.company?._id)
        ) ?? [];
      return matchedCatalogs;
    }
  };

  const handleDownloadCSVFile = async () => {
    setLoadingState("table-data");
    setFileData(catalogsTree);
    const selectedRowsData = getSelectedCatalogsRows();
    const processedData = getFormattedData(
      selectedCatalog.length > 0 ? selectedRowsData : fileData
    );
    const csvContent = convertToCSV(processedData);

    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute(
      "download",
      `${appliedTemplate?.company?.Code}_${appliedTemplate?.company?.Protocol}`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setLoadingState("");
  };

  const handleDownloadExcel = async () => {
    setLoadingState("table-data");
    setFileData(catalogsTree);
    const selectedRowsData = getSelectedCatalogsRows();
    const processedData = getFormattedData(
      selectedCatalog.length > 0 ? selectedRowsData : fileData
    );
    const worksheet = XLSX.utils.json_to_sheet(processedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const anchor = document.createElement("a");
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = `${appliedTemplate?.company?.Code}_${appliedTemplate?.company?.Protocol}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(anchor.href);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setLoadingState("");
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csv = [
      headers.join(","),
      ...data.map((obj) =>
        headers
          .map((header) => {
            const value = obj[header];
            return typeof value === "string" && value.includes(",")
              ? `"${value}"`
              : value;
          })
          .join(",")
      ),
    ].join("\n");

    return csv;
  };

  const getFormattedData = (data) => {
    let processedData = data.map((item) => {
      const row = selectedCatalog.length > 0 ? item.company : item;
      const categoryInRow = allCategoryData.find(
        (category) =>
          category?.company?.Key === row.CategoryId[row.CategoryId.length - 1]
      );
      const code = selectedCatalog.length > 0 ? row?.Code : row.Division;
      const unitCost = row?.unitCost ?? row.UnitCost;
      return {
        Level: row?.Level,
        Code: addSymbolsToLevel(row?.Level, code),
        Description: addSymbolsToLevel(row?.Level, row?.Description),
        UnitCost: (unitCost * conversionRate),
        Unit: row?.Unit ?? "--",
        Category: categoryInRow ? categoryInRow?.company?.Name : "--",
        Awp: row?.Awp?.length ? row?.Awp : "--",
      };
    });
    return processedData;
  };

  const addSymbolsToLevel = (level, label) => {
    let symbols = "";
    if (level > 1) {
      symbols = "|";
      for (let i = 2; i < level; i++) {
        symbols += "|";
      }
      return symbols + `_${label}`;
    } else {
      return label;
    }
  };
  return (
    <MDBDropdown className="border-none catalogbtn">
      <MDBDropdownToggle
        className={`bg-bid d-flex flex-row justify-content-between ${
          !selectedTemplate && "disabled"
        }`}
      >
        <div className="d-flex gap-2">
          <SaveIcon />
          {"Save"}
        </div>

        <EllipsesIcon />
      </MDBDropdownToggle>
      <MDBDropdownMenu className="w-100 border-none" responsive="end">
        <MDBDropdownItem
          onClick={() => { handleLoadDataToDatabase()
            {
              eventTrack(
                "Load-Database-Catelogue-Action",
                "Load-Database-Catelogue",
                'load-database',
                "+1",
                false,
                "+1"
              );
            }
           }}
          disabled={!isCatalogsUploaded && selectedCatalog.length === 0}
        >
          {"Save to Data-base"}
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => { handleDownloadExcel()
                                      {
                                        eventTrack(
                                          "DownloadExcel-Catelogues-Action",
                                          "DownloadExcel-Catelogues",
                                          'Excel-Catelogues',
                                          "+1",
                                          false,
                                          "+1"
                                        );
                                      }
        }}>
          {"Save to Excel"}
        </MDBDropdownItem>
        <MDBDropdownItem onClick={() => { handleDownloadCSVFile()
                                      {
                                        eventTrack(
                                          "Download-Catelogues-CSV-Action",
                                          "Download-Catelogues-CSV",
                                          'Catelogues',
                                          "+1",
                                          false,
                                          "+1"
                                        );
                                      }
        }}>
          {"Save to CSV"}
        </MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};
