import React, { useState } from "react";
import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { useSelector } from "react-redux";

import Loading from "../../components/ui-components/loading";
import { useHistory } from "react-router-dom";
import { choseToggle } from "helpers/toggleIfLoading";
import addNotification from "helpers/notify";
import { inviteUserByEmail } from "api/users/inviteUser";
import { useHttp } from "hooks/useHttp";
import { Maybe } from "helpers/maybeFunctor";
import GA4 from "../../components/GA4";
import { options, reset } from "numeral";
import { tokenRef, url } from "api";
import axios from "axios";
import { inviteUserByCsv } from "api/users/csvInvite";
import { useForm } from "react-hook-form";

const styles = {
  fontWeight: "bold",
  color: "white",
  backgroundColor: "#3a5765",
  fontSize: 14,
  width: "100%",
  height: "40px",
  borderRadius: "5px",
  padding: "10px",
};

const toolTipStyle = {
  border: "5px solid transparent !important",
  color: "#3a5765",
  fontSize: 16,
  fontWeight: "Bold",
  font: "poppins-Bold",
  padding: "20px",
  width: "100%",
  textIndent: "initial",
  borderSpacing: "2px",
};

//here
const AddUserToCompanyModal = (props) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("DefaultUser");
  const [roleTip, setRoleTip] = useState(
    "Can access basic settings in all environments!"
  );

  const [rate, setRate] = useState("Hourly");
  const [rateValue, setRateValue] = useState("");

  const [bulkloading, setBulkLoad] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const {
    register,
    formState: { errors },
    trigger,
  } = useForm({ mode: "onChange" });

  // {roles?.map((item) => (
  //   <option value={item?.label} className="capitalize">{item?.label}</option>
  //   ))}
  // Using state to keep track of what the selected fruit is

  // Using this function to update the state of fruit
  // whenever a new option is selected from the dropdown
  let handleRoleChange = (e) => {
    var r = JSON.parse(e.target.value);
    setRole(r.value);
    setRoleTip(r.toolTip);
  };

  let handleRateChange = (e) => {
    var r = JSON.parse(e.target.value);
    setRate(r.value);
  };

  // const adminUsersRedux = JSON.parse(localStorage.getItem("adminUsersRedux"));
  const adminUsersRedux = useSelector((s) => s.admin.users);
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  // const userInfoRedux = useSelector((s) => s.user.userInfo);

  // console.log(userInfoRedux)

  const admin = Maybe.of(adminUsersRedux).map((users) =>
    users.find((user) => user.companyAdmin)
  ).value;

  // console.log("admin-------",admin.company)

  const toggle = () => {
    setEmail("");
    setRateValue("")
    props.toggle();
  };

  const roles = [
    {
      label: "Admin",
      value: "Admin",
      toolTip: "Can access advanced settings in all environments!",
    }, //'Can access advanced functionality in all systems'
    {
      label: "DefaultUser",
      value: "DefaultUser",
      toolTip: "Can access basic settings in all environments!",
    }, //'Can access basic functionality in all systems'
    {
      label: "Estimator",
      value: "Estimator",
      toolTip: "Can access estimation settings in desktop environment!",
    }, //'Can access estimation functionality in desktop env'
    {
      label: "Engineer",
      value: "Engineer",
      toolTip: "Can access data export settings in Revit environment!",
    }, //'Can access data export functionality in Revit env'
    {
      label: "Designer",
      value: "Designer",
      toolTip: "Can access data export settings in Revit environment!",
    }, //'Can access data export functionality in Revit env'
    {
      label: "Const_Manager",
      value: "Const_Manager",
      toolTip: "Can access basic settings in web environment!",
    },
  ]; //'Can access cloud functionality in Web env'

  const rates = [
    {
      label: "Hourly",
      value: "Hourly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Yearly",
      value: "Yearly",
    },
  ];

  const history = useHistory();
  const { loading, request: inviteUserAPI } = useHttp({
    requestCallback: inviteUserByEmail,
    onLoad: (res) => {
      toggle();
      addNotification(
        "An email has been sent to your inbox, please allow 5 minutes before checking! ",
        "success"
      );
    },
    onError: (res) => {
      toggle();
      addNotification(res?.err, "danger");
    },
  });

  const submitNewUserToCompany = (e) => {
    // e.preventDefault();
    if (selectedFile) {
      handleUploadCsv();
    } else {
      return inviteUserAPI({
        mail: email,
        owner: userInfoRedux.id,
        role: role,
        rate: rate,
        rateValue: rateValue,
      });
    }
  };

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadCsv = async () => {
    setBulkLoad(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("owner", userInfoRedux.id);

      // const res= await inviteUserByCsv(formData)
      const token = `Bearer ${localStorage.getItem(tokenRef)}`;
      const res = await axios.post(`${url}/invites/csv`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data", // Make sure to set the content type for FormData
        },
      });
      console.log("res-------", res);
      setSelectedFile(null);
      setTimeout(() => {
        setBulkLoad(false);
        toggle();
        if (res?.status == 200) {
          addNotification(
            "An email has been sent to your inbox, please allow 5 minutes before checking! ",
            "success"
          );
        } else {
          toggle();
          addNotification(
            "Something went wrong, please contact support",
            "danger"
          );
        }
      }, 5000);

      console.log(res);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={choseToggle(loading, toggle)}
      centered
      className="create-modal"
    >
      <MDBModalHeader toggle={choseToggle(loading, toggle)}>
        Add user to company
      </MDBModalHeader>
      <MDBModalBody>
        <form
          // onSubmit={ submitNewUserToCompany}
          style={{ height: "300px", minHeight: "200px" }}
          id="adduseradminfrmid"
        >
          <div className="form-group-div">
            <div>
              <label className="inter pl-2">
                Enter email of user <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                value={email}
                // onChange={(e) => setEmail(e.target.value)}
                {...register("email", {
                  required: "Email is required",
                  onChange: (e) => setEmail(e.target.value),
                })}
              />
              {errors.email && (
                <p role="alert" className="validation-error-msg mb-0">
                  {errors.email.message}
                </p>
              )}
            </div>

            <select onChange={handleRoleChange} className="mt-3">
              {roles.map((role, index) =>
                role.value == "DefaultUser" ? (
                  <option
                    defaultValue
                    value={JSON.stringify(roles[index])}
                    key={index}
                  >
                    {role.label}
                  </option>
                ) : (
                  <option value={JSON.stringify(roles[index])} key={index}>
                    {role.label}
                  </option>
                )
              )}
              required
            </select>

            <label className="inter pl-2 mt-4">
              Estimated Salary of the user{" "}
              <span className="text-danger">*</span>{" "}
            </label>
            <div className="d-flex gap-3">
              <select onChange={handleRateChange} className="">
                {rates.map((rate, index) => (
                  <option value={JSON.stringify(rates[index])} key={index}>
                    {rate.label}
                  </option>
                ))}
                required
              </select>

              <div className="position-relative">
                <span className="currency-prefix">$</span>
                <input
                  type="number"
                  value={rateValue}
                  {...register("rateValue", {
                    required: "Rate is required",
                    onChange: (e) => setRateValue(e.target.value),
                  })}
                  className="pl-4"
                />
              </div>
            </div>
            {errors.rateValue && (
              <p role="alert" className="validation-error-msg">
                {errors.rateValue.message}
              </p>
            )}
          </div>
          {/* <MDBInput
            value={email}
            size="sm"
            type="email"
            onChange={e => setEmail(e.target.value)}
            label="Enter email of user"
            className='mdb-input'
            //required
          /> */}

          {/* <input style={{marginTop:"20px", marginBottom:"20px"}} type='file' accept=".csv" onChange={handleFile}/> */}

          <label style={toolTipStyle}>
            <span style={{ position: "absolute", bottom: "18%", left: "5%" }}>
              <b>
                <strong>Tip:</strong>
              </b>{" "}
              {roleTip}
            </span>
          </label>
        </form>

        <MDBBtn
          size="md"
          type="submit"
          color=""
          className="w-100 bg-bid m-auto text-white radius-2 m-0"
          disabled={loading || bulkloading}
          onClick={() => {
            email?.length === 0
              ? trigger(["email"])
              : rateValue?.length === 0
              ? trigger(["rateValue"])
              : submitNewUserToCompany();
          }}
        >
          Invite User
        </MDBBtn>
        {loading || bulkloading ? (
          <Loading color="black" text="Loading..." />
        ) : null}
        <GA4
          userId={email}
          category={"Management"}
          action={"AddUser-Action"}
          label={history.location.pathname}
          value={email}
          nonInteraction={false}
          transport={"xhr"}
        ></GA4>
      </MDBModalBody>
    </MDBModal>
  );
};

export default AddUserToCompanyModal;
