import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Redirect, useParams } from "react-router";

import Loading from "components/ui-components/loading";

import { useHttp } from "hooks/useHttp";

import { Maybe } from "helpers/maybeFunctor";
import formatTime from "helpers/formatTime";
import {
  addPermissionIsUser,
  setAllPermission,
  setCompanyPermission,
  setSidebarStatus,
} from "actions/user";

import { getCompanyPermissionByID } from "api/permission/getPermissions";
import {
  copyUserPermissionFromRole,
  editUserPermission,
  editBulkPermission,
} from "api/permission/editPermissions";
import addNotification from "helpers/notify";
import Loader from "components/Loader";
import { all_permissionsRef, company_permissionsRef, user_permissionsRef } from "api";

export default function MainAdminPermissions() {
  const { idCompany } = useParams();
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);

  const [inputSearchValue, setInputSearchValue] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { loading: initialLoading, request: getCompanyPermissionAPI } = useHttp(
    {
      requestCallback: (data) =>
        getCompanyPermissionByID({
          userId: userInfoRedux?.id,
          companyId: idCompany,
        }),
      onLoad: (res) => {
        // setting self permissions to local storage
        if (idCompany === userInfoRedux?.company) {
          console.log("self");
          localStorage.setItem(user_permissionsRef, res?.user_permissions);
          localStorage.setItem(company_permissionsRef, res?.company_permissions);
          localStorage.setItem(all_permissionsRef, res?.all_permissions);
          //
          dispatch(addPermissionIsUser(res?.user_permissions));
          dispatch(setCompanyPermission(res?.company_permissions));
          dispatch(setAllPermission(res?.all_permissions));
        }
        const data = allPermissionRedux?.map((permission) => {
          return {
            permission,
            checked: res?.company_permissions?.includes(permission),
          };
        });
        setPermissions(data);
        setRolePermissions(
          res?.company_permissions ? res?.company_permissions : []
        );
      },
      onError: (res) => {},
    }
  );

  useEffect(() => {
    getCompanyPermissionAPI();
  }, []);

  const handleCheckBox = async (e) => {
    const data = permissions?.map((permission) => {
      if (permission.permission === e.target.value) {
        return {
          ...permission,
          checked: e.target.checked,
        };
      } else {
        return permission;
      }
    });
    let permissionData = [];
    if (e.target.checked) {
      permissionData = [...rolePermissions, e.target.value];
    } else {
      permissionData = rolePermissions?.filter((rp) => rp !== e.target.value);
    }
    setRolePermissions(permissionData);
    setPermissions(data);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const res = await editUserPermission(idCompany, {
        permissions: rolePermissions,
        entityId: idCompany,
        entityType: "company",
      });
      if (res) {
        setLoading(false);
        addNotification("Permission Updated Successfully", "success");
        getCompanyPermissionAPI();
      }
    } catch (err) {
      console.log("err-------", err);
    }
  };

  return (
    <div className="personal-area">
      {loading || (initialLoading && <Loader />)}

      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
            <Link
              to={"/admin/projects-settings"}
              className="my-heading f-14 ml-3 mr-3 headerLink"
            >
              Admin
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>
            <Link
              to={"/main-admin"}
              className="my-heading f-14 ml-3 mr-3 headerLink"
            >
              All Companies
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>
            <p className="my-heading f-14 ml-3 mr-3">{}</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <input
              type="text"
              className="search-bar"
              value={inputSearchValue}
              onChange={(e) => setInputSearchValue(e.target.value)}
              placeholder="Search"
              // disabled={loading}
            />
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              <button
                onClick={() => getCompanyPermissionAPI(idCompany)}
                className="px-3 bg-white text-bid shadow-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="content-bg p-2">
        <div className="w-100 rounded-2 border bg-white mb-3 container">
          <div className="d-flex align-items-center justify-content-between py-2 px-2">
            <div className="d-flex align-items-center">
              <i className="fa fa-lock mr-3" />
              <p className="f-16 text-bid mb-0 poppins-medium">
                Company Permissions
              </p>
            </div>
            <div className="d-flex">
              <button
                className="btn-header bg-bid text-white border-bid py-1 px-3"
                onClick={() => handleSave()}
              >
                <i className="fa fa-save mr-2"></i>
                Save
              </button>
              {/* <button className="btn-header bg-white text-bid border-bid py-1 px-3">
                  <i className="fa fa-times mr-2"></i>
                  Cancel
                </button> */}
            </div>
          </div>
        </div>

        <div className="container mt-1 bg-white p-0 rounded-3 p-2 py-0 border">
          <div className="grid-two-sets">
            {permissions?.map((item, key) => (
              <div className="capitalize d-flex" key={key}>
                <input
                  className="squared-checkbox mr-3"
                  value={item?.permission}
                  onChange={(e) => handleCheckBox(e)}
                  type="checkbox"
                  defaultChecked={item?.checked}
                />
                {item?.permission?.split(/(?=[A-Z])/).join(" ")}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
