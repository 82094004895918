import React, { useState } from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBListGroupItem,
  MDBListGroup,
  MDBBtn,
  MDBBox,
  MDBBtnGroup,
} from "mdbreact";

import Loading from "../ui-components/loading";

import { assignUserToProject } from "api/users/addUserToProject";
import addNotification from "helpers/notify";
import { choseToggle } from "helpers/toggleIfLoading";
import { removeUserFromProject } from "api/users/removeUserFromProject";

import {
  addUserToProject as addUserToProjectRedux,
  removeUserFromProject as removeUserFromProjectRedux,
} from "actions/user";
import {
  adminAddUserToProject,
  adminRemoveUserFromProject,
} from "actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { useSearch } from "../../hooks/useSearch";
import { useHttp } from "../../hooks/useHttp";
import { Maybe } from "../../helpers/maybeFunctor";

import "./members-modal.scss";

const MembersModalAdmin = (props) => {
  const { projectListToEdit } = props;

  const usersRedux = useSelector((s) => s.admin.users);
  const projectsRedux = useSelector((s) => s.admin.projects);
  const emailRedux = useSelector((s) => s.user.userInfo.email);

  const userProjectsRedux = useSelector((s) => s.user.projects);
  const usersPARedux = useSelector((s) => s.user.users);

  const dispatch = useDispatch();
  const search = useSearch();

  const [searchValue, setSearchValue] = useState("");
  const [isAddPage, setIsAddPage] = useState(true);
  const [chosenUserForDelete, setChosenUserForDelete] = useState(null);
  const [chosenUserForAdd, setChosenUserForAdd] = useState(null);
  const [btn, setBtn] = useState("Pending");

  const toggle = () => {
    props.toggle();
    setChosenUserForDelete(null);
    setChosenUserForAdd(null);
  };

  const {
    loading: assignUserToProjectAPILoading,
    request: assignUserToProjectAPI,
  } = useHttp({
    requestCallback: assignUserToProject,
    onLoad: (res) => {
      dispatch(adminAddUserToProject(chosenUserForAdd, projectListToEdit));
      const canAssignToPA =
        userProjectsRedux &&
        userProjectsRedux
          .map((project) => project._id)
          ?.includes(chosenUserForAdd) &&
        usersPARedux &&
        usersPARedux.map((user) => user._id)?.includes(projectListToEdit);
      if (canAssignToPA) {
        dispatch(addUserToProjectRedux(chosenUserForAdd, projectListToEdit));
      }
      setChosenUserForAdd(null);
      console.log(res);
      addNotification(
        "User was successfully added to this project!",
        "success"
      );
    },
    onError: (res) => {
      setChosenUserForAdd(null);
      addNotification("Something went wrong, please contact support", "danger");
      console.log(res);
    },
  });

  const addUserToProject = () => {
    if (!chosenUserForAdd) {
      addNotification(
        "Choose the user you want to add to this project",
        "default"
      );
    } else {
      return assignUserToProjectAPI({
        project_id: projectListToEdit,
        user_id: chosenUserForAdd,
      });
    }
  };

  const {
    loading: deleteUserFromProjectLoading,
    request: removeUserFromProjectAPI,
  } = useHttp({
    requestCallback: removeUserFromProject,
    onLoad: (res) => {
      const canAssignToPA =
        userProjectsRedux &&
        userProjectsRedux
          .map((project) => project._id)
          ?.includes(chosenUserForDelete) &&
        usersPARedux &&
        usersPARedux.map((user) => user._id).includes(projectListToEdit);
      if (canAssignToPA) {
        dispatch(
          removeUserFromProjectRedux(chosenUserForDelete, projectListToEdit)
        );
      }
      dispatch(
        adminRemoveUserFromProject(chosenUserForDelete, projectListToEdit)
      );
      setChosenUserForDelete(null);
      console.log(res);
      addNotification(
        "User was successfully removed from this project",
        "success"
      );
    },
    onError: (res) => {
      console.log(res);
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const deleteUserToProject = () => {
    if (!chosenUserForDelete) {
      addNotification(
        "Choose the user you want to delete from this project",
        "default"
      );
    } else {
      return removeUserFromProjectAPI({
        project_id: projectListToEdit,
        user_id: chosenUserForDelete,
      });
    }
  };

  const projectListToEditUsers = Maybe.of(projectsRedux)
    .map((projectsRedux) =>
      projectsRedux.find((projectItem) => projectItem._id === projectListToEdit)
    )
    .map((project) => project.users).value;

  const usersAbleToAdd = Maybe.of(usersRedux)
    .map((users) => {
      if (projectListToEditUsers) {
        return users
          .filter((user) => !projectListToEditUsers?.includes(user._id))
          .filter((user) => user.email !== emailRedux);
      } else {
        return null;
      }
    })
    .map((filteredUsers) => {
      return search(filteredUsers, "email", searchValue);
    })
    .map((searchedUsers) => {
      return searchedUsers.map((user) => {
        const { name, lastName, email, _id } = user;
        return (
          <MDBListGroupItem
            key={_id}
            className="cursor-pointer p-2"
            onClick={() => setChosenUserForAdd(_id)}
            active={chosenUserForAdd === _id}
          >
            <div className="font-size-2">
              Name : {name} {lastName}
            </div>
            <span className="font-size-1">Email : {email}</span>
          </MDBListGroupItem>
        );
      });
    }).value;

  const usersAbleToDelete = Maybe.of(usersRedux)
    .map((users) => {
      if (projectListToEdit && projectListToEditUsers) {
        return users
          .filter((user) => projectListToEditUsers?.includes(user._id))
          .filter((user) => user.email !== emailRedux);
      } else {
        return null;
      }
    })
    .map((filteredUsers) => {
      return search(filteredUsers, "email", searchValue);
    })
    .map((searchedUsers) => {
      return searchedUsers.map((user) => {
        const { name, lastName, email, _id } = user;
        return (
          <MDBListGroupItem
            key={_id}
            className="cursor-pointer p-2"
            onClick={() => setChosenUserForDelete(_id)}
            active={chosenUserForDelete === _id}
          >
            <div className="font-size-2">
              Name : {name} {lastName}
            </div>
            <span className="font-size-1">Email : {email}</span>
          </MDBListGroupItem>
        );
      });
    }).value;

  const loading = deleteUserFromProjectLoading || assignUserToProjectAPILoading;

  return (
    <MDBModal
      toggle={choseToggle(loading, toggle)}
      isOpen={props.open}
      centered
    >
      <MDBModalHeader
        toggle={choseToggle(loading, toggle)}
        className="d-flex mb-0 pb-2"
      >
        Project members
      </MDBModalHeader>
      <MDBModalBody className="pt-1">
        <div className="d-flex align-items-center w-75 mb-3">
          <MDBBtn
            size="sm"
            color=""
            className={
              btn === "Pending"
                ? "sharePending-button active-toggle"
                : "sharePending-button"
            }
            onClick={() => {
              setIsAddPage(true);
              setBtn("Pending")
            }
            }
          >
            Add user
          </MDBBtn>
          <MDBBtn
            size="sm"
            color=""
            className={
              btn === "Shared"
                ? "sharePending-button active-toggle"
                : "sharePending-button"
            }
            onClick={() => {
              setIsAddPage(false);
              setBtn("Shared");
            }}
          >
            Delete user
          </MDBBtn>
        </div>
        {isAddPage ? (
          <>
            <h6 className="mt-2 text-status">Add user menu</h6>
            <div className="form-group mb-2">
              <input
                type="text"
                className="shared-input-search"
                placeholder="Search by email.."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <MDBListGroup className="member-ul">{usersAbleToAdd}</MDBListGroup>
            <div className="d-flex flex-center py-2">
            {loading ? <div className="d-flex flex-center py-2 spinner-box">
                 <Loading color="black" text="Loading..."  /> 
            </div>: null}
            </div>
            <MDBBox className="d-flex justify-content-end">
              <MDBBtn
                size="sm"
                color=""
                className="bg-bid border-bid text-white mdb-btn"
                onClick={addUserToProject}
                disabled={loading}
              >
                <i className="fa fa-plus mr-2"></i> Add this user
              </MDBBtn>
            </MDBBox>
          </>
        ) : (
          <>
            <h6 className="mt-2 text-status">Delete user menu</h6>
            <div className="form-group mb-2">
              <input
                type="text"
                className="shared-input-search"
                placeholder="Search by email.."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <MDBListGroup className="member-ul">
              {usersAbleToDelete}
            </MDBListGroup>
            <div className="d-flex flex-center py-2">
            {loading ? <div className="d-flex flex-center py-2 spinner-box">
                 <Loading color="black" text="Loading..."  /> 
            </div>: null}
            </div>
            <MDBBox className="d-flex justify-content-end">
              <MDBBtn
                size="sm"
                color=""
                className="bg-bid border-bid text-white mdb-btn"
                onClick={deleteUserToProject}
                disabled={loading}
              >
                <i className="fa fa-trash mr-2"></i> Remove this user
              </MDBBtn>
            </MDBBox>
          </>
        )}
      </MDBModalBody>
    </MDBModal>
  );
};

export default MembersModalAdmin;
