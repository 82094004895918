export function mergeDataWithLogicGate(filters, projects = []) {
  let allNodes = [];
  const categorizedFiltersByProjectId = filters?.filter(
    (item, index, self) => index === self.findIndex((i) => i.id === item.id)
  );
  let response = null;
  for (let project of projects) {
    const isFilterApplied = categorizedFiltersByProjectId.some(
      (item) => item.id === project.id
    );
    if (isFilterApplied) {
      const appliedFilter = filters.filter((item) => item.id === project.id);
      const categorizedFiltersByGate = appliedFilter?.filter(
        (item, index, self) =>
          index === self.findIndex((i) => i.gate === item.gate)
      );
      let gateResponses = [];
      for (let gateItem of categorizedFiltersByGate) {
        let gateFilterResponse = null;
        const filtersByGate = appliedFilter.filter((f) => f.gate === gateItem.gate);
        switch (gateItem.gate) {
          case "AND":
          case "XNOR":
            gateFilterResponse = applyIntersectionRule(
              filtersByGate,
              project,
              gateItem.gate
            );
            break;
          case "NOT":
          case "NOR":
          case "NAND":
          case "XOR":
          case "OR":
            gateFilterResponse = applyUnionRule(
              filtersByGate,
              project,
              gateItem.gate
            );
            break;
        }
        gateResponses = [...gateResponses, gateFilterResponse];
      }
      response = getCommonResultToHideNodes(gateResponses);
    } else {
      response = makeProjectHide(project);
    }
    if (response) {
      allNodes = [...allNodes, { response, projects: [project] }];
    }
  }
  console.log("allNodes**", allNodes);
  return allNodes;
}

const getCommonResultToHideNodes = (gateFiltersResponse) => {
  const allFilterResult = gateFiltersResponse.flatMap((item) => item);
  const unionAppliedFilterResult = allFilterResult?.filter(
    (item, index, self) =>
      index === self.findIndex((i) => i?.label === item?.label)
  );

  let response = unionAppliedFilterResult.map((f) => {
    gateFiltersResponse.map((filters) => {
      const isMatched = filters.some(
        (item) => item?.label === f?.label
      );
      if (isMatched) {
        f.treeVisible = false;
      } else {
        f.treeVisible = true;
      }
      return;
    });
    return f;
  });
  response = response.filter((item) => item.treeVisible === false);
  return response;
};

const makeProjectHide = (projectToHide) => {
  function makeNodeHide(tree) {
    let nodesToHide = [];
    tree.treeVisible = false;
    if (!tree.meshInfo) {
      nodesToHide = [...nodesToHide, tree];
    }
    tree.nodes.forEach((item) => makeNodeHide(item));
    return nodesToHide;
  }
  const response = makeNodeHide(projectToHide);
  return response;
};

const getMeshInfo = (rule) => {
  let meshInfo;
  if (rule.meshInfo) {
    meshInfo = rule.meshInfo;
  } else {
    rule.nodes.forEach((m) => {
      if (m) {
        // getMeshInfo(m);
      }
    });
  }
  return meshInfo;
};

const evaluateLogic = (meshInfo, logicValue, input) => {
  switch (logicValue) {
    case "==":
      return meshInfo == input;
    case "!=":
      return meshInfo != input;
    case "<":
      return meshInfo < input;
    case ">":
      return meshInfo > input;
    case "hasValue":
      return meshInfo != undefined && meshInfo != null;
    case "none":
      return meshInfo == undefined || meshInfo == null;
    default:
      return false;
  }
};

const getChildNodes = (gate, projectFileNodes, newRuleNodes, compareType) => {
  let filteredNodes = projectFileNodes;
  switch (gate) {
    case "OR":
      // if (compareType === "parameters") {
      //   let filteredComponents = [];
      //   projectFileNodes.forEach((node) => {
      //     let nodeTreeVisible = false;
      //     newRuleNodes.forEach((component) => {
      //       component.logic.map((logic) => {
      //         const evaluationResult = evaluateLogic(
      //           node.meshInfo[component.label],
      //           +logic?.value,
      //           component.input
      //         );
      //         nodeTreeVisible = nodeTreeVisible || evaluationResult;
      //         // if (filteredComponents.some((f) => f.id !== node.id)) {
      //         //   filteredComponents = [
      //         //     ...filteredComponents,
      //         //     { ...node, treeVisible: evaluationResult },
      //         //   ];
      //         // }
      //       });
      //     });
      //     filteredComponents.push({
      //       ...node,
      //       treeVisible: nodeTreeVisible,
      //     });
      //   });
      //   filteredNodes = filteredComponents;
      // } else {
      filteredNodes = projectFileNodes.map((item) => {
        const doesItemExist = newRuleNodes.some((i) => i.label === item.label);
        return { ...item, treeVisible: doesItemExist };
      });
      // }
      break;
    case "AND":
    case "XNOR":
      // if (compareType === "parameters") {
      //   let filteredComponents = [];

      //   projectFileNodes.forEach((node) => {
      //     let nodeTreeVisible = false;

      //     newRuleNodes.forEach((component) => {
      //       component.logic.forEach((logic) => {
      //         const evaluationResult = evaluateLogic(
      //           node.meshInfo[component.label],
      //           logic?.value,
      //           +component.input
      //         );

      //         nodeTreeVisible = nodeTreeVisible || evaluationResult;
      //       });
      //     });

      //     filteredComponents.push({
      //       ...node,
      //       treeVisible: nodeTreeVisible,
      //     });
      //   });

      //   filteredNodes = filteredComponents;
      // } else {
      filteredNodes = projectFileNodes?.map((item) => {
        const doesItemExist = newRuleNodes?.some((i) => i.label === item.label);
        return { ...item, treeVisible: doesItemExist };
      });
      // }
      break;
    case "NOT":
    case "NOR":
    case "NAND":
    case "XOR":
      // if (compareType === "subProjects" || compareType === "group") {
      if (compareType) {
        filteredNodes = projectFileNodes?.map((item) => {
          const doesItemExist = newRuleNodes.some(
            (i) => i.label === item.label
          );
          return { ...item, treeVisible: !doesItemExist };
        });
      }
      // } else if (compareType === "parameters") {
      //   let filteredComponents = [];
      //   projectFileNodes.forEach((node) => {
      //     let nodeTreeVisible = false;
      //     newRuleNodes.forEach((component) => {
      //       component.logic.map((logic) => {
      //         const evaluationResult = evaluateLogic(
      //           node.meshInfo[component.label],
      //           +logic?.value,
      //           component.input
      //         );
      //         nodeTreeVisible = nodeTreeVisible || evaluationResult;
      //       });
      //       if (filteredComponents.some((f) => f.id !== node.id)) {
      //         filteredComponents = [
      //           ...filteredComponents,
      //           { ...node, treeVisible: nodeTreeVisible },
      //         ];
      //       }
      //     });
      //   });
      // }
      break;
    default:
      break;
  }
  return filteredNodes;
};

const getSingleNodeByRecursiveCall = (newTreeItems, rule) => {
  let removedItems = [];
  function search(treeNodes, ruleNodes) {
    for (let item of treeNodes) {
      for (let r of ruleNodes) {
        // if (r.label === item.label) {
        if (!item.treeVisible) {
          removedItems = [...removedItems, item];
        } else {
          search(item.nodes, r.nodes);
        }
        // }
      }
    }
  }

  search(newTreeItems.nodes, rule.nodes);
  return removedItems;
};

const handleCreateParentNode = (project, rule, gate) => {
  let newParentItem = {};
  if (project.id !== rule.id) return;
  const ruleSubGroups = rule?.nodes; //first nodes as wall, floor etc
  const ruleGroups = ruleSubGroups?.flatMap((item) => item?.nodes); //child of walls as wall-1
  const ruleComponent = ruleGroups?.flatMap((item) => item?.nodes); //wall-1 child as units
  const shouldCompareSubProjects =
    ruleGroups?.length === 0 && ruleComponent?.length === 0;
  // ? "subProjects"
  // : false;

  const filteredSubProjects = getChildNodes(
    gate,
    project.nodes,
    ruleSubGroups,
    shouldCompareSubProjects
  );
  newParentItem = {
    id: project.id,
    parentId: project.id,
    treeVisible: true,
    treeSelected: false,
    key: project.id,
    label: project.label,
    nodes: filteredSubProjects?.map((subProject) => {
      //first nodes as wall, floor etc
      const shouldCompareGroups = ruleComponent.length === 0;
      // ? "group" : false;
      const childSubProjectNodes = getChildNodes(
        gate,
        subProject.nodes,
        ruleGroups,
        shouldCompareGroups
      );
      return {
        id: subProject.id,
        parentId: project.id,
        treeVisible: subProject.treeVisible,
        treeSelected: false,
        key: subProject.id,
        label: subProject.label,
        nodes: childSubProjectNodes?.map((group) => {
          //child of wall as wall-1
          // const shouldCompareParams =
          //   ruleComponent.length === 0 ? "parameters" : false;
          const childGroupNodes = getChildNodes(
            gate,
            group.nodes,
            ruleComponent
            // shouldCompareParams
          );
          return {
            id: group.id,
            parentId: subProject.id,
            treeVisible: group.treeVisible,
            treeSelected: false,
            key: group.id,
            label: group.label,
            nodes: childGroupNodes?.map((component) => {
              //child nodes of wall-1
              return {
                id: component.id,
                parentId: group.id,
                key: component.id,
                treeVisible: component.treeVisible,
                treeSelected: false,
                label: component.label,
                meshInfo: component.meshInfo,
                nodes: [],
              };
            }),
          };
        }),
      };
    }),
  };
  let response = getSingleNodeByRecursiveCall(newParentItem, rule);
  return response;
};

//uniqueItems /NOR NAND NOT XOR OR
const applyUnionRule = (appliedFilters, projects, gate) => {
  let newTreeItem = null;

  const allSubProjects = appliedFilters.flatMap((subPro) => subPro.nodes);
  let unionAppliedSubProjects = allSubProjects?.filter(
    (item, index, self) =>
      index === self.findIndex((i) => i.label === item.label)
  );

  unionAppliedSubProjects = unionAppliedSubProjects.map((unionSp) => {
    const allExistingSubProjects = allSubProjects.filter(
      (sp) => sp.label === unionSp.label
    );
    const groupsOfUnionSp = allExistingSubProjects.flatMap(
      (item) => item.nodes
    );
    let unionAppliedGroups = groupsOfUnionSp?.filter(
      (item, index, self) =>
        index === self.findIndex((i) => i.label === item.label)
    );
    unionSp.nodes = unionAppliedGroups;
    return unionSp;
  });

  newTreeItem = getNewTreeItem(appliedFilters.at(0), unionAppliedSubProjects);
  const itemsToRender = handleCreateParentNode(projects, newTreeItem, gate);
  return itemsToRender;
};

//CommonItems /AND XNOR
const applyIntersectionRule = (appliedFilters, projects, gate) => {
  let newTreeItem = null;

  const allSubProjects = appliedFilters.flatMap((subPro) => subPro.nodes);
  const isGroupsExist = allSubProjects.some((item) => item.nodes.length > 0);
  let selectedSubProjects = [];
  if (isGroupsExist) {
    const unionAppliedSubProjects = allSubProjects?.filter(
      (item, index, self) =>
        index === self.findIndex((i) => i.label === item.label)
    );
    selectedSubProjects = unionAppliedSubProjects.map((unionSp) => {
      const allExistingSubProjects = allSubProjects.filter(
        (sp) => sp.label === unionSp.label
      );
      const groupsOfUnionSp = allExistingSubProjects.flatMap(
        (item) => item.nodes
      );
      if (groupsOfUnionSp.length > 0) {
        const rawGroups = allExistingSubProjects.map((item) => item.nodes);
        const groups = unionSp.nodes.flatMap((item) => item); //flatMap to get all groups exists in nodes of each subProject
        const commonGroupsLabel = getcommonLabels(rawGroups);
        const commonGroups = [];
        commonGroupsLabel.forEach((label) => {
          const matchedItem = groups.find((item) => item.label === label);
          if (matchedItem) {
            commonGroups.push(matchedItem);
          }
        });
        unionSp.nodes = commonGroups;
      }
      return unionSp;
    });
  } else {
    const rawSubProjects = appliedFilters.map((item) => item.nodes); //raw data of subProjects as [[subProjects]] to get common label in all arrays
    const subProjects = appliedFilters.flatMap((item) => item.nodes); //flatMap to get all subProjects exists in nodes of each item
    const commonSubProjectsLabels = getcommonLabels(rawSubProjects);
    commonSubProjectsLabels.forEach((label) => {
      const matchedItem = subProjects.find((item) => {
        if (item.label === label) {
          item.nodes = [];
          return item;
        }
      });
      if (matchedItem) {
        selectedSubProjects.push(matchedItem);
      }
    });
  }

  newTreeItem = getNewTreeItem(appliedFilters.at(0), selectedSubProjects);
  const itemsToRender = handleCreateParentNode(projects, newTreeItem, gate);
  return itemsToRender;
};

const getNewTreeItem = (treeNode, subProjects, groups, parameters) => {
  const isGroupsExist = subProjects.some((item) => item.nodes.length > 0);
  const newTreeItem = {
    id: treeNode.id,
    parentId: treeNode.id,
    treeVisible: true,
    treeSelected: false,
    key: treeNode.id,
    label: treeNode.label,
    nodes:
      isGroupsExist.length === 0
        ? subProjects
        : subProjects?.map((subProject) => {
            return {
              id: subProject.id,
              parentId: treeNode.id,
              treeVisible: subProject.treeVisible,
              treeSelected: false,
              key: subProject.id,
              label: subProject.label,
              nodes: subProject?.nodes,
              // parameters.length === 0
              //   ? groups
              //   : groups.map((group) => {
              //       return {
              //         id: group.id,
              //         parentId: subProject.id,
              //         treeVisible: group.treeVisible,
              //         treeSelected: false,
              //         key: group.id,
              //         label: group.label,
              //         nodes: parameters?.map((component) => {
              //           //child nodes of wall-1
              //           return {
              //             id: component.id,
              //             parentId: group.id,
              //             key: component.id,
              //             treeVisible: true,
              //             treeSelected: false,
              //             input: component.input,
              //             logic: component.logic,
              //             label: component.label,
              //             meshInfo: component.meshInfo,
              //             nodes: [],
              //           };
              //         }),
              //       };
              // }),
            };
          }),
  };

  return newTreeItem;
};

const getcommonLabels = (data) => {
  const allLabels = data.map((arr) => arr.map((item) => item.label));
  const commonLabels = allLabels.reduce((acc, labels) =>
    acc.filter((label) => labels.includes(label))
  );
  return commonLabels;
};

function exclude(data1, data2) {}

// [[{id: 1, label: "C"}, {id: 1, label: "A"}, {id: 1, label: "B"}], [{id: 1, label: "A"}], [{id: 1, label: "A"}], [{id: 1, label: "B"}, {id: 1, label: "A"}]]
