import React, { useEffect, useState, useRef } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { choseToggle } from "helpers/toggleIfLoading";
import { useHttp } from "hooks/useHttp";
import Select from "react-select";
import addNotification from "helpers/notify";
import { getSettingsBySettingId } from "api/healthCheck/getSettingsBySettingId";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import {
  healthSettingsTitles,
  healthSettingsRuleTitles,
} from "constants/utils";
import { HEALTH_SETTINGS_FORM_FEILDS } from "./settingsData";
import Arrow from "assets/images/icons/arrow.png";
import Loader from "components/Loader";
import {
  getModalHealthSettings,
  updateBulkModelHealthSettings,
  updateModelHealthSettings,
} from "api/ModalHealthSettings/modelHealthSettings";

export default function BulkModelHealth(props) {
  const dispatch = useDispatch();
  const milestonesRedux = useSelector((s) => s.user.milestones);
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const projectsRedux = useSelector((s) => s.user.projects);

  const toggle = () => {
    props.toggle();
  };

  const [filteredHealthSettings, setFilteredHealthSettings] = useState([]);
  const [healthSettings, setHealthSettings] = useState([]);

  const { loading: settingsLoading, request: getModalHealthSettingsAPI } =
    useHttp({
      requestCallback: (data) => getModalHealthSettings(data),
      onLoad: (res) => {
        if (res && res?._id) {
          const { _id, __v, companyId, createdAt, updatedAt, ...settings } =
            res;
          Object.entries(settings).map(([key, value]) => {
            Object.entries(value).map(([innerKey, innerValue]) => {
              settings[key][innerKey]["type"] =
                HEALTH_SETTINGS_FORM_FEILDS[key][innerKey].type;
            });
          });
          setFilteredHealthSettings(settings);
          setHealthSettings(settings);
        }
      },
      onError: (res) => {},
    });

  const handleIncludeToggle = (key, innerKey, value) => {
    const settings = { ...filteredHealthSettings };
    settings[key][innerKey].include = value == "true" ? false : true;
    setFilteredHealthSettings(settings);
  };

  const handleCriticalToggle = (
    key = "",
    innerKey = "",
    isCritical = false
  ) => {
    const settings = { ...filteredHealthSettings };
    settings[key][innerKey].importance = isCritical ? "critical" : "normal";
    setFilteredHealthSettings(settings);
  };

  const handleDropdownValeChange = (key, innerKey, value) => {
    const settings = { ...filteredHealthSettings };
    settings[key][innerKey].value = value;
    setFilteredHealthSettings(settings);
  };

  useEffect(() => {
    getModalHealthSettingsAPI(userInfoRedux?.company);
  }, []);

  const convertValueToOriginalType = (formValue) => {
    if (formValue === "" || formValue === undefined) return "";
    const isNumber = !isNaN(formValue);
    const isBoolean = formValue === "true" || formValue === "false";
    return isNumber || isBoolean ? JSON.parse(formValue) : formValue;
  };

  const handleSave = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let settings = {};

    for (let [key, value] of formData.entries()) {
      const keyName = key.split(".")[0];
      const keyValue = key.split(".")[1];
      const valueName = key.split(".")[2];

      value = convertValueToOriginalType(value);

      if (keyName in settings) {
        if (keyValue in settings[keyName]) {
          // if setting exists than set its vlaues like: include and importance
          settings[keyName][keyValue][valueName] = value;
        } else {
          // if one setting has multiple rules we have to reinitilize the other rules
          settings[keyName] = {
            ...settings[keyName],
            [keyValue]: {
              [valueName]: value,
            },
          };
        }
      } else {
        // initialize new settings and rule
        settings[keyName] = {
          [keyValue]: {
            [valueName]: value,
          },
        };
      }
    }

    // Setting include false as if switch is set to false it doesn't providing bindings
    Object.entries(settings).map(([key, value]) => {
      Object.entries(value).map(([innerKey, innerValue]) => {
        if (!Object.keys(settings[key][innerKey]).includes("include")) {
          settings[key][innerKey].include = false;
        }
      });
    });

    settings["companyId"] = userInfoRedux?.company;

    const payload = props?.bulkEditProject?.map((item) => {
      return { _id: item?.settingId, ...settings };
    });
     console.log(props?.bulkEditProject)
    console.log(payload);
    updateBulkModelHealthSettingsAPI(payload);
  };

  const {
    loading: updatingSettings,
    request: updateBulkModelHealthSettingsAPI,
  } = useHttp({
    requestCallback: (data) => updateBulkModelHealthSettings(data),
    onLoad: (res) => {
      addNotification(
        "Bulk Models Health Settings Updated Successfully!",
        "success"
      );
    },
    onError: (res) => {
      addNotification(
        res.error ?? "Something went wrong please connect to support!",
        "danger"
      );
    },
  });

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={choseToggle(updatingSettings, toggle)}
      centered
      className="create-modal-full-size"
    >
      <MDBModalHeader toggle={choseToggle(updatingSettings, toggle)}>
        Model Health Settings Bulk Assign
      </MDBModalHeader>
      <MDBModalBody className="pt-1">
        {settingsLoading && <Loader />}
        <form onSubmit={handleSave}>
          <Accordion
            allowZeroExpanded
            className="modal-health-accordian checkbox_accordian"
          >
            {Object.entries(filteredHealthSettings).map(([key, value]) => (
              <AccordionItem className="mt-3 accordion__item" key={key}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <div className=" px-3 py-2 d-flex align-items-center justify-content-between">
                          <p className="f-14 inter font-weight-400 text-black mb-0 txt-cap flex-1">
                            {healthSettingsTitles[key]}
                          </p>
                          <div className=" flex-1 d-flex justify-content-between">
                            <div className="d-flex">
                              <p className="rule-count bg-light-warning text-warning mr-2">
                                {value
                                  ? Object.keys(value).length + " Total"
                                  : ""}
                              </p>
                              <p className="rule-count bg-light-success text-success mr-2">
                                {value
                                  ? Object.values(value).filter(
                                      (prop) => prop.include === true
                                    ).length + " Included"
                                  : ""}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="rule-count bg-light-danger text-danger mr-2">
                                {value
                                  ? Object.values(value).filter(
                                      (prop) => prop.importance === "critical"
                                    ).length + " Critical"
                                  : ""}
                              </p>
                              <p className="rule-count bg-light-success text-success">
                                {value
                                  ? Object.values(value).filter(
                                      (prop) => prop.importance === "normal"
                                    ).length + " Normal"
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <img
                            src={Arrow}
                            className={
                              expanded
                                ? "arrow-icon"
                                : "arrow-icon transform-180"
                            }
                          />
                        </div>
                      )}
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="card-body px-0 mx-0">
                    {Object.entries(value)?.map(([innerKey, innerValue]) => (
                      <div className="form-group-div mb-3" key={innerKey}>
                        <div>
                          <label className="inter pl-2 txt-cap">
                            {healthSettingsRuleTitles[innerKey]}
                          </label>
                          {innerValue.type !== "boolean" ? (
                            <input
                              type={innerValue.type}
                              className="mt-3"
                              name={`${key}.${innerKey}.${"value"}`}
                              defaultValue={innerValue.value}
                            />
                          ) : (
                            <select
                              className="mt-3"
                              name={`${key}.${innerKey}.${"value"}`}
                              value={innerValue.value}
                              onChange={(event) =>
                                handleDropdownValeChange(
                                  key,
                                  innerKey,
                                  event.target.value
                                )
                              }
                            >
                              <option>true</option>
                              <option>false</option>
                            </select>
                          )}
                        </div>
                        {/* include toggle */}
                        <div className="form-check form-switch mr-4 mt-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            onChange={(e) =>
                              handleIncludeToggle(key, innerKey, e.target.value)
                            }
                            name={`${key}.${innerKey}.${"include"}`}
                            checked={innerValue?.include}
                            value={innerValue?.include}
                          />
                          <label className="form-check-label ml-2">
                            Include
                          </label>
                        </div>

                        {/* normal/critical toggle */}
                        <div
                          className="btn-group btn-group-toggle toggle-group"
                          data-toggle="buttons"
                          onClick={() =>
                            handleCriticalToggle(
                              key,
                              innerKey,
                              innerValue?.importance === "normal" ? true : false
                            )
                          }
                        >
                          <label
                            className={`${"btn btn-secondary"} ${
                              innerValue?.importance === "normal"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name={`${key}.${innerKey}.${"importance"}`}
                              value="normal"
                              checked={
                                innerValue?.importance === "normal"
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                console.log("value", innerValue?.importance)
                              }
                            />{" "}
                            Normal
                          </label>
                          <label
                            className={`${"btn btn-secondary"} ${
                              innerValue?.importance === "critical"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name={`${key}.${innerKey}.${"importance"}`}
                              value="critical"
                              checked={
                                innerValue?.importance === "critical"
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                console.log("value", innerValue?.importance)
                              }
                            />{" "}
                            Critical
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>

          <button
            className="header-btn bg-bid text-white border-bid rounded-lg h6 mx-auto  mt-2 cursor-pointer"
            type="submit"
          >
            <span className="f-14">
              {updatingSettings ? (
                <i className="fa fa-spinner small-spinner mr-2" />
              ) : (
                <i className="fa fa-save mr-2 "></i>
              )}
              Save
            </span>
          </button>

        </form>
      </MDBModalBody>
    </MDBModal>
  );
}
