import {
  SET_ROLES_AND_THEIR_PERMISSIONS,
  UPDATE_ROLES_AND_THEIR_PERMISSIONS,
} from "constants/action-types/role-permission-settings";

export const setRolesAndTheirPermissions = (assignedPermissions) => {
  return {
    type: SET_ROLES_AND_THEIR_PERMISSIONS,
    payload: assignedPermissions,
  };
};

export const updateRolesAndTheirPermissions = (assignedPermissions) => {
  return {
    type: UPDATE_ROLES_AND_THEIR_PERMISSIONS,
    payload: assignedPermissions,
  };
};