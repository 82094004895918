import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TopNavbar from "../navbar";
import Sidebar from "../sidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import Arrow from "assets/images/icons/arrow.png";
import { useHttp } from "hooks/useHttp";
import {
  getModalHealthSettings,
  createModelHealthSettings,
  updateModelHealthSettings,
} from "api/ModalHealthSettings/modelHealthSettings";
import { HEALTH_SETTINGS_FORM_FEILDS } from "./settingsData";
import { getCompanySettings } from "api/companySettings/getCompanySettings";
import Loader from "components/Loader";
import addNotification from "helpers/notify";
import {
  healthSettingsTitles,
  healthSettingsRuleTitles,
} from "constants/utils";
import { getCompanyModelFileList } from "api/healthCheck/getCompanyModelFileList";
import DefaultImage from "assets/images/user.png";
import { url } from "api";
import DefaultImageProject from "assets/images/Default_Project_Image.png";
import moment from "moment";
import EyeImg from "assets/images/icons/eye-fill.png";
import FileSettingsView from "./FileSettingsView";
import BulkModelHealth from "./BulkModelhealth";

export default function ModalHealth() {
  const dispatch = useDispatch();
  const [recetSettingsId, setRecentSettingsId] = useState(null);
  const [healthSettings, setHealthSettings] = useState([]);

  const handleSearch = (keyword) => {
    if (activeTab === TABS.GLOBAL) {
      const filteredTitles = Object.fromEntries(
        Object.entries(healthSettingsTitles).filter(([key, value]) =>
          value.toLowerCase().includes(keyword.toLowerCase())
        )
      );
      const filteredHealthSettings = Object.fromEntries(
        Object.entries(filteredTitles).filter(
          ([key, value]) => healthSettings[key]
        )
      );

      setFilteredHealthSettings(filteredHealthSettings);
    } else {
      const filteredResult = modelFileList?.filter((list) =>
        list?.modelName.toLowerCase().includes(keyword.toLowerCase())
      );

      if (keyword.length > 0) {
        setFilteredModelFileList(filteredResult);
      } else {
        setFilteredModelFileList(modelFileList);
      }
    }
  };

  const userInfoRedux = useSelector((s) => s.user.userInfo);
  // const companySettingsRedux = useSelector((s) => s.user.companySettings);
  const [filteredHealthSettings, setFilteredHealthSettings] = useState([]);

  const { loading, request: getCompanySettingsAPI } = useHttp({
    requestCallback: () => getCompanySettings(userInfoRedux?.company),
    onLoad: (res) => {
      if (res?.recentModelHealthSettings) {
        setRecentSettingsId(res?.recentModelHealthSettings);
        getModalHealthSettingsAPI(userInfoRedux?.company);
      }
    },
    onError: (res) => {},
  });

  const { loading: settingsLoading, request: getModalHealthSettingsAPI } =
    useHttp({
      requestCallback: (data) => getModalHealthSettings(data),
      onLoad: (res) => {
        if (res && res?._id) {
          const { _id, __v, companyId, createdAt, updatedAt, ...settings } =
            res;
          Object.entries(settings).map(([key, value]) => {
            Object.entries(value).map(([innerKey, innerValue]) => {
              settings[key][innerKey]["type"] =
                HEALTH_SETTINGS_FORM_FEILDS[key][innerKey].type;
            });
          });
          setFilteredHealthSettings(settings);
          setHealthSettings(settings);
        }
      },
      onError: (res) => {},
    });

  const { loading: creatingSettings, request: createModelHealthSettingsAPI } =
    useHttp({
      requestCallback: (data) => createModelHealthSettings(data),
      onLoad: (res) => {
        if (res?._id) {
          addNotification(
            "Model Health Settings Created Successfully!",
            "success"
          );
        } else {
          addNotification(
            res?.err ?? "Something went wrong please connect to support!",
            "danger"
          );
        }
      },
      onError: (res) => {
        addNotification(
          res?.error ?? "Something went wrong please connect to support!",
          "danger"
        );
      },
    });

  const { loading: updatingSettings, request: updateModelHealthSettingsAPI } =
    useHttp({
      requestCallback: (data) => updateModelHealthSettings(data),
      onLoad: (res) => {
        if (res?._id) {
          addNotification(
            "Model Health Settings Updated Successfully!",
            "success"
          );
        } else {
          addNotification(
            res?.err ?? "Something went wrong please connect to support!",
            "danger"
          );
        }
      },
      onError: (res) => {
        addNotification(
          res.error ?? "Something went wrong please connect to support!",
          "danger"
        );
      },
    });

  useEffect(() => {
    getCompanySettingsAPI();
  }, []);

  const convertValueToOriginalType = (formValue) => {
    if (formValue === "" || formValue === undefined) return "";
    const isNumber = !isNaN(formValue);
    const isBoolean = formValue === "true" || formValue === "false";
    return isNumber || isBoolean ? JSON.parse(formValue) : formValue;
  };

  const handleSaveHealthSettings = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let settings = {};

    for (let [key, value] of formData.entries()) {
      const keyName = key.split(".")[0];
      const keyValue = key.split(".")[1];
      const valueName = key.split(".")[2];

      value = convertValueToOriginalType(value);

      if (keyName in settings) {
        if (keyValue in settings[keyName]) {
          // if setting exists than set its vlaues like: include and importance
          settings[keyName][keyValue][valueName] = value;
        } else {
          // if one setting has multiple rules we have to reinitilize the other rules
          settings[keyName] = {
            ...settings[keyName],
            [keyValue]: {
              [valueName]: value,
            },
          };
        }
      } else {
        // initialize new settings and rule
        settings[keyName] = {
          [keyValue]: {
            [valueName]: value,
          },
        };
      }
    }

    // Setting include false as if switch is set to false it doesn't providing bindings
    Object.entries(settings).map(([key, value]) => {
      Object.entries(value).map(([innerKey, innerValue]) => {
        if (!Object.keys(settings[key][innerKey]).includes("include")) {
          settings[key][innerKey].include = false;
        }
      });
    });

    settings["companyId"] = userInfoRedux?.company;

    if (recetSettingsId) {
      settings["_id"] = recetSettingsId;
      updateModelHealthSettingsAPI(settings);
    } else {
      createModelHealthSettingsAPI(settings);
    }
  };

  const handleIncludeToggle = (key, innerKey, value) => {
    const settings = { ...filteredHealthSettings };
    settings[key][innerKey].include = value == "true" ? false : true;
    setFilteredHealthSettings(settings);
  };

  const handleDropdownValeChange = (key, innerKey, value) => {
    const settings = { ...filteredHealthSettings };
    settings[key][innerKey].value = value;
    setFilteredHealthSettings(settings);
  };

  const handleCriticalToggle = (
    key = "",
    innerKey = "",
    isCritical = false
  ) => {
    const settings = { ...filteredHealthSettings };
    settings[key][innerKey].importance = isCritical ? "critical" : "normal";
    setFilteredHealthSettings(settings);
  };

  //

  const [openFileHealthSettings, setOpenFileHealthSettings] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const TABS = {
    GLOBAL: "GLOBAL",
    PROJECT: "PROJECT",
  };
  const [activeTab, setActiveTab] = useState(TABS.GLOBAL);
  const [modelFileList, setModelFileList] = useState([]);
  const [filteredModelFileList, setFilteredModelFileList] = useState([]);

  const [fileListToEdit, setFileListToEdit] = useState(null);

  const { ModelFileloading, request: getCompanyModelFileListAPI } = useHttp({
    requestCallback: () => getCompanyModelFileList(userInfoRedux.company),
    onLoad: (res) => {
      setModelFileList(res);
      setFilteredModelFileList(res);
    },
    onError: (res) => {
      addNotification(res?.err, "danger");
    },
  });

  useEffect(() => {
    getCompanyModelFileListAPI();
  }, []);

  const getHealthSettingsOfFile = (item) => {
    setOpenFileHealthSettings(true);
    setHeaderTitle(item?.modelName);
    setFileListToEdit(item);
  };

  const [bulkEditProject, setBulkEditProject] = useState([]);
  const [openBulkEditModel, setBulkEditModel] = useState(false);

  const handleCheckboxChange = (item) => {
    if (bulkEditProject.includes(item)) {
      // If yes, remove it
      setBulkEditProject(
        bulkEditProject.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      // If no, add it
      setBulkEditProject([...bulkEditProject, item]);
    }
  };

  useEffect(() => {
    setBulkEditProject([]);
  }, [activeTab]);

  return (
    <div className="personal-area">
      <TopNavbar handleSearch={handleSearch} />

      <div className="content-bg p-2">
        <div className="container d-flex mt-1">
          <Sidebar />

          <div className="profile-content px-0 pt-0">
            {(loading ||
              settingsLoading ||
              creatingSettings ||
              updatingSettings) && <Loader />}
            <div className="w-100 px-0 position-relative">
              <div className="d-flex justify-content-between px-3 py-3 border-bottom">
                <div className="d-flex align-items-center">
                  {openFileHealthSettings ? (
                    <i
                      className="fa fa-arrow-left cursor-pointer"
                      onClick={() => setOpenFileHealthSettings(false)}
                    ></i>
                  ) : (
                    <div className="heading-svg-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                      >
                        <path
                          d="M17 0C17.5523 0 18 0.44772 18 1V19C18 19.5523 17.5523 20 17 20H3C2.44772 20 2 19.5523 2 19V17H0V15H2V13H0V11H2V9H0V7H2V5H0V3H2V1C2 0.44772 2.44772 0 3 0H17ZM11 6H9V9H6V11H8.999L9 14H11L10.999 11H14V9H11V6Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  )}
                  <span
                    className="font-weight-bold ml-3 cursor-pointer text-black"
                    onClick={() => setOpenFileHealthSettings(false)}
                  >
                    Model Health Settings
                  </span>
                  {openFileHealthSettings && (
                    <div className="d-flex">
                      <span className="mx-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                        >
                          <path
                            d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                            fill="#9E9E9E"
                          ></path>
                        </svg>
                      </span>
                      <span className="font-weight-bold text-black">
                        {headerTitle}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {!openFileHealthSettings && (
                <div
                  className="btn-group btn-group-toggle toggle-group mt-3 w-75 mx-3 report-btn-group"
                  data-toggle="buttons"
                >
                  <label
                    className={`${"btn btn-secondary py-3"} ${
                      activeTab === TABS.GLOBAL ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(TABS.GLOBAL)}
                  >
                    <input
                      type="radio"
                      name="options"
                      id="option1"
                      defaultChecked
                    />
                    Global Settings
                  </label>
                  <label
                    className={`${"btn btn-secondary py-3"} ${
                      activeTab === TABS.PROJECT ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(TABS.PROJECT)}
                  >
                    <input type="radio" name="options" id="option2" />
                    File Settings
                  </label>
                </div>
              )}

              {/* bulkEditProject */}

              {activeTab === TABS.PROJECT &&
                bulkEditProject.length > 0 &&
                !openFileHealthSettings && (
                  <div
                    className="header-btn bg-bid text-white border-bid rounded-lg h6 ml-auto mb-0 cursor-pointer mr-1 save-btn-position"
                    onClick={() => setBulkEditModel(true)}
                  >
                    <span className="f-14">
                      <i className="fa fa-save mr-2 "></i> Bulk Assign
                    </span>
                  </div>
                )}

              {openFileHealthSettings ? (
                <FileSettingsView fileListToEdit={fileListToEdit} />
              ) : activeTab === TABS.GLOBAL ? (
                <form onSubmit={handleSaveHealthSettings}>
                  {activeTab === TABS.GLOBAL && (
                    <button className="header-btn bg-bid text-white border-bid rounded-lg h6 ml-auto mb-0 cursor-pointer mr-1 save-btn-position">
                      <span className="f-14">
                        <i className="fa fa-save mr-2 "></i> Save
                      </span>
                    </button>
                  )}
                  <div className="container">
                    <Accordion
                      allowZeroExpanded
                      className="modal-health-accordian checkbox_accordian"
                    >
                      {Object.entries(filteredHealthSettings).map(
                        ([key, value]) => (
                          <AccordionItem
                            className="mt-3 accordion__item"
                            key={key}
                          >
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <AccordionItemState>
                                  {({ expanded }) => (
                                    <div className=" px-3 py-2 d-flex align-items-center justify-content-between">
                                      <p className="f-14 inter font-weight-400 text-black mb-0 txt-cap flex-1">
                                        {healthSettingsTitles[key]}
                                      </p>
                                      <div className=" flex-1 d-flex justify-content-between">
                                        <div className="d-flex">
                                          <p className="rule-count bg-light-warning text-warning mr-2">
                                            {value
                                              ? Object.keys(value).length +
                                                " Total"
                                              : ""}
                                          </p>
                                          <p className="rule-count bg-light-success text-success mr-2">
                                            {value
                                              ? Object.values(value).filter(
                                                  (prop) =>
                                                    prop.include === true
                                                ).length + " Included"
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <p className="rule-count bg-light-danger text-danger mr-2">
                                            {value
                                              ? Object.values(value).filter(
                                                  (prop) =>
                                                    prop.importance ===
                                                    "critical"
                                                ).length + " Critical"
                                              : ""}
                                          </p>
                                          <p className="rule-count bg-light-success text-success">
                                            {value
                                              ? Object.values(value).filter(
                                                  (prop) =>
                                                    prop.importance === "normal"
                                                ).length + " Normal"
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                      <img
                                        src={Arrow}
                                        className={
                                          expanded
                                            ? "arrow-icon"
                                            : "arrow-icon transform-180"
                                        }
                                      />
                                    </div>
                                  )}
                                </AccordionItemState>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="card-body px-0 mx-0">
                                {Object.entries(value)?.map(
                                  ([innerKey, innerValue]) => (
                                    <div
                                      className="form-group-div mb-3"
                                      key={innerKey}
                                    >
                                      <div>
                                        <label className="inter pl-2 txt-cap">
                                          {healthSettingsRuleTitles[innerKey]}
                                        </label>
                                        {innerValue.type !== "boolean" ? (
                                          <input
                                            type={innerValue.type}
                                            className="mt-3"
                                            name={`${key}.${innerKey}.${"value"}`}
                                            defaultValue={innerValue.value}
                                          />
                                        ) : (
                                          <select
                                            className="mt-3"
                                            name={`${key}.${innerKey}.${"value"}`}
                                            value={innerValue.value}
                                            onChange={(event) =>
                                              handleDropdownValeChange(
                                                key,
                                                innerKey,
                                                event.target.value
                                              )
                                            }
                                          >
                                            <option>true</option>
                                            <option>false</option>
                                          </select>
                                        )}
                                      </div>
                                      {/* include toggle */}
                                      <div className="form-check form-switch mr-4 mt-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          onChange={(e) =>
                                            handleIncludeToggle(
                                              key,
                                              innerKey,
                                              e.target.value
                                            )
                                          }
                                          name={`${key}.${innerKey}.${"include"}`}
                                          checked={innerValue?.include}
                                          value={innerValue?.include}
                                        />
                                        <label className="form-check-label ml-2">
                                          Include
                                        </label>
                                      </div>

                                      {/* normal/critical toggle */}
                                      <div
                                        className="btn-group btn-group-toggle toggle-group"
                                        data-toggle="buttons"
                                        onClick={() =>
                                          handleCriticalToggle(
                                            key,
                                            innerKey,
                                            innerValue?.importance === "normal"
                                              ? true
                                              : false
                                          )
                                        }
                                      >
                                        <label
                                          className={`${"btn btn-secondary"} ${
                                            innerValue?.importance === "normal"
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <input
                                            type="radio"
                                            name={`${key}.${innerKey}.${"importance"}`}
                                            value="normal"
                                            checked={
                                              innerValue?.importance ===
                                              "normal"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              console.log(
                                                "value",
                                                innerValue?.importance
                                              )
                                            }
                                          />{" "}
                                          Normal
                                        </label>
                                        <label
                                          className={`${"btn btn-secondary"} ${
                                            innerValue?.importance ===
                                            "critical"
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <input
                                            type="radio"
                                            name={`${key}.${innerKey}.${"importance"}`}
                                            value="critical"
                                            checked={
                                              innerValue?.importance ===
                                              "critical"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              console.log(
                                                "value",
                                                innerValue?.importance
                                              )
                                            }
                                          />{" "}
                                          Critical
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        )
                      )}
                    </Accordion>
                  </div>
                </form>
              ) : (
                <div className="mt-3">
                  <div className="project-table-div p-0 rounded-0">
                    <table className="bid-table project-list-view-table border-top-none">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="col-01">Model Name</th>
                          <th className="col-02">Size</th>
                          <th className="col-03">Latest exporter</th>
                          <th className="col-04">Associated projects</th>
                          <th className="col-05">Created Date</th>
                          <th className="col-07"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredModelFileList?.map((item, key) => (
                          <tr key={key}>
                            <td className="pl-0">
                              <input
                                type="checkbox"
                                className="squared-checkbox"
                                onChange={() => handleCheckboxChange(item)}
                              />
                            </td>
                            <td className="col-01">{item?.modelName}</td>
                            <td className="col-02">{item?.fileSize}Mb</td>
                            <td className="col-03">
                              {" "}
                              <div className="d-flex">
                                <img
                                  src={item?.doneBy?.profilePhoto}
                                  onError={(e) => (e.target.src = DefaultImage)}
                                  className="user-img"
                                />
                                <p className="mb-0 f-14 ml-2 poppins-regular text-black">
                                  {item?.doneBy?.name}
                                </p>
                              </div>
                            </td>
                            <td className="col-04">
                              <div className="users-profile-list">
                                {item?.associatedProjects?.map((i, index) => (
                                  <img
                                    src={`${url}/${i.img}`}
                                    // alt="grp"
                                    key={index}
                                    className="u-profile"
                                    onError={(e) =>
                                      (e.target.src = DefaultImageProject)
                                    }
                                  />
                                ))}
                                {item?.associatedProjects?.length > 4 && (
                                  <div className="u-profile">
                                    +{item?.associatedProjects?.length - 1}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="col-05">
                              {" "}
                              {moment(item?.createdAt).format("DD MMMM YYYY")}
                            </td>
                            <td className="col-07">
                              <div
                                onClick={() => {
                                  getHealthSettingsOfFile(item);
                                }}
                                className="cursor-pointer"
                              >
                                <img
                                  src={EyeImg}
                                  alt="eye"
                                  className="action-icon"
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <BulkModelHealth
        isOpen={openBulkEditModel}
        toggle={() => setBulkEditModel(false)}
        bulkEditProject={bulkEditProject}
      />
    </div>
  );
}
