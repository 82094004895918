import React, { useState } from "react";
import { MDBBox, MDBIcon } from "mdbreact";
import { ReactComponent as Dollar } from "../../assets/images/dollar2.svg";
import { ReactComponent as IconMesh } from "../../assets/images/mesh.svg";
import { checkDepth } from "../../helpers/checkDepth";
import {
  findRecursiveInTree,
  UpdateGroupVisibility,
} from "../../helpers/three";

import "./TreeViewerItem.scss";
import { ItemComponent } from "react-simple-tree-menu";
import { fileterData } from "components/Share/test";

const TreeViewerItem = (props) => {
  const {
    item: { hasNodes, level, meshInfo, isOpen, treeVisible, id, label, key },
    selectGroups,
    setSelectGroups,
    clickedChild,
    setClickedChild,
    WebGL,
    tree,
    setTreeData,
    index,
    setOpenNodes,
    selectors,
    isUnmatchedMeshes,
    isExistingMeshes,
    selectedMesh,
    isTableView = false
  } = props;

  const depthClassName = checkDepth(level);

  const itemClassName = `tree-item d-flex user-select-none
    border-bottom border-light ${isTableView ? "tree-item-table-view" : ""}
    ${index === 0 ? 'border-top' : ''} w-100
    align-items-center ${!hasNodes ? 'cursor-pointer' : ''} 
    ${isOpen && !selectGroups?.includes(id) ? 'grey lighten-3' : ''}
    ${
      meshInfo &&
      clickedChild.find((child) => child.UniqueId === meshInfo.UniqueId)
        ? "amber light-2"
        : ""
    }
    ${selectGroups?.includes(id) ? 'amber light-2' : ''}
    ${depthClassName}`.trimRight();

  const selectElements = (meshList, list, group) => {
    const temp = [...tree];
    const newTreeItem = findRecursiveInTree(temp, id);

    if (
      (!newTreeItem.result.nodes || newTreeItem.result.nodes.length === 0) &&
      !newTreeItem.result.meshInfo?.UniqueId
    ) {
      return;
    }

    if (
      (selectGroups?.includes(newTreeItem.result?.id) ||
        clickedChild.filter(
          (item) => item.UniqueId === newTreeItem.result.meshInfo?.UniqueId
        ).length > 0) &&
      ((!group &&
        (!selectGroups?.includes(newTreeItem.parent?.id) ||
          newTreeItem.parent.nodes.length < 2)) ||
        group)
    ) {
      hasNodes
        ? (list = list.filter((item) => item !== newTreeItem.result.id))
        : (meshList = meshList.filter(
            (item) => item.UniqueId !== newTreeItem.result.meshInfo?.UniqueId
          ));

      newTreeItem.path.forEach((el) => {
        el.nodes.length > 0
          ? (list = list.filter((item) => item !== el.id))
          : (meshList = meshList.filter(
              (item) => item.UniqueId !== el.meshInfo?.UniqueId
            ));
      });

      if (
        newTreeItem.result.nodes.filter(
          el =>
            list?.includes(el.id) ||
            meshList.filter(item => item.UniqueId === el.meshInfo?.UniqueId)
              .length > 0,
        ).length >= newTreeItem.result.nodes.length
      ) {
        (function enable(tree) {
          if (tree.nodes && tree.nodes.length > 0)
            list = list.filter((item) => item !== tree.id);
          if (tree.meshInfo?.UniqueId)
            meshList.filter(
              (item) => item.UniqueId !== tree.meshInfo?.UniqueId
            );

          tree.nodes.forEach((item) => enable(item));
        })(newTreeItem.result);
      }
    } else {
      hasNodes
        ? list.push(newTreeItem.result.id)
        : meshList.push(newTreeItem.result.meshInfo);

      newTreeItem.path.forEach((item) => {
        if (
          list?.includes(item.id) ||
          (item.nodes.filter(el => list?.includes(el.id)).length >=
            item.nodes.length - 1 &&
            (!newTreeItem.parent ||
              newTreeItem.parent.nodes.filter(el => list?.includes(el.id))
                .length >= newTreeItem.parent.nodes.length)) ||
          (item.nodes.filter(
            (el) =>
              meshList.filter((item) => item.UniqueId === el.meshInfo?.UniqueId)
                .length > 0
          ).length >=
            item.nodes.length - 1 &&
            (!newTreeItem.parent ||
              newTreeItem.parent.nodes.filter(
                (el) =>
                  meshList.filter(
                    (item) => item.UniqueId === el.meshInfo?.UniqueId
                  ).length > 0
              ).length >= newTreeItem.parent.nodes.length))
        ) {
          (function disable(tree) {
            if (
              !list?.includes(tree.id) &&
              meshList.filter(el => el.UniqueId === tree.meshInfo?.UniqueId)
                .length === 0
            ) {
              if (tree.nodes && tree.nodes.length > 0) list.push(tree.id);
              if (tree.meshInfo?.UniqueId) meshList.push(tree.meshInfo);
            }
            tree.nodes.forEach((item) => disable(item));
          })(item);
        }
      });
    }

    WebGL.current.viewer.onClickChildInList(
      "reset",
      false,
      selectors,
      label === "unknown"
    );

    meshList.forEach((item) => {
      WebGL.current.viewer.onClickChildInList(
        item.UniqueId,
        true,
        selectors,
        label === "unknown"
      );
      selectedMesh.current = item.UniqueId;
    });

    setSelectGroups(list);
    setClickedChild(meshList);
    WebGL.current.viewer.targetItems();
  };

  return (
    <div
      id={id}
      className={itemClassName}
      onContextMenu={(e) => {
        e.preventDefault();
        isUnmatchedMeshes();
        isExistingMeshes();
        selectElements([...clickedChild], [...selectGroups], tree);

        // return;
        // if (!hasNodes) {
        //   if(!meshInfo || !meshInfo.UniqueId){
        //     return;
        //   }

        //   setClickedChild(s => {
        //     if(s.find(mesh => mesh.UniqueId === meshInfo.UniqueId)) {
        //       const neededIndex = s.findIndex(mesh => mesh.UniqueId === meshInfo.UniqueId)
        //       let temp = [...s.slice(0,neededIndex),...s.slice(neededIndex + 1)]
        //       return temp;
        //     }
        //     let temp = [...s, meshInfo]
        //     return temp;
        //   });

        //   WebGL.current.viewer.onClickChildInList(meshInfo.UniqueId, true,selectors,true);
        // }else{
        //   if(selectGroups.includes(id)){
        //     return;
        //   }

        //   const temp = [...tree];
        //   const newTreeItem = findRecursiveInTree(temp, id);
        //   const meshList = [];
        //   const list = [];

        //   (function enable(tree) {
        //     if (tree.meshInfo?.UniqueId) {

        //       if(clickedChild.filter(item => item.UniqueId === tree.meshInfo.UniqueId).length === 0){
        //         meshList.push(tree.meshInfo);
        //         WebGL.current.viewer.onClickChildInList(tree.meshInfo.UniqueId, true, selectors, label === 'unknown');
        //       }

        //     }else{

        //       if(!selectGroups.includes(tree.id)){
        //         list.push(tree.id);
        //       }

        //     }

        //     tree.nodes.forEach(item => enable(item));
        //   })(newTreeItem.result);

        //   setSelectGroups(s => [...s, ...list]);
        //   setClickedChild(s=> [...s, ...meshList]);

        // }
      }}
      //click meshInfo
      onClick={(e) => {
        isUnmatchedMeshes();
        isExistingMeshes();
        selectElements([], []);
        if (e.ctrlKey) {
          selectElements([...clickedChild], [...selectGroups], tree);
        }
      }}
    >
      {hasNodes ? (
        <MDBIcon
          far
          className="mr-2"
          icon={`${isOpen ? "minus" : "plus"}-square cursor-pointer`}
          onClick={(e) => {
            e.stopPropagation();
            setOpenNodes(s => {
              if (s?.includes(key)) {
                return s.filter(nodeKey => nodeKey !== key);
              }
              return [...s, key];
            });
          }}
        />
      ) : null}
      <MDBBox className="flex-grow-1 d-flex align-items-center text-truncate">
        {label === "unknown" ? (
          <>
            <Dollar />
            {"  "}
            <span className="ml-2">unpriced</span>
          </>
        ) : meshInfo?.UniqueId &&
          !WebGL.current.viewer.checkMesh(meshInfo.UniqueId) ? (
          <>
            <IconMesh />
            {"  "}
            <span className="ml-2">{label}</span>
          </>
        ) : (
          label
        )}
      </MDBBox>
      {!isTableView && 
      <MDBIcon
        icon={treeVisible ? "eye" : "eye-slash"}
        className="mr-2"
        onClick={(e) => {
          e.stopPropagation();
          const temp = [...tree];
          const newTreeItem = findRecursiveInTree(temp, id);
          console.log(
            "---0------------------newTreeItem",
            JSON.stringify(newTreeItem, null, 2)
          );

          if (newTreeItem.result.treeVisible === true) {
            newTreeItem.result.treeVisible = false;
            newTreeItem.path.forEach((item) => {
              if (
                (item.nodes.filter((el) => el.treeVisible === false).length >=
                  item.nodes.length - 1 &&
                  (!newTreeItem.parent ||
                    newTreeItem.parent.nodes.filter(
                      (el) => el.treeVisible === false
                    ).length >= newTreeItem.parent.nodes.length)) ||
                item.treeVisible === false
              ) {
                (function disable(tree) {
                  tree.treeVisible = false;
                  if (tree.meshInfo) {
                    let temp = WebGL.current.viewer.meshesObject.get(
                      tree.meshInfo.UniqueId
                    );
                    if (temp) UpdateGroupVisibility(temp, false);
                  }

                  tree.nodes.forEach((item) => disable(item));
                })(item);
              }
            });
          } else {
            newTreeItem.result.treeVisible = true;
            newTreeItem.path.forEach((item) => {
              item.treeVisible = true;
            });
            if (
              newTreeItem.result.nodes.filter(
                (item) => item.treeVisible === false
              ).length >= newTreeItem.result.nodes.length
            ) {
              (function enable(tree) {
                tree.treeVisible = true;
                if (tree.meshInfo) {
                  let temp = WebGL.current.viewer.meshesObject.get(
                    tree.meshInfo.UniqueId
                  );
                  if (temp) UpdateGroupVisibility(temp, true);
                }
                tree.nodes.forEach((item) => enable(item));
              })(newTreeItem.result);
            }
          }

          setTreeData(temp);
        }}
      />
      }
    </div>
  );
};

export default TreeViewerItem;
