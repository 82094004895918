import React from "react";
import { follow } from "../../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { url } from "api";
import { eventTrack } from "helpers/ga4Helper";

const FollowButton = ({ to_follow, onSuccess }) => {
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const isFollowing =
    userInfoRedux &&
    userInfoRedux?.following &&
    userInfoRedux?.following.length &&
    userInfoRedux?.following.includes(to_follow);

  const followUser = () => {
    if (userInfoRedux) {
      if (userInfoRedux?.id !== to_follow) {
        follow(userInfoRedux?.id, to_follow);
        if (onSuccess) {
          onSuccess();
        }
      }
    } else {
      alert("please login to follow");
    }
  };

  function follow(id, user_id) {
    axios
      .post(`${url}/api/user/follow`, { id, user_id })
      .then((res) => {
        // getUser(id).then((res) => {
        //   dispatch({ type: "SET_USER", user: res });
        // });
        console.log(res);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="mb-3">
      <button
        onClick={() => {followUser()
          {
            eventTrack(
              "Follow-User-Action",
              "Follow-User",
              userInfoRedux?.name,
              "+1",
              false,
              "+1"
            );
          }
        }}
        className={
          !isFollowing ? "follow-button unfollow" : "follow-button follow"
        }
      >
        {!isFollowing ? (
          <i className="fa fa-plus mr-2"></i>
        ) : (
          <i className="fa fa-check mr-2"></i>
        )}

        {!isFollowing ? "Follow" : "Following"}
      </button>
    </div>
  );
};

export default FollowButton;
