import React from "react";
import Select from "react-select";

export default function Pagination({
  totalRecords,
  totalPage,
  recordsPerPage,
  page,
  onPageChange,
  onRecordsPerPageChange,
  limit,
  onLimitChange,
}) {
  const PER_PAGE_RECORD = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 75, label: "75" },
    { value: 100, label: "100" },
  ];

  const safeTotalPage = Number.isInteger(totalPage) && totalPage > 0 ? totalPage : 1;

  return (
    <div className="d-flex align-items-center justify-content-between pt-2 px-2 pagination-css">
      <div>Total Records : {totalRecords}</div>
      <div className="d-flex">
        <p className="mb-0 mr-1">Page : </p>
        <select
          className="pagination-select"
          onChange={(e) => onPageChange(Number(e.target.value))}
          value={page}
        >
          {Array.from(Array(safeTotalPage), (e, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>
      <div className="d-flex">
        <p className="mb-0 mr-1">Show : </p>
        <select
          className="pagination-select"
          onChange={(e) => onLimitChange(Number(e.target.value))}
          value={limit}
        >
          {PER_PAGE_RECORD?.map((item) => (
            <option key={item?.value}>{item.value}</option>
          ))}
        </select>
      </div>
    </div>
  );
}
