import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MDBBox } from "mdbreact";
import jwt_decode from "jwt-decode";

import Loading from "../ui-components/loading";

import { authorize } from "api/auth/authorization";
import { setJWT, tokenRef } from "api";
import addNotification from "helpers/notify";
import { useDispatch } from "react-redux";
import { useHttp } from "hooks/useHttp";
import { setUser } from "actions/user";
import capitalize from "helpers/capitalize";
import GA4 from "../../components/GA4";
import { eventTrack } from "../../helpers/ga4Helper";
import { url } from "api";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [stayedInSystem, setStayedInSystem] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, request: authorizeAPI } = useHttp({
    requestCallback: (data) => authorize(data),
    onLoad: (res) => {
      if (res.err) {
        addNotification(res.err, "danger");
      } else {
        localStorage.setItem(tokenRef, res.token);
        setJWT(res.token);
        const token = jwt_decode(res.token);
        dispatch(setUser(token));
        history.push(props.fromPath);
      }
    },
    onError: (err) => {
      addNotification(err.err, "danger");
    },
  });

  const submitAuthorization = (email, password) => (e) => {
    eventTrack(
      "Login-Action",
      "Login",
      history.location.pathname,
      email,
      false,
      "xhr"
    );
    e.preventDefault();
    if (email.length === 0) {
      addNotification("Email field must not be empty!", "danger");
    } else if (password.length === 0) {
      addNotification("Password field must not be empty!", "danger");
    } else {
      authorizeAPI({ email, password, keep: stayedInSystem });
    }
  };

  return (
    <form
      className="mt-1 px-4 box-login"
      onSubmit={submitAuthorization(email, password)}
    >
      <h2 className="text-center title-login">Login</h2>
      <div className="form-group">
        <div className="icon_login">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="mail"
            width="18"
            height="14.324"
            viewBox="0 0 18 14.324"
          >
            <path
              id="mail-2"
              data-name="mail"
              d="M3.01,5.838a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1V17.162a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2v-11a1.008,1.008,0,0,1,.01-.14ZM5,8.062v9.1H19v-9.1l-4.878,4.879a3,3,0,0,1-4.243,0ZM6.572,6.806H17.428l-4.721,4.721a1,1,0,0,1-1.414,0Z"
              transform="translate(-3 -4.838)"
              fill="#aeaeae"
            />
          </svg>
        </div>
        <input
          type="email"
          className="input-login"
          placeholder="Your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="position-relative">
        <div className="form-group">
          <div className="icon_login">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="lock"
              width="18"
              height="21"
              viewBox="0 0 18 21"
            >
              <path
                id="lock-2"
                data-name="lock"
                d="M18,10.5a3,3,0,0,1,3,3v6a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3v-6a3,3,0,0,1,3-3v-3a6,6,0,0,1,12,0Zm-6-7a4,4,0,0,1,4,4v3H8v-3A4,4,0,0,1,12,3.5Zm6,9H6a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1v-6A1,1,0,0,0,18,12.5Z"
                transform="translate(-3 -1.5)"
                fill="#aeaeae"
              />
            </svg>
          </div>
          <input
            type={showPassword ? "text" : "password"}
            className="input-login"
            placeholder="Your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <i
            className={`password-eye fa ${
              showPassword ? "fa-eye-slash" : "fa-eye"
            }`}
            onClick={() =>
              setShowPassword((prevShowPassword) => !prevShowPassword)
            }
          ></i>
        </div>
      </div>
      <div>
        <MDBBox className="d-flex justify-space-between mt-3">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input cursor-pointer"
              id="stay-in-system"
              onChange={() => setStayedInSystem((s) => !s)}
            />
            <label
              className="custom-control-label cursor-pointer"
              htmlFor="stay-in-system"
            >
              Keep me signed in
            </label>
          </div>
        </MDBBox>
      </div>
      {loading ? <Loading color="black" text="Authorizing..." /> : null}

      <div className="form-group">
        <input
          type="submit"
          className="btn-login-login"
          value="Login"
          disabled={loading}
        />
      </div>
      <GA4
        userId={email}
        category={"Navigation"}
        action={"Login-Action"}
        label={history.location.pathname}
        value={email}
        nonInteraction={false}
        transport={"xhr"}
      ></GA4>
    </form>
  );
};

export default Login;
