import axios from 'axios'

import { url } from 'api'

const baseURL = `${url}/api`

const publicFetch = axios.create({
  baseURL
})

export { publicFetch, baseURL }
