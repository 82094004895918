import React from "react";

import NavItem from "./nav-item";
import { World } from "../icons";

import styles from "./navigation.module.css";
import { useLocation } from "react-router";

const Navigation = () => {
  const router = useLocation();

  return (
    <div className="learning-sidebar">
      <p className="font-weight-bold">Learning</p>
      <NavItem
        href="/learning"
        selected={
          router.pathname == "/learning" ||
          router.pathname.split("/")[2] == "questions"
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="19"
          viewBox="0 0 22 19"
          fill="none"
        >
          <path
            d="M15 1.46776C16.4817 2.20411 17.5 3.73314 17.5 5.5C17.5 7.26686 16.4817 8.79589 15 9.53224M17 14.7664C18.5115 15.4503 19.8725 16.565 21 18M1 18C2.94649 15.5226 5.58918 14 8.5 14C11.4108 14 14.0535 15.5226 16 18M13 5.5C13 7.98528 10.9853 10 8.5 10C6.01472 10 4 7.98528 4 5.5C4 3.01472 6.01472 1 8.5 1C10.9853 1 13 3.01472 13 5.5Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        All
      </NavItem>
      <NavItem
        href="/learning/tags"
        selected={router.pathname == "/learning/tags"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="21"
          viewBox="0 0 18 21"
          fill="none"
        >
          <path
            d="M15 7H17C17.5523 7 18 7.44772 18 8V20C18 20.5523 17.5523 21 17 21H1C0.44772 21 0 20.5523 0 20V8C0 7.44772 0.44772 7 1 7H3V6C3 2.68629 5.68629 0 9 0C12.3137 0 15 2.68629 15 6V7ZM13 7V6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6V7H13ZM8 13V15H10V13H8ZM4 13V15H6V13H4ZM12 13V15H14V13H12Z"
            fill="black"
          />
        </svg>
        Tags
      </NavItem>

      <NavItem
        href="/learning/users"
        selected={router.pathname == "/learning/users"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="21"
          viewBox="0 0 18 21"
          fill="none"
        >
          <path
            d="M15 7H17C17.5523 7 18 7.44772 18 8V20C18 20.5523 17.5523 21 17 21H1C0.44772 21 0 20.5523 0 20V8C0 7.44772 0.44772 7 1 7H3V6C3 2.68629 5.68629 0 9 0C12.3137 0 15 2.68629 15 6V7ZM13 7V6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6V7H13ZM8 13V15H10V13H8ZM4 13V15H6V13H4ZM12 13V15H14V13H12Z"
            fill="black"
          />
        </svg>
        Users
      </NavItem>
    </div>
  );
};

export default Navigation;
