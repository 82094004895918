import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import FooterSpy from "./footerSpylink";

import linkedin from "assets/images/logo/linkd.png";
import fb from "assets/images/logo/face.png";
import youtube from "assets/images/logo/yt.png";
import twitter from "assets/images/logo/twit.png";
import logo from "assets/images/logo/logo.png";
import { eventTrack } from "helpers/ga4Helper";

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
const FooterComponent = () => {
  const location = useLocation();

  return (
    <>
      <div className="footer pt-2 pb-2">
        <div className="footer-container mt-5 mb-3">
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-baseline">
                <img src={logo} className="footer-logo" alt="Bidlight Logo" />
                <span
                  className="logo-title-footer"
                  style={{ color: "#000000 !important" }}
                >
                  <b>
                    id<span>Light</span>
                  </b>
                </span>
              </div>
              <p className="footer-desc">
                Bidlight publishes software and offers digital transformation
                services. BIM Execution Plan, Cloudbased Construction Ecosystem,
                and more ... Please use the links provided in the contact
                section to <br />
                reach out to us.
              </p>
              <div className="footer-social-container">
                <a
                  href="https://www.facebook.com/bidlightcom"
                  target="blank"
                  onClick={eventTrack(
                    "Navigate-Facebook-Action",
                    "Navigate-Facebook",
                    "Navigate-Facebook",
                    "+1",
                    false,
                    "+1"
                  )}
                >
                  <img src={fb} />
                </a>
                <a
                  href="https://twitter.com/BidlightUSA"
                  target="blank"
                  onClick={eventTrack(
                    "Navigate-Twitter-Action",
                    "Navigate-Twitter",
                    "Navigate-Twitter",
                    "+1",
                    false,
                    "+1"
                  )}
                >
                  <img src={twitter} />
                </a>
                <a
                  href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGk4YnTGLuxhAAAAX8sRUL4TK8Qny1WJ-ExI4UiVS1x2M56HkV5wjiLGvwsce5cphsnH42qhZBTKr-LyoFgZWeNwu8DfMo-uwpKfFEkzLoRnIWkS3peOc5tX6uY6iQwM2wcozs=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F68919562%2F"
                  target="blank"
                  onClick={eventTrack(
                    "Navigate-Linkedin-Action",
                    "Navigate-Linkedin",
                    "Navigate-Linkedin",
                    "+1",
                    false,
                    "+1"
                  )}
                >
                  <img src={linkedin} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCTOCHPiBAIOpWbUGQf_6ceg"
                  target="blank"
                  onClick={eventTrack(
                    "Navigate-Youtube-Action",
                    "Navigate-Youtube",
                    "Navigate-Youtube",
                    "+1",
                    false,
                    "+1"
                  )}
                >
                  <img src={youtube} />
                </a>
              </div>
            </div>
            <div className="col-md-8">
              {location.pathname !== "/personal-area" ? (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-5">
                    <p className="footer-head">Useful Links</p>
                    <FooterSpy />
                  </div>
                  <div className="col-md-4">
                    <p className="footer-head">Contact</p>
                    <div className="footer-links-div">
                      <Link to="">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                          />
                        </svg>{" "}
                        +1 928 478 1277
                      </Link>
                      <Link to="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                        support@bidlight.com
                      </Link>
                      <Link to="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        64 E Broadway Rd, Suite 200 Tempe, AZ 85281
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <p className="footer-head">Contact</p>
                    <div className="footer-links-div">
                      <Link to="">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                          />
                        </svg>{" "}
                        +1 928 478 1277
                      </Link>
                      <Link to="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                        support@bidlight.com
                      </Link>
                      <Link to="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        64 E Broadway Rd, Suite 200 Tempe, AZ 85281
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <p className="all-right-reserve">
            All rights reserved | BidLight 2022
          </p>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;
