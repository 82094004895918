import React, { useEffect, useState, useCallback } from "react";
import "react-input-range/lib/css/index.css";
import { getCountriesAndStatesData } from "api/countries";
import { useHttp } from "hooks/useHttp";
import { CollapseAbleTable } from "../../components/PricingCatalogs/CollapseableTable";
import { PricingCatalogsFiltrationBar } from "../../components/PricingCatalogs/PricingCatalogsFiltrationBar";
import { PricingCatalogHeader } from "../../components/PricingCatalogs/PricingCatalogHeader";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import handleBuildTree from "helpers/buildTree";
import {
  setTemplates,
  clearTemplates,
  setCountries,
  setAwps,
  setCategories,
  setProjectTypes,
  setLaborTypes,
  setAllCategories,
} from "actions/pricing";
import { setCatalogs, setCatalogsTree } from "actions/user";
import {
  getCatalogs,
  addNewCatalogs,
  getDataByEndpoint,
  getCategories,
  getExchangeRate,
} from "api/database";
import { MDBContainer } from "mdbreact";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import addNotification from "helpers/notify";
import { formats, unitsData } from "constants/utils";
import Loading from "components/ui-components/loading";

export default function PricingCatelogs() {
  const catalogs = useSelector((s) => s.user.catalogsTree);
  const templates = useSelector((s) => s.pricing.versions);
  const countries = useSelector((s) => s.pricing.countries);
  const [units, setUnits] = useState([]);
  const [template, setTemplate] = useState(null);
  const [appliedTemplate, setAppliedTemplate] = useState(null);
  const [seachedCatalogs, setSearchedCatalogs] = useState(catalogs);
  const [selectedCatalog, setSelectedCatalog] = useState([]);
  const [selectedCatalogsIds, setSelectedCatalogsIds] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [conversionRate, setConversionRate] = useState(1);
  const [page, setPage] = useState(0);
  const [searchCategoryQuery, setSearchCategoryQuery] = useState("");
  const [searchCategoryColumn, setSearchCategoryColumn] = useState("");
  const [countryState, setCountryState] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const [typesOfData, setTypesOfData] = useState(["bidlight"]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const { company, admin, companyAdmin } = userInfoRedux;

  const {
    loading: countriesLoading,
    error,
    request: countriesDataApi,
    refresh,
  } = useHttpWithCache({
    requestCallback: () => getCountriesAndStatesData(),
    reduxSetter: (res) => {
      dispatch(setCountries(res));
    },
    reduxReset: () => {
      dispatch(setCatalogsTree(null));
    },
    reduxCash: {
      catalogsTree: catalogs,
    },
    allowToCash: true,
  });

  const { loading: catalogsLoading, request: handleGetCatalogs } = useHttp({
    requestCallback: (data) => getCatalogs(data),
    onLoad: (res) => {
      return res;
    },
  });

  const { loading: dataLoading, request: handleGetDatabaseData } = useHttp({
    requestCallback: (query) => getDataByEndpoint(query),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("Error**", res);
    },
  });

  const { loading: categoryLoading, request: handleGetCategories } = useHttp({
    requestCallback: (query) => getCategories(query),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("Error**", res);
    },
  });

  const { loading: currecnyLoading, request: handleCurrencyExchange } = useHttp(
    {
      requestCallback: (data) => getExchangeRate(data),
      onLoad: (res) => {
        return res;
      },
      onError: (error) => {
        console.log("Currency Exchange Error**: ", error);
      },
    }
  );

  const { loading: postCatalogsLoading, request: handlePostNewCatalog } =
    useHttp({
      requestCallback: (data) =>
        addNewCatalogs(data?.protocol, data?.company, data?.companyRowsData),
      onLoad: (res) => {
        if (res.err) {
          addNotification(res.err, "danger");
        } else {
          return res;
        }
      },
      onError: (res) => {
        addNotification(
          res.err ?? "Something went wrong, please contact support",
          "danger"
        );
      },
    });

  const handleChangeTemplate = async (
    item,
    isNew = false,
    loadFromDatabase = true
  ) => {
    try {
      if (item) {
        item.company = item?.company ?? item?.default;
        setTemplate(item);
        const templateCountry = countries?.find(
          (country) => country.iso3 === item?.company?.Country
        );
        const templateState = templateCountry?.states?.find(
          (state) => state.state_code === item?.company?.State
        );
        const templateCity = templateState?.cities?.find(
          (city) => city?.name === item?.company?.City
        );
        setCountry(templateCountry);
        templateState ? setCountryState(templateState) : setCountryState(null);
        templateCity ? setCity(templateCity.name) : setCity(null);
        const breadcrumbData = [];
        if (templateCountry?.name) {
          breadcrumbData.push(templateCountry.name);
        }
        if (templateState?.name) {
          breadcrumbData.push(templateState.name);
        }
        if (templateCity?.name) {
          breadcrumbData.push(templateCity.name);
        }
        const baseCurrency = item?.company.Currency;

        setBreadcrumbData(breadcrumbData);
        if (loadFromDatabase) {
          const baseCurrencyRate = await hanldeExchangeUnitCost(baseCurrency);
          baseCurrencyRate
            ? setConversionRate(baseCurrencyRate)
            : setConversionRate(1);
          setAppliedTemplate(item);
          const protocol = formats[item?.company.Protocol];
          const payload = {
            Country: item.company.Country,
            City: item.company.City,
            ProjectType: item.company.ProjectType,
            LaborType: item.company.LaborType,
            State: item.company.State,
            catalogKey: protocol,
            OrganizationId: selectedCompany?.value ?? company,
          };

          const selectedUnits = unitsData.find(
            (unit) => unit.label === item?.company?.Measurements
          ).value;
          setUnits(selectedUnits);

          const response = await handleGetCatalogs(payload);
          const data = response.rows;

          const noCompanyRecords = data.filter(
            (catalog) => catalog.company === null
          );
          if (isNew && companyAdmin && noCompanyRecords.length) {
            const companyRowsData = noCompanyRecords.map((row) => {
              return {
                City: item.company?.City,
                Country: item.company?.Country,
                State: item.company?.State,
                LaborType: item.company?.LaborType,
                ProjectType: item.company?.ProjectType,
                Version: item.company?.Version,
                OrganizationId: selectedCompany?.value ?? company,
                Code: row.average ? row.average : row.default?.Code,
                Description: row.average
                  ? row.average
                  : row.default?.Description,
                Level: row.average ? row.average : row.default?.Level,
                UnitCost: parseInt(
                  row.average ? row.average : row.default?.UnitCost
                ),
                CategoryId: row?.average
                  ? row?.average
                  : row?.default?.CategoryId,
                Unit: row.average ? row.average : row.default?.Unit,
                Awp: row.average ? row.average : row.default?.Awp,
                TotalSimpleUnitCost: row.average
                  ? row.average
                  : row.default?.TotalSimpleUnitCost,
                EquipmentCodes: row.average
                  ? row.average
                  : row.default?.EquipmentCodes,
                CrewCodes: row.average ? row.average : row.default?.CrewCodes,
                isPublic: false,
                Quarter: row.average ? row.average : row.default?.Quarter,
                Year: row.average ? row.average : row.default?.Year,
              };
            });

            const newCompanyTemplates = await handlePostNewCatalog({
              protocol,
              company: selectedCompany?.value ?? company,
              companyRowsData,
            });

            if (newCompanyTemplates?.data?.length) {
              const companyResponse = await handleGetCatalogs(payload);
              getStructuredData(companyResponse?.rows, item);
            } else {
              addNotification(
                newCompanyTemplates?.data?.err ??
                  "Something went wrong while moving Catalogs!",
                "danger"
              );
            }
          } else {
            getStructuredData(data, item);
          }
        }
      }
    } catch (error) {
      console.log(error, "Error fetching the catalogs => ");
    }
  };

  const getStructuredData = (rows, selectedTemplate) => {
    const structuredData = rows.map((row) => {
      const defaultRow = row?.default && { ...row.default, ref: "default" };
      const averageRow = row?.average && {
        ...row.average,
        ref: "average",
        _id: Math.random(),
      };
      const oneBuildRow = row?.oneBuild
        ? { ...row.oneBuild, ref: "1build" }
        : null;
      const craftsmanRow = row?.craftsman
        ? { ...row.craftsman, ref: "craftsman" }
        : null;
      const companyRow = row?.company && { ...row.company, ref: "company" };
      let dataRow;
      if (admin && !selectedCompany?.value) {
        dataRow = {
          default: defaultRow || averageRow || companyRow,
          average: averageRow || defaultRow || companyRow,
          company: defaultRow
            ? { ...defaultRow }
            : averageRow
            ? { ...averageRow }
            : { ...companyRow },
          oneBuild: oneBuildRow,
          craftsman: craftsmanRow,
        };
      } else {
        dataRow = {
          default: defaultRow || averageRow || companyRow,
          average: averageRow || defaultRow || companyRow,
          company: companyRow
            ? { ...companyRow }
            : averageRow
            ? { ...averageRow }
            : { ...defaultRow },
          oneBuild: oneBuildRow,
          craftsman: craftsmanRow,
        };
      }

      return dataRow;
    });

    dispatch(setCatalogs(structuredData));

    const catalogsDataTree = handleBuildTree(
      structuredData,
      admin,
      selectedTemplate.company.Protocol
    );
    setTypesOfData(["bidlight"]);
    setSearchedCatalogs(catalogsDataTree.Children);
    dispatch(setCatalogsTree(catalogsDataTree.Children));
    setSelectedCatalogsIds([]);
    setSelectedCatalog([]);
  };

  const hanldeExchangeUnitCost = async (baseCurrency) => {
    if (!baseCurrency || baseCurrency === "USD") return 1;
    const currency = await handleCurrencyExchange({
      to: baseCurrency,
      from: "USD",
      value: 1,
    });
    return currency?.value ?? 1;
  };

  const getCategoriesWithSearch = useCallback(
    async (query) => {
      const categories = await handleGetCategories(query);
      if (categories?.rows) {
        dispatch(setCategories(categories?.rows));
      }
    },
    [page, searchCategoryQuery, searchCategoryColumn]
  );

  /**
   * useEffect to make API call onLoad through dynamic function.
   * Passing different type of params to get data drom `Curlight` API
   */
  // useEffect(() => {
  //   if (userInfoRedux?.company) {
  //     getData();
  //   }
  // }, [userInfoRedux.company]);

  const getData = async () => {
    // if(countries?.length === 0){
    // }
    countriesDataApi();
    setIsLoading("basic-data");
    const awpsResponse = await handleGetDatabaseData(`awps?OrganizationId=${userInfoRedux.company}`);
    const templatesResponse = await handleGetDatabaseData(`versions?OrganizationId=${userInfoRedux.company}`);
    const projectTypesResponse = await handleGetDatabaseData(`project_types?OrganizationId=${userInfoRedux.company}`);
    const laborTypesResponse = await handleGetDatabaseData(`labor_types?OrganizationId=${userInfoRedux.company}`);
    await getCategoriesWithSearch({
      skip: page * 50,
      limit: 50,
      search: "",
      column: "",
    });
    const allCategories = await handleGetCategories();
    handleChangeTemplate(templatesResponse.rows[0]);
    dispatch(setAwps(awpsResponse.rows));
    dispatch(clearTemplates());
    dispatch(setTemplates(templatesResponse.rows));
    dispatch(setProjectTypes(projectTypesResponse.rows));
    dispatch(setLaborTypes(laborTypesResponse.rows));
    dispatch(setAllCategories(allCategories.rows));
    setIsLoading("");
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      if (selectedCompany !== null) {
        console.log(selectedCompany);
        const templatesResponse = await handleGetDatabaseData(
          `versions?OrganizationId=${selectedCompany?.value}`
        );
        if (templatesResponse) {
          console.log(templatesResponse);
          handleChangeTemplate(templatesResponse?.rows[0]);
          dispatch(clearTemplates());
          dispatch(setTemplates(templatesResponse?.rows));
        }
      }
      else{
        getData();
      }
    };
  
    fetchTemplates();
  }, [selectedCompany]);
  

  if (isLoading === "basic-data") {
    return (
      <div className="bg-white min-h-100">
        <div className="mt-5 position-relative">
          <Loading text="loading" />
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <div className="">
        <div>
          <PricingCatalogHeader
            catalogs={seachedCatalogs}
            setSearchedCatalogs={(data) => setSearchedCatalogs(data)}
            selectedTemplate={appliedTemplate}
            setIsLoading={setIsLoading}
            refresh={refresh}
            setSelectedCompany={(data) => setSelectedCompany(data)}
          />
        </div>
        <div style={{ paddingTop: "24px" }}>
          <PricingCatalogsFiltrationBar
            onChangeTemplate={handleChangeTemplate}
            setSearchedCatalogs={(data) => setSearchedCatalogs(data)}
            setIsLoading={(state) => setIsLoading(state)}
            selectedCatalog={selectedCatalog}
            setSelectedCatalog={setSelectedCatalog}
            conversionRate={conversionRate}
            allTemplates={templates}
            key={templates}
            countryState={countryState}
            country={country}
            city={city}
            selectedTemplate={template}
            appliedTemplate={appliedTemplate}
            breadcrumbData={breadcrumbData}
            setTypesOfData={setTypesOfData}
            dataTypes={typesOfData}
            selectedCompany={selectedCompany}
          />
        </div>
        {catalogsLoading ||
        isLoading === "table-data" ||
        countriesLoading ||
        postCatalogsLoading ? (
          <Loading text="Loading components" />
        ) : (
          <MDBContainer size="2xl" className="pt-20">
            <CollapseAbleTable
              units={units}
              catalogs={seachedCatalogs}
              getCategoriesWithSearch={getCategoriesWithSearch}
              searchCategoryQuery={searchCategoryQuery}
              setSearchCategoryQuery={setSearchCategoryQuery}
              searchCategoryColumn={searchCategoryColumn}
              setSearchCategoryColumn={setSearchCategoryColumn}
              page={page}
              setPage={setPage}
              setSearchedCatalogs={setSearchedCatalogs}
              setSelectedCatalog={(catalog) => setSelectedCatalog(catalog)}
              selectedCatalog={selectedCatalog}
              appliedTemplate={appliedTemplate}
              conversionRate={conversionRate}
              setSelectedCatalogsIds={setSelectedCatalogsIds}
              selectedCatalogsIds={selectedCatalogsIds}
              dataTypes={typesOfData}
            />
          </MDBContainer>
        )}
      </div>
    </div>
  );
}
