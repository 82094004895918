import React, { useEffect, useRef, useState } from "react";

export default function Stats({ data }) {
  return (
    <div className="container-fluid">
      {/* first row */}
      <div className="row g-6 mt-3">
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="dashboard-cards card border-0">
            <div className="card-body p-3">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div
                    className="icon icon-shape text-white text-lg rounded-circle"
                    style={{ background: "#283f4b" }}
                  >
                    <i className="bi bi-people-fill"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex mt-2 align-items-center">
                    <p className="h4 font-weight-bold mb-0 poppins-bold">
                      {data?.activeUsers?.length}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <span className="dashboard-text">Total Active Users</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="dashboard-cards card border-0">
            <div className="card-body p-3">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div
                    className="icon icon-shape text-white text-lg rounded-circle"
                    style={{ background: "#283f4b" }}
                  >
                    <i className="bi bi-people"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex mt-2 align-items-center">
                    <p className="h4 font-weight-bold mb-0 poppins-bold">
                      {data?.inActiveUsers?.length}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <span className="dashboard-text">Total Inactive Users</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="dashboard-cards card border-0">
            <div className="card-body p-3">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div
                    className="icon icon-shape text-white text-lg rounded-circle"
                    style={{ background: "#283f4b" }}
                  >
                    <i className="bi bi-cash-stack"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex mt-2 align-items-center">
                    <p className="h4 font-weight-bold mb-0 poppins-bold">
                      ${data?.newEarnings}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <span className="dashboard-text">New Earnings</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
