import React, { useState } from "react";
import { Redirect, useParams } from "react-router";
import { useSelector } from "react-redux";
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBContainer,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBIcon,
} from "mdbreact";

import HowToCreateModal from "components/HowToCreateTimelineModal";
import NoItems from "components/ui-components/no-items";
import EditTimelineModal from "components/EditTimelineModal";
import DeleteTimelineModal from "components/DeleteTimelineModal/DeleteTimelineModal";

import { sortByDate, FROM_NEWER_TO_OLDER } from "helpers/sortByDate";
import { Maybe } from "helpers/maybeFunctor";
import formatTime from "helpers/formatTime";
import { url as urlApi } from "api";

import "./TimelinesPageAdmin.scss";

const TimelinePageAdmin = (props) => {
  const [modal, setModal] = useState("");
  const [timelineToEdit, setTimelineToEdit] = useState("");
  const [timelineToDelete, setTimelineToDelete] = useState("");
  const [linkToDownloadForJson, setLinkToDownloadForJson] = useState(null);

  const projectsRedux = useSelector((s) => s.admin.projects);
  const { companyId } = useParams();

  const mainAdmin = useSelector((s) => s.user.userInfo.admin);

  if (!projectsRedux) {
    return <Redirect to={`/main-admin/${companyId}`} />;
  }

  const neededProject = projectsRedux.find((projectItem) => {
    return projectItem._id === props.projectId;
  });

  const maybeTimelines = Maybe.of(neededProject.timelines)
    .map((timelines) => {
      return sortByDate(timelines, FROM_NEWER_TO_OLDER);
    })
    .map((timelines) => {
      return timelines.map((timelineItem) => {
        const { title, description, excel, excelQuant, zipInfo, date, _id } =
          timelineItem;

        return (
          <MDBCard className="main-bid-card mt-2 min-h-screen" key={_id}>
            <div className="w-100 m-auto">
            <MDBCardTitle className="d-flex justify-content-between">
              <span className="font-size-2">{title}</span>
              <span className="font-size-1">{formatTime(date)}</span>
            </MDBCardTitle>
            <MDBCardText className="font-size-1">{description}</MDBCardText>
            <MDBCardFooter className="bg-transparent p-0 border-0 d-flex justify-content-between">
              <MDBBtnGroup className="w-50">
                {/*<a
                  className="btn btn-sm text-white blue darken-2"
                  href={`${urlApi}/${pdf}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Download takeoff
                </a>*/}
                {linkToDownloadForJson}
                <a
                  className="bg-bid border-bid text-inherit text-white card-button d-flex align-items-center justify-content-center btn-radius ml-0"
                  href={`${urlApi}/${excel}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                ><i className="fa fa-download mr-2"></i>
                  Download Estimation
                </a>
                <a
                  className="bg-white border-bid text-inherit text-bid card-button d-flex align-items-center justify-content-center btn-radius"
                  href={`${urlApi}/${excelQuant}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                ><i className="fa fa-download mr-2"></i>
                  Download Quantity
                </a>
              </MDBBtnGroup>
              <MDBBtnGroup className="ml-auto">
                <MDBBtn
                  color=""
                  size="sm"
                  className="see-btn mr-3 action-btns ml-auto"
                  onClick={() => {
                    setModal("EditTimeline");
                    setTimelineToEdit(timelineItem);
                  }}
                >
                  <MDBIcon icon="edit" className="font-size-1" />
                </MDBBtn>
                <MDBBtn
                  color=""
                  size="sm"
                  className="dlt-btn action-btns"
                  onClick={() => {
                    setModal("DeleteTimeline");
                    setTimelineToDelete(timelineItem);
                  }}
                >
                  <MDBIcon icon="trash" className="font-size-1" />
                </MDBBtn>
              </MDBBtnGroup>
            </MDBCardFooter>
            </div>
          </MDBCard>
        );
      });
    });

  const { value: timelinesToJSX } = maybeTimelines;

  return (
    <div className="timelines-page">
      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between py-3">
          <p className="my-heading">
            Timelines{" "}
            {mainAdmin ? (
              <MDBBtn
                size="sm"
                className="pink darken-1"
                onClick={() => setModal("HowToCreateTimeline")}
              >
                How to create timeline?
              </MDBBtn>
            ) : null}
            
          </p>
          <div className="d-flex align-items-center project-admin-header ">
           <i className="fa fa-home mr-2"></i> Project: 
            <strong className="ml-2">{neededProject.title}</strong>
          </div>
        </div>
      </div>
      <MDBContainer size="lg" className="pt-12">
        <MDBBox className="pt-3">
          <div className="d-flex flex-column">
            {neededProject.timelines.length === 0 ||
            !neededProject.timelines ? (
              <NoItems text="This project has no timelines" />
            ) : null}
            {timelinesToJSX}
          </div>
        </MDBBox>
      </MDBContainer>
      <HowToCreateModal
        id={neededProject._id}
        isOpen={modal === "HowToCreateTimeline"}
        toggle={() => setModal("")}
      />
      {modal === "EditTimeline" && (
        <EditTimelineModal
          isOpen={modal === "EditTimeline"}
          timelineItem={timelineToEdit}
          toggle={() => setModal("")}
          clear={() => setTimelineToEdit(null)}
        />
      )}
      <DeleteTimelineModal
        isOpen={modal === "DeleteTimeline"}
        toggle={() => setModal("")}
        itemToDelete={
          timelineToDelete ? { timelines_id: timelineToDelete._id } : null
        }
      />
    </div>
  );
};

export default TimelinePageAdmin;
