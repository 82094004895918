import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { MDBBox, MDBBtn, MDBRow } from "mdbreact";
import { useSelector } from "react-redux";

import ProjectItemMainAdmin from "components/ProjectItemMainAdmin";
import AddprojectAdmin from "./AddprojectAdmin";
import MemberModalAdmin from "./MemberModalAdmin";

const MainAdminProjects = (props) => {
  const { idCompany } = useParams();

  const companiesRedux = useSelector((s) => s.mainAdmin.company_projects);
  const companiesReduxAll= useSelector((s) => s.mainAdmin);

  const usersRedux = useSelector((s) => s.mainAdmin.all_users);

  const [modal, setModal] = useState("");
  const [parents, setParents] = useState([]);
  const [projectListToEdit, setProjectListToEdit] = useState(null);

  const company = companiesRedux?.find(
    (companyItem) => companyItem?._id === idCompany
  );

  const companyUser = companiesReduxAll?.company?.find(
    (companyItem) => companyItem?._id === idCompany
  )?.users


  // console.log(company);
  // console.log(companiesReduxAll);


  useEffect(() => {
    setParents(company?.projects?.filter((item) => item.isParent == true));
  }, [company?.projects]);

  if (!companiesRedux) {
    return <Redirect to="/main-admin" />;
  }

  return (
    <div className="custom-container">
      <MDBBox className="pt-2 w-100">
        <MDBBox className="d-flex justify-content-between align-items-center">
          <h2>{company?.companyName} projects</h2>
          <div
            onClick={() => setModal("CreateModal")}
            data-toggle="modal"
            data-target="#exampleModalCenter"
            className="header-btn bg-bid text-white border-bid rounded-lg mr-0 mb-0 cursor-pointer f-14 roboto font-weight-normal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <path
                d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                fill="#fff"
              />
            </svg>{" "}
            <span className="ml-2 font-weight-normal">Add Projects</span>
          </div>
        </MDBBox>
        <MDBRow className="row-cols-lg-4 row-cols-sm-3 row-cols-1 mt-3">
          {company.projects.map((project, index) => {
            const userWhoCreateTest = usersRedux.find(
              (user) => project.user === user._id
            );
            return (
              <ProjectItemMainAdmin
                key={project._id}
                projectItem={project}
                className={index > 2 ? "mt-3" : ""}
                userWhoCreate={userWhoCreateTest}
                openMembersModal={() => {
                  setProjectListToEdit(project);
                  setModal("ProjectListToEdit");
                }}
                parentProjectID={project?.parentProjectID}
                isParent={project?.isParent}
              />
            );
          })}
        </MDBRow>
 
        <AddprojectAdmin
          parents={parents}
          isOpen={modal === "CreateModal"}
          toggle={() => setModal("")}
          data={company}
        />

        <MemberModalAdmin
          open={modal === "ProjectListToEdit"}
          toggle={() => setModal("")}
          projectListToEdit={projectListToEdit ? projectListToEdit._id : null}
          companyUser={companyUser}
          companyProjects={company}
        />
      </MDBBox>
    </div>
  );
};

export default MainAdminProjects;
