export class Maybe {
  static of(x) {
    return new Maybe(x);
  }

  get isNothing() {
    return this.value === null || this.value === undefined;
  }

  constructor(x) {
    this.value = x;
  }

  map(fn) {
    return this.isNothing ? this : Maybe.of(fn(this.value));
  }
}
