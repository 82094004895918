import React, { useContext, useEffect, useState } from "react";

import Tag from "../../tag";
import { Spinner } from "../../icons";

import styles from "./extra.module.css";
import { publicFetch } from "learning/util/fetcher";

const Extra = ({ marginTop = 24 }) => {

  const [tagState, setTagState] = useState(null)

  useEffect(() => {
    const fetchPopularTags = async () => {
      const { data } = await publicFetch.get('/tags/populertags')
      setTagState(data)
    }

    fetchPopularTags()
  }, [])

  return (
    <div className={styles.container}>
      <div
        className={styles.tagContainer}
        style={{ marginTop: `${marginTop}px` }}
      >
        <h2>Popular Tags</h2>
        {!tagState && (
          <div className="loading">
            <Spinner />
          </div>
        )}
        <div className={styles.popularTags}>
          {tagState?.map((tag) => (
            <Tag key={tag._id} count={tag.count}>
              {tag._id}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Extra;
