import React from "react";
import TopNavbar from "pages/configuration/navbar";
import Sidebar from "pages/configuration/sidebar";
import Loader from "components/Loader";
import { Inbox } from "./Inbox";

export const SupportChat = () => {
  const handleSearch = () => {};
  return (
    <div className="personal-area">
      {/* {loadingGetSettings && <Loader />} */}
      <TopNavbar handleSearch={handleSearch} />

      <div className="content-bg p-2">
        <div className="container d-flex mt-1">
          <Sidebar />

          <div className="profile-content px-0 pt-0">
            <div className="w-100 px-0">
              <div className="d-flex justify-content-between px-3 py-3 border-bottom">
                <div className="d-flex align-items-center">
                  <div className="heading-svg-wrapper">
                    <i className="fa fa-comments"></i>
                  </div>
                  <span className="font-weight-bold ml-3 text-black">
                    Support Chat
                  </span>
                </div>
              </div>
              <Inbox />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
