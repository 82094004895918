import { useCallback, useState, useRef, useEffect } from 'react';

const useHttpWithCache = config => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const isMountedRef = useRef(true);

  const {
    requestCallback,
    reduxSetter,
    reduxCash,
    reduxReset,
    allowToCash = true,
  } = config;

  const request = useCallback(() => {
    const ifNulls = Object.entries(reduxCash).some(
      cashItem => cashItem[1] === null,
    );

    if (!ifNulls && allowToCash) {
      setLoading(false);
      setError(false);
      reduxSetter(reduxCash);
    } else {
      if (!allowToCash) {
        reduxReset();
      }
      requestCallback()
        .then(res => {
          if (isMountedRef.current) {
            setLoading(false);
            reduxSetter(res);
          }
          return res;
        })
        .catch(res => {
          if (isMountedRef.current) {
            setError(true);
            setErrorStatus(res);
            setLoading(false);
          }
          return res;
        });
    }
  }, [allowToCash, reduxCash, reduxReset, reduxSetter, requestCallback]);

  const refresh = useCallback(() => {
    reduxReset();
    setLoading(true);
    setError(false);

    requestCallback()
      .then(res => {
        if (isMountedRef.current) {
          setLoading(false);
          reduxSetter(res);
        }
        return res;
      })
      .catch(res => {
        if (isMountedRef.current) {
          setError(true);
          setLoading(false);
        }
        return res;
      });
  }, [reduxReset, reduxSetter, requestCallback]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return { loading, error, errorStatus, request, refresh };
};

export { useHttpWithCache };
