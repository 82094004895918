import React from "react";

export const RedTrendIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3V19H21V21H3V3H5ZM19.9393 5.93934L22.0607 8.06066L16 14.1213L13 11.121L9.06066 15.0607L6.93934 12.9393L13 6.87868L16 9.879L19.9393 5.93934Z"
        fill="#B22222"
      />
    </svg>
  );
};

export const GreenTrendIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 3V19H21V21H3V3H5Z" fill="#3B873E" />
      <path
        d="M9.11566 6L7.00101 8.12132L13.0424 14.182L16.0329 11.1817L19.9597 15.1214L22.0742 13L16.0329 6.93934L13.0424 9.93966L9.11566 6Z"
        fill="#3B873E"
      />
    </svg>
  );
};

export const BlueTrendIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 3V19H21V21H3V3H5Z" fill="#0000FF" />
      <path
        d="M9.11566 6L7.00101 8.12132L13.0424 14.182L16.0329 11.1817L19.9597 15.1214L22.0742 13L16.0329 6.93934L13.0424 9.93966L9.11566 6Z"
        fill="#0000FF"
      />
    </svg>
  );
};

export const YelloTrendIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3V19H21V21H3V3H5ZM19.9393 5.93934L22.0607 8.06066L16 14.1213L13 11.121L9.06066 15.0607L6.93934 12.9393L13 6.87868L16 9.879L19.9393 5.93934Z"
        fill="#FFFF00"
      />
    </svg>
  );
};


