import React, { useEffect, useState, useRef } from "react";
import { Drawer } from "antd";
import Logo from "assets/images/logo/logo1.png";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { saveAs } from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";
import { eventTrack } from "helpers/ga4Helper";

export default function Preview({
  data,
  openPreview,
  setOpenPreview,
  disciplines,
}) {
  console.log(data);
  console.log(disciplines);

  const [fileData, setFileData] = useState([]);
  const componentRef = useRef();

  useEffect(() => {
    setFileData(data ? data : []);
  }, [data]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGeneratePDF = async () => {
    const input = componentRef.current;
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "png", 0, 0, 210, 0);
    pdf.save("download.pdf");
  };

  const handleDownloadDocx = () => {
    const content = componentRef.current.innerHTML;
    const blob = htmlDocx.asBlob(content);
    saveAs(blob, "document.docx");
  };

  const onClose = () => {
    setOpenPreview(false);
  };

  const Header = () => {
    return (
      <div className="btn-tool-group d-flex justify-content-end mt-0">
        <button
          className="bg-bid px-3 mr-2"
          onClick={() => {
            handlePrint();
            eventTrack(
              "Print-Doc-Control-Action",
              "Print-Doc-Control",
              "Document-Control",
              "+1",
              false,
              "+1"
            );
          }}
        >
          <i className="fa fa-print mr-2" aria-hidden="true"></i>
          Print
        </button>
        <button
          className="bg-bid px-3 mr-0"
          onClick={() => {
            handleGeneratePDF();
            eventTrack(
              "Generate-PDF-Doc-Control-Action",
              "Generate-PDF-Doc-Control",
              "Document-Control",
              "+1",
              false,
              "+1"
            );
          }}
        >
          <i className="fa fa-file-pdf-o mr-2" aria-hidden="true"></i>
          Download PDF
        </button>
        <button
          className="bg-bid px-3 mr-0"
          onClick={() => {
            handleDownloadDocx();
            eventTrack(
              "Download-DOCX-Doc-Control-Action",
              "Download-DOCX-Doc-Control",
              "Document-Control",
              "+1",
              false,
              "+1"
            );
          }}
        >
          <i className="fa fa-file-text mr-2" aria-hidden="true"></i>
          Download DOCX
        </button>
      </div>
    );
  };

  const getDistinctObjects = (arr, key) => {
    const uniqueObjects = new Map();

    arr.forEach((item) => {
      if (!uniqueObjects.has(item[key])) {
        uniqueObjects.set(item[key], item);
      }
    });

    return Array.from(uniqueObjects.values());
  };

  const getDistinctProjectIds = (arr) => {
    const uniqueProjects = new Map();

    arr.forEach((item) => {
      if (item.projectId && !uniqueProjects.has(item.projectId._id)) {
        uniqueProjects.set(item.projectId._id, item.projectId);
      }
    });

    return Array.from(uniqueProjects.values());
  };

  const getDistinctFloorLevels = (arr) => {
    const uniqueFloorLevels = new Set();

    arr.forEach((item) => {
      if (Array.isArray(item.floorLevel)) {
        item.floorLevel.forEach((level) => {
          uniqueFloorLevels.add(level);
        });
      }
    });

    return Array.from(uniqueFloorLevels);
  };

  const getDistinctRevisionDates = (arr) => {
    const uniqueRevisionDates = new Set();

    arr.forEach((item) => {
      if (moment(item?.revisionDate).format("DD/MM/YYYY")) {
        uniqueRevisionDates.add(
          moment(item?.revisionDate).format("DD/MM/YYYY")
        );
      }
    });

    return Array.from(uniqueRevisionDates);
  };
  return (
    <>
      <Drawer
        title={<Header />}
        placement="right"
        closable={true}
        onClose={() => onClose()}
        open={openPreview}
        key="left"
        width={1300}
      >
        <div
          ref={componentRef}
          style={{
            width: "100%",
            background: "#fff",
            padding: "50px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "0px",
          }}
        >
          <div
            style={{
              textAlign: "left",
              marginBottom: "20px",
              border: "none",
              background: "#fff",
            }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{
                width: "110px",
              }}
            />
          </div>

          <div
            style={{
              textAlign: "left",
              marginBottom: "20px",
              background: "#fff",
            }}
          >
            <p
              style={{
                marginBottom: "20px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Drawing Index Sheet
            </p>
          </div>

          {/* Index Table */}
          <table
            className="content"
            style={{
              border: "1px solid #ddd",
              borderCollapse: "collapse",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <tbody>
              <tr
                className="field"
                // style={{
                //   border: "1px solid #ddd",
                //   borderCollapse: "collapse",
                // }}
              >
                <td
                  style={{
                    fontweight: "bold",
                    width: "250px",
                    minWidth: "250px",
                    maxWidth: "250px",

                    padding: "10px",

                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  Revit File Name:
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  {getDistinctObjects(fileData, "revitFileName")?.map(
                    (dist) => dist?.revitFileName + ", "
                  )}
                </td>
              </tr>
              <tr
                className="field"
                // style={{
                //   border: "1px solid #ddd",
                //   borderCollapse: "collapse",
                // }}
              >
                <td
                  style={{
                    fontweight: "bold",
                    width: "250px",
                    minWidth: "250px",
                    maxWidth: "250px",

                    padding: "10px",

                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  Project Name:
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  {getDistinctProjectIds(fileData)?.map((item) => item?.title)}
                </td>
              </tr>
              <tr
                className="field"
                // style={{
                //   border: "1px solid #ddd",
                //   borderCollapse: "collapse",
                // }}
              >
                <td
                  style={{
                    fontweight: "bold",
                    width: "250px",
                    minWidth: "250px",
                    maxWidth: "250px",

                    padding: "10px",

                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  Floor Level:
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  {getDistinctFloorLevels(fileData)?.map((item) => item + ", ")}
                </td>
              </tr>
            </tbody>
          </table>

          {/* Disciplines */}
          <table
            className="content"
            style={{
              border: "1px solid #ddd",
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "50px",
              backgroundColor: "#fff",
            }}
          >
            <tbody>
              <tr
                className="field"
                style={{
                  border: "1px solid #ddd",
                  borderCollapse: "collapse",
                  backgroundColor: "#fff",
                }}
              >
                <td
                  style={{
                    fontweight: "bold",
                    width: "250px",
                    minWidth: "250px",
                    maxWidth: "250px",

                    padding: "10px",

                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  All Disciplines
                </td>

                {disciplines?.map((item, key) => (
                  <td
                    key={key}
                    style={{
                      padding: "10px",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                      borderCollapse: "collapse",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "normal",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{
                          marginRight: "10px",
                          width: "15px",
                          height: "15px",
                        }}
                        checked={
                          fileData?.some(
                            (i) =>
                              i?.discipline?.key.toLowerCase() ===
                              item?.key.toLowerCase()
                          ) || false
                        }
                      />
                      {item?.key}
                    </div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>

          {/* All Tables */}

          <table
            className="content"
            style={{
              border: "1px solid #ddd",
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "50px",
              backgroundColor: "#fff",
            }}
          >
            <thead>
              <tr
                className="field"
                style={{
                  border: "1px solid #ddd",
                  borderCollapse: "collapse",
                  backgroundColor: "#eaeaea",
                }}
              >
                <th
                  style={{
                    fontweight: "bold",
                    padding: "10px",
                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  Discipline
                </th>
                <th
                  style={{
                    fontweight: "bold",
                    padding: "10px",
                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  Sheet Name
                </th>
                <th
                  style={{
                    fontweight: "bold",
                    padding: "10px",
                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    fontweight: "bold",
                    padding: "10px",
                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  Revision Number
                </th>

                <th
                  style={{
                    fontweight: "bold",
                    padding: "10px",
                    color: "#2a2929",
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {fileData?.map((item, key) => (
                <tr
                  className="field"
                  style={{
                    border: "1px solid #ddd",
                    borderCollapse: "collapse",
                    background: "#fff",
                  }}
                >
                  <td
                    style={{
                      padding: "10px",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                      borderCollapse: "collapse",
                    }}
                  >
                    {item?.discipline?.key ?? "N/A"}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                      borderCollapse: "collapse",
                    }}
                  >
                    {item?.sheetName}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                      borderCollapse: "collapse",
                    }}
                  >
                    {moment(item?.revisionDate).format("DD/MM/YYYY")}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                      borderCollapse: "collapse",
                    }}
                  >
                    {item?.revisionNumber}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                      borderCollapse: "collapse",
                    }}
                  >
                    {item?.sheetStatus}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Drawer>
    </>
  );
}
