import React, { useState } from "react";
import styled from "styled-components";
import "./slider.scss";

export default function CurvedRangeSlider({ totalScore, status }) {
  // const [inputValue, setInputValue] = useState(totalScore);
  const ProgressBarContainer = styled.div`
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
      conic-gradient(
        ${(status === 'Fail')
            ? "#ffa500"
            : (status === 'Pass')
            ? "rgba(255, 165, 0, 1)"
            : (status === 'Great')
            ? "#3b873e"
            : ""}
          ${totalScore}%,
        ${(status === 'Fail')
            ? "#f8e9e9"
            : (status === 'Pass')
            ? "#fff6e6"
            : (status === 'Great')
            ? "#eefaea"
            : ""}
          0
      );
  `;

  return (
    <div>
      <ProgressBarContainer>
        <progress
          value={totalScore}
          min="0"
          max="100"
          style={{ visibility: "hidden", height: 0, width: 0 }}
        ></progress>
        <p className="curvedScore inter">{totalScore}%</p>
      </ProgressBarContainer>
    </div>
  );
}
