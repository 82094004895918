
import rootReducer from './reducers';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools,devToolsEnhancer, actionCreators } from 'redux-devtools-extension';



const composeEnhancers = composeWithDevTools({ actionCreators, trace: true });


const store = createStore(rootReducer, composeEnhancers(applyMiddleware()));



export default store;
