import React, { useState } from "react";
import { MDBBox } from "mdbreact";

import Loading from "../ui-components/loading";

import { postLicence } from "api/licences/postLicence";
import addNotification from "helpers/notify";
import { useHttp } from "hooks/useHttp";
import { eventTrack } from "../../helpers/ga4Helper";
import { useHistory } from "react-router-dom";

const GetLicence = ({ setOpenAddCompany }) => {
  const [email, setEmail] = useState("");
  const [rateValue, setRateValue] = useState("");
  const [rate, setRate] = useState("Hourly");

  const rates = [
    {
      label: "Hourly",
      value: "Hourly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Yearly",
      value: "Yearly",
    },
  ];

  const history = useHistory();

  const { loading: loading, request: submitLicenceAPI } = useHttp({
    requestCallback: (data) => postLicence(data),
    onLoad: (res) => {
      setEmail("");
      setRateValue("");
      setOpenAddCompany(false);
      if (res.err) {
        addNotification(res.err, "danger");
      } else {
        addNotification(
          "An email has been sent with a link to register your company",
          "success"
        );
      }
    },
    onError: (err) => {
      setEmail("");
      setRateValue("");
      addNotification(err.err, "danger");
    },
  });

  const submitLicence = (e) => {
    eventTrack(
      "License-Action",
      "LicenseRequest",
      history.location.pathname,
      email,
      false,
      "xhr"
    );
    e.preventDefault();
    submitLicenceAPI({ mail: email, rate: rate, rateValue: rateValue });
  };

  let handleRateChange = (e) => {
    var r = JSON.parse(e.target.value);
    setRate(r.value);
  };

  return (
    <MDBBox className="text-center d-flex flex-column p-0">
      <form onSubmit={submitLicence}>
        <div className="form-group mt-0">
          <div className="icon_login">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="mail"
              width="18"
              height="14.324"
              viewBox="0 0 18 14.324"
            >
              <path
                id="mail-2"
                data-name="mail"
                d="M3.01,5.838a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1V17.162a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2v-11a1.008,1.008,0,0,1,.01-.14ZM5,8.062v9.1H19v-9.1l-4.878,4.879a3,3,0,0,1-4.243,0ZM6.572,6.806H17.428l-4.721,4.721a1,1,0,0,1-1.414,0Z"
                transform="translate(-3 -4.838)"
                fill="#aeaeae"
              />
            </svg>
          </div>
          <input
            type="email"
            className="input-login"
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="d-flex mt-3 gap-3">
          <div className="form-group mt-0 w-100">
            <div className="icon_login">
              <i className="fa fa-clock"></i>
            </div>

            <select onChange={handleRateChange} className="input-login">
              {rates.map((rate, index) => (
                <option value={JSON.stringify(rates[index])} key={index}>
                  {rate.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mt-0 w-100">
            <div className="icon_login">
              <i className="fa fa-usd"></i>
            </div>
            <input
              type="number"
              value={rateValue}
              onChange={(e) => setRateValue(e.target.value)}
              className="input-login"
              required
            />
          </div>
        </div>

        <div className="form-group">
          <input
            type="submit"
            className="btn-login-login bg-grey-blue bg-bid"
            value="Add Company"
            disabled={loading}
          />
        </div>
        {loading ? <Loading color="black" text="Adding..." /> : null}
      </form>
    </MDBBox>
  );
};

export default GetLicence;
