import React from 'react'
import cn from 'classnames'

import Button from '../button'

import styles from './button-group.module.css'

const ButtonGroup = ({
  buttons,
  selected,
  setSelected,
  borderBottom = false,
  className
}) => {
  return (
    <div className='learning-btn-group'>
      {buttons.map((button) => (
        <Button
          key={button}
          className={selected === button && "active"}
          onClick={() => setSelected(button)}
        >
          {button}
        </Button>
      ))}
    </div>
  )
}

export default ButtonGroup
