import ReactGA from "react-ga4";



const timeDifference = (current,previous) => {
  const milliSecondsPerMinute = 60*1000
  const milliSecondsPerHour = milliSecondsPerMinute * 60
  const milliSecondsPerDay = milliSecondsPerHour * 24
  const milliSecondsPerMonth = milliSecondsPerDay * 30
  const milliSecondsPerYear = milliSecondsPerDay * 365
  const elapsed = current - previous
  if(elapsed < milliSecondsPerMinute / 3){
    return 'just now'
  }
  if(elapsed<milliSecondsPerMinute) {
    return 'Less than a minute'
  }else if(elapsed<milliSecondsPerHour){
    return Math.round(elapsed/milliSecondsPerMinute) + ' min ago'
  }else if(elapsed<milliSecondsPerDay){
    return Math.round(elapsed/milliSecondsPerHour) + 'h ago'
  }else if(elapsed<milliSecondsPerMonth){
    return Math.round(elapsed/milliSecondsPerDay) + ' days ago'
  }else if (elapsed<milliSecondsPerYear){
    return Math.round(elapsed/milliSecondsPerMonth) + ' mo ago'
  }else{
    return Math.round(elapsed/milliSecondsPerYear) + ' years ago'
  }
}

export const timeDifferenceForDate = (date) => {
  const now = new Date().getTime()
  const updated = new Date(date).getTime()
  return timeDifference(now,updated)
}



export const googleAnalytics4 = (userId) =>{ReactGA.initialize(
  "G-W482SV8S8S",
    [{
      legacyDimensionMetric: true,
      nonce: '',
      testMode: false,
      gaOptions: {'userId': userId,'debug_mode': false},
      gtagOptions:{ 'user_id': userId,'debug_mode': false}, //'send_page_view': true, 'debug_mode': false,

    },
    {
      trackingId: "UA-223995228-1",
    }
    
     
    ],{ debug: false}
 )
 if (ReactGA.isInitialized) {
  ReactGA.gtag('config', 'G-W482SV8S8S',{ 'debug_mode':false }); //,{ 'debug_mode':true }
  
  ReactGA.gtag('set', {'user_id': userId});
  ReactGA.ga('set', 'userId', userId);

ReactGA.gtag('consent', 'update', {
  'ad_storage': 'granted',
  'analytics_storage': 'granted'
})
}

}


export const eventTrack = (category, action, label,  value,  nonInteraction) =>{
   // console.log("GA event:", category, ":", action, ":", label, ":", value, ":", nonInteraction, ":", transport);
  
  
    ReactGA.event({
        category: category,
        action: action,
        label: label, // optional
        value: value, // optional, must be a number
        nonInteraction: nonInteraction, // optional, true/false
        transport: 'xhr', // optional, beacon/xhr/image
      });
  
      
  
  }