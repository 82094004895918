import React, { useEffect, useState, useRef } from "react";
import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";

import { useHttp } from "../../hooks/useHttp";
import { useHistory } from "react-router";
import { createUser } from "../../api/auth/registration";
import addNotification from "../../helpers/notify";
import withKey from "../../components/hoc/withKey";
import Loading from "../../components/ui-components/loading";
import UploadIcon from "assets/images/icons/Featured icon.png";

import defaultLogo from "assets/images/logo/logo-blue.png";
import { uploadFileToServer } from "components/uploadFile/uploadFile";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const RegistrationUserAdmin = (props) => {
  const { email, key, role } = props.licenseData;
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [username, setUsername] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
  const [companyOwner, setCompanyOwner] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyMainColor, setCompanyMainColor] = useState("");
  const [companySecondColor, setCompanySecondColor] = useState("");

  // import tt from '../../assets/images/ec.png'
  useEffect(() => {
    return () => {
      setName("");
      setLastName("");
      setCity("");
      setPassword("");
      setUsername("");
      setRepeatPassword("");
      setCountry("");
      setCompanyName("");
      setCompanyAddress("");
      setCompanyPhoneNumber("");
      setCompanyOwner("");
      setCompanySize("");
      setCompanyIndustry("");
      setCompanyLogo("");
      setCompanyMainColor("");
      setCompanySecondColor("");
    };
  }, []);

  const history = useHistory();
  const companyLogoRef = useRef(null);

  const { loading: loadingRegistraion, request: createUserAPI } = useHttp({
    requestCallback: createUser,
    onLoad: (res) => {
      if (res.err) {
        addNotification(res.err, "danger");
      } else {
        addNotification("You succesfully registered a new user!", "success");
        history.push("/");
      }
    },
    onError: (err) => {
      addNotification(err.err, "danger");
    },
  });

  const submitRegistration = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      addNotification("Password doesn't have enough chars", "danger");
    } else if (password !== repeatPassword) {
      addNotification("Password mismatch", "danger");
    } else {
      var fileUrl;
      fileUrl = await uploadFileToServer(companyLogo, true);
      console.log(fileUrl);
      if (fileUrl === null) {
        addNotification(
          "Something went wrong while uploading company logo",
          "danger"
        );
        return;
      }

      const newUser = {
        email,
        name,
        lastName,
        username,
        country,
        city,
        password,
        companyName,
        companyAddress,
        companyPhoneNumber,
        companyOwner,
        companySize,
        companyIndustry,
        companyLogo: fileUrl,
        companyMainColor,
        companySecondColor,
        key,
        role,
      };
      // console.log(newUser);
      return createUserAPI(newUser);
    }
  };

  return (
    <>
      <div className="position-fixed registration-image"></div>
      <div className="d-flex align-items-center justify-content-center h-100 w-100 position-absolute">
        <div className="registration_box admin-register-box">
          <p className="reg-header">Registration</p>
          <hr />

          <form
            onSubmit={submitRegistration}
            className="reg_form"
            id="registerfrmid"
          >
            <p className="reg-subheader my-3">Personal Information</p>
            <div className="form-group mt-0 w-100">
              <div className="icon_login icon_login_1">
                <i className="fa fa-user"></i>
              </div>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="input-login"
                required
                placeholder="First Name"
              />
            </div>

            <div className="form-group mt-0 w-100">
              <div className="icon_login icon_login_1">
                <i className="fa fa-user"></i>
              </div>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="input-login"
                required
                placeholder="Last Name"
              />
            </div>

            <div className="form-group mt-0 w-100">
              <div className="icon_login icon_login_1">
                <i className="fa fa-user"></i>
              </div>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="input-login"
                required
                placeholder="Username"
              />
            </div>

            <MDBRow>
              <MDBCol size="6">
                <div className="form-group mt-0 w-100">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-envelope"></i>
                  </div>
                  <input
                    type="email"
                    autoComplete="off"
                    value={email}
                    // onChange={(e) => setLastName(e.target.value)}
                    className="input-login"
                    disabled
                    required
                    placeholder="Email"
                  />
                </div>
              </MDBCol>
              <MDBCol size="6">
                <div className="form-group mt-0 w-100">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-tasks"></i>
                  </div>
                  <input
                    type="text"
                    autoComplete="off"
                    value={role}
                    // onChange={(e) => setLastName(e.target.value)}
                    className="input-login"
                    disabled
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow className="w-100 m-auto">
              <MDBCol size="6" className="pl-0">
                <div className="form-group mt-0 w-100">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-key"></i>
                  </div>
                  <input
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-login"
                    required
                    placeholder="Password"
                  />
                </div>
              </MDBCol>
              <MDBCol size="6" className="pr-0">
                <div className="form-group mt-0 w-100">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-key"></i>
                  </div>
                  <input
                    type="password"
                    autoComplete="off"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    className="input-login"
                    required
                    placeholder="Repeat Password"
                  />
                </div>
              </MDBCol>
              <MDBCol size="6" className="pl-0">
                <div className="form-group mt-0 w-100">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-flag"></i>
                  </div>
                  <input
                    type="text"
                    autoComplete="off"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="input-login"
                    required
                    placeholder="Country"
                  />
                </div>
              </MDBCol>
              <MDBCol size="6" className="pr-0">
                <div className="form-group mt-0 w-100">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-flag"></i>
                  </div>
                  <input
                    type="text"
                    autoComplete="off"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="input-login"
                    required
                    placeholder="City"
                  />
                </div>
              </MDBCol>
            </MDBRow>

            <p className="reg-subheader my-3">Company Information</p>
            <div className="form-group">
              <div className="icon_login icon_login_1">
                <i className="fa fa-address-card"></i>
              </div>
              <input
                type="text"
                placeholder="Company Name"
                autoComplete="off"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
                className="input-login"
              />
            </div>

            <div className="form-group">
              <div className="icon_login icon_login_1">
                <i className="fa fa-building"></i>
              </div>
              <input
                type="text"
                placeholder="Company Address"
                autoComplete="off"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                required
              />
            </div>

            <MDBRow className="w-100 m-auto">
              <MDBCol size="6" className="pl-0">
                <div className="form-group">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-address-book"></i>
                  </div>
                  <input
                    type="tel"
                    placeholder="Company contact number"
                    autoComplete="off"
                    value={companyPhoneNumber}
                    onChange={(e) => setCompanyPhoneNumber(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
              <MDBCol size="6" className="pr-0">
                <div className="form-group">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-user"></i>
                  </div>
                  <input
                    type="text"
                    placeholder="Company owner name"
                    autoComplete="off"
                    value={companyOwner}
                    onChange={(e) => setCompanyOwner(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
              <MDBCol size="6" className="pl-0">
                <div className="form-group">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-users"></i>
                  </div>
                  <input
                    type="number"
                    placeholder="Company size"
                    autoComplete="off"
                    value={companySize}
                    onChange={(e) => setCompanySize(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
              <MDBCol size="6" className="pr-0">
                <div className="form-group">
                  <div className="icon_login icon_login_1">
                    <i className="fa fa-industry"></i>
                  </div>
                  <input
                    type="text"
                    placeholder="Company industry"
                    autoComplete="off"
                    value={companyIndustry}
                    onChange={(e) => setCompanyIndustry(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>

              <MDBCol size="12" className="pl-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="profile-upload-box cursor-pointer w-fill d-flex align-items-center my-0 justify-content-between"
                    onClick={() => companyLogoRef.current.click()}
                  >
                    <div className="text-left">
                      <p className="font-weight-bold text-black f-14">
                        Click to upload company logo{" "}
                      </p>
                      <p className="f-12">SVG, PNG, JPG (max. 800x400px)</p>
                    </div>
                    <img src={UploadIcon} className="upload-icon" />
                  </div>
                  <img
                    src={
                      companyLogo !== null
                        ? window.URL.createObjectURL(companyLogo)
                        : UploadIcon
                    }
                    className="uploaded-icon"
                  />
                </div>

                <input
                  ref={companyLogoRef}
                  onChange={(e) => setCompanyLogo(e.target.files[0])}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  required
                />
              </MDBCol>
            </MDBRow>
          </form>
          {loadingRegistraion ? (
            <Loading color="black" text="Registering..." />
          ) : null}
          <div className="form-group mb-0">
            <input
              form="registerfrmid"
              type="submit"
              className="reg-btn"
              value="Register"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withKey(RegistrationUserAdmin);
// export default RegistrationUserAdmin;
