import React, { useRef } from "react";
import { Modal } from "antd";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { getTracking } from "api/tracking/getTracking";
import { createTracking } from "api/tracking/createTracking";
import { deleteTracking } from "api/tracking/deleteTracking";

import { useHttpWithCache } from "hooks/useHtthWithCache";
import { useHttp } from "hooks/useHttp";
import addNotification from "helpers/notify";
import { editTracking } from "api/tracking/editTracking";
import { getRevisionProject } from "api/tracking/getRevisionProject";

import { compare } from "helpers/sortAlphabetic";
import { setSidebarStatus } from "actions/user";
import { useHistory } from "react-router";
import { eventTrack } from "helpers/ga4Helper";
import Select, { components } from "react-select";

import Loading from "components/ui-components/loading";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";
import {
  CustomOption,
  formatOptionLabel,
} from "helpers/reactSelectCustomStyling";
import Loader from "components/Loader";
import { RequestCard } from "pages/configuration/requestCard";

import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { getFeatureRequest } from "api/featureRequests/getFeatureRequest";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";

function ResourceTracking() {
  const dispatch = useDispatch();
  const history = useHistory();
  const Selectref = useRef();

  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const [requestModal, setRequestModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false);

  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    .filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const [featureRequests, setFeatureRequests] = useState([]);

  const { request: getFeatureRequestAPI } = useHttp({
    requestCallback: getFeatureRequest,
    onLoad: (res) => {
      setFeatureRequests(res?.map((feature) => feature?.requestedFeature));
    },
    onError: (res) => {},
  });

  const [openAddModel, setOpenAddModel] = useState(false);
  const [openCopyModel, setOpenCopyModel] = useState(false);
  const [selectedProjectData, setSelectedProjectData] = useState(null);

  const [tblData, setTblData] = useState([]);

  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  const [previousWeekButtonDisabled, setPreviousWeekButtonDisabled] =
    useState(false);

  const [directIndirectVal, setDirectIndirectVal] = useState({
    value: "Direct",
    label: "Direct",
  });
  const [reset, setReset] = useState(false);

  const [trackingProject, setTrackingProject] = useState("");
  const [note, setNote] = useState("");

  const [addBtnDisabled, setAddBtnDisabled] = useState(false);

  const projectsRedux = useSelector((s) => s.user.projects);

  const [revisionProjectOption, setRevisionProjectOption] = useState([]);

  const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  var timesheet_id = "";
  var curr = new Date(); // get current date

  Date.prototype.GetFirstDayOfWeek = function () {
    return new Date(
      this.setDate(
        this.getDate() - this.getDay() + (this.getDay() == 0 ? -6 : 1)
      )
    );
  };
  Date.prototype.GetLastDayOfWeek = function () {
    return new Date(this.setDate(this.getDate() - this.getDay() + 7));
  };

  var firstday = curr.GetFirstDayOfWeek();
  var lastday = curr.GetLastDayOfWeek();

  const currentMonday = {
    day: firstday.getDate(),
    monthName: firstday.toLocaleString("default", { month: "short" }),
    year: firstday.getFullYear(),
    fullDate: firstday,
  };
  const currentSunday = {
    day: lastday.getDate(),
    monthName: lastday.toLocaleString("default", { month: "short" }),
    year: lastday.getFullYear(),
    fullDate: lastday,
  };

  const intialDate = {
    monday: currentMonday,
    sunday: currentSunday,
  };

  const [displayPeriod, setDisplayPeriod] = useState(intialDate);
  const [weekListDisplay, setWeekListDisplay] = useState([]);

  function getPreviousPeriod(monday) {
    // setBtnDisabled(false);
    var preDate = new Date(monday);
    preDate.setDate(preDate.getDate() - 1);
    //prevMonday.setDate(prevMonday.getDate() - 7);
    var prevMonday = preDate.GetFirstDayOfWeek();

    var prevSunday = preDate.GetLastDayOfWeek();
    //prevSunday.setDate(prevSunday.getDate() - 1)

    const Monday = {
      day: prevMonday.getDate(),
      monthName: prevMonday.toLocaleString("default", { month: "short" }),
      year: prevMonday.getFullYear(),
      fullDate: prevMonday,
    };
    const Sunday = {
      day: prevSunday.getDate(),
      monthName: prevSunday.toLocaleString("default", { month: "short" }),
      year: prevSunday.getFullYear(),
      fullDate: prevSunday,
    };

    setDisplayPeriod({
      monday: Monday,
      sunday: Sunday,
    });
  }

  function getNextPeriod(sunday) {
    // setBtnDisabled(false);
    var nextDate = new Date(sunday);
    nextDate.setDate(nextDate.getDate() + 1);
    var nextMonday = nextDate.GetFirstDayOfWeek();

    //nextMonday.setDate(nextMonday.getDate() + 1);

    var nextSunday = nextDate.GetLastDayOfWeek();

    //nextSunday.setDate(nextSunday.getDate() + 7)

    const Monday = {
      day: nextMonday.getDate(),
      monthName: nextMonday.toLocaleString("default", { month: "short" }),
      year: nextMonday.getFullYear(),
      fullDate: nextMonday,
    };
    const Sunday = {
      day: nextSunday.getDate(),
      monthName: nextSunday.toLocaleString("default", { month: "short" }),
      year: nextSunday.getFullYear(),
      fullDate: nextSunday,
    };
    setDisplayPeriod({
      monday: Monday,
      sunday: Sunday,
    });
  }

  function weekDayDisplay() {
    var startDateVal = new Date(displayPeriod.monday.fullDate);
    var weekListArrayDisplay = [
      startDateVal.getDate() +
        "-" +
        startDateVal.toLocaleString("default", { month: "short" }) +
        "-" +
        startDateVal.getFullYear(),
    ];

    for (let index = 0; index < 6; index++) {
      let weekdate = new Date(startDateVal.setDate(startDateVal.getDate() + 1));
      weekListArrayDisplay.push(
        weekdate.getDate() +
          "-" +
          weekdate.toLocaleString("default", { month: "short" }) +
          "-" +
          weekdate.getFullYear()
      );
    }
    setWeekListDisplay(weekListArrayDisplay);

    const { fullDate: mondayDate } = intialDate.monday;
    const currentWeekMonday = new Date(mondayDate).setHours(0, 0, 0, 0);

    const mondayOfWeekList = new Date(weekListArrayDisplay[0]).getTime();
    const isCurrentWeek = currentWeekMonday === mondayOfWeekList;
    setIsCurrentWeek(isCurrentWeek);
    // setnextBtnDisabled(isCurrentWeek);
    // setBtnDisabled(isCurrentWeek);

    const twoWeeksBeforeMonday = new Date(
      new Date(mondayDate).setDate(mondayDate.getDate() - 14)
    ).setHours(0, 0, 0, 0);
    const previousWeekButtonDisabled = mondayOfWeekList <= twoWeeksBeforeMonday;
    setPreviousWeekButtonDisabled(previousWeekButtonDisabled);
  }

  useEffect(() => {
    weekDayDisplay();
  }, [displayPeriod]);

  //********************************** API CALLS ********************************/

  useEffect(() => {
    getTrackingAPI();
    getRevisionProjectAPI();
    getFeatureRequestAPI(userInfoRedux?.id);
  }, []);

  const { loading: timesheetLoading, request: getTrackingAPI } = useHttp({
    requestCallback: () => getTracking(userInfoRedux),
    onLoad: (res) => {
      console.log(res?.filter((item) => item?.dates?.length > 0));
      setTblData(res?.filter((item) => item?.dates?.length > 0));
    },
    onError: (res) => {
      console.log("err", res);
    },
  });

  const { loading: revisionProjectLoading, request: getRevisionProjectAPI } =
    useHttp({
      requestCallback: () => getRevisionProject(userInfoRedux?.company),
      onLoad: (res) => {
        const mergedRevisionAndProjects = [];
        const data = res?.projects?.filter(
          x => (x.user === userInfoRedux.id || x.users.includes(userInfoRedux.id))
        ).map((i) => {
          const arr = res?.revisions?.forEach((j) => {
            if (j?.projectId?._id === i?._id) {
              mergedRevisionAndProjects.push({
                label: `${j?.key}_?_${i?.projectNumber}`,
                value: j._id,
              });
            }
          });
          mergedRevisionAndProjects.push({
            label: `${i.title}_?_${i?.projectNumber}`,
            value: i._id,
          });
        });
        setRevisionProjectOption(mergedRevisionAndProjects);
      },
      onError: (res) => {
        console.log("err", res);
      },
    });

  //add timesheet data
  const { loading: addloading, request: AddTimesheetAPI } = useHttp({
    requestCallback: (data) => createTracking(data),
    onLoad: (res) => {
      let existData = [...tblData];
      // existData.pop();

      if (res.added.length > 0) {
        setTblData([...existData, ...res.added]);
      }
      if (res.updated.length > 0) {
        // setTblData([...existData, ...res.updated])

        let tableDataList = existData;
        res.updated.forEach((x) => {
          const projectIndex = tableDataList.findIndex((y) => y._id == x._id);
          if (projectIndex != -1) {
            tableDataList[projectIndex] = x;
          } else {
            tableDataList.push(x);
          }
        });
        setTblData([...tableDataList]);
      }
      if (res.updated.length > 0) {
        addNotification("Project was successfully updated!", "success");
      } else if (res.added.length > 0) {
        addNotification("Project was successfully added!", "success");
      } else {
        setTblData(existData);
        addNotification("Project update failed", "danger");
      }
      setOpenAddModel(false);
    },
    onError: (res) => {
      console.log("err", res);
    },
  });

  //add multipletimesheet data
  const { addMulloading, request: AddMultipleTimesheetAPI } = useHttp({
    requestCallback: (data) => createTracking(data),
    onLoad: (res) => {
      if (res.added.length > 0) {
        setTblData([...tblData, ...res.added]);
      }
      if (res.updated.length > 0) {
        let tableDataList = tblData;
        res.updated.forEach((x) => {
          const projectIndex = tableDataList.findIndex((y) => y._id == x._id);
          if (projectIndex != -1) {
            tableDataList[projectIndex] = x;
          } else {
            tableDataList.push(x);
          }
        });

        setTblData([...tableDataList]);
      }
      if (res.updated.length > 0) {
        addNotification(
          "Previous week project was successfully updated!",
          "success"
        );
      } else if (res.added.length > 0) {
        addNotification(
          "Previous week project was successfully added!",
          "success"
        );
      } else {
        addNotification("Previous week project update failed", "danger");
      }
    },
    onError: (res) => {
      console.log("err", res);
    },
  });

  // update Timesheet

  const { loading: updatingLoading, request: UpdateTimesheetAPI } = useHttp({
    requestCallback: (updateTimesheetVal) =>
      editTracking({ updateTimesheetVal, userInfoRedux }),
    onLoad: (res) => {
      addNotification("Successfully updated existing time entry!", "success");
      cancelEdit();
    },
    onError: (res) => {
      addNotification("Something went wrong.", "danger");
    },
  });

  // delete timesheet data
  const { loading: deleteLoading, request: RemoveTimesheetAPI } = useHttp({
    requestCallback: (value) => deleteTracking(value),
    onLoad: (res) => {
      addNotification("Successfully removed time entry", "success");
      // const result = tblData.filter(data => data._id !== timesheet_id);
      let result = tblData;
      let deleteTimesheetIndex = result.findIndex((x) => x._id == timesheet_id);
      result[deleteTimesheetIndex].dates = result[
        deleteTimesheetIndex
      ].dates.filter((x) => !weekListDisplay.includes(x.date));
      setTblData([...result]);
    },
    onError: (res) => {
      addNotification("Something went wrong.", "danger");
    },
  });

  const directIndirectOptions = [
    { value: "Direct", label: "Direct" },
    // { value: "Project Managers", label: "Project Managers" },
    { value: "InDirect", label: "InDirect" },
  ];

  const onHrsChange = (e, id, date) => {
    let hrvalue = Number(e.target.value);
    if (hrvalue > 24 || hrvalue == "e") {
      hrvalue = 0;
    }

    let index = tblData.findIndex((val) => val._id == id);
    // console.log("index ", index, tblData)
    let editvalue = [...tblData];
    let dateindex = editvalue[index].dates.findIndex((val) => val.date == date);
    if (dateindex != -1) {
      editvalue[index].dates[dateindex].hours = hrvalue;
    } else {
      editvalue[index].dates.push({
        date: date,
        hours: hrvalue,
      });
    }

    setTblData(editvalue);
  };

  const onNoteChange = (e, value) => {
    let index = tblData.findIndex((val) => val._id == value._id);
    let editvalue = [...tblData];
    editvalue[index].notes = [
      { date: value?.dates[0]?.date, note: e.target.value },
    ];
    console.log(editvalue);
    setTblData(editvalue);
  };

  const addProject = () => {
    let newproj = {
      direct: directIndirectVal.value === "Direct" ? true : false,
      user: userInfoRedux?.id,
      projectId: trackingProject?.value,
      notes: [{ date: weekListDisplay[0], note: note }],
      dates: weekListDisplay.map((value) => {
        return {
          date: value,
          hours: 0,
        };
      }),
      companyId: userInfoRedux?.company,
    };

    AddTimesheetAPI([newproj]);
    let existdata = [...tblData];
    setTblData([...existdata, newproj]);
    setTrackingProject("");
    setNote("");
    setAddBtnDisabled(true);
  };

  const onRowEdit = (value) => {
    let existdata = [...tblData];
    existdata.forEach((val) => {
      if (val._id == value._id) {
        val.edit = true;
      } else {
        val.edit = false;
      }
    });
    setTblData(existdata);
    setAddBtnDisabled(true);
  };

  const onRowSave = (value) => {
    if (value._id) {
      //put
      let updatedValue = {
        timesheetID: value._id,
        dates: value.dates,
        notes: value.notes,
      };
      UpdateTimesheetAPI(updatedValue);
    } else {
      //add Project api
      delete value["edit"];
      AddTimesheetAPI([value]);
    }
  };

  const cancelEdit = () => {
    // let existdata = [...tblData];
    // existdata.forEach((val) => {
    //   val.edit = false;
    // });
    // setTblData(existdata);
    getTrackingAPI();
    setAddBtnDisabled(false);
  };

  const onRowDelete = (value) => {
    if (value._id) {
      timesheet_id = value._id;
      value.firstday = value.dates.map((x) => x.date).filter((i)=> weekListDisplay.includes(i)).join(",");
      RemoveTimesheetAPI(value);
    } else {
      let existdata = [...tblData];
      /* existdata.filter((val) => {
        console.log("existdata  val._id", val._id);
        return val._id;
      }) */
      let newData = existdata.filter((val) => {
        return Boolean(val._id);
      });
      setTblData(newData);
    }
    setAddBtnDisabled(false);
    setSelectedProjectData(null);
  };

  function addModalCloseBtn() {
    setOpenAddModel(false);
    setTrackingProject("");
    setDirectIndirectVal({ value: "Direct", label: "Direct" });
    setNote([]);
  }

  function SetDirectIndirect(e) {
    setTrackingProject("");
    setDirectIndirectVal({ value: e.value, label: e.label });
    setReset(false);
  }

  const copyPrevData = () => {
    setOpenCopyModel(false);
    //prev week data

    let preDateVal = new Date(displayPeriod.monday.fullDate);
    preDateVal.setDate(preDateVal.getDate() - 1);
    let prevMonday = preDateVal.GetFirstDayOfWeek();

    //console.log("startDateVal", prevMonday);
    var copyWeekListArray = [
      prevMonday.getDate() +
        "-" +
        prevMonday.toLocaleString("default", { month: "short" }) +
        "-" +
        prevMonday.getFullYear(),
    ];

    for (let index = 0; index < 6; index++) {
      let weekdate = new Date(prevMonday.setDate(prevMonday.getDate() + 1));
      copyWeekListArray.push(
        weekdate.getDate() +
          "-" +
          weekdate.toLocaleString("default", { month: "short" }) +
          "-" +
          weekdate.getFullYear()
      );
    }

    let getPreValue = tblData.filter((value) => {
      return (
        value.dates.filter((projDate) => {
          return (
            copyWeekListArray.filter((weeklistValue) => {
              return (
                new Date(weeklistValue).getTime() ===
                new Date(projDate.date).getTime()
              );
            }).length > 0
          );
        }).length > 0
      );
    });

    console.log("getPreValue", getPreValue);
    console.log("getCurrentWeekRecord", getCurrentWeekRecord);

    //currentweek data

    if (getPreValue.length > 0) {
      let dateArr = [];

      getPreValue.forEach((data) => {
        dateArr.push({
          timesheetID: data?._id,
          dates: weekListDisplay.map((value) => {
            return {
              date: value,
              hours: data.dates.filter(
                (dh) =>
                  new Date(
                    new Date(value).setDate(new Date(value).getDate() - 7)
                  ).getDate() === new Date(dh.date).getDate()
              )[0]?.hours ?? '0',
              // hours: 0,
            };
          }),
        });
      });

      console.log("dateArr", dateArr);

      let pretblData = getPreValue.map((value) => {
        return {
          direct: value.direct,
          companyId: userInfoRedux?.company,
          user: userInfoRedux?.id,
          projectId: value?.projectId?._id,
          notes: value.notes,
          dates: dateArr.filter((d) => d.timesheetID == value._id)[0].dates,
        };
      });
      console.log(pretblData);
      AddMultipleTimesheetAPI(pretblData);
    }
  };

  const PerDayTotal = [];
  const totalHoursOfWeek = {
    value: 0,
  };
  var getCurrentWeekRecord = [];

  const requestAccess = async () => {
    const res = await createFeatureRequest(userInfoRedux.id, {
      companyId: userInfoRedux.company,
      permission: requestType,
      userId: userInfoRedux.id,
    });
    if (res.status === 200) {
      setLoadingRequest(false);
      setFeatureRequests(res.data.map((feature) => feature.requestedFeature));
    }
  };

  return (
    <div className=" personal-area">
      {timesheetLoading ? <Loader /> : null}

      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
            <p className="my-heading f-14 ml-3 mr-3">Resource Tracking</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <input
              type="text"
              className="search-bar"
              //   value={searchValue}
              //   onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
              // disabled={loading}
            />
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              {userInfoRedux?.admin || userInfoRedux?.companyAdmin ? (
                <button
                  onClick={() => {
                    history.push(`/admin/projects-settings`);
                    {
                      eventTrack(
                        "Navigate-Admin-Section-Action",
                        "Navigate-Admin-Section",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }
                  }}
                  className="bg-bid px-3 mr-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                    fill="none"
                  >
                    <path
                      d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                      fill="white"
                    />
                  </svg>
                  Admin
                </button>
              ) : null}

              <div className="vr mx-3"></div>

              {/* <button
                onClick={handleSortModelFIleList}
                disabled={loading}
                className="px-3 bg-white text-bid shadow-hover"
              >
                {sortOrder === "asc" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                  >
                    <path
                      d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                      fill="#3A5765"
                    />
                    <path
                      d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                      fill="black"
                      fillOpacity="0.2"
                    />
                  </svg>
                ) : (
                  <div className="upsideDown-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                    >
                      <path
                        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                        fill="#3A5765"
                      />
                      <path
                        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                        fill="black"
                        fillOpacity="0.2"
                      />
                    </svg>
                  </div>
                )}
                Sort
              </button> */}

              <button
                onClick={() => getTrackingAPI()}
                className="px-3 bg-white text-bid shadow-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="assestmgmt-table-div mt-3">
        <div className="d-flex align-items-center justify-content-between mt-3">
          <div className="d-flex">
            <div className="tracking-date-div">
              <i
                className="fa fa-chevron-left"
                disabled={previousWeekButtonDisabled}
                onClick={() => {
                  getPreviousPeriod(displayPeriod.monday.fullDate);
                }}
              ></i>
              <input
                type="text"
                placeholder="date"
                value={`${displayPeriod.monday.monthName} ${displayPeriod.monday.day} - ${displayPeriod.sunday.monthName} ${displayPeriod.sunday.day} ${displayPeriod.sunday.year}`}
              />
              <i
                className="fa fa-chevron-right"
                disabled={isCurrentWeek}
                onClick={() => {
                  getNextPeriod(displayPeriod.sunday.fullDate);
                }}
              ></i>
            </div>
            <button
              disabled={isCurrentWeek}
              className="header-btn bg-bid text-white mr-2 border-bid shadow-none f-12"
              onClick={() => setDisplayPeriod(intialDate)}
            >
              <i className="fa fa-clock-o mr-2"></i>
              Current Week
            </button>
            <button
              // disabled={!isCurrentWeek}
              onClick={() => setOpenCopyModel(true)}
              className="header-btn bg-bid text-white m-0 border-bid shadow-none f-12"
            >
              <i className="fa fa-copy mr-2"></i>
              Copy Previous
            </button>
          </div>

          {/* <div className="dateStatus">
            Today's Date: {date.toLocaleDateString("en-US")}
          </div> */}

          <button
            className="header-btn bg-bid text-white border-bid shadow-none f-12 ml-2 mr-2"
            onClick={() => {
              FinalPermissions?.includes(
                ALL_PERMISSIONS.ENGINEERING_TRACKING_ADD
              )
                ? setOpenAddModel(true)
                : setRequestModal(true);
              setRequestType(ALL_PERMISSIONS.ENGINEERING_TRACKING_ADD);
            }}
          >
            <i className="fa fa-plus mr-2"></i> Add Project/Time
          </button>
        </div>

        {/* table starts */}

        <div className="mt-3">
          <table className="bid-table bid-table-small sticky-table-header">
            <thead>
              <tr key={990}>
                <th key={991}>Project</th>
                <th key={992}>Type</th>
                {weekListDisplay.map((element, index) => {
                  return (
                    <th key={index}>
                      {dayName[new Date(element).getDay()] +
                        " " +
                        element.split("-")[0]}
                    </th>
                  );
                })}
                <th key={993}>Week Total</th>
                <th key={994}>Notes</th>
                <th key={995}>Actions</th>
              </tr>
            </thead>

            <tbody>
              {tblData
                .filter((value) => {
                  return (
                    value.dates.filter((dcrDate) => {
                      return (
                        weekListDisplay.filter((weeklistValue, index) => {
                          return (
                            new Date(weeklistValue).getTime() ===
                            new Date(dcrDate.date).getTime()
                          );
                        }).length > 0
                      );
                    }).length > 0
                  );
                })
                .map((value, key) => {
                  getCurrentWeekRecord.push(value);
                  const weekTotal = {
                    value: 0,
                  };
                  return (
                    <tr key={key}>
                      <td>
                        <input
                          type="text"
                          style={{ width: "200px" }}
                          className="disabled-inputs-track"
                          disabled
                          value={value.projectId?.title}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="disabled-inputs-track"
                          disabled
                          value={value.direct ? "Direct" : "Indirect"}
                        />
                      </td>
                      {weekListDisplay.map((weekdate, index) => {
                        let checkdate = value.dates.findIndex((wkdate) => {
                          return (
                            new Date(wkdate.date).getTime() ===
                            new Date(weekdate).getTime()
                          );
                        });
                        let val = {};
                        if (checkdate == -1) {
                          val = {
                            date: weekdate,
                            hours: 0,
                          };
                        } else {
                          val = value.dates[checkdate];
                        }

                        weekTotal.value += Number(val?.hours ?? 0);
                        const findPerDayExists = PerDayTotal.findIndex(
                          (x) =>
                            new Date(x.date).getTime() ==
                            new Date(val.date).getTime()
                        );

                        if (findPerDayExists != -1) {
                          if (
                            Number(PerDayTotal[findPerDayExists].value) +
                              Number(val.hours) >
                            24
                          ) {
                            val.hours = 0;
                          }

                          PerDayTotal[findPerDayExists].value =
                            Number(PerDayTotal[findPerDayExists].value) +
                            Number(val?.hours ?? 0);
                        } else {
                          PerDayTotal.push({
                            date: val.date,
                            value: Number(val.hours),
                          });
                        }
                        totalHoursOfWeek.value =
                          totalHoursOfWeek.value + Number(val?.hours ?? 0);
                        return (
                          <td key={index} className="tracking-td">
                            <input
                              type="number"
                              min="0"
                              className="tracking-table-input daysInput-width track-input"
                              disabled={value.edit ? false : true}
                              value={val.hours}
                              onChange={(e) =>
                                onHrsChange(e, value._id, weekdate)
                              }
                            />
                          </td>
                        );
                      })}
                      <td>
                        <input
                          type="text"
                          className="tracking-table-input track-input"
                          disabled
                          value={weekTotal.value}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          style={{ width: "100px" }}
                          className="tracking-table-input track-input"
                          onChange={(e) => onNoteChange(e, value)}
                          disabled={value.edit ? false : true}
                          value={
                            value?.notes
                              ?.filter((note) =>
                                weekListDisplay.filter(
                                  (weekdate) =>
                                    new Date(weekdate).getTime() ===
                                    new Date(note?.date).getTime()
                                )
                              )
                              ?.map((note) => note?.note)[0]
                          }
                        />
                      </td>
                      <td>
                        <div className="tabel-action-div">
                          {!value.edit ? (
                            <span
                              disabled={
                                addBtnDisabled || value.edit ? true : false
                              }
                              onClick={() => {
                                FinalPermissions?.includes(
                                  ALL_PERMISSIONS.ENGINEERING_TRACKING_EDIT
                                )
                                  ? onRowEdit(value)
                                  : setRequestModal(true);
                                setRequestType(
                                  ALL_PERMISSIONS.ENGINEERING_TRACKING_EDIT
                                );
                              }}
                              className="cursor-pointer mr-3"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                              >
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="#3A5765"
                                />
                                <path
                                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                                  fill="black"
                                  fillOpacity="0.2"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                cancelEdit();
                              }}
                              className="cursor-pointer mr-3"
                            >
                              <i className="fa fa-times text-danger mr-2" />
                            </span>
                          )}

                          {updatingLoading ? (
                            <i className="fa fa-spinner small-spinner" />
                          ) : (
                            <>
                              {!value.edit ? (
                                <span
                                  onClick={() => {
                                    FinalPermissions?.includes(
                                      ALL_PERMISSIONS.ENGINEERING_TRACKING_DELETE
                                    )
                                      ? setSelectedProjectData(value)
                                      : setRequestModal(true);
                                    setRequestType(
                                      ALL_PERMISSIONS.ENGINEERING_TRACKING_DELETE
                                    );
                                  }}
                                  className="cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="22"
                                    viewBox="0 0 26 22"
                                    fill="none"
                                  >
                                    <path
                                      d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                      fill="#3A5765"
                                    />
                                    <path
                                      d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                                      fill="black"
                                      fillOpacity="0.2"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <span
                                  disabled={
                                    addBtnDisabled || value.edit ? true : false
                                  }
                                  onClick={() => {
                                    onRowSave(value);
                                  }}
                                  className="cursor-pointer mr-0"
                                >
                                  <i className="fa fa-save mr-2 "></i>
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <td selected>Total Hours</td>
                <td></td>
                {PerDayTotal.map((val, ind) => {
                  return <td key={ind}>{val.value}</td>;
                })}
                <td>{totalHoursOfWeek.value}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>

        {/* <p style={{ float: "right" }}>
          Last Update: {new Date().toUTCString()}
        </p> */}
      </div>

      {/* add tracking model */}
      <Modal
        title="Add Time Entry"
        onCancel={() => addModalCloseBtn()}
        open={openAddModel}
        footer={null}
      >
        <div className="form-group-div mt-4">
          <label className="f-14 inter text-black font-weight-500">
            Select Project type
          </label>
          <Select
            options={directIndirectOptions}
            //   isMulti
            onChange={(options) => {
              SetDirectIndirect(options);
              setReset(true);
            }}
            key={directIndirectVal.value}
            value={directIndirectVal}
            selected={directIndirectVal}
          />
        </div>

        <div className="form-group-div mt-3">
          <label className="f-14 inter text-black font-weight-500">
            Select Project
          </label>
          <Select
            options={revisionProjectOption}
            formatOptionLabel={formatOptionLabel}
            components={{ Option: CustomOption }}
            onChange={(e) => setTrackingProject(e)}
            value={trackingProject}
          />
        </div>

        <div className="form-group-div mt-3">
          <label htmlFor="" className="f-14 inter text-black font-weight-500">
            Note
          </label>
          <input
            type="text"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>

        {addloading && <Loading color="black" text="Creating..." />}

        <div className="modal-footer border-none mt-5">
          <div className="w-100">
            <div
              onClick={() => addProject()}
              className="border-bid bg-bid rounded-lg py-2 w-100 text-white text-center inter f-16 font-weight-500 cursor-pointer"
            >
              Add Time Entry
            </div>
            <div
              onClick={() => addModalCloseBtn()}
              // ref={modalRef}
              className="bg-white border-bid rounded-lg py-2 w-100 text-bid text-center inter f-16 font-weight-500 mt-3 cursor-pointer"
              data-dismiss="modal"
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>

      {/* Copy previous model */}
      <MDBModal
        isOpen={openCopyModel}
        toggle={() => setOpenCopyModel(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpenCopyModel(false)}>
          <div className="icon-div-model">
            <i className="fa fa-copy"></i>
          </div>
        </MDBModalHeader>

        <MDBModalBody>
          <p className="delete-modal-label-head">Copy Previous Record?</p>
          <p className="delete-modal-label">
            Do you want to copy previous week record to this week?
          </p>
        </MDBModalBody>

        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpenCopyModel(false);
              }}
              className="delete-modal-btn cancel-confirm bg-white"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              onClick={() => copyPrevData()}
              className="delete-modal-btn delete-confirm bg-bid border-bid"
            >
              Copy
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      {/* delete modal */}
      <MDBModal
        isOpen={selectedProjectData}
        toggle={() => setSelectedProjectData(null)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setSelectedProjectData(null)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Time Entry ?</p>
          <p className="delete-modal-label">
            Are you sure you want to delete this Time entry? This action cannot
            be undone.
          </p>
        </MDBModalBody>
        {deleteLoading ? <Loading color="black" text="Deleting..." /> : null}
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setSelectedProjectData(null);
              }}
              disabled={deleteLoading}
              className="delete-modal-btn cancel-confirm bg-white"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              onClick={() => {
                onRowDelete(selectedProjectData);
              }}
              disabled={deleteLoading}
              className="delete-modal-btn delete-confirm bg-danger"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      {/* request model */}

      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequests?.includes(requestType)}
          loading={loadingRequest}
          icon={
            featureRequests?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequests?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequests?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </div>
  );
}

export default ResourceTracking;
