import React, { useEffect, useState, useRef } from "react";
import {
  MDBBox,
  MDBContainer,
  MDBRow,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CreateModal from "components/CreateProjectModal";
import DeleteProjectModal from "components/DeleteProjectModal/DeleteProjectModal";
import UpdateProjectModal from "components/UpdateProjectModal/UpdateProjectModal";
import Members from "components/MembersModalPersonalArea";
import ShareModal from "components/ShareModal";
import Loading from "components/ui-components/loading";
import Error from "components/ui-components/Error";
import NoItems from "components/ui-components/no-items";
import ProjectItemPersonalArea from "components/ProjectItemPersonalArea";
import {
  sortByDate,
  FROM_NEWER_TO_OLDER,
  FROM_OLDER_TO_NEWER,
} from "helpers/sortByDate";
import { getProjects } from "api/projects/getProjects";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import { useHttp } from "hooks/useHttp";

import { useSearch } from "hooks/useSearch";
import {
  setProjects,
  setCompanyUsers,
  setMilestones,
  setCompanySettings,
  setPersonalAreaActiveTab,
} from "actions/user";
import { Maybe } from "helpers/maybeFunctor";
import { eventTrack } from "../../helpers/ga4Helper";

import { getCompanyUsersAndProjects } from "api/common/getCompanyUsersAndProjects";
import { adminSetUsers } from "actions/admin";
import { setFeatureRequest } from "actions/user";
import KanbanBoard from "components/KanbanBoard";
import { getMileStones } from "api/milestones/getMilestones";
import ProjectListView from "components/ProjectListView";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Loader from "components/Loader";
import { RequestCard } from "pages/configuration/requestCard";
import { Modal } from "antd";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { getVersions } from "api/versions/getVersions";
import { getFeatureRequest } from "api/featureRequests/getFeatureRequest";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { setSidebarStatus } from "actions/user";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { mainAdminGetCompanies } from "api/users/mainAdminGetCompanies";
import FooterComponent from "components/LandingPageTemplateComponents/FooterComponent";

export const ACTIVE_TABS = {
  CARD_VIEW: "CARD_VIEW",
  LIST_VIEW: "LIST_VIEW",
  KANBAN_VIEW: "KANBAN_VIEW",
};

const PersonalArea = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useSearch();
  const [sortedToNewer, setSortedToNewer] = useState(true);
  const [modal, setModal] = useState("");
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [projectToUpdate, setProjectToUpdate] = useState(null);
  const [projectListToEdit, setProjectListToEdit] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredProjects, setfilteredProjects] = useState([]);
  const [userSet, setUser] = useState(false);
  const [companySet, setCompany] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [featureRequests, setFeatureRequests] = useState([]);
  const [permissions, setPermissions] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);

  const projectsRedux = useSelector((s) => s.user.projects);
  const usersRedux = useSelector((s) => s.user.users);
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const milestonesRedux = useSelector((s) => s.user.milestones);
  const companyRedux = useSelector((s) => s.admin.company);
  const activeTabRedux = useSelector((s) => s.user.personalAreaActiveTab);

  const isMountedRef = useRef(null);

  // below filter
  const [filtered, setFiltered] = useState([]);
  const [show, setShow] = useState(false);

  // title input
  const [keyword, setKeyword] = useState("");
  const [trigger, setTrigger] = useState(false);
  // version
  const [version, setVersion] = useState(false);
  const [uniqueVersions, setUniqueVersions] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState([]);
  // export
  const [exportcheck, setExportcheck] = useState(false);
  const [selectedExporters, setSelectedExporters] = useState([]);
  // estimation
  const [estimationcheck, setEstimationcheck] = useState(false);
  const [estimationMaxRange, setEstimationMaxRange] = useState();
  const [range, setRange] = useState({ min: 0, max: estimationMaxRange });

  let debounceTimeout;
  // Due Date
  const [datecheck, setDatecheck] = useState(false);
  const [dueDate, setDueDate] = useState("");

  useEffect(() => {
    isMountedRef.current = true;
    if (!usersRedux || (!projectsRedux && isMountedRef.current)) {
      requestMileStone(userInfoRedux.company);
      if (userInfoRedux?.companyAdmin && !userSet && !usersRedux) {
        // cacheRequest();
      }
    }
    return () => (isMountedRef.current = false);
  }, [userInfoRedux, userSet, companySet, usersRedux, companyRedux]);

  const {
    cacheError,
    cacheLoading,
    cacheErrorStatus,
    request: cacheRequest,
  } = useHttpWithCache({
    requestCallback: () => getCompanyUsersAndProjects(userInfoRedux.company),
    reduxSetter: (data) => {
      dispatch(adminSetUsers(data.users));
      dispatch({
        type: "ADMIN_SET_PROJECTS",
        payload: data.projects,
      });
      dispatch({
        type: "ADMIN_SET_COMPANY",
        payload: data.company,
      });
      setCompany(true);
    },
    reduxReset: () => {
      dispatch(adminSetUsers(null));
      dispatch({
        type: "ADMIN_SET_PROJECTS",
        payload: null,
      });
      dispatch({
        type: "ADMIN_SET_COMPANY",
        payload: null,
      });
    },
    reduxCash: {
      users: usersRedux,
      company: companyRedux,
      projects: projectsRedux,
    },
    allowToCash: false,
  });

  const {
    loading,
    error,
    request: getProjectsAPI,
  } = useHttpWithCache({
    requestCallback: () => getProjects(),
    reduxSetter: (data) => {
      dispatch(setProjects(data?.projects));
      dispatch(setCompanyUsers(data?.users));

      const timelines = data?.projects
        ?.map((item) => {
          if (item?.timelines[item?.timelines.length - 1]) {
            return parseInt(
              item?.timelines[item?.timelines.length - 1].estimation.replace(
                /[^0-9.]/g,
                ""
              )
            );
          }
        })
        ?.filter((item) => item);

      setEstimationMaxRange(Math.max(...timelines));
      setRange({ min: 0, max: Math.max(...timelines) });

      setUser(true);
      setApiLoading(false);
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
    },
    reduxCash: {
      projects: projectsRedux,
      users: usersRedux,
    },
    allowToCash: false,
  });

  const {
    adminProjectsLoading,
    adminProjectsError,
    request: getAdminProjectsAPI,
  } = useHttpWithCache({
    requestCallback: () => mainAdminGetCompanies(),
    reduxSetter: (data) => {
      const proj = data?.company_projects?.find(
        (item) => item?._id === userInfoRedux?.company
      )?.projects;
      dispatch(setProjects(proj));
      dispatch(
        setCompanyUsers(
          data?.all_users?.filter(
            (item) => item?.company === userInfoRedux?.company
          )
        )
      );

      const timelines = proj
        ?.map((item) => {
          if (item?.timelines[item?.timelines.length - 1]) {
            return parseInt(
              item?.timelines[item?.timelines.length - 1].estimation.replace(
                /[^0-9.]/g,
                ""
              )
            );
          }
        })
        ?.filter((item) => item);

      setEstimationMaxRange(Math.max(...timelines));
      setRange({ min: 0, max: Math.max(...timelines) });
      setUser(true);
      setApiLoading(false);
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
    },
    reduxCash: {
      projects: projectsRedux,
      users: usersRedux,
    },
    allowToCash: false,
  });

  const { request: requestMileStone } = useHttpWithCache({
    requestCallback: () => getMileStones(userInfoRedux.company),
    reduxSetter: (data) => {
      dispatch(setMilestones(data));
    },
    reduxCash: {
      milestones: milestonesRedux,
    },
    reduxReset: () => {
      dispatch(setMilestones(null));
    },
    allowToCash: false,
  });

  const { request: getVersionsAPI } = useHttp({
    requestCallback: getVersions,
    onLoad: (res) => {
      const uniqueCodes = [
        ...new Set(res?.data?.rows?.map((item) => item?.company?.Code)),
      ];
      setUniqueVersions(uniqueCodes);
    },
    onError: (res) => {},
  });

  const { request: getFeatureRequestAPI } = useHttp({
    requestCallback: getFeatureRequest,
    onLoad: (res) => {
      setFeatureRequests(res?.map((feature) => feature?.requestedFeature));
      dispatch(
        setFeatureRequest(res?.map((feature) => feature?.requestedFeature))
      );
    },
    onError: (res) => {},
  });

  useEffect(() => {
    if (projectsRedux === null) {
      if (userInfoRedux?.admin) {
        getAdminProjectsAPI();
      } else {
        getProjectsAPI();
      }
    } else {
      setApiLoading(false);
    }
    getVersionsAPI();
    getFeatureRequestAPI(userInfoRedux?.id);
  }, []);

  const { companyAdmin, admin, company, name, lastName, email, role } =
    userInfoRedux;

  const parentProjects = Maybe.of(projectsRedux).map((projects) => {
    return projects.filter(
      (project) => project.isParent == true || project.parentProjectID == ""
    );
  });

  const getDistinctObjects = (arr, key) => {
    const uniqueObjects = new Map();

    arr.forEach((item) => {
      if (!uniqueObjects.has(item[key])) {
        uniqueObjects.set(item[key], item);
      }
    });

    return Array.from(uniqueObjects.values());
  };

  //render parent projects and change button to access children
  const maybeProjects = parentProjects
    .map((projects) => {
      let array = [];
      for (let i = 0; i < projects.length; i++) {
        if (projects[i].allZipVersions) {
          for (let k = 0; k < projects[i].allZipVersions.length; k++) {
            array.push(projects[i].allZipVersions[k].exportedBy);
          }
        }
      }

      let filteredArr = array.filter((value) => value !== null);
      let uniqueExporters = getDistinctObjects(filteredArr, "_id");
      let exporters = [...new Set(uniqueExporters)];

      localStorage.setItem("exporters", JSON.stringify(exporters));

      return !sortedToNewer
        ? sortByDate(projects, FROM_OLDER_TO_NEWER)
        : sortByDate(projects, FROM_NEWER_TO_OLDER);
    })
    .map((projects) => search(projects, "title", searchValue))
    .map((projects) => {
      return (
        <MDBRow className="row-cols-lg-3 row-cols-sm-2 row-cols-1 mt-3">
          {projects.map((projectItem, index) => {
            return (
              <ProjectItemPersonalArea
                isAdmin={companyAdmin || admin}
                className={index > 2 ? "mt-4" : ""}
                projectItem={projectItem}
                key={projectItem._id}
                idUser={userInfoRedux._id || userInfoRedux.id}
                isParent={projectItem.isParent}
                parentProjectID={projectItem.parentProjectID}
                openUpdateModal={() => {
                  setProjectToUpdate(projectItem);
                  setModal("UpdateModal");
                }}
                openDeleteModal={() => {
                  setProjectToDelete(projectItem);
                  setModal("DeleteModal");
                }}
                openMembersModal={() => {
                  setProjectListToEdit(projectItem);
                  setModal("ProjectListToEdit");
                }}
                openShareModal={() => {
                  setProjectListToEdit(projectItem);
                  setModal("Share");
                }}
              />
            );
          })}
        </MDBRow>
      );
    });

  const { value: projectsToJSX } = maybeProjects;

  let milestoneMap =
    milestonesRedux &&
    milestonesRedux.reduce((acc, val) => ({ ...acc, [val._id]: val }), {});
  let usersMap =
    usersRedux &&
    usersRedux.reduce((acc, val) => ({ ...acc, [val._id]: val }), {});

  const handleKeyword = (e) => {
    setKeyword(e.target.value);
    setTrigger(!trigger);
  };

  const handleVersionCheckbox = (code) => {
    if (selectedVersions?.includes(code)) {
      setSelectedVersions(
        selectedVersions.filter((selectedVersion) => selectedVersion !== code)
      );
    } else {
      setSelectedVersions([...selectedVersions, code]);
    }
    setTrigger(!trigger);
  };

  const handleOuterVersion = (e) => {
    setVersion(e.target.checked);
    if (!e.target.checked) setSelectedVersions([]);
  };

  const handleOuterExport = (e) => {
    setExportcheck(e.target.checked);
    if (!e.target.checked) {
      setSelectedExporters([]);
      setTrigger(!trigger);
    }
  };

  const handleExporterCheckbox = (value) => {
    if (selectedExporters?.includes(value)) {
      setSelectedExporters(
        selectedExporters.filter((exporter) => exporter != value)
      );
    } else {
      setSelectedExporters([...selectedExporters, value]);
    }
    setTrigger(!trigger);
  };

  const handleOuterEstimation = (e) => {
    setEstimationcheck(e.target.checked);
    if (!e.target.checked) {
      setRange({ min: 0, max: estimationMaxRange });
      setTrigger(!trigger);
    }
  };

  const handleChangeEstimation = (newRange) => {
    setRange(newRange);

    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      setTrigger(!trigger);
    }, 300);
  };

  const handleOuterDateCheck = (e) => {
    setDatecheck(e.target.checked);
    if (!e.target.checked) {
      setDueDate("");
      setTrigger(!trigger);
    }
  };

  const handleDueDate = (e) => {
    setDueDate(e.target.value);
    setTrigger(!trigger);
  };

  useEffect(() => {
    let maybeProjects2;

    if (
      keyword ||
      searchValue ||
      selectedVersions.length > 0 ||
      selectedExporters.length > 0 ||
      range.min > 0 ||
      range.max < estimationMaxRange ||
      dueDate
    ) {
      let fProjects = parentProjects.map((projects) => {
        // Sort the projects based on the sorting order
        const sortedProjects = !sortedToNewer
          ? sortByDate(projects, FROM_OLDER_TO_NEWER)
          : sortByDate(projects, FROM_NEWER_TO_OLDER);
        let filtered = sortedProjects;
        if (keyword) {
          const titleProjects = search(sortedProjects, "title", keyword);
          filtered = titleProjects;
        } else if (searchValue) {
          const searchedProject = search(sortedProjects, "title", searchValue);
          filtered = searchedProject;
        }

        if (selectedVersions.length > 0) {
          const versionProjects = filtered.filter((project) =>
            selectedVersions?.includes(project.version)
          );
          filtered = versionProjects;
        }

        if (selectedExporters.length > 0) {
          const exportersProjects = filtered.filter((project) =>
            selectedExporters?.includes(project?.zipInfo?.exportedBy?._id)
          );
          filtered = exportersProjects;
        }

        if (range.min > 0 || range.max < estimationMaxRange) {
          const rangeProjects = filtered.filter((project) => {
            if (project.timelines[project.timelines.length - 1]) {
              let priceInNumber = parseFloat(
                project.timelines[
                  project.timelines.length - 1
                ].estimation.replace(/[^0-9.]/g, "")
              );
              if (priceInNumber >= range.min && priceInNumber <= range.max)
                return true;
              else return false;
            } else {
              return false;
            }
          });
          filtered = rangeProjects;
        }

        if (dueDate) {
          let dueDateFiltered = filtered.filter((project) => {
            const selectedDate = new Date(dueDate);
            const endDate = new Date(project.endDate);

            return endDate <= selectedDate; // project end date less than selected date
          });
          filtered = dueDateFiltered;
        }

        return filtered;
      });

      maybeProjects2 = fProjects.map((projects) => {
        return (
          <MDBRow className="row-cols-lg-3 row-cols-sm-2 row-cols-1 mt-3">
            {projects.map((projectItem, index) => {
              return (
                <ProjectItemPersonalArea
                  isAdmin={companyAdmin || admin}
                  className={index > 2 ? "mt-4" : ""}
                  projectItem={projectItem}
                  key={projectItem._id}
                  idUser={userInfoRedux._id || userInfoRedux.id}
                  isParent={projectItem.isParent}
                  parentProjectID={projectItem.parentProjectID}
                  openUpdateModal={() => {
                    setProjectToUpdate(projectItem);
                    setModal("UpdateModal");
                  }}
                  openDeleteModal={() => {
                    setProjectToDelete(projectItem);
                    setModal("DeleteModal");
                  }}
                  openMembersModal={() => {
                    setProjectListToEdit(projectItem);
                    setModal("ProjectListToEdit");
                  }}
                  openShareModal={() => {
                    setProjectListToEdit(projectItem);
                    setModal("Share");
                  }}
                />
              );
            })}
          </MDBRow>
        );
      });

      const { value: projectsToJSX2 } = maybeProjects2;
      setFiltered(projectsToJSX2);
      setShow(true);
      setfilteredProjects(fProjects.value || []);
    } else {
      setShow(false);
      setfilteredProjects(projectsRedux);
    }
  }, [trigger, searchValue]);

  const projectToShow = show ? filteredProjects : projectsRedux;

  const requestAccess = async () => {
    try {
      setRequestLoading(true);
      const res = await createFeatureRequest(userInfoRedux.id, {
        companyId: userInfoRedux.company,
        permission: requestType,
        userId: userInfoRedux.id,
      });
      if (res) {
        setRequestLoading(false);
        dispatch(
          setFeatureRequest(res.map((feature) => feature.requestedFeature))
        );
        setFeatureRequests(res.map((feature) => feature.requestedFeature));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const reloadProjects = () => {
    setApiLoading(true);
    if (userInfoRedux?.admin) {
      getAdminProjectsAPI();
    } else {
      getProjectsAPI();
    }
  };

  useEffect(() => {
    reloadProjects();
  }, [activeTabRedux === ACTIVE_TABS.KANBAN_VIEW]);

  return (
    <>
      {apiLoading ? <Loader /> : null}
      <div className="personal-area">
        <div className="inner-header-position">
          <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center">
              <div
                onClick={() => dispatch(setSidebarStatus(true))}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                >
                  <path
                    d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                    fill="#9E9E9E"
                  />
                </svg>
              </div>
              <p className="my-heading f-14 ml-3 mr-3">My Projects</p>
            </div>
            <div
              className="projActionButtons d-flex flex-wrap"
              style={{ maxWidth: "100vw" }}
            >
              <input
                type="text"
                className="search-bar"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
                disabled={apiLoading}
              />
              <div className="vr mx-3"></div>
              <div className="btn-tool-group">
                {
                  (userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                    // usersPermissionRedux?.includes("adminSectionAccess") ? (
                    <>
                      <button
                        onClick={() => {
                          if (
                            FinalPermissions?.includes(
                              ALL_PERMISSIONS.PROJECT_VIEW
                            )
                          ) {
                            history.push(`/admin/projects-settings`);
                          } else if (
                            FinalPermissions?.includes(
                              ALL_PERMISSIONS.MILESTONE_VIEW
                            )
                          ) {
                            history.push(`/admin/milestones`);
                          } else {
                            history.push(`/admin/user-permissions`);
                          }
                          eventTrack(
                            "Navigate-Admin-Section-Action",
                            "Navigate-Admin-Section",
                            name,
                            "+1",
                            false,
                            "+1"
                          );
                        }}
                        className="bg-bid px-3 mr-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="19"
                          viewBox="0 0 14 19"
                          fill="none"
                        >
                          <path
                            d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                            fill="white"
                          />
                        </svg>
                        Admin
                      </button>
                      <div className="vr mx-3"></div>
                    </>
                  )
                  // ) : null
                }

                <button
                  onClick={() => setSortedToNewer(!sortedToNewer)}
                  disabled={apiLoading}
                  className="px-3 bg-white text-bid shadow-hover"
                  title="Sort"
                >
                  {!sortedToNewer ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                    >
                      <path
                        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                        fill="#3A5765"
                      />
                      <path
                        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                        fill="black"
                        fillOpacity="0.2"
                      />
                    </svg>
                  ) : (
                    <div className="upsideDown-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                      >
                        <path
                          d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                          fill="#3A5765"
                        />
                        <path
                          d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                          fill="black"
                          fillOpacity="0.2"
                        />
                      </svg>
                    </div>
                  )}
                  Sort
                </button>

                <button
                  disabled={apiLoading}
                  onClick={() => reloadProjects()}
                  className="px-3 bg-white text-bid shadow-hover"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                  >
                    <path
                      d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                      fill="#3A5765"
                    />
                    <path
                      d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                      fill="black"
                      fillOpacity="0.2"
                    />
                  </svg>
                  Refresh
                </button>

                <div className="vr mx-2"></div>

                <div className="d-flex view-switch-container">
                  <div
                    onClick={() => {
                      dispatch(setPersonalAreaActiveTab(ACTIVE_TABS.CARD_VIEW));
                      eventTrack(
                        "View-Project-CardView-Action",
                        "View-Project-CardView",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    className={
                      activeTabRedux === ACTIVE_TABS.CARD_VIEW ? "active" : null
                    }
                    title="Card view"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.676758 8.9671V0.703125H8.94077V8.9671H0.676758ZM0.676758 19.2971V11.0331H8.94077V19.2971H0.676758ZM11.0068 8.9671V0.703125H19.2708V8.9671H11.0068ZM11.0068 19.2971V11.0331H19.2708V19.2971H11.0068ZM2.74276 6.89709H6.87675V2.76514H2.74476L2.74276 6.89709ZM13.0728 6.89709H17.2048V2.76514H13.0768L13.0728 6.89709ZM13.0728 17.2271H17.2048V13.1031H13.0768L13.0728 17.2271ZM2.74276 17.2271H6.87675V13.1031H2.74476L2.74276 17.2271Z"
                        fill="#C0C0C0"
                      />
                    </svg>
                  </div>

                  <div
                    onClick={() => {
                      dispatch(setPersonalAreaActiveTab(ACTIVE_TABS.LIST_VIEW));
                      eventTrack(
                        "View-Project-ListView-Action",
                        "View-Project-ListView",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    className={
                      activeTabRedux === ACTIVE_TABS.LIST_VIEW ? "active" : null
                    }
                    title="List view"
                  >
                    <svg
                      width="21"
                      height="16"
                      viewBox="0 0 21 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.97384 14.0002H18.9738V11.3252H7.97384V14.0002ZM2.97384 4.67518H5.97384V2.0002H2.97384V4.67518ZM2.97384 9.35017H5.97384V6.67518H2.97384V9.35017ZM2.97384 14.0002H5.97384V11.3252H2.97384V14.0002ZM7.97384 9.35017H18.9738V6.67518H7.97384V9.35017ZM7.97384 4.67518H18.9738V2.0002H7.97384V4.67518ZM2.97384 16.0002C2.71071 16.0041 2.44957 15.954 2.20655 15.853C1.96352 15.7521 1.74377 15.6024 1.56084 15.4132C1.37165 15.2303 1.22196 15.0105 1.12101 14.7675C1.02005 14.5244 0.969962 14.2633 0.973842 14.0002V2.0002C0.970228 1.73716 1.02053 1.4762 1.12166 1.23335C1.22279 0.990503 1.3726 0.770934 1.56184 0.588208C1.74456 0.398965 1.96412 0.249151 2.20697 0.148023C2.44981 0.0468939 2.71081 -0.00341862 2.97384 0.000195533H18.9738C19.237 -0.0035558 19.4982 0.0467067 19.7413 0.14784C19.9843 0.248972 20.204 0.398841 20.3868 0.588208C20.5759 0.771019 20.7255 0.990629 20.8265 1.23347C20.9274 1.47631 20.9776 1.73723 20.9738 2.0002V14.0002C20.9775 14.2632 20.9272 14.5242 20.826 14.767C20.7249 15.0099 20.5751 15.2295 20.3858 15.4122C20.2031 15.6014 19.9836 15.7512 19.7407 15.8524C19.4979 15.9535 19.2369 16.0038 18.9738 16.0002H2.97384Z"
                        fill="#C0C0C0"
                      />
                    </svg>
                  </div>
                  {FinalPermissions?.includes(ALL_PERMISSIONS.KANBAN_VIEW) ? (
                    <div
                      className={
                        activeTabRedux === ACTIVE_TABS.KANBAN_VIEW
                          ? "active"
                          : null
                      }
                      onClick={() => {
                        dispatch(
                          setPersonalAreaActiveTab(ACTIVE_TABS.KANBAN_VIEW)
                        );
                        eventTrack(
                          "View-Project-KanbanView-Action",
                          "View-Project-KanbanView",
                          userInfoRedux?.name,
                          "+1",
                          false,
                          "+1"
                        );
                      }}
                      title="Kanban view"
                    >
                      <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.97384 14H6.97384V1.99995H2.97384V14ZM8.97384 14H12.9738V1.99995H8.97384V14ZM14.9738 14H18.9738V1.99995H14.9738V14ZM2.97384 16C2.71071 16.0038 2.44957 15.9538 2.20655 15.8528C1.96352 15.7518 1.74377 15.6022 1.56084 15.413C1.37165 15.23 1.22196 15.0102 1.12101 14.7672C1.02005 14.5242 0.969962 14.2631 0.973842 14V1.99995C0.970099 1.73699 1.02025 1.47607 1.1212 1.23323C1.22216 0.990385 1.37178 0.770775 1.56084 0.587964C1.74368 0.398597 1.9634 0.248728 2.20643 0.147595C2.44946 0.0464626 2.71064 -0.00379994 2.97384 -4.86073e-05H18.9738C19.237 -0.00379994 19.4982 0.0464626 19.7413 0.147595C19.9843 0.248728 20.204 0.398597 20.3868 0.587964C20.5759 0.770775 20.7255 0.990385 20.8265 1.23323C20.9274 1.47607 20.9776 1.73699 20.9738 1.99995V14C20.9777 14.2631 20.9276 14.5242 20.8267 14.7672C20.7257 15.0102 20.576 15.23 20.3868 15.413C20.2039 15.6022 19.9842 15.7518 19.7411 15.8528C19.4981 15.9538 19.237 16.0038 18.9738 16H2.97384Z"
                          fill="#C0C0C0"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRequestType(ALL_PERMISSIONS.KANBAN_VIEW);
                        setRequestModal(true);
                      }}
                      className={
                        activeTabRedux === ACTIVE_TABS.KANBAN_VIEW
                          ? "active"
                          : null
                      }
                      title="Kanban view"
                    >
                      <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.97384 14H6.97384V1.99995H2.97384V14ZM8.97384 14H12.9738V1.99995H8.97384V14ZM14.9738 14H18.9738V1.99995H14.9738V14ZM2.97384 16C2.71071 16.0038 2.44957 15.9538 2.20655 15.8528C1.96352 15.7518 1.74377 15.6022 1.56084 15.413C1.37165 15.23 1.22196 15.0102 1.12101 14.7672C1.02005 14.5242 0.969962 14.2631 0.973842 14V1.99995C0.970099 1.73699 1.02025 1.47607 1.1212 1.23323C1.22216 0.990385 1.37178 0.770775 1.56084 0.587964C1.74368 0.398597 1.9634 0.248728 2.20643 0.147595C2.44946 0.0464626 2.71064 -0.00379994 2.97384 -4.86073e-05H18.9738C19.237 -0.00379994 19.4982 0.0464626 19.7413 0.147595C19.9843 0.248728 20.204 0.398597 20.3868 0.587964C20.5759 0.770775 20.7255 0.990385 20.8265 1.23323C20.9274 1.47607 20.9776 1.73699 20.9738 1.99995V14C20.9777 14.2631 20.9276 14.5242 20.8267 14.7672C20.7257 15.0102 20.576 15.23 20.3868 15.413C20.2039 15.6022 19.9842 15.7518 19.7411 15.8528C19.4981 15.9538 19.237 16.0038 18.9738 16H2.97384Z"
                          fill="#C0C0C0"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="vr mx-2"></div>
                <div className="head-filter-icon">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      style={{ backgroundColor: "white" }}
                      color="link"
                      title="Filters"
                    >
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.97635 16.0002C7.8442 16.0036 7.71277 15.9798 7.59015 15.9305C7.46752 15.8811 7.3563 15.8072 7.26335 15.7132C7.16932 15.6203 7.09534 15.509 7.04597 15.3864C6.99661 15.2638 6.97291 15.1323 6.97635 15.0002V9.00018L1.17635 1.60015C1.05613 1.45573 0.981356 1.27901 0.961426 1.09216C0.941495 0.905308 0.977304 0.716699 1.06435 0.550165C1.14163 0.376041 1.27058 0.229907 1.43372 0.131525C1.59685 0.0331435 1.78628 -0.0127164 1.97635 0.000177374H15.9763C16.1664 -0.0127164 16.3559 0.0331435 16.519 0.131525C16.6821 0.229907 16.8111 0.376041 16.8884 0.550165C16.9754 0.716699 17.0112 0.905308 16.9913 1.09216C16.9713 1.27901 16.8966 1.45573 16.7764 1.60015L10.9763 9.00018V15.0002C10.9797 15.1324 10.9558 15.2639 10.9063 15.3865C10.8567 15.5092 10.7826 15.6204 10.6884 15.7132C10.5955 15.8071 10.4845 15.8809 10.362 15.9303C10.2396 15.9796 10.1083 16.0034 9.97635 16.0002H7.97635ZM8.97635 8.30017L13.9263 2.00018H4.02635L8.97635 8.30017Z"
                          fill="#455E6C"
                        />
                      </svg>
                      {/* keyword, version,exportcheck,estimationcheck,datecheck */}
                      <p className="filter-indicator">
                        {(keyword.length > 1 ? 1 : 0) +
                          (version ? 1 : 0) +
                          (exportcheck ? 1 : 0) +
                          (estimationcheck ? 1 : 0) +
                          (datecheck ? 1 : 0)}
                      </p>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      responsive="lg-end"
                      right
                      style={{ width: "200px" }}
                    >
                      <div className="card">
                        <div className="card-body p-2">
                          <div className="mb-2">
                            <input
                              value={keyword}
                              onChange={handleKeyword}
                              type="text"
                              className="form-control filter-input"
                              placeholder="Keyword"
                            />
                          </div>
                          {/* version */}
                          <label className="form-check">
                            <input
                              value={version}
                              onChange={handleOuterVersion}
                              className="form-check-input small-squared-checkbox"
                              type="checkbox"
                            />{" "}
                            Version
                          </label>
                          {version && (
                            <div className="ml-4 mt-2">
                              {uniqueVersions.map((code, i) => (
                                <label key={i} className="form-check">
                                  <input
                                    onChange={() => handleVersionCheckbox(code)}
                                    value={code}
                                    className="form-check-input small-squared-checkbox"
                                    type="checkbox"
                                  />{" "}
                                  {code}
                                </label>
                              ))}
                            </div>
                          )}
                          {/* export */}
                          <label className="form-check">
                            <input
                              value={exportcheck}
                              onChange={handleOuterExport}
                              className="form-check-input small-squared-checkbox"
                              type="checkbox"
                            />{" "}
                            Export
                          </label>
                          {exportcheck && (
                            <div className="ml-4 mt-2">
                              {JSON.parse(
                                localStorage.getItem("exporters")
                              )?.map((exporter, i) => (
                                <label key={i} className="form-check">
                                  <input
                                    onChange={() =>
                                      handleExporterCheckbox(exporter?._id)
                                    }
                                    value={exporter?._id}
                                    className="form-check-input small-squared-checkbox"
                                    type="checkbox"
                                  />{" "}
                                  {exporter?.username}
                                </label>
                              ))}
                            </div>
                          )}

                          {/* estimation */}
                          <label className="form-check">
                            <input
                              value={estimationcheck}
                              onChange={handleOuterEstimation}
                              className="form-check-input small-squared-checkbox"
                              type="checkbox"
                            />{" "}
                            Estimation
                          </label>
                          {estimationcheck && (
                            <div className="mx-2 mt-3">
                              <InputRange
                                minValue={0}
                                maxValue={estimationMaxRange}
                                value={range}
                                onChange={handleChangeEstimation}
                              />
                            </div>
                          )}
                          {/* Due Date */}
                          <label className="form-check">
                            <input
                              value={datecheck}
                              onChange={handleOuterDateCheck}
                              className="form-check-input small-squared-checkbox"
                              type="checkbox"
                            />{" "}
                            Due Date
                          </label>
                          {datecheck && (
                            <div className="ml-3 mt-0">
                              <input
                                onChange={handleDueDate}
                                type="date"
                                className="date-picker-input"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        {activeTabRedux === ACTIVE_TABS.KANBAN_VIEW &&
        projectToShow &&
        projectToShow.length > 0 ? (
          <div className="kanban-box">
            <KanbanBoard
              projects={show ? filteredProjects : projectsRedux}
              milestones={milestonesRedux}
              isAdmin={companyAdmin || admin}
              setModal={setModal}
              setProjectToDelete={setProjectToDelete}
              setProjectToUpdate={setProjectToUpdate}
              setProjectListToEdit={setProjectListToEdit}
              users={usersMap}
            />
          </div>
        ) : null}

        {activeTabRedux === ACTIVE_TABS.LIST_VIEW &&
        projectToShow &&
        projectToShow.length > 0 ? (
          <ProjectListView
            isAdmin={companyAdmin || admin}
            setModal={setModal}
            setProjectToDelete={setProjectToDelete}
            setProjectToUpdate={setProjectToUpdate}
            setProjectListToEdit={setProjectListToEdit}
            projects={projectToShow.map((val) => {
              let cost =
                val.timelines && val.timelines.length > 0
                  ? val.timelines[val.timelines.length - 1].estimation
                  : "";
              return { ...val, cost, n_users: val.users.length };
            })}
            milestones={milestoneMap}
            users={usersMap}
            sortedToNewer={sortedToNewer}
          />
        ) : null}

        {activeTabRedux === ACTIVE_TABS.CARD_VIEW && (
          <div className="pt-20 container px-5">
            <MDBBox className="pt-0 min-h-100 mt-6s">
              {error ? <Error /> : null}
              {projectsRedux?.length === 0 ? (
                <NoItems text="There is no projects" />
              ) : null}
              {show ? filtered : projectsToJSX}
            </MDBBox>
          </div>
        )}

        <CreateModal
          isOpen={modal === "CreateModal"}
          toggle={() => setModal("")}
        />

        <DeleteProjectModal
          isOpen={modal === "DeleteModal"}
          toggle={() => setModal("")}
          itemToDelete={projectToDelete ? { _id: projectToDelete._id } : null}
        />
        <UpdateProjectModal
          isOpen={modal === "UpdateModal"}
          toggle={() => setModal("")}
          itemToUpdate={projectToUpdate ? { _id: projectToUpdate._id } : null}
        />
        <Members
          open={modal === "ProjectListToEdit"}
          toggle={() => setModal("")}
          projectListToEdit={projectListToEdit ? projectListToEdit._id : null}
        />
        <ShareModal
          open={modal === "Share"}
          toggle={() => setModal("")}
          projectItem={projectListToEdit}
          setProjectItem={setProjectListToEdit}
        />

        <Modal
          title={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          open={requestModal}
          footer={null}
          onCancel={() => setRequestModal(false)}
        >
          <RequestCard
            status={featureRequests?.includes(requestType)}
            loading={requestLoading}
            icon={
              featureRequests?.includes(requestType) ? (
                <ClockCircleOutlined style={{ color: "black" }} />
              ) : (
                <InfoCircleOutlined style={{ color: "black" }} />
              )
            }
            title={
              featureRequests?.includes(requestType)
                ? `Request Already Sent`
                : `Oops, looks like you need to ${
                    userInfoRedux?.companyAdmin
                      ? `Acquire License`
                      : `Request Access`
                  }`
            }
            description={
              featureRequests?.includes(requestType)
                ? `Please reach out to your project Admin`
                : `Elevate your experience with exclusive futures, early releases, and premium features.`
            }
            buttonText={
              userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
            }
            buttonHandler={() => requestAccess()}
          />
        </Modal>
      </div>
      {activeTabRedux !== ACTIVE_TABS.KANBAN_VIEW && (
        <div className="mt-5">
          <FooterComponent />
        </div>
      )}
    </>
  );
};

export default PersonalArea;
