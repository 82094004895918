import formatTime from "helpers/formatTime";
import { MDBContainer, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import sortIcon from "assets/images/icons/sort_icon.svg";
import Tabel from "./tabel";
import { useDispatch, useSelector } from "react-redux";


// Define constant for columns
const COLUMNS = [
  { label: "Name", dataField: "title", dataType: "string" },
  { label: "", dataField: "favorite", dataType: "boolean" },
  { label: "Project No.", dataField: "projectNumber", dataType: "string" },
  { label: "Creation Date", dataField: "creationDate", dataType: "date" },
  { label: "3D Date", dataField: "date", dataType: "date" },
  { label: "Exported By", dataField: "exportedBy", dataType: "string" },
  { label: "Milestone", dataField: "milestone", dataType: "string" },
  { label: "Cost", dataField: "cost", dataType: "string" },
  { label: "Members", dataField: "n_users", dataType: "number" },
  { label: "Estimation Date", dataField: "estimationDate", dataType: "date" },
  { label: "", dataField: "", dataType: "" },
];

const ProjectListView = ({ projects, milestones, users: usersObj, setModal, setProjectToDelete, setProjectToUpdate, setProjectListToEdit, isAdmin, projectNumber, sortedToNewer }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("desc");

  const handleSort = (column) => {
    if (sortBy === column.dataField) {
      // Toggle sorting direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column.dataField);
      setSortDirection("desc");
    }
  };

  const sortedProjects = [...projects].sort((a, b) => {
    if (sortBy) {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (COLUMNS.find((column) => column.dataField === sortBy).dataType === "string") {
        return sortDirection === "asc" ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
      } else if (COLUMNS.find((column) => column.dataField === sortBy).dataType === "date") {
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
      } else if (COLUMNS.find((column) => column.dataField === sortBy).dataType === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      }
    }

    return 0;
  });

  useEffect(() => {
   handleSort({ label: "3D Date", dataField: "date", dataType: "date" })
  },[sortedToNewer])

  return (
    <div className="px-3 py-1 karban-container">
      <MDBContainer fluid>
        <div className="project-table-div position-relative w-100 mt-4 bid-table-border">
        <MDBTable small className="project-list-view-table">
          <MDBTableHead color="" textWhite>
            <tr>
              {COLUMNS.map((column, index) => (
                <th key={index} onClick={() => handleSort(column)}>
                  {column.label}
                  {sortBy === column.dataField && (
                    <img
                      src={sortIcon}
                      alt="Sort Icon"
                      style={{ marginLeft: '10px',transform: sortDirection === "asc" ? "rotate(180deg)" : "rotate(0deg)" }}
                    />
                  )}
                </th>
              ))}
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {sortedProjects.map((projectItem, i) => <Tabel isAdmin={isAdmin} key={i} projectItem={projectItem} milestones={milestones} usersObj={usersObj} setModal={setModal} setProjectToDelete={setProjectToDelete} setProjectToUpdate={setProjectToUpdate} setProjectListToEdit={setProjectListToEdit}/>)}
          </MDBTableBody>
        </MDBTable>
        </div>
      </MDBContainer>
    </div>
  );
};

export default ProjectListView;
