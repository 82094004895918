import React from "react";

export const LeftArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      transform="rotate(-90)"
    >
      <path
        d="M12.9998 14.7684L18.362 9.40625L19.8941 10.9383L12.9998 17.8327L6.10547 10.9383L7.63754 9.40625L12.9998 14.7684Z"
        fill="black"
      />
    </svg>
  );
};

export const RightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      transform="rotate(90)"
    >
      <path
        d="M12.9998 14.7684L18.362 9.40625L19.8941 10.9383L12.9998 17.8327L6.10547 10.9383L7.63754 9.40625L12.9998 14.7684Z"
        fill="black"
      />
    </svg>
  );
};

export const DownArrowIcon = () => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9998 14.7684L18.362 9.40625L19.8941 10.9383L12.9998 17.8327L6.10547 10.9383L7.63754 9.40625L12.9998 14.7684Z"
        fill="#09121F"
      />
    </svg>
  );
};
