import React from "react";
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from "mdbreact";
import { useEffect, useMemo, useRef, useState } from "react";
import { setCompanyUsers, setProjects, setSidebarStatus } from "actions/user";
import { IFC_Url, url } from "api";
import { getCompressedJsonFile } from "api/files/getJsonFile";
import { getProjects } from "api/projects/getProjects";
import TreeViewerItem from "components/TreeViewerItem";
import Loading from "components/ui-components/loading";
import { generateId } from "helpers/id";
import { Maybe } from "helpers/maybeFunctor";
import addNotification from "helpers/notify";
import {
  findRecursiveInTreeJsonId,
  findRecursiveInTreeListJsonId,
  generateRawMeshes,
  generateRawTree,
} from "helpers/three";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import TreeViewMenu from "react-simple-tree-menu";
import ResizablePanels from "resizable-panels-react";
import WebGlApp from "webgl";
import NoMeshModal from "../../components/NoMeshModal";
import { useHttp } from "../../hooks/useHttp";

import { useLogout } from "hooks/useLogout";
import "../ModelViewer/TimelineViewer.scss";

import { ReactComponent as CloseIcon } from "../../assets/images/down-arrow.svg";

import estimationImg from "assets/images/estimationImg.png";
import projectIcon from "assets/images/projectIcon.png";

import ModelResizableViewer from "components/hoc/resizable-viewer/modelResizable-viewer";
import { QuantityContext } from "../../hooks/quantityContext";
import Filters from "components/Share/Filters";

const Viewer3d = (props) => {
  const { projectId, token, line } = props;
  const dispatch = useDispatch();
  const logOut = useLogout();
  const viewerRef = useRef();
  const WebGL = useRef();
  const history = useHistory();
  const resizeRef = useRef();
  const [currencysymb, setCurrencysymb] = useState("");
  const projectsRedux = useSelector((s) => s.user.projects);
  const usersRedux = useSelector((s) => s.user.users);
  const selectedMesh = useRef(null);

  const project = Maybe.of(projectsRedux).map((projects) => {
    return projects.filter((project) => project._id == projectId)[0];
  }).value;

  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current && project && !isGLOpend) {
      setGLOpend(true);
      initializeAPI();
    }
    return () => (isMountedRef.current = false);
  }, [project]);

  const isMountedRef = useRef(null);

  const [clickedChild, setClickedChild] = useState([]);
  const [percentsLoaded, setPercentsLoaded] = useState(0);
  const [allowToResize, setAllowToResize] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [isPopover, setIsPopOver] = useState(false);
  const [popoverCoords, setCoordsForPopover] = useState({
    top: "0px",
    left: "0px",
  });
  //
  const [resize, setResize] = useState(null);
  const [isUnmatchedMeshes, setIsUnmatchedMeshes] = useState(false);
  //
  const [isExistingMeshes, setIsExistingMeshes] = useState(false);
  const [isDemolitionMeshes, setIsDemolitionMeshes] = useState(false);
  //
  const [isSecondScreen, setSecondScreen] = useState(true);
  const [isFirstScreen, setFirstScreen] = useState(true);

  const [selectGroups, setSelectGroups] = useState([]);
  const [isGLOpend, setGLOpend] = useState(false);

  const [menu, setMenu] = useState(false);

  // project files variables
  let filesMeshes = [];
  let treeFilesMeshes = [];

  let additionalMeshes = [];
  let electricalMeshes = [];
  let mechanicalMeshes = [];

  let [modelNumber, setModelNumber] = useState(0);
  let [modelsNames, setModelsNames] = useState([]);
  let [jsons, setJsons] = useState([]);
  let [models, setModels] = useState([]);
  let [additionalParams] = useState([]);
  let [electricalCircuits] = useState([]);
  let [mechanicalSystems] = useState([]);
  const [scrollId, setScrollId] = useState("");

  const [treeFilesData, setTreeFilesData] = useState([]);
  const [filesOpenNodes, setFilesOpenNodes] = useState([]);
  const [filesMeshesArray, setFilesMeshes] = useState(null);

  const [additionalMeshesArray, setAdditionalMeshes] = useState(null);
  const [electricalMeshesArray, setElectricalMeshes] = useState(null);
  const [mechanicalMeshesArray, setMechanicalMeshes] = useState(null);

  const [isFilesMeshes, setIsFilesMeshes] = useState(false);
  const [clickedFilesChild, setClickedFilesChild] = useState([]);

  const [FiltersArr, setFiltersArr] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);

  const meshQuantityInfoRef = useRef([]);

  let FilesMeshesForFilters = [];

  useEffect(() => {
    setFiltersArr([
      { ProjectFiles: treeFilesData.filter((item) => item) },
      { UnpricedComponents: [] },
      { ExistingComponents: [] },
      { DemolitionComponents: [] },
      { PricedComponents: [] },
      { AdditionalParams: additionalData.filter((item) => item) },
    ]);
  }, [treeFilesData, additionalData]);

  useEffect(() => {
    if (menu) {
      resizeRef.current.state.panelsSize = [100];
    } else {
      resizeRef.current.state.panelsSize = [0];
    }
  }, [menu]);

  const { request: initializeAPI } = useHttp({
    requestCallback: async (d) => {
      const obj1 = project?.zipInfo?.pathsmapping?.forEach((fname) => {
        if (fname.endsWith(".json") || fname.endsWith(".json.gz")) {
          setModelNumber(modelNumber);
          modelNumber = modelNumber + 1;
          var fileName = fname.split(".")[0];
          modelsNames.push(fileName);
          setModelsNames(modelsNames);
        }
      });

      var baseURL = url;
      if (project?.isIfcProject) {
        baseURL = project.isIfcProject ? IFC_Url : url;
      }
      const obj2 = project?.zipInfo?.paths?.forEach((path, i) => {
        const filename = project?.zipInfo?.pathsmapping[i];
        if (filename?.includes("_AdditionalParam")) {
          additionalParams.push(`${path}`);
        }
        if (filename?.includes("_ElectricalCircuits")) {
          electricalCircuits.push(`${path}`);
        }
        if (filename?.includes("_MechanicalSystems")) {
          mechanicalSystems.push(`${path}`);
        }
        if (path.endsWith(".glb") || path.endsWith(".glb.gz")) {
          models.push(`${baseURL}/${path}`);
        } else if (path.endsWith(".json") || path.endsWith(".json.gz")) {
          jsons.push(`${path}`);
        }
      });

      setModels(models);
      setJsons(jsons);
      for (let i = 0; i < modelNumber; i++) {
        var mNumber = i;
        var mpath = jsons[i];
        var mAdditionalPath = additionalParams[i];
        var mElectricalCircuitsPath = electricalCircuits[i];
        var mMechanicalSystemsPath = mechanicalSystems[i];

        // download raw json
        await getCompressedJsonFile(
          mpath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            filesMeshes[mNumber] = generateRawMeshes(res); //generateMeshes(res);
            treeFilesMeshes[mNumber] = generateRawTree(res);
          })
          .catch((err) => {});

        // download additional json
        await getCompressedJsonFile(
          mAdditionalPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            additionalMeshes[mNumber] = res;
          })
          .catch((err) => {});

        // download electrical json
        await getCompressedJsonFile(
          mElectricalCircuitsPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            electricalMeshes[mNumber] = res;
          })
          .catch((err) => {});

        // download mechanical json
        await getCompressedJsonFile(
          mMechanicalSystemsPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            mechanicalMeshes[mNumber] = res;
          })
          .catch((err) => {});
      }

      WebGL.current = new WebGlApp(
        viewerRef.current,
        {
          glbs: models,
        },
        {
          setClickedChild,
          setLoading,
          setError,
          setPercentsLoaded,
          setAllowToResize,
          setModal,
          setCoordsForPopover,
          findInJsonUsingViewer,
          setIsPopOver,
          onHoverMesh,
          selectedMesh,
          project,
        },
        line,
        false,
        project?.isIfcProject ? project?.isIfcProject : false
      );
    },
    onLoad: (res) => {
      setFilesMeshes([...filesMeshes]);
      setAdditionalMeshes(...additionalMeshes);
      setElectricalMeshes(...electricalMeshes);
      setMechanicalMeshes(...mechanicalMeshes);

      setTreeFilesData([...treeFilesMeshes]?.filter((item) => item));
      setFilesOpenNodes([
        ...filesOpenNodes,
        ...[...treeFilesMeshes]?.filter((item) => item).map((tv) => tv[0]?.key),
      ]);

      setAdditionalData(FilesMeshesForFilters);
    },
    onError: (e) => {
      console.error(e);
      setError(true);
    },
  });

  const {
    error: cacheError,
    loading: cacheLoading,
    errorStatus: cacheErrorStatus,
    request: cacheRequest,
    refresh,
  } = useHttpWithCache({
    requestCallback: () => getProjects(),
    reduxSetter: (data) => {
      dispatch(setProjects(data.projects));
      dispatch(setCompanyUsers(data.users));
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
    },
    reduxCash: {
      projects: projectsRedux,
      users: usersRedux,
    },
    allowToCash: true,
  });

  const onHoverMesh = (id) => {};

  const jsonInfo = Maybe.of(
    (() => {
      if (true) {
        return {
          neededMeshesArray: filesMeshesArray,
          neededClickedChild: clickedFilesChild,
        };
      }
    })()
  ).map(({ neededMeshesArray, neededClickedChild }) => {
    meshQuantityInfoRef.current = neededClickedChild;
    return neededClickedChild.length === 1
      ? neededClickedChild?.flatMap((i) => {
          return neededMeshesArray
            ?.flat()
            ?.find((k) => k?.UniqueId === i?.UniqueId);
        })[0]
      : neededClickedChild.reduce(
          (totalObject, mesh) => {
            const sumProps = Object.assign(
              {},
              {
                LaborInfoTime:
                  totalObject.LaborInfoTime + mesh.LaborInfo
                    ? mesh.LaborInfo?.map((l) => l.TotalTime)
                    : 0,
                EquipmentInfoTime:
                  totalObject.EquipmentInfoTime + mesh.EquipmentInfo
                    ? mesh.EquipmentInfo?.map((l) => l.TotalTime)
                    : 0,

                LaborInfoPrice:
                  totalObject.LaborInfoPrice + mesh.LaborInfo
                    ? mesh.LaborInfo?.map((l) => l.TotalPrice)
                    : 0,
                EquipmentInfoPrice:
                  totalObject.EquipmentInfoPrice + mesh.EquipmentInfo
                    ? mesh.EquipmentInfo?.map((l) => l.TotalPrice)
                    : 0,

                Area: totalObject.Area + mesh.Area,
                Count: totalObject.Count + mesh.Count,
                Length: totalObject.Length + mesh.Length,
                Quantity: totalObject.Quantity + mesh.Quantity,
                Volume: totalObject.Volume + mesh.Volume,
                TotalPrice: totalObject.TotalPrice + mesh.TotalPrice,
              },
              typeof totalObject.Width === "number"
                ? {
                    Width: totalObject.Width + mesh.Width,
                  }
                : {},
              typeof totalObject.Depth === "number"
                ? {
                    Depth: totalObject.Depth + mesh.Depth,
                  }
                : {},
              typeof totalObject.TotalAmount === "number"
                ? {
                    TotalAmount: totalObject.TotalAmount + mesh.TotalAmount,
                  }
                : {},
              typeof totalObject.Dimentions === "number"
                ? {
                    Dimentions: totalObject.Dimentions + mesh.Dimentions,
                  }
                : {}
            );

            const stringProps = Object.assign(
              {},
              totalObject.Uniformat === mesh.Uniformat && totalObject.Uniformat
                ? { Uniformat: "-" }
                : { Uniformat: mesh.Uniformat },
              totalObject.Name !== mesh.Name && totalObject.Name
                ? { Name: "-" }
                : { Name: mesh.Name },
              totalObject.Type !== mesh.Type && totalObject.Type
                ? { Type: "-" }
                : { Type: mesh.Type },
              totalObject.MainCategory !== mesh.MainCategory &&
                totalObject.MainCategory
                ? { MainCategory: "-" }
                : { MainCategory: mesh.MainCategory },
              totalObject.CategoryName !== mesh.CategoryName &&
                totalObject.CategoryName
                ? { CategoryName: "-" }
                : { CategoryName: mesh.CategoryName },
              totalObject.LaborInfo !== mesh.LaborInfo && totalObject.LaborInfo
                ? { LaborInfoType: "-" }
                : {
                    LaborInfoType: mesh.LaborInfo
                      ? mesh.LaborInfo?.map((l) => l.Description).join(" | ")
                      : "-",
                  },
              totalObject.EquipmentInfo !== mesh.EquipmentInfo &&
                totalObject.EquipmentInfo
                ? { EquipmentInfoType: "-" }
                : {
                    EquipmentInfoType: mesh.EquipmentInfo
                      ? mesh.EquipmentInfo?.map((l) => l.Description).join(
                          " | "
                        )
                      : "-",
                  }
            );

            if (!totalObject.LaborInfo || totalObject.LaborInfo == null) {
              totalObject.LaborInfo = [];
            }
            totalObject.LaborInfo.push(
              ...totalObject.LaborInfo,
              mesh.LaborInfo ? mesh.LaborInfo : null
            );

            if (
              !totalObject.EquipmentInfo ||
              totalObject.EquipmentInfo == null
            ) {
              totalObject.EquipmentInfo = [];
            }
            totalObject.EquipmentInfo.push(
              ...totalObject.EquipmentInfo,
              mesh.EquipmentInfo ? mesh.EquipmentInfo : null
            );

            if (!totalObject.MaterialInfo || totalObject.MaterialInfo == null) {
              totalObject.MaterialInfo = [];
            }

            totalObject.MaterialInfo.push(...totalObject.MaterialInfo, {
              Types: mesh.MaterialNames,
              Name: mesh.Type,
              TotalAmount: mesh.TotalAmount,
              TotalPrice: mesh.TotalPrice,
            });

            return { ...sumProps, ...stringProps };
          },
          neededClickedChild[0]
            ? {
                ...neededClickedChild[0],
                ...{
                  LaborInfoTime: 0,
                  EquipmentInfoTime: 0,
                  EquipmentInfoPrice: 0,
                  LaborInfoPrice: 0,
                  LaborInfo: [],
                  EquipmentInfo: [],
                  MaterialInfo: [],
                  Area: 0,
                  Count: 0,
                  Length: 0,
                  Quantity: 0,
                  Volume: 0,
                  TotalPrice: 0,
                },
              }
            : null
        );
  }).value;

  const [additionalFileInfo, setAdditionalFileInfo] = useState(null);
  const [electricalFileInfo, setElectricalFileInfo] = useState(null);
  const [mechanicalFileInfo, setMechanicalFileInfo] = useState(null);

  useEffect(() => {
    (async () => {
      const additionalMatchedData = await Maybe.of(
        (() => {
          if (true) {
            return {
              neededMeshesArray: additionalMeshesArray,
              neededClickedChild: clickedFilesChild,
            };
          }
        })()
      ).map(({ neededMeshesArray, neededClickedChild }) => {
        return neededClickedChild?.map((i) => {
          return neededMeshesArray?.find((j) => {
            return j?.UniqueId === i?.UniqueId;
          });
        });
      }).value;

      const electricalMatchedData = await Maybe.of(
        (() => {
          return {
            neededMeshesArray: electricalMeshesArray,
          };
        })()
      ).map(({ neededMeshesArray }) => {
        return additionalMatchedData?.map((i) => {
          return neededMeshesArray?.find((j) => {
            return j?.uniqueId === i?.EquipmentParameters?.ElectricalSystem;
          });
        });
      }).value;

      const mechanicalmatchedData = Maybe.of(
        (() => {
          if (true) {
            return {
              neededMeshesArray: mechanicalMeshesArray,
            };
          }
        })()
      ).map(({ neededMeshesArray }) => {
        return additionalMatchedData?.map((i) => {
          return neededMeshesArray?.find((j) => {
            return j?.uniqueId === i?.EquipmentParameters?.MechanicalSystem;
          });
        });
      }).value;

      setAdditionalFileInfo(additionalMatchedData?.filter((item) => item));
      setElectricalFileInfo(
        electricalMatchedData.filter(function (element) {
          return element !== undefined;
        })
      );
      setMechanicalFileInfo(
        mechanicalmatchedData.filter(function (element) {
          return element !== undefined;
        })
      );
    })();
  }, [clickedFilesChild]);

  const jsonFilesMeshesToJSX = useMemo(() => {
    return treeFilesData.map((section, mainIndex) => {
      return Maybe.of(section).map((tree) => (
        <TreeViewMenu key={generateId()} openNodes={filesOpenNodes} data={tree}>
          {({ items }) => {
            return items.map((item, index) => {
              return (
                <TreeViewerItem
                  item={item}
                  index={index}
                  clickedChild={clickedFilesChild}
                  setClickedChild={setClickedFilesChild}
                  selectGroups={selectGroups}
                  setSelectGroups={setSelectGroups}
                  key={generateId()}
                  selectedMesh={selectedMesh}
                  WebGL={WebGL}
                  tree={tree}
                  setTreeData={(updatedSection) => {
                    [...treeFilesData][mainIndex] = updatedSection;
                    setTreeFilesData([...treeFilesData]);
                  }}
                  setOpenNodes={setFilesOpenNodes}
                  isUnmatchedMeshes={() => setIsUnmatchedMeshes(false)}
                  isExistingMeshes={() => setIsExistingMeshes(false)}
                  isDemolitionMeshes={() => setIsDemolitionMeshes(false)}
                  isFilesMeshes={() => setIsFilesMeshes(true)}
                  selectors={{
                    prevMeshes: "prevFilesMeshes",
                    prevMaterials: "prevFilesMaterials",
                    color: "green",
                  }}
                />
              );
            });
          }}
        </TreeViewMenu>
      )).value;
    });
  }, [[...treeFilesData], clickedFilesChild, filesOpenNodes]);

  const pickMeshInNeededTree = (
    clicked,
    treeMeshes,
    setOpenNodes,
    setClickedChild,
    keyIsCtrl,
    selectors
  ) => {
    console.log(treeMeshes);
    console.log(clicked);

    setSelectGroups([]);
    if (!clicked) {
      setModal(true);
    } else {
      let newClicked;

      clicked.forEach((m) => {
        if (m) {
          newClicked = m;
          console.log("item within children", m);
        }
      });

      let path;

      if (clicked.length > 1) {
        path = findRecursiveInTreeListJsonId(treeMeshes, newClicked.$id).path;
        console.log(
          "treeList > json path from pick needed mesh ",
          treeMeshes,
          "clicked.id = ",
          newClicked.$id
        );
      } else {
        path = findRecursiveInTreeJsonId(...treeMeshes, newClicked?.$id).path;

        console.log(
          "tree > json path from pick needed mesh ",
          treeMeshes,
          "clicked.id = ",
          clicked.$id
        );
      }

      clicked = newClicked;

      console.log("path from pick needed mesh ", path);

      const nodesKey = [
        path[0].id,
        `${path[0].id}/${path[1].id}`,
        `${path[0].id}/${path[1].id}/${path[2].id}`,
        `${path[0].id}/${path[1].id}/${path[2].id}/${path[3].id}`,
      ];
      nodesKey.forEach((nodeKey) => {
        setOpenNodes((s) => {
          if (s?.includes(nodeKey)) {
            return s;
          }
          return [...s, nodeKey];
        });
      });
      setClickedChild((s) => {
        if (keyIsCtrl) {
          if (s.find((mesh) => mesh.UniqueId === clicked.UniqueId)) {
            const neededIndex = s.findIndex(
              (mesh) => mesh.UniqueId === clicked.UniqueId
            );
            return [...s.slice(0, neededIndex), ...s.slice(neededIndex + 1)];
          }
          return [...s, clicked];
        } else {
          return [clicked];
        }
      });
      setScrollId(path[3].id);

      WebGL.current.viewer.onClickChildInList(
        clicked ? clicked.UniqueId : "",
        keyIsCtrl,
        selectors,
        false
      );
      selectedMesh.current = clicked.UniqueId;
    }
  };

  const findInJsonUsingViewer = (id, keyIsCtrl) => {
    // Project files
    const clickedFilesMesh = filesMeshes.map((file) => {
      return file.find((mesh) => {
        return mesh.UniqueId === id;
      });
    });

    if (clickedFilesMesh) {
      setIsFilesMeshes(true);
      setIsDemolitionMeshes(false);
      setIsExistingMeshes(false);
      setIsUnmatchedMeshes(false);
      pickMeshInNeededTree(
        clickedFilesMesh,
        treeFilesMeshes?.filter((item) => item),
        setFilesOpenNodes,
        setClickedFilesChild,
        keyIsCtrl,
        {
          prevMeshes: "prevFilesMeshes",
          prevMaterials: "prevFilesMaterials",
          color: "green",
        }
      );
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    if (!projectsRedux && !line) {
      if (isMountedRef.current) {
        cacheRequest();
      }
    }
    return () => (isMountedRef.current = false);
  }, [projectsRedux]);

  useEffect(() => {
    isMountedRef.current = true;
    if (scrollId && document.getElementById(scrollId)) {
      if (isMountedRef.current) {
        document.getElementById(scrollId).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
    return () => (isMountedRef.current = false);
  }, [scrollId]);

  useEffect(() => {
    if (cacheErrorStatus) {
      const stringError = String(cacheErrorStatus);
      addNotification(stringError, "danger");
      if (stringError.toLowerCase()?.includes("unexpected token")) {
        logOut();
        history.push("/");
      }
    }
  }, [cacheErrorStatus]);

  const infoPanel = (
    <div
      style={{
        minWidth: `${isSecondScreen ? resize?.panels[1] || 600 : ""}px`,
        maxWidth: `${isSecondScreen ? resize?.panels[1] || 600 : ""}px`,
      }}
      className="viewer-outer-div"
    >
      <div className="px-2 d-flex justify-content-between align-items-center overflow-hidden viewer-header-item viewer-gapping">
        <div className="d-flex align-items-center viewer-header-item ">
          <img src={estimationImg} className="estimation-icon" />
          Component Details:
        </div>

        <MDBBox
          onClick={() => setSecondScreen((s) => !s)}
          className={`viewer-icon ${
            !isSecondScreen ? "viewer-icon-rotate" : ""
          }`}
        >
          <CloseIcon />
        </MDBBox>
      </div>
    </div>
  );

  const ProjectPanel = (
    <div
      style={{
        minWidth: `${isFirstScreen ? resize?.panels[2] || 600 : ""}px`,
        maxWidth: `${isFirstScreen ? resize?.panels[2] || 600 : ""}px`,
      }}
      className="viewer-outer-div"
    >
      <div className="px-2 d-flex justify-content-between align-items-center overflow-hidden viewer-header-item viewer-gapping">
        <MDBBox className="viewer-header-item d-flex align-items-center">
          <img src={projectIcon} className="project-icon" />
          Project Files
        </MDBBox>
        <MDBBox
          onClick={() => setFirstScreen((s) => !s)}
          className={`viewer-icon ${
            !isFirstScreen ? "viewer-icon-rotate" : ""
          }`}
        >
          <CloseIcon />
        </MDBBox>
      </div>
    </div>
  );

  return (
    <div className="timeline-viewer-container mt-2">
      <MDBBox className="viewer d-flex overflow-hidden flex-wrap">
        <MDBBox className="viewer-header viewer-header1 d-flex w-100">
          {isFirstScreen && isSecondScreen && (
            <>
              {ProjectPanel}
              {infoPanel}
            </>
          )}

          {!isFirstScreen && isSecondScreen && (
            <>
              {infoPanel}
              {ProjectPanel}
            </>
          )}
          {isFirstScreen && !isSecondScreen && (
            <>
              {ProjectPanel}
              {infoPanel}
            </>
          )}

          {!isFirstScreen && !isSecondScreen && (
            <>
              {ProjectPanel}
              {infoPanel}
            </>
          )}

          <div className="d-flex align-items-center justify-content-end flex-grow-1 px-2 viewer-header-item viewer-gapping h-100">
            {project ? (
              <MDBBox tag="span" className="viewer-header-item">
                <div className="d-flex align-items-center project-admin-header ">
                  <i className="fa fa-home mr-2"></i> Project:
                  <strong className="ml-2">{project.title}</strong>
                </div>
              </MDBBox>
            ) : null}
            {/* <button
              className="bg-bid header-btn text-white ml-2 font-weight-light"
              onClick={() => setMenu(!menu)}
            >
              {" "}
              <i className="fa fa-filter mr-2" />
              Filters
            </button> */}
          </div>
        </MDBBox>

        {/* resizable main container starts */}
        <ModelResizableViewer
          width="100%"
          height="100%"
          WebGlApp={WebGL.current}
          setResize={setResize}
          isFirstScreen={isFirstScreen}
          isSecondScreen={isSecondScreen}
          allowToResize={allowToResize}
        >
          {/* project files container starts */}
          <div style={{ height: "100%" }}>
            <ResizablePanels
              displayDirection="column"
              width="100%"
              maxheight="100%"
              panelsSize={0}
              resizerSize="3px"
              sizeUnitMeasure="%"
            >
              <MDBBox className="overflow-auto h-76vh inner_resizable">
                {jsonFilesMeshesToJSX}
                {loading ? (
                  <MDBBox className="h-100">
                    <Loading color="black" text="Loading components..." />
                  </MDBBox>
                ) : null}
              </MDBBox>
              <MDBBox></MDBBox>
            </ResizablePanels>
          </div>

          {/* estimation-info container starts */}
          <MDBBox className="overflow-auto h-76vh inner_resizable">
            <MDBListGroup>
              {console.log(jsonInfo)}
              {jsonInfo &&
                Object.entries(jsonInfo).map((keyAndValue) => {
                  const [key, value] = keyAndValue;
                  if (key === "$id") {
                    return null;
                  }

                  if (key === "TotalPrice" || key === "UnitPrice") {
                    return null;
                  }

                  return (
                    <MDBListGroupItem
                      key={key}
                      className="border border-light py-1 text-truncate"
                    >
                      {`${key}: ${value}`}
                    </MDBListGroupItem>
                  );
                })}
            </MDBListGroup>

            {/* Additional-info container starts */}
            {additionalFileInfo?.length === 1 && (
              <>
                <div className="px-2 d-flex justify-content-between align-items-center overflow-hidden viewer-header-item viewer-gapping">
                  <div className="d-flex align-items-center viewer-header-item ">
                    <img src={estimationImg} className="estimation-icon" />
                    Additional Details:
                  </div>
                </div>

                <MDBListGroup>
                  <MDBListGroupItem className="border border-light py-1 text-truncate">
                    {`Name: ${additionalFileInfo[0]?.Name}`}
                  </MDBListGroupItem>

                  {Object.entries(
                    additionalFileInfo[0]?.BuiltInParameters || {}
                  )?.map((keyandvalue) => {
                    const [key, value] = keyandvalue;
                    return (
                      <MDBListGroupItem
                        className="border border-light py-1 text-truncate"
                        key={key}
                      >
                        {`${key}: ${value}`}
                      </MDBListGroupItem>
                    );
                  })}

                  {Object.entries(
                    additionalFileInfo[0]?.EquipmentParameters || {}
                  )?.map((keyandvalue) => {
                    const [key, value] = keyandvalue;
                    return (
                      <MDBListGroupItem
                        className="border border-light py-1 text-truncate"
                        key={key}
                      >
                        {`${key}: ${value}`}
                      </MDBListGroupItem>
                    );
                  })}

                  {Object.entries(
                    additionalFileInfo[0]?.FamilyParameters || {}
                  )?.map((keyandvalue) => {
                    const [key, value] = keyandvalue;
                    return (
                      <MDBListGroupItem
                        className="border border-light py-1 text-truncate"
                        key={key}
                      >
                        {`${key}: ${value}`}
                      </MDBListGroupItem>
                    );
                  })}

                  {Object.entries(
                    additionalFileInfo[0]?.GlobalParameters || {}
                  )?.map((keyandvalue) => {
                    const [key, value] = keyandvalue;
                    return (
                      <MDBListGroupItem
                        className="border border-light py-1 text-truncate"
                        key={key}
                      >
                        {`${key}: ${value}`}
                      </MDBListGroupItem>
                    );
                  })}

                  {Object.entries(
                    additionalFileInfo[0]?.ProjectParameters || {}
                  )?.map((keyandvalue) => {
                    const [key, value] = keyandvalue;
                    return (
                      <MDBListGroupItem
                        className="border border-light py-1 text-truncate"
                        key={key}
                      >
                        {`${key}: ${value}`}
                      </MDBListGroupItem>
                    );
                  })}

                  {Object.entries(
                    additionalFileInfo[0]?.SharedParameters || {}
                  )?.map((keyandvalue) => {
                    const [key, value] = keyandvalue;
                    return (
                      <MDBListGroupItem
                        className="border border-light py-1 text-truncate"
                        key={key}
                      >
                        {`${key}: ${value}`}
                      </MDBListGroupItem>
                    );
                  })}
                </MDBListGroup>
              </>
            )}

            {/* Electrical-info container starts */}
            {electricalFileInfo?.length === 1 && (
              <>
                <div className="px-2 d-flex justify-content-between align-items-center overflow-hidden viewer-header-item viewer-gapping">
                  <div className="d-flex align-items-center viewer-header-item ">
                    <img src={estimationImg} className="estimation-icon" />
                    Electrical Systems:
                  </div>
                </div>

                <MDBListGroup>
                  {electricalFileInfo?.map((item) => {
                    return Object.entries(item).map((keyAndValue) => {
                      const [key, value] = keyAndValue;
                      return (
                        <MDBListGroupItem
                          key={key}
                          className="border border-light py-1 text-truncate"
                        >
                          {`${key}: ${value}`}
                        </MDBListGroupItem>
                      );
                    });
                  })}
                </MDBListGroup>
              </>
            )}

            {/* Mechanical-info container starts */}
            {mechanicalFileInfo?.length === 1 && (
              <>
                <div className="px-2 d-flex justify-content-between align-items-center overflow-hidden viewer-header-item viewer-gapping">
                  <div className="d-flex align-items-center viewer-header-item ">
                    <img src={estimationImg} className="estimation-icon" />
                    Mechanical Systems:
                  </div>
                </div>
                <MDBListGroup>
                  {mechanicalFileInfo?.map((item) => {
                    return Object.entries(item).map((keyAndValue) => {
                      const [key, value] = keyAndValue;
                      return (
                        <MDBListGroupItem
                          key={key}
                          className="border border-light py-1 text-truncate"
                        >
                          {`${key}: ${value}`}
                        </MDBListGroupItem>
                      );
                    });
                  })}
                </MDBListGroup>
              </>
            )}
          </MDBBox>

          {/* 3d modal and share container starts */}
          <div className="h-100 position-relative">
            <ResizablePanels
              displayDirection="column"
              width="100%"
              height="100%"
              panelsSize={[0, 100]}
              resizerSize={menu ? "10px" : "0px"}
              resizerColor="#E6EAF4"
              sizeUnitMeasure="%"
              ref={resizeRef}
            >
              <QuantityContext.Provider
                value={{
                  Currency: currencysymb,
                  meshQuantityInfoRef: meshQuantityInfoRef.current,
                }}
              >
                <div className="share-container-new">
                  <div
                    className="share-wrappre"
                    style={menu ? {} : { display: "none" }}
                  >
                    <div className="share">
                      <Filters
                        FiltersData={FiltersArr}
                        setTreeFilesData={setTreeFilesData}
                        WebGL={WebGL}
                      />
                    </div>
                  </div>
                </div>
              </QuantityContext.Provider>

              <div
                id="WebglViewer"
                style={{ flexGrow: 1 }}
                className="h-100 position-relative"
                ref={viewerRef}
              >
                {loading ? (
                  <MDBBox className="h-100 w-100 flex-center flex-column">
                    <Loading color="black" text="Loading model..." />
                    {percentsLoaded ? (
                      <span>{percentsLoaded}% has loaded...</span>
                    ) : null}
                  </MDBBox>
                ) : null}
                <MDBBtnGroup
                  size="sm"
                  className="position-absolute viewer-zoom"
                >
                  <p id="clippingTooltip" />
                  <MDBBtn
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn "
                    onClick={() => {
                      window.location.reload(false);
                    }}
                  >
                    <MDBIcon icon="refresh" className="font-size-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => WebGL.current.viewer.onZoom(0.1)}
                  >
                    <MDBIcon icon="plus" className="font-size-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => WebGL.current.viewer.onZoom(-0.1)}
                  >
                    <MDBIcon icon="minus" className="font-size-1" />
                  </MDBBtn>
                  <MDBBtn
                    title="Take a screenshot of the current view"
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => WebGL.current.viewer.onScreenShot()}
                  >
                    <a id="take-snapshot">
                      <MDBIcon icon="camera" className="font-size-1" />
                      &nbsp; <b>[S] </b>
                    </a>
                  </MDBBtn>

                  <MDBBtn
                    title="Go to Home view"
                    size="sm"
                    position="left"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => {
                      WebGL.current.viewer.onInitialPosition();
                    }}
                  >
                    <MDBIcon icon="home" className="font-size-1" />
                    &nbsp; <b>[H] </b>
                  </MDBBtn>

                  <MDBBtn
                    title="Zoom to selected item in viewer"
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn "
                    onClick={() => {
                      WebGL.current.viewer.targetItems();
                    }}
                  >
                    <MDBIcon icon="map-marker" className="font-size-1" />
                    &nbsp; <b>[F] </b>
                  </MDBBtn>
                  <MDBBtn
                    id="gridToggleBtn"
                    title="Toggle Grid in viewer"
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
                      WebGL.current.viewer.onShowGrid(e.currentTarget);
                    }}
                  >
                    <MDBIcon icon="braille" className="font-size-1" />
                    &nbsp; <b>[G] </b>
                  </MDBBtn>
                  <MDBBtn
                    id="measurementToggleBtn"
                    title="Toggle Measurement Tool in viewer"
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
                      WebGL.current.viewer.toggleMeasurement(e.currentTarget);
                    }}
                  >
                    <MDBIcon icon="tape" className="font-size-1" />
                    &nbsp; <b>[M] </b>
                  </MDBBtn>
                  <MDBBtn
                    id="clippingToggleBtn"
                    title="Toggle Clipping Tool in viewer"
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
                      WebGL.current.viewer.toggleClipping(e.currentTarget);
                    }}
                  >
                    <MDBIcon icon="cut" className="font-size-1" />
                    &nbsp; <b>[C] </b>
                  </MDBBtn>

                  <MDBBtn
                    id="isolateToggleBtn"
                    title="Toggle Isolation Tool in viewer"
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
                      WebGL.current.viewer.toggleIsolate(e.currentTarget);
                    }}
                  >
                    <MDBIcon icon="box" className="font-size-1" />
                    &nbsp; <b>[I] </b>
                  </MDBBtn>
                </MDBBtnGroup>
              </div>
            </ResizablePanels>
          </div>
        </ModelResizableViewer>

        <NoMeshModal isOpen={modal} toggle={() => setModal((s) => !s)} />
      </MDBBox>
    </div>
  );
};

export default Viewer3d;
