const { get, post, putCatalog,remove } = require("api");

export const getCatalogs = (query) => {
  return get(
    `/data/${query.catalogKey}?OrganizationId=${query.OrganizationId}&Country=${query.Country}&City=${query.City}&ProjectType=${query.ProjectType}&LaborType=${query.LaborType}&State=${query.State}`,
    true
  );
};

//API call to get data of version, awps, categories by giving their unique key
export const getDataByEndpoint = (query) => {
  return get(`/data/${query}`, true);
};

export const getExchangeRate = (data) => {
  return get(
    `/rates?from=${data.from}&to=${data.to}&value=${data.value}`,
    true
  );
};

export const getCategories = (query) => {
  let url = `/data/categories`;
  if (query) {
    url = `${url}?$skip=${query.skip}&$limit=${query.limit}&$search=${query.search}&$column=${query.column}`;
  }
  return get(url, true);
};

export const addNewProjectType = (OrganizationId, body) => {
  return post(
    `/data/project_types?OrganizationId=${OrganizationId}&Override=false`,
    body,
    false,
    true
  );
};

export const addNewLaborType = (OrganizationId, body) => {
  return post(
    `/data/labor_types?OrganizationId=${OrganizationId}&Override=false`,
    body,
    false,
    true
  );
};

export const addNewTemplate = (OrganizationId, body) => {
  return post(
    `/data/versions?OrganizationId=${OrganizationId}&Override=false`,
    body,
    false,
    true
  );
};

export const addNewCatalogs = (format, OrganizationId, body) => {
  return post(
    `/data/${format}?OrganizationId=${OrganizationId}&Override=false`,
    body,
    false,
    true
  );
};

export const updateCatalogs = (format, catalog) => {
  return putCatalog(`/data/${format}/${catalog?._id}`, catalog, false, true);
};

export const deleteCatalog = (format, catalog) => {
  return remove(`/data/${format}/${catalog._id}`, catalog, true);
};
