import React, { useState } from 'react';
import { MDBBox } from 'mdbreact';
import jwt_decode from 'jwt-decode';

import Loading from '../ui-components/loading';

import { requestResetPassword } from 'api/auth/requestResetPassword';
import { setJWT, tokenRef, url } from 'api';
import addNotification from 'helpers/notify';
import { useDispatch } from 'react-redux';
import { useHttp } from 'hooks/useHttp';
import { setUser } from 'actions/user';
import capitalize from 'helpers/capitalize';

let RequestResetPassword = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stayedInSystem, setStayedInSystem] = useState(false);
  const dispatch = useDispatch();

  const { loading, request: authorizeAPI } = useHttp({
    requestCallback: requestResetPassword,
    onLoad: res => {
      if (res.err) {
        addNotification(res.err, 'danger');
      } else {
        localStorage.setItem(tokenRef, res.token);
        setJWT(res.token);
        dispatch(setUser(jwt_decode(res.token)));
      }
    },
    onError: err => {
      addNotification(err.err, 'danger');
    },
  });

  const submitResendPassword = (email, password) => e => {
    e.preventDefault();
    if (email.length === 0) {
      addNotification('Email field must not be empty!', 'danger');
    } else {
      const user = { email };
      return requestResetPassword(user).then(res => {
        if (res.err) {
          addNotification(res.err, 'danger');
        } else {
          addNotification(
            'Request to reset password sent successfully.',
            'success',
          );
        }
      }).catch(err => {
        if (err) {
          addNotification(err.err, 'danger');
        } 
      });
    }
  };

  return (
    <form
      className="mt-5 px-4"
      onSubmit={submitResendPassword(email, password)}
    >
       <h2 className='text-center title-login'>Reset Password</h2>
      <div className="form-group">
      <div className='icon_login'>
      <svg xmlns="http://www.w3.org/2000/svg" id="mail" width="18" height="14.324" viewBox="0 0 18 14.324">
  <path id="mail-2" data-name="mail" d="M3.01,5.838a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1V17.162a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2v-11a1.008,1.008,0,0,1,.01-.14ZM5,8.062v9.1H19v-9.1l-4.878,4.879a3,3,0,0,1-4.243,0ZM6.572,6.806H17.428l-4.721,4.721a1,1,0,0,1-1.414,0Z" transform="translate(-3 -4.838)" fill="#aeaeae"/>
</svg>
        </div>
        <input
          type="email"
          className="input-login"
          placeholder="Your email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="submit"
          className="btn-login-login bg-grey-blue"
          value="Reset password"
          disabled={loading}
        />
      </div>
    </form>
  );
};
export default RequestResetPassword;
