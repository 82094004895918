import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useSearch } from "hooks/useSearch";
import { eventTrack } from "../../helpers/ga4Helper";
import "react-input-range/lib/css/index.css";
import { setAdminMobileSidebarStatus, setSidebarStatus } from "actions/user";
import Hamburger from "assets/images/icons/hamburger.png";


const TopNavbar = ({ handleSearch }) => {
  const [sortedToNewer, setSortedToNewer] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const PATHNAME = {
    MODAL_HEALTH_SETTINGS: "/admin/model-health-settings",
    AFFILIATIONS: "/admin/affiliations",
    COMPANY_SETTINGS: "/admin/company-settings",
    SUPPORT_CHAT: "/admin/support-chat",
    PROJECTS: "/admin/projects-settings",
    MILESTONES: "/admin/milestones",
    DISCIPLINE: "/admin/discipline-settings",
    REVISIONS: "/admin/revision-settings",
    USER_PERMISSION: "/admin/user-permissions",
    EXPORT_SETTINGS: "/admin/export-settings",
    PRICING_CATEGLOGS: "/pricing-catalogues",
  };

  const { companyAdmin, admin, company, name, lastName, email, role } =
    userInfoRedux;

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  const toggleSidebar = () => {};

  // console.log(history)

  return (
    <>
      {userInfoRedux?.admin || userInfoRedux?.companyAdmin ? (
        <div className="inner-header-position admin-topnavbar">
          <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center">
              <div
                onClick={() => dispatch(setSidebarStatus(true))}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                >
                  <path
                    d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                    fill="#9E9E9E"
                  />
                </svg>
              </div>
              <p className="my-heading f-14 ml-3 mr-3">Admin</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
              <p className="my-heading f-14 ml-3">
                {location.pathname === PATHNAME.PROJECTS
                  ? "Projects Settings"
                  : location.pathname === PATHNAME.AFFILIATIONS
                  ? "Affiliations"
                  : location.pathname === PATHNAME.COMPANY_SETTINGS
                  ? "Company Settings"
                  : location.pathname === PATHNAME.DISCIPLINE
                  ? "Disciplines settings"
                  : location.pathname === PATHNAME.REVISIONS
                  ? "Revisions settings"
                  : location.pathname === PATHNAME.MODAL_HEALTH_SETTINGS
                  ? "Modal Health settings"
                  : location.pathname === PATHNAME.MILESTONES
                  ? "Milestones"
                  : location.pathname === PATHNAME.USER_PERMISSION
                  ? "User, Roles & Permissions"
                  : location.pathname === PATHNAME.EXPORT_SETTINGS
                  ? "Export Settings"
                  : location.pathname === PATHNAME.PRICING_CATEGLOGS
                  ? "Pricing Catalogs"
                  : location.pathname === PATHNAME.SUPPORT_CHAT
                  ? "Support Chat"
                  : null}
              </p>
            </div>
            <div
              className="projActionButtons d-flex align-items-center"
              style={{ maxWidth: "100vw" }}
            >
              <div className="hamburger-div mobile-show">
                <img
                  src={Hamburger}
                  className="w-20px cursor-pointer"
                  onClick={() => dispatch(setAdminMobileSidebarStatus(true))}
                />
              </div>
              <input
                type="text"
                className="search-bar"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
                // disabled={loading}
              />
              <div className="vr mx-3 desktop-show"></div>
              <div className="btn-tool-group">
                {/* admin */}
                <button
                  onClick={() => {
                    history.push(`/admin/projects-settings`);
                    {
                      eventTrack(
                        "View-Admin-Action",
                        "View-Admin",
                        name,
                        "+1",
                        false,
                        "+1"
                      );
                    }
                  }}
                  className="bg-bid px-3 mr-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                    fill="none"
                  >
                    <path
                      d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                      fill="white"
                    />
                  </svg>
                  Admin
                </button>

                {/* sort */}
                {/* <div className="vr mx-3"></div> */}

                {/* <button
                  onClick={() => setSortedToNewer(!sortedToNewer)}
                  className="px-3 bg-white text-bid shadow-hover"
                >
                  {!sortedToNewer ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                    >
                      <path
                        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                        fill="#3A5765"
                      />
                      <path
                        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                        fill="black"
                        fillOpacity="0.2"
                      />
                    </svg>
                  ) : (
                    <div className="upsideDown-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                      >
                        <path
                          d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                          fill="#3A5765"
                        />
                        <path
                          d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
                          fill="black"
                          fillOpacity="0.2"
                        />
                      </svg>
                    </div>
                  )}
                  Sort
                </button> */}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TopNavbar;
