import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HealthIcon from "assets/images/icons/Health (1).png";
import PassImg from "assets/images/icons/pass.png";
import { eventTrack } from "../../../helpers/ga4Helper";
import PassedRule from "./PassedRule";
import FailedRule from "./FailedRule";
import OthersRule from "./OthersRule";
import CurvedRangeSlider from "components/CurvedRangeSlider/CurvedRangeSlider";
import { useHistory, Link, useLocation } from "react-router-dom";
import { setSidebarStatus } from "actions/user";
import { useParams } from "react-router-dom";
import { useHttp } from "hooks/useHttp";
import Loader from "components/Loader";
import { getHealthReportbyId } from "api/healthCheck/getHealthReportbyId";

export default function Report() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { reportId } = useParams();

  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const { companyAdmin, admin, company, name, lastName, email } = userInfoRedux;

  const ACTIVE_TABS = {
    PASSED: "PASSED",
    FAILED: "FAILED",
    OTHERS: "OTHERS",
  };
  const [activeTab, setActiveTab] = useState(ACTIVE_TABS.PASSED);
  const [totalScore, setTotalScore] = useState();
  const [reportData, setReportData] = useState();
  const [reportStatus, setReportStatus] = useState("");

  const { loading, request: getHealthReportByIdAPI } = useHttp({
    requestCallback: () => getHealthReportbyId(reportId),
    onLoad: (res) => {
      setReportData(res);
      CalcluateTotalScore(res);
      setReportStatus(res?.summary?.reportStatus);
    },
    onError: (res) => {},
  });

  const CalcluateTotalScore = (res) => {
    setTotalScore(
      (
        ((Number(res?.summary?.healthStatus.criticalPassedRules) +
          Number(res?.summary?.healthStatus.normalPassedRules)) /
          (Number(res?.summary?.criticalRules) +
            Number(res?.summary?.normalRules))) *
        100
      ).toFixed(2)
    );
  };

  useEffect(() => {
    getHealthReportByIdAPI();
  }, [reportId]);

  return (
    <div className="personal-area">
      {loading && <Loader />}
      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
            <Link
              to={"/model-health"}
              className="my-heading f-14 ml-3 mr-3 headerLink"
            >
              Health Check Report
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>
            <p className="my-heading f-14 ml-3 mr-3">Health Report</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <div className="btn-tool-group">
              {admin || companyAdmin ? (
                <button
                  onClick={() => {
                    history.push(`/admin/projects-settings`);
                    {
                      eventTrack(
                        "View-Admin-Action",
                        "View-Admin",
                        name,
                        "+1",
                        false,
                        "+1"
                      );
                    }
                    eventTrack(
                      "Navigate-Admin-Section-Action",
                      "Navigate-Admin-Section",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }}
                  className="bg-bid px-3 mr-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                    fill="none"
                  >
                    <path
                      d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                      fill="white"
                    />
                  </svg>
                  Admin
                </button>
              ) : null}

              <div className="vr mx-3"></div>

              <button
                disabled={loading}
                onClick={() => getHealthReportByIdAPI()}
                className="px-3 bg-white text-bid shadow-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
                Regenerate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-0 pt-4">
        <div className="w-100 rounded-2 py-2 px-2 border bg-white mt-0">
          <div className="row row-low-padding">
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <CurvedRangeSlider
                totalScore={totalScore}
                status={reportStatus}
              />
            </div>

            <div className="col-md-4">
              <div className="text-center">
                <div className="rules-states-outer-container">
                  <p className="f-18 mb-3 mt-1 text-bid mb-0 poppins-medium d-block">
                    Critical rules
                  </p>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="rules-stats-div total">
                      <h2>{reportData?.summary?.criticalRules}</h2>
                      <p>
                        Total{" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 18 13"
                            fill="none"
                          >
                            <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                          </svg>
                        </span>
                      </p>
                    </div>
                    <div
                      className={` ${"rules-stats-div"} ${
                        reportData?.summary?.healthStatus?.criticalPassedRules >
                        0
                          ? "great"
                          : "great"
                      }`}
                    >
                      <h2>
                        {reportData?.summary?.healthStatus?.criticalPassedRules}
                      </h2>
                      <p>
                        Pass
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 18 13"
                            fill="none"
                          >
                            <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                          </svg>
                        </span>
                      </p>
                    </div>
                    <div className="rules-stats-div fail">
                      <h2>
                        {reportData?.summary?.healthStatus?.criticalFailedRules}
                      </h2>
                      <p>
                        Failed{" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                          >
                            <path
                              d="M7.0007 5.0865L11.9504 0.136719L13.3646 1.55093L8.4149 6.5007L13.3646 11.4504L11.9504 12.8646L7.0007 7.9149L2.05093 12.8646L0.636719 11.4504L5.5865 6.5007L0.636719 1.55093L2.05093 0.136719L7.0007 5.0865Z"
                              fill="#B22222"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="text-center">
                <div className="rules-states-outer-container">
                  <p className="f-18 mb-3 mt-1 text-bid mb-0 poppins-medium d-block">
                    Normal rules
                  </p>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="rules-stats-div total">
                      <h2>{reportData?.summary?.normalRules}</h2>
                      <p>
                        Total{" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 18 13"
                            fill="none"
                          >
                            <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                          </svg>
                        </span>
                      </p>
                    </div>
                    <div
                      className={` ${"rules-stats-div"} ${
                        reportData?.summary?.healthStatus?.normalPassedRules > 0
                          ? "great"
                          : "great"
                      }`}
                    >
                      <h2>
                        {reportData?.summary?.healthStatus?.normalPassedRules}
                      </h2>
                      <p>
                        Pass
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 18 13"
                            fill="none"
                          >
                            <path d="M7.0007 10.1709L16.1931 0.978516L17.6073 2.39273L7.0007 12.9993L0.636719 6.6354L2.05093 5.2212L7.0007 10.1709Z" />
                          </svg>
                        </span>
                      </p>
                    </div>
                    <div className="rules-stats-div fail">
                      <h2>
                        {reportData?.summary?.healthStatus?.normalFailedRules}
                      </h2>

                      <p>
                        Failed{" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                          >
                            <path
                              d="M7.0007 5.0865L11.9504 0.136719L13.3646 1.55093L8.4149 6.5007L13.3646 11.4504L11.9504 12.8646L7.0007 7.9149L2.05093 12.8646L0.636719 11.4504L5.5865 6.5007L0.636719 1.55093L2.05093 0.136719L7.0007 5.0865Z"
                              fill="#B22222"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div
                className={`${"rules-states-outer-container h-100"} ${
                  reportData?.summary?.reportStatus === "Pass" &&
                  "bg-light-warning"
                } ${
                  reportData?.summary?.reportStatus === "Great" &&
                  "bg-light-success"
                } ${
                  reportData?.summary?.reportStatus === "Fail" &&
                  "bg-light-danger"
                }`}
              >
                <div
                  className={`${"rules-stats-div w-100 h-100"} ${
                    reportData?.summary?.reportStatus === "Pass" && "pass"
                  } ${
                    reportData?.summary?.reportStatus === "Great" && "great"
                  } ${reportData?.summary?.reportStatus === "Fail" && "fail"}`}
                >
                  {reportData?.summary?.reportStatus.toLowerCase() ===
                  "fail" ? (
                    <div className="fail-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                    >
                      <path
                        d="M7.0007 5.0865L11.9504 0.136719L13.3646 1.55093L8.4149 6.5007L13.3646 11.4504L11.9504 12.8646L7.0007 7.9149L2.05093 12.8646L0.636719 11.4504L5.5865 6.5007L0.636719 1.55093L2.05093 0.136719L7.0007 5.0865Z"
                        fill="#fff"
                      ></path>
                    </svg>
                    </div>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                    >
                      <rect
                        width="64"
                        height="64"
                        rx="32"
                        fill={
                          reportData?.summary?.reportStatus === "Pass"
                            ? "rgba(255, 165, 0, 1)"
                            : reportData?.summary?.reportStatus === "Great"
                            ? "rgba(59, 135, 62, 1)"
                            : reportData?.summary?.reportStatus === "Fail"
                            ? "rgba(178, 34, 34, 1)"
                            : null
                        }
                      />
                      <path
                        d="M29.0926 36.611L42.4634 23.2402L44.5204 25.2973L29.0926 40.725L19.8359 31.4684L21.893 29.4114L29.0926 36.611Z"
                        fill="white"
                      />
                    </svg>
                  )}

                  <h2 className="mt-3">{reportData?.summary?.reportStatus}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="btn-group btn-group-toggle toggle-group mt-3 report-btn-group"
          data-toggle="buttons"
        >
          <label
            className="btn btn-secondary active"
            onClick={() => setActiveTab(ACTIVE_TABS.PASSED)}
          >
            <input type="radio" name="options" id="option1" defaultChecked />
            Passed Rules
          </label>
          <label
            className="btn btn-secondary"
            onClick={() => setActiveTab(ACTIVE_TABS.FAILED)}
          >
            <input type="radio" name="options" id="option2" /> Failed Rules
          </label>
          <label
            className="btn btn-secondary"
            onClick={() => setActiveTab(ACTIVE_TABS.OTHERS)}
          >
            <input type="radio" name="options" id="option3" /> Other Data
          </label>
        </div>

        <div className="w-100 rounded-2 border bg-white mt-3">
          {activeTab === ACTIVE_TABS.PASSED && (
            <PassedRule reportData={reportData} />
          )}
          {activeTab === ACTIVE_TABS.FAILED && (
            <FailedRule reportData={reportData} />
          )}
          {activeTab === ACTIVE_TABS.OTHERS && (
            <OthersRule reportData={reportData} />
          )}
        </div>
      </div>
    </div>
  );
}
