import React from "react";
import { MDBBox, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";

import { useEffect, useState } from "react";
import Loading from "components/ui-components/loading";
import { useDispatch, useSelector } from "react-redux";
import { choseToggle } from "helpers/toggleIfLoading";

import "../ModelViewer/TimelineViewer.scss";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { findBiggestObject } from "helpers/findBiggestObject";
import Pagination from "components/Pagination/Pagination";

const TableViewer = (props) => {
  const {
    projectId,
    token,
    line,
    refresh,
    setItemForCSVDownload,
    searchValue,
    setSearchExtensions,
    selectedSearchExt,
    treeFilesDatas,
    filteredDatas,
    apiLoading,
  } = props;
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const dispatch = useDispatch();

  const projectsRedux = useSelector((s) => s.user.projects);

  const [treeFilesData, setTreeFilesData] = useState(treeFilesDatas);
  const [filteredData, setFilteredData] = useState(filteredDatas);

  useEffect(() => {
    setTreeFilesData(treeFilesDatas);
    setFilteredData(filteredDatas);
  }, [filteredDatas, treeFilesDatas]);

  useEffect(() => {
    (async () => {
      const headersWithHighestkeys = await findBiggestObject(treeFilesData);
      const headers = Object.entries(headersWithHighestkeys).reduce(
        (acc, [key, value]) => {
          if (
            key === "$id" ||
            key === "Connectors" ||
            key === "MaterialIds" ||
            key === "MaterialNames" ||
            key === "MaterialsData" ||
            key === "ConnectedElements" ||
            key === "Connectors" ||
            key === "uniqueId" ||
            key === "UniqueId" ||
            key === "typeId"
          ) {
            return acc;
          }

          if (
            key === "BuiltInParameters" ||
            key === "EquipmentParameters" ||
            key === "FamilyParameters" ||
            key === "GlobalParameters" ||
            key === "ProjectParameters" ||
            key === "SharedParameters"
          ) {
            return acc.concat(Object.keys(value));
          }
          return acc.concat(key);
        },
        []
      );

      console.log(headers, "headers");

      const defaultHeaders = headers
        ?.map((key) => {
          if (
            key === "Location" ||
            // key === "TopCategory" ||
            key === "PhysicalCategory" ||
            key === "Name" ||
            key === "Unit" ||
            key === "Area" ||
            key === "Width" ||
            key === "Diameter" ||
            key === "Perimeter" ||
            // key === "SubCategory" ||
            key === "Type" ||
            key === "Quantity" ||
            key === "Count" ||
            key === "AreaUnit" ||
            key === "Volume" ||
            key === "Length" ||
            key === "Depth" ||
            key === "CircuitNumber" ||
            key === "Voltage" ||
            key === "WireSize" ||
            key === "WireType" ||
            key === "Rating" ||
            key === "NumberOfHotConductors" ||
            key === "NumberOfGroundConductors" ||
            key === "CircuitLength" ||
            key === "ConductorSize" ||
            key === "ConductorLength" ||
            key === "PanelName" ||
            key === "NumberofPoles" ||
            key === "ApparentLoad" ||
            key === "LoadName" ||
            key === "ConductorType" ||
            key === "GroundConductorSize" ||
            key === "ConduitSize" ||
            key === "id"
          ) {
            return key;
          }
          return null;
        })
        .filter(function (el) {
          return el != null;
        });

      setHeadersToShow(defaultHeaders);
      setAllHeaders(headers);
    })();
  }, [treeFilesData]);

  const [headersToShow, setHeadersToShow] = useState([]);
  const [allHeaders, setAllHeaders] = useState([]);

  const [itemsToDownload, setItemsToDownload] = useState([]);

  const handleCheckBox = (e, key) => {
    const isChecked = e.target.checked;

    const index = headersToShow.indexOf(key);

    if (isChecked && index === -1) {
      setHeadersToShow((prev) => [...prev, key]);
    }

    if (!isChecked && index !== -1) {
      setHeadersToShow((prev) => prev.filter((oldKey) => oldKey !== key));
    }
  };

  const handleCheckBoxTable = (e, item) => {
    const isChecked = e.target.checked;

    const index = itemsToDownload.indexOf(item);

    if (isChecked && index === -1) {
      setItemsToDownload((prev) => [...prev, item]);
    }

    if (!isChecked && index !== -1) {
      setItemsToDownload((prev) => prev.filter((oldItem) => oldItem !== item));
    }
  };

  const handleSelectALL = (e, Allitem) => {
    const isChecked = e.target.checked;

    const arr = Allitem?.map((item) => {
      const index = itemsToDownload.indexOf(item);
      if (isChecked && index === -1) {
        setItemsToDownload((prev) => [...prev, item]);
      }

      if (!isChecked && index !== -1) {
        setItemsToDownload((prev) =>
          prev.filter((oldItem) => oldItem !== item)
        );
      }
    });
  };

  useEffect(() => {
    setItemForCSVDownload(itemsToDownload);
  }, [itemsToDownload]);

  const toggle = () => {
    props.toggle();
  };

  useEffect(() => {
    setSearchExtensions(
      allHeaders?.map((i) => {
        return {
          value: i,
          label: i,
        };
      })
    );
  }, [allHeaders]);

  const [totalCount, setTotalCount] = useState();
  const [totalPage, setTotalPage] = useState();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);

  const handleSearch = (keyword) => {
    if (keyword?.length === 0) {
      const currentItems = treeFilesData?.slice(
        (page - 1) * limit,
        page * limit
      );
      setFilteredData(currentItems);
      setTotalPage(Math.ceil(treeFilesData?.length / limit));
      setTotalCount(treeFilesData?.length)
    } else {
      const filteredResult = treeFilesData?.filter((item) => {
        return String(item?.[selectedSearchExt?.value])
          ?.toLowerCase()
          ?.includes(keyword?.toLowerCase());
      });
      setTotalPage(Math.ceil(filteredResult?.length / limit));
      const currentItems = filteredResult?.slice(
        (page - 1) * limit,
        page * limit
      );
      setFilteredData(currentItems);
      setTotalCount(filteredResult?.length)
    }
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, selectedSearchExt, limit, page]);

  useEffect(() => {
    setTotalCount(treeFilesData?.length);
    setTotalPage(Math.ceil(treeFilesData?.length / limit));
    const currentItems = treeFilesData?.slice(0, limit);
    setFilteredData(currentItems);
  }, [treeFilesData]);

  return (
    <div className="">
      {apiLoading ? (
        <MDBBox className="h-100 bg-white pt-5">
          <Loading color="black" text="Loading components..." />
        </MDBBox>
      ) : (
        <>
          <div className="assestmgmt-table-div warehousetable-height">
            <table className="bid-table bid-table-small border-top-none sticky-table-header">
              <thead>
                <tr>
                  <th>
                    <input
                      // checked={itemsToDownload?.includes(item)}
                      onChange={(e) => handleSelectALL(e, treeFilesDatas)}
                      type="checkbox"
                      className="squared-checkbox mr-3"
                    />
                  </th>
                  {headersToShow?.map((key, index) => {
                    return (
                      <th key={index}>
                        {key === "PhysicalCategory" ? "Category" : key}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <input
                        checked={itemsToDownload?.includes(item)}
                        onChange={(e) => handleCheckBoxTable(e, item)}
                        type="checkbox"
                        className="squared-checkbox mr-3"
                      />
                    </td>
                    {headersToShow?.map((key)=> {                      
                    // {Object.entries(item).map((keyAndValue) => {
                      // const [key, value] = keyAndValue;
                      if (
                        key === "$id" ||
                        key === "Connectors" ||
                        key === "MaterialIds" ||
                        key === "MaterialNames" ||
                        key === "MaterialsData" ||
                        key === "ConnectedElements" ||
                        key === "Connectors" ||
                        key === "uniqueId" ||
                        key === "UniqueId" ||
                        key === "typeId" ||
                        !headersToShow?.includes(key)
                      ) {
                        return null;
                      }
                      if (
                        key === "BuiltInParameters" ||
                        key === "EquipmentParameters" ||
                        key === "FamilyParameters" ||
                        key === "GlobalParameters" ||
                        key === "ProjectParameters" ||
                        key === "SharedParameters"
                      ) {
                        return Object.entries(item)?.map(
                          (innerkeyandValue) => {
                            const [key1, value1] = innerkeyandValue;
                            return <td key={key1}>{value1}</td>;
                          }
                        );
                      }
                      return (
                        <td key={Math.random()}>
                          {typeof item[key] === "number" ? item[key].toFixed(2) : key == 'error' ? item[key].status : item[key]?.toString()}
                          {/* {item[key]} */}
                        </td>
                      );
                    // })}
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="asset-pagination-div mx-4">
            <Pagination
              totalRecords={totalCount}
              totalPage={totalPage}
              recordsPerPage={limit}
              page={page + 1}
              onPageChange={(pageNo) => setPage(pageNo)}
              limit={limit}
              onLimitChange={(limitNo) => {
                setLimit(Number(limitNo));
              }}
            />
          </div>

          {/* header model */}
          <MDBModal
            isOpen={props.openHeaderModel}
            toggle={choseToggle(apiLoading, toggle)}
            centered
            className="create-modal-full-size"
          >
            <MDBModalHeader toggle={choseToggle(apiLoading, toggle)}>
              Edit Headers
            </MDBModalHeader>
            <MDBModalBody className="pt-1">
              <div className="assestmgmt-checkbox-div">
                {allHeaders.map((key) => {
                  return (
                    <div key={Math.random()}>
                      {" "}
                      <input
                        checked={headersToShow?.includes(key)}
                        onChange={(e) => handleCheckBox(e, key)}
                        type="checkbox"
                        className="squared-checkbox mr-3"
                      />
                      {key === "PhysicalCategory" ? "Category" : key}
                    </div>
                  );
                })}
              </div>
            </MDBModalBody>
          </MDBModal>
        </>
      )}
    </div>
  );
};

export default TableViewer;
