import { get } from "../index";

export const getUserPermission = (id) => {
  return get(`/permissions/${id}`);
};

export const getPermissionOfUser = (id) => {
  return get(`/permissions/users/${id}`);
};

export const getCompanyRoles = (company) => {
  return get(`/roles/company/${company}`);
};

export const getCompanyPermissionByID = (data) => {
  return get(`/permissions/${data?.userId}/company/${data?.companyId}`);
};

export const getRole = (id) => {
  return get(`/roles/${id}`);
};

export const getAdminPermission = (id) => {
  return get(`/permissions/admin/${id}`);
};

export const getUserCompanyPermission = (id, company) => {
  return get(`/permissions/${id}/company/${company}`);
};
