import React, { useEffect, useState } from "react";
import { setSidebarStatus } from "actions/user";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Select from "react-select";
import { IFC_Url, url } from "api";

import "../ModelViewer/TimelineViewer.scss";

import Arrow from "assets/images/leftArr.png";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import Viewer3d from "./Viewer3d";
import TableViewer from "./TableViewer";
import { Maybe } from "helpers/maybeFunctor";
import { findBiggestObject } from "helpers/findBiggestObject";
import { useHttp } from "hooks/useHttp";
import { getCompressedJsonFile } from "api/files/getJsonFile";
import {
  generateMeshInfo,
  generateRawMeshes,
  generateRawTree,
} from "helpers/three";
import { eventTrack } from "helpers/ga4Helper";

const AssetViewer = (props) => {
  const { projectId, token, line } = props;
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const projectsRedux = useSelector((s) => s.user.projects);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const dispatch = useDispatch();
  const history = useHistory();

  const TABS = {
    MODEL_VIEW: "MODEL_VIEW",
    TABLE_VIEW: "TABLE_VIEW",
  };
  const [activeTab, setActiveTab] = useState(TABS.TABLE_VIEW);
  const [refresh, setRefresh] = useState(false);
  const [openHeaderModel, setOpenHeaderModel] = useState(false);
  const [itemForCSVDownload, setItemForCSVDownload] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchExtensions, setSearchExtensions] = useState([]);
  const [selectedSearchExt, setSelectedSearchExt] = useState();

  const project = Maybe.of(projectsRedux).map((projects) => {
    return projects.filter((project) => project._id == projectId)[0];
  }).value;

  // project files variables
  let filesMeshes = [];
  let letTreeFilesMeshes = [];

  let additionalMeshes = [];
  let electricalMeshes = [];
  let mechanicalMeshes = [];

  let [modelNumber, setModelNumber] = useState(0);
  let [modelsNames, setModelsNames] = useState([]);
  let [jsons, setJsons] = useState([]);
  let [models, setModels] = useState([]);

  let [additionalParams, setAdditionalParams] = useState([]);
  let [electricalCircuits, setElectricalCircuits] = useState([]);
  let [mechanicalSystems, setMechanicalSystems] = useState([]);

  const [treeFilesData, setTreeFilesData] = useState([{}]);
  const [filteredData, setFilteredData] = useState([{}]);

  const [filesMeshesArray, setFilesMeshes] = useState(null);
  const [additionalMeshesArray, setAdditionalMeshes] = useState(null);
  const [electricalMeshesArray, setElectricalMeshes] = useState(null);
  const [mechanicalMeshesArray, setMechanicalMeshes] = useState(null);

  const [treeFilesData3d, setTreeFilesData3d] = useState([]);
  const [filesOpenNodes, setFilesOpenNodes] = useState([]);
  // const [additionalData, setAdditionalData] = useState([]);

  const { loading: apiLoading, request: initializeAPI } = useHttp({
    requestCallback: async (d) => {
      const obj1 = project?.zipInfo?.pathsmapping?.forEach((fname) => {
        if (fname.endsWith(".json") || fname.endsWith(".json.gz")) {
          setModelNumber(modelNumber);
          modelNumber = modelNumber + 1;
          var fileName = fname.split(".")[0];
          modelsNames.push(fileName);
          setModelsNames(modelsNames);
        }
      });

      var baseURL = url;
      if (project?.isIfcProject) {
        baseURL = project.isIfcProject ? IFC_Url : url;
      }
      const obj2 = project?.zipInfo?.paths?.forEach((path, i) => {
        const filename = project?.zipInfo?.pathsmapping[i];
        if (filename?.includes("_AdditionalParam")) {
          additionalParams.push(`${path}`);
        }
        if (filename?.includes("_ElectricalCircuits")) {
          electricalCircuits.push(`${path}`);
        }
        if (filename?.includes("_MechanicalSystems")) {
          mechanicalSystems.push(`${path}`);
        }
        if (path.endsWith(".glb") || path.endsWith(".glb.gz")) {
          models.push(`${baseURL}/${path}`);
        } else if (path.endsWith(".json") || path.endsWith(".json.gz")) {
          jsons.push(`${path}`);
        }
      });

      setModels(models);
      setJsons(jsons);
      for (let i = 0; i < modelNumber; i++) {
        var mNumber = i;
        var mpath = jsons[i];
        var mAdditionalPath = additionalParams[i];
        var mElectricalCircuitsPath = electricalCircuits[i];
        var mMechanicalSystemsPath = mechanicalSystems[i];

        // download raw json
        await getCompressedJsonFile(
          mpath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            filesMeshes[mNumber] = generateRawMeshes(res); //generateMeshes(res);
            letTreeFilesMeshes[mNumber] = generateRawTree(res);
          })
          .catch((err) => {});

        // download additional json
        await getCompressedJsonFile(
          mAdditionalPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            additionalMeshes[mNumber] = res;
          })
          .catch((err) => {});

        // download electrical json
        await getCompressedJsonFile(
          mElectricalCircuitsPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            electricalMeshes[mNumber] = res;
          })
          .catch((err) => {});

        // download mechanical json
        await getCompressedJsonFile(
          mMechanicalSystemsPath,
          project?.isIfcProject ? project?.isIfcProject : false
        )
          .then((res) => {
            mechanicalMeshes[mNumber] = res;
          })
          .catch((err) => {});
      }
    },
    onLoad: async (res) => {
      const meshInfo = generateMeshInfo(letTreeFilesMeshes);
      //----------------------------- merge meshinfo and additional
      const flattenedArr = additionalMeshes.flat();
      const idMap = {};
      flattenedArr.forEach((obj) => {
        idMap[obj.UniqueId] = obj;
      });
      const mergeMeshInfoandAdditional = meshInfo.map((obj) => {
        const matchingObj = idMap[obj?.UniqueId];
        if (matchingObj) {
          return { ...obj, ...matchingObj };
        } else {
          return obj;
        }
      });

      //------------------------- merge meshinfoAdditonal and electrical
      const flattenedArr1 = electricalMeshes.flat();
      const idMap1 = {};
      flattenedArr1.forEach((obj) => {
        idMap1[obj.uniqueId] = obj;
      });
      const mergedElectrical = mergeMeshInfoandAdditional.map((obj) => {
        const matchingObj = idMap1[obj?.EquipmentParameters?.ElectricalSystem];
        if (matchingObj) {
          return { ...obj, ...matchingObj };
        } else {
          return obj;
        }
      });

      //------------------------- merge meshinfoAdditonalElectrical and mechanical
      const flattenedArr2 = mechanicalMeshes.flat();
      const idMap2 = {};
      flattenedArr2.forEach((obj) => {
        idMap2[obj.uniqueId] = obj;
      });
      const mergedMechanical = mergedElectrical.map((obj) => {
        const matchingObj = idMap2[obj?.EquipmentParameters?.MechanicalSystem];
        if (matchingObj) {
          return { ...obj, ...matchingObj };
        } else {
          return obj;
        }
      });
      setTreeFilesData(mergedMechanical);
      setFilteredData(mergedMechanical);

      //
      setFilesMeshes([...filesMeshes?.filter((item) => item)]);
      setAdditionalMeshes(...additionalMeshes);
      setElectricalMeshes(...electricalMeshes);
      setMechanicalMeshes(...mechanicalMeshes);
      setTreeFilesData3d([...letTreeFilesMeshes]?.filter((item) => item));
      setFilesOpenNodes([
        ...filesOpenNodes,
        ...[...letTreeFilesMeshes]?.filter((item) => item).map((tv) => tv[0]?.key),
      ]);
    },
    onError: (e) => {
      console.error(e);
    },
  });

  useEffect(() => {
    initializeAPI();
  }, [project]);

  const handleDownloadCSVFile = async () => {
    const csvContent = await convertToCSV(itemForCSVDownload);

    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "file");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  const convertToCSV = async (data) => {
    const largestObjectTogetAllHeaders = await findBiggestObject(data);
    const headers = Object.keys(largestObjectTogetAllHeaders);
    const csv = [
      headers.join(","),
      ...data.map((obj) =>
        headers
          .map((header) => {
            const value = obj[header];
            return typeof value === "string" && value.includes(",")
              ? `"${value}"`
              : value;
          })
          .join(",")
      ),
    ].join("\n");

    return csv;
  };

  return (
    <div className="personal-area">
      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <img src={Arrow} className="left-arrow-img" />
            </div>
            <Link
              to={"/assets-management"}
              className="my-heading f-14 ml-3 mr-3 headerLink"
            >
              Data Warehouse
            </Link>
            <div className="cursor-pointer">
              <img src={Arrow} className="left-arrow-img" />
            </div>

            <p className="my-heading f-14 ml-3 mr-3">{project?.title}</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <Select
              name="filters"
              onChange={(e) => setSelectedSearchExt(e)}
              options={searchExtensions}
              isDisabled={searchExtensions.length === 0}
              className="header-select"
              classNamePrefix="header-select"
              styles={{width: '150px'}}
              isClearable
              // styles={{ menuPortal: (base) => ({ ...base, width: '150px' }) }}
            />
            <input
              type="text"
              className="search-bar"
              value={searchValue}
              onChange={(e) => setSearchValue(String(e.target.value))}
              placeholder="Search"
              disabled={!selectedSearchExt}
            />
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                <>
                  <button
                    onClick={() => {
                      if (
                        FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW)
                      ) {
                        history.push(`/admin/projects-settings`);
                      } else if (
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.MILESTONE_VIEW
                        )
                      ) {
                        history.push(`/admin/milestones`);
                      } else {
                        history.push(`/admin/user-permissions`);
                      }
                      eventTrack(
                        "Navigate-Admin-Section-Action",
                        "Navigate-Admin-Section",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    className="bg-bid px-3 mr-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                    >
                      <path
                        d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                        fill="white"
                      />
                    </svg>
                    Admin
                  </button>
                  <div className="vr mx-3"></div>
                </>
              )}
              <div className="d-flex view-switch-container pr-0">
                <div
                  onClick={() => {setActiveTab(TABS.MODEL_VIEW)
                    eventTrack(
                      "View-Datawarehouse-3dModel-Action",
                      "View-Datawarehouse-3dModel",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }}
                  className={activeTab === TABS.MODEL_VIEW ? "active" : null}
                  title="3D view"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.0"
                    width="32.000000pt"
                    height="32.000000pt"
                    viewBox="0 0 32.000000 32.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                      fill="#455E6C"
                      stroke="none"
                    >
                      <path d="M93 267 l-53 -31 0 -76 0 -76 56 -32 c31 -18 61 -32 66 -32 14 0 111 60 120 74 10 14 10 118 0 132 -11 17 -107 74 -123 73 -8 0 -38 -15 -66 -32z m115 -13 l44 -26 -51 -31 -51 -32 0 -58 c0 -32 -3 -57 -7 -55 -85 41 -83 38 -83 100 l0 58 32 -20 c17 -10 36 -16 41 -13 5 3 -7 16 -27 28 l-37 22 43 26 c24 15 45 26 47 27 2 0 24 -12 49 -26z m62 -98 c0 -53 -1 -54 -44 -80 -25 -14 -47 -26 -50 -26 -3 0 -6 24 -6 53 0 50 2 53 43 79 23 15 45 28 50 28 4 0 7 -24 7 -54z" />
                    </g>
                  </svg>
                </div>

                <div
                  onClick={() => {setActiveTab(TABS.TABLE_VIEW)
                    eventTrack(
                      "View-Datawarehouse-ListView-Action",
                      "View-Datawarehouse-ListView",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }}
                  className={activeTab === TABS.TABLE_VIEW ? "active" : null}
                  title="Table view"
                >
                  <svg
                    width="21"
                    height="16"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.97384 14.0002H18.9738V11.3252H7.97384V14.0002ZM2.97384 4.67518H5.97384V2.0002H2.97384V4.67518ZM2.97384 9.35017H5.97384V6.67518H2.97384V9.35017ZM2.97384 14.0002H5.97384V11.3252H2.97384V14.0002ZM7.97384 9.35017H18.9738V6.67518H7.97384V9.35017ZM7.97384 4.67518H18.9738V2.0002H7.97384V4.67518ZM2.97384 16.0002C2.71071 16.0041 2.44957 15.954 2.20655 15.853C1.96352 15.7521 1.74377 15.6024 1.56084 15.4132C1.37165 15.2303 1.22196 15.0105 1.12101 14.7675C1.02005 14.5244 0.969962 14.2633 0.973842 14.0002V2.0002C0.970228 1.73716 1.02053 1.4762 1.12166 1.23335C1.22279 0.990503 1.3726 0.770934 1.56184 0.588208C1.74456 0.398965 1.96412 0.249151 2.20697 0.148023C2.44981 0.0468939 2.71081 -0.00341862 2.97384 0.000195533H18.9738C19.237 -0.0035558 19.4982 0.0467067 19.7413 0.14784C19.9843 0.248972 20.204 0.398841 20.3868 0.588208C20.5759 0.771019 20.7255 0.990629 20.8265 1.23347C20.9274 1.47631 20.9776 1.73723 20.9738 2.0002V14.0002C20.9775 14.2632 20.9272 14.5242 20.826 14.767C20.7249 15.0099 20.5751 15.2295 20.3858 15.4122C20.2031 15.6014 19.9836 15.7512 19.7407 15.8524C19.4979 15.9535 19.2369 16.0038 18.9738 16.0002H2.97384Z"
                      fill="#455E6C"
                    />
                  </svg>
                </div>
              </div>
              {activeTab === TABS.TABLE_VIEW && (
                <>
                  <div className="vr mx-2"></div>
                  <div
                    className="head-filter-icon"
                    onClick={() => setOpenHeaderModel(true)}
                  >
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.97635 16.0002C7.8442 16.0036 7.71277 15.9798 7.59015 15.9305C7.46752 15.8811 7.3563 15.8072 7.26335 15.7132C7.16932 15.6203 7.09534 15.509 7.04597 15.3864C6.99661 15.2638 6.97291 15.1323 6.97635 15.0002V9.00018L1.17635 1.60015C1.05613 1.45573 0.981356 1.27901 0.961426 1.09216C0.941495 0.905308 0.977304 0.716699 1.06435 0.550165C1.14163 0.376041 1.27058 0.229907 1.43372 0.131525C1.59685 0.0331435 1.78628 -0.0127164 1.97635 0.000177374H15.9763C16.1664 -0.0127164 16.3559 0.0331435 16.519 0.131525C16.6821 0.229907 16.8111 0.376041 16.8884 0.550165C16.9754 0.716699 17.0112 0.905308 16.9913 1.09216C16.9713 1.27901 16.8966 1.45573 16.7764 1.60015L10.9763 9.00018V15.0002C10.9797 15.1324 10.9558 15.2639 10.9063 15.3865C10.8567 15.5092 10.7826 15.6204 10.6884 15.7132C10.5955 15.8071 10.4845 15.8809 10.362 15.9303C10.2396 15.9796 10.1083 16.0034 9.97635 16.0002H7.97635ZM8.97635 8.30017L13.9263 2.00018H4.02635L8.97635 8.30017Z"
                        fill="#455E6C"
                      />
                    </svg>
                  </div>
                  <button
                    className={`${"head-filter-icon"} ${
                      itemForCSVDownload.length === 0 ? "disable-btn" : ""
                    }`}
                    onClick={() => {
                      itemForCSVDownload.length > 0 && handleDownloadCSVFile();
                      eventTrack(
                        "Download-Datawarehouse-CSV-Action",
                        "Download-Datawarehouse-CSV",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    title="CSV Download"
                  >
                    <i className="fa fa-download text-bid download-btn-size"></i>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        {activeTab === TABS.MODEL_VIEW ? (
          <Viewer3d
            projectId={projectId}
            token={token}
            filesMeshesArray1={filesMeshesArray}
            additionalMeshesArray1={additionalMeshesArray}
            electricalMeshesArray1={electricalMeshesArray}
            mechanicalMeshesArray1={mechanicalMeshesArray}
            treeFilesData3d={treeFilesData3d}
            filesOpenNodes1={filesOpenNodes}
            models1={models}
            filesMeshes1={filesMeshesArray}
            treeFilesMeshes1={treeFilesData3d}
          />
        ) : (
          <TableViewer
            projectId={projectId}
            token={token}
            refresh={refresh}
            openHeaderModel={openHeaderModel}
            toggle={() => {
              setOpenHeaderModel(false);
            }}
            setItemForCSVDownload={setItemForCSVDownload}
            searchValue={searchValue}
            setSearchExtensions={setSearchExtensions}
            selectedSearchExt={selectedSearchExt}
            treeFilesDatas={treeFilesData}
            filteredDatas={filteredData}
            apiLoading={apiLoading}
          />
        )}
      </div>
    </div>
  );
};

export default AssetViewer;
