import React, { useState } from "react";
import PersonalInfo from "./PersonalInfo";
import Password from "./Password";
import API from "./API";
import { uploadFileToServer } from "components/uploadFile/uploadFile";
import { setSidebarStatus } from "actions/user";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { eventTrack } from "helpers/ga4Helper";
import { CurlyBraces } from "assets/svg-icons/CurlyBraces";

export default function Profile() {
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const { companyAdmin, admin, company, name, lastName, email } = userInfoRedux;
  const history = useHistory();

  const TABS = {
    PERSONAL_INFO: "PERSONAL_INFO",
    PASSWORD: "PASSWORD",
    API: "API",
  };
  const [activeTab, setActiveTab] = useState(TABS.PERSONAL_INFO);

  return (
    <div className="personal-area">
      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
            <p className="my-heading f-14 ml-3 mr-3">Profile</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>
            <p className="my-heading f-14 ml-3 mr-3">
              {activeTab === TABS.PERSONAL_INFO
                ? "Personal Information"
                : activeTab === TABS.PASSWORD
                ? "Password"
                : activeTab === TABS.API
                ? "API"
                : ""}
            </p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              {admin || companyAdmin ? (
                <button
                  onClick={() => {
                    history.push(`/admin/projects-settings`);
                    {
                      eventTrack(
                        "Navigate-Admin-Section-Action",
                        "Navigate-Admin-Section",
                        name,
                        "+1",
                        false,
                        "+1"
                      );
                    }
                  }}
                  className="bg-bid px-3 mr-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                    fill="none"
                  >
                    <path
                      d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                      fill="white"
                    />
                  </svg>
                  Admin
                </button>
              ) : null}

              {/* <div className="vr mx-3"></div> */}

              {/* <button
              onClick={() => getHealthCheckAPI()}
              className="px-3 bg-white text-bid shadow-hover"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
              >
                <path
                  d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                  fill="#3A5765"
                />
                <path
                  d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                  fill="black"
                  fillOpacity="0.2"
                />
              </svg>
              Refresh
            </button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="content-bg p-2">
        <div className="container d-flex mt-1">
          <div className="profile-sidebar">
            <p className="font-weight-bold">Settings</p>
            <button
              onClick={() => setActiveTab(TABS.PERSONAL_INFO)}
              className={activeTab === TABS.PERSONAL_INFO && "ActiveTab"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="19"
                viewBox="0 0 22 19"
                fill="none"
              >
                <path
                  d="M15 1.46776C16.4817 2.20411 17.5 3.73314 17.5 5.5C17.5 7.26686 16.4817 8.79589 15 9.53224M17 14.7664C18.5115 15.4503 19.8725 16.565 21 18M1 18C2.94649 15.5226 5.58918 14 8.5 14C11.4108 14 14.0535 15.5226 16 18M13 5.5C13 7.98528 10.9853 10 8.5 10C6.01472 10 4 7.98528 4 5.5C4 3.01472 6.01472 1 8.5 1C10.9853 1 13 3.01472 13 5.5Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Personal Info
            </button>
            <button
              onClick={() => setActiveTab(TABS.PASSWORD)}
              className={activeTab === TABS.PASSWORD && "ActiveTab"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="21"
                viewBox="0 0 18 21"
                fill="none"
              >
                <path
                  d="M15 7H17C17.5523 7 18 7.44772 18 8V20C18 20.5523 17.5523 21 17 21H1C0.44772 21 0 20.5523 0 20V8C0 7.44772 0.44772 7 1 7H3V6C3 2.68629 5.68629 0 9 0C12.3137 0 15 2.68629 15 6V7ZM13 7V6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6V7H13ZM8 13V15H10V13H8ZM4 13V15H6V13H4ZM12 13V15H14V13H12Z"
                  fill="black"
                />
              </svg>
              Password
            </button>
            <button
              onClick={() => setActiveTab(TABS.API)}
              className={activeTab === TABS.API && "ActiveTab"}
            >
              <CurlyBraces />
              API
            </button>
          </div>
          <div className="profile-content">
            {activeTab === TABS.PERSONAL_INFO && <PersonalInfo />}
            {activeTab === TABS.PASSWORD && <Password />}
            {activeTab === TABS.API && <API />}
          </div>
        </div>
      </div>
    </div>
  );
}
