
import React, { useEffect } from "react";
import ReactGA from "react-ga4";




const GA4 = (props) => {

    const {
       
        userId,
        category,
         action,
          label,
          value,
          nonInteraction,
      } = props;
    
      const eventTrack =  useEffect(()=> {
        //console.log("GA event:", category, ":", action, ":", label, ":", value, ":", nonInteraction, ":", transport);
   

        ReactGA.event({
            category: category,
            action: action,
            label: label, // optional
            value: value, // optional, must be a number
            nonInteraction: nonInteraction, // optional, true/false
            transport: 'xhr', // optional, beacon/xhr/image
          });

          

    },[])


  let googleAnalytics4 =  useEffect(() =>{ReactGA.initialize(
    "G-W482SV8S8S",
    [{
      legacyDimensionMetric: true,
      nonce: '',
      testMode: false,
      gaOptions: {'userId': userId,'debug_mode': false},
      gtagOptions:{ 'user_id': userId,'debug_mode': false}, //'send_page_view': true, 'debug_mode': false,

    },
    {
      trackingId: "UA-223995228-1",
    }
    
     
    ],{ debug: false}
 )
 if (ReactGA.isInitialized) {
  ReactGA.gtag('config', 'G-W482SV8S8S',{ 'debug_mode':false }); //,{ 'debug_mode':true }
  
  ReactGA.gtag('set', {'user_id': userId});
  ReactGA.ga('set', 'userId', userId);
 }
 
 // ReactGA.gtag('config', "G-68N0W43MCH" )
 //gtag('set', {'user_id': 'USER_ID'}); // Set the user ID using signed-in user_id.
  
// { 'debug_mode': true,'send_page_view': true,'user_id': maybeUserID }
}, [eventTrack]);






 useEffect(() =>{  ReactGA.gtag('consent', 'update', {
    'ad_storage': 'granted',
    'analytics_storage': 'granted'
  })

}, [googleAnalytics4]);

  
 
return (<>{googleAnalytics4}</>);
};






export default GA4;








