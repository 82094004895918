import React, { useEffect, useState } from "react";
import { MDBIcon } from "mdbreact";
import "./style.scss";

let numeral = require("numeral");

const RenderItem = ({ i, item, priceView, currencysymb }) => {
  const itemRows = [
    <tr className="item-child" size="sm" key={"row-data-" + i}>
      {/* <td size="sm" style={{width:'30px'}}>   </td>  */}
      <td size="sm">{item.Code}</td>
      <td size="sm">{item.Description}</td>
      {/*}  <td size="sm">{numeral(item.InflationRate).format('0,0.00')}</td>*/}
      {/*}  <td size="sm">{numeral(item.InflationValue).format('0,0.00')}</td>		*/}
      {/*}  <td size="sm">{numeral(item.MaxTotalCost).format('0,0.00')}</td>	*/}
      {/*}  <td size="sm">{numeral(item.MinTotalCos).format('0,0.00')}</td> */}
      {priceView && (
        <td size="sm">
          {numeral(item.TotalCost).format(`${currencysymb}0,0.00`)}
        </td>
      )}
      {/*} <td size="sm">{numeral(item.TotalCostWithoutInflation).format('0,0.00')}</td>	*/}
      {/*} <td size="sm">{item.Amount}</td> */}
    </tr>,
  ];

  return itemRows;
};

const CollapsibleTable = (props) => {
  const { currencysymb } = props;
  const [allItemRows, setAllItemRowss] = useState([]);
  const [isExpanded, setExpand] = useState(props.expanded);
  const [totalCost, setTotalCoast] = useState(0);
  const handleRowClick = () => {
    setExpand(!isExpanded);
  };
  useEffect(() => {
    let allItem = [];
    if (props.data != undefined) {
      let estimationDetails = props.data;
      let _totalCost = 0;

      if (estimationDetails[0].Description != "") {
        var i = 0;
        estimationDetails.forEach((item) => {
          _totalCost += item.TotalCost;

          const perItemRows = (
            <RenderItem
              key={i}
              item={item}
              priceView={props.priceView}
              currencysymb={currencysymb}
            />
          );
          if (!allItem?.includes(perItemRows)) {
            i++;
            allItem.push(perItemRows);
          }
        });
        setAllItemRowss(allItem);
        setTotalCoast(_totalCost);
      }
    }
  }, [props.data]);

  return (
    <table className={props.myClassName + " w-100"}>
      <tbody>
        <tr
          className={`${isExpanded ? "expanded" : ""} expandable`}
          size="sm"
          onClick={handleRowClick}
          key={"row-data-header"}
        >
          <td size="sm" colSpan="100%" className="total-summary">
            &nbsp;
            <MDBIcon
              className="mr-2 collapse-icon-wrap"
              icon={`${isExpanded ? "minus" : "plus"} cursor-pointer`}
              onClick={handleRowClick}
            />
            Total cost summary{" "}
            <span className="float-right">
              {props.priceView
                ? numeral(totalCost).format(`${currencysymb}0,0.00`)
                : ""}
            </span>{" "}
          </td>
        </tr>
        {isExpanded && <>{allItemRows}</>}
      </tbody>
    </table>
  );
};

export default CollapsibleTable;
