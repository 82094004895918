import React from 'react';
import PropTypes from 'prop-types';
import { MDBBox } from 'mdbreact';

const Loading = props => {
  return (
    <MDBBox className="d-flex align-items-center flex-column mt-3">
      <div className={`spinner-border text-${props.color}`} role="status" />
      <MDBBox className={`mt-2 font-size-2 spinner-text text-${props.color}`}>
        {props.text}
      </MDBBox>
    </MDBBox>
  );
};

Loading.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Loading;
