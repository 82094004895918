import React, { useEffect, useState } from "react";

import { publicFetch } from "../util/fetcher";

import Layout from "../components/layout";
import PageTitle from "../components/page-title";
import SearchInput from "../components/search-input";
import TagList from "../components/tag-list";
import TagItem from "../components/tag-list/tag-item";
import { Spinner } from "../components/icons";
import Seo from "../components/seo/Seo";
import Sidebar from "learning/components/layout/sidebar";
import { url } from "api";

function TagsPage({anonymous, userInfoRedux}) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [tags, setTags] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchTerm === null) {
      const fetchUser = async () => {
        const { data } = await publicFetch.get("/tags");
        setTags(data);
      };

      fetchUser();
    } else {
      const delayDebounceFn = setTimeout(async () => {
        setLoading(true);
        const { data } = await publicFetch.get(
          searchTerm ? `/tags/${searchTerm}` : `/tags`
        );
        setTags(data);
        setLoading(false);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  return (
    <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>
      <Seo
        title={`BidLight BIM Revit Plugin Questions & Support | BidLight`}
        description={
          "BidLight BIM Revit Plugin Questions & Support - BidLight Learning"
        }
        siteUrl={`${url}/learning/tags`}
        keywords={
          "BidLight BIM Revit Plugin Questions & Support - BidLight Learning"
        }
      />

      <div className="personal-area min-h-full">
        <div className="container-fluid learning-container-flex pt-3">
          <Sidebar />
          <div className="learning-content">
            <PageTitle title="Tags" borderBottom={false}>
              A tag is a keyword or label that categorizes your question with
              other, similar questions. Using the right tags makes it easier for
              others to find and answer your question.
            </PageTitle>

            <SearchInput
              placeholder="Filter by tag name"
              autoFocus={false}
              isLoading={loading}
              autoComplete="off"
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {!tags && (
              <div className="loading">
                <Spinner />
              </div>
            )}

            {tags && (
              <>
                <TagList>
                  {tags?.map(({ count, _id }) => (
                    <TagItem key={_id} count={count}>
                      {_id}
                    </TagItem>
                  ))}
                </TagList>

                {tags.length == 0 && (
                  <p className="not-found">No tags matched your search.</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TagsPage;
