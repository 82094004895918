import React, { useEffect, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import { useHistory } from "react-router";

import withKey from "components/hoc/withKey";
import Loading from "components/ui-components/loading";

import { createUser } from "api/auth/registration";
import { useHttp } from "hooks/useHttp";
import addNotification from "helpers/notify";

import "../RegistrationUserAdmin/Registration.scss";

const RegistrationUser = (props) => {
  const { email, key, role } = props.licenseData;
  // const { email, key } = {
  //   email: 'test',
  //   key: 'test',
  // };
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [rate, setRate] = useState();
  const [rateValue, setRateValue] = useState("");

  const history = useHistory();

  useEffect(() => {
    return () => {
      setName("");
      setLastName("");
      setUsername("");
      setCountry("");
      setCity("");
      setPassword("");
      setRepeatPassword("");
      setRate("Hourly");
      setRateValue("");
    };
  }, []);

  const rates = [
    {
      label: "Hourly",
      value: "Hourly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Yearly",
      value: "Yearly",
    },
  ];

  const { loading, request: createUserAPI } = useHttp({
    requestCallback: createUser,
    onLoad: (res) => {
      addNotification("Your user was successfully registered", "success");
      history.push("/");
    },
    onError: (err) => {
      addNotification(err.err, "danger");
    },
  });

  const submitRegistration = (e) => {
    e.preventDefault();
    if (password.length < 6) {
      addNotification("Password must have 6 or more chars!", "danger");
    }
    if (password !== repeatPassword) {
      addNotification("Password mismatch", "danger");
    } else {
      const newUser = {
        name,
        lastName,
        password,
        username,
        email,
        country,
        city,
        key,
        role,
        rate,
        rateValue,
      };
      return createUserAPI(newUser);
    }
  };

  let handleRateChange = (e) => {
    var r = JSON.parse(e.target.value);
    setRate(r.value);
  };

  return (
    <>
      <div className="position-fixed registration-image"></div>
      <div className="d-flex align-items-center justify-content-center h-100 w-100 position-absolute">
        <div className="registration_box">
          <p className="reg-header">Registration</p>
          <hr />

          <form onSubmit={submitRegistration} className="reg_form regForm1">
            <p className="reg-subheader my-3">Personal Information</p>
            <div className="form-group">
              <input
                type="text"
                placeholder="Your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                autoComplete="off"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="off"
                required
              />
            </div>
            <MDBRow>
              <MDBCol size="6">
                <div className="form-group">
                  <select onChange={handleRateChange} className="">
                    {rates.map((rate, index) => (
                      <option value={JSON.stringify(rates[index])} key={index}>
                        {rate.label}
                      </option>
                    ))}
                  </select>
                </div>
              </MDBCol>
              <MDBCol size="6">
                <div className="form-group position-relative">
                <span className="currency-prefix font-weight-bold">$</span>
                  <input
                    type="number"
                    value={rateValue}
                    placeholder="Your rate"
                    autoComplete="off"
                    onChange={(e) => setRateValue(e.target.value)}
                    required
                    className="pl-4"
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="6">
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Your email"
                    autoComplete="off"
                    value={email}
                    //  onChange={() => {}}
                    disabled
                    required
                  />
                </div>
              </MDBCol>
              <MDBCol size="6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Your role"
                    autoComplete="off"
                    value={role}
                    //  onChange={() => {}}
                    disabled
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol size="6">
                <div className="form-group">
                  <input
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
              <MDBCol size="6">
                <div className="form-group">
                  <input
                    type="password"
                    placeholder="Repeat password"
                    autoComplete="new-password"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Your city"
                    autoComplete="off"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
              <MDBCol size="6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Your country"
                    autoComplete="off"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <div className="form-group mb-0">
              <input type="submit" value="Register" className="reg-btn mt-5" />
            </div>
            {loading ? <Loading color="white" text="Registering..." /> : null}
          </form>
        </div>
      </div>
    </>
  );
};

export default withKey(RegistrationUser);

// export default RegistrationUser;
