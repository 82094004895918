import React, { useEffect, useRef, useState } from "react";
import { setSidebarStatus } from "actions/user";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { useHttp } from "hooks/useHttp";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Arrow from "assets/images/leftArr.png";
import SortImg from "assets/images/sort.png";
import Loader from "components/Loader";
import {
  sortByDate,
  FROM_NEWER_TO_OLDER,
  FROM_OLDER_TO_NEWER,
} from "helpers/sortByDate";
import Pagination from "components/Pagination/Pagination";
import Select from "react-select";
import addNotification from "helpers/notify";
import { getActivityLog } from "api/activityLog/getActivityLog";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { eventTrack } from "helpers/ga4Helper";

export default function ActivityLog() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const [activityLog, setActivityLog] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(0);
  const [columnNameOptions, setColumnNameOptions] = useState();
  const [columnNameSelectValue, setColumnNameSelectValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [sortedToNewer, setSortedToNewer] = useState(false);

  const data = useRef({ index: 0, limit: 10 });

  const coversheetHeaders = [
    { header: "Package Name", value: "packageName" },
    { header: "Task", value: "task" },
    { header: "Date", value: "date" },
  ];

  const { loading: apiLoading, request: getActivityLogAPI } = useHttp({
    requestCallback: (data) => getActivityLog(data?.current),
    onLoad: (res) => {
      console.log(res);
      setActivityLog(res?.logs);
      setTotalRecords(res?.totalCount);
      setTotalPage(Math.ceil(res?.totalCount / data?.current?.limit));
    },
    onError: (res) => {
      console.log("err", res);
      addNotification("Something went wrong", "danger");
    },
  });

  useEffect(() => {
    data.current = {
      ...data.current,
      index: page,
      column: columnNameSelectValue?.value,
      search: searchValue,
      sort: "updatedAt",
      sort_dir: -1,
    };

    console.log(data);
    getActivityLogAPI(data);
  }, [page]);

  useEffect(() => {
    setColumnNameOptions(
      coversheetHeaders.map((value) => {
        return { value: value.value, label: value.header };
      })
    );
  }, []);

  const onDropdownChange = (e) => {
    if (e.value == columnNameSelectValue.value)
      setColumnNameSelectValue({ label: "", value: "" });
    else setColumnNameSelectValue(e);
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onClearFunc = () => {
    setSearchValue("");
    data.current = { ...data.current, index: 0, search: null, column: null };
    getActivityLogAPI(data);
  };

  const SearchFunction = () => {
    setActivityLog([]);
    data.current = {
      ...data.current,
      index: 0,
      column: columnNameSelectValue.value,
      search: searchValue,
    };
    getActivityLogAPI(data);
    setTotalPage(0);
    setPage(0);
  };

  const sort = () => {
    if (sortedToNewer) {
      setActivityLog(sortByDate(activityLog, FROM_OLDER_TO_NEWER));
    } else {
      setActivityLog(sortByDate(activityLog, FROM_NEWER_TO_OLDER));
    }
  };

  return (
    <>
      {apiLoading && <Loader />}
      <div className="personal-area">
        <div className="inner-header-position">
          <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center">
              <div
                onClick={() => dispatch(setSidebarStatus(true))}
                className="cursor-pointer"
              >
                <img src={Arrow} className="left-arrow-img" />
              </div>
              <p className="my-heading f-14 ml-3 mr-3">Activity Log</p>
            </div>
            <div
              className="projActionButtons d-flex flex-wrap"
              style={{ maxWidth: "100vw" }}
            >
              <Select
                onChange={(e) => onDropdownChange(e)}
                options={columnNameOptions}
                placeholder="Select Category"
                value={columnNameSelectValue}
                selected={columnNameSelectValue}
                className="header-select"
                classNamePrefix="header-select"
              />
              <div className="input-with-cancelBtn">
                <input
                  type="text"
                  className="search-bar"
                  value={searchValue}
                  onChange={(e) => onSearchChange(e)}
                  placeholder="Search"
                  disabled={!columnNameSelectValue}
                />
                {searchValue?.length > 0 && (
                  <button onClick={() => onClearFunc()}>
                    <i className="fa fa-times" />
                  </button>
                )}
              </div>

              <button
                disabled={!searchValue?.length > 0}
                onClick={() => SearchFunction()}
                className="global-btn bg-bid text-white mr-0"
              >
                <i className="fa fa-search mr-2" />
                Search
              </button>
              <div className="vr mx-3"></div>
              <div className="btn-tool-group">
                {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                  <>
                    <button
                      onClick={() => {
                        if (
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.PROJECT_VIEW
                          )
                        ) {
                          history.push(`/admin/projects-settings`);
                        } else if (
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_VIEW
                          )
                        ) {
                          history.push(`/admin/milestones`);
                        } else {
                          history.push(`/admin/user-permissions`);
                        }
                      }}
                      className="bg-bid px-3 mr-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="19"
                        viewBox="0 0 14 19"
                        fill="none"
                      >
                        <path
                          d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                          fill="white"
                        />
                      </svg>
                      Admin
                    </button>
                    <div className="vr mx-3"></div>
                  </>
                )}

                <button
                  onClick={() => {
                    setSortedToNewer(!sortedToNewer);
                    sort();
                    eventTrack(
                      "Sort-ActivityLog-Action",
                      "Sort-ActivityLog",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }}
                  className="px-3 bg-white text-bid shadow-hover d-flex align-items-center leading-1"
                >
                  <img
                    src={SortImg}
                    className={`${"w-15 mr-2"} ${
                      sortedToNewer ? "upsideDown-svg" : ""
                    } `}
                  />
                  Sort
                </button>

                <button
                  disabled={apiLoading}
                  onClick={() => {
                    onClearFunc();
                  }}
                  className="px-3 bg-white text-bid shadow-hover"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                  >
                    <path
                      d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                      fill="#3A5765"
                    />
                    <path
                      d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                      fill="black"
                      fillOpacity="0.2"
                    />
                  </svg>
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="assestmgmt-table-div mt-3">
          <table className="bid-table bid-table-small border-top-none sticky-table-header">
            <thead>
              <tr>
                <th>S.No</th>
                {coversheetHeaders.map((value, key) => {
                  return <th key={key}>{value.header}</th>;
                })}
                <th></th>
              </tr>
            </thead>
            {!apiLoading && activityLog?.length !== 0 ? (
              <tbody className="no-wrap">
                {activityLog
                  ?.slice(0, data.current?.limit)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.packageName}</td>
                        <td>{item?.task}</td>
                        <td>
                          {" "}
                          {formatDistanceToNowStrict(new Date(item?.date), {
                            addSuffix: true,
                          })}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>No record Found</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>

        <div className="asset-pagination-div mx-auto">
          <Pagination
            totalRecords={totalRecords}
            totalPage={totalPage}
            page={page + 1}
            onPageChange={(pageNo) => setPage(pageNo - 1)}
            onLimitChange={(count) => {
              data.current = { ...data.current, limit: count };
              if (page === 0) {
                getActivityLogAPI(data);
              } else {
                setPage(0);
              }
            }}
            limit={data.current?.limit}
          />
        </div>
      </div>
    </>
  );
}
