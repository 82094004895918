import React from "react";

export const UploadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33268 15.8327H16.666V9.99935H18.3327V16.666C18.3327 17.1263 17.9596 17.4993 17.4993 17.4993H2.49935C2.03912 17.4993 1.66602 17.1263 1.66602 16.666V9.99935H3.33268V15.8327ZM11.666 7.49935V12.4993H8.33268V7.49935H4.16602L9.99935 1.66602L15.8327 7.49935H11.666Z"
        fill="white"
      />
    </svg>
  );
};
