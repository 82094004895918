import React from 'react'
import { Link } from "react-router-dom";


const NavItem = ({ href, children, selected, ...props }) => {
  return (
    <Link to={href} className={selected? 'ActiveTab' : null}>
        {children}
    </Link>
  ) 
}

export default NavItem
