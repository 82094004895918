import React, { PureComponent, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: -3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: -2000,
    pv: -9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: -1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: -3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function HealthCheckChart({ chartData }) {
  const [data, setData] = useState();

  useEffect(() => {
    const res = chartData?.map((item) => {
      return {
        name: item?.modelFileName,
        latestReportStatus: item?.latestReportStatus === "Pass" ? 1 : -1,
      };
    });

    setData(res);
  }, [chartData]);

  const tickFormatter = (value, index) => {
    const limit = 20; // put your maximum character
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}...`;
 };

  return (
    <ResponsiveContainer width="100%" height={285}>
      <BarChart
        data={data}
        barSize={15}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 100,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          padding={{ left: 0, right: 0 }}
          angle={-45}
          textAnchor="end"
          tick={{ fontSize: "12px" }}
          tickFormatter={tickFormatter}
        />

        <YAxis />
        <Tooltip />
        <Bar
          dataKey="latestReportStatus"
          fill="#F4A261"
          // stroke="#000000"
          // strokeWidth={1}
          radius={[3, 3, 0, 0]}
        >
          {data?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry?.latestReportStatus === 1 ? "#365E32" : "#973131"}
            />
          ))}
        </Bar>
        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
      </BarChart>
    </ResponsiveContainer>
  );
}
