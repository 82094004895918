import React, { useState } from 'react';
import {
  MDBBox,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../ui-components/loading';

import { Maybe } from 'helpers/maybeFunctor';
import { useHttp } from 'hooks/useHttp';
import addNotification from 'helpers/notify';
import { changeAdmin } from 'api/users/changeAdmin';
import { choseToggle } from 'helpers/toggleIfLoading';
import { mainAdminSetNewAdmin } from 'actions/main-admin';
import { useHistory } from 'react-router-dom';
import GA4 from '../GA4'
import {eventTrack} from 'helpers/ga4Helper'



const ChangeAdminModal = props => {
  const [chosenUser, setChosenUser] = useState(null);
  const usersRedux = useSelector(s => s.admin.users);
  const dispatch = useDispatch();
  const history = useHistory();
  const toggle = () => {
    props.toggle();
    setChosenUser(null);
  };

  const { loading, request: changeAdminAPI } = useHttp({
    requestCallback: changeAdmin,
    onLoad: res => {
      dispatch(mainAdminSetNewAdmin(res));
      toggle();
      setChosenUser(null);
      addNotification('Admin was successfully changed.', 'success');
    },
    onError: res => {
      toggle();
      setChosenUser(null);
      addNotification(
        'Something went wrong with admin change, please contact support',
        'danger',
      );
    },
  });

  const maybeUsers = Maybe.of(usersRedux).map(users => {
    return users.map(userItem => {
      const { name, email, lastName, _id } = userItem;
      return (
        <MDBListGroupItem
          key={_id}
          className="p-2 cursor-pointer expiration-box d-flex align-items-center"
          active={chosenUser === _id}
          onClick={() =>{ setChosenUser(_id)
            {eventTrack("Change-Admin-Action","Change-Project",history.location.pathname,_id,false,chosenUser)}}}
          
        >
          <div className='admin-badge-icon'>
          <i className='fa fa-user'></i>
          </div>
          <div>
          <div className="admin-list-name">
            {name} {lastName}
          </div>
          <div className="admin-list-email">{email}</div>
          </div>
        </MDBListGroupItem>
      );
    });
  });

  const submitNewAdmin = () => {
    if (!chosenUser) {
      addNotification("You didn't pick the user!", 'danger');
    } else {
      changeAdminAPI({ user_id: chosenUser });
    }
  };

  const { value: usersToJSX } = maybeUsers;

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={choseToggle(loading, toggle)}
      centered
      className='expiration-modal'
    >
      <MDBModalHeader toggle={toggle}>Change Admin</MDBModalHeader>
      <MDBModalBody>
        <MDBListGroup className="fixes-height-members overflow-auto change-admin-list-group">
          {usersToJSX}
        </MDBListGroup>
        {loading ? <Loading color="black" text="Changing admin..." /> : null}
        <GA4 
       
       userId ={chosenUser}
       category={"Management"}
        action={"ChangeCompanyAdmin-Action"}
         label={history.location.pathname}
         value={chosenUser}
         nonInteraction={false}
          transport={"xhr"}
     ></GA4>
        <MDBBox className="d-flex justify-content-end">
          <MDBBtn
            size="sm"
            color=""
            className='bg-bid text-white card-button text-inherit w-100 expiration-btn'
            disabled={loading}
            onClick={submitNewAdmin}
          >
            Change
          </MDBBtn>
          
        </MDBBox>
      </MDBModalBody>
    </MDBModal>
  );
};

export default ChangeAdminModal;
