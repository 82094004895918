import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BuiltIn from "./BuiltIn";
import Shared from "./Shared";
import Project from "./Project";
import Family from "./Family";
import Global from "./Global";
import { getParameters } from "api/parameters/getParameters";
import { useHttp } from "hooks/useHttp";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";
import Loading from "components/ui-components/loading";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { Modal } from "antd";
import { RequestCard } from "pages/configuration/requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { setFeatureRequest } from "actions/user";
import { deleteParameter } from "api/parameters/deleteParameter";
import addNotification from "helpers/notify";
import Select from "react-select";
import { createParameter } from "api/parameters/createParameter";
import Loader from "components/Loader";
import { useForm } from "react-hook-form";
import { eventTrack } from "helpers/ga4Helper";

export default function Parameters() {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    trigger,
  } = useForm({ mode: "onChange" });
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const featureRequestRedux = useSelector((s) => s.user.featureRequest);
  const parameterRefreshRedux = useSelector((s) => s.extras?.refreshParameters);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);

  const [parameterToDelete, setParameterToDelete] = useState(null);
  const [bulkParameterToDelete, setBulkParameterToDelete] = useState([]);

  const [builtInTotalRecords, setbuiltInTotalRecords] = useState();
  const [sharedTotalRecords, setsharedTotalRecords] = useState();
  const [projectTotalRecords, setprojectTotalRecords] = useState();
  const [familyTotalRecords, setfamilyTotalRecords] = useState();
  const [globalTotalRecords, setglobalTotalRecords] = useState();

  const [requestType, setRequestType] = useState("");

  const TABS = {
    BUILT_IN: "builtIn",
    SHARED: "shared",
    PROJECT: "project",
    FAMILY: "family",
    GLOBAL: "global",
  };

  const PARAMETER_TYPE_OPTIONS = [
    {
      label: "Built In Parameters",
      value: "builtIn",
    },
    {
      label: "Shared Parameters",
      value: "shared",
    },
    {
      label: "Project Parameters",
      value: "project",
    },
    {
      label: "Family Parameters",
      value: "family",
    },
    {
      label: "Global Parameters",
      value: "global",
    },
  ];

  const [activeTab, setActiveTab] = useState(TABS.BUILT_IN);
  const [paramsName, setParamsName] = useState("");
  const [isInstance, setIsInstance] = useState(false);
  const [parameterType, setParameterType] = useState("");
  const [loading, setLoading] = useState(false);
  const [bulkDeleteLoading, setBulkDeleteLoading] = useState(false);

  const [requestModal, setRequestModal] = useState(false);
  const [addParameterModal, setAddParameterModal] = useState(false);

  const [initialAPICall, setinitialAPICall] = useState(false);

  const { loading: builtInparamsLoad, request: getParametersbuiltInAPI } =
    useHttp({
      requestCallback: (data) =>
        getParameters({ companyId: userInfoRedux?.company, type: "builtIn" }),
      onLoad: (res) => {
        setbuiltInTotalRecords(res?.totalCount);
      },
      onError: (res) => {},
    });

  const { loading: sharedparamsLoad, request: getParameterssharedAPI } =
    useHttp({
      requestCallback: (data) =>
        getParameters({ companyId: userInfoRedux?.company, type: "shared" }),
      onLoad: (res) => {
        setsharedTotalRecords(res?.totalCount);
      },
      onError: (res) => {},
    });

  const { loading: projectparamsLoad, request: getParametersprojectAPI } =
    useHttp({
      requestCallback: (data) =>
        getParameters({ companyId: userInfoRedux?.company, type: "project" }),
      onLoad: (res) => {
        setprojectTotalRecords(res?.totalCount);
      },
      onError: (res) => {},
    });

  const { loading: familyparamsLoad, request: getParametersfamilyAPI } =
    useHttp({
      requestCallback: (data) =>
        getParameters({ companyId: userInfoRedux?.company, type: "family" }),
      onLoad: (res) => {
        setfamilyTotalRecords(res?.totalCount);
      },
      onError: (res) => {},
    });

  const { loading: globalparamsLoad, request: getParametersglobalAPI } =
    useHttp({
      requestCallback: (data) =>
        getParameters({ companyId: userInfoRedux?.company, type: "global" }),
      onLoad: (res) => {
        setglobalTotalRecords(res?.totalCount);
        setLoading(false);
      },
      onError: (res) => {
        setLoading(false);
      },
    });

  useEffect(() => {
    getParametersbuiltInAPI();
    getParameterssharedAPI();
    getParametersprojectAPI();
    getParametersfamilyAPI();
    getParametersglobalAPI();
  }, []);

  useEffect(() => {
    setLoading(true);
    getParametersbuiltInAPI();
    getParameterssharedAPI();
    getParametersprojectAPI();
    getParametersfamilyAPI();
    getParametersglobalAPI();
  }, [parameterRefreshRedux]);

  const handleDeleteParameter = async () => {
    setLoading(true);
    const res = await deleteParameter([parameterToDelete?._id]);
    if (res.success) {
      setinitialAPICall(res);
      setLoading(false);
      setOpenDeleteModal(false);
      addNotification("Parameter Deleted Successfully! ", "success");
    } else {
      addNotification(res?.message, "danger");
    }
  };

  const handleDeleteBulk = async () => {
    setBulkDeleteLoading(true);
    const res = await deleteParameter(bulkParameterToDelete);
    if (res.success) {
      setinitialAPICall(res);
      setBulkDeleteLoading(false);
      setOpenBulkDeleteModal(false);
      addNotification("Parameter Deleted Successfully! ", "success");
      setBulkParameterToDelete([]);
    } else {
      addNotification(res?.message, "danger");
    }
  };

  const handleAddParameter = async () => {
    const formObject = {
      companyId: userInfoRedux?.company,
      name: paramsName,
      isInstance: isInstance,
      parameterType: parameterType?.value,
    };
    setLoading(true);
    const res = await createParameter([formObject]);
    if (res.results) {
      setinitialAPICall(res);
      setLoading(false);
      setAddParameterModal(false);
      addNotification("Parameter Added Successfully! ", "success");
    } else {
      addNotification(res?.message, "danger");
    }
  };

  const requestAccess = async () => {
    try {
      setLoading(true);
      const res = await createFeatureRequest(userInfoRedux.id, {
        companyId: userInfoRedux.company,
        permission: requestType,
        userId: userInfoRedux.id,
      });
      if (res) {
        setLoading(false);
        addNotification("Request Successfully! ", "success");
        dispatch(
          setFeatureRequest(res?.map((feature) => feature?.requestedFeature))
        );
      }
    } catch (e) {
      addNotification(e?.message, "danger");
      setLoading(false);
    }
  };

  const cancelAddForm = () => {
    setAddParameterModal(false);
    setParamsName("");
    setIsInstance(false);
    setParameterType("");
  };

  return (
    <div className="personal-area">
      {loading && <Loader />}
      <div className="content-bg p-2">
        <div className="container-fluid d-flex parameter-container">
          <div className="profile-sidebar">
            <p className="font-weight-bold">All Parameters</p>
            <button
              onClick={() => setActiveTab(TABS.BUILT_IN)}
              className={activeTab === TABS.BUILT_IN ? "ActiveTab" : ""}
            >
              Builtin Parameters ({builtInTotalRecords})
            </button>
            <button
              onClick={() => setActiveTab(TABS.SHARED)}
              className={activeTab === TABS.SHARED ? "ActiveTab" : ""}
            >
              Shared Parameters ({sharedTotalRecords})
            </button>
            <button
              onClick={() => setActiveTab(TABS.PROJECT)}
              className={activeTab === TABS.PROJECT ? "ActiveTab" : ""}
            >
              Project Parameters ({projectTotalRecords})
            </button>
            <button
              onClick={() => setActiveTab(TABS.FAMILY)}
              className={activeTab === TABS.FAMILY ? "ActiveTab" : ""}
            >
              Family Parameters ({familyTotalRecords})
            </button>
            <button
              onClick={() => setActiveTab(TABS.GLOBAL)}
              className={activeTab === TABS.GLOBAL ? "ActiveTab" : ""}
            >
              Global Parameters ({globalTotalRecords})
            </button>
          </div>

          <div
            className="profile-content px-0 pt-0 pb-0"
            style={{ overflow: "hidden", maxHeight: "79vh" }}
          >
            {activeTab === TABS.BUILT_IN && (
              <BuiltIn
                setParameterToDelete={setParameterToDelete}
                setOpenDeleteModal={setOpenDeleteModal}
                FinalPermissions={FinalPermissions}
                setRequestModal={setRequestModal}
                setRequestType={setRequestType}
                setOpenBulkDeleteModal={setOpenBulkDeleteModal}
                setBulkParameterToDelete={setBulkParameterToDelete}
                bulkParameterToDelete={bulkParameterToDelete}
                setAddParameterModal={setAddParameterModal}
                userInfoRedux={userInfoRedux}
                setbuiltInTotalRecords={setbuiltInTotalRecords}
                initialAPICall={initialAPICall}
              />
            )}
            {activeTab === TABS.SHARED && (
              <Shared
                userInfoRedux={userInfoRedux}
                setParameterToDelete={setParameterToDelete}
                setOpenDeleteModal={setOpenDeleteModal}
                FinalPermissions={FinalPermissions}
                setRequestModal={setRequestModal}
                setRequestType={setRequestType}
                setOpenBulkDeleteModal={setOpenBulkDeleteModal}
                setBulkParameterToDelete={setBulkParameterToDelete}
                bulkParameterToDelete={bulkParameterToDelete}
                setAddParameterModal={setAddParameterModal}
                setsharedTotalRecords={setsharedTotalRecords}
                initialAPICall={initialAPICall}
              />
            )}
            {activeTab === TABS.PROJECT && (
              <Project
                userInfoRedux={userInfoRedux}
                setParameterToDelete={setParameterToDelete}
                setOpenDeleteModal={setOpenDeleteModal}
                FinalPermissions={FinalPermissions}
                setRequestModal={setRequestModal}
                setRequestType={setRequestType}
                setOpenBulkDeleteModal={setOpenBulkDeleteModal}
                setBulkParameterToDelete={setBulkParameterToDelete}
                bulkParameterToDelete={bulkParameterToDelete}
                setAddParameterModal={setAddParameterModal}
                setprojectTotalRecords={setprojectTotalRecords}
                initialAPICall={initialAPICall}
              />
            )}
            {activeTab === TABS.FAMILY && (
              <Family
                userInfoRedux={userInfoRedux}
                setParameterToDelete={setParameterToDelete}
                setOpenDeleteModal={setOpenDeleteModal}
                FinalPermissions={FinalPermissions}
                setRequestModal={setRequestModal}
                setRequestType={setRequestType}
                setOpenBulkDeleteModal={setOpenBulkDeleteModal}
                setBulkParameterToDelete={setBulkParameterToDelete}
                bulkParameterToDelete={bulkParameterToDelete}
                setAddParameterModal={setAddParameterModal}
                setfamilyTotalRecords={setfamilyTotalRecords}
                initialAPICall={initialAPICall}
              />
            )}
            {activeTab === TABS.GLOBAL && (
              <Global
                userInfoRedux={userInfoRedux}
                setParameterToDelete={setParameterToDelete}
                setOpenDeleteModal={setOpenDeleteModal}
                FinalPermissions={FinalPermissions}
                setRequestModal={setRequestModal}
                setRequestType={setRequestType}
                setOpenBulkDeleteModal={setOpenBulkDeleteModal}
                setBulkParameterToDelete={setBulkParameterToDelete}
                bulkParameterToDelete={bulkParameterToDelete}
                setAddParameterModal={setAddParameterModal}
                setglobalTotalRecords={setglobalTotalRecords}
                initialAPICall={initialAPICall}
              />
            )}
          </div>
        </div>
      </div>

      {/* add Parameter */}

      <MDBModal
        isOpen={addParameterModal}
        toggle={() => setAddParameterModal(false)}
        centered
        className="delete-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            <p className="delete-modal-label-head">Add Parameter</p>
          </h5>
          <button onClick={cancelAddForm} type="button" className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <form>
            <div className="form-group mt-3">
              <label
                htmlFor=""
                className="f-14 inter text-black font-weight-500"
              >
                Parameter name
              </label>
              <input
                value={paramsName}
                name="parameterName"
                type="text"
                className="form-control"
                {...register("parameterName", {
                  required: "Parameter name is required",
                  onChange: (e) => setParamsName(e.target.value),
                })}
              />
              {errors.parameterName && (
                <p role="alert" className="validation-error-msg">
                  {errors.parameterName.message}
                </p>
              )}
            </div>
            <div className="form-group mt-3">
              <label for="" className="f-14 inter text-black font-weight-500">
                Parameter Type
              </label>
              <Select
                options={PARAMETER_TYPE_OPTIONS}
                onChange={(option) => setParameterType(option)}
              />
            </div>

            <div className="form-check form-switch ml-1 mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                value={isInstance}
                onChange={(e) => setIsInstance(e.target.checked)}
                checked={isInstance}
              />
              <label
                className="form-check-label ml-2 f-14 inter text-black font-weight-500"
                for="flexSwitchCheckChecked"
              >
                Is Instance
              </label>
            </div>
          </form>
        </div>
        {loading ? <Loading color="black" text="Adding Parameter..." /> : null}

        <div className="modal-footer border-none">
          <div className="w-100">
            <div
              onClick={() => {
                paramsName?.length === 0
                  ? trigger(["parameterName"])
                  : handleAddParameter();
                eventTrack(
                  "Add-Parameter-Action",
                  "Add-Parameter",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
              className="border-bid bg-bid rounded-lg py-2 w-100 text-white text-center inter f-16 font-weight-500 cursor-pointer"
            >
              Add Parameter
            </div>
            <div
              onClick={cancelAddForm}
              className="bg-white border-bid rounded-lg py-2 w-100 text-bid text-center inter f-16 font-weight-500 mt-3 cursor-pointer"
            >
              Cancel
            </div>
          </div>
        </div>
      </MDBModal>

      {/* delete modal check */}
      <MDBModal
        isOpen={openDeleteModal}
        toggle={() => setOpenDeleteModal(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpenDeleteModal(false)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Parameter ?</p>
          <p className="delete-modal-label">
            Are you sure you want to delete this Parameter? This action cannot
            be undone.
          </p>
        </MDBModalBody>
        {loading ? <Loading color="black" text="Deleting..." /> : null}
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpenDeleteModal(false);
                setParameterToDelete("");
              }}
              color=""
              disabled={loading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={() => {
                handleDeleteParameter();
                eventTrack(
                  "Delete-Parameter-Action",
                  "Delete-Parameter",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
              disabled={loading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      {/* bulk delete */}
      <MDBModal
        isOpen={openBulkDeleteModal}
        toggle={() => setOpenBulkDeleteModal(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpenBulkDeleteModal(false)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Parameters</p>
          <p className="delete-modal-label">
            Are you sure you want to delete this Parameters? This action cannot
            be undone.
          </p>
        </MDBModalBody>
        {bulkDeleteLoading ? (
          <Loading color="black" text="Deleting..." />
        ) : null}
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpenBulkDeleteModal(false);
                setBulkParameterToDelete([]);
              }}
              color=""
              disabled={bulkDeleteLoading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={handleDeleteBulk}
              disabled={bulkDeleteLoading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      {/* request Access modal */}
      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequestRedux?.includes(requestType)}
          loading={loading}
          icon={
            featureRequestRedux?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequestRedux?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequestRedux?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </div>
  );
}
