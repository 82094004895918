import React from 'react';

export const ProjectIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08333 4.58268V1.83268C7.08333 1.32643 7.49374 0.916016 8 0.916016H15.3333C15.8396 0.916016 16.25 1.32643 16.25 1.83268V4.58268H19.9167C20.4229 4.58268 20.8333 4.99309 20.8333 5.49935V18.3327C20.8333 18.839 20.4229 19.2493 19.9167 19.2493H3.41667C2.91041 19.2493 2.5 18.839 2.5 18.3327V5.49935C2.5 4.99309 2.91041 4.58268 3.41667 4.58268H7.08333ZM4.33333 14.666V17.416H19V14.666H4.33333ZM4.33333 12.8327H19V6.41602H4.33333V12.8327ZM8.91667 2.74935V4.58268H14.4167V2.74935H8.91667ZM10.75 10.0827H12.5833V11.916H10.75V10.0827Z"
        fill="#9C9C9C"
      />
    </svg>
  );
};
