import { store } from 'react-notifications-component';

const addNotification = (message, type) => {
  store.addNotification({
    title: 'Bidlight notification',
    message: message || "Error",
    type: type,
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export default addNotification;
