import React from "react";
import { Helmet } from "react-helmet";
import { url } from "api";

export const DefaultConfig = {
  title: "BidLight - Material, Equipment and Labor prediction",
  description:
    "BidLight helps you predict the equipment, labor, materials, cost and time required to turn your BIM designs into a physical reality.",
  siteUrl: "https://bidlight.com/",
  author: {
    email: "support@bidlight.com",
    name: "BidLight",
  },
  lang: "en",
  ogImage: `${url}/marketing/branding/hotlink-ok/bidlight-demo.png`,
  keywords:
    "BidLight BIM Revit plugin Estimation cost unitcost costdatabase Aec AEC Material Predictions Equipment bim360 bim autodesk navisworks bim modeling bim building Optimization Labor Forecasting Project Management Integration Customization Permanent Records BIM Execution Plan Strategy Content Development Design Development Fabrication Detailing 4D 5D Integration Consulting Digitalization",
};

const extractContent = (HTMLPart) =>
  HTMLPart.replace(/\n/gi, "")
    .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/gi, "")
    .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/gi, "")
    .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/gi, "")
    .replace(/<\/\s*(?:p|div)>/gi, "\n")
    .replace(/<br[^>]*\/?>/gi, "\n")
    .replace(/<[^>]*>/gi, "")
    .replace("&nbsp;", " ")
    .replace(/[^\S\r\n][^\S\r\n]+/gi, " ");

export default function Seo({
  title = DefaultConfig.title,
  description = DefaultConfig.description,
  ogImage = DefaultConfig.ogImage,
  siteUrl = DefaultConfig.siteUrl,
  keywords = DefaultConfig.keywords,
  authorUsername = DefaultConfig.author.name,
}) {
  return (
    <Helmet>
      <meta content="text/html; charset=UTF-8" httpEquiv="Content-Type" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <title>{title}</title>
      <meta name="description" content={extractContent(description)} />
      <meta property="og:description" content={extractContent(description)} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="keywords" name="keywords" content={keywords.toString()} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:width" content={526} />
      <meta property="og:image:height" content={275} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={authorUsername} />
      <meta property="og:site_name" content="BidLight Learning" />
      <meta property="og:url" name="og:url" content={siteUrl} />
      <meta property="twitter:url" name="twitter:url" content={siteUrl} />
      <meta property="url" name="url" content={siteUrl} />
      <link rel="shortcut icon" href="/images/logo.svg" />
      <link rel="canonical" href={siteUrl} />
      <meta property="author" name="author" content={authorUsername} />
      <meta property="image" name="image" content={ogImage} />
      <meta property="twitter:image" name="twitter:image" content={ogImage} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  );
}
