export const pricingData = [
    {
        title: 'Student',
        label: false,
        description: `Know by heart and learn by rote.<br/>
        For free education!. (Student id required)`,
        amount: 0,
        points: [
            'Automatic estimation',
            'Revit Real-Time estimation',
            'Bidlight prices database',
            '1Build prices database',
            'Craftsman prices database',
            'RSMeans (bring your own licence)',
            'Price comparison',
            '1 Project/month',
            'Build your own prices database',
            '1 user license',
            'Email support'
        ]
    },
    {
        title: 'Individual',
        label: false,
        description: `Small steps, for bigger impact.<br/>
        For personal use!`,
        amount: 199,
        points: [
            'Automatic estimation',
            'Revit Real-Time estimation',
            'Bidlight prices database',
            '1Build prices database',
            'Craftsman prices database',
            'RSMeans (bring your own licence)',
            'Price comparison',
            '1 Project/month',
            'Build your own prices database',
            '1 user license',
            'Email support'
        ]
    },
    {
        title: 'Essential',
        label: false,
        description: `Pay a little for essential upgrades.<br/>
        For professionals!`,
        amount: 499,
        points: [
            'Automatic estimation',
            'Revit Real-Time estimation',
            'Bidlight prices database',
            '1Build prices database',
            'Craftsman prices database',
            'RSMeans (bring your own licence)',
            'Price comparison',
            '3 Projects/month',
            'Build your own prices database',
            '3 users license',
            'Email support',
        ]
    },
    {
        title: 'Standard',
        label: 'Best Offer',
        description: `More access more business.<br/>
        For small businesses!`,
        amount: 799,
        points: [
            'Automatic estimation',
            'Revit Real-Time estimation',
            'Bidlight prices database',
            '1Build prices database',
            'Craftsman prices database',
            'RSMeans (bring your own licence)',
            'Price comparison',
            '5 Projects/month',
            'Build your own prices database',
            '5 users license',
            'Email/Phone support',
        ]
    },
    {
        title: 'Enterprise',
        label: false,
        description: `Go big or go home.<br/>
        For organizations!`,
        amount: 499,
        points: [
            'Automatic estimation',
            'Project management',
            'Data warehouse',
            'Parameter management system',
            'Real-time estimation',
            'Advanced users management',
            'Custom integrations',
            'Email/Phone support',
        ]
    },
] 

export const reviewsData = [
    
    {
        user: 'Will Moore',
        review: 'BidLight eased me through every step of the estimating process.  I particularly liked the flexibility of the program, simple adjustment process and seamless integration with Revit.',
        company: 'Southern Concepts Design Build',
        image: require('assets/images/willmoore.jpg'),
    },
    {
        user: 'Mary Ann Jones',
        review: 'Simply, now we are capable of designing complex and custom projects under budget',
        company: 'Hensel Architects, LLC',
        image: require('assets/images/mj.jpg'),
    },
    {
        user: 'John Theis',
        review: 'Providing pre-con with initial estimates and quantities, is a huge help!',
        company: 'CBT, INC',
        image: require('assets/images/jt.jpg'),
    },
    {
        user: 'Gerry Thomas',
        review: 'Thanks to Bidlight, we now have the capability to generate rough estimates early on, in the bidding cycle',
        company: 'Better Built, INC',
        image: require('assets/images/gt.jpg'),
    }
]