import React, { useEffect, useState } from "react";
import Arrow from "assets/images/icons/arrow.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import MainData from "./mainData";
import { modelHealthMetaLabels } from "constants/utils";

const labels = {
  healthDetailMeta: "Health Detail Meta",
};

export default function OthersRule({ reportData }) {
  const [otherData, setOtherData] = useState(reportData);
  // const [otherData, setOtherData] = useState(MainData);

  // useEffect(() => {
  //   setOtherData(MainData);
  // }, [MainData]);

  const Extradetails = ({ rule }) => {
    const additionalDetails = rule.details;
    return (
      <>
        {additionalDetails?.length > 0 ? (
          <div className="bg-light-bid m-3 rounded-3">
            <table className="bid-table bid-table-small border-top-none">
              <thead>
                <tr>
                  {Object.entries(additionalDetails[0]).map(
                    ([key, value], index) =>
                      value && (
                        <th className="txt-cap" key={key}>
                          {key}
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {additionalDetails?.map((item, key) => {
                  return (
                    <tr>
                      {Object.entries(item).map(([key, value], index) => (
                        <td className="txt-cap">
                          {Array.isArray(value) ? JSON.stringify(value) : value}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="bg-light-bid m-3 rounded-3 p-3 f-10">No Data</div>
        )}
      </>
    );
  };

  return (
    <div className="mt-0">
      <table className="bid-table border-top-none">
        <thead className="border-bottom-none">
          <tr>
            <th width="15%">Name</th>
            <th width="15%">Note</th>
            <th width="10%"></th>
          </tr>
        </thead>
      </table>
      <Accordion allowZeroExpanded>
        {Object.entries(otherData)
          .filter(([key]) => key === "healthDetailMeta")
          .map(([key, value]) => (
            <AccordionItem key={Math.random()}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <table className="bid-table">
                        <tbody>
                          <tr>
                            <td width="15%" key={key}>
                              {labels[key]}
                            </td>
                            <td width="15%" key={key}>
                              {"N/A"}
                            </td>
                            <td width="10%">
                              <img
                                src={Arrow}
                                className={
                                  expanded
                                    ? "arrow-icon"
                                    : "arrow-icon  transform-180"
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>

            <AccordionItemPanel>
              {Array.isArray(value) ? (
                console.log(value, "---> Array")
              ) : typeof value === "object" && value !== null ? (
                Object.entries(value)?.map(([innerKey, innerValue]) => (
                  <div className="bg-light-bid m-3 rounded-3" key={Math.random()}>
                    <table className="bid-table bid-table-small border-top-none">
                      <tbody>
                        <tr>
                          <td className="table-key-width">{modelHealthMetaLabels[innerKey]}</td>
                          <td>
                            {typeof innerValue === "object" ? "" : innerValue}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <div className="bg-light-bid m-3 rounded-3">
                  <table className="bid-table bid-table-small border-top-none">
                    <tbody>
                      <tr>
                        <td>
                          {value === null
                            ? "null"
                            : value === false
                            ? "False"
                            : value === true
                            ? "True"
                            : value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Accordion allowZeroExpanded>
        {otherData?.reportDetails?.otherRules?.map((item, key) => {
          return (
            <AccordionItem key={key}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <table className="bid-table">
                        <tbody>
                          <tr>
                            <td width="15%">{item?.settingName}</td>
                            <td width="15%" title={item.note ?? "N/A"}>
                              {item?.note
                                ? item?.note.length < 20
                                  ? item?.note
                                  : `${item.note.slice(0, 19) + "..."}`
                                : "N/A"}
                            </td>
                            <td width="10%">
                              <img
                                src={Arrow}
                                className={
                                  expanded
                                    ? "arrow-icon"
                                    : "arrow-icon  transform-180"
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Extradetails rule={item} />
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
}
