import React, { useEffect, useState, useRef } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { choseToggle } from "helpers/toggleIfLoading";
import { useHttp } from "hooks/useHttp";
import Select from "react-select";
import addNotification from "helpers/notify";
import { getRecentExportSettings, updateBulkProjectExportSettings } from "api/projects/exportSettings";

export default function ExportSettingsBulkAssignModel(props) {
  const dispatch = useDispatch();
  const milestonesRedux = useSelector((s) => s.user.milestones);
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const projectsRedux = useSelector((s) => s.user.projects);
  const [include2DElements, setInclude2DElements] = useState(false);
  const [includeMiscellaneous, setIncludeMiscellaneous] = useState(false);
  const [filteredParamsNameList, setFilteredParamsNameList] = useState();
  const [matchingParamsType, setMatchingParamsType] = useState();
  const [matchingParamsNameList, setMatchingParamsNameList] = useState();
  const [filteredParamsType, setFilteredParamsType] = useState();
  const [milestones, setMilestones] = useState([]);
  const [selectedMilestone, setSelectedMilstone] = useState(null);
  const [showExtraMatchingParameter, setShowExtraMatchingParameter] =
    useState(false);
  const [showFilterBidlightParameter, setShowFilterBidlightParameter] =
    useState(false);
  const [showFilterCustomParameter, setShowFilterCustomParameter] =
    useState(false);
  const [filterParameterSelected, setFilterParameterSelected] = useState([]);
  const [matchingParameterId, setMatchingParameterId] = useState([]);
  const [filterParamsProjectObj, setFilterParamsProjectObj] = useState([]);
  const [selectedFilterParameter, setSelectedFilterParameter] = useState();
  const [selectedMatchingParameter, setSelectedMatchingParameter] = useState();
  const [recentExportSettings, setRecenetExportSettings] = useState(false);

  useEffect(() => {
    setMilestones(
      milestonesRedux?.map((i) => ({
        label: i.key,
        value: i._id,
      }))
    );
  }, [milestonesRedux]);

  const PARAMETER_TYPE_OPTIONS = [
    {
      label: "Built In Parameters",
      value: "builtIn",
    },
    {
      label: "Shared Parameters",
      value: "shared",
    },
    {
      label: "Project Parameters",
      value: "project",
    },
    {
      label: "Family Parameters",
      value: "family",
    },
    {
      label: "Global Parameters",
      value: "global",
    },
  ];

  const parentProjectOptions = projectsRedux?.map((i) => ({
    label: i.title,
    value: i._id,
  }));

  const [parameters, setParameters] = useState([]);

  useEffect(() => {
    setParameters(props?.filteredParams);
  }, [props?.filteredParams]);

  useEffect(() => {
    if (parameters?.length && recentExportSettings?._id) {
      if (recentExportSettings?.filterParameter.type === "custom parameter") {
        const filteredParameters = parameters
          .filter((param) =>
            recentExportSettings.filterParameter.value.includes(param._id)
          )
          ?.map((i) => ({
            label: i.name,
            value: i._id,
          }));
        setFilterParameterSelected(filteredParameters);
      }
      if (recentExportSettings?.matchingParameter.type === "custom parameter") {
        const matchinParameters = parameters
          .filter((param) =>
            recentExportSettings.matchingParameter.parameters.includes(
              param._id
            )
          )
          ?.map((i) => ({
            label: i.name,
            value: i._id,
          }));
        setMatchingParameterId(matchinParameters);
      }
    }
  }, [parameters, recentExportSettings]);

  const handleChangeFilterParameterType = (filter) => {
    const filteredObj = parameters?.filter(
      (i) => i?.parameterType === filter.value
    );
    const filteredObjArr = filteredObj?.map((i) => ({
      label: i.name,
      value: i._id,
    }));
    setFilteredParamsNameList(filteredObjArr);
    setFilteredParamsType({ ...filter });
    setSelectedFilterParameter({ ...filter });
  };

  const handleChangeMatchingParameterType = (filter) => {
    const filteredObj = parameters?.filter(
      (i) => i?.parameterType === filter.value
    );
    const filteredObjArr = filteredObj?.map((i) => ({
      label: i.name,
      value: i._id,
    }));
    setMatchingParameterId([]);
    setMatchingParamsNameList(filteredObjArr);
    setMatchingParamsType({ ...filter });
    setSelectedMatchingParameter({ ...filter });
  };

  const handle2DElementCheckbox = (value = false) => {
    setInclude2DElements(value);
  };

  const handleMiscellaneousElementCheckbox = (value = false) => {
    setIncludeMiscellaneous(value);
  };

  const hanldeProjectSettingApiRequest = () => {
    const filterParameter = {
      type: showFilterBidlightParameter
        ? "bidlight parameter"
        : showFilterCustomParameter
        ? "custom parameter"
        : "None",
      paramType: showFilterCustomParameter
        ? filteredParamsType?.value
        : showFilterBidlightParameter
        ? "bidlight parameter"
        : "None",
      value: showFilterBidlightParameter
        ? filterParamsProjectObj?.map((i) => i.value)
        : showFilterCustomParameter
        ? filterParameterSelected?.map((i) => i.value)
        : [],
    };

    const matchingParameter = {
      type: showExtraMatchingParameter ? "custom parameter" : "Default",
      paramType: matchingParamsType?.value,
      parameters: matchingParameterId?.map((i) => i.value),
    };
    const payload = props?.bulkEditProject?.map((item) => {
      return {
        _id: item?.projectExportSettings,
        companyId: userInfoRedux?.company,
        projectId: item?._id,
        milestone: selectedMilestone?.value,
        includeMiscellaneous: includeMiscellaneous,
        include2DElements: include2DElements,
        matchingParameter: matchingParameter,
        filterParameter: filterParameter,
      };
    });

    updateBulkExportSettingsAPI(payload);
  };

  const toggle = () => {
    props.toggle();
  };

  const { loading: updateLoading, request: updateBulkExportSettingsAPI } =
    useHttp({
      requestCallback: (data) => updateBulkProjectExportSettings(data),
      onLoad: (res) => {
        addNotification("Bulk Export Settings Updated Successfully", "success");
      },
      onError: (res) => {
        console.log(res, res);
        addNotification("Something went wrong", "danger");
      },
    });

  useEffect(() => {
    if (
      props.recentExportSettings?.filterParameter?.type === "custom parameter"
    ) {
      setShowFilterCustomParameter(true);
    }

    if (
      props.recentExportSettings?.filterParameter?.type === "bidlight parameter"
    ) {
      setShowFilterBidlightParameter(true);
      const bidlightFilters = parentProjectOptions?.filter((option) =>
        props.recentExportSettings?.filterParameter?.value.includes(
          option.value
        )
      );
      console.log(bidlightFilters);
      setFilterParamsProjectObj(bidlightFilters);
    }

    if (
      props.recentExportSettings?.matchingParameter?.type === "custom parameter"
    ) {
      setShowExtraMatchingParameter(true);
    }

    const filterParameter = PARAMETER_TYPE_OPTIONS.find(
      (item) =>
        item?.value === props.recentExportSettings?.filterParameter?.paramType
    );
    const matchingParameter = PARAMETER_TYPE_OPTIONS.find(
      (item) =>
        item?.value === props.recentExportSettings?.matchingParameter?.paramType
    );

    setSelectedMatchingParameter(matchingParameter);
    setSelectedFilterParameter(filterParameter);
    setRecenetExportSettings(props.recentExportSettings);
    handle2DElementCheckbox(props.recentExportSettings?.include2DElements);
    handleMiscellaneousElementCheckbox(
      props.recentExportSettings?.includeMiscellaneous
    );
    const settingMileStone = milestones?.find(
      (mileStoneItem) =>
        mileStoneItem?.value === props.recentExportSettings?.milestone
    );

    setSelectedMilstone(settingMileStone);
  }, [props.recentExportSettings]);

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={choseToggle(updateLoading, toggle)}
      centered
      className="create-modal-full-size"
    >
      <MDBModalHeader toggle={choseToggle(updateLoading, toggle)}>
        Export Settings Bulk Assign
      </MDBModalHeader>
      <MDBModalBody className="pt-1">
        <div className="p-3">
          <div className="border rounded-3 bg-white p-3 pb-4">
            <div className="form-group-div">
              <div>
                <label className="inter pl-2">Milestone</label>
                <Select
                  options={milestones}
                  value={selectedMilestone}
                  onChange={(options) => setSelectedMilstone(options)}
                />
              </div>
            </div>
          </div>

          <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
            <label className="inter pl-2 f-14">
              Select the preferred matching parameter
            </label>
            <div>
              <div className="form-check mt-2">
                <input
                  className="form-check-input rounded-checkbox"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={!showExtraMatchingParameter}
                  onChange={(e) => setShowExtraMatchingParameter(false)}
                  value={showExtraMatchingParameter}
                />
                <label
                  className="form-check-label inter f-14"
                  htmlFor="flexRadioDefault1"
                >
                  Use revit default category
                </label>
              </div>
              <div className="form-check mt-3">
                <input
                  className="form-check-input rounded-checkbox"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  onChange={(e) => setShowExtraMatchingParameter(true)}
                  checked={showExtraMatchingParameter}
                  value={showExtraMatchingParameter}
                />
                <label
                  className="form-check-label inter f-14"
                  htmlFor="flexRadioDefault2"
                >
                  Use custom parameter
                </label>
              </div>
            </div>

            {showExtraMatchingParameter && (
              <>
                <div className="form-group-div mt-3">
                  <div>
                    <label className="inter pl-2">Project parameter</label>
                    <Select
                      options={PARAMETER_TYPE_OPTIONS}
                      onChange={(option) =>
                        handleChangeMatchingParameterType(option)
                      }
                      value={selectedMatchingParameter}
                    />
                  </div>
                </div>

                <div className="form-group-div mt-3">
                  <div>
                    <label className="inter pl-2">Input parameter</label>
                    <div className="form-group-div mt-3">
                      <Select
                        options={matchingParamsNameList}
                        isMulti
                        value={matchingParameterId}
                        onChange={(option) => {
                          setMatchingParameterId(option);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
            <label className="inter pl-2 f-14">
              Select the preferred export filtering parameter
            </label>
            <div>
              <div className="form-check mt-2">
                <input
                  className="form-check-input rounded-checkbox"
                  type="radio"
                  name="filterParameterRad"
                  id="filterParameterRad1"
                  checked={
                    !showFilterBidlightParameter && !showFilterCustomParameter
                  }
                  onChange={(e) => {
                    setShowFilterBidlightParameter(false);
                    setShowFilterCustomParameter(false);
                  }}
                />
                <label
                  className="form-check-label inter f-14"
                  htmlFor="filterParameterRad"
                >
                  None
                </label>
              </div>
              <div className="form-check mt-3">
                <input
                  className="form-check-input rounded-checkbox"
                  type="radio"
                  name="filterParameterRad"
                  id="filterParameterRad2"
                  checked={
                    showFilterBidlightParameter && !showFilterCustomParameter
                  }
                  onChange={(e) => {
                    setShowFilterBidlightParameter(true);
                    setShowFilterCustomParameter(false);
                  }}
                />
                <label
                  className="form-check-label inter f-14"
                  htmlFor="filterParameterRad2"
                >
                  Use BidLight project ID
                </label>
              </div>
            </div>

            {showFilterBidlightParameter && (
              <div>
                <div className="form-group-div mt-3">
                  <Select
                    options={parentProjectOptions}
                    isMulti
                    value={filterParamsProjectObj}
                    onChange={(e) => setFilterParamsProjectObj(e)}
                  />
                </div>
              </div>
            )}

            {/* <div className="d-flex justify-content-between gap-3">
      <div className="form-group-div mt-3 w-100">
        <div>
          <input type="text" placeholder="Parameter name" />
        </div>
      </div>
      <div className="form-group-div mt-3 w-100">
        <div>
          <input type="text" placeholder="Parameter value" />
        </div>
      </div>
    </div> */}

            <div className="form-check mt-3">
              <input
                className="form-check-input rounded-checkbox"
                type="radio"
                name="filterParameterRad"
                id="filterParameterRad3"
                checked={
                  !showFilterBidlightParameter && showFilterCustomParameter
                }
                onChange={(e) => {
                  setShowFilterBidlightParameter(false);
                  setShowFilterCustomParameter(true);
                }}
              />
              <label
                className="form-check-label inter f-14"
                htmlFor="filterParameterRad3"
              >
                Use Custom parameter
              </label>
            </div>

            {showFilterCustomParameter && (
              <>
                <div className="form-group-div mt-3">
                  <div>
                    {/* <label className="inter pl-2">Project parameter</label> */}
                    <Select
                      options={PARAMETER_TYPE_OPTIONS}
                      onChange={(option) =>
                        handleChangeFilterParameterType(option)
                      }
                      value={selectedFilterParameter}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  {/* <div className="form-group-div mt-3 w-100">
            <div>
              <input type="text" placeholder="Parameter name" />
            </div>
          </div> */}
                  <div className="form-group-div mt-3 w-100">
                    <div>
                      <Select
                        options={filteredParamsNameList}
                        isMulti
                        onChange={(option) =>
                          setFilterParameterSelected(option)
                        }
                        value={filterParameterSelected}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
            <label className="inter pl-2 f-14">
              Additional export configuration
            </label>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input squared-checkbox"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={includeMiscellaneous}
                  onChange={(e) =>
                    handleMiscellaneousElementCheckbox(e?.target?.checked)
                  }
                />
                <label
                  className="form-check-label f-14 inter"
                  htmlFor="flexCheckDefault"
                >
                  Include rooms spaces and miscellaneous items
                </label>
              </div>
              <div className="form-check mt-3">
                <input
                  className="form-check-input squared-checkbox"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  checked={include2DElements}
                  onChange={(e) => handle2DElementCheckbox(e?.target?.checked)}
                />
                <label
                  className="form-check-label inter f-14"
                  htmlFor="flexCheckChecked"
                >
                  Include 2D Elements
                </label>
              </div>
            </div>
          </div>

          <div
            className="header-btn bg-bid text-white border-bid rounded-lg h6 mx-auto mb-0 cursor-pointer w-25 justify-content-center mt-3"
            onClick={() => hanldeProjectSettingApiRequest()}
          >
            <span className="f-14">
              {updateLoading ? (
                <i className="fa fa-spinner small-spinner mr-2" />
              ) : (
                <i className="fa fa-save mr-2 "></i>
              )}
              Save
            </span>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
}
