import React, { useEffect } from "react";
import NavigationComponent from "./NavigationComponent";
import WhatIsBidlightComponent from "./WhatIsBidlight";
import FeaturesComponent from "./Features";
import HowToUseComponent from "./HowToUse";
import AboutUsComponent from "./AboutUs";
import OurPricingComponent from "components/LandingPageTemplateComponents/Pricing";
import AOS from "aos";
import "aos/dist/aos.css";
import FooterComponent from "components/LandingPageTemplateComponents/FooterComponent";

AOS.init();



const Landing = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <NavigationComponent {...props} />

      <div className="site-wrap mt-5" id="home-section">
        <WhatIsBidlightComponent {...props} /> 

        <FeaturesComponent {...props} />

        <HowToUseComponent {...props} />
        <OurPricingComponent estimationPriceCards/>

        <AboutUsComponent {...props} />

        <FooterComponent />
      </div>
    </>
  );
};

export default Landing;
