import React, { useState, useEffect } from 'react';
import {
  MDBBox,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { url } from 'api';

import addNotification from 'helpers/notify';

const HowToCreateTimelineModal = props => {
  const copy = `${url}/timelines/?id=${props.id}`;
  const [isCopied, setCopied] = useState(false);

  if (isCopied) {
    addNotification('Link is copied to clipboard!', 'success');
  }

  useEffect(() => {
    return () => {
      setCopied(false);
    };
  });

  return (
    <MDBModal toggle={props.toggle} isOpen={props.isOpen} size="md" centered>
      <MDBModalHeader toggle={props.toggle}>POST</MDBModalHeader>
      <MDBModalBody>
        <MDBBox className="d-flex flex-column align-items-start">
          <div className="font-size-1">{copy}</div>
          <CopyToClipboard text={copy} onCopy={() => setCopied(true)}>
            <MDBBtn color="blue darken-2" size="sm" className="ml-0">
              Copy
            </MDBBtn>
          </CopyToClipboard>
          <MDBBox className="d-flex flex-column pl-4 mt-2">
            <span className="font-size-1">title:string</span>
            <span className="mt-1 font-size-1">description:string</span>
            <span className="mt-1 font-size-1">json:file</span>
            <span className="mt-1 font-size-1">obj:file</span>
            <span className="mt-1 font-size-1">mtl:file</span>
            <span className="mt-1 font-size-1">excel:file</span>
            <span className="mt-1 font-size-1">pdf:file</span>
            <span className="mt-1 font-size-1">estimation:string</span>
            <span className="mt-1 font-size-1">doneBy:string</span>
            <span className="mt-1 font-size-1">date:Date</span>
          </MDBBox>
        </MDBBox>
      </MDBModalBody>
    </MDBModal>
  );
};

export default HowToCreateTimelineModal;
