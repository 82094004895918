import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import AsideFeed from "./AsideFeed";
import { Link, useLocation } from "react-router-dom";
import constants from "../../constants";
import ArticleNav from "../articleNav";
import { World } from "../../components/icons";
import { timeDifferenceForDate } from "../utils";

import Main from "../layout/main";
import Loader from "components/Loader";
import axios from "axios";
import { url } from "api";
import { extractImageFromZip } from "learning/utils/imageHandler";
import Pagination from "components/Pagination/Pagination";
import { useHttp } from "hooks/useHttp";
import { getArticles } from "api/articles/getArticles";
import addNotification from "helpers/notify";

const smartNotes = [
  "• a must read",
  "• 1 minute read",
  "• informative read",
  "• easy read",
  "• unexpected read",
];

const Feed = ({ userInfoRedux }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramCategory = searchParams.get("category");

  const [allArticles, setAllArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(paramCategory);

  const [totalRecords, setTotalRecords] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const data = useRef({ index: 0, limit: 10 });

  const { loading: apiLoading, request: getArticlesAPI } = useHttp({
    requestCallback: (data) => getArticles(data?.current),
    onLoad: async (res) => {
      let articles = res?.articlesAll;
      const updatedArticles = await Promise.all(
        articles.map(async (article) => {
          const authorImage = await extractImageFromZip(
            article.author.profilePhoto
          );
          article.author.profilePhoto = authorImage;
          return article;
        })
      );
      console.log(updatedArticles);
      setAllArticles(updatedArticles);
      setFilteredArticles(updatedArticles);
      if (paramCategory) {
        setSelectedCategory(paramCategory);
      }
      // filterArticles(updatedArticles, paramCategory ? paramCategory : "All");

      setTotalRecords(res?.totalCount);
      setTotalPage(Math.ceil(res?.totalCount / data?.current?.limit));
    },
    onError: (res) => {
      console.log("err", res);
      addNotification("Something went wrong", "danger");
    },
  });

  const FilterArticles = (e) => {
    setAllArticles([]);
    data.current = {
      ...data.current,
      index: 0,
      column: "category",
      search: e.target.innerText === "All" ? "" : e.target.innerText,
      sort: 1,
      sort_dir: -1,
    };
    getArticlesAPI(data);
    setTotalPage(0);
    setPage(0);
    setSelectedCategory(e.target.innerText);
    setSearchValue("");

    // append the query param without reloading the page
    const category = e.target.innerText;
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?category=" +
      category;
    window.history.pushState({ path: newurl }, "", newurl);
  };

  function clap(article_id) {
    console.log("clapping...");
    axios
      .post(`${url}/api/article/clap`, { article_id })
      .then((res) => {
        getArticlesAPI(data);
      })
      .catch((err) => console.log(err));
  }

  const categoryList = ["All", "Tutorials", "Blogs"];

  const navList = categoryList.sort().map((category, index) => (
    <button
      key={index}
      className={selectedCategory === category ? "ActiveTab" : ""}
      onClick={(e) => {
        FilterArticles(e);
      }}
    >
      {category === "All" && <World />}
      <span>{category}</span>
    </button>
  ));

  const articles = filteredArticles?.map((article) => (
    <div className="post-panel" key={article._id}>
      <div className="post-metadata">
        <img
          alt={article.author?.username}
          className="avatar-image"
          src={article.author.profilePhoto}
          height="40"
          width="40"
        />

        <div className="post-info">
          <div className="PopoverLink">
            <span className="popover-link" data-reactroot="">
              <Link to={`/learning/articles/profile/${article.author?._id}`}>
                {article.author?.name}
              </Link>
            </span>
          </div>
          <small>
            {" "}
            Article {
              smartNotes[Math.floor(Math.random() * smartNotes.length)]
            }{" "}
          </small>{" "}
          <small
            style={{
              position: "absolute",
              paddingRight: "10px",
              right: "5px",
              textAlign: "right",
              align: "right",
            }}
          >
            {" "}
            Published • {timeDifferenceForDate(article.createdAt)}
          </small>
        </div>
      </div>

      {article.feature_img?.length > 0 ? (
        <div className="post-picture-wrapper">
          <Link to={`/learning/articles/${article.slug}`}>
            {" "}
            <img
              src={`${constants.SERVER_URL}/marketing/branding/hotlink-ok/${article.feature_img}`}
              alt={article.slug}
            />
          </Link>
        </div>
      ) : (
        ""
      )}

      <div className="main-body">
        <Link to={`/learning/articles/${article.slug}`}>
          <h1 className="post-title-1">{article.title}</h1>
        </Link>

        <div className="post-body">
          <h2>
            <main
              className="article-font"
              dangerouslySetInnerHTML={{ __html: article.description }}
            />
          </h2>
        </div>
        <Link to={`/learning/articles/${article.slug}`}>Read more</Link>
      </div>

      <div className="post-stats clearfix">
        <div className="pull-left">
          <div className="like-button-wrapper">
            <div className="like-button-wrapper">
              <button
                onClick={() => clap(article._id)}
                className="like-button"
                data-behavior="trigger-overlay"
                type="submit"
              >
                <span className="text d-flex align-items-center">
                  <i className="fa fa-thumbs-up mr-2"></i> Like
                  <span className="like-count ml-3 mr-0">{article.claps}</span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="pull-right">
          <div className="bookmark-button-wrapper">
            <form className="button_to" method="get" action="">
              <button
                className="bookmark-button"
                data-behavior="trigger-overlay"
                type="submit"
              >
                {" "}
                <span className="icon-bookmark-o"></span>
                <span className="hide-text">Bookmark</span>
              </button>
            </form>
          </div>
        </div>

        <div className="response-count pull-right"></div>
      </div>
    </div>
  ));

  useEffect(() => {
    data.current = {
      ...data.current,
      index: page,
      column: searchValue ? "title" : "category",
      search: searchValue
        ? searchValue
        : selectedCategory === "All"
        ? ""
        : selectedCategory,
      sort: 1,
      sort_dir: -1,
    };
    getArticlesAPI(data);
  }, [page]);

  const SearchFunction = () => {
    setAllArticles([]);
    data.current = {
      ...data.current,
      index: 0,
      column: "title",
      search: searchValue,
      sort: 1,
      sort_dir: -1,
    };
    getArticlesAPI(data);
    setTotalPage(0);
    setPage(0);
    setSelectedCategory("All");
  };

  return (
    <>
      {apiLoading && <Loader />}
      <div className="personal-area min-h-full">
        <div className="container-fluid learning-container-flex pt-3">
          {allArticles && (
            <ArticleNav>
              <div className="learning-sidebar">
                <p className="font-weight-bold">Articles</p>
                {navList}
              </div>
            </ArticleNav>
          )}
          <div className="learning-container-flex w-100">
            <div className="learning-content p-0">
              <Main>
                <div className="container-fluid main-container">
                  <div className="search-div w-100">
                    <div className="input-with-cancelBtn ms-auto">
                      <input
                        type="text"
                        className="search-bar"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search"
                      />
                      {searchValue?.length > 0 && (
                        <button
                          onClick={() => {
                            setSearchValue("");
                            data.current = {
                              ...data.current,
                              index: 0,
                              search: null,
                              column: "category",
                            };
                            getArticlesAPI(data);
                          }}
                          className="search-btn1"
                        >
                          <i className="fa fa-times" />
                        </button>
                      )}
                    </div>

                    <button
                      disabled={!searchValue?.length > 0}
                      onClick={() => SearchFunction()}
                      className="search-btn2 bg-bid text-white mr-0"
                    >
                      <i className="fa fa-search me-2" />
                      Search Article
                    </button>
                  </div>
                  <div
                    className="col-md-offset-1 dashboard-main-content"
                    style={{ margin: "10px" }}
                  >
                    <div
                      className="posts-wrapper animated fadeInUp"
                      data-behavior="endless-scroll"
                      data-animation="fadeInUp-fadeOutDown"
                    >
                      {articles?.length === 0 ? (
                        <p>No Record Found</p>
                      ) : (
                        articles
                      )}
                    </div>
                  </div>
                </div>
                <div className="asset-pagination-div mx-auto">
                  <Pagination
                    totalRecords={totalRecords}
                    totalPage={totalPage}
                    page={page + 1}
                    onPageChange={(pageNo) => setPage(pageNo - 1)}
                    onLimitChange={(count) => {
                      data.current = { ...data.current, limit: count };
                      if (page === 0) {
                        getArticlesAPI(data);
                      } else {
                        setPage(0);
                      }
                    }}
                    limit={data.current?.limit}
                  />
                </div>
              </Main>
            </div>
            {filteredArticles && (
              <AsideFeed
                selectedCategory={selectedCategory}
                _articles={filteredArticles}
                userInfoRedux={userInfoRedux}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Feed;
