import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";

const COLORS = ["#283f4b", "#088395"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      style={{fontSize: '20px'}}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function DirectIndirectChart({ data }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const res = [
      {
        name: "Direct",
        value: data?.filter((item) => item?.directProjectWorked === true)?.length,
      },
      {
        name: "InDirect",
        value: data?.filter((item) => item?.directProjectWorked === false)?.length,
      },
    ];
    setChartData(res);
  }, [data]);

  return (
    <PieChart width={500} height={250}>
      <Pie
        data={chartData}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={120}
        fill="#8884d8"
        dataKey="value"
        dataName="name"
      >
        {chartData?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}
