//should be exported to constants/sort.js
export const FROM_NEWER_TO_OLDER = 'FROM_NEWER_TO_OLDER';
export const FROM_OLDER_TO_NEWER = 'FROM_OLDER_TO_NEWER';

export const sortByDate = (data, type) => {
  if (!data) {
    return data;
  }
  switch (type) {
    case FROM_NEWER_TO_OLDER:
      return data.sort((prev, next) => {
        return new Date(next.date) - new Date(prev.date);
      });
    case FROM_OLDER_TO_NEWER:
      return data.sort((prev, next) => {
        return new Date(prev.date) - new Date(next.date);
      });
    default:
      return data.sort((prev, next) => {
        return new Date(next.date) - new Date(prev.date);
      });
  }
};
