import { get } from '../index';

export const getFilterView = () => {
  return get('/filter');
};

export const getFilterByCompanyId = (companyId) => {
    return get(`/filter/company/${companyId}`);
  };

  export const getFilterByProjectId = (projectId, timelineId) => {
    return get(`/filter/project/${projectId}/${timelineId}`);
  };