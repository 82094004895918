import React from "react";
import Feed from "../../components/articles/Feed";
import Seo from "../../components/seo/Seo";

import { eventTrack } from "../../components/utils";
import GA4 from "../../components/GA4";
import Layout from "learning/components/layout";
import { url } from "api";

const FeedPage = ({ anonymous, userInfoRedux }) => {
  return (
    <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>
      <Seo
        title={"BidLight | BIM Revit Plugin Articles"}
        siteUrl={`https://app.bidlight.com/learning/articles`}
        description={
          "Explore expert insights, industry trends, and in-depth guides on BIM, architecture, engineering, and construction. Stay informed with articles that help you innovate and optimize project performance."
        }
        keywords={
          "BidLight articles faq learning BIM Revit Plugin Aec contact help support AEC Material Predictions Equipment bim360 bim autodesk navisworks	bim modeling	 bim building	 Optimization Labor Forecasting Project Management Integration Customization Permanent Records BIM Execution Plan Strategy Content Development Design Development Fabrication  Detailing 4D 5D Integration Consulting Digitalization "
        }
        authorUsername={"BidLight"}
        ogImage={
          "https://app.bidlight.com/marketing/branding/hotlink-ok/articles.png"
        }
      />
      {eventTrack("Article Feed", "Article Feed", null, 10, false)}
      <GA4
        userId={"Article Feed"}
        category={"Article Feed"}
        action={"Browsing Articles"}
        label={"Article Feed"}
        value={10}
        nonInteraction={false}
        transport={"xhr"}
      ></GA4>
      <Feed userInfoRedux={userInfoRedux} />
    </Layout>
  );
};

export default FeedPage;
