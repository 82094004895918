import React from "react";
import Editor from "../../components/articles/Editor";
import Seo from "../../components/seo/Seo";
import { url } from "api";
const ArticleEditor = ({ anonymous, userInfoRedux, slug }) => {
  return (
    <>
      <Seo
        title={"BidLight | Add Articles"}
        description={
          "Explore expert insights, industry trends, and in-depth guides on BIM, architecture, engineering, and construction. Stay informed with articles that help you innovate and optimize project performance."
        }
        siteUrl={`https://app.bidlight.com/learning/articles/create/editor`}
      />
      <Editor anonymous={anonymous} userInfoRedux={userInfoRedux} slug={slug} />
    </>
  );
};

export default ArticleEditor;
