import React, { useEffect, useRef, useState } from "react";
import { createAffiliations } from "api/affiliations/createAffiliations";
import Loading from "components/ui-components/loading";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import addNotification from "helpers/notify";
import { useHttp } from "hooks/useHttp";
import {
  MDBModal
} from "mdbreact";
import { useSelector } from "react-redux";
import { eventTrack } from "helpers/ga4Helper";

export default function AffiliatedUsers({
  data,
  FinalPermissions,
  setRequestModal,
  setRequestType,
}) {
  const TABS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  };
  const [affiliations, setAffiliations] = useState([]);
  const [affiliationsDisplayList, setAffiliationsDisplayList] = useState([]);

  const [addUser, setAddUser] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.ACTIVE);

  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const [mail, setMail] = useState("");

  useEffect(() => {
    setAffiliations(data?.length > 0 ? data : []);
  }, [data]);

  const handleAddAffiliations = async () => {
    const formObject = {
      // affiliationId: userInfoRedux?.id,
      mail: mail,
    };
    createAffiliationsAPI(formObject);
  };

  const { loading: loading, request: createAffiliationsAPI } = useHttp({
    requestCallback: (data) => createAffiliations(data),
    onLoad: (res) => {
      setMail("");
      setAddUser(false);
      if (res.err) {
        addNotification(res.err, "danger");
      } else {
        addNotification(
          "An email has been sent with a link to register user",
          "success"
        );
      }
    },
    onError: (err) => {
      addNotification(err.err, "danger");
    },
  });

  const cancelAddForm = () => {
    setMail("");
    setAddUser(false);
  };

  useEffect(() => {
    if (activeTab === TABS.ACTIVE) {
      setAffiliationsDisplayList(data?.activeUsers);
    } else {
      setAffiliationsDisplayList(data?.inActiveUsers);
    }
  }, [activeTab]);

  return (
    <>
      <div className="w-100 px-0 position-relative h-100">
        <div className="d-flex justify-content-between px-3 py-3 border-bottom">
          <div className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
            >
              <path
                d="M0.273438 17.7598V0.240234H9.25072L9.71511 2.40519H15.7265V12.1955H8.92443L8.46004 10.048H1.9843V17.7598H0.273438Z"
                fill="black"
              />
            </svg>
            <span className="font-weight-bold ml-3 text-black">
              Affiliated Users
            </span>

            <div
              className={`header-btn border-bid rounded-lg h6 ml-4 mr-1 mb-0 cursor-pointer ${
                activeTab === TABS.ACTIVE
                  ? "bg-bid text-white"
                  : "bg-white text-bid"
              }`}
              onClick={() => setActiveTab(TABS.ACTIVE)}
            >
              <span className="ml-1 f-14">Active users</span>
            </div>
            <div
              className={`header-btn border-bid rounded-lg h6 ml-1 mr-1 mb-0 cursor-pointer ${
                activeTab === TABS.INACTIVE
                  ? "bg-bid text-white"
                  : "bg-white text-bid"
              }`}
              onClick={() => setActiveTab(TABS.INACTIVE)}
            >
              <span className="ml-1 f-14">Inactive users</span>
            </div>
          </div>

          <div className="d-flex">
            <div
              onClick={() => {
                if (
                  FinalPermissions?.includes(ALL_PERMISSIONS.AFFILIATIONS_ADD)
                ) {
                  setAddUser(true);
                } else {
                  setRequestModal(true);
                  setRequestType(ALL_PERMISSIONS.PRICING_DATABASE_VIEW);
                }
              }}
              className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
              >
                <path
                  d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                  fill="#455E6C"
                />
              </svg>{" "}
              <span className="ml-2 f-14">Add User</span>
            </div>
          </div>
        </div>
        <table className="bid-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Email</th>
              <th>Role</th>
              <th>Created At</th>
              <th>Paid Status</th>
              <th>Hourly Rate</th>
              <th>Package</th>
              <th>Product</th>
              <th>Rate Type</th>
              <th>Package</th>
            </tr>
          </thead>

          <tbody>
            {affiliationsDisplayList?.length === 0 ? (
              <tr>
                <td>No records found</td>
              </tr>
            ) : (
              affiliationsDisplayList?.map((item, i) => (
                <tr key={item?._id}>
                  <td>{i + 1}</td>
                  <td>{item?.mail}</td>
                  <td>{item?.role ?? "N/A"}</td>
                  <td>{item?.createdAt ?? "N/A"}</td>
                  <td>
                    {item?.affiliationPaid === false
                      ? "Not Paid"
                      : "Paid" ?? "N/A"}
                  </td>
                  <td>{item?.hourlyRate ? "$" + item?.hourlyRate : "N/A"}</td>
                  <td>{item?.package ?? "N/A"}</td>
                  <td>{item?.product ?? "N/A"}</td>
                  <td>{item?.rateType ?? "N/A"}</td>
                  <td>{item?.role ?? "N/A"}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <MDBModal
        isOpen={addUser}
        toggle={() => setAddUser(false)}
        centered
        className="delete-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            <p className="delete-modal-label-head">Add Affiliation</p>
          </h5>
          <button
            onClick={() => cancelAddForm()}
            type="button"
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <form>
            <div className="form-group mt-3">
              <label
                htmlFor=""
                className="f-14 inter text-black font-weight-500"
              >
                Email
              </label>
              <input
                value={mail}
                name="parameterName"
                type="text"
                className="form-control"
                onChange={(e) => setMail(e.target.value)}
              />
            </div>
          </form>
        </div>
        {loading ? <Loading color="black" text="Inviting..." /> : null}

        <div className="modal-footer border-none">
          <div className="w-100">
            <div
              onClick={() => {
                handleAddAffiliations();
                eventTrack(
                  "Add-Affiliations-Action",
                  "Add-Affiliations",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
              className="border-bid bg-bid rounded-lg py-2 w-100 text-white text-center inter f-16 font-weight-500 cursor-pointer"
            >
              Add Affiliation
            </div>
            <div
              onClick={cancelAddForm}
              className="bg-white border-bid rounded-lg py-2 w-100 text-bid text-center inter f-16 font-weight-500 mt-3 cursor-pointer"
            >
              Cancel
            </div>
          </div>
        </div>
      </MDBModal>
    </>
  );
}
