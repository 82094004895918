import React, { useContext } from 'react'
import { useAuthAxios } from '../../../store/fetch'
import Button from '../../button'
import { ArrowUp, ArrowDown } from '../../icons'

import styles from './post-vote.module.css'
import { useSelector } from 'react-redux'

const PostVote = ({ score, votes, questionId, answerId, setQuestion }) => {

  const authAxios = useAuthAxios();

  const userInfoRedux = useSelector((s) => s.user.userInfo);


  const isUpVoted = () => {
    return votes.find((v) => v.user === userInfoRedux?.id)?.vote === 1
  }

  const isDownVoted = () => {
    return votes.find((v) => v.user === userInfoRedux?.id)?.vote === -1
  }

  const upVote = async () => {
    const { data } = await authAxios.get(
      `/votes/upvote/${questionId}/${answerId ? answerId : ''}`
    )
    setQuestion(data)
  }

  const downVote = async () => {
    const { data } = await authAxios.get(
      `/votes/downvote/${questionId}/${answerId ? answerId : ''}`
    )
    setQuestion(data)
  }

  const unVote = async () => {
    const { data } = await authAxios.get(
      `/votes/unvote/${questionId}/${answerId ? answerId : ''}`
    )
    setQuestion(data)
  }

  return (
    <div className={styles.voteCell}>
      <Button
        className={styles.voteButton}
        onClick={() =>
          userInfoRedux
            ? isUpVoted()
              ? unVote()
              : upVote()
            : alert('login to vote')
        }
      >
        <ArrowUp className={isUpVoted() ? styles.voted : ''} />
      </Button>
      <div className={styles.score}>{score}</div>
      <Button
        className={styles.voteButton}
        onClick={() =>
          userInfoRedux
            ? isDownVoted()
              ? unVote()
              : downVote()
            : alert('login to vote')
        }
      >
        <ArrowDown className={isDownVoted() ? styles.voted : ''} />
      </Button>
    </div>
  )
}

export default PostVote
