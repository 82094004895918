import React, { useEffect } from "react";
import { MDBBox, MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router";
import moment from "moment";

import { tokenRef, url as urlApi } from "api";
import { viewerSetUrls } from "actions/viewer";

import "./TimelineItemPersonalArea.scss";
import DownloadIcon from "assets/images/download";
import ResizeIcon from "assets/images/resize.svg";
import CollapsibleTable from "components/CollapsibleTable";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import { getMileStones } from "api/milestones/getMilestones";
import { setMilestones } from "actions/user";
import { eventTrack } from "helpers/ga4Helper";

let numeral = require("numeral");

const TimelineItemPersonalArea = (props) => {
  const {
    date,
    excel,
    excelQuant,
    doneBy,
    estimation,
    excelAwp,
    excelAwpInfo,
    excelQuantInfo,
    excelInfo,
    json,
    currencysymb,
    summary,
    zipInfo,
    urls,
    _id,
    milestone,
    milestoneKey,
  } = props.timelineItem;
  const { projectId } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const milestonesRedux = useSelector((s) => s.user.milestones);
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersRedux = useSelector((s) => s.user.users);

  const { request: requestMileStone } = useHttpWithCache({
    requestCallback: () => getMileStones(userInfoRedux.company),
    reduxSetter: (data) => {
      dispatch(setMilestones(data));
    },
    reduxCash: {
      milestones: milestonesRedux,
    },
    reduxReset: () => {
      dispatch(setMilestones(null));
    },
    allowToCash: false,
  });

  useEffect(() => {
    requestMileStone();
  }, []);

  console.log(props.timelineItem)

  //format(`${neededTimeline?.currencysymb} 0,0.00`)
  const token = localStorage.getItem(tokenRef);

  let estimationFormatted;

  if (estimation.indexOf(currencysymb) === 0) {
    estimationFormatted = numeral(estimation.slice(1)).format("0,0.00");
  } else {
    estimationFormatted = numeral(estimation.split(currencysymb)[0]).format(
      "0,0.00"
    );
  }

  function downloadURI(uri, name) {
    fetch(uri).then((response) => response.blob())
      .then((blobresp) => {
        var blob = new Blob([blobresp], { type: "octet/stream" });
        var url = window.URL.createObjectURL(blob);

        var link = document.createElement("a");
        link.download = name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // delete link;
      })
  }

  return (
    <div className="main-bid-card mt-2 min-h-screen">
      <div>
        {estimation ? (
          <MDBBox className="estimation-detail-item">
            {/* {   console.log("inside TimelineItemPersonalArea ",summary)}         */}
            <CollapsibleTable
              data={summary}
              colorClassName={props.colorClassName}
              priceView={true}
              expanded={true}
              currencysymb={currencysymb}
              myClassName="bid-history-table"
            ></CollapsibleTable>
          </MDBBox>
        ) : null}
      </div>

      <div>
        <div className="export-detail-div">
          {zipInfo ? (
            <MDBBox className="font-size-1 ">
              Model exported by:
              <span className="float-right">
                {zipInfo?.exportedBy?.name
                  ? `${zipInfo?.exportedBy?.name} ${zipInfo?.exportedBy?.lastName}`
                  : zipInfo?.exportedBy ?? "N/A"}
              </span>
            </MDBBox>
          ) : null}
          {zipInfo ? (
            <MDBBox className="font-size-1">
              Model Export date:
              <span className="float-right">
                {moment(zipInfo.date).format("MMM Do YY , h:mm:ss a")}{" "}
              </span>
            </MDBBox>
          ) : null}
        </div>

        <div className="estimation-div">
          {doneBy ? (
            <MDBBox className="font-size-1 mb-1">
              Estimated by:
              <span className="float-right">
                {doneBy?.name} {doneBy?.lastName}
              </span>
            </MDBBox>
          ) : null}

          <MDBBox className="font-size-1 mb-3">
            Milestone:
            <span className="float-right">
              {milestoneKey ? milestoneKey : 'N/A'}
            </span>
          </MDBBox>

          <MDBBox>
            {/* {pdf ? (
            <a
              href={`${urlApi}/${pdf}`}
              target="_blank"
              rel="noopener noreferrer"
              download
              className="ml-1 d-flex align-items-center timeline-item-link"
            >
              <DownloadIcon color="#9bcf7e" />
              <span className="ml-1">Full takeoff.pdf</span>
              <span className="ml-2 font-small">
                {(pdfInfo.size / 1000).toFixed(2)}kb
              </span>
            </a>
          ) : null} */}
            {excelQuant ? (
              <a
                target="_blank"
                rel="noopener noreferrer" 
                className="d-flex align-items-center timeline-item-link "
                onClick={() => { downloadURI(`${urlApi}/${excelQuant}`, "Detailed Quantity.xlsx")
                {
                  eventTrack(
                    "Download-Quantity-Action",
                    "Download-Quantity",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }}}
              >
                <span className="excel-link">
                  <DownloadIcon color="#2C3E50" className="pdf-icon" /> Detailed
                  Quantity.xlsx{" "}
                </span>
                <span className="ml-2 font-small float-right">
                  {(excelQuantInfo.size / 1000).toFixed(2)}kb
                </span>
              </a>
            ) : null}
            {excel ? (
              <a
                onClick={() => { downloadURI(`${urlApi}/${excel}`, "Division based estimation.xlsx")
                {
                  eventTrack(
                    "Download-DivisionBasedEstimation-Action",
                    "Download-DivisionBasedEstimation",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }}}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center timeline-item-link "
              >
                <span className="excel-link">
                  <DownloadIcon color="#2C3E50" className="pdf-icon" /> Division
                  based estimation.xlsx{" "}
                </span>
                <span className="ml-2 font-small float-right">
                  {(excelInfo.size / 1000).toFixed(2)}kb
                </span>
              </a>
            ) : null}
            {excelAwp ? (
              <a
               onClick={() => {downloadURI(`${urlApi}/${excelAwp}`, "Trade based estimation.xlsx")
               {
                eventTrack(
                  "Download-TradeBasedEstimation-Action",
                  "Download-TradeBasedEstimation",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center timeline-item-link "
              >
                <span className="excel-link">
                  <DownloadIcon color="#2C3E50" className="pdf-icon" /> Trade
                  based estimation (AWP).xlsx{" "}
                </span>
                <span className="ml-2 font-small float-right">
                  {(excelAwpInfo.size / 1000).toFixed(2)}kb
                </span>
              </a>
            ) : null}
            <MDBBox className="font-size-1">
              {" "}
              <p></p>
            </MDBBox>
          </MDBBox>
        </div>
      </div>

      <div className="d-flex" style={{ minWidth: "300px" }}>
        <MDBBox className="bid-date-container">
          <div className="month-text">{moment(date).format("Do")}</div>
          <div className="month-text">{moment(date).format("MMMM")}</div>
          <div className="time-text">{moment(date).format("h:mm a")}</div>
          <div className="time-text">{moment(date).format("YYYY")}</div>
        </MDBBox>
        <div className="bid-viewModal-container">
          <Link
            to={`${url}/${_id}`}
            onClick={() => {
              if (urls) {
                console.log("urls value ", urls);
                console.log("urls value ", json);
                dispatch(viewerSetUrls({ json }));
              }
            }}
            className="w-100 h-100 d-flex align-items-center"
          >
            <div className="view-modaltext">
              <MDBIcon icon="eye" className="mr-1" />
              View model
              <img src={ResizeIcon} className="resizer-icon" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TimelineItemPersonalArea;
