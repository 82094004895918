import React, { useState, useRef, useEffect } from "react";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBIcon,
  MDBCardFooter,
  MDBBox,
} from "mdbreact";

import { useHistory, useLocation } from "react-router-dom";
import { url } from "api";
import formatTime from "helpers/formatTime";

import "../ProjectItemAdmin/ProjectItem.scss";
import moment from "moment";
import { eventTrack } from "helpers/ga4Helper";

import { useHttp } from "hooks/useHttp";
import { editProjectPut } from "api/projects/updateProject";
import addNotification from "helpers/notify";
import { adminEditProject } from "actions/admin";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../ui-components/loading";

import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { setFeatureRequest, setProjects } from "actions/user";
import { Modal } from "antd";
import { RequestCard } from "pages/configuration/requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import DefaultImage from "assets/images/Default_Project_Image.png";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import { uploadFileToServer } from "components/uploadFile/uploadFile";

import FULL_BATTERY_IMG from "assets/images/full.jpg";
import THREE_QUARTER_BATTERY_IMG from "assets/images/three-quarter.jpg";
import HALF_BATTERY_IMG from "assets/images/half.jpg";
import ONE_THIRD_BATTERY_IMG from "assets/images/one-third.jpg";
import EMPTY_BATTERY_IMG from "assets/images/empty.jpg";

let numeral = require("numeral");
const ProjectItemPersonalArea = (props) => {
  const {
    img, 
    title,
    favourite,
    description,
    date,
    _id,
    timelines,
    user,
    zipInfo,
    isParent,
    parentProjectID,
    projectNumber,
    milestone,
  } = props.projectItem;

  const { isAdmin } = props;

  const { idUser } = props;
  const projectItemState = useRef({ img, title, description, _id });

  const dispatch = useDispatch();
  const history = useHistory();
  const imageRef = useRef();
  const location = useLocation();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const featureRequestRedux = useSelector((s) => s.user.featureRequest);
  const projectsRedux = useSelector((s) => s.user.projects);
  const usersRedux = useSelector((s) => s.user.users);
  const milestonesRedux = useSelector((s) => s.user.milestones);

  const [requestType, setRequestType] = useState("");
  const [requestModal, setRequestModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [projectPicture, setProjectPicture] = useState(null);

  const changeProject = (prop, payload) => {
    projectItemState.current = { ...projectItemState.current, [prop]: payload };
  };
  const editProjectForm = new FormData();

  const editProjectField = (field) => {
    editProjectForm.append(field, projectItemState.current[field]);
  };

  const { loading, request: editProjectAPI } = useHttp({
    requestCallback: editProjectPut,
    onLoad: (res) => {
      const cloneProjectArr = [...projectsRedux];
      const i = cloneProjectArr.findIndex((x) => x._id === res._id);
      cloneProjectArr[i] = res;

      addNotification("Project updated successfully", "success");
      dispatch(setProjects(cloneProjectArr));
      if (res.img !== props.img) {
        imageRef.current.src = `${res.img}`;
      }
      dispatch(adminEditProject(res));
    },
    onError: (res) => {
      // addNotification(res.message, "danger");
    },
  });

  const editProject = () => {
    editProjectField("_id", _id);
    editProjectField("title", title);
    editProjectField("description", description);
    editProjectField("img", img);

    return editProjectAPI({
      id: projectItemState.current._id,
      body: editProjectForm,
    });
  };

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (img?.split(".").pop() === "gz") {
        const extractedImg = await extractImageFromZip(img);
        if (isMounted) {
          setProjectPicture(extractedImg);
        }
      } else {
        if (isMounted) {
          setProjectPicture(DefaultImage);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [img, projectsRedux]);

  const toggleFavorite = (value) => {
    editProjectField("_id", _id);
    return editProjectAPI({
      _id: projectItemState.current._id,
      favourite: !value,
    });
  };

  const isIfcProject = props.projectItem.isIfcProject;

  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const requestAccess = async () => {
    try {
      setRequestLoading(true);
      const res = await createFeatureRequest(userInfoRedux?.id, {
        companyId: userInfoRedux.company,
        permission: requestType,
        userId: userInfoRedux.id,
      });
      if (res) {
        setRequestLoading(false);
        dispatch(
          setFeatureRequest(res?.map((feature) => feature?.requestedFeature))
        );
      }
    } catch (e) {
      setRequestLoading(false);
    }
  };

  const handleImageChange = async (file) => {
    const fileUrl = await uploadFileToServer(file);
    editProjectField("_id", _id);
    return editProjectAPI({
      _id: projectItemState.current._id,
      img: fileUrl,
    });
  };

  return (
    <MDBCol className={props.className}>
      <MDBCard className="h-100">
        <div
          className={`project-item-img ${
            isIfcProject ? "ifcIcon" : "revitIcon"
          }`}
        >
          <img
            src={projectPicture}
            alt={`Project ${title}`}
            ref={imageRef}
            onError={(e) => (e.target.src = DefaultImage)}
          />

          <div className="project-card-fav cursor-pointer">
            {loading ? (
              <i className="fa fa-spinner small-spinner" />
            ) : (
              <div onClick={() => { toggleFavorite(favourite)
                {
                  eventTrack(
                    "Toggle-Fav-Action",
                    "Toggle-Fav",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }
              }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <g clipPath="url(#clip0_426_6573)">
                    <rect
                      width="18"
                      height="18"
                      transform="translate(0.455078 0.45459)"
                      fill="white"
                    />
                    <path
                      d="M9.45602 14.1496L4.16593 17.1107L5.34743 11.1645L0.896484 7.04844L6.91676 6.33463L9.45602 0.82959L11.9952 6.33463L18.0155 7.04844L13.5646 11.1645L14.7461 17.1107L9.45602 14.1496Z"
                      fill={favourite ? "#FFBF1B" : "#ddd"}
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_426_6573">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.455078 0.45459)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
          </div>
          <span className="project-item-date">{formatTime(date)}</span>
        </div>
        <MDBCardBody className="p-3 position-relative">
          {/* {loading ? <Loading color="dark" text="Editing..." /> : null} */}
          <MDBBox className="upload-project-card-img">
            <input
              style={{ display: "none" }}
              type="file"
              name="file_image"
              className="file-input"
              id="input_image"
              required
              accept={".jpeg,.png,.jpg,.png"}
              disabled={loading}
              ref={hiddenFileInput}
              onChange={(e) => {
                handleImageChange(e.target.files[0]);
              }}
            />
            {isAdmin ? (
              <MDBBtn
                size="sm"
                color=""
                onClick={() => { handleClick()
                  {
                    eventTrack(
                      "Upload-Project-Image-Action",
                      "Upload-Project-Image",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
                disabled={loading}
              >
                {loading ? (
                  <i className="fa fa-spinner small-spinner" />
                ) : (
                  <MDBIcon icon="camera" className="font-size-1" />
                )}
              </MDBBtn>
            ) : null}
          </MDBBox>

          <MDBBox className="font-size-1 text-bid capitalize d-flex align-items-center battery-container battery-card-container">
            {/* Project completion:{" "} */}
            {Number(
              milestonesRedux?.find((item) => item?._id === milestone)?.value
            ) == 0 ? (
              <img
                src={EMPTY_BATTERY_IMG}
                alt="FULL_BATTERY_IMG"
                title={`${
                  milestonesRedux?.find((item) => item?._id === milestone)
                    ?.value
                }${"%"}`}
              />
            ) : Number(
                milestonesRedux?.find((item) => item?._id === milestone)?.value
              ) <= 25 &&
              Number(
                milestonesRedux?.find((item) => item?._id === milestone)?.value
              ) > 0 ? (
              <img
                src={ONE_THIRD_BATTERY_IMG}
                alt="FULL_BATTERY_IMG"
                title={`${
                  milestonesRedux?.find((item) => item?._id === milestone)
                    ?.value
                }${"%"}`}
              />
            ) : Number(
                milestonesRedux?.find((item) => item?._id === milestone)?.value
              ) <= 50 &&
              Number(
                milestonesRedux?.find((item) => item?._id === milestone)?.value
              ) >= 26 ? (
              <img
                src={HALF_BATTERY_IMG}
                alt="FULL_BATTERY_IMG"
                title={`${
                  milestonesRedux?.find((item) => item?._id === milestone)
                    ?.value
                }${"%"}`}
              />
            ) : Number(
                milestonesRedux?.find((item) => item?._id === milestone)?.value
              ) <= 75 &&
              Number(
                milestonesRedux?.find((item) => item?._id === milestone)?.value
              ) >= 51 ? (
              <img
                src={THREE_QUARTER_BATTERY_IMG}
                alt="FULL_BATTERY_IMG"
                title={`${
                  milestonesRedux?.find((item) => item?._id === milestone)
                    ?.value
                }${"%"}`}
              />
            ) : Number(
                milestonesRedux?.find((item) => item?._id === milestone)?.value
              ) <= 100 &&
              Number(
                milestonesRedux?.find((item) => item?._id === milestone)?.value
              ) >= 76 ? (
              <img
                src={FULL_BATTERY_IMG}
                alt="FULL_BATTERY_IMG"
                title={`${
                  milestonesRedux?.find((item) => item?._id === milestone)
                    ?.value
                }${"%"}`}
              />
            ) : null}
          </MDBBox>

          <MDBCardTitle className="mb-1 font-size-2">{title}</MDBCardTitle>
          {timelines && timelines[0] && timelines[0].estimation ? (
            <MDBBox className="card-price font-size-2 mb-1 text-bid">
              {numeral(timelines[timelines.length - 1].estimation).format(
                `${timelines[timelines.length - 1]?.currencysymb} 0,0.00`
              )}
            </MDBBox>
          ) : null}

          <MDBCardText className="capitalize text-bid">
            {description}
          </MDBCardText>
          <MDBBox className="font-size-1 text-bid capitalize">
            Project Number: {projectNumber ? projectNumber : "N/A"}
          </MDBBox>
          {zipInfo ? (
            <MDBBox className="">
              <hr />
              <div className="modelDiv">
                <MDBCardTitle className="mb-1 font-size-2">Model</MDBCardTitle>
                {props.projectItem.isIfcProject ? (
                  <div>
                    <button
                      className="bg-white text-bid border-bid shadow-none text-style-inherit"
                      onClick={props.openUpdateModal}
                    >
                      <small>update </small>
                      <MDBIcon fas icon="upload" />
                    </button>
                  </div>
                ) : null}
              </div>
              {zipInfo ? (
                <MDBBox className="font-size-1 text-bid">
                  Exported by:{" "}
                  {zipInfo?.exportedBy?.name
                    ? `${zipInfo?.exportedBy.name} ${zipInfo?.exportedBy.lastName}`
                    : zipInfo?.exportedBy ?? "N/A"}
                </MDBBox>
              ) : null}

              {zipInfo ? (
                <MDBBox className="font-size-1 text-bid">
                  Date: {moment(zipInfo.date).format("MMM Do YY , h:mm:ss a")}{" "}
                </MDBBox>
              ) : null}

              {/*<MDBBox className="font-weight-light font-size-1 mb-1">Exported by   { zipInfo.exportedBy} </MDBBox>*/}
            </MDBBox>
          ) : null}
        </MDBCardBody>
        <MDBCardFooter className="pl-2 pr-2 pb-2 pt-0">
          <div className="d-flex align-items-center  justify-content-between">
            {idUser === user || { isAdmin } ? (
              <MDBBtn
                color=""
                className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit "
                onClick={() => {
                  if (
                    FinalPermissions?.includes(
                      ALL_PERMISSIONS.SHARE_PROJECT_ADD
                    )
                  ) {
                    props.openShareModal();
                  } else {
                    setRequestType(ALL_PERMISSIONS.SHARE_PROJECT_ADD);
                    setRequestModal(true);
                  }
                  {
                    eventTrack(
                      "Project-Access-Action",
                      "Project-Access",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }
                }}
              >
                Access
              </MDBBtn>
            ) : null}

            <MDBBtn
              color=""
              className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit"
              onClick={() => {
                if (
                  FinalPermissions?.includes(ALL_PERMISSIONS.ASSIGN_PROJECT_ADD)
                ) {
                  props.openMembersModal();
                } else {
                  setRequestType(ALL_PERMISSIONS.ASSIGN_PROJECT_ADD);
                  setRequestModal(true);
                }
                {
                  eventTrack(
                    "Project-Members-Action",
                    "Project-Members",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }
              }}
            >
              Members
            </MDBBtn>

            {isParent && (
              <MDBBtn
                color=""
                className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit "
                onClick={() => {
                  history.push(`./personal-area/projects/${_id}/timelines`);
                  {
                    eventTrack(
                      "View-SubProjects-Action",
                      "View-History",
                      title,
                      _id,
                      false,
                      title
                    );
                  }
                }}
              >
                Projects
              </MDBBtn>
            )}

            {FinalPermissions?.includes(ALL_PERMISSIONS.ESTIMATION_VIEW) ? (
              !isParent && (
                <MDBBtn
                  color=""
                  className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit "
                  onClick={() => {
                    if (!isParent && parentProjectID != "") {
                      history.push(`../${_id}`);
                    } else {
                      history.push(`./personal-area/projects/${_id}/timelines`);
                    }
                    {
                      eventTrack(
                        "View-History-Action",
                        "View-History",
                        title,
                        _id,
                        false,
                        title
                      );
                    }
                  }}
                >
                  History ({timelines?.length})
                </MDBBtn>
              )
            ) : (
              <MDBBtn
                color=""
                className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit "
                onClick={() => {
                  setRequestType(ALL_PERMISSIONS.ESTIMATION_VIEW);
                  setRequestModal(true);
                }}
              >
                History
              </MDBBtn>
            )}

            {zipInfo?.paths.length > 0 && (
              <MDBBtn
                color=""
                className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit "
                onClick={() => {
                  history.push(`./personal-area/projects/model-view/${_id}`);
                  {
                    eventTrack(
                      "View-Model-Action",
                      "View-Model",
                      title,
                      _id,
                      false,
                      title
                    );
                  }
                }}
              >
                Model
              </MDBBtn>
            )}

            {idUser === user || { isAdmin } ? (
              <button
                color="danger"
                className="bg-white dlt-btnFlex text-bid border-bid shadow-none text-style-inherit"
                onClick={props.openDeleteModal}
              >
                <MDBIcon icon="trash-alt" size="lg" />
              </button>
            ) : null}
          </div>
        </MDBCardFooter>
      </MDBCard>

      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequestRedux?.includes(requestType)}
          loading={requestLoading}
          icon={
            featureRequestRedux?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequestRedux?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequestRedux?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </MDBCol>
  );
};

export default ProjectItemPersonalArea;
