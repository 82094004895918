import {
  MAIN_ADMIN_SET_COMPANIES,
  MAIN_ADMIN_SET_NEW_DATE_FOR_COMPANY,
} from '../constants/action-types/main-admin';

const initialState = {
  company: null,
  company_projects: null,
  all_users: null,
};

const mainAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAIN_ADMIN_SET_COMPANIES:
      return { ...state, company: action.payload };
    case 'MAIN_ADMIN_SET_ALL_COMPANIES_PROJECTS':
      return { ...state, company_projects: action.payload };
    case 'MAIN_ADMIN_SET_ALL_USERS':
      return { ...state, all_users: action.payload };
    case MAIN_ADMIN_SET_NEW_DATE_FOR_COMPANY:
      const neededCompanyIndex = state.company.findIndex(
        companyItem => companyItem._id === action.payload._id,
      );
      return {
        ...state,
        company: [
          ...state.company.slice(0, neededCompanyIndex),
          action.payload,
          ...state.company.slice(neededCompanyIndex + 1),
        ],
      };

    default:
      return state;
  }
};

export default mainAdminReducer;
