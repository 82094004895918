import React, { useState } from "react";
import HealthIcon from "assets/images/icons/Health (1).png";
import EyeImg from "assets/images/icons/eye-fill.png";
import DeleteImg from "assets/images/icons/delete-bin-6-fill.png";
import { useHistory } from "react-router-dom";
import { url } from "api";
import { useDispatch, useSelector } from "react-redux";
//
import { Modal } from "antd";
import { RequestCard } from "pages/configuration/requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { setAllHealthReports, setFeatureRequest } from "actions/user";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";
import { deleteReport } from "api/healthCheck/deleteReport";
import addNotification from "helpers/notify";
import Loading from "components/ui-components/loading";
import moment from "moment";
import DefaultImage from "assets/images/user.png";
import { eventTrack } from "helpers/ga4Helper";

export default function HealthCheckCardView({ data }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const featureRequestRedux = useSelector((s) => s.user.featureRequest);
  const healthReportsRedux = useSelector((s) => s.user.healthReports);
  const [requestType, setRequestType] = useState("");
  const [requestModal, setRequestModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [reportsToDelete, setReportsToDelete] = useState(null);

  const STATUS = {
    PASS: "Pass",
    FAILED: "Fail",
    GREAT: "Great",
  };

  const deletePopupOpen = (item) => {
    setReportsToDelete(item);
    setOpenDeleteModal(true);
  };

  const handleDeleteReport = async () => {
    setLoading(true);
    const res = await deleteReport({ _id: reportsToDelete?._id });
    if (!res.error) {
      const filterResponse = healthReportsRedux?.filter(
        (item) => item?._id !== res._id
      );
      dispatch(setAllHealthReports(filterResponse));
      setLoading(false);
      setOpenDeleteModal(false);
      addNotification("Report Deleted Successfully! ", "success");
    } else {
      addNotification(res?.message, "danger");
    }
  };

  const requestAccess = async () => {
    setLoading(true);
    const res = await createFeatureRequest(userInfoRedux.id, {
      companyId: userInfoRedux.company,
      permission: requestType,
      userId: userInfoRedux.id,
    });
    if (res.status === 200) {
      setLoading(false);
      dispatch(
        setFeatureRequest(res?.map((feature) => feature?.requestedFeature))
      );
    }
  };

  return (
    <div className="container">
      {/* page title */}
      {/* <div className="w-100 rounded-2 py-1 px-2 border bg-white d-flex align-items-center">
        <img src={HealthIcon} alt="img" className="mr-2 title-icon" />
        <p className="f-16 text-bid mb-0 poppins-medium">
          Health Check Report ({data?.length})
        </p>
      </div> */}
      <div className="health-overflow-div">
        {/* cards */}
        {data?.length > 0 ? (data?.map((item, key) => (
          <div className="health-cards mt-2" key={key}>
            {/* linked project */}
            <div className="linked-projects">
              <div className="d-flex align-items-center justify-content-between mb-3 pr-3">
                <p className="mb-0 f-12 poppins-medium">Linked Projects</p>
                <p className="number-count bg-light-bid text-bid">
                  {item?.projects?.length}
                </p>
              </div>
              <div className="scrollable-div">
                {item?.projects?.map((proj, innKey) => (
                  <div className="d-flex justify-content-between mb-2" key={innKey}>
                    <img src={`${url}/${proj?.img}`} alt="img" />
                    <p className="mb-0 f-12 poppins-medium text-black">
                      {proj?.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {/* exported by */}
            <div className="linked-projects">
              <div className="d-flex align-items-center justify-content-between mb-3 pr-3">
                <p className="mb-0 f-12 poppins-regular">Exported By</p>
                <div className="d-flex">
                  <img
                    src={`${url}/${item?.doneBy?.profilePhoto}`}
                    onError={(e) => (e.target.src = DefaultImage)}
                    className="list-user-img"
                  />
                  <p className="mb-0 f-12 ml-2 poppins-regular text-black">
                    {item?.doneBy?.name}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 pr-3">
                <p className="mb-0 f-12 poppins-regular">Health Status</p>
                <div className="d-flex">
                  <p className="number-count bg-light-success text-success mr-2">
                    {item?.reportDetails?.passedRules?.length}
                  </p>
                  <p className="number-count bg-light-warning text-warning mr-2">
                    {item?.summary?.healthStatus?.normalFailedRules}
                  </p>
                  <p className="number-count bg-light-danger text-danger">
                    {item?.summary?.healthStatus?.criticalFailedRules}
                  </p>
                </div>
              </div>
              <div className="mt-3 pr-3">
                <p className="mb-2 f-10">Health Criteria</p>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="mb-0 f-12 poppins-medium text-black">
                    Critical rules
                  </p>
                  <p className="number-count bg-light-bid text-bid">
                    {item?.summary?.criticalRules}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="mb-0 f-12 poppins-medium text-black">
                    Normal rules
                  </p>
                  <p className="number-count bg-light-bid text-bid">
                    {item?.summary?.normalRules}
                  </p>
                </div>
              </div>
            </div>
            {/* Report Overview */}
            <div className="linked-projects">
              <div className="d-flex align-items-center justify-content-between mb-3 pr-3">
                <p className="mb-0 f-12 poppins-regular">Report Overview</p>
              </div>
              <div className="scrollable-div">
                {Object.entries(item?.healthDetailMeta).map(
                  ([key, value], i) => (
                    <div className="d-flex justify-content-between mb-2" key={key}>
                      <p className="mb-0 f-12 poppins-regular">{key}</p>
                      <p className="mb-0 f-12 poppins-medium text-black">
                        {value}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Last coloumn */}
            <div className="d-flex align-items-center justify-content-between p-2 border-none">
              <div>
                <div className="rounded-3 bg-light-bid p-2 px-2 text-center">
                  <p className="text-bid poppins-medium mb-0 f-12">
                    Report Date
                  </p>
                  <p className="text-bid poppins-medium mb-0 f-14 font-weight-bold">
                    {/* Dec 5, 2023 */}
                    {moment(item?.date).format("DD MMMM YYYY")}
                  </p>
                  <p className="text-bid poppins-medium mb-0 f-12">
                    {moment(item?.date).format("hh:mm a")}
                  </p>
                </div>
                <div
                  className={`${"rounded-3 py-4 px-2 text-center mt-2"}
              ${
                item?.summary?.reportStatus === STATUS.PASS
                  ? "bg-light-warning text-warning"
                  : item?.summary?.reportStatus === STATUS.FAILED
                  ? "bg-light-danger text-danger"
                  : item?.summary?.reportStatus === STATUS.GREAT
                  ? "bg-light-success text-success"
                  : ""
              }
              `}
                >
                  <p className="poppins-medium mb-0 h4">
                    {item?.summary?.reportStatus}
                  </p>
                </div>
              </div>
              <div className="h-100 text-center p-3 d-flex align-items-center">
                <div
                  onClick={() => {
                    history.push(`./model-health/${item?._id}/`);
                    eventTrack(
                      "View-Detailed-Health-Report-Action",
                      "View-Detailed-Health-Report",
                      userInfoRedux?.name,
                      "+1",
                      false,
                      "+1"
                    );
                  }}
                  className="cursor-pointer"
                >
                  <img src={EyeImg} alt="img" />
                  <p className="text-bid poppins-medium mb-0 f-10 mt-2">
                    View Complete <br /> Report
                  </p>
                </div>
              </div>
              <div className="h-100 text-center  p-3 d-flex align-items-center">
                <div
                  onClick={() => {
                    FinalPermissions?.includes(
                      ALL_PERMISSIONS.MODAL_HEALTH_REPORT_DELETE
                    )
                      ? deletePopupOpen(item)
                      : setRequestModal(true);
                    setRequestType(ALL_PERMISSIONS.MODAL_HEALTH_REPORT_DELETE);
                  }}
                  className="cursor-pointer"
                >
                  <img src={DeleteImg} alt="img" />
                  <p className="text-bid poppins-medium mb-0 f-10 mt-2">
                    Delete
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))) : <p className="m-2">No record found</p>}
      </div>

      {/* delete modal check */}
      <MDBModal
        isOpen={openDeleteModal}
        toggle={() => setOpenDeleteModal(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpenDeleteModal(false)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Report ?</p>
          <p className="delete-modal-label">
            Are you sure you want to delete this Report? This action cannot be
            undone.
          </p>
        </MDBModalBody>
        {loading ? <Loading color="black" text="Deleting..." /> : null}
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpenDeleteModal(false);
                setReportsToDelete("");
              }}
              color=""
              disabled={loading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={() => {handleDeleteReport()
                eventTrack(
                  "Delete-Health-Report-Action",
                  "Delete-Health-Report",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
              disabled={loading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      {/* request Access Modal */}
      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequestRedux?.includes(requestType)}
          loading={loading}
          icon={
            featureRequestRedux?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequestRedux?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequestRedux?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </div>
  );
}
