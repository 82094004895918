import { ADMIN_SET_NEW_ADMIN } from '../constants/action-types/admin';

import {
  MAIN_ADMIN_SET_NEW_DATE_FOR_COMPANY,
  MAIN_ADMIN_SET_COMPANIES,
} from '../constants/action-types/main-admin';

export const mainAdminSetCompanies = companies => {
  return {
    type: MAIN_ADMIN_SET_COMPANIES,
    payload: companies,
  };
};

export const mainAdminSetNewAdmin = newAdmin => {
  return {
    type: ADMIN_SET_NEW_ADMIN,
    payload: newAdmin,
  };
};

export const mainAdminSetNewDateForCompany = newCompany => {
  return {
    type: MAIN_ADMIN_SET_NEW_DATE_FOR_COMPANY,
    payload: newCompany,
  };
};
