import { get, globalPut } from '../index';

export const getCompanySettings = (companyID) => {
  return get(`/company-settings?companyId=${companyID}`);
};


export const getCompanySettingsAdmin = () => {
  return get(`/company`);
};

export const putCompanySettings = (data) => {
  return globalPut(`/company`, data);
};
