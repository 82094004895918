import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "react-input-range/lib/css/index.css";
import {
  FilledProfileIcon,
  SortIcon,
  RefreshIcon,
  RightArrowIcon,
  ListIcon,
  FilterIcon,
} from "assets/svg-icons";
import { MDBDropdownToggle, MDBDropdownMenu, MDBDropdown } from "mdbreact";
import handleBuildTree from "helpers/buildTree";
import useDebounce from "hooks/useDebounce";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { useDispatch } from "react-redux";
import { setExpandedCatalogsDivisons } from "actions/pricing";
import { setSidebarStatus } from "actions/user";
import Select from "react-select";
import { mainAdminGetCompanies } from "api/users/mainAdminGetCompanies";
import { useHttp } from "hooks/useHttp";
import { eventTrack } from "helpers/ga4Helper";

export const PricingCatalogHeader = ({
  setSearchedCatalogs,
  catalogs: incomingCatalogs,
  selectedTemplate,
  setIsLoading,
  refresh,
  setSelectedCompany
}) => {
  const [selectedView, setSelectedView] = useState(2);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const catalogs = useSelector((state) => state.user.catalogs);
  const catalogsTree = useSelector((state) => state.user?.catalogsTree);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const debounceSearch = useDebounce(searchValue, 500);

  const { role, admin } = userInfoRedux;

  const [sortOrder, setSortOrder] = useState("desc");
  const [companiesList, setCompaniesList] = useState([]);

  let allMatchingItems = [];
  const searchInRow = (item) => {
    if (
      item.Description.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.Division.toLowerCase().includes(searchValue.toLowerCase())
    )
      allMatchingItems = [...allMatchingItems, item];
    if (item.Children.length) {
      item.Children.forEach((x) => searchInRow(x));
    }
    return allMatchingItems;
  };

  const findAllParentsOfSelectedItems = (selectedItems) => {
    const allParents = [];
    for (const item of selectedItems) {
      let parent = item?.Parent;

      while (parent) {
        allParents.push(parent);
        parent = parent.Parent;
      }
    }
    return allParents;
  };

  const handleSearchCatalogs = () => {
    if (catalogsTree?.length) {
      setIsLoading("table-data");
      const searchedCatalogRows =
        catalogsTree?.map((row) => searchInRow(row)).flat(1) ?? [];
      const parentIds = findAllParentsOfSelectedItems(searchedCatalogRows).map(
        (x) => x._id
      );
      const matchedItems = [
        ...searchedCatalogRows.map((x) => x._id),
        ...parentIds,
      ];
      const uniqueItemIds = [...new Set(matchedItems)];
      const matchedCatalogs =
        catalogs?.filter((catalog) =>
          uniqueItemIds?.includes(catalog?.company?._id)
        ) ?? [];
      if (searchValue.length > 0) {
        dispatch(
          setExpandedCatalogsDivisons(
            matchedCatalogs?.map((item) => item?.company?.Code)
          )
        );
      } else {
        dispatch(setExpandedCatalogsDivisons([]));
      }

      const searchedCatalogTree = handleBuildTree(
        matchedCatalogs,
        admin,
        selectedTemplate?.company?.Protocol
      );
      setSearchedCatalogs(searchedCatalogTree.Children);
      setTimeout(() => {
        setIsLoading("");
      }, 1000);
    }
  };

  useEffect(() => {
    handleSearchCatalogs();
  }, [debounceSearch]);

  const handleSortCatalogs = () => {
    const sortedArray = [...incomingCatalogs].sort((a, b) => {
      const divisionA = a.Division.toUpperCase();
      const divisionB = b.Division.toUpperCase();
      if (sortOrder === "asc") {
        return divisionA.localeCompare(divisionB, undefined, { numeric: true });
      } else {
        return divisionB.localeCompare(divisionA, undefined, { numeric: true });
      }
    });
    setSearchedCatalogs(sortedArray);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const { loading: companiesListLoading, request: getAllCompaniesListAPI } =
    useHttp({
      requestCallback: mainAdminGetCompanies,
      onLoad: (res) => {
        console.log(
          res?.company?.map((item) => {
            return { label: item?.companyName, value: item?._id };
          })
        );
        setCompaniesList(
          res?.company?.map((item) => {
            return { label: item?.companyName, value: item?._id };
          })
        );
      },
      onError: (res) => {},
    });

  useEffect(() => {
    getAllCompaniesListAPI();
  }, []);

  return (
    <div className="inner-header-position">
      <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
        {/* <div className="d-flex align-items-center">
          <div
            className="cursor-pointer"
            onClick={() => {
              history.goBack();
            }}
          >
            <RightArrowIcon />
          </div>
          <p className="my-heading ml-5">Pricing Catalogs</p>
        </div> */}
        <div className="d-flex align-items-center">
          <div
            onClick={() => dispatch(setSidebarStatus(true))}
            className="cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>
          </div>
          <p className="my-heading f-14 ml-3 mr-3">Pricing Catalogues</p>

          {admin && <Select
            onChange={(e) => setSelectedCompany(e)}
            options={companiesList}
            isDisabled={companiesList.length === 0}
            className="header-select"
            // classNamePrefix="header-select"
            styles={{ minWidth: "250px", width: "250px" }}
            isClearable
            // styles={{ menuPortal: (base) => ({ ...base, width: '150px' }) }}
          />}
        </div>

        <div
          className="projActionButtons d-flex flex-wrap"
          style={{ maxWidth: "100vw" }}
        >
          <input
            type="text"
            className="search-bar"
            disabled={!catalogs}
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="Search"
          />
          <div className="btn-tool-group">
            {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
              <>
                <div className="vr mx-3"></div>
                <button
                  onClick={() => {
                    if (
                      FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW)
                    ) {
                      history.push(`/admin/projects-settings`);
                    } else if (
                      FinalPermissions?.includes(ALL_PERMISSIONS.MILESTONE_VIEW)
                    ) {
                      history.push(`/admin/milestones`);
                    } else {
                      history.push(`/user_permissions`);
                    }
                  }}
                  className="bg-bid px-3 mr-0"
                >
                  <FilledProfileIcon />
                  Admin
                </button>
                <div className="vr mx-3"></div>
              </>
            )}

            <button
              onClick={() => { handleSortCatalogs ()
                {
                  eventTrack(
                    "Sort-Catelogue-Action",
                    "Sort-Catelogue",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  );
                }
              }}
              className="px-3 bg-white text-bid shadow-hover"
            >
              <div className={`${sortOrder === "asc" && "upsideDown-svg"}`}>
                <SortIcon />
              </div>
              Sort
            </button>

            <button
              className="px-3 bg-white text-bid shadow-hover"
              onClick={refresh}
            >
              <RefreshIcon />
              Refresh
            </button>

            <div className="vr mx-2"></div>

            <div className="d-flex view-switch-container">
              <div
                onClick={() => setSelectedView(2)}
                className={selectedView === 2 && "active"}
              >
                <ListIcon />
              </div>
            </div>
            <div className="vr mx-2"></div>

            <div className="head-filter-icon">
              <MDBDropdown>
                <MDBDropdownToggle
                  style={{ backgroundColor: "white" }}
                  color="link"
                >
                  <FilterIcon />
                </MDBDropdownToggle>
                <MDBDropdownMenu right style={{ width: "200px" }}>
                  <div className="card">
                    <div className="card-body">
                      <div className="p-2">
                        <input
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Keyword"
                        />
                      </div>
                    </div>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
