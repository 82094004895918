import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./resizable-viewer.scss";

export default class ModelResizableViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDragging: false,
      panels: [0, 300, 300],
    };
  }

  startResize = (event, index) => {
    if (!this.props.allowToResize) {
    } else {
      this.setState({
        isDragging: true,
        currentPanel: index,
        initialPos: event.clientX,
      });
    }
  };

  stopResize = () => {
    if (this.state.isDragging) {
      this.setState(
        ({ panels, currentPanel, delta }) => {
          return {
            isDragging: false,
            panels: {
              ...panels,
              [currentPanel]: (panels[currentPanel] || 0) + delta,
              [currentPanel + 1]: (panels[currentPanel + 1] || 0) - delta,
            },
            delta: 0,
            currentPanel: null,
          };
        },
        () => this.props.WebGlApp.viewer.onWindowResize()
      );

      this.props.setResize(this.state);
    }
  };

  resizePanel = (event) => {
    if (this.state.isDragging) {
      const delta = event.clientX - this.state.initialPos;
      this.setState({ delta });
    }
  };

  componentDidMount() {
    this.props.setResize(this.state);
    ReactDOM.findDOMNode(this).addEventListener("mousemove", this.resizePanel, {
      passive: true,
    });
    ReactDOM.findDOMNode(this).addEventListener("mouseup", this.stopResize, {
      passive: true,
    });
    ReactDOM.findDOMNode(this).addEventListener("mouseleave", this.stopResize, {
      passive: true,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.WebGlApp) {
      this.props.WebGlApp.viewer.onWindowResize();
    }
  }

  render() {
    const rest = this.props.children.slice(0, 2);

    let widthForViewer = `calc(100%   - ${this.state.panels[1]}px - ${this.state.panels[2]}px)`;

    if (!this.props.isSecondScreen) {
      widthForViewer = `calc(100%   - ${this.state.panels[1]}px) `;
    }

    if (!this.props.isFirstScreen) {
      widthForViewer = `calc(100%    - ${this.state.panels[2]}px)`;
    }

    if (!this.props.isFirstScreen && !this.props.isSecondScreen) {
      widthForViewer = `100% `;
    }

    return (
      <div
        className="resize-panel-container"
        onMouseUp={() => this.stopResize()}
      >
        {[].concat(
          ...rest.map((child, i) => {
            if (
              (!this.props.isSecondScreen && i === 1) ||
              (!this.props.isFirstScreen && i === 0)
            ) {
              return [];
            }

            return [
              <div
                key={"panel" + i}
                className="resize-panel"
                style={{ width: `${this.state.panels[i + 1]}px` }}
              >
                {child}
              </div>,
              <div
                onMouseDown={(e) => this.startResize(e, i + 1)}
                key={"resizer_" + i}
                style={
                  this.state.currentPanel === i + 1
                    ? { left: this.state.delta }
                    : {}
                }
                className="resizer"
              ></div>,
            ];
          })
        )}
        <div className="resize-panel" style={{ width: widthForViewer }}>
          {this.props.children[2]}
        </div>
      </div>
    );
  }
}
