import React, { useState, useEffect } from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { Modal, Button } from "antd";
import { DownArrowIcon } from "assets/svg-icons";
import { useSelector } from "react-redux";
import { addNewCatalogs, getCategories, updateCatalogs } from "api/database";
import addNotification from "helpers/notify";
import { useHttp } from "hooks/useHttp";
import useDebounce from "hooks/useDebounce";
import { clearCategories, setCategories } from "actions/pricing";
import { useDispatch } from "react-redux";
import { formats, unitsData } from "constants/utils";

export const NewCatalogModal = ({
  closeModal,
  handleNewCatalogModal,
  appliedTemplate,
  newDivision,
  conversionRate,
  parentCatalog,
  isEdited,
  setIsLoading,
  onChangeTemplate,
  selectedCompany,
}) => {
  const [description, setDescription] = useState(
    isEdited ? parentCatalog?.Description : ""
  );
  const [unit, setUnit] = useState(isEdited ? parentCatalog?.Unit : "");
  const [unitPrice, setUnitPrice] = useState(
    isEdited ? (parentCatalog?.unitCost * conversionRate).toFixed(2) : 0
  );

  const [isPublic, setIsPublic] = useState(
    isEdited ? parentCatalog?.isPublic : false
  );

  const allCategoryData = useSelector((s) => s.pricing.allCategories);

  const [category, setCategory] = useState(
    allCategoryData?.find(
      (category) =>
        (category.company ?? category.default).Key ===
        parentCatalog?.CategoryId?.at(0)
    )
  );

  const [awp, setAwp] = useState(isEdited ? parentCatalog?.Awp : "");

  const categories = useSelector((state) => state?.pricing?.categories);
  const awpsData = useSelector((state) => state?.pricing.awps);

  const [searchCategoryQuery, setSearchCategoryQuery] = useState("");
  const [page, setPage] = useState(0);

  const debouncedSearchTerm = useDebounce(searchCategoryQuery, 300);
  const [allAwps, setallAwps] = useState(awpsData);
  const userInfo = useSelector((s) => s.user.userInfo);
  const { admin, company } = userInfo;
  const dispatch = useDispatch();

  const companyId = selectedCompany?.value ?? company;
  const { loading: catalogsLoading, request: handlePostNewCatalog } = useHttp({
    requestCallback: (data) =>
      addNewCatalogs(
        formats[appliedTemplate?.company.Protocol],
        companyId,
        data
      ),
    onLoad: async (res) => {
      const { data } = res;
      if (res?.err) {
        addNotification(res.err, "danger");
        closeModal();
      } else {
        onChangeTemplate(appliedTemplate);
        addNotification("New catalog added successfully", "success");
      }
      closeModal();
      setIsLoading("");
    },
    onError: (res) => {
      closeModal();
      setIsLoading("");
      addNotification(
        res.err ?? "Something went wrong, please contact support",
        "danger"
      );
    },
  });

  const { loading: updateCatalogLoading, request: handleUpdateCatalog } =
    useHttp({
      requestCallback: (data) =>
        updateCatalogs(formats[appliedTemplate?.company.Protocol], data),
      onLoad: (res) => {
        setIsLoading("");
        if (res?.data?.at(0)?.err) {
          addNotification(res?.data?.at(0)?.err, "danger");
          closeModal();
        } else {
          addNotification("Catalogue Updated Successfully!", "success");
          onChangeTemplate(appliedTemplate);
        }
        closeModal();
        return res;
      },
      onError: (res) => {
        setIsLoading("");
        addNotification(
          "Something went wrong, please contact support",
          "danger"
        );
        closeModal();
      },
    });

  const { loading: categoryLoading, request: handleGetCategories } = useHttp({
    requestCallback: (query) => getCategories(query),
    onLoad: (res) => {
      return res;
    },
    onError: (res) => {
      console.log("Error**", res);
    },
  });

  const handleCreateNewCatalog = () => {
    if (!appliedTemplate) {
      addNotification("Please select template", "danger");
      return;
    }

    if (!description) {
      addNotification("Please enter description", "danger");
      return;
    }

    setIsLoading("table-data");
    const selectedFormat = formats[appliedTemplate?.company.Protocol];
    if (!selectedFormat) return;

    const template =
      appliedTemplate.company ||
      appliedTemplate.average ||
      appliedTemplate?.default;

    const usdUnitCost = unitPrice / conversionRate;
    const parentLevel = parentCatalog?.Level;
    const childLevel =
      parentLevel && parseInt(parentLevel) < 5 ? parseInt(parentLevel) + 1 : 1;

    const newCatalogsPayload = [
      {
        Code: isEdited ? parentCatalog?.Division : newDivision,
        Description: description,
        City: template?.City,
        Country: template?.Country,
        State: template?.State,
        Level: isEdited ? parentCatalog?.Level : childLevel,
        UnitCost: usdUnitCost,
        CategoryId: [category?.company?._id],
        Unit: unit,
        Awp: awp,
        TotalSimpleUnitCost: unitPrice,
        EquipmentCodes: [],
        CrewCodes: [],
        LaborType: template?.LaborType,
        ProjectType: template?.ProjectType,
        isPublic: isPublic,
        Quarter: template?.Quarter,
        Year: template?.Year,
        Version: template?.Version,
        OrganizationId: selectedCompany?.value
          ? selectedCompany?.value
          : company,
      },
    ];

    if (!isEdited) {
      handlePostNewCatalog(newCatalogsPayload);
    } else {
      if (admin) {
        if (
          (parentCatalog?.ref !== "company" && selectedCompany?.value) ||
          (!selectedCompany && parentCatalog?.ref !== "default")
        ) {
          handlePostNewCatalog(newCatalogsPayload);
        }
        // if (
        //   // parentCatalog?.ref === "average" ||
        //   (parentCatalog?.ref === "default" && parentCatalog?._id) ||
        //   (parentCatalog?.ref !== "company" && selectedCompany?.value) ||
        //   (!selectedCompany && parentCatalog?.ref !== "default")
        // )
        else {
          newCatalogsPayload[0]._id = parentCatalog._id;
          handleUpdateCatalog(newCatalogsPayload?.at(0));
        }
      } else {
        if (parentCatalog?.ref === "company" && parentCatalog?._id) {
          newCatalogsPayload[0]._id = parentCatalog._id;
          handleUpdateCatalog(newCatalogsPayload.at(0));
        }
        // if (
        //   parentCatalog?.ref === "average" ||
        //   (parentCatalog?.ref === "default" && parentCatalog?._id)
        // )
        else {
          handlePostNewCatalog(newCatalogsPayload);
        }
      }
    }
  };

  const getCategoriesWithSearch = async (query) => {
    const categories = await handleGetCategories(query);
    if (categories?.rows) {
      dispatch(setCategories(categories.rows));
    }
  };

  const handlePageChange = () => {
    const newPage = page + 1;
    setPage(newPage);
    const skip = newPage * 50;
    const columnName = searchCategoryQuery.length ? "DisplayName" : "";
    getCategoriesWithSearch({
      skip,
      limit: 50,
      search: searchCategoryQuery,
      column: columnName,
    });
  };

  useEffect(() => {
    handleCategorySearch();
  }, [debouncedSearchTerm]);

  const handleCategorySearch = () => {
    const columnName = searchCategoryQuery.length ? "DisplayName" : "";
    getCategoriesWithSearch({
      skip: 0,
      limit: 50,
      search: searchCategoryQuery,
      column: columnName,
    });
  };

  return (
    <Modal
      open
      title={`${isEdited ? "Edit Catalogue" : "Add New Catalogue"}`}
      className="create-modal"
      onCancel={closeModal}
      footer={(_, { OkBtn, CancelBtn }) => (
        <span className="d-flex justify-content-end align-items-center">
          <Button
            onClick={handleCreateNewCatalog}
            className="btn-header bg-bid text-white mr-2"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              closeModal();
            }}
            className="btn-header bg-white text-bid border-bid px-3"
          >
            Cancel
          </Button>
        </span>
      )}
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-4 align-items-center">
          <div className="d-flex justify-content-end w-25">
            <label>Code</label>
          </div>
          <MDBDropdown
            dropend
            group
            className="w-100 dropdownListItems newCatalogSelect"
          >
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between disabled`}
            >
              <div className="d-flex align-items-center gap-2">
                <span className="align-items-center">
                  {isEdited ? parentCatalog?.Division : newDivision}
                </span>
              </div>
            </MDBDropdownToggle>
          </MDBDropdown>
        </div>
        <div className="d-flex gap-4 align-items-center">
          <div className="d-flex justify-content-end w-25">
            <label>Description</label>
          </div>
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="key"
            type="text"
            className="form-control newCatalogInput"
            placeholder="Enter Description"
          />
        </div>
        <div className="d-flex gap-4 align-items-center">
          <div className="d-flex justify-content-end  w-25">
            <span>Unit</span>
          </div>
          <MDBDropdown
            dropend
            group
            className="w-100 dropdownListItems newCatalogSelect"
          >
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between`}
            >
              <div className="d-flex align-items-center gap-2">
                <span className="align-items-center">
                  {unit || "Select Unit"}
                </span>
              </div>

              <div>
                <DownArrowIcon />
              </div>
            </MDBDropdownToggle>
            <MDBDropdownMenu className="w-100 border-none" responsive="end">
              <div className="list">
                {unitsData
                  .find(
                    (item) =>
                      item?.label === appliedTemplate?.company?.Measurements
                  )
                  ?.value?.map((item, index) => (
                    <MDBDropdownItem key={index} onClick={() => setUnit(item)}>
                      {item}
                    </MDBDropdownItem>
                  ))}
              </div>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>

        <div className="d-flex gap-4 align-items-center">
          <div className="d-flex justify-content-end  w-25">
            <label>Unit Price</label>
          </div>
          <input
            value={unitPrice}
            onChange={(e) => setUnitPrice(e.target.value)}
            name="key"
            type="text"
            className="form-control newCatalogInput"
            placeholder="Unit Price"
          />
        </div>

        <div className="d-flex gap-4 align-items-center">
          <div className="d-flex justify-content-end  w-25">
            <span>Category</span>
          </div>

          <MDBDropdown
            dropend
            group
            className="w-100 dropdownListItems newCatalogSelect"
          >
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between`}
            >
              <div className="d-flex align-items-center gap-2">
                <span>
                  {category?.company?.DisplayName || "Select Category"}
                </span>
              </div>

              <div>
                <DownArrowIcon />
              </div>
            </MDBDropdownToggle>
            <MDBDropdownMenu className="w-100 border-none" responsive="end">
              <div className="px-2">
                <input
                  onChange={(e) => {
                    dispatch(clearCategories());
                    setSearchCategoryQuery(e.target.value);
                  }}
                  name="key"
                  type="text"
                  className="form-control"
                  placeholder="Search Category"
                />
              </div>
              <div className="list">
                {(categories || []).map((item, index) => (
                  <MDBDropdownItem
                    key={index}
                    onClick={() => setCategory(item)}
                  >
                    {item?.company?.DisplayName}
                  </MDBDropdownItem>
                ))}
                <div
                  className="px-2"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={handlePageChange}
                >
                  <span>Load More</span>
                </div>
              </div>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>
        <div className="d-flex gap-4 align-items-center">
          <div className="d-flex justify-content-end  w-25">
            <span>Awp</span>
          </div>
          <MDBDropdown
            dropend
            group
            className="w-100 dropdownListItems newCatalogSelect"
          >
            <MDBDropdownToggle
              className={`pricingDropdown d-flex justify-content-between`}
            >
              <div className="d-flex align-items-center gap-2">
                <span>{awp || "Select Awp"}</span>
              </div>

              <div>
                <DownArrowIcon />
              </div>
            </MDBDropdownToggle>
            <MDBDropdownMenu className="w-100 border-none" responsive="end">
              <div className="px-2">
                <input
                  onChange={(e) => {
                    const filteredAwps = awpsData.filter((awp) =>{
                      const awpRow = awp?.company || awp?.default || awp?.average;
                      if(awpRow?.Description?.toLowerCase().includes(e.target.value.toLowerCase())){
                        return awp;
                      }
                    }
                    );
                    setallAwps(filteredAwps);
                  }}
                  name="key"
                  type="text"
                  className="form-control"
                  placeholder="Search Country"
                />
              </div>
              <div className="list">
                {(allAwps || []).map((item, index) => (
                  <MDBDropdownItem
                    key={index}
                    onClick={() => setAwp(item?.company?.Description ?? item?.default?.Description)}
                  >
                    {item?.company?.Description ?? item?.default?.Description}
                  </MDBDropdownItem>
                ))}
              </div>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>

        {admin && (
          <div className="d-flex gap-4 align-items-center">
            <div className="d-flex justify-content-end  w-25">
              <label>Is Public</label>
            </div>
            <div className="w-100">
              <input
                value={isPublic}
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
                name="isPublic"
                type="checkbox"
                className="squared-checkbox"
              />
            </div>
          </div>
        )}

        {!isEdited && (
          <div>
            <input
              type="checkbox"
              role="switch"
              onChange={(e) => handleNewCatalogModal(e.target.checked)}
            />
            <label className="ml-2">Root Level</label>
          </div>
        )}
      </div>
    </Modal>
  );
};
