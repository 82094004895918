export const SET_VERSIONS = "SET_VERSIONS";
export const CLEAR_VERSIONS = "CLEAR_VERSIONS";
export const UPDATE_VERSIONS = "UPDATE_VERSIONS";
export const SET_AWPS = "SET_AWPS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_PROJECT_TYPE = "SET_PROJECT_TYPE";
export const SET_LABOR_TYPE = "SET_LABOR_TYPE";
export const ADD_NEW_VERSION = "ADD_NEW_VERSION";
export const ADD_NEW_PROJECT_TYPE = "ADD_NEW_PROJECT_TYPE";
export const ADD_NEW_LABOR_TYPE = "ADD_NEW_LABOR_TYPE";
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";
export const SET_EXPANDED_CATALOGS_DIVISIONS = "SET_EXPANDED_CATALOGS_DIVISIONS";
export const ADD_EXPANDED_CATALOGS_DIVISIONS = "ADD_EXPANDED_CATALOGS_DIVISIONS";
