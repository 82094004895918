import {
    Vector2,
} from 'three';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
//import { GlitchPass } from 'three/examples/js/postprocessing/GlitchPass';

export class PostProcessing {

    constructor(scene, camera, renderer) {
        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;


        this.composer = new EffectComposer(this.renderer);

        this.renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(this.renderPass);

        this.outlinePass = this.setupOutlinePass();
    }


    setupOutlinePass() {
        const outlinePass = new OutlinePass(new Vector2(window.innerWidth, window.innerHeight), this.scene, this.camera);
        outlinePass.edgeStrength = 10;
        // outlinePass.edgeGlow = 0.5;
        // outlinePass.edgeThickness = 2;
        outlinePass.pulsePeriod = 3;
        outlinePass.visibleEdgeColor.set('#0066ff');
        outlinePass.hiddenEdgeColor.set('#190a05');
        this.composer.addPass(outlinePass);

        return outlinePass
    }
}