import {
  ADMIN_SET_USERS,
  ADMIN_EDIT_TIMELINE,
  EDIT_PROJECT,
  ADMIN_REMOVE_TIMELINE,
  ADMIN_REMOVE_USER_FROM_COMPANY,
  ADMIN_REMOVE_USER_FROM_PROJECT,
  ADMIN_ADD_USER_TO_PROJECT,
  ADMIN_SET_NEW_ADMIN,
} from '../constants/action-types/admin';

const initialState = {
  users: null,
  projects: null,
  company: null,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_SET_USERS:
      return { ...state, users: action.payload };

    case 'ADMIN_SET_PROJECTS':
      return { ...state, projects: action.payload };

    case 'ADMIN_SET_COMPANY':
      return { ...state, company: action.payload };

    case ADMIN_EDIT_TIMELINE:
      const { newTimeline, oldTimeline } = action.payload;

      const neededProject = state.projects.find(projectItem => {
        return projectItem._id === oldTimeline.project_id;
      });

      
      const neededProjectIndex = state.projects.findIndex(projectItem => {
        return projectItem._id === oldTimeline.project_id;
      });

      const timelineToEdit = neededProject.timelines.findIndex(
        timeline => oldTimeline._id === timeline._id,
      );

      const newTimelines = [
        ...neededProject.timelines.slice(0, timelineToEdit),
        newTimeline,
        ...neededProject.timelines.slice(timelineToEdit + 1),
      ];

      const newProjects = [
        ...state.projects.slice(0, neededProjectIndex),
        { ...neededProject, timelines: newTimelines },
        ...state.projects.slice(neededProjectIndex + 1),
      ];

      return {
        ...state,
        projects: newProjects,
      };

      case EDIT_PROJECT:
        console.log({state})
      const  newProject  = action.payload;
      

      const neededProjectToEdit = state.projects.find(projectItem => {
        return projectItem._id === newProject._id;
      });

      
      const neededProjectIndexToEdit = state.projects.findIndex(projectItem => {
        return projectItem._id === newProject._id;
      });

     

     

      const newProjectsAfterUpdate = [
        ...state.projects.slice(0, neededProjectIndexToEdit),
        { ...neededProjectToEdit, img: newProject.img},
        ...state.projects.slice(neededProjectIndexToEdit + 1)
      ];

      return {
        ...state,
        projects: [...newProjectsAfterUpdate]
      };

    case ADMIN_REMOVE_TIMELINE: {
      const neededProject = state.projects.find(projectItem => {
        return projectItem.timelines.find(
          timelineItem => timelineItem._id === action.payload,
        );
      });

      const neededProjectIndex = state.projects.findIndex(projectItem => {
        return projectItem.timelines.find(
          timelineItem => timelineItem._id === action.payload,
        );
      });

      const timelineIndex = neededProject.timelines.findIndex(
        timeline => timeline._id === action.payload,
      );

      const newTimelines = [
        ...neededProject.timelines.slice(0, timelineIndex),
        ...neededProject.timelines.slice(timelineIndex + 1),
      ];

      const newProjects = [
        ...state.projects.slice(0, neededProjectIndex),
        { ...neededProject, timelines: newTimelines },
        ...state.projects.slice(neededProjectIndex + 1),
      ];

      return {
        ...state,
        projects: newProjects,
      };
    }

    case ADMIN_REMOVE_USER_FROM_COMPANY:
      const userToDeleteIndex = state.users.findIndex(
        user => user._id === action.payload,
      );

      return {
        ...state,
        users: [
          ...state.users.slice(0, userToDeleteIndex),
          ...state.users.slice(userToDeleteIndex + 1),
        ],
      };

    case ADMIN_REMOVE_USER_FROM_PROJECT: {
      const { user, project } = action.payload;

      const neededProject = state.projects.find(
        projectItem => projectItem._id === project,
      );

      const neededProjectIndex = state.projects.findIndex(
        projectItem => projectItem._id === project,
      );

      const neededUserIdIndex = neededProject.users.findIndex(
        userId => userId === user,
      );

      const neededProjectWithoutUser = {
        ...neededProject,
        users: [
          ...neededProject.users.slice(0, neededUserIdIndex),
          ...neededProject.users.slice(neededUserIdIndex + 1),
        ],
      };

      const newProjects = [
        ...state.projects.slice(0, neededProjectIndex),
        neededProjectWithoutUser,
        ...state.projects.slice(neededProjectIndex + 1),
      ];

      return {
        ...state,
        projects: newProjects,
      };
    }

    case ADMIN_ADD_USER_TO_PROJECT: {
      const { user, project } = action.payload;

      const neededProject = state.projects.find(
        projectItem => projectItem._id === project,
      );

      const neededProjectIndex = state.projects.findIndex(
        projectItem => projectItem._id === project,
      );

      const neededProjectWithUser = {
        ...neededProject,
        users: [...neededProject.users, user],
      };

      const newProjects = [
        ...state.projects.slice(0, neededProjectIndex),
        neededProjectWithUser,
        ...state.projects.slice(neededProjectIndex + 1),
      ];

      return {
        ...state,
        projects: newProjects,
      };
    }

    case ADMIN_SET_NEW_ADMIN: {
      const oldUserAdmin = state.users.find(user => user.companyAdmin === true);

      if (oldUserAdmin._id === action.payload._id) {
        return state;
      } else {
        const oldUserAdminIndex = state.users.findIndex(
          user => user.companyAdmin === true,
        );

        const newUserWithoutAdmin = { ...oldUserAdmin, companyAdmin: false };

        const oldUserWithoutAdminIndex = state.users.findIndex(
          user => user._id === action.payload._id,
        );

        if (oldUserAdminIndex > oldUserWithoutAdminIndex) {
          const newUsers = [
            ...state.users.slice(0, oldUserWithoutAdminIndex),
            action.payload,
            ...state.users.slice(
              oldUserWithoutAdminIndex + 1,
              oldUserAdminIndex,
            ),
            newUserWithoutAdmin,
            ...state.users.slice(oldUserAdminIndex + 1),
          ];

          return {
            ...state,
            users: newUsers,
          };
        } else {
          const newUsers = [
            ...state.users.slice(0, oldUserAdminIndex),
            newUserWithoutAdmin,
            ...state.users.slice(
              oldUserAdminIndex + 1,
              oldUserWithoutAdminIndex,
            ),
            action.payload,
            ...state.users.slice(oldUserWithoutAdminIndex + 1),
          ];

          return {
            ...state,
            users: newUsers,
          };
        }
      }
    }

    default:
      return state;
  }
};

export default adminReducer;
