import * as React from 'react'

function SvgArrowUp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path d="M2 26h32L18 10 2 26z" fill="currentColor" />
    </svg>
  )
}

export default SvgArrowUp
