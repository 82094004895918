import {
    Mesh,
    Plane,
    MeshBasicMaterial,
    DoubleSide,
    CylinderGeometry,
    Object3D,
    PlaneGeometry,
} from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'

export class ClipPlane {
    constructor(scene, worldNormal, intersection, normal, container, camera, cameraControls, orbitControls, allModels) {

        this.scene = scene;
        this.planeMesh = this.getPlaneMesh();
        this.plane = new Plane();
        this.plane.setFromNormalAndCoplanarPoint(worldNormal, intersection.point);
        this.normal = normal;
        this.origin = intersection.point;
        this.container = container;
        this.arrowBoundingBox = new Mesh();
        this.camera = camera;
        this.cameraControls = cameraControls;
        this.OrbitControls = orbitControls;
        this.allModels = allModels;


        this.helper = this.createHelper();
        this.transformControls = this.newTransformControls();
        // this.setupEvents(onStartDragging, onEndDragging);
        this.setupEvents();
    }

    getPlaneMesh() {
        const planeMat = new MeshBasicMaterial({
            color: 0xff0000,
            side: DoubleSide,
            transparent: true,
            opacity: 0.5
        });

        const planeGeom = new PlaneGeometry(5, 5, 1);
        const planeMesh = new Mesh(planeGeom, planeMat)
        planeMesh.name = "clipingHelper";
        return planeMesh;
    }

    createHelper() {
        const helper = new Object3D();
        helper.name = "clipingHelper";
        helper.lookAt(this.normal);
        helper.position.copy(this.origin);
        const scene = this.scene;
        scene.add(helper);
        helper.add(this.planeMesh);
        return helper;
    }

    newTransformControls() {
        const camera = this.camera;
        const container = this.container;
        const controls = new TransformControls(camera, container);
        this.initializeControls(controls);
        const scene = this.scene;
        scene.add(controls);
        return controls;
    }
    initializeControls(controls) {
        controls.mode = 'translate';
        controls.attach(this.helper);
        controls.showX = false;
        controls.showY = false;
        controls.setSpace('local');
        this.createArrowBoundingBox();
        controls.children[0].children[0].add(this.arrowBoundingBox);
    }


    createArrowBoundingBox() {
        this.arrowBoundingBox.geometry = new CylinderGeometry(0.18, 0.18, 1.2);
        this.arrowBoundingBox.material = new MeshBasicMaterial({ visible: false });
        // this.arrowBoundingBox.rotateX(Math.PI / 2);
        // this.arrowBoundingBox.updateMatrix();
        // const invMatrix = new Matrix4()
        // invMatrix.copy(this.arrowBoundingBox.matrix).invert();
        // this.arrowBoundingBox.geometry.applyMatrix4(invMatrix);
    }

    setupEvents() {
        this.transformControls.addEventListener('change', (event) => {
            // if (!this.enabled)
            //     return;
            this.plane.setFromNormalAndCoplanarPoint(this.normal, this.helper.position);
            // if (this.edgesActive)
            //     this.edges.updateEdges();
        });
        this.transformControls.addEventListener('dragging-changed', (event) => {
            // if (!this.enabled)
            //     return;
            this.isVisible = !event.value;
            this.toggleCameraControls(this.isVisible);
            // if (event.value)
            //     onStart();
            // else
            //     onEnd();
        });
        // this.context.ifcCamera.currentNavMode.onChangeProjection.on((camera) => {
        //     this.controls.camera = camera;
        // });
    }

    toggleCameraControls(active) {
        this.cameraControls.enabled = active;
        this.OrbitControls.enabled = active;
        this.scene.removeTempClipper = !active;

        if (active) {
            this.container.style.touchAction = 'none';
            this.container.style.userSelect = 'none';
            this.container.style.webkitUserSelect = 'none';
        }
        else {
            // this.cancel();
            this.container.style.touchAction = '';
            this.container.style.userSelect = '';
            this.container.style.webkitUserSelect = '';
        }

    }

    removefromScene() {
        this.helper.removeFromParent();
        this.arrowBoundingBox.removeFromParent();
        this.arrowBoundingBox.geometry.dispose();
        this.arrowBoundingBox = undefined;
        this.planeMesh.geometry.dispose();
        this.planeMesh.geometry = undefined;
        this.transformControls.removeFromParent();
        this.transformControls.dispose();
        // this.edges.dispose();
        this.helper.removeFromParent();
    }

}