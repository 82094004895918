import React, { useEffect, useState } from "react";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';


const styles = {
    padding:"20px",
    width:'80%',

}


 const html = `
 <p>&nbsp;</p>
<!-- saved from url=(0041)https://bidlight.com/revit-estimate-plugin-->


<!-- [if lt IE 9]>
 <link rel='stylesheet' id='twentyseventeen-ie8-css'  href='https://Bidlight.com/wp-content/themes/twentyseventeen/assets/css/ie8.css?ver=1.0' type='text/css' media='all' />
 <![endif]-->

<!-- [if lt IE 9]>
 <script type='text/javascript' src='https://Bidlight.com/wp-content/themes/twentyseventeen/assets/js/html5.js?ver=3.7.3' id='html5-js'></script>
 <![endif]-->

<!-- BEGIN ExactMetrics v5.3.5 Universal Analytics - https://exactmetrics.com/ -->
<!-- END ExactMetrics Universal Analytics -->
<div id="page" class="site" style="color: blue; text-align: left; padding-left: 50px;"><header id="masthead" class="site-header" role="banner">
<div class="custom-header">
<div class="custom-header-media">
<div class="site-branding">
<div class="wrap">
<div class="site-branding-text">
<p class="site-title"><a href="https://Bidlight.com/" rel="home">BidLight</a></p>
<p class="site-description">Revit Estimation</p>
</div>
<!-- .site-branding-text --></div>
<!-- .wrap --></div>
<!-- .site-branding --></div>
<!-- .custom-header --></div>
</header><!-- #masthead -->
<div style="margin: 5%, padding:10%;">
<div id="content">
<div>
<div id="primary" class="site-header">
<article id="post-594" class="post-594 page type-page status-publish hentry"><header class="entry-header">
<h1 class="entry-title">Create Estimate Using Revit Models</h1>
</header><!-- .entry-header -->
<div class="site-blocks-cover pt-0 mt-0" style="margin: 5%, padding:10%;">
<div id="page">
<div>&nbsp;</div>
</div>
<div id="guide-content-outer">
<div id="div-workspace" class="f-div-section">
<div id="div-Measurement" class="s-div-section">
<div id="coll-Measurement" class="coll-div-section"></div>
</div>
<hr class="hr-usage-guide" />
<div id="div-Control" class="s-div-section">

<p><strong><u><h1>3 simple steps to estimate the cost of your 3D Model</h1><br /><br /></u></strong></p>
<div id="coll-Control">
<h4 class="">* Assuming you downloaded BidLight successfully from&nbsp;<a href="https://bidlight.com" target="_blank" rel="noopener">https:/bidlight.com</a> or from the <a href="https://apps.autodesk.com/RVT/en/Detail/Index?id=6237938337445006374&amp;appLang=en&amp;os=Win64" target="_blank" rel="noopener">Autodesk Store</a>.</h4>
<h4>You will need to apply for a license through the landing page after picking one of the plans as follow:<br /><br /><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot1.png" /><br /><br />Make sure to check your inbox or spam/junk folder for a license that will look something like this:<br /><br /></h4>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot2.png" /></h4>
<h4><br />Once done with the license phase head over to your account online at&nbsp;<a href="https://bidlight.com/authorization" target="_blank" rel="noopener">https://bidlight.com/authorization</a>&nbsp;and login and download the software if you didn't <a href="http://already.At" target="_blank" rel="noopener"><span data-auto-link="true" data-href="http://already.At">already.<br /><br /><br />At</span></a>&nbsp;this stage you are ready to estimate your project in 3 simple steps, will go over these steps one by one.<br /><br /></h4>
<hr />
<h4>&nbsp;</h4>
<p><strong>&nbsp;</strong></p>
<div style="color: #4E6471;"><strong><h2>* Step 1 Export your project and all its linked files from Revit to BidLight.</h2></strong></div>
<h4>&nbsp;</h4>
<h5>Head over to your Revit application and look for BidLight ribbon (will only show after you install BidLight).<br />Should look like this image:<br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot3.png" /></h4>
<h5><br />At this point, all you have to do is click the Blue \"B\" icon to export your project.<br /><br />You will be presented with a login window, provide your email and password that you used for registering the license.<br />A page with your personal space will open up, most likely empty because you don't have any projects yet.<br />For the export to work you need to create a project with a name and a description, like below:<br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot4.png" /></h4>
<h5><br />If you already have a project, and you want to estimate the changes made to the 3D model, all you have to do is click Export on the desired project, as shown above.<br /><br />Next the export page will show up with the main model and any linked files (models), you can check/uncheck any linked file, if you prefer to only export specific files (models).<br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot5.png" /></h4>
<h5><br />BidLight will collect all the relevant data required to estimate your project including the 3D model (geometry), once done it will prompt an estimation button and ask you if you want to estimate the project right away.<br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot6.png" /></h4>
<h5><br />Clicking yes or Estimate will take you away from Revit to BidLight, and here Step 2 (the estimation workflow) starts<br /><br /><br /></h5>
<h2>&nbsp;</h2>
<hr />
<h4>&nbsp;</h4>
<div style="color: #4E6471;"><strong><h2>* Step 2 Estimate your project and all its linked files inside BidLight.</h2><br /></strong></div>
<h4>&nbsp;</h4>
<h5>At this stage you have your project completely loaded inside BidLight, and your first step would be to provide the software with relevant information regarding your project, to get an accurate estimate. There is way more things to consider to get a 100% accurate estimate using BidLight, but for the sake of simplicity, we will only consider the input page information.<br /><br />&nbsp;&nbsp;</h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot7.png" /></h4>
<h2>&nbsp;</h2>
<h5>As shown above the only required field is the country in which you're building the project, but the more information you provide the more accurate the estimation gets.<br />Select a country and click Next.<br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot8.png" /></h4>
<h5><br />This will fire up the estimation algorithm, the algorithm will attempt to estimate every element inside the project, and will only classify the item if it has 85% confidence or more.<br />If some of the items for one reason or another didn't get an 85% confidence score, these items will be presented as unclassified material, which will require the manual input of the user.<a href="http://user.It" target="_blank" rel="noopener"><span data-auto-link="true" data-href="http://user.It"><br /></span></a></h5>
<p><a href="http://user.It" target="_blank" rel="noopener"><span data-auto-link="true" data-href="http://user.It">&nbsp;</span></a></p>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot9.png" /></h4>
<p><a href="http://user.It" target="_blank" rel="noopener"><span data-auto-link="true" data-href="http://user.It">&nbsp;</span></a></p>
<h5><a href="http://user.It" target="_blank" rel="noopener"><span data-auto-link="true" data-href="http://user.It">It</span></a>&nbsp;is also important to note that the software will learn from your actions and the decisions you make.<br />For instance, if one item was not classified and you moved it to a specific catalog node (as classified material), BidLight will remember your choice next time, and will attempt to place similar items in the right category without your input.<br /><br />At this stage of the estimation, you get a chance to edit the prices and create, clone new items that are missing from the model, or add complete scopes. to the project.<br /><br />Obviously for the sake of the simplicity of this guide, we will not go into details of what you can do inside BidLight to make your estimate more accurate and completely automated.<br />We will assume that we are happy with our current estimate, and we want to generate the estimate and share it with our clients/stakeholders.<br /><br />To publish and finalize your estimate, you need to click on export estimate button in one of 2 locations, either on the top right corner or at the bottom of the left menu, as bellow:<br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot10.png" /></h4>
<h5><br /><br />The software might prompt few warning messages if things are not 100% setup, but we can go ahead and ignore them for now.<br /><br />Once the estimation is finalized, you will be prompt to provide a location on your local machine, which you can ignore by clicking cancel if you choose to.<br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot11.png" /></h4>
<h5><br /><br /></h5>
<hr />
<div style="color: #4E6471;"><strong><h2>* Step 3 Investigate, Download and share your final estimate.</h2><br /></strong></div>
<h4>&nbsp;</h4>

<h5>Once the estimation is finalized, you will see a link that will take you directly to your dashboard online.<br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot12.png" /></h4>
<h5><br />Clicking on the above link will take you the online dashboard. You will see the complete estimation, organized as priced/unpriced items.<br />and also you will be able to download any of the estimation files (Quantities, Estimation, or Trade Cost) and share them directly from the dashboard by just providing the email and selecting the level of access you want the user to have.<br /><br /><br /></h5>
<h4><img src="https://bidlight.com/marketing/branding/hotlink-ok/BidLight-Screenshot13.png" /></h4>
<h5>&nbsp;</h5>
<h5>This video below details the 3rd and final process.</h5>
</div>
</div>
</div>
</div>
<footer id="colophon" role="contentinfo">
<div>
<div>
<div><nav class="footer-nav"></nav></div>
</div>
</div>
</footer>
<p><!-- Google Tag Manager (noscript) --><noscript><iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-P6NXPT8\"height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe></noscript><!-- End Google Tag Manager (noscript) --><!-- .entry-content --><!-- #post-692 --><!-- .site-main --><!-- .content-area --><!-- .site-content --><!-- .site-inner --><!-- .site --><!-- .site-footer --><iframe title="BidLight" src="https://www.youtube.com/embed/9cti1NgPzug" width="90%" height="664" frameborder="0" allowfullscreen="allowfullscreen"></iframe></p>
</div>
<!-- .entry-content --></article>
<!-- #post-## --> <!-- #main --></div>
<!-- #primary --></div>
<!-- .wrap --></div>
<!-- #content --></div>
</div>
<footer id="colophon" class="site-footer" role="contentinfo">
<div class="wrap">
<aside class="widget-area" role="complementary" aria-label="Footer">
<div class="widget-column footer-widget-1">
<section id="pages-2" class="widget widget_pages">
<h2 class="widget-title">LEARN ABOUT BidLight</h2>
<ul>
<li class="page_item page-item-575"><a href="https://Bidlight.com/#contact-section">Contact Us</a></li>
<li class="page_item page-item-598"><a href="https://Bidlight.com/disclosure/">Disclosure</a></li>
<li class="page_item page-item-594 current_page_item"><a href="https://Bidlight.com/privacy-policy/" aria-current="page">Privacy Policy</a></li>
<li class="page_item page-item-596"><a href="https://Bidlight.com/privacy-policy/">Terms of Service</a></li>
</ul>
</section>
</div>
<div class="widget-column footer-widget-2">&nbsp;</div>
</aside>
</div>
</footer>
             

`

const RevitEstimate = (props) => {


return (
  <div dangerouslySetInnerHTML={{__html:html}} />
       
        
  );
};

export default RevitEstimate;