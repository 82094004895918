import React from "react";

export const SortIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
    >
      <path
        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
        fill="#3A5765"
      />
      <path
        d="M14.6667 0.833252V10.8333H17.1667L13.8333 14.9999L10.5 10.8333H13V0.833252H14.6667ZM8 12.4999V14.1666H0.5V12.4999H8ZM9.66667 6.66659V8.33325H0.5V6.66659H9.66667ZM9.66667 0.833252V2.49992H0.5V0.833252H9.66667Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};
