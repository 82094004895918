import React, { memo, useState, useEffect, useRef } from "react";
import Main from "./Main";
import Navigate from "./Navigate";
import Schedule from "./Schedule";
import Filters from "./Filters";

import "./share.scss";
import { eventTrack } from "helpers/ga4Helper";
import { useHistory } from "react-router";
import { tokenRef } from 'api';

const Share = ({
  timeline,
  rule,
  pending,
  setPending,
  withShare,
  setWith,
  menu,
  setMenu,
  setPriceView,
  FiltersData,
  setTreeData,
  treeData,
  WebGL,
  treeFilesData
}) => {
  const history = useHistory();
  useEffect(() => {
    if (rule) {
      setPriceView(
        rule.prices,
        rule.estimation_excel,
        rule.estimation_excel_awp
      );
    }
  }, [rule]);
  const userToken = localStorage.getItem(tokenRef);

  return (
    <div className="share-container-new">
      <div className="share-button-group">
        {/* <div
          className={
            menu === 'filters' ? 'share-button active-share' : 'share-button'
          }
          onClick={() => setMenu(item => (item === 'filters' ? '' : 'filters'))}
        >
          Filters
        </div>

        <div
          className={
            menu === 'schedule' ? 'share-button active-share' : 'share-button'
          }
          onClick={() =>
            setMenu(item => (item === 'schedule' ? '' : 'schedule'))
          }
        >
          Schedule
        </div>
        */}
        {!rule && (
          <button
            className={
              menu === "share" ? "share-button active-share" : "share-button"
            }
            onClick={() => {
              setMenu((item) => (item === "share" ? "" : "share"));
              {
                eventTrack(
                  "View-InsideShare-Action",
                  "View-InsideShare",
                  timeline,
                  "+1",
                  false,
                  "+1"
                );
              }
            }}
          >
            <i className="fa fa-share-alt"></i>
            Share
          </button>
        )}

        {!userToken && <button
          className={
            menu === "filters" ? "share-button active-share" : "share-button"
          }
          onClick={() => {
            history.push('/#contact-section');
          }}
        >
          <i className="fa fa-download"></i>
          Download
        </button>}

        <button
          className={
            menu === "filters" ? "share-button active-share" : "share-button"
          }
          disabled={treeData.length === 0}
          onClick={() => {
            setMenu((item) => (item === "filters" ? "" : "filters"));
            console.log("Filter clicked");
            {
              eventTrack(
                "View-Filters-Action",
                "View-Filters",
                timeline,
                "+1",
                false,
                "+1"
              );
            }
          }}
        >
          <i className="fa fa-filter"></i>
          Filters
        </button>

        {(!rule || rule.navigation) && (
          <button
            className={
              menu === "navigate" ? "share-button active-share" : "share-button"
            }
            onClick={() => {
              setMenu((item) => (item === "navigate" ? "" : "navigate"));
              console.log("Navigate clicked");
              {
                eventTrack(
                  "View-Navigate-Action",
                  "View-Navigate",
                  timeline,
                  "+1",
                  false,
                  "+1"
                );
              }
            }}
          >
            <i className="fa fa-info-circle"></i>
            Navigate
          </button>
        )}
      </div>

      <div className="share-wrappre" style={menu ? {} : { display: "none" }}>
        <div className="share">
          {menu === "share" && (
            <Main
              timeline={timeline}
              pending={pending}
              setPending={setPending}
              withShare={withShare}
              setWith={setWith}
              setPriceView={setPriceView}
              rule={rule}
            />
          )}
          {menu === "schedule" && <Schedule />}
          {menu === "navigate" && <Navigate timeline={timeline} rule={rule} />}
          {menu === "filters" && (
            <Filters FiltersData={FiltersData} setTreeData={setTreeData} treeData={treeData} WebGL={WebGL} treeFilesData={treeFilesData}/>
          )}
        </div>
      </div>
    </div>
  );
};
export default memo(Share);
