/** */
import axios from "axios";
import Constants from "../../constants";
import { extractImageFromZip } from "../../utils/imageHandler";

export const url = `${Constants.SERVER_URL}/api/`;

// export function loadArticles() {
//   return (dispatch) => {
//     axios
//       .get(`${url}articles`)
//       .then(async (res) => {
//         let articles = res.data;
//         const updatedArticles = await Promise.all(
//           articles.map(async (article) => {
//             const authorImage = await extractImageFromZip(
//               article.author.profilePhoto
//             );
//             article.author.profilePhoto = authorImage;
//             return article;
//           })
//         );

//         dispatch({ type: "LOAD_ARTICLES", articles: updatedArticles });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// }

// export function filterArticles(articles, category) {
//   return (dispatch) => {
//     let filteredArticles =
//       category == "All"
//         ? articles
//         : articles.filter((article) => article.category == category).reverse();
//     console.log({ filteredArticles, articles });
//     dispatch({
//       type: "FILTER_ARTICLES",
//       filteredArticles,
//       articles,
//       selectedCategory: category,
//     });
//   };
// }

export function getUser(_id) {
  return axios
    .get(`${url}user/${_id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
}

// export function getUserProfile(_id) {
//   return (dispatch) => {
//     axios
//       .get(`${url}user/profile/${_id}`)
//       .then((res) => {
//         let profile = res.data;
//         // console.log({profile})
//         dispatch({ type: "SET_PROFILE", profile });
//       })
//       .catch((err) => console.log(err));
//   };
// }

export function getArticle(article_id) {
  return (dispatch) => {
    axios
      .get(`${url}article/slug/${article_id}`)
      .then((res) => {
        let article = res.data;
        dispatch({ type: "VIEW_ARTICLE", article });
      })
      .catch((err) => console.log(err));
  };
}
// article_id, author_id, comment
export function comment() {
  return (dispatch) => {};
}
//req.body.article_id
// export function clap(article_id) {
//   return (dispatch) => {
//     console.log("clapping...");
//     axios
//       .post(`${url}article/clap`, { article_id })
//       .then((res) => {
//         loadArticles()
//       })
//       .catch((err) => console.log(err));
//   };
// }
//id, user_id
// export function follow(id, user_id) {
//   return (dispatch) => {
//     axios
//       .post(`${url}user/follow`, { id, user_id })
//       .then((res) => {
//         // dispatch({type:'FOLLOW_USER', user_id})
//         getUser(id).then((res) => {
//           dispatch({ type: "SET_USER", user: res });
//         });
//         dispatch(loadArticles());
//       })
//       .catch((err) => console.log(err));
//   };
// }

export function SignInUser(user_data) {
  return (dispatch) => {
    console.log("adding us..");
    axios
      .post(`${url}signup`, user_data)
      .then((res) => {
        let user = res.data;
        console.log("==================signin=======");
        console.log(user);
        console.log("==================signin=======");
        localStorage.setItem("Auth", JSON.stringify(user));
        dispatch({ type: "SET_USER", user });
      })
      .catch((err) => console.log(err));
  };
}

export function Loginuser(user_data) {
  localStorage.setItem("Auth", user_data);
  localStorage.setItem("userInfo", user_data);
  // dispatch({ type: 'SET_USER', user_data })
}

export function toggleClose() {
  return (dispatch) => {
    dispatch({ type: "TOGGLE_MODAL", modalMode: false });
  };
}
export function toggleOpen() {
  return (dispatch) => {
    dispatch({ type: "TOGGLE_MODAL", modalMode: true });
  };
}
