export const formats = {
  RevitUniFormat: "catalogs",
  MasterFormat: "catalogs_masters",
  UniFormat: "catalogs_uniformats",
  OmniClass: "catalogs_omnis",
  TeslaMasterFormat: "catalogs_tesla_masters",
  CSIUniFormat: "catalogs_uniformats",
};

export const unitsData = [
  { label: "Imperial", value: ["SF", "LF", "CY", "SY", "TON", "LB", "EA"] },
  { label: "Metric", value: ["SM", "LM", "CM", "TONNE", "KG", "EA"] },
];

export const healthSettingsTitles = {
  maxSizeOfProject: "Number of associated projects",
  maxUnusedFamilies: "Unused Families",
  worksets: "Worksets",
  views: "Views",
  viewSheets: "View Sheets",
  phases: "Phases",
  loadedFamilies: "Loaded Families",
  usedFamilies: "Used Families",
  sharedParameters: "Shared Parameters",
  projectParameters: "Project Parameters",
  maxParametersCount: "Maximum number of Parameters",
  linkedRevit: "Linked Revit",
  linkedImage: "Linked Image",
  linkedCAD: "Linked CAD",
  linkedPDF: "Linked PDF",
  isModelInCloud: "Is Model In Cloud",
  centralServerPath: "Central Server Path",
  isCloudpath: "Cloud Path",
  buildingType: "Building Type",
  importedCADFiles: "Imported CAD Files",
  listOfLevels: "List of Levels",
  listOfLevelTypes: "List of Level Types",
  listOfGrids: "List of Grids",
  listOfGridTypes: "List of Grid Types",
  rooms: "Rooms",
  spaces: "Spaces",
  panelScheduleViews: "Panel Schedule Views",
  viewSchedule: "View Schedule",
  modelName: "Model Name",
  modelArea: "Model Area",
  modelHeight: "Model Height",
  warnings: "Warnings",
  unusedElements: "Unused Elements",
  nonBuiltInObjectStyle: "Non Built-in Object Style",
  modelGroups: "Model Groups",
  detailGroups: "Detail Groups",
  inPlaceFamiles: "In Place Familes",
  designOptions: "Design Options",
  projectCoordinates: "Coordinates of Project",
  surveyCoordinates: "Coordinates of Survey",
  linkedRevitFilesNotInPlace: "Linked Revit Files Not In Place",
  linkedCADFilesVisibleInAllViews: "Linked CAD Files Visible In All Views",
  linkedCADFilesNotPinnedInPlace: "Linked CAD Files Not Pinned In Place",
  wrongElementsOnSharedLevelsAndGrid:
    "Wrong Elements On Shared Levels And Grid",
  sharedLevelsAndGridOnWrongWorkset: "Shared Levels And Grid On Wrong Workset",
  unPlacedRooms: "Un-Placed Rooms",
  redundantAndUnEnclosedRooms: "Redundant And Un-Enclosed Rooms",
  unSpaces: "Un-Spaces",
  redundantAndUnEnclosedSpaces: "Redundant And Un-Enclosed Spaces",
  placeHolderSheets: "Place Holder Sheets",
  viewTemplates: "View Templates",
  viewWithHiddenElements: "Views With Hidden Model Elements",
  viewWithNoViewTemplate: "Views With No View Template",
  viewsNotOnSheet: "Views Not On Sheets",
  viewsOnSheetWithNoViewTemplate: "Views On Sheets With No View Template",
  sheetsWithOutView: "Sheets With Out View",
  legendViews: "Legend Views",
  scopeBoxes: "Scope Boxes",
  duplicateModeledElements: "Duplicate Modeled Elements",
  mirroredElements: "Mirrored Elements",
  assemblies: "Assemblies",
  genericModels: "Generic Models",
  isUniqueRoomNumber: "Has Unique Room Number",
  globalParameters: "Global Parameters",
  linkedModel: "Linked Model",
  totalFamilies: "Total Families",
};

export const healthSettingsRuleTitles = {
  prefix: "Prefix",
  suffix: "Suffix",
  maxCount: "Maximum Allowed Entries",
  inputValue: "Value",
  maxViewsPerSheet: "Maximum Allowed Views Per Sheet",
};

export const modelHealthMetaLabels = {
  globalParametersCount: "Global Parameter Count",
  linkedCADCount: "Linked CAD Count",
  linkedImageCount: "Linked Image Count",
  linkedPDFCount: "Linked PDF Count",
  linkedRevitCount: "Linked Revit Count",
  loadedFamiliesCount: "Loaded Families Count",
  modelArea: "Model Area",
  parametersCount: "Parameters Count",
  phasesCount: "Phases Count",
  projectParametersCount: "Project Parameter Count",
  sharedParametersCount: "Shared Parameter Count",
  unusedFamiliesCount: "Unused Families Count",
  usedFamiliesCount: "Used Families Count",
  viewCount: "View Count",
  viewSheetsCount: "View Sheets Count",
  worksetsCount: "Workesets Count",
};

export const ratesData = [
  {
    label: "Hourly",
    value: "Hourly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Yearly",
    value: "Yearly",
  },
];
