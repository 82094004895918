import React from "react";
import Select, { components } from "react-select";

export const CustomOption = (props) => {
  const name = props?.data?.label?.split("_?_")[0];
  const projectNumber = props?.data?.label?.split("_?_")[1];
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center justify-content-between">
        <div>{name}</div>
        <div className="f-10 text-grey">
          {projectNumber === "undefined" ? "N/A" : projectNumber}
        </div>
      </div>
    </components.Option>
  );
};

export const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div className="d-flex align-items-center justify-content-between">
      <div>{label?.split("_?_")[0]}</div>
      <div className="f-10 text-grey">
        {label?.split("_?_")[1] === "undefined" ? "N/A" : label?.split("_?_")[1]}
      </div>
    </div>
  );