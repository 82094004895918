import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { setFeatureRequest } from "actions/user";
import { Modal } from "antd";
import { getAffiliations } from "api/affiliations/getAffiliations";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import Loader from "components/Loader";
import addNotification from "helpers/notify";
import { useHttp } from "hooks/useHttp";
import { RequestCard } from "pages/configuration/requestCard";
import AccountDetails from "./AccountDetails";
import AffiliatedUsers from "./AffiliatedUsers";
import EarningNavbar from "./EarningNavbar";
import Stats from "./Stats";

export default function Earnings() {
  const dispatch = useDispatch();

  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const featureRequestRedux = useSelector((s) => s.user.featureRequest);

  const [userToDelete, setUserToDelete] = useState(null);
  const [requestType, setRequestType] = useState("");
  const [affiliatedUser, setAllAffiliatedUser] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const TABS = {
    STATS: "stats",
    USERS: "users",
    ACCOUNT: "account",
  };

  const [activeTab, setActiveTab] = useState(TABS.STATS);

  const [requestModal, setRequestModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { loading: loadingGetAffiliations, request: getAffiliatedUserAPI } =
    useHttp({
      requestCallback: (data) => getAffiliations(),
      onLoad: (res) => {
        setAllAffiliatedUser(res);
      },
      onError: (res) => {},
    });

  useEffect(() => {
    getAffiliatedUserAPI();
  }, []);

  useEffect(() => {
    getAffiliatedUserAPI();
  }, [refresh]);

  const requestAccess = async () => {
    try {
      setLoading(true);
      const res = await createFeatureRequest(userInfoRedux.id, {
        companyId: userInfoRedux.company,
        permission: requestType,
        userId: userInfoRedux.id,
      });
      if (res) {
        setLoading(false);
        addNotification("Request Successfully! ", "success");
        dispatch(
          setFeatureRequest(res?.map((feature) => feature?.requestedFeature))
        );
      }
    } catch (e) {
      addNotification(e?.message, "danger");
      setLoading(false);
    }
  };

  const handleSearch = (keyword) => {
    // const filteredResult = paramsList.filter((list) =>
    //   list?.name.toLowerCase().includes(keyword.toLowerCase())
    // );
    // setFilteredList(filteredResult);
    // if (keyword.length > 0) {
    //   setFiltered(true);
    // } else {
    //   setFiltered(false);
    // }
  };

  return (
    <div className="personal-area">
      {loadingGetAffiliations && <Loader />}
      <EarningNavbar
        handleSearch={handleSearch}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <div className="content-bg p-2">
        <div className="container-fluid d-flex parameter-container">
          <div className="profile-sidebar">
            <p className="font-weight-bold">Earnings</p>
            <button
              onClick={() => setActiveTab(TABS.STATS)}
              className={activeTab === TABS.STATS ? "ActiveTab" : ""}
            >
              Statistics
            </button>
            <button
              onClick={() => setActiveTab(TABS.USERS)}
              className={activeTab === TABS.USERS ? "ActiveTab" : ""}
            >
              Affiliated Users
            </button>
            <button
              onClick={() => setActiveTab(TABS.ACCOUNT)}
              className={activeTab === TABS.ACCOUNT ? "ActiveTab" : ""}
            >
              Account Details
            </button>
          </div>

          <div
            className="profile-content px-0 pt-0 pb-0"
            style={{ overflow: "hidden", maxHeight: "79vh" }}
          >
            {activeTab === TABS.STATS && <Stats data={affiliatedUser} />}
            {activeTab === TABS.USERS && (
              <AffiliatedUsers
                data={affiliatedUser}
                FinalPermissions={FinalPermissions}
                setRequestModal={setRequestModal}
                setRequestType={setRequestType}
              />
            )}
            {activeTab === TABS.ACCOUNT && (
              <AccountDetails
                data={affiliatedUser}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            )}
          </div>
        </div>
      </div>

      {/* request Access modal */}
      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequestRedux?.includes(requestType)}
          loading={loading}
          icon={
            featureRequestRedux?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequestRedux?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequestRedux?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </div>
  );
}
