import React from "react";
import { Link } from "react-router-dom";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";

import styles from "./user-item.module.css";
import DefaultImage from "assets/images/user.png";

const UserItem = ({ username, profilePhoto, created, name, lastName }) => {
  return (
    <div className={styles.card}>
      <div className={styles.avatar}>
        <Link to={`/learning/users/${username}`}>
          <img
            src={profilePhoto}
            alt="img"
            onError={(e) => (e.target.src = DefaultImage)}
          />
        </Link>
      </div>
      <div className={styles.body}>
        <Link to={`/learning/users/${username}`}>
          {name} {lastName}
        </Link>
        <p>
          Created{" "}
          {created
            ? formatDistanceToNowStrict(new Date(created), {
                addSuffix: true,
              })
            : "N/A"}
        </p>
      </div>
    </div>
  );
};

export default UserItem;
