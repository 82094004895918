import { post, postpro } from '../index';

export const createIFCProject = data => {
  return post('/ifcProject', data, true);
};

export const createProject = data => {
  return post('/projects', data, true);
};

export const createIFCProjectNew = data => {
  return postpro('/projects', data, true);
};

export const createProjectNew = data => {
  return postpro('/projects', data, false);
};
