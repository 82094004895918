import {
  SET_CATEGORIES,
  SET_VERSIONS,
  CLEAR_VERSIONS,
  SET_COUNTRIES,
  SET_AWPS,
  SET_PROJECT_TYPE,
  SET_LABOR_TYPE,
  ADD_NEW_VERSION,
  ADD_NEW_LABOR_TYPE,
  ADD_NEW_PROJECT_TYPE,
  CLEAR_CATEGORIES,
  SET_ALL_CATEGORIES,
  SET_EXPANDED_CATALOGS_DIVISIONS,
  ADD_EXPANDED_CATALOGS_DIVISIONS
} from "constants/action-types/pricing";

export const setProjectTypes = (projectTypes) => {
  return {
    type: SET_PROJECT_TYPE,
    payload: projectTypes,
  };
};

export const setAllCategories = (allCategories) => {
  return {
    type: SET_ALL_CATEGORIES,
    payload: allCategories,
  };
};

export const setLaborTypes = (laborTypes) => {
  return {
    type: SET_LABOR_TYPE,
    payload: laborTypes,
  };
};

export const setTemplates = (template) => {
  return {
    type: SET_VERSIONS,
    payload: template,
  };
};


export const clearTemplates = () => {
  return {
    type: CLEAR_VERSIONS,
    payload: []
  }
};

export const setCountries = (countries) => {
  return {
    type: SET_COUNTRIES,
    payload: countries,
  };
};

export const setCategories = (categories) => {
  return {
    type: SET_CATEGORIES,
    payload: categories,
  };
};

export const clearCategories = () => {
  return {
    type: CLEAR_CATEGORIES,
    payload: [],
  };
};

export const setNewTemplate = (template) => {
  return {
    type: ADD_NEW_VERSION,
    payload: template,
  };
};

export const setNewLaborType = (laborType) => {
  return {
    type: ADD_NEW_LABOR_TYPE,
    payload: laborType,
  };
};

export const setNewProjectType = (projectType) => {
  return {
    type: ADD_NEW_PROJECT_TYPE,
    payload: projectType,
  };
};

export const setAwps = (awps) => {
  return {
    type: SET_AWPS,
    payload: awps,
  };
};

export const setExpandedCatalogsDivisons = (expandedCatalogs) => {
  return {
    type: SET_EXPANDED_CATALOGS_DIVISIONS,
    payload: expandedCatalogs,
  };
};

export const addExpandedCatalogsDivision = (expandedCatalogs) => {
  return {
    type: ADD_EXPANDED_CATALOGS_DIVISIONS,
    payload: expandedCatalogs,
  };
};
