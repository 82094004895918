export const checkDepth = depth => {
  switch (depth) {
    case 0:
      return 'pl-2';
    case 1:
      return 'pl-4';
    case 2:
      return 'pl-8';
    case 3:
      return 'pl-12';
    default:
      return '';
  }
};
