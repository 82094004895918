import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import FollowButton from "./FollowButton";
import {
  getUserProfile,
  follow,
  loadArticles,
} from "../../redux/actions/actions";
import constants from "../../constants";
import Seo from "../seo/Seo";
import { extractImageFromZip } from "../../utils/imageHandler";
import { url } from "api";
import axios from "axios";
import { setSidebarStatus } from "actions/user";
import { useHistory } from "react-router";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import Arrow from "assets/images/leftArr.png";
import Layout from "../layout";
import { eventTrack } from "helpers/ga4Helper";

const Profile = (props) => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({});
  console.log(props?.id);

  const history = useHistory();

  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  useEffect(() => {
    document.body.className = "users show";
    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    getUserProfile(props?.id);
  }, [props?.id]);

  function getUserProfile(_id) {
    axios
      .get(`${url}/api/user/profile/${_id}`)
      .then((res) => {
        setProfile(res.data);
      })
      .catch((err) => console.log(err));
  }

  const onSuccess = (id) => {
    if (profile) {
      return (
        <>
          <title>Articles by {profile.user.name}</title>
          <Seo
            title={`AEC Articles by ${profile.user.name}`}
            siteUrl={`${url}/learning/articles/profile/${props?.id}`}
            authorUsername={profile.user.name}
          />
        </>
      );
    } else {
      const idToFetch = props?.id;
      dispatch(getUserProfile(idToFetch));
    }
  };

  return (
    <Layout userInfoRedux={userInfoRedux}>
      <>
        <Seo
          title={`BidLight | BIM Revit Plugin Articles - ${props.profile?.user?.name}`}
          description={`BidLight | BIM Revit Plugin Articles - ${props.profile?.user?.name} ${props.profile?.user?.lastName}`}
          siteUrl={`https://app.bidlight.com/learning/articles/profile/${props.id}`}
          authorUsername={"BidLight"}
          ogImage={props?.user?.profilePhoto}
        />
      </>

      <div className="inner-header-position desktop-show">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <img src={Arrow} className="left-arrow-img" />
            </div>
            <p
              className="my-heading f-14 ml-3 mr-3 cursor-pointer"
              onClick={() => {
                history.push("/learning/articles");
                eventTrack(
                  "Navigate-Articles-Action",
                  "Navigate-Articles",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
            >
              Articles
            </p>
            <img src={Arrow} className="left-arrow-img" />
            <p className="my-heading f-14 ml-3 mr-3">Profile</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                <>
                  <button
                    onClick={() => {
                      if (
                        FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW)
                      ) {
                        history.push(`/admin/projects-settings`);
                      } else if (
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.MILESTONE_VIEW
                        )
                      ) {
                        history.push(`/admin/milestones`);
                      } else {
                        history.push(`/admin/user-permissions`);
                      }
                      eventTrack(
                        "Navigate-Admin-Section-Action",
                        "Navigate-Admin-Section",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    className="bg-bid px-3 mr-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                    >
                      <path
                        d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                        fill="white"
                      />
                    </svg>
                    Admin
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {Object.keys(profile).length > 0 && (
        <ItemList
          items={{ profile }}
          onSuccess={() => onSuccess(profile.user.id)}
        />
      )}
    </Layout>
  );
};

const ItemList = ({ items, onSuccess }) => {
  const [profilePhoto, setProfilePhoto] = useState("");

  useEffect(() => {
    const getImage = async () => {
      if (items?.profile?.user) {
        const url = await extractImageFromZip(items.profile.user.profilePhoto);
        setProfilePhoto(url);
      }
    };
    getImage();
  }, [items?.profile?.user]);

  return (
    <div className="users show" key={items.profile.user.id}>
      <div className="container main-container">
        <div
          className="animated fadeInUp-small"
          data-animation="fadeInUp-fadeOutDown-slow"
        >
          <div className="hero-wrapper">
            <header className="hero">
              <div className="profile-info">
                <div className="d-flex">
                  <div className="hero-avatar my-2 mr-2">
                    <img
                      alt={items.profile.user.name}
                      className="avatar-image rounded-full"
                      src={profilePhoto}
                      height="40"
                      width="40"
                    />
                  </div>
                  <h1 className="hero-title">{items.profile.user.name}</h1>
                </div>
                <p className="hero-description">{items.profile.user.city}</p>
                <div className="hero-location">
                  <i className="fa fa-map-marker mr-2"></i>
                  {items.profile.user.country
                    ? items.profile.user.country
                    : "N/A"}
                </div>
              </div>
            </header>

            <div>
              <div className="UserFollowContainer">
                <div data-reactroot="">
                  <div className="following-metadata my-2">
                    <span className="following-count mr-2">
                      <span>
                        <span>
                          <b>
                            {items.profile.user.following?.length
                              ? items.profile.user.following?.length
                              : "0"}
                          </b>{" "}
                          Following
                        </span>
                      </span>
                    </span>
                    <span className="follower-count">
                      <span>
                        <span>
                          <b>
                            {items.profile.user.followers?.length
                              ? items.profile.user.followers?.length
                              : "0"}
                          </b>{" "}
                          Followers
                        </span>
                      </span>
                    </span>
                  </div>
                  <div>
                    {items.user && (
                      <FollowButton
                        to_follow={`${items.profile.user.id}`}
                        onSuccess={onSuccess}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="posts-wrapper animated fadeInUp"
          data-animation="fadeInUp-fadeOutDown"
        >
          <h4 className="small-heading border-top pt-3">latest</h4>
          {items.profile.articles.map((article, key) => (
            <div className="post-panel" key={key}>
              <div className="post-metadata">
                <img
                  alt={items.profile.user.name}
                  className="avatar-image"
                  src={profilePhoto}
                  height="40"
                  width="40"
                />
                <div className="post-info">
                  <div className="PopoverLink">
                    <span className="popover-link" data-reactroot="">
                      <a href="javascript:void(0);">
                        {items.profile.user.name}
                      </a>
                    </span>
                  </div>
                  <small>Published • a must read</small>
                </div>
              </div>

              {article?.feature_img?.length > 0 ? (
                <div className="post-picture-wrapper">
                  <a
                    href={`/learning/articles/${article.slug}`}
                    as={`/learning/articles/${article.slug}`}
                  >
                    <img
                      src={`${constants.SERVER_URL}/marketing/branding/hotlink-ok/${article.feature_img}`}
                      alt={article.slug}
                    />
                  </a>
                </div>
              ) : (
                ""
              )}

              <div className="main-body">
                <h3 className="post-title">
                  <a href={`/learning/articles/${article.slug}`}>
                    <h1>{article.title}</h1>
                  </a>
                </h3>
                <div className="post-body">
                  <p
                    className=""
                    dangerouslySetInnerHTML={{ __html: article.description }}
                  ></p>
                </div>
                <a className="read-more" href={`/articles/${article.slug}`}>
                  Read more
                </a>
              </div>

              <div className="post-stats clearfix">
                <div className="pull-left">
                  <div className="like-button-wrapper">
                    <button
                      className="like-button"
                      data-behavior="trigger-overlay"
                      type="submit"
                    >
                      <span className="text d-flex align-items-center">
                        <i className="fa fa-heart mr-2"></i> Like
                        <span className="like-count ml-3 mr-0">
                          {article.claps}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>

                <div className="pull-right">
                  <div className="bookmark-button-wrapper">
                    <form className="button_to" method="get" action="">
                      <button
                        className="bookmark-button"
                        data-behavior="trigger-overlay"
                        type="submit"
                      >
                        <span className="icon-bookmark-o"></span>
                        <span className="hide-text">Bookmark</span>
                      </button>
                    </form>
                  </div>
                </div>

                <div className="response-count pull-right">
                  <a className="response-count" href="javascript:void(0);">
                    0 responses
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
