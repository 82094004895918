import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { setSidebarStatus, setUserAnalytics } from "actions/user";
import Arrow from "assets/images/leftArr.png";
import Arrow1 from "assets/images/icons/arrow.png";

import { useDispatch, useSelector } from "react-redux";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { useHistory } from "react-router";
import { useHttp } from "hooks/useHttp";
import { getDashboardData } from "api/dashboard/getDashboardData";
import moment from "moment";
import DirectIndirectChart from "./DirectIndirectChart";
import Loader from "components/Loader";
import ProjectChart from "./ProjectChart";
import HealthCheckChart from "./HealthCheckChart";
import ActivityLog from "./ActivityLog";
import { formatCurrency } from "helpers/formatCurrency";
import { useHttpWithCache } from "hooks/useHtthWithCache";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { getRevisionProject } from "api/tracking/getRevisionProject";
import Loading from "components/ui-components/loading";
import FooterComponent from "components/LandingPageTemplateComponents/FooterComponent";
import { eventTrack } from "helpers/ga4Helper";

export default function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const userAnalyticsRedux = useSelector((s) => s.user.userAnalytics);
  const projectsRedux = useSelector((s) => s.user?.projects);

  const [dashboardData, setDashboardData] = useState(userAnalyticsRedux);
  const [openTotalProjects, setOpenTotalProjects] = useState(false);
  const [projectRevisions, setProjectRevisions] = useState([]);

  const {
    loading: dashboardLoading,
    dashboardLoadingError,
    request: getDashboardAPI,
  } = useHttpWithCache({
    requestCallback: () => getDashboardData(),
    reduxSetter: (data) => {
      if (!userAnalyticsRedux) {
        dispatch(setUserAnalytics(data?.data));
      }
    },
    reduxReset: () => {
      dispatch(setUserAnalytics(null));
    },
    reduxCash: {
      userAnalytics: userAnalyticsRedux,
    },
    allowToCash: true,
  });

  const { loading: revisionProjectLoading, request: getRevisionProjectAPI } =
    useHttp({
      requestCallback: () => getRevisionProject(userInfoRedux?.company),
      onLoad: (res) => {
        const mergedRevisionAndProjects = [];

        const arr = res?.projects?.forEach((project) => {
          const projectRevisions = res?.revisions
            ?.filter((rev) => rev?.projectId?._id === project?._id)
            ?.map((rev) => ({
              revisionId: rev?._id,
              revisionName: rev?.key,
              timelines: project?.timelines,
            }));

          if (projectRevisions.length > 0) {
            mergedRevisionAndProjects.push({
              projectTitle: project?.title,
              projectNumber: project?.projectNumber,
              _id: project?._id,
              revision: projectRevisions,
            });
          }
        });

        const mergedData = mergeDuplicatesProjectWithRevisions(
          mergedRevisionAndProjects
        );
        console.log(mergedData);
        setProjectRevisions(mergedData);
      },
      onError: (res) => {
        console.log("err", res);
      },
    });

  const mergeDuplicatesProjectWithRevisions = (array) => {
    const mergedItems = {};

    array.forEach((item) => {
      if (mergedItems[item._id]) {
        mergedItems[item._id] = {
          ...mergedItems[item._id],
          revision: [...mergedItems[item._id].revision, ...item.revision],
        };
      } else {
        mergedItems[item._id] = { ...item };
      }
    });

    return Object.values(mergedItems);
  };

  useEffect(() => {
    getDashboardAPI();
  }, []);

  useEffect(() => {
    getRevisionProjectAPI();
  }, [projectsRedux]);

  useEffect(() => {
    setDashboardData(userAnalyticsRedux);
    getOverallEstimationCost(userAnalyticsRedux);
  }, [userAnalyticsRedux]);

  const [totalEstimation, setTotalEstimation] = useState("");

  const getOverallEstimationCost = (data) => {
    const arr = data?.user?.totalEstimationsByUser
      ?.map((item) => {
        return item?.estimationTotalCost?.split("$")[1];
      })
      ?.filter((item) => item);

    const totalSum = arr?.reduce((acc, num) => {
      const number = parseFloat(num.replace(/[, ]/g, ""));
      return acc + number;
    }, 0);
    setTotalEstimation(totalSum?.toFixed(2)); // setSum(totalSum);
  };

  return (
    <div style={{ background: "#eaeaea" }}>
      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <img src={Arrow} className="left-arrow-img" />
            </div>
            <p className="my-heading f-14 ml-3 mr-3">My Dashboard</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            {/* {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
              <div
                className="btn-group btn-group-toggle toggle-group report-btn-group"
                data-toggle="buttons"
              >
                <label
                  className="btn btn-secondary active"
                  // onClick={() => setActiveTab(ACTIVE_TABS.PASSED)}
                >
                  <input
                    type="radio"
                    name="options"
                    id="option1"
                    defaultChecked
                  />
                  Personal
                </label>
                <label
                  className="btn btn-secondary"
                  // onClick={() => setActiveTab(ACTIVE_TABS.FAILED)}
                >
                  <input type="radio" name="options" id="option2" /> Team
                </label>
              </div>
            )} */}
            {/* <input
              type="text"
              className="search-bar"
              // value={searchValue}
              // onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
            /> */}
            {/* <div className="vr mx-3"></div> */}
            <div className="btn-tool-group">
              {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                <>
                  <button
                    onClick={() => {
                      if (
                        FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW)
                      ) {
                        history.push(`/admin/projects-settings`);
                      } else if (
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.MILESTONE_VIEW
                        )
                      ) {
                        history.push(`/admin/milestones`);
                      } else {
                        history.push(`/admin/user-permissions`);
                      }
                      eventTrack(
                        "Navigate-Admin-Section-Action",
                        "Navigate-Admin-Section",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    className="bg-bid px-3 mr-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                    >
                      <path
                        d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                        fill="white"
                      />
                    </svg>
                    Admin
                  </button>
                  <div className="vr mx-3"></div>
                </>
              )}
              <button
                disabled={dashboardLoading}
                // onClick={() => {
                //   dispatch(setUserAnalytics(null));
                //   getDashboardAPI();
                //   getRevisionProjectAPI();
                // }}
                className="px-3 bg-white text-bid shadow-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary mx-3">
        <div className="h-screen flex-grow-1 overflow-y-lg-auto">
          <main className="py-6 bg-surface-secondary">
            <div className="container-fluid">
              {/* first row */}
              <div className="row g-6 mt-3">
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="dashboard-cards card border-0">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div
                            className="icon icon-shape text-white text-lg rounded-circle"
                            style={{ background: "#283f4b" }}
                          >
                            <i className="bi bi-wallet2"></i>
                          </div>
                        </div>

                        <div className="col">
                          <div className="d-flex mt-2 align-items-center">
                            <p className="h4 font-weight-bold mb-0 poppins-bold">
                              {dashboardLoading ? (
                                <i className="fa fa-spinner small-spinner" />
                              ) : (
                                dashboardData?.user?.projectWorked?.length
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <span className="dashboard-text">
                              Total Projects
                            </span>
                            <span
                              className="small-redirect-text"
                              onClick={() => {
                                history.push("./personal-area");
                                eventTrack(
                                  "Navigate-Projects-Action",
                                  "Navigate-Projects",
                                  userInfoRedux?.name,
                                  "+1",
                                  false,
                                  "+1"
                                );
                              }}
                            >
                              View all
                              <i className="fa fa-arrow-right ml-1"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="dashboard-cards card border-0">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div
                            className="icon icon-shape text-white text-lg rounded-circle"
                            style={{ background: "#283f4b" }}
                          >
                            <i className="bi bi-wallet2"></i>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex mt-2 align-items-center">
                            <p className="h4 font-weight-bold mb-0 poppins-bold">
                              {dashboardLoading ? (
                                <i className="fa fa-spinner small-spinner" />
                              ) : (
                                `$ ${formatCurrency(
                                  dashboardData?.user?.totalCostIncured
                                )}`
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <span className="dashboard-text">
                              Total Cost Incured
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="dashboard-cards card border-0">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div
                            className="icon icon-shape text-white text-lg rounded-circle"
                            style={{ background: "#283f4b" }}
                          >
                            <i className="bi bi-hourglass"></i>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex mt-2 align-items-center">
                            <p className="h4 font-weight-bold mb-0 poppins-bold">
                              {dashboardLoading ? (
                                <i className="fa fa-spinner small-spinner" />
                              ) : (
                                `${formatCurrency(
                                  dashboardData?.user?.totalHoursWorked
                                )}`
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <span className="dashboard-text">
                              Total Hours Logged
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="dashboard-cards card border-0">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div
                            className="icon icon-shape text-white text-lg rounded-circle"
                            style={{ background: "#283f4b" }}
                          >
                            <i className="bi bi-clock-history"></i>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex mt-2 align-items-center">
                            <p className="h4 font-weight-bold mb-0 poppins-bold">
                              {dashboardLoading ? (
                                <i className="fa fa-spinner small-spinner" />
                              ) : (
                                moment(
                                  dashboardData?.user?.lastLoginTime
                                ).format("DD/MM/YYYY HH:mm")
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <span className="dashboard-text">Last Login</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* second row */}
              <div className="row g-6 mt-3">
                {/* total projects */}
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="dashboard-cards card border-0">
                    <div className="card-body p-3">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div
                            className="icon icon-shape text-white text-lg rounded-circle"
                            style={{ background: "#283f4b" }}
                          >
                            <i className="bi bi-kanban"></i>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex mt-2 align-items-center">
                            <p className="h4 font-weight-bold mb-0 poppins-bold">
                              {revisionProjectLoading ? (
                                <i className="fa fa-spinner small-spinner" />
                              ) : (
                                projectRevisions?.length
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <span className="dashboard-text">
                              Total Projects with Revisions
                            </span>
                            <div
                              className="expand-div"
                              onClick={() =>
                                setOpenTotalProjects(!openTotalProjects)
                              }
                            >
                              {openTotalProjects ? (
                                <i className="fa fa-minus"></i>
                              ) : (
                                <i className="fa fa-plus"></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`projectname_number_container ${
                          openTotalProjects
                            ? "project-cont-height mt-4"
                            : "height-0"
                        }`}
                      >
                        <Accordion allowZeroExpanded>
                          {projectRevisions?.map((item, key) => (
                            <AccordionItem key={`proj_rev_${key}`}>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <AccordionItemState>
                                    {({ expanded }) => (
                                      <div className="projectname_number_div">
                                        <p>
                                          {key + 1}
                                          {")"} {item?.projectTitle}
                                        </p>
                                        <img
                                          src={Arrow1}
                                          className={
                                            expanded
                                              ? "arrow-icon"
                                              : "arrow-icon  transform-180"
                                          }
                                        />
                                      </div>
                                    )}
                                  </AccordionItemState>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <Accordion
                                  allowZeroExpanded
                                  className="inner-accordian"
                                >
                                  {item?.revision?.map((revItem, revkey) => (
                                    <AccordionItem className="inner-accordian-item" key={`rev_${revkey}`}>
                                      <AccordionItemHeading className="inner-accordian-heading">
                                        <AccordionItemButton>
                                          <AccordionItemState>
                                            {({ expanded }) => (
                                              <div className="projectname_number_div ml-3">
                                                <p>{revItem?.revisionName}</p>
                                                <img
                                                  src={Arrow1}
                                                  className={
                                                    expanded
                                                      ? "arrow-icon"
                                                      : "arrow-icon  transform-180"
                                                  }
                                                />
                                              </div>
                                            )}
                                          </AccordionItemState>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel className="nested-inner-panel">
                                        {revItem?.timelines?.length > 0 ? (
                                          revItem?.timelines[
                                            revItem?.timelines?.length - 1
                                          ]?.summary?.map((sum) => (
                                            <div
                                              key={sum.Code || sum.Description}
                                              className="summary-item"
                                            >
                                              <div className="projectname_number_div">
                                                <p
                                                  className="code"
                                                  title={sum?.Code}
                                                >
                                                  {sum?.Code || "N/A"}
                                                </p>
                                                <p
                                                  className="desc"
                                                  title={sum?.Description}
                                                >
                                                  {sum?.Description || "N/A"}
                                                </p>
                                                <p
                                                  className="totalCost"
                                                  title={sum?.TotalCost}
                                                >
                                                  {
                                                    revItem?.timelines[
                                                      revItem?.timelines
                                                        ?.length - 1
                                                    ]?.currencysymb
                                                  }
                                                  {sum?.TotalCost.toFixed(2) ||
                                                    "N/A"}
                                                </p>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <div className="projectname_number_div">
                                            <p>No Record</p>
                                          </div>
                                        )}
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  ))}
                                </Accordion>
                              </AccordionItemPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

                {/* total estimations */}
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="dashboard-cards card border-0">
                    <div className="card-body p-3">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div
                            className="icon icon-shape text-white text-lg rounded-circle"
                            style={{ background: "#283f4b" }}
                          >
                            <i className="bi bi-cash-stack"></i>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex mt-2 align-items-center">
                            <p className="h4 font-weight-bold mb-0 poppins-bold">
                              {dashboardLoading ? (
                                <i className="fa fa-spinner small-spinner" />
                              ) : (
                                `$ ${formatCurrency(totalEstimation)}`
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <span className="dashboard-text">
                              Total Estimations
                            </span>
                            <div
                              className="expand-div"
                              onClick={() =>
                                setOpenTotalProjects(!openTotalProjects)
                              }
                            >
                              {openTotalProjects ? (
                                <i className="fa fa-minus"></i>
                              ) : (
                                <i className="fa fa-plus"></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`projectname_number_container ${
                          openTotalProjects
                            ? "project-cont-height mt-4"
                            : "height-0"
                        }`}
                      >
                        {dashboardData?.user?.totalEstimationsByUser?.map(
                          (item, key) => {
                            return (
                              <div
                                className="projectname_number_div"
                                key={key}
                                onClick={() =>
                                  item?.estimationId !== "N/A"
                                    ? history.push(
                                        `/personal-area/projects/${item?.projectId?._id}/timelines/${item?.estimationId}`
                                      )
                                    : null
                                }
                              >
                                <p>
                                  {key + 1}
                                  {")"} {item?.projectId?.title}
                                </p>
                                <p>{item?.estimationTotalCost}</p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* shared access */}

                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="dashboard-cards card border-0">
                    <div className="card-body p-3">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div
                            className="icon icon-shape text-white text-lg rounded-circle"
                            style={{ background: "#283f4b" }}
                          >
                            <i className="bi bi-share"></i>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex mt-2 align-items-center">
                            <p className="h4 font-weight-bold mb-0 poppins-bold">
                              {dashboardLoading ? (
                                <i className="fa fa-spinner small-spinner" />
                              ) : (
                                dashboardData?.user?.totalSharedAccessByUser
                                  ?.length
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <span className="dashboard-text">
                              Shared Access
                            </span>
                            <div
                              className="expand-div"
                              onClick={() =>
                                setOpenTotalProjects(!openTotalProjects)
                              }
                            >
                              {openTotalProjects ? (
                                <i className="fa fa-minus"></i>
                              ) : (
                                <i className="fa fa-plus"></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`projectname_number_container ${
                          openTotalProjects
                            ? "project-cont-height mt-4"
                            : "height-0"
                        }`}
                      >
                        {dashboardData?.user?.totalSharedAccessByUser?.map(
                          (item, key) => {
                            return (
                              <div className="projectname_number_div" key={key}>
                                <p className="text-lowercase">
                                  {key + 1}
                                  {")"} {item?.email}
                                </p>
                                <p>
                                  {item?.date
                                    ? moment(item?.date).format("DD/MM/YYYY")
                                    : "N/A"}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Pending access */}

                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="dashboard-cards card border-0">
                    <div className="card-body p-3">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div
                            className="icon icon-shape text-white text-lg rounded-circle"
                            style={{ background: "#283f4b" }}
                          >
                            <i className="bi bi-people"></i>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex mt-2 align-items-center">
                            <p className="h4 font-weight-bold mb-0 poppins-bold">
                              {dashboardLoading ? (
                                <i className="fa fa-spinner small-spinner" />
                              ) : (
                                dashboardData?.user?.totalPendingAccessByUser
                                  ?.length
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <span className="dashboard-text">
                              Pending Access
                            </span>
                            <div
                              className="expand-div"
                              onClick={() =>
                                setOpenTotalProjects(!openTotalProjects)
                              }
                            >
                              {openTotalProjects ? (
                                <i className="fa fa-minus"></i>
                              ) : (
                                <i className="fa fa-plus"></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`projectname_number_container ${
                          openTotalProjects
                            ? "project-cont-height mt-4"
                            : "height-0"
                        }`}
                      >
                        {dashboardData?.user?.totalPendingAccessByUser?.map(
                          (item, key) => {
                            return (
                              <div className="projectname_number_div" key={key}>
                                <p className="text-lowercase">
                                  {key + 1}
                                  {")"} {item?.email}
                                </p>
                                <p>{moment(item?.date).format("DD/MM/YYYY")}</p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* third row */}
              <div className="row g-6 mt-3">
                <div className="col-xl-9">
                  {/*  */}
                  <div className="row">
                    <div className="col-xl-4">
                      <div className="dashboard-cards card border-0 mb-7">
                        <div>
                          <p className="chart-header">
                            Direct/Indirect Projects
                          </p>
                          <div className="d-flex justify-content-center">
                            {dashboardLoading ? (
                              <div
                                style={{
                                  height: "250px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <i className="fa fa-spinner small-spinner" />
                              </div>
                            ) : (
                              <DirectIndirectChart
                                data={dashboardData?.user?.projectWorked}
                              />
                            )}
                          </div>
                          <div className="d-flex justify-content-center">
                            <p className="legend-p">
                              <span
                                className="legend-box"
                                style={{ background: "#283f4b" }}
                              ></span>{" "}
                              Direct
                            </p>
                            <p className="legend-p">
                              <span
                                className="legend-box"
                                style={{ background: "#ddd" }}
                              ></span>{" "}
                              InDirect
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-8">
                      <div className="dashboard-cards card border-0 mb-7">
                        <div>
                          <p className="chart-header">Hours per project</p>
                          <div className="d-flex justify-content-center">
                            {dashboardLoading ? (
                              <div
                                style={{
                                  height: "250px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <i className="fa fa-spinner small-spinner" />
                              </div>
                            ) : (
                              <ProjectChart
                                chartData={
                                  dashboardData?.user?.totalHoursPerProject
                                }
                              />
                            )}
                          </div>
                          <div className="d-flex justify-content-center">
                            <p className="legend-p">
                              <span
                                className="legend-box"
                                style={{ background: "#283f4b" }}
                              ></span>{" "}
                              Project
                            </p>
                            {/* <p className="legend-p">
                          <span
                            className="legend-box"
                            style={{ background: "#ddd" }}
                          ></span>{" "}
                          InDirect
                        </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    {/* <div className="col-xl-4">
                      <div className="dashboard-cards card border-0 mb-7">
                        <div>
                          <p className="chart-header">Estimated Projects</p>
                          <div className="d-flex justify-content-center">
                            <DirectIndirectChart
                              data={dashboardData?.user?.projectWorked}
                            />
                          </div>
                          <div className="d-flex justify-content-center">
                            <p className="legend-p">
                              <span
                                className="legend-box"
                                style={{ background: "#283f4b" }}
                              ></span>{" "}
                              Direct
                            </p>
                            <p className="legend-p">
                              <span
                                className="legend-box"
                                style={{ background: "#ddd" }}
                              ></span>{" "}
                              InDirect
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-xl-12">
                      <div className="dashboard-cards card border-0 mb-7">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="chart-header">Health Checks</p>
                            <div className="d-flex justify-content-center">
                              <p className="legend-p">
                                <span
                                  className="legend-box"
                                  style={{ background: "#365E32" }}
                                ></span>{" "}
                                Pass
                              </p>
                              <p className="legend-p">
                                <span
                                  className="legend-box"
                                  style={{ background: "#973131" }}
                                ></span>{" "}
                                Failed
                              </p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            <HealthCheckChart
                              chartData={
                                dashboardData?.user?.totalHealthCheckReports
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <ActivityLog
                    data={dashboardData?.user?.activityLogs}
                    loading={dashboardLoading}
                  />
                </div>
              </div>

              {/* last row */}
            </div>
          </main>
        </div>
      </div>

      <div className="mt-3">
        <FooterComponent />
      </div>
    </div>
  );
}
