import React from "react";

export const RullerIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.48154 13.5002L3.88881 16.0929L7.12972 19.3338L19.4452 7.01839L16.2042 3.77748L14.2597 5.72203L15.5561 7.01839L14.2597 8.31475L12.9634 7.01839L11.0188 8.96293L12.9634 10.9075L11.667 12.2038L9.72244 10.2593L7.7779 12.2038L9.07426 13.5002L7.7779 14.7966L6.48154 13.5002ZM16.8524 1.83294L21.3897 6.37021C21.7477 6.72819 21.7477 7.30859 21.3897 7.66657L7.7779 21.2784C7.41992 21.6364 6.83952 21.6364 6.48154 21.2784L1.94427 16.7411C1.58628 16.3832 1.58628 15.8027 1.94427 15.4448L15.5561 1.83294C15.914 1.47496 16.4944 1.47496 16.8524 1.83294Z"
        fill="#9C9C9C"
      />
    </svg>
  );
};
