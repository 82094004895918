import React, { useState, useEffect, useRef } from "react";
import constants from "../../constants";
import axios from "axios";
import "./editor.css";
import UploadIcon from "assets/images/icons/Featured icon.png";
import { tokenRef } from "api";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import MediumEditor from "medium-editor";
import addNotification from "helpers/notify";
import CONSTANTS from "../../constants";

import { useDispatch, useSelector } from "react-redux";
import { setSidebarStatus } from "actions/user";
import { useHistory } from "react-router";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import Arrow from "assets/images/leftArr.png";

const stylesArea = {
  minWidth: "100%",
  minHeight: "150px",
  padding: "12px 20px",
  boxSizing: "border-box",
  border: "2px solid #ccc",
  borderRadius: "4px",
  color: "#3a5765",
  backgroundColor: "#f8f8f8",
  fontSize: 14,
  resize: "vertical",
  overflowY: "scroll",
};

const stylesBtn = {
  backgroundColor: "#6e98ae",
  minWidth: "100%",
  border: "none",
  color: "white",
  padding: "15px 32px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "16px",
};

const styleDropdown = {
  marginLeft: "10px",
  color: "#6e98ae",
  backgroundColor: "#fff",
  border: "1px solid #6e98ae",
  borderRadius: "5px",
};

const Editor = ({ slug }) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("Tutorials");
  const [imgSrc, setImgSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listBodyImages, setListBodyImages] = useState("");
  const [articleId, setArticleId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [profilePic, setProfilePic] = useState(null);

  const fileUploader = useRef();
  const uploadedBodyImage = useRef();
  const history = useHistory();

  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  useEffect(() => {
    const dom = document.getElementById("medium-editable");

    const editor = new MediumEditor(dom, {
      autoLink: true,
      delay: 1000,
      targetBlank: true,
      toolbar: {
        buttons: [
          "bold",
          "italic",
          "quote",
          "underline",
          "anchor",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "strikethrough",
          "subscript",
          "superscript",
          "pre",
          "image",
          "html",
          "justifyCenter",
        ],
        allowMultiParagraphSelection: true,
        diffLeft: 25,
        diffTop: 10,
        firstButtonClass: "medium-editor-button-first",
        lastButtonClass: "medium-editor-button-last",
        relativeContainer: null,
        standardizeSelectionStart: true,
        static: true,
        align: "center",
        sticky: true,
        updateOnEmptySelection: true,
      },

      anchor: {
        placeholderText: "Type a link",
        customClassOption: "btn",
        customClassOptionText: "Create Button",
      },
      paste: {
        cleanPastedHTML: true,
        cleanAttrs: ["style", "dir"],
        cleanTags: ["label", "meta"],
        unwrapTags: ["sub", "sup"],
      },
      anchorPreview: {
        hideDelay: 300,
      },
      placeholder: { text: "Tell your Story ...", hideOnClick: true },
    });

    editor.subscribe("editableInput", () => {
      setTitle(document.getElementById("editor-title").value);
      setText(editor.getContent(0));
      const htmlExtract = extractContent(editor.getContent(0));
      setDescription(`${htmlExtract.substring(0, 350)}...`);
    });

    if (slug) {
      getArticle(editor);
    }
  }, [slug]);

  const extractContent = (HTMLPart) =>
    HTMLPart.replace(/\n/gi, "")
      .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/gi, "")
      .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/gi, "")
      .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/gi, "")
      .replace(/<\/\s*(?:p|div)>/gi, "\n")
      .replace(/<br[^>]*\/?>/gi, "\n")
      .replace(/<[^>]*>/gi, "")
      .replace("&nbsp;", " ")
      .replace(/[^\S\r\n][^\S\r\n]+/gi, " ");

  useEffect(() => {
    (async () => {
      const extractedImg = await extractImageFromZip(
        userInfoRedux?.profilePhoto
      );
      setProfilePic(extractedImg);
      setUserInfo(userInfoRedux);
    })();
  }, [userInfoRedux]);

  const getArticle = async (editor) => {
    const _url = `${constants.SERVER_URL}/api/`;
    const res = await fetch(`${_url}article/slug/${slug}`);
    const response = await res.json();
    if (response) {
      document.getElementById("editor-title").value = response.title;
      editor.setContent(response.text.replace(/https:\/\/(www\.)?bidlight\.com/g, `${CONSTANTS.SERVER_URL}`));
      document.getElementById(
        "image_preview"
      ).src = `${constants.SERVER_URL}/marketing/branding/hotlink-ok/${response.feature_img}`;
      setImgSrc(response.feature_img);
      setArticleId(response._id);
      setCategory(response.category);
    }
  };

  const previewImg = () => {
    const file = fileUploader.current.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      document.getElementById("image_preview").src = e.target.result;
      setImgSrc(file);
    };
    reader.readAsDataURL(file);
  };

  const publishStoryAPI = async (file) => {
    setLoading(true);
    const _url = `${constants.SERVER_URL}/api/`;
    const data = new FormData();
    data.append("image", file);
    data.append("text", text);
    data.append("title", title);
    data.append("category", category);
    data.append("claps", 0);
    data.append("description", description);
    data.append("author", userInfo.id);
    const token = localStorage.getItem(tokenRef);

    try {
      await axios.post(`${_url}article`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      addNotification("Story published successfully", "success");
      history.push("/learning/articles");
    } catch {
      setLoading(false);
      addNotification("Something went wrong", "danger");
    }
  };

  const updateStoryAPI = async (file) => {
    setLoading(true);
    const _url = `${constants.SERVER_URL}/api/`;
    const data = new FormData();
    if (typeof file === "string") {
      data.append("file", file);
    } else {
      data.append("image", file);
    }
    data.append("text", text);
    data.append("title", title);
    data.append("category", category);
    data.append("description", description);
    data.append("_id", articleId);
    const token = localStorage.getItem("Auth");

    try {
      await axios.put(`${_url}article`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      addNotification("Article updated successfully", "success");
      history.push("/learning/articles");
    } catch {
      setLoading(false);
      addNotification("Something went wrong", "danger");
    }
  };

  const publishStory = async () => {
    const file = fileUploader.current.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (file.type === 'image/gif') {
          // Skip resizing for GIF and directly upload
          publishStoryAPI(file);
        } else {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
    
            let width = img.width;
            let height = img.height;
            const maxWidth = 800;
            const maxHeight = 350;
    
            if (width > maxWidth || height > maxHeight) {
              if (width / height > maxWidth / maxHeight) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
              } else {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
              }
            }
    
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
    
            canvas.toBlob((blob) => {
              const imageFile = new File([blob], file.name, { type: file.type });
    
              publishStoryAPI(imageFile);
            }, file.type);
          };
          img.src = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    } else {
      publishStoryAPI();
    }
  };

  const updateStory = async () => {
    const file = fileUploader.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (file.type === 'image/gif') {
          updateStoryAPI(file);
        } else {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
  
            let width = img.width;
            let height = img.height;
            const maxWidth = 800;
            const maxHeight = 350;
  
            if (width > maxWidth || height > maxHeight) {
              if (width / height > maxWidth / maxHeight) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
              } else {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
              }
            }
  
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
  
            canvas.toBlob((blob) => {
              const imageFile = new File([blob], file.name, { type: file.type });
              updateStoryAPI(imageFile);
            }, file.type);
          };
          img.src = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    } else {
      updateStoryAPI(imgSrc);
    }
  };

  const handleClick = () => {
    fileUploader.current.click();
  };

  const uploadImage = () => {
    setLoading(true);
    const _url = `${constants.SERVER_URL}/api/`;
    const image = uploadedBodyImage.current.files[0];
    const data = new FormData();

    const token = localStorage.getItem(tokenRef);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;
        const maxWidth = 800;
        const maxHeight = 350;

        if (width > maxWidth || height > maxHeight) {
          if (width / height > maxWidth / maxHeight) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          } else {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          console.log(blob);
          const imgFile = new File([blob], image.name, { type: image.type });
          data.append("image", imgFile);
          axios
            .post(`${_url}article/uploadimage`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              var url = `${constants.SERVER_URL}/marketing/branding/hotlink-ok/${res.data}\n`;
              setListBodyImages(url);
              document.getElementById("listuploadedurlsarea").value += url;

              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }, image.type);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(image);
  };

  return (
    <div className="personal-area min-h-full">
      <div className="inner-header-position desktop-show">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <img src={Arrow} className="left-arrow-img" />
            </div>
            <p
              className="my-heading f-14 ml-3 mr-3 cursor-pointer"
              onClick={() => history.push("/learning/articles")}
            >
              Articles
            </p>
            <img src={Arrow} className="left-arrow-img" />
            <p className="my-heading f-14 ml-3 mr-3">Add Article</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                <>
                  <button
                    onClick={() => {
                      if (
                        FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW)
                      ) {
                        history.push(`/admin/projects-settings`);
                      } else if (
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.MILESTONE_VIEW
                        )
                      ) {
                        history.push(`/admin/milestones`);
                      } else {
                        history.push(`/admin/user-permissions`);
                      }
                    }}
                    className="bg-bid px-3 mr-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                    >
                      <path
                        d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                        fill="white"
                      />
                    </svg>
                    Admin
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="editor-bg container position-relative bg-grey  pt-3">
        <div className="row position-relative">
          <div className="col-md-8 position-relative">
            <div>
              <div id="main-post">
                <div className="post-metadata">
                  <img
                    alt={userInfo?.name}
                    className="avatar-image"
                    src={profilePic}
                    height="40"
                    width="40"
                  />
                  <div className="post-info">
                    <div
                      className="PopoverLink"
                      props='{"user_id":608,"url":"/users/netk","children":"netk"}'
                    >
                      <span className="popover-link" data-reactroot="">
                        <a href="">
                          {userInfo?.name} {userInfo?.lastName}
                        </a>
                      </span>
                    </div>
                    <small>{userInfo?.email}</small>
                  </div>
                </div>

                <form className="editor-form main-editor" autoComplete="off">
                  <div className="my-2">
                    <label className="labels font-weight-bold text-bid">
                      Category
                    </label>
                    <select
                      style={styleDropdown}
                      onChange={(e) => setCategory(e.target.value)}
                      value={category}
                      className="form-control ml-0"
                    >
                      <option value="Tutorials">Tutorials</option>
                      <option value="Blogs">Blogs</option>
                      <option value="Success Stories">Success Stories</option>
                    </select>
                  </div>
                  <div
                    className="profile-upload-box cursor-pointer"
                    onClick={() => handleClick()}
                  >
                    <img src={UploadIcon} />
                    <p className="font-weight-bold text-bid">
                      Click to upload{" "}
                      <span className="font-weight-light">
                        or drag and drop
                      </span>
                    </p>
                    <p className="font-weight-bold">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </p>
                  </div>

                  <div className="d-none">
                    <input
                      type="file"
                      onChange={() => previewImg()}
                      id="file"
                      ref={fileUploader}
                    />
                  </div>

                  <img
                    src=""
                    className="d-block mt-4 editor-image-preview"
                    id="image_preview"
                  />

                  <div
                    className="existing-img-previewer"
                    id="existing-img-previewer"
                  ></div>

                  <div className="my-2">
                    <label className="labels font-weight-bold text-bid">
                      Title
                    </label>
                    <textarea
                      id="editor-title"
                      type="text"
                      className="form-control Editor-title"
                      onChange={(e) => setTitle(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="my-2 position-relative">
                    <label className="labels font-weight-bold text-bid">
                      Summary
                    </label>
                    <textarea
                      id="medium-editable"
                      className="form-control editor-summary"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 position-relative">
            <div className="upload-box">
              <div className="editor-image-upload-box">
                <label>Uploaded body image(s) : </label>
                <input
                  type="file"
                  style={stylesBtn}
                  ref={uploadedBodyImage}
                  onChange={() => uploadImage()}
                  className="w-100"
                />
                <label>Copy uploaded image(s) url(s) from below : </label>

                <textarea
                  id="listuploadedurlsarea"
                  type="textarea"
                  name="textValue"
                  style={stylesArea}
                ></textarea>
              </div>

              <div className="d-flex mt-3">
                <ul className="nav navbar-nav navbar-right">
                  <li className="publish-button">
                    <button
                      onClick={() => {
                        history.push("/learning/articles");
                      }}
                      className={"header-btn bg-white text-bid border-bid mr-2"}
                    >
                      {"Cancel"}
                    </button>
                  </li>
                </ul>
                <ul className="nav navbar-nav navbar-right">
                  <li className="publish-button">
                    <button
                      onClick={() =>
                        articleId ? updateStory() : publishStory()
                      }
                      className={"header-btn bg-bid text-white border-bid"}
                      role="button"
                    >
                      {loading === true ? "Publishing...." : "Publish"}{" "}
                      <i className="fa fa-globe ml-3"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editor;
