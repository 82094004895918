import React from 'react'

import Navigation from '../../navigation'

const Sidebar = () => {
  return (
      <Navigation />
  )
}

export default Sidebar
