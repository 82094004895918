import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";

import ChangeExpirationModal from "components/ChangeExpirationDateModal";
import Loading from "components/ui-components/loading";
import Error from "components/ui-components/Error";

import { mainAdminGetCompanies } from "api/users/mainAdminGetCompanies";
import { useHttpWithCache } from "hooks/useHtthWithCache";
import { mainAdminSetCompanies } from "actions/main-admin";
import { Maybe } from "helpers/maybeFunctor";
import formatTime from "helpers/formatTime";
import { useSearch } from "hooks/useSearch";
import { setSidebarStatus } from "actions/user";
import GetLicence from "components/GetLicence";

const MainAdmin = () => {
  const [modal, setModal] = useState(false);
  const [companyToEdit, setCompanyToEdit] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const search = useSearch();
  const companiesRedux = useSelector((s) => s.mainAdmin.company);
  const companiesProjectsRedux = useSelector(
    (s) => s.mainAdmin.company_projects
  );
  const allUsersRedux = useSelector((s) => s.mainAdmin.all_users);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [openAddCompany, setOpenAddCompany] = useState(false);

  const { loading, error, request, refresh } = useHttpWithCache({
    requestCallback: () => mainAdminGetCompanies(),
    reduxSetter: (data) => {
      dispatch(mainAdminSetCompanies(data.company));
      dispatch({
        type: "MAIN_ADMIN_SET_ALL_COMPANIES_PROJECTS",
        payload: data.company_projects,
      });
      dispatch({
        type: "MAIN_ADMIN_SET_ALL_USERS",
        payload: data.all_users,
      });
    },
    reduxReset: () => {
      dispatch(mainAdminSetCompanies(null));
      dispatch({
        type: "MAIN_ADMIN_SET_ALL_COMPANIES_PROJECTS",
        payload: null,
      });
      dispatch({
        type: "MAIN_ADMIN_SET_ALL_USERS",
        payload: null,
      });
    },
    reduxCash: {
      company: companiesRedux,
      company_projects: companiesProjectsRedux,
      all_users: allUsersRedux,
    },
    allowToCash: true,
  });

  useEffect(() => request(), []);

  const maybeCompanies = Maybe.of(companiesRedux)
    .map((companies) =>
      search(
        companies,
        ["companyName", "companyAddress", "plan"],
        inputSearchValue
      )
    )
    .map((companies) => {
      return (
        <>
          <div className="position-relative">
            <MDBTable small className="admin-table sticky-table-header">
              <MDBTableHead color="" textWhite>
                <tr>
                  <th width="10%">Company name</th>
                  <th>Address</th>
                  <th>Phone number</th>
                  <th>Owner</th>
                  <th>Size</th>
                  <th>Industry</th>
                  <th>Exp Date</th>
                  <th>Change Exp Date</th>
                  <th>Users</th>
                  <th>Projects</th>
                  <th>Permissions</th>
                  <th>Plan</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {companies.map((companyObject) => {
                  const {
                    companyName,
                    companyAddress,
                    companyPhoneNumber,
                    companyOwner,
                    companySize,
                    companyIndustry,
                    dateOfExpiration,
                    plan,
                    role,
                    trial,
                    _id,
                  } = companyObject;
                  return (
                    <tr key={Math.random()}>
                      <td>{companyName}</td>
                      <td>{companyAddress}</td>
                      <td>{companyPhoneNumber}</td>
                      <td>{companyOwner}</td>
                      <td>{companySize}</td>
                      <td>{companyIndustry}</td>
                      <td>{formatTime(dateOfExpiration)}</td>
                      <td>
                        <button
                          className="see-btn table-btn btn-radius change-btn"
                          onClick={() => {
                            setCompanyToEdit(companyObject);
                            setModal(true);
                          }}
                        >
                          <i className="fa fa-random"></i>
                          Change
                        </button>
                      </td>
                      <td>
                        <button
                          className="see-btn table-btn btn-radius"
                          onClick={() =>
                            history.push(`/main-admin/users/${_id}`)
                          }
                        >
                          <i className="fa fa-eye"></i>
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          className="see-btn table-btn btn-radius"
                          onClick={() =>
                            history.push(`/main-admin/projects/${_id}`)
                          }
                        >
                          <i className="fa fa-eye"></i>
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          className="see-btn table-btn btn-radius"
                          onClick={() =>
                            history.push(`/main-admin/permissions/${_id}`)
                          }
                        >
                          <i className="fa fa-eye"></i>
                          View
                        </button>
                      </td>
                      <td>
                        {plan?.map((planobject) => {
                          const { product } = planobject;
                          return <td> {product}</td>;
                        })}
                      </td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </div>
        </>
      );
    });

  const { value: companiesToJSX } = maybeCompanies;

  return (
    <div className="personal-area">
      <div className="inner-header-position">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
            <Link
              to={"/admin/projects-settings"}
              className="my-heading f-14 ml-3 mr-3 headerLink"
            >
              Admin
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
            >
              <path
                d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                fill="#9E9E9E"
              />
            </svg>
            <p className="my-heading f-14 ml-3 mr-3">All Companies</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <input
              type="text"
              className="search-bar"
              value={inputSearchValue}
              onChange={(e) => setInputSearchValue(e.target.value)}
              placeholder="Search"
              disabled={loading}
            />
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              <button
                onClick={() => setOpenAddCompany(true)}
                className="px-3 bg-bid text-white shadow-hover"
              >
                <i className="fa fa-plus mr-2"></i>
                Add Company
              </button>
              <button
                onClick={() => refresh()}
                className="px-3 bg-white text-bid shadow-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="#3A5765"
                  />
                  <path
                    d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                    fill="black"
                    fillOpacity="0.2"
                  />
                </svg>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-large">
        {/* page title */}
        <div className="w-100 rounded-2 border bg-white pt-0">
          <div className="adminTable">
            {companiesToJSX}
            {error ? <Error /> : null}
            {loading ? <Loading color="dark" text="Loading..." /> : null}
          </div>
        </div>
      </div>

      <ChangeExpirationModal
        isOpen={modal}
        toggle={() => setModal((s) => !s)}
        companyToEdit={companyToEdit}
      />

      <MDBModal
        isOpen={openAddCompany}
        toggle={() => setOpenAddCompany(false)}
        centered
        className="expiration-modal"
      >
        <MDBModalHeader toggle={() => setOpenAddCompany(false)}>
          Add Company
        </MDBModalHeader>
        <MDBModalBody>
          <GetLicence setOpenAddCompany={setOpenAddCompany}/>
        </MDBModalBody>
      </MDBModal>
    </div>
  );
};

export default MainAdmin;
