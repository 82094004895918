import React, { useEffect, useState } from 'react';
import { checkLicence } from '../../api/licences/checkLicence';
import addNotification from '../../helpers/notify';
import { useHistory, useParams } from 'react-router';
import { useHttp } from '../../hooks/useHttp';

const withKey = Wrapped => {
  return props => {
    const { key,role } = useParams();
    const history = useHistory();

    const [licenseData, setLicenseData] = useState({
      email: '',
      key: '',
      role:'',
    });

    const { request: checkLicenseAPI } = useHttp({
      requestCallback: () => checkLicence({ key }),
      onLoad: res => {
        console.log('res ', res);
        setLicenseData({
          email: res.mail,
          key ,
          role : res.role,
        });
      },
      onError: res => {
        addNotification('You have no permission to register!', 'danger');
        history.push('/');
      },
    });

    useEffect(() => {
      checkLicenseAPI();
    }, []);

    return <Wrapped licenseData={licenseData} {...props} />;
  };
};

export default withKey;
