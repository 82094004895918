export const SET_USER = 'SET_USER';
export const SET_MILESTONES = 'SET_MILESTONES';
export const UPDATE_MILESTONE = 'UPDATE_MILESTONE';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_COMPANY_USERS = 'SET_COMPANY_USERS';
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT';
export const UPDATE_IFC_PROJECT = 'UPDATE_IFC_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_NEW_PROJECT';
export const REMOVE_USER_FROM_PROJECT = 'REMOVE_USER_FROM_PROJECT';
export const ADD_USER_TO_PROJECT = 'ADD_USER_FROM_PROJECT';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const SET_PERM = "SET_PERM";
export const FEATURE_REQUEST = "FEATURE_REQUEST";
export const SET_SIDEBAR = "SET_SIDEBAR";
export const SET_ADMIN_MOBILE_SIDEBAR = "SET_ADMIN_MOBILE_SIDEBAR";
export const SET_CATALOGS_TREE = "SET_CATALOGS_TREE";
export const SET_CATALOGS = "SET_CATALOGS";
export const SET_COMPANY_PERMISSION = "SET_COMPANY_PERMISSION" ;
export const SET_ALL_PERMISSION = "SET_ALL_PERMISSION" ;
export const SET_ALL_HEALTH_REPORT = "SET_ALL_HEALTH_REPORT" ;
export const SET_ALL_PARAMETERS = "SET_ALL_PARAMETERS" ;
export const SET_COMPANY_SETTINGS = "SET_COMPANY_SETTINGS" ;
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const SET_PERSONAL_AREA_ACTIVE_TAB = "SET_PERSONAL_AREA_ACTIVE_TAB";
export const SET_COMPANY_ROLE = "SET_COMPANY_ROLE";
export const SET_USER_ANALYTICS = "SET_USER_ANALYTICS" ;
export const REFRESH_PROJECTS = "REFRESH_PROJECTS" ;
export const PROJECT_FILES_TREE = "PROJECT_FILES_TREE";











