export const findBiggestObject = async (arrayOfObjects) => {
  let maxObject = {};
  let maxCount = -1;

  // Iterate through the array of objects
  await arrayOfObjects.forEach((obj) => {
    const keysCount = Object.keys(obj).length;
    const totalCount = keysCount;

    // Update maxObject if the current object has more keys and values
    if (totalCount > maxCount) {
      maxObject = obj;
      maxCount = totalCount;
    }
  });

  return maxObject;
};
