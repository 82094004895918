import React, { useEffect, useState } from 'react';
import Viewer from 'pages/TimelineViewer';
import { getTimeline } from 'api/share';
import { useParams } from 'react-router-dom';
import Loading from 'components/ui-components/loading';
import Header from 'components/Header';

import './style.scss';

const Share = ({ anonymous }) => {
  let { shareId } = useParams();
  const [line, setLine] = useState(null);
  useEffect(() => {
    getTimeline(shareId)
      .then(res => {
        if (res.err) {
          setLine('Not Valid Link');
        } else {
          console.log(res);
          setLine(res);
        }
      })
      .catch(err => {
        setLine('Not Valid Link');
      });
  }, []);
  return (
    
    <>
      {anonymous && <Header anonymous={anonymous} />}
      {anonymous && <div style={{ height: 58 }} />}
     
      {line && line !== 'Not Valid Link' && <Viewer line={line} personal={false} />}
      {line && line === 'Not Valid Link' && (
        <div className="wrap-not-valid">
          <div className="h-not-valid">Not Valid Link</div>
          <div className="p-not-valid">this link is not working</div>
        </div>
      )}
      {!line && (
        <div className="wrap-not-valid">
          <Loading color="black" text="Loading..." />
        </div>
      )}
    </>
  );
};
export default Share;
