import React, { useContext } from 'react'
import cn from 'classnames'
import Button from '../button'

import styles from './page-title.module.css'
import { useSelector } from 'react-redux'

const PageTitle = ({ title, button, borderBottom = true, children }) => {
  const userInfoRedux = useSelector((s) => s.user.userInfo);


  return (
    <div className={cn(styles.container, borderBottom && styles.borderBottom)}>
      <div className={styles.title}>
        <h1 className='mb-0 text-black'>{title}</h1>
        <div className={styles.buttonContainer}>
          {button && (
            <a
              href={userInfoRedux ? '/learning/ask-question' : '/authorization'}
              primary
            >
              Ask Question
            </a>
          )}
        </div>
      </div>
      {children && <p className={styles.summary}>{children}</p>}
      
    </div>
  )
}

export default PageTitle
