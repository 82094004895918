import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { extractImageFromZip } from "../../../utils/imageHandler";
//import slug from 'slug'

import Tag from "../../tag";

import styles from "./question-summary.module.css";
import { formatDistanceToNowStrict } from "date-fns";

function getslug(title) {
  return title
    .toLowerCase()
    .replace(/\s(-)\s/g, "-")
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

const QuestionSummary = ({
  // slug, // id,
  title,
  tags,
  author,
  createdTime,
  children,
  feature_img
}) => {
  const [profilePhoto, setProfilePhoto] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const extractedProfilePhoto = await extractImageFromZip(
        author.profilePhoto
      );
      setProfilePhoto(extractedProfilePhoto);
    };
    fetchUser();
  }, [author]);
  return (
    <div className={styles.container}>
      <Link
        to={`${"/learning/questions/"}${getslug(title)}`}
        className={styles.title}
      >
        {title}
      </Link>
      <img src={feature_img} />

      <div className={styles.excerpt}>{children}</div>
      <div className={styles.footer}>
        <div className={styles.tagContainer}>
          {tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </div>
        <div className={styles.userDetails}>
          <Link to={`/learning/users/${author.username}`}>
            <img src={profilePhoto} alt={author.username} />
          </Link>
          <div className={styles.info}>
            <span>
              asked{" "}
              {formatDistanceToNowStrict(new Date(createdTime), {
                addSuffix: true,
              })}
            </span>
            <Link to={`/learning/users/${author.username}`}>
              {author.name} {author.lastName}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionSummary;
