import React from "react";

export const UserLocationIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_391_21291)">
        <path
          d="M11.3333 12.8327V14.666C8.29577 14.666 5.83333 17.1285 5.83333 20.166H4C4 16.1159 7.28324 12.8327 11.3333 12.8327ZM11.3333 11.916C8.29458 11.916 5.83333 9.45477 5.83333 6.41602C5.83333 3.37727 8.29458 0.916016 11.3333 0.916016C14.3721 0.916016 16.8333 3.37727 16.8333 6.41602C16.8333 9.45477 14.3721 11.916 11.3333 11.916ZM11.3333 10.0827C13.3592 10.0827 15 8.44185 15 6.41602C15 4.39018 13.3592 2.74935 11.3333 2.74935C9.3075 2.74935 7.66667 4.39018 7.66667 6.41602C7.66667 8.44185 9.3075 10.0827 11.3333 10.0827ZM19.426 19.3145L16.8333 21.9993L14.2406 19.3145C12.8087 17.8317 12.8087 15.4276 14.2406 13.9448C15.6726 12.462 17.9941 12.462 19.426 13.9448C20.858 15.4276 20.858 17.8317 19.426 19.3145ZM18.1072 18.041C18.8531 17.2686 18.8531 15.9907 18.1072 15.2183C17.3962 14.4819 16.2705 14.4819 15.5594 15.2183C14.8135 15.9907 14.8135 17.2686 15.5594 18.041L16.8333 19.3602L18.1072 18.041Z"
          fill="#9C9C9C"
        />
      </g>
      <defs>
        <clipPath id="clip0_391_21291">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
