import React, { useEffect, useState } from "react";
import {
  MDBBtnGroup,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { useHttp } from "../../hooks/useHttp";
import { getRevit } from "../../api/files/getRevit";
import { url as urlApi } from "../../api";
import UploadIcon from "assets/images/icons/Featured icon.png";

const DownloadSoftwareModal = (props) => {
  const [files, setFiles] = useState(null);

  const { loading, error, request } = useHttp({
    requestCallback: () => getRevit(),
    onLoad: (res) => {
      setFiles(res);
    },
    onError: (res) => {},
  });

  useEffect(() => {
    request();
  }, []);

  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered
      className="delete-modal"
    >
      {/* <MDBModalHeader toggle={props.toggle}>Download Software</MDBModalHeader> */}
      <MDBModalHeader toggle={props.toggle}>
        <div className="d-flex align-items-center">
          <p className="delete-modal-label-head mb-0 ml-2">Download Software</p>
        </div>
      </MDBModalHeader>
      <MDBModalBody className="d-flex justify-content-center">
        {files ? (
          <div className="d-flex flex-column w-100">
            {Object.entries(files).map(([key, link]) => {
              const name = link.split("/")[3];
              return (
                <a
                  href={`${urlApi}${link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={name}
                  key={key}
                  className="d-flex align-items-center download-list"
                >
                  {/* <MDBIcon icon="download" /> */}
                  <img src={UploadIcon} />

                  <span>{key}</span>
                </a>
              );
            })}
          </div>
        ) : null}
        {error ? (
          <span className="delete-modal-label-head">
            Something went wrong with the file request, please contact support
          </span>
        ) : null}
      </MDBModalBody>
    </MDBModal>
  );
};

export default DownloadSoftwareModal;
