import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "api/users/getUsers";
import { deleteUser } from "api/users/deleteUser";
import DeleteModalIcon from "assets/images/icons/deleteIcon.png";
import addNotification from "../../helpers/notify";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import Loading from "../../components/ui-components/loading";
import { createUser } from "../../api/auth/registration";
import { editUser } from "api/users/editUser";
import { removeUser } from "api/users/removeUser";
import AddUserToCompanyModal from "./addUserModal";
import { Maybe } from "helpers/maybeFunctor";
import axios from "axios";
import { url } from "api";
import TopNavbar from "./navbar";
import BulkImportIcon from "assets/images/icons/bulk.png";
import DeleteIcon from "assets/images/icons/delete-bin-line.png";
import Arrow from "assets/images/icons/arrow.png";
import { inviteUserByCsv } from "api/users/csvInvite";
import { useHttp } from "hooks/useHttp";

const Users = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [usertodelete, setDelete] = useState("");
  const [usernametodelete, setusernametoDelete] = useState("");
  const userInfoRedux = useSelector((s) => s.user.userInfo);

  const [addUserOpen, setAddUserOpen] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState([]);

  const [isOpen2, setOpen2] = useState(false);
  const hiddenFileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const modalRef = useRef();
  const [id, setId] = useState("");
  const [body, setBody] = useState({
    name: "",
    lastName: "",
    country: "",
    city: "",
    role: "User",
  });

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filtered, setFiltered] = useState("");

  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await removeUser({ ids: [usertodelete] });
      setUsers((prev) => prev.filter((pre) => pre._id != usertodelete));
      setLoading(false);
      setOpen(false);
      addNotification("User Deleted Successfully! ", "success");
    } catch (err) {
      addNotification("Error not delete", "danger");
    }
  };

  const handleEdit = (user) => {
    setBody(user);
    setId(user._id);
  };

  useEffect(() => {
    const adminUsersRedux = JSON.parse(localStorage.getItem("adminUsersRedux"));
    const admin = Maybe.of(adminUsersRedux).map((users) =>
      users.find((user) => user.companyAdmin)
    ).value;

    (async () => {
      const res = await getUsers(admin.company);
      setUsers(res);
    })();
  }, []);

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setBody((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (id) {
      if (body.role == "Admin") {
        body.admin = true;
      } else {
        body.admin = false;
      }

      const res = await editUser(id, body);

      if (!res.error) {
        setUsers((prevUsers) => {
          const updatedUsers = prevUsers.map((user) =>
            user._id == id ? res : user
          );
          return updatedUsers;
        });
        addNotification("User Updated Successfully! ", "success");
        modalRef.current.click();
      } else {
        addNotification(res?.message, "danger");
      }
    } else {
      body.company = userInfoRedux.company;
      const res = await createUser(body);
      console.log("res-----", res);
      if (!res.error) {
      } else {
        addNotification(res?.message, "danger");
      }
    }
  };

  const handleClear = () => {
    setBody({
      email: "",
      company: "",
      password: "",
      name: "",
      lastName: "",
      country: "",
      city: "",
      username: "",
      role: "User",
    });
    setId("");
  };

  const handleCheckBox = (e, id) => {
    const isChecked = e.target.checked;

    // Check if the user is already in the array
    const index = usersToDelete.indexOf(id);

    // If checked and not in array, add to the array
    if (isChecked && index === -1) {
      setUsersToDelete((prev) => [...prev, id]);
    }

    // If unchecked and in array, remove from the array
    if (!isChecked && index !== -1) {
      setUsersToDelete((prev) => prev.filter((userId) => userId !== id));
    }
  };

  const handleBulkDelete = async () => {
    setLoading(true);
    try {
      const res = await removeUser({ ids: usersToDelete });
      setUsers((prev) =>
        prev.filter((user) => !usersToDelete.includes(user._id))
      );
      setUsersToDelete([]);
      setLoading(false);
      setOpen2(false);
      addNotification("Deleted Successfully! ", "success");
    } catch (err) {
      setLoading(false);
      setOpen2(false);
      addNotification("Error not delete", "danger");
    }
  };

  const handleBulkClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  const adminUsersRedux = JSON.parse(localStorage.getItem("adminUsersRedux"));

  const admin2 = Maybe.of(adminUsersRedux).map((users) =>
    users.find((user) => user.companyAdmin)
  ).value;

  const { request: uploadCsvAPI } = useHttp({
    requestCallback: (data) => inviteUserByCsv(data),
    onLoad: (res) => {
      setSelectedFile(null);
      setSpinner(false);
      addNotification(
        "An email has been sent to your inbox, please allow 5 minutes before checking! ",
        "success"
      );
      //   getMileStones(userInfoRedux.company);
    },
    onError: (res) => {
      setSpinner(false);
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const handleUploadCsv = async () => {
    setSpinner(true);

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("owner", admin2._id);

    return uploadCsvAPI(formData);
  };

  const handleSearch = (keyword) => {
    const filteredResult = users.filter((user) =>
      user?.email.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredProjects(filteredResult);

    if (keyword.length > 0) {
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  };

  const UserRow = ({ user }) => {
    const [open, setOpen] = useState(false);

    const [check1, set1] = useState(false);
    const [check2, set2] = useState(false);
    const [check3, set3] = useState(false);
    const [check4, set4] = useState(false);
    const [check5, set5] = useState(false);
    const [check6, set6] = useState(false);
    const [check7, set7] = useState(false);
    const [check8, set8] = useState(false);

    useEffect(() => {
      if (user.permissions) {
        if (user.permissions.includes("1")) {
          set1(true);
        }
        if (user.permissions.includes("2")) {
          set2(true);
        }
        if (user.permissions.includes("3")) {
          set3(true);
        }
        if (user.permissions.includes("4")) {
          set4(true);
        }
        if (user.permissions.includes("5")) {
          set5(true);
        }
        if (user.permissions.includes("6")) {
          set6(true);
        }
        if (user.permissions.includes("7")) {
          set7(true);
        }
        if (user.permissions.includes("8")) {
          set8(true);
        }
      }
    }, []);

    const handleCheckBox2 = async (e) => {
      if (e.target.value == "1" && e.target.checked) {
        set1(true);
      } else if (e.target.value == "1" && !e.target.checked) {
        set1(false);
      }
      if (e.target.value == "2" && e.target.checked) {
        set2(true);
      } else if (e.target.value == "2" && !e.target.checked) {
        set2(false);
      }
      if (e.target.value == "3" && e.target.checked) {
        set3(true);
      } else if (e.target.value == "3" && !e.target.checked) {
        set3(false);
      }
      if (e.target.value == "4" && e.target.checked) {
        set4(true);
      } else if (e.target.value == "4" && !e.target.checked) {
        set4(false);
      }
      if (e.target.value == "5" && e.target.checked) {
        set5(true);
      } else if (e.target.value == "5" && !e.target.checked) {
        set5(false);
      }
      if (e.target.value == "6" && e.target.checked) {
        set6(true);
      } else if (e.target.value == "6" && !e.target.checked) {
        set6(false);
      }
      if (e.target.value == "7" && e.target.checked) {
        set7(true);
      } else if (e.target.value == "7" && !e.target.checked) {
        set7(false);
      }
      if (e.target.value == "8" && e.target.checked) {
        set8(true);
      } else if (e.target.value == "8" && !e.target.checked) {
        set8(false);
      }
      try {
        const res = await axios.put(
          `${url}/permissions/${user._id}/permissions/${e.target.value}`
        );
      } catch (err) {
        console.log("err-------", err);
      }
    };

    return (
      <>
        <div
          style={{ borderBottom: "2px solid #E2E2E2" }}
          className="d-flex d-flex justify-content-between  pt-3 pb-2"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <input
              checked={usersToDelete.includes(user._id)}
              onChange={(e) => handleCheckBox(e, user._id)}
              type="checkbox"
              className="squared-checkbox mr-2"
            />
            <img
              src={user?.profilePhoto}
              //alt={`Avatar for ${row.name}`}
              style={{ width: "25px", height: "25px", borderRadius: "50%" }}
            />
            <div className="ml-2 inter f-14 font-weight-regular">
              {" "}
              {user?.username}{" "}
            </div>
          </div>
          <div>
            <span
              onClick={() => handleEdit(user)}
              data-toggle="modal"
              data-target="#exampleModal"
              className="mr-5 cursor-pointer table-icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
              >
                <path
                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                  fill="#3A5765"
                />
                <path
                  d="M5.61625 17.4999H0.3125V12.9033L14.6063 0.515428C14.8407 0.312334 15.1585 0.198242 15.49 0.198242C15.8215 0.198242 16.1393 0.312334 16.3738 0.515428L19.91 3.58018C20.1443 3.78333 20.276 4.05883 20.276 4.34609C20.276 4.63336 20.1443 4.90886 19.91 5.11201L5.61625 17.4999ZM0.3125 19.6666H22.8125V21.8333H0.3125V19.6666Z"
                  fill="black"
                  fillOpacity="0.2"
                />
              </svg>
            </span>
            <span
              onClick={() => {
                setDelete(user._id);
                setusernametoDelete(user?.username); 
                setOpen(true);
              }}
              className="mr-5 cursor-pointer table-icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
              >
                <path
                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                  fill="#3A5765"
                />
                <path
                  d="M19.4375 2.33317H25.6875V4.49984H23.1875V20.7498C23.1875 21.0372 23.0558 21.3127 22.8214 21.5159C22.587 21.719 22.269 21.8332 21.9375 21.8332H4.4375C4.10598 21.8332 3.78804 21.719 3.55362 21.5159C3.3192 21.3127 3.1875 21.0372 3.1875 20.7498V4.49984H0.6875V2.33317H6.9375V0.166504H19.4375V2.33317ZM9.4375 7.74984V16.4165H11.9375V7.74984H9.4375ZM14.4375 7.74984V16.4165H16.9375V7.74984H14.4375Z"
                  fill="black"
                  fillOpacity="0.2"
                />
              </svg>
            </span>
            <span
              onClick={() => setOpen(!open)}
              className="mr-5 ml-3 cursor-pointer table-icon"
              data-toggle="collapse"
              data-target={`#${user._id}`}
            >
              {!open ? (
                <img src={Arrow} className="arrow-icon transform-180" />
              ) : (
                <img src={Arrow} className="arrow-icon" />
              )}
            </span>
          </div>
        </div>
        <div className="collapse" id={`${user._id}`}>
          <div style={{ marginTop: "10px" }}>
            <input
              checked={check1 ? true : false}
              onChange={handleCheckBox2}
              type="checkbox"
              style={{ marginLeft: "50px" }}
              value={"1"}
              className="squared-checkbox mr-2"
            />{" "}
            Share Estimate
          </div>
          <div style={{ marginTop: "4px" }}>
            <input
              checked={check2 ? true : false}
              onChange={handleCheckBox2}
              type="checkbox"
              style={{ marginLeft: "50px" }}
              value={"2"}
              className="squared-checkbox mr-2"
            />{" "}
            Export/Download Estimate
          </div>
          <div style={{ marginTop: "4px" }}>
            <input
              checked={check3 ? true : false}
              onChange={handleCheckBox2}
              type="checkbox"
              style={{ marginLeft: "50px" }}
              value={"3"}
              className="squared-checkbox mr-2"
            />{" "}
            Add/Edit Project
          </div>
          <div style={{ marginTop: "4px" }}>
            <input
              checked={check4 ? true : false}
              onChange={handleCheckBox2}
              type="checkbox"
              style={{ marginLeft: "50px" }}
              value={"4"}
              className="squared-checkbox mr-2"
            />{" "}
            Delete Single Project
          </div>
          <div style={{ marginTop: "4px" }}>
            <input
              checked={check5 ? true : false}
              onChange={handleCheckBox2}
              type="checkbox"
              style={{ marginLeft: "50px" }}
              value={"5"}
              className="squared-checkbox mr-2"
            />{" "}
            Bulk Import Milestones, Users
          </div>
          <div style={{ marginTop: "4px" }}>
            <input
              checked={check6 ? true : false}
              onChange={handleCheckBox2}
              type="checkbox"
              style={{ marginLeft: "50px" }}
              value={"6"}
              className="squared-checkbox mr-2"
            />{" "}
            Bulk Delete Milestones, Users
          </div>
          <div style={{ marginTop: "4px" }}>
            <input
              checked={check7 ? true : false}
              onChange={handleCheckBox2}
              type="checkbox"
              style={{ marginLeft: "50px" }}
              value={"7"}
              className="squared-checkbox mr-2"
            />{" "}
            Add User
          </div>
          <div style={{ marginTop: "4px" }}>
            <input
              checked={check8 ? true : false}
              onChange={handleCheckBox2}
              type="checkbox"
              style={{ marginLeft: "50px" }}
              value={"8"}
              className="squared-checkbox mr-2"
            />{" "}
            Delete User
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="personal-area">
        <TopNavbar handleSearch={handleSearch} />
        <div className="content-bg p-2">
          <div className="container d-flex mt-1">
            <Sidebar />

            <div className="profile-content px-0 pt-0">
              <div class="w-100 px-0">
                <div className="d-flex justify-content-between px-3 py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        d="M15.2323 10.9609C14.3651 10.9609 13.5174 11.2181 12.7963 11.6999C12.0753 12.1817 11.5133 12.8664 11.1814 13.6676C10.8496 14.4688 10.7627 15.3504 10.9319 16.2009C11.1011 17.0515 11.5187 17.8327 12.1319 18.4459C12.7451 19.0591 13.5263 19.4767 14.3769 19.6459C15.2274 19.8151 16.109 19.7283 16.9102 19.3964C17.7114 19.0645 18.3962 18.5026 18.8779 17.7815C19.3597 17.0605 19.6169 16.2127 19.6169 15.3456C19.6108 14.1845 19.1469 13.0728 18.326 12.2519C17.505 11.4309 16.3933 10.967 15.2323 10.9609ZM16.0015 15.7302C15.8822 15.7272 15.7646 15.7011 15.6554 15.6532L14.0015 17.3071C13.9088 17.3989 13.7856 17.4536 13.6554 17.4609C13.5893 17.4673 13.5227 17.4565 13.462 17.4296C13.4014 17.4026 13.3487 17.3604 13.3092 17.3071C13.2165 17.208 13.1648 17.0774 13.1648 16.9417C13.1648 16.806 13.2165 16.6754 13.3092 16.5763L14.963 14.9225C14.9192 14.8119 14.8933 14.6951 14.8861 14.5763C14.8686 14.3929 14.8892 14.2079 14.9467 14.0329C15.0041 13.8578 15.0972 13.6966 15.2201 13.5593C15.3429 13.422 15.4928 13.3117 15.6604 13.2352C15.828 13.1586 16.0096 13.1177 16.1938 13.1148C16.3131 13.1177 16.4307 13.1438 16.54 13.1917C16.6169 13.1917 16.6169 13.2686 16.5784 13.3071L15.8092 14.0379C15.7914 14.0468 15.7764 14.0606 15.766 14.0776C15.7555 14.0945 15.75 14.1141 15.75 14.134C15.75 14.1539 15.7555 14.1735 15.766 14.1905C15.7764 14.2074 15.7914 14.2212 15.8092 14.2302L16.3092 14.7302C16.3229 14.7477 16.3404 14.762 16.3604 14.7717C16.3804 14.7815 16.4023 14.7866 16.4246 14.7866C16.4468 14.7866 16.4688 14.7815 16.4888 14.7717C16.5088 14.762 16.5263 14.7477 16.54 14.7302L17.2707 13.9994C17.3092 13.9609 17.4246 13.9609 17.4246 14.0379C17.4645 14.1496 17.4904 14.2659 17.5015 14.384C17.4986 14.5732 17.4566 14.7598 17.3782 14.9321C17.2997 15.1043 17.1865 15.2585 17.0457 15.3849C16.9048 15.5113 16.7394 15.6072 16.5597 15.6666C16.38 15.7261 16.1899 15.7477 16.0015 15.7302Z"
                        fill="#1D1D1D"
                      />
                      <path
                        d="M8.84826 11.1926C11.5884 11.1926 13.8098 8.97125 13.8098 6.23107C13.8098 3.49089 11.5884 1.26953 8.84826 1.26953C6.10808 1.26953 3.88672 3.49089 3.88672 6.23107C3.88672 8.97125 6.10808 11.1926 8.84826 11.1926Z"
                        fill="#1D1D1D"
                      />
                      <path
                        d="M10.1931 19.652C11.0392 19.652 10.5777 19.0751 10.5777 19.0751C9.72848 18.0173 9.267 16.7007 9.26998 15.3443C9.2659 14.4941 9.44978 13.6536 9.80844 12.8827C9.82459 12.8387 9.85095 12.7992 9.88537 12.7674C10.1546 12.2289 9.61614 12.1904 9.61614 12.1904C9.37393 12.1585 9.12959 12.1456 8.88537 12.152C7.07455 12.159 5.3262 12.8147 3.9573 14.0002C2.58841 15.1856 1.6896 16.8223 1.42383 18.6135C1.42383 18.9981 1.53921 19.6904 2.73152 19.6904H10.0777C10.1546 19.652 10.1546 19.652 10.1931 19.652Z"
                        fill="#1D1D1D"
                      />
                    </svg>
                    <span className="font-weight-bold ml-2">
                      Users, Roles & Permissions
                    </span>
                    {usersToDelete.length > 0 && (
                      <div
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 ml-4 mb-0 cursor-pointer"
                        onClick={() => setOpen2(true)}
                      >
                        <img src={DeleteIcon} className="w-15" />
                        <span className="ml-1 f-14">
                          Delete {usersToDelete.length} Users
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex">
                    <div
                      onClick={handleBulkClick}
                      className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-2 mb-0 cursor-pointer"
                    >
                      <img src={BulkImportIcon} className="w-15" />
                      <span className="ml-1 f-14">Bulk Import Users</span>
                    </div>
                    {selectedFile ? (
                      <div
                        onClick={handleUploadCsv}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        {spinner ? (
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="text-bid">Upload</div>
                        )}
                      </div>
                    ) : (
                      <div
                        onClick={() => setAddUserOpen(true)}
                        className="header-btn bg-white text-bid border-bid rounded-lg h6 mr-0 mb-0 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <path
                            d="M5.60013 9.11418H6.61313V6.62315H9.11313V5.61015H6.61313V3.03819H5.60013V5.61015H3.03813V6.62315H5.60013V9.11418ZM6.07913 12.1521C5.26654 12.1572 4.46167 11.9941 3.71513 11.6731C2.99435 11.3666 2.33864 10.9254 1.78313 10.3732C1.23087 9.81769 0.78965 9.16196 0.483133 8.44114C0.160192 7.69298 -0.00426501 6.88603 0.000131185 6.07115C-0.00529131 5.25797 0.157444 4.45248 0.478128 3.70518C0.783938 2.98546 1.22529 2.33125 1.77813 1.77818C2.33424 1.22671 2.9898 0.785612 3.71013 0.478193C4.45897 0.156833 5.26627 -0.00594709 6.08113 0.000166009C6.89431 -0.00525648 7.69983 0.157509 8.44713 0.478193C9.16637 0.784898 9.82042 1.22612 10.3741 1.77818C10.9265 2.33241 11.3678 2.98713 11.6741 3.70714C11.9947 4.45482 12.1574 5.26065 12.1521 6.07414C12.1575 6.88667 11.9948 7.69154 11.6741 8.43815C11.3669 9.15798 10.9257 9.81291 10.3741 10.3681C9.81993 10.9206 9.16516 11.3619 8.44513 11.6682C7.69798 11.991 6.89203 12.1554 6.07813 12.1512L6.07913 12.1521Z"
                            fill="#455E6C"
                          />
                        </svg>{" "}
                        <span className="ml-2 f-14">Add Users</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="border-bottom pl-2 pt-2 pb-2 d-flex justify-content-between">
                  <div className="f-12 font-weight-normal">Username</div>
                  {/* <div className="f-12 font-weight-normal">
                    Completion percentage
                  </div> */}
                </div>

                {filtered
                  ? filteredProjects?.map((user, i) => {
                      if (userInfoRedux.id == user._id || user?.companyAdmin)
                        return;

                      return <UserRow key={i} user={user} />;
                    })
                  : users?.map((user, i) => {
                      if (userInfoRedux.id == user._id || user?.companyAdmin)
                        return;

                      return <UserRow key={i} user={user} />;
                    })}

                <input
                  style={{ display: "none" }}
                  type="file"
                  accept=".csv"
                  name="file_image"
                  className="file-input"
                  id="input_image"
                  required
                  // disabled={loading}
                  ref={hiddenFileInput}
                  onChange={handleFile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal starts */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog create-modal" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit User
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClear}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group-div">
                  <label for="exampleInputEmail1" className="inter pl-2">
                    Name
                  </label>
                  <input
                    value={body.name}
                    onChange={handleOnchange}
                    name="name"
                    type="text"
                    class="form-control"
                    placeholder="Enter Name"
                  />
                </div>
                <div class="form-group-div mt-3">
                  <label for="exampleInputEmail1" className="inter pl-2">
                    Last Name
                  </label>
                  <input
                    value={body.lastName}
                    onChange={handleOnchange}
                    name="lastName"
                    type="text"
                    class="form-control"
                    placeholder="Enter last name"
                  />
                </div>
                <div class="form-group-div mt-3">
                  <label for="exampleInputEmail1" className="inter pl-2">
                    Country
                  </label>
                  <input
                    value={body.country}
                    onChange={handleOnchange}
                    name="country"
                    type="text"
                    class="form-control"
                    placeholder="Enter Country"
                  />
                </div>
                <div class="form-group-div mt-3">
                  <label for="exampleInputEmail1" className="inter pl-2">
                    City
                  </label>
                  <input
                    value={body.city}
                    onChange={handleOnchange}
                    name="city"
                    type="text"
                    class="form-control"
                    placeholder="Enter City"
                  />
                </div>

                <div class="form-group-div mt-3">
                  <label for="exampleFormControlSelect1" className="inter pl-2">
                    Role
                  </label>
                  <select
                    value={body.role}
                    name="role"
                    onChange={handleOnchange}
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option value={"User"}>User</option>
                    <option value={"Admin"}>Admin</option>
                    <option value={"Estimator"}>Estimator</option>
                    <option value={"Engineer"}>Engineer</option>
                    <option value={"Designer"}>Designer</option>
                    <option value={"Const.Manager"}>Const.Manager</option>
                  </select>
                </div>
                {/* <div class="form-group">
                            <label for="exampleInputPassword1">Password</label>
                            <input value={body.password} onChange={handleOnchange} name='password' type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"/>
                        </div> */}
              </form>
            </div>
            <div class="modal-footer border-none">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  ref={modalRef}
                  onClick={handleClear}
                  className="bg-white text-bid border-bid text-inherit rounded py-2"
                  style={{
                    width: "48%",
                    textAlign: "center",
                    color: "black",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </div>
                <div
                  onClick={handleSave}
                  className="border-bid rounded py-2"
                  style={{
                    width: "48%",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "#3A5765",
                    color: "white",
                  }}
                >
                  {id ? "Update" : "Add"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* single delete */}
      <MDBModal
        isOpen={isOpen}
        toggle={() => setOpen(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpen2(false)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>
        <MDBModalBody>
          <p className="delete-modal-label-head">
            Delete User {usernametodelete} ?
          </p>
          <p className="delete-modal-label">
            Are you sure you want to delete this User? This action cannot be
            undone.
          </p>
        </MDBModalBody>
        {loading ? <Loading color="black" text="Deleting..." /> : null}
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpen(false);
                setDelete("");
              }}
              color=""
              disabled={loading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={handleDelete}
              disabled={loading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      {/* bulk delete */}
      <MDBModal
        isOpen={isOpen2}
        toggle={() => setOpen2(false)}
        centered
        className="delete-modal"
      >
        <MDBModalHeader toggle={() => setOpen2(false)}>
          <img src={DeleteModalIcon} />
        </MDBModalHeader>

        <MDBModalBody>
          <p className="delete-modal-label-head">Delete Users ?</p>
          <p className="delete-modal-label">
            Are you sure you want to delete this Users? This action cannot be
            undone.
          </p>
        </MDBModalBody>

        {loading ? <Loading color="black" text="Deleting..." /> : null}
        <MDBModalFooter className="d-flex justify-content-center border-none">
          <MDBBtnGroup size="md" className="w-100">
            <MDBBtn
              size="sm"
              onClick={() => {
                setOpen2(false);
                setDelete("");
              }}
              color=""
              disabled={loading}
              className="delete-modal-btn cancel-confirm"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size="sm"
              color=""
              onClick={handleBulkDelete}
              disabled={loading}
              className="delete-modal-btn delete-confirm"
            >
              Delete
            </MDBBtn>
          </MDBBtnGroup>
        </MDBModalFooter>
      </MDBModal>

      <AddUserToCompanyModal
        isOpen={addUserOpen}
        toggle={() => {
          setAddUserOpen(false);
        }}
      />
    </>
  );
};

export default Users;
