import { putProject, globalPut } from '../index';

export const updateProject = ({ id, body }) => {
  console.log({body})
  return putProject(id, body, false);
}

export const editProjectPut = data => {
  return globalPut(`/projects` , data, false);
}
