import React from "react";

export const ListIcon = () => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.97384 14.0002H18.9738V11.3252H7.97384V14.0002ZM2.97384 4.67518H5.97384V2.0002H2.97384V4.67518ZM2.97384 9.35017H5.97384V6.67518H2.97384V9.35017ZM2.97384 14.0002H5.97384V11.3252H2.97384V14.0002ZM7.97384 9.35017H18.9738V6.67518H7.97384V9.35017ZM7.97384 4.67518H18.9738V2.0002H7.97384V4.67518ZM2.97384 16.0002C2.71071 16.0041 2.44957 15.954 2.20655 15.853C1.96352 15.7521 1.74377 15.6024 1.56084 15.4132C1.37165 15.2303 1.22196 15.0105 1.12101 14.7675C1.02005 14.5244 0.969962 14.2633 0.973842 14.0002V2.0002C0.970228 1.73716 1.02053 1.4762 1.12166 1.23335C1.22279 0.990503 1.3726 0.770934 1.56184 0.588208C1.74456 0.398965 1.96412 0.249151 2.20697 0.148023C2.44981 0.0468939 2.71081 -0.00341862 2.97384 0.000195533H18.9738C19.237 -0.0035558 19.4982 0.0467067 19.7413 0.14784C19.9843 0.248972 20.204 0.398841 20.3868 0.588208C20.5759 0.771019 20.7255 0.990629 20.8265 1.23347C20.9274 1.47631 20.9776 1.73723 20.9738 2.0002V14.0002C20.9775 14.2632 20.9272 14.5242 20.826 14.767C20.7249 15.0099 20.5751 15.2295 20.3858 15.4122C20.2031 15.6014 19.9836 15.7512 19.7407 15.8524C19.4979 15.9535 19.2369 16.0038 18.9738 16.0002H2.97384Z"
        fill="#C0C0C0"
      />
    </svg>
  );
};
