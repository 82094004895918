import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventTrack } from "../../helpers/ga4Helper";
import { useHistory } from "react-router";
import HealthCheckCardView from "./HealthCheckCardView";
import HealthCheckListView from "./HealthCheckListView";
import { setSidebarStatus } from "actions/user";
import { getHealthCheck } from "api/healthCheck/getHealthCheck";
import { useHttp } from "hooks/useHttp";
import { setAllHealthReports } from "actions/user";
import Loader from "components/Loader";

export default function ModalHealth() {
  const TABS = {
    MODAL_LIST: "MODAL_LIST",
    MODAL_CARD: "MODAL_CARD",
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TABS.MODAL_LIST);
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const healthReportsRedux = useSelector((s) => s.user.healthReports);
  const [modelHealthReports, setModelHealthReports] = useState([]);
  const { companyAdmin, admin, company, name, lastName, email } = userInfoRedux;

  const { loading, request: getHealthCheckAPI } = useHttp({
    requestCallback: () => getHealthCheck(userInfoRedux.company),
    onLoad: (res) => {
      dispatch(setAllHealthReports(res));
    },
    onError: (res) => {},
  });

  const handleKeyword = (keyword) => {
    const filteredReports = healthReportsRedux.filter((report) =>
      report?.modelName?.toLowerCase().includes(keyword.toLowerCase())
    );

    setModelHealthReports(filteredReports);
  };

  useEffect(() => {
    setModelHealthReports(healthReportsRedux);
  },[healthReportsRedux])

  useEffect(() => {
    getHealthCheckAPI();
  }, []); 

  return (
    <div>
      <div className="personal-area">
        <div className="inner-header-position">
          <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center">
              <div
                onClick={() => dispatch(setSidebarStatus(true))}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                >
                  <path
                    d="M6.84102 8.825L3.02435 5L6.84102 1.175L5.66602 0L0.666016 5L5.66602 10L6.84102 8.825Z"
                    fill="#9E9E9E"
                  />
                </svg>
              </div>
              <p className="my-heading f-14 ml-3 mr-3">Health Check Report ({modelHealthReports?.length})</p>
            </div>
            <div
              className="projActionButtons d-flex flex-wrap"
              style={{ maxWidth: "100vw" }}
            >
              <input
                type="text"
                className="search-bar"
                // value={searchValue}
                onChange={(e) => handleKeyword(e?.target?.value)}
                placeholder="Search"
                // disabled={loading}
              />
              <div className="vr mx-3"></div>
              <div className="btn-tool-group">
                {admin || companyAdmin ? (
                  <button
                    onClick={() => {
                      history.push(`/admin/projects-settings`);
                      {
                        eventTrack(
                          "View-Admin-Action",
                          "View-Admin",
                          name,
                          "+1",
                          false,
                          "+1"
                        );
                      }
                    }}
                    className="bg-bid px-3 mr-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                    >
                      <path
                        d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                        fill="white"
                      />
                    </svg>
                    Admin
                  </button>
                ) : null}

                <div className="vr mx-3"></div>

                <button
                  onClick={() => getHealthCheckAPI()}
                  className="px-3 bg-white text-bid shadow-hover"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                  >
                    <path
                      d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                      fill="#3A5765"
                    />
                    <path
                      d="M3.55149 2.19418C5.01232 0.931059 6.9166 0.166992 8.99935 0.166992C13.6017 0.166992 17.3327 3.89795 17.3327 8.50033C17.3327 10.2804 16.7745 11.9302 15.8237 13.2842L13.166 8.50033H15.666C15.666 4.81843 12.6813 1.83366 8.99935 1.83366C7.20753 1.83366 5.58082 2.54055 4.38287 3.69068L3.55149 2.19418ZM14.4472 14.8065C12.9863 16.0696 11.0821 16.8337 8.99935 16.8337C4.39697 16.8337 0.666016 13.1027 0.666016 8.50033C0.666016 6.72021 1.22417 5.07046 2.17502 3.71653L4.83268 8.50033H2.33268C2.33268 12.1822 5.31745 15.167 8.99935 15.167C10.7912 15.167 12.4179 14.4601 13.6159 13.31L14.4472 14.8065Z"
                      fill="black"
                      fillOpacity="0.2"
                    />
                  </svg>
                  Refresh
                </button>

                <div className="vr mx-2"></div>

                <div className="d-flex view-switch-container pr-0">
                  <div
                    onClick={() => {setActiveTab(TABS.MODAL_CARD)
                      eventTrack(
                        "View-Model-Health-CardView-Action",
                        "View-Model-Health-CardView",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    className={activeTab === TABS.MODAL_CARD ? "active" : null}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.676758 8.9671V0.703125H8.94077V8.9671H0.676758ZM0.676758 19.2971V11.0331H8.94077V19.2971H0.676758ZM11.0068 8.9671V0.703125H19.2708V8.9671H11.0068ZM11.0068 19.2971V11.0331H19.2708V19.2971H11.0068ZM2.74276 6.89709H6.87675V2.76514H2.74476L2.74276 6.89709ZM13.0728 6.89709H17.2048V2.76514H13.0768L13.0728 6.89709ZM13.0728 17.2271H17.2048V13.1031H13.0768L13.0728 17.2271ZM2.74276 17.2271H6.87675V13.1031H2.74476L2.74276 17.2271Z"
                        fill="#C0C0C0"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {setActiveTab(TABS.MODAL_LIST)
                      eventTrack(
                        "View-Model-Health-ListView-Action",
                        "View-Model-Health-ListView",
                        userInfoRedux?.name,
                        "+1",
                        false,
                        "+1"
                      );
                    }}
                    className={activeTab === TABS.MODAL_LIST ? "active" : null}
                  >
                    <svg
                      width="21"
                      height="16"
                      viewBox="0 0 21 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.97384 14.0002H18.9738V11.3252H7.97384V14.0002ZM2.97384 4.67518H5.97384V2.0002H2.97384V4.67518ZM2.97384 9.35017H5.97384V6.67518H2.97384V9.35017ZM2.97384 14.0002H5.97384V11.3252H2.97384V14.0002ZM7.97384 9.35017H18.9738V6.67518H7.97384V9.35017ZM7.97384 4.67518H18.9738V2.0002H7.97384V4.67518ZM2.97384 16.0002C2.71071 16.0041 2.44957 15.954 2.20655 15.853C1.96352 15.7521 1.74377 15.6024 1.56084 15.4132C1.37165 15.2303 1.22196 15.0105 1.12101 14.7675C1.02005 14.5244 0.969962 14.2633 0.973842 14.0002V2.0002C0.970228 1.73716 1.02053 1.4762 1.12166 1.23335C1.22279 0.990503 1.3726 0.770934 1.56184 0.588208C1.74456 0.398965 1.96412 0.249151 2.20697 0.148023C2.44981 0.0468939 2.71081 -0.00341862 2.97384 0.000195533H18.9738C19.237 -0.0035558 19.4982 0.0467067 19.7413 0.14784C19.9843 0.248972 20.204 0.398841 20.3868 0.588208C20.5759 0.771019 20.7255 0.990629 20.8265 1.23347C20.9274 1.47631 20.9776 1.73723 20.9738 2.0002V14.0002C20.9775 14.2632 20.9272 14.5242 20.826 14.767C20.7249 15.0099 20.5751 15.2295 20.3858 15.4122C20.2031 15.6014 19.9836 15.7512 19.7407 15.8524C19.4979 15.9535 19.2369 16.0038 18.9738 16.0002H2.97384Z"
                        fill="#C0C0C0"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* tab content */}
        {loading && <Loader />}
        {activeTab === TABS.MODAL_CARD && (
          <HealthCheckCardView data={modelHealthReports} />
        )}
        {activeTab === TABS.MODAL_LIST && (
          <HealthCheckListView data={modelHealthReports} />
        )}
      </div>
    </div>
  );
}
