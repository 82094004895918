import React, { useEffect, useState } from "react";
import {
  MDBBadge,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBCardFooter,
  MDBBtn,
} from "mdbreact";
import { url } from "api";
import formatTime from "helpers/formatTime";
import { extractImageFromZip } from "helpers/extractImageFromZip";
import DefaultImage from "assets/images/Default_Project_Image.png";
import { useHistory } from "react-router";

const ProjectItemMainAdmin = (props) => {
  const { img, title, description, date, users, _id, timelines, zipInfo, company } =
    props.projectItem;
  const { parentProjectID, isParent } = props;

  const [projectPicture, setProjectPicture] = useState(null);

  const { userWhoCreate } = props;
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (img?.split(".").pop() === "gz") {
        const extractedImg = await extractImageFromZip(img);
        if (isMounted) {
          setProjectPicture(extractedImg);
        }
      } else {
        if (isMounted) {
          setProjectPicture(DefaultImage);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [img, props.projectItem]);

  return (
    <MDBCol className={props.className}>
      <MDBCard className="h-100">
        <div className="project-item-img">
          <img
            src={projectPicture}
            alt={`Project ${title}`}
            onError={(e) => (e.target.src = DefaultImage)}
          />
          <span className="project-item-date">{formatTime(date)}</span>
        </div>
        <MDBCardBody>
          <MDBCardTitle className="font-size-2">{title}</MDBCardTitle>
          <MDBCardText
            tag="div"
            className="d-flex flex-column align-items-start"
          >
            <p>{description}</p>
            {users.length === 0 ? (
              <h6>
                <MDBBadge pill tag="span" color="danger">
                  Deleted
                </MDBBadge>
              </h6>
            ) : null}
            <span>
              User: {userWhoCreate?.name} {userWhoCreate?.lastName}
            </span>
            <span>Email: {userWhoCreate?.email}</span>
          </MDBCardText>
        </MDBCardBody>
        <MDBCardFooter className="pl-2 pr-2 pb-2 pt-0">
          <div className="d-flex align-items-center  justify-content-between">
            <MDBBtn
              color=""
              className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit"
              onClick={() => {
                props.openMembersModal();
              }}
            >
              Members
            </MDBBtn>

            {isParent && (
              <MDBBtn
                color=""
                className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit "
                onClick={() => {
                  history.push(`/personal-area/projects/${_id}/timelines`);
                }}
              >
                Projects
              </MDBBtn>
            )}

            <MDBBtn
              color=""
              className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit "
              onClick={() => {
                // if (!isParent && parentProjectID != "") {
                //   history.push(`../${_id}`);
                // } else {
                //   history.push(`./personal-area/projects/${_id}/timelines`);
                // }
                history.push(`/personal-area/projects/${_id}/timelines`);
              }}
            >
              History ({timelines?.length})
            </MDBBtn>

            {zipInfo?.paths.length > 0 && (
              <MDBBtn
                color=""
                className="bg-bid btnFlex text-white border-bid shadow-none text-style-inherit "
                onClick={() => {
                  history.push(`/main-admin/projects/${company}/model-view/${_id}`);
                }}
              >
                Model
              </MDBBtn>
            )}
          </div>
        </MDBCardFooter>
      </MDBCard>
    </MDBCol>
  );
};

export default ProjectItemMainAdmin;
