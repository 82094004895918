import React, { useEffect, useState } from 'react';
import { addShare, rmShare } from 'api/share';
import addNotification from 'helpers/notify';
import {eventTrack} from 'helpers/ga4Helper'


const List = ({ header, list = [], setPending }) => {
  const rm = id => {
    rmShare(id)
      .then(res => {
        if (res._id) {
          setPending(item => item.filter(el => el._id !== res._id));
        } else {
          addNotification('Something went wrong!', 'danger');
        }
      })
      .catch(err => {
        addNotification(err.message, 'danger');
      });
  };

  return (
    <div className="share-email-list">
      <div className="share-email-heder">{header}</div>
      {list?.map(item => (
        <div className="share-email-item" key={item._id}>
          <div
            className="share-email-item-delete"
            onClick={() => {rm(item._id)
              {eventTrack("Remove-Share-Action","Remove-Share",item.email,'+1',false,'+1')}}}
          />
          <div className="item">{item.email}</div>
        </div>
      ))}
    </div>
  );
};

const CustomCheckBox = ({ title, check, right }) => {
  return (
    <label className="container-share-check">
      {title}
      <input
        type="checkbox"
        checked={right[title.toLocaleLowerCase().split(' ').join('_')]}
        onChange={e =>
          check(
            e.target.checked,
            title.toLocaleLowerCase().split(' ').join('_'),
          )
        }
      />
      <span className="checkmark"></span>
    </label>
  );
};

const Main = ({ rule, timeline, pending, setPending, withShare, setWith, setPriceView }) => {
  const [right, setRight] = useState({
    prices: rule?.prices || true,
    navigation: rule?.navigation || true,
    quantity_excel: true,
    estimation_excel: rule?.estimation_excel || true,
    estimation_excel_awp: rule?.estimation_excel_awp || true
  });
  const [email, setEmail] = useState('');

  useEffect(() => {
    setPriceView(right.prices & right.estimation_excel & right.estimation_excel_awp);
  },[right.prices, right.estimation_excel, right.estimation_excel_awp]);

  const handleCheckBox = (checked, value) => {
    if (value === 'prices') {
      setRight(item => ({
        ...item,
        [value]: checked, 
        
      }));
      return;
    }
    if (value === 'navigation') {
      setRight(item => ({
        ...item,
        [value]: checked,
        quantity_excel: checked,
        estimation_excel: checked,
        estimation_excel_awp: checked,
      }));
      return;
    }

    if (
      (value === 'quantity_excel' ||
        value === 'estimation_excel'||
        value === 'estimation_excel_awp') &&
      checked
    ) {
      setRight(item => ({
        ...item,
        [value]: checked,
        navigation: checked,
      }));
      return;
    }
    if (
      (value === 'quantity_excel' ||
        value === 'estimation_excel'||
        value === 'estimation_excel_awp') &&
      !checked
    ) {
      if (
        (right.quantity_excel === false || value === 'quantity_excel') &&
        (right.estimation_excel === false || value === 'estimation_excel')&&
        (right.estimation_excel_awp === false || value === 'estimation_excel_awp')
      ) {
        setRight(item => ({
          ...item,
          [value]: checked,
          navigation: checked,
        }));        
        return;
      }
    }

    setRight(item => ({
      ...item,
      [value]: checked,
    }));
  };

  const submit = event => {
    event.preventDefault();
    addShare(
      {
        ...right,
        email,
      },
      timeline._id,
    )
      .then(res => {
        setPending(item => [...item, res]);
        setEmail('');
      })
      .catch(err => {
        addNotification(err.message, 'danger');
      });
  };

  return (
    <div className="share-container">
      <div className="options-wrapper">
        <div className="share-check-list">
          <div className="share-check-group">
            <CustomCheckBox title="Prices" check={handleCheckBox} right={right} />
            <CustomCheckBox title="Navigation" check={handleCheckBox} right={right} />
            <CustomCheckBox title="Quantity Excel" check={handleCheckBox} right={right} />
            <CustomCheckBox title="Estimation Excel" check={handleCheckBox} right={right} />
            <CustomCheckBox title="Estimation Excel AWP" check={handleCheckBox} right={right} />
          </div>
        </div>

        <form className="share-form" onSubmit={submit}>
        <input
            type="email"
            placeholder="email@example.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button
            className="btn Ripple-parent btn-sm"
            style={{ marginLeft: 0 }}
          >
            Add
          </button>
          
        </form>
      </div>

      <div className="share-lists">
        {pending.length > 0 && (
          <List header="Pending Share" list={pending} setPending={setPending} />
        )}
        {withShare.length > 0 && (
          <List header="Shared with" list={withShare} setPending={setWith} />
        )}
      </div>
    </div>
  );
};
export default Main;
