import React, { useState } from 'react';
import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact';
import { useSelector } from 'react-redux';

import Loading from '../ui-components/loading';
import { useHistory } from 'react-router-dom';
import { choseToggle } from 'helpers/toggleIfLoading';
import addNotification from 'helpers/notify';
import { inviteUserByEmail } from 'api/users/inviteUser';
import { useHttp } from 'hooks/useHttp';
import { Maybe } from 'helpers/maybeFunctor';
import GA4 from '../GA4'
import { options } from 'numeral';



const styles = {

    fontWeight:  "bold" ,
    color: "white",
    backgroundColor: '#3a5765' ,
    fontSize: 14,
    width:'100%',
    height:'40px',
    borderRadius:'5px',
    padding: '10px',

};

const toolTipStyle={
   border: "5px solid transparent !important",
   color: "#3a5765",
   fontSize: 16,
   fontWeight:"Bold",
   font: "poppins-Bold",
   padding: "20px",
   width: "100%",
   textIndent: "initial",
     borderSpacing: "2px",

}

const AddUserToCompanyModal = props => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('DefaultUser')
  const [roleTip, setRoleTip] = useState('Can access basic settings in all environments!')

  // Using state to keep track of what the selected fruit is
 

// Using this function to update the state of fruit
// whenever a new option is selected from the dropdown
let handleRoleChange = (e) => {
  var r  = JSON.parse(e.target.value)
  
  
  setRole(r.value)
  setRoleTip(r.toolTip)

}



  const adminUsersRedux = useSelector(s => s.admin.users);

  const admin = Maybe.of(adminUsersRedux).map(users =>
    users.find(user => user.companyAdmin),
  ).value;

  const toggle = () => {
    setEmail('');
    props.toggle();
  };

  const roles = [
    {label:'Admin',value:'Admin', toolTip:'Can access advanced settings in all environments!'}, //'Can access advanced functionality in all systems'
    {label:'DefaultUser',value:'DefaultUser', toolTip:'Can access basic settings in all environments!'}, //'Can access basic functionality in all systems'
  {label:'Estimator',value:'Estimator', toolTip:'Can access estimation settings in desktop environment!'}, //'Can access estimation functionality in desktop env'
  {label:'Engineer',value:'Engineer', toolTip:'Can access data export settings in Revit environment!'}, //'Can access data export functionality in Revit env'
  {label:'Designer',value:'Designer', toolTip:'Can access data export settings in Revit environment!'}, //'Can access data export functionality in Revit env'
  {label:'Const_Manager',value:'Const_Manager', toolTip:'Can access basic settings in web environment!'}] //'Can access cloud functionality in Web env'

  const history = useHistory();
  const { loading, request: inviteUserAPI } = useHttp({
    requestCallback: inviteUserByEmail,
    onLoad: res => {
      toggle();
      addNotification(
        'An email has been sent to your inbox, please allow 5 minutes before checking! ',
        'success',
      );
    },
    onError: res => {
      toggle();
      addNotification('Something went wrong, please contact support', 'danger');
    },
  });

  const submitNewUserToCompany = e => {
    e.preventDefault();
    return inviteUserAPI({ mail: email, owner: admin._id, role:role });
  };

  return (
    <MDBModal
      isOpen={props.open}
      toggle={choseToggle(loading, toggle)}
      centered
    >
      <MDBModalHeader toggle={choseToggle(loading, toggle)}>
        Add user to company
      </MDBModalHeader>
      <MDBModalBody>
     
        <form onSubmit={submitNewUserToCompany} style={{  height: "300px", minHeight: "200px"}} id="adduseradminfrmid">
       
          <MDBInput
            value={email}
            size="sm"
            type="email"
            onChange={e => setEmail(e.target.value)}
            label="Enter email of user"
            className='mdb-input'
            required
          />
          <select  style={styles}   onChange={handleRoleChange} > 
      
       
      {roles.map((role,index) =>   (role.value == 'DefaultUser')? <option selected value={JSON.stringify(roles[index])}   key={index}>{role.label}</option> : <option value={JSON.stringify(roles[index])}  key={index}>{role.label}</option> )}
   
      
      required
    </select>
    <label style={toolTipStyle}>
   
   <span style={{position:"absolute",bottom: "18%" ,left: "5%"}}><b><strong>Tip:</strong></b> {roleTip}</span>

    </label>
        </form>
      
        <MDBBtn
            size="md"
            type="submit"
            color=''
            className="w-100 bg-bid m-auto text-white radius-2 m-0"
            disabled={loading}
            form ="adduseradminfrmid" 
          >
            Invite User
          </MDBBtn>
        {loading ? <Loading color="black" text="Loading..." /> : null}
        <GA4 
       
       userId ={email}
       category={"Management"}
        action={"AddUser-Action"}
         label={history.location.pathname}
         value={email}
         nonInteraction={false}
          transport={"xhr"}
     ></GA4>
      </MDBModalBody>
    </MDBModal>
  );
};

export default AddUserToCompanyModal;
