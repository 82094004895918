import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import clock from "assets/images/icons/clock.svg";
import money from "assets/images/icons/money.svg";
import dots from "assets/images/icons/3dots.png";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import {
  setFeatureRequest,
  setProjects,
  updateProjectData,
} from "actions/user";
import { Modal } from "antd";
import { RequestCard } from "pages/configuration/requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { eventTrack } from "helpers/ga4Helper";
import { editProjectPut } from "api/projects/updateProject";
import addNotification from "helpers/notify";
import { adminEditProject } from "actions/admin";
import { useHttp } from "hooks/useHttp";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
};
let numeral = require("numeral");

const KanbanCard = ({
  project,
  index,
  isAdmin,
  setModal,
  setProjectToDelete,
  setProjectToUpdate,
  setProjectListToEdit,
}) => {
  const { img, title, zipInfo, date, timelines, projectNumber } = project;
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const featureRequestRedux = useSelector((s) => s.user.featureRequest);
  const projectsRedux = useSelector((s) => s.user.projects);
  const usersRedux = useSelector((s) => s.user.users);

  const [requestType, setRequestType] = useState("");
  const [requestModal, setRequestModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  const handleAccess = (item) => {
    setProjectListToEdit(item);
    setModal("Share");
  };
  const handleMembers = (item) => {
    setProjectListToEdit(item);
    setModal("ProjectListToEdit");
  };

  const handleHistory = () => {
    history.push(`./personal-area/projects/${project?._id}/timelines`);
    {
      eventTrack(
        "View-History-Action",
        "View-History",
        project?.title,
        project?._id,
        false,
        project?.title
      );
    }
  };

  const handleDelete = (item) => {
    setProjectToDelete(item);
    setModal("DeleteModal");
  };

  const requestAccess = async () => {
    try {
      setRequestLoading(true);
      const res = await createFeatureRequest(userInfoRedux?.id, {
        companyId: userInfoRedux.company,
        permission: requestType,
        userId: userInfoRedux.id,
      });
      if (res) {
        setRequestLoading(false);
        dispatch(
          setFeatureRequest(res?.map((feature) => feature?.requestedFeature))
        );
      }
    } catch (e) {
      setRequestLoading(false);
    }
  };

  const form = new FormData();

  const { loading, request: editProjectAPI } = useHttp({
    requestCallback: editProjectPut,
    onLoad: (res) => {
      const cloneProjectArr = [...projectsRedux];
      const i = cloneProjectArr.findIndex((x) => x._id === res._id);
      cloneProjectArr[i] = { ...cloneProjectArr[i], favourite: res?.favourite };
      addNotification("Project updated successfully", "success");
      dispatch(setProjects(cloneProjectArr));
      dispatch(adminEditProject(res));
    },
    onError: (res) => {
      // addNotification(res.message, "danger");
    },
  });

  const toggleFavorite = (value) => {
    console.log(project);
    return editProjectAPI({
      _id: project?._id,
      favourite: !value,
    });
  };

  return (
    <>
      <Draggable draggableId={project._id} index={index}>
        {(provided, snapshot) => (
          <div
            className={`kanban-card ${snapshot.isDragging ? "dragging" : ""}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div>
              <p className="kanban-card-projectNo">
                Project No :{" "}
                {project?.projectNumber ? project?.projectNumber : "N/A"}
                {project?.isParent && <i className="fa fa-users cursor-pointer" title="Parent Project" />}
              </p>
              <div className="card-title">
                <p title={project.title}>{project.title}</p>
                <div className="d-flex align-items-center">
                  {loading ? (
                    <i className="fa fa-spinner small-spinner" />
                  ) : (
                    <div
                      className="cursor-pointer"
                      onClick={() => {toggleFavorite(project?.favourite)
                        {
                          eventTrack(
                            "Toggle-Fav-Action",
                            "Toggle-Fav",
                            userInfoRedux?.name,
                            "+1",
                            false,
                            "+1"
                          );
                        }
                      }
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_426_6573)">
                          <rect
                            width="18"
                            height="18"
                            transform="translate(0.455078 0.45459)"
                            fill="white"
                          />
                          <path
                            d="M9.45602 14.1496L4.16593 17.1107L5.34743 11.1645L0.896484 7.04844L6.91676 6.33463L9.45602 0.82959L11.9952 6.33463L18.0155 7.04844L13.5646 11.1645L14.7461 17.1107L9.45602 14.1496Z"
                            fill={project?.favourite ? "#FFBF1B" : "#ddd"}
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_426_6573">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(0.455078 0.45459)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  )}

                  <MDBDropdown dropright className="ml-2">
                    <MDBDropdownToggle className="dots-btn">
                      <img src={dots} className="dots px-2 cursor-pointer" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="w-100 border-none">
                      <div className="card-body-help">
                        <ul className="siderbar-inner-ul">
                          <li
                            onClick={() => {
                              FinalPermissions?.includes(
                                ALL_PERMISSIONS.SHARE_PROJECT_ADD
                              )
                                ? handleAccess(project)
                                : setRequestModal(true);
                              setRequestType(ALL_PERMISSIONS.SHARE_PROJECT_ADD);
                            }}
                          >
                            Access
                          </li>
                          <li
                            onClick={() => {
                              FinalPermissions?.includes(
                                ALL_PERMISSIONS.ASSIGN_PROJECT_ADD
                              )
                                ? handleMembers(project)
                                : setRequestModal(true);

                              setRequestType(
                                ALL_PERMISSIONS.ASSIGN_PROJECT_ADD
                              );
                            }}
                          >
                            Members
                          </li>
                          <li
                            onClick={() => {
                              FinalPermissions?.includes(
                                ALL_PERMISSIONS.ESTIMATION_VIEW
                              )
                                ? handleHistory(project)
                                : setRequestModal(true);

                              setRequestType(ALL_PERMISSIONS.ESTIMATION_VIEW);
                            }}
                          >
                            History
                          </li>
                          {project?.zipInfo?.paths.length > 0 && (
                            <li
                              onClick={() => {
                                history.push(
                                  `./personal-area/projects/model-view/${project?._id}`
                                );
                                {
                                  eventTrack(
                                    "View-Model-Action",
                                    "View-Model",
                                    project?.title,
                                    project?._id,
                                    false,
                                    project?.title
                                  );
                                }
                              }}
                            >
                              View Model
                            </li>
                          )}

                          <li
                            onClick={() => {
                              FinalPermissions?.includes(
                                ALL_PERMISSIONS.PROJECT_DELETE
                              )
                                ? handleDelete(project)
                                : setRequestModal(true);

                              setRequestType(ALL_PERMISSIONS.PROJECT_DELETE);
                            }}
                          >
                            Delete
                          </li>
                        </ul>
                      </div>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </div>
              <div className="card-detail-container">
                <div className="item">
                  <div>
                    {timelines?.length ? (
                      <>
                        <img src={money} className="d-icon mr-2" />${" "}
                        {numeral(
                          timelines[timelines.length - 1].estimation
                        ).format("0,0.00")}
                      </>
                    ) : (
                      "No Estimations"
                    )}
                  </div>
                </div>
                <div className="item">
                  <div>{project.version}</div>
                </div>
                <div className="item">
                  <div>
                    {zipInfo?.exportedBy?.name
                      ? `${zipInfo?.exportedBy.name} ${zipInfo?.exportedBy.lastName}`
                      : zipInfo?.exportedBy ?? "N/A"}
                  </div>
                </div>
                {zipInfo && (
                  <div className="item">
                    <div className="d-flex">
                      <img src={clock} className="d-icon mr-2 w-4" />
                      <div>
                        <div>{formatDate(zipInfo.date)}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {provided.placeholder}
            </div>
          </div>
        )}
      </Draggable>

      {/* request access model */}
      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequestRedux?.includes(requestType)}
          loading={requestLoading}
          icon={
            featureRequestRedux?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequestRedux?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequestRedux?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </>
  );
};

export default KanbanCard;
