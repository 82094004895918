import * as React from 'react'

function SvgArrowDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path d="M2 10h32L18 26 2 10z" fill="currentColor" />
    </svg>
  )
}

export default SvgArrowDown
