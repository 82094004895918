import React from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { EllipsesIcon, UploadIcon } from "assets/svg-icons";
import * as XLSX from "xlsx";
import handleBuildTree from "helpers/buildTree";
import { useDispatch } from "react-redux";
import { setCatalogs, setCatalogsTree } from "actions/user";
import addNotification from "helpers/notify";
import { useSelector } from "react-redux";

export const UploadOptionList = ({
  setLoadingState,
  setSearchedCatalogs,
  template,
  conversionRate,
  admin,
  getIsCatalogUploaded,
  handleLoadFromDatabase
}) => {
  const allCategoryData = useSelector((s) => s.pricing.allCategories);
  const dispatch = useDispatch();
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    e.target.value = '';

    reader.onload = (event) => {
      const fileData = event.target.result;
      const fileExtension = getFileExtension(file.name);

      if (fileExtension === "xlsx") {
        processXLSXFile(fileData);
      } else if (fileExtension === "csv") {
        processCSVFile(fileData);
      } else {
        console.error("Unsupported file format");
      }
    };

    reader.readAsBinaryString(file);
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop().toLowerCase();
  };

  const processXLSXFile = (binaryString) => {
    setLoadingState("table-data");
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 1 });
    const dataArray = data.map((row) => {
      const filteredCaegories = allCategoryData
        .filter(
          (category) =>
            category?.company?.Name?.toLowerCase() === row[5]?.toLowerCase()
        )
        .map((item) => item?.company?.Key);
      const awp = row[6] === "--" ? "(None)" : row[6];
      const unitCostInUSD = parseInt(row[3]) > 0 ? (parseInt(row[3]) / conversionRate) : parseInt(row[3]);
      const arrangedData = {
        Level: row[0],
        Code: sanitizeDivision(row[1]),
        Description: sanitizeDivision(row[2]),
        UnitCost: unitCostInUSD,
        Unit: row[4],
        CategoryId: filteredCaegories,
        Awp: awp,
      };
      const treeRawData = {
        default: { ...arrangedData, _id: Math.random() + 1 },
        company: { ...arrangedData, _id: Math.random() + 2 },
        average: { ...arrangedData, _id: Math.random() + 1.25 },
      };
      return treeRawData;
    });
    getTreeData(dataArray);
  };

  const sanitizeDivision = (division) => {
    if (typeof division === 'string') {
      return division?.replace(/[|_]/g, "").trim();
    } else {
      return division;
    }
  };

  const processCSVFile = (csvString) => {
    try {
      setLoadingState("table-data");
      const data = CSVToArray(csvString);
      const dataArray = data.flatMap((row) => {
        const filteredCaegories = allCategoryData
          .filter(
            (category) =>
              category?.company?.Name?.toLowerCase() === row[5]?.toLowerCase() ?? ""
          )
          .map((item) => item?.company?.Key);
        const awp = row[6] === "--" ? "(None)" : row[6];
        const unitCostInUSD = parseInt(row[3]) > 0 ? (parseInt(row[3]) / conversionRate) : parseInt(row[3]);
        const arrangedData = {
          Level: row[0],
          Code: sanitizeDivision(row[1]),
          Description: sanitizeDivision(row[2]),
          UnitCost: unitCostInUSD,
          Unit: row[4],
          CategoryId: filteredCaegories,
          Awp: awp,
        };
        const treeRawData = {
          default: { ...arrangedData, _id: Math.random() + 1 },
          company: { ...arrangedData, _id: Math.random() + 2 },
          average: { ...arrangedData, _id: Math.random() + 1.25 },
        };
        return treeRawData;
      });
      getTreeData(dataArray);
    } catch (error) {
      setLoadingState("");
      addNotification("Something went wrong, please connect with support!", "danger");
    }
  };

  const CSVToArray = (csvString) => {
    const rows = csvString.split("\n");
    return rows.map((row) => row.split(","));
  };

  const getTreeData = (data) => {
    dispatch(setCatalogs(data));
    const catalogsDataTree = handleBuildTree(
      data,
      admin,
      template?.company?.Protocol
    );
    setTimeout(() => {
      if (catalogsDataTree.Children.length) {
        getIsCatalogUploaded(true);
        setSearchedCatalogs(catalogsDataTree.Children);
        dispatch(setCatalogsTree(catalogsDataTree.Children));
        addNotification("Catalogs uploaded successfully", "success");
      }
      setLoadingState("");
    }, 2000);
    return catalogsDataTree;
  };

  return (
    <MDBDropdown className="border-none catalogbtn ">
      <MDBDropdownToggle
        className={`bg-bid d-flex flex-row justify-content-between ${
          !template && "disabled"
        }`}
      >
        <div className="d-flex gap-2">
          <UploadIcon />
          {"Load"}
        </div>

        <EllipsesIcon />
      </MDBDropdownToggle>
      <MDBDropdownMenu className="w-100 border-none" responsive="end">
        <MDBDropdownItem
          className="optionItem"
          onClick={() => {
            handleLoadFromDatabase(template)
            getIsCatalogUploaded(false);
          }}
        >
          {"Load from Data-base"}
        </MDBDropdownItem>
        <MDBDropdownItem
          className="optionItem"
          onClick={() => document.getElementById("xlFile")?.click()}
        >
          {"Load Excel"}
          <input
            id="xlFile"
            type="file"
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
        </MDBDropdownItem>
        <MDBDropdownItem
          className="optionItem"
          onClick={() => document.getElementById("csvFile")?.click()}
        >
          {"Load CSV"}
          <input
            id="csvFile"
            type="file"
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
        </MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};
