import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import './assets/fonts/flaticon/font/flaticon.css';
import './assets/fonts/icomoon/style.css';
import './styles/index.scss';
import App from './App';
import store from './store';
import { initUser } from './helpers/initUser';

import 'react-notifications-component/dist/theme.css';

import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import { UserChat } from 'pages/Chat/UserChat';

var DEBUG = true;
if(!DEBUG){
    if(!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for(var i=0;i<methods.length;i++){
        console[methods[i]] = function(){};
    }
}

initUser(store);

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate persistor={persistor}> */}
    <Router>
      <ReactNotification />
      <div>
      <App />
      <UserChat/>
      </div>
    </Router>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root'),
  );
