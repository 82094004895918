import { useHttp } from "hooks/useHttp";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getProjectExportSettings,
  updateProjectExportSettings,
} from "api/projects/exportSettings";
import addNotification from "helpers/notify";
import Select from "react-select";
import { mainAdminGetCompanies } from "api/users/mainAdminGetCompanies";
import { setProjects } from "actions/user";
import { getProjects } from "api/projects/getProjects";
import { useHttpWithCache } from "hooks/useHtthWithCache";

export default function ExportSettingsProjectView({
  projectToEdit,
  filteredParams,
}) {
  const dispatch = useDispatch();
  const milestonesRedux = useSelector((s) => s.user.milestones);
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const projectsRedux = useSelector((s) => s.user.projects);
  const [recentExportSettings, setRecenetExportSettings] = useState(false);
  const [include2DElements, setInclude2DElements] = useState(false);
  const [includeMiscellaneous, setIncludeMiscellaneous] = useState(false);
  const [filteredParamsNameList, setFilteredParamsNameList] = useState();
  const [matchingParamsType, setMatchingParamsType] = useState();
  const [matchingParamsNameList, setMatchingParamsNameList] = useState();
  const [filteredParamsType, setFilteredParamsType] = useState();
  const [milestones, setMilestones] = useState([]);
  const [selectedMilestone, setSelectedMilstone] = useState(null);
  const [showExtraMatchingParameter, setShowExtraMatchingParameter] =
    useState(false);
  const [showFilterBidlightParameter, setShowFilterBidlightParameter] =
    useState(false);
  const [showFilterCustomParameter, setShowFilterCustomParameter] =
    useState(false);
  const [filterParameterSelected, setFilterParameterSelected] = useState([]);
  const [matchingParameterId, setMatchingParameterId] = useState([]);
  const [filterParamsProjectObj, setFilterParamsProjectObj] = useState([]);
  const [selectedFilterParameter, setSelectedFilterParameter] = useState();
  const [selectedMatchingParameter, setSelectedMatchingParameter] = useState();

  const PARAMETER_TYPE_OPTIONS = [
    {
      label: "Built In Parameters",
      value: "builtIn",
    },
    {
      label: "Shared Parameters",
      value: "shared",
    },
    {
      label: "Project Parameters",
      value: "project",
    },
    {
      label: "Family Parameters",
      value: "family",
    },
    {
      label: "Global Parameters",
      value: "global",
    },
  ];

  const parentProjectOptions = projectsRedux?.map((i) => ({
    label: i.title,
    value: i._id,
  }));
  const [parameters, setParameters] = useState(filteredParams);

  const { projectSettingsLoading, request: getProjectSettings } = useHttp({
    requestCallback: (projectID) => getProjectExportSettings(projectID),
    onLoad: async (response) => {
      if (response?.filterParameter?.type === "custom parameter") {
        setShowFilterCustomParameter(true);
      }

      if (response?.filterParameter?.type === "bidlight parameter") {
        setShowFilterBidlightParameter(true);
        const bidlightFilters = parentProjectOptions.filter((option) =>
          response?.filterParameter?.value.includes(option.value)
        );

        setFilterParamsProjectObj(bidlightFilters);
      }

      if (response?.matchingParameter?.type === "custom parameter") {
        setShowExtraMatchingParameter(true);
      }

      const filterParameter = PARAMETER_TYPE_OPTIONS.find(
        (item) => item?.value === response?.filterParameter?.paramType
      );
      const matchingParameter = PARAMETER_TYPE_OPTIONS.find(
        (item) => item?.value === response?.matchingParameter?.paramType
      );

      setSelectedMatchingParameter(matchingParameter);
      setSelectedFilterParameter(filterParameter);
      setRecenetExportSettings(response);
      handle2DElementCheckbox(response?.include2DElements);
      handleMiscellaneousElementCheckbox(response?.includeMiscellaneous);
      const milestoneOptions = milestonesRedux?.map((i) => ({
        label: i.key,
        value: i._id,
      }));

      const settingMileStone = milestoneOptions.find(
        (mileStoneItem) => mileStoneItem?.value === response?.milestone
      );

      setSelectedMilstone(settingMileStone);
      setMilestones(milestoneOptions);
    },
    onError: (res) => {
      addNotification("something went wrong", "danger");
    },
  });

  const handleChangeFilterParameterType = (filter) => {
    const filteredObj = parameters?.filter(
      (i) => i?.parameterType === filter.value
    );
    const filteredObjArr = filteredObj?.map((i) => ({
      label: i.name,
      value: i._id,
    }));
    setFilteredParamsNameList(filteredObjArr);
    setFilteredParamsType({ ...filter });
    setSelectedFilterParameter({ ...filter });
  };

  const handleChangeMatchingParameterType = async (filter) => {
    const filteredObj = parameters?.filter(
      (i) => i?.parameterType === filter.value
    );
    const filteredObjArr = filteredObj?.map((i) => ({
      label: i.name,
      value: i._id,
    }));

    // const res = await getParameters({
    //   type: filter.value,
    //   companyId: userInfoRedux?.company,
    // });
    

    setMatchingParameterId([]);
    setMatchingParamsNameList(filteredObjArr);
    setMatchingParamsType({ ...filter });
    setSelectedMatchingParameter({ ...filter });
  };

  const handle2DElementCheckbox = (value = false) => {
    setInclude2DElements(value);
  };

  const handleMiscellaneousElementCheckbox = (value = false) => {
    setIncludeMiscellaneous(value);
  };

  useEffect(() => {
    if (parameters?.length && recentExportSettings?._id) {
      if (recentExportSettings?.filterParameter.type === "custom parameter") {
        const filteredParameters = parameters
          ?.filter((param) =>
            recentExportSettings.filterParameter.value.includes(param._id)
          )
          ?.map((i) => ({
            label: i.name,
            value: i._id,
          }));
        setFilterParameterSelected(filteredParameters);
      }
      if (recentExportSettings?.matchingParameter.type === "custom parameter") {
        const matchinParameters = parameters
          ?.filter((param) =>
            recentExportSettings.matchingParameter.parameters.includes(
              param._id
            )
          )
          ?.map((i) => ({
            label: i.name,
            value: i._id,
          }));
        setMatchingParameterId(matchinParameters);
      }
    }
  }, [filteredParams, recentExportSettings]);

  const hanldeProjectSettingApiRequest = () => {
    const filterParameter = {
      type: showFilterBidlightParameter
        ? "bidlight parameter"
        : showFilterCustomParameter
        ? "custom parameter"
        : "None",
      paramType: showFilterCustomParameter
        ? filteredParamsType?.value
        : showFilterBidlightParameter
        ? "bidlight parameter"
        : "None",
      value: showFilterBidlightParameter
        ? filterParamsProjectObj?.map((i) => i.value)
        : showFilterCustomParameter
        ? filterParameterSelected?.map((i) => i.value)
        : [],
    };

    const matchingParameter = {
      type: showExtraMatchingParameter ? "custom parameter" : "Default",
      paramType: matchingParamsType?.value,
      parameters: matchingParameterId?.map((i) => i.value),
    };

    const payload = {
      _id: recentExportSettings?._id,
      companyId: userInfoRedux?.company,
      projectId: recentExportSettings?.projectId,
      milestone: selectedMilestone?.value,
      includeMiscellaneous: includeMiscellaneous,
      include2DElements: include2DElements,
      matchingParameter: matchingParameter,
      filterParameter: filterParameter,
    };
    updateExportSetting(payload);
  };

  const { loading: updateLoading, request: updateExportSetting } = useHttp({
    requestCallback: (data) => updateProjectExportSettings(data),
    onLoad: (res) => {
      addNotification(
        "Project export settings successfully updated",
        "success"
      );
      if (userInfoRedux?.admin) {
        getAdminProjectsAPI();
      } else {
        getProjectsAPI();
      }
    },
    onError: (res) => {
      console.log("Error**", res);
      addNotification("Something went wrong", "danger");
    },
  });

  const {
    adminProjectsLoading,
    adminProjectsError,
    request: getAdminProjectsAPI,
  } = useHttpWithCache({
    requestCallback: () => mainAdminGetCompanies(),
    reduxSetter: (data) => {
      dispatch(
        setProjects(
          data?.company_projects?.find(
            (item) => item?._id === userInfoRedux?.company
          )?.projects
        )
      );
    },
    reduxReset: () => {
      dispatch(setProjects(null));
    },
    reduxCash: {
      projects: projectsRedux,
    },
    allowToCash: false,
  });

  const {
    loading,
    error,
    request: getProjectsAPI,
  } = useHttpWithCache({
    requestCallback: () => getProjects(),
    reduxSetter: (data) => {
      dispatch(setProjects(data?.projects));
    },
    reduxReset: () => {
      dispatch(setProjects(null));
    },
    reduxCash: {
      projects: projectsRedux,
    },
    allowToCash: false,
  });

  useEffect(() => {
    getProjectSettings(projectToEdit?.projectExportSettings);
  }, []);

  return (
    <div className="p-3">
      <div
        className="header-btn bg-bid text-white border-bid rounded-lg h6 ml-auto mb-0 cursor-pointer mr-1 export-save-btn"
        onClick={hanldeProjectSettingApiRequest}
      >
        <span className="f-14">
          {updateLoading ? (
            <i className="fa fa-spinner small-spinner mr-2" />
          ) : (
            <i className="fa fa-save mr-2 "></i>
          )}
          Save
        </span>
      </div>
      <div className="border rounded-3 bg-white p-3 pb-4">
        <div className="form-group-div">
          <div>
            <label className="inter pl-2">Milestone</label>
            <Select
              options={milestones}
              value={selectedMilestone}
              onChange={(options) => setSelectedMilstone(options)}
            />
          </div>
        </div>
      </div>

      <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
        <label className="inter pl-2 f-14">
          Select the preferred matching parameter
        </label>
        <div>
          <div className="form-check mt-2">
            <input
              className="form-check-input rounded-checkbox"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={!showExtraMatchingParameter}
              onChange={(e) => setShowExtraMatchingParameter(false)}
              value={showExtraMatchingParameter}
            />
            <label
              className="form-check-label inter f-14"
              htmlFor="flexRadioDefault1"
            >
              Use revit default category
            </label>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input rounded-checkbox"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              onChange={(e) => setShowExtraMatchingParameter(true)}
              checked={showExtraMatchingParameter}
              value={showExtraMatchingParameter}
            />
            <label
              className="form-check-label inter f-14"
              htmlFor="flexRadioDefault2"
            >
              Use custom parameter
            </label>
          </div>
        </div>

        {showExtraMatchingParameter && (
          <>
            <div className="form-group-div mt-3">
              <div>
                <label className="inter pl-2">Project parameter</label>
                <Select
                  options={PARAMETER_TYPE_OPTIONS}
                  onChange={(option) =>
                    handleChangeMatchingParameterType(option)
                  }
                  value={selectedMatchingParameter}
                />
              </div>
            </div>

            <div className="form-group-div mt-3">
              <div>
                <label className="inter pl-2">Input parameter</label>
                <div className="form-group-div mt-3">
                  <Select
                    options={matchingParamsNameList}
                    isMulti
                    value={matchingParameterId}
                    onChange={(option) => {
                      setMatchingParameterId(option);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
        <label className="inter pl-2 f-14">
          Select the preferred export filtering parameter
        </label>
        <div>
          <div className="form-check mt-2">
            <input
              className="form-check-input rounded-checkbox"
              type="radio"
              name="filterParameterRad"
              id="filterParameterRad1"
              checked={
                !showFilterBidlightParameter && !showFilterCustomParameter
              }
              onChange={(e) => {
                setShowFilterBidlightParameter(false);
                setShowFilterCustomParameter(false);
              }}
            />
            <label
              className="form-check-label inter f-14"
              htmlFor="filterParameterRad"
            >
              None
            </label>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input rounded-checkbox"
              type="radio"
              name="filterParameterRad"
              id="filterParameterRad2"
              checked={
                showFilterBidlightParameter && !showFilterCustomParameter
              }
              onChange={(e) => {
                setShowFilterBidlightParameter(true);
                setShowFilterCustomParameter(false);
              }}
            />
            <label
              className="form-check-label inter f-14"
              htmlFor="filterParameterRad2"
            >
              Use BidLight project ID
            </label>
          </div>
        </div>

        {showFilterBidlightParameter && (
          <div>
            <div className="form-group-div mt-3">
              <Select
                options={parentProjectOptions}
                isMulti
                value={filterParamsProjectObj}
                onChange={(e) => setFilterParamsProjectObj(e)}
              />
            </div>
          </div>
        )}

        {/* <div className="d-flex justify-content-between gap-3">
      <div className="form-group-div mt-3 w-100">
        <div>
          <input type="text" placeholder="Parameter name" />
        </div>
      </div>
      <div className="form-group-div mt-3 w-100">
        <div>
          <input type="text" placeholder="Parameter value" />
        </div>
      </div>
    </div> */}

        <div className="form-check mt-3">
          <input
            className="form-check-input rounded-checkbox"
            type="radio"
            name="filterParameterRad"
            id="filterParameterRad3"
            checked={!showFilterBidlightParameter && showFilterCustomParameter}
            onChange={(e) => {
              setShowFilterBidlightParameter(false);
              setShowFilterCustomParameter(true);
            }}
          />
          <label
            className="form-check-label inter f-14"
            htmlFor="filterParameterRad3"
          >
            Use Custom parameter
          </label>
        </div>

        {showFilterCustomParameter && (
          <>
            <div className="form-group-div mt-3">
              <div>
                {/* <label className="inter pl-2">Project parameter</label> */}
                <Select
                  options={PARAMETER_TYPE_OPTIONS}
                  onChange={(option) => handleChangeFilterParameterType(option)}
                  value={selectedFilterParameter}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between gap-3">
              {/* <div className="form-group-div mt-3 w-100">
            <div>
              <input type="text" placeholder="Parameter name" />
            </div>
          </div> */}
              <div className="form-group-div mt-3 w-100">
                <div>
                  <Select
                    options={filteredParamsNameList}
                    isMulti
                    onChange={(option) => setFilterParameterSelected(option)}
                    value={filterParameterSelected}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="border rounded-3 bg-white p-3 pb-4 mt-3">
        <label className="inter pl-2 f-14">
          Additional export configuration
        </label>
        <div>
          <div className="form-check">
            <input
              className="form-check-input squared-checkbox"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              checked={includeMiscellaneous}
              onChange={(e) =>
                handleMiscellaneousElementCheckbox(e?.target?.checked)
              }
            />
            <label
              className="form-check-label f-14 inter"
              htmlFor="flexCheckDefault"
            >
              Include rooms spaces and miscellaneous items
            </label>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input squared-checkbox"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={include2DElements}
              onChange={(e) => handle2DElementCheckbox(e?.target?.checked)}
            />
            <label
              className="form-check-label inter f-14"
              htmlFor="flexCheckChecked"
            >
              Include 2D Elements
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
